import { AbstractModel } from '../../abstract.model';

export class CollectRequestMax extends AbstractModel {
  public establishmentId: number;
  public depositorId: number;
  public requestMaxNf: number;
  public maxQtdLnfIssued: number;
  public minValueByNf: number;

  constructor() {
    super();
  }

}
