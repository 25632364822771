import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SDM_URL } from './../../../../app.api';
import { environment as env } from './../../../../../environments/environment';
import { DockVehicleCompatibility } from './../../../models/sdm/docks/dock-vehicle-compatibility';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DockVehicleCompatiblityService extends AbstractService<
  DockVehicleCompatibility
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.dockVehicleCompatibility.default);
  }

  findByDepositorId(depositorId: number): Observable<DockVehicleCompatibility[]> {
    return this.http.get<DockVehicleCompatibility[]>(
      `${SDM_URL.docks.dockVehicleCompatibility.findByDepositorIdAndStatusTrue.replace(
        ':depositorId',
        depositorId.toString()
      )}`
    );
  }
}
