import {
  Component,
  OnDestroy,
  OnInit,
  Renderer,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SDM_URL } from '../../../../../app.api';
import { SelectFieldComponent } from '../../../../../components/select/select-field/select-field.component';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { VehicleDepositor } from '../../../../../core/models/sdm/vehicle-depositor';
import { AuthService } from '../../../../../core/services/auth.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { DockVehicleCompatibility } from './../../../../../core/models/sdm/docks/dock-vehicle-compatibility';
import { Establishment } from './../../../../../core/models/sdm/establishment';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';
import { DockVehicleCompatiblityService } from './../../../../../core/services/sdm/docks/dock-vehicle-compatiblity.service';
import { DockVehiclesService } from './../../../../../core/services/sdm/docks/dock-vehicles.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { DocksService } from './../../docks.service';

@Component({
  selector: 'app-dock-vehicle-compatibility-form',
  templateUrl: './dock-vehicle-compatibility-form.component.html',
  styleUrls: ['./dock-vehicle-compatibility-form.component.scss']
})
export class DockVehicleCompatibilityFormComponent
  implements OnInit, OnDestroy {
  dock: object = {};
  vehicle: VehicleDepositor[] = [];
  depositorID: string;
  isDisabled = true;
  btnDisable = true;
  establishmentID: string;
  dockVehicleCompatibility: DockVehicleCompatibility;
  sdmDockId = null;
  userId: null;
  // dockVehicles: DockVehicles;
  formEnabled = false;

  subscriptions: Subscription[] = [];

  formDockVehicles: FormGroup;
  establishments: Establishment[] = [];
  depositors: Depositor[] = [];

  @ViewChild('gridDepositorVehicleCompatibility')
  gridDepositorVehicleCompatibility: DatatableApiComponent;

  @ViewChild('gridEstablishments') gridEstablishments: DatatableApiComponent;
  @ViewChild('gridDepositors') gridDepositors: DatatableApiComponent;

  @ViewChild('gridVehicleDepositor')
  gridVehicleDepositor: DatatableApiComponent;
  @ViewChild('gridDockDepositor') gridDockDepositor: DatatableApiComponent;

  @ViewChild('gridDockVehicle') gridDockVehicle: DatatableApiComponent;

  @ViewChild('actTpl') actTpl: TemplateRef<any>;
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  columnsVehicle = [
    { prop: 'vehicleEstablishment.vehicle.vehicleType', name: 'Tipo' }
  ];

  columnsDocas = [
    { prop: 'id', name: '# Doca' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'initialHour', name: 'Hora Início' },
    { prop: 'finalHour', name: 'Hora Término' }
  ];

  columnsEstablishment = [
    { prop: 'id', name: '#', checkboxable: false, width: 60 },
    { prop: 'description', name: 'Descrição' },
    { prop: 'shortDescription', name: 'Descrição resumida' },
    { prop: 'document', name: 'CNPJ' }
  ];

  columnsDepositors = [
    { prop: 'id', name: '#', checkboxable: false, width: 60 },
    { prop: 'name', name: 'Razão social' },
    { prop: 'document', name: 'CNPJ' }
  ];

  columnsDockVehicle: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    {
      prop: 'vehicleTypeCompatibilityAllDocks',
      name: 'Veículos Compatíveis?',
      pipe: new BooleanConverter()
    }
  ];

  columnsDockVehicleCompatible = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'initialHour', name: 'Horário Início' },
    { prop: 'finalHour', name: 'Horário Término' }
  ];
  depositorsListAfterInsert: Depositor;
  establishmentListAfterInsert: Establishment;
  @ViewChild('inputEstablishment') inputEstablishment: SelectFieldComponent;

  constructor(
    public toaster: ToastrService,
    public translator: TranslatorService,
    public _depositorService: DepositorService,
    private _dockVehicleService: DockVehicleCompatiblityService,
    private _docksService: DocksService,
    private _dockVehiclesService: DockVehiclesService,
    private _establishmentService: EstablishmentService,
    private renderer: Renderer,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this.columnsDockVehicle.push({
      name: 'Ações',
      cellTemplate: this.actTpl,
      headerTemplate: this.hdrTpl
    });
    this.dockVehicleCompatibility = new DockVehicleCompatibility();

    this.formDockVehicles = new FormGroup({
      id: new FormControl(''),
      establishment: new FormControl(''),
      establishmentId: new FormControl(''),
      depositor: new FormControl(''),
      depositorId: new FormControl(''),
      vehicleTypeId: new FormControl(''),
      priorVisualizationId: new FormControl(''),
      vehicleTypeCompatibilityAllDocks: new FormControl(
        this.dockVehicleCompatibility.vehicleTypeCompatibilityAllDocks
      ),
      createdBy: new FormControl('')
    });

    this.formDockVehicles
      .get('vehicleTypeCompatibilityAllDocks')
      .valueChanges.subscribe(value => {
        if (value != null && (value || value === 'true')) {
          this.formDockVehicles.get('vehicleTypeId').setValue([]);
          this.formDockVehicles.get('priorVisualizationId').setValue('');
          this.gridVehicleDepositor.selected = [];
          this.gridDockDepositor.selected = [];
        }

        if (value == null) {
          this.formDockVehicles.get('vehicleTypeId').setValue([]);
          this.formDockVehicles.get('priorVisualizationId').setValue('');
          this.gridVehicleDepositor.selected = [];
          this.gridDockDepositor.selected = [];
          this.gridDepositorVehicleCompatibility.selected = [];
        }
      });

    this._docksService.emitEventTabVehicleCompatibilityFired
      .pipe(take(1))
      .subscribe(() => {
        this.loadEstablishments();
        // this.gridEstablishments.reloadPage();
        this.gridDepositorVehicleCompatibility.reloadPage();
      });
  }

  loadEstablishments() {
    this.subscriptions.push(
      this._establishmentService.findAll().subscribe(data => {
        this.establishments = [...data.body['content']];

        if (this.establishments.length === 1) {
          this.formDockVehicles
            .get('establishment')
            .setValue(this.establishments[0]);
          this.establishmentChanged(
            this.formDockVehicles.get('establishment').value
          );
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  establishmentChanged(establishment: Establishment) {
    
    if (establishment) {
      this.formDockVehicles.get('establishmentId').setValue(establishment.id);
      this.establishmentID = establishment.id.toString();
      this.subscriptions.push(
        this._depositorService
          .findByEstablishmentId(establishment.id)
          .subscribe(data => {
            if (data && data.body['content'].length === 1) {
              const depositor = data.body['content'][0];
              this.formDockVehicles.get('depositor').setValue(depositor);
              this.depositorSelected(depositor);
            }
            this.depositors = [...data.body['content']];
          })
      );
    } else {
      this.depositors = [];
      this.formDockVehicles.get('depositor').setValue([]);
    }
  }

  submit() {
    if (!this.vehicleAreCompatibles() && !this.vehiclesAndDocksAreSelected()) {
      this.toaster.warning('Selecione o(s) veículo(s) e a Doca', 'SDM');
      return;
    }

    if (!this.formDockVehicles.get('id').value) {
      this._dockVehicleService.create(this.formDockVehicles.value).subscribe(
        data => {
          this.formDockVehicles.patchValue(data);
          
          this.dockVehicleCompatibility.id = data.id;
          this.gridDepositorVehicleCompatibility.reloadPage();
          this.translator.translate
            .get(`pages.docks.dock-vehicle-compatibility.success.create`)
            .subscribe(message => this.toaster.success(message, 'SDM'));
        },
        error => console.error(error)
      );
    } else {
      this._dockVehicleService
        .edit(this.formDockVehicles.value)
        .subscribe(data => {
          
          this.formDockVehicles.patchValue(data['body']);
          this.setDockVehicles(data['body']);
          this.gridDepositorVehicleCompatibility.reloadPage();
          this.translator.translate
            .get(`pages.docks.dock-vehicle-compatibility.success.update`)
            .subscribe(message => this.toaster.success(message, 'SDM'));
        });
    }
  }

  setDockVehicles(data) {
    if (data && data.dockVehicles.length > 0)
      this.formDockVehicles
        .get('vehicleTypeId')
        .setValue(data.dockVehicles.map(v => v.vehicleTypeId));
  }

  vehicleAreCompatibles(): boolean {
    return (
      this.formDockVehicles.get('vehicleTypeCompatibilityAllDocks').value ===
        true ||
      this.formDockVehicles.get('vehicleTypeCompatibilityAllDocks').value ===
        'true'
    );
  }

  vehiclesAndDocksAreSelected(): boolean {
    return (
      this.formDockVehicles.get('vehicleTypeId').value.length > 0 &&
      this.formDockVehicles.get('priorVisualizationId').value !== ''
    );
  }

  // fillObjDockVehicle(row) {
  //   this.gridEstablishments.selected = [row.establishment];
  //   this.establishmentSelected(row.establishment);
  //   this.gridDepositors.selected = [row.depositor];
  //   this.depositorSelected(row.depositor);
  //   this.dockVehicleCompatibility.id = row.id;
  //   this.dockVehicleCompatibility.vehicleTypeCompatibilityAllDocks = row.vehicleTypeCompatibilityAllDocks;
  // }

  establishmentSelected(data) {
    this.establishmentID = data.id;
    this.dockVehicleCompatibility.establishmentId = data.id;
    setTimeout(() => this.gridDepositors.reloadPage(), 1);
    this.dockVehicleCompatibility.id = null;
  }

  depositorSelected(depositor: Depositor) {
    this.dockVehicleCompatibility.id = null;
    if (depositor) {
      
      this.isDisabled = false;
      this.formDockVehicles.get('depositorId').setValue(depositor.id);
      this.depositorID = depositor.id;
      const { id } = this._authService.getUser();
      this.userId = id;
      this.dockVehicleCompatibility.depositorId = depositor.id;
      setTimeout(() => {
        this.gridVehicleDepositor.reloadPage();
        this.gridDockDepositor.reloadPage();
      }, 1);
    } else {
      this.depositorID = '-1';

      setTimeout(() => {
        this.gridVehicleDepositor.reloadPage();
        this.gridDockDepositor.reloadPage();
        this.gridVehicleDepositor.clearData();
      }, 1);

      this.dock = [];
      this.vehicle = [];
      this.gridDockDepositor.selected = [];
      this.gridVehicleDepositor.selected = [];
    }
  }

  changeAllTypesAreCompatibles(event) {
    const value = event.target.value;
    this.btnDisable = value === 'true';
  }

  // vehicleCompatible() {
  //   if (!this.dock) {
  //     this.toaster.info('Selecione a Doca', 'SDM');
  //     return;
  //   }

  //   if (!this.vehicle) {
  //     this.toaster.info('Selecione o Tipo de Veículo', 'SDM');
  //     return;
  //   }

  //   this.dockVehicles = new DockVehicles();
  //   this.dockVehicles.establishmentId = +this.establishmentID;
  //   this.dockVehicles.sdmDock = this.dock['id'];
  //   this.dockVehicles.vehicleTypeId = this.vehicle['vehicle']['id'];
    //   this._dockVehiclesService.create(this.dockVehicles)
  //     .subscribe(res => {
  //       this.gridDockVehicle.reloadPage();
  //     });
  // }

  dockSelected(dock: PriorVisualization) {
    this.dock = dock;
    this.sdmDockId = dock.id;
    this.formDockVehicles.get('priorVisualizationId').setValue(dock.id);
    this.formDockVehicles
      .get('vehicleTypeCompatibilityAllDocks')
      .setValue(false);
  }

  vehicleSelected(vehicleSel: VehicleDepositor[]) {
    // this.gridDepositorVehicleCompatibility.selected = [];
    this.vehicle = vehicleSel;
    this.formDockVehicles
      .get('vehicleTypeId')
      .setValue(this.vehicle.map(v => v.id));
    this.formDockVehicles
      .get('vehicleTypeCompatibilityAllDocks')
      .setValue(false);
    // setTimeout(() => this.gridDepositorVehicleCompatibility.reloadPage(), 1);
  }

  selectCompatibleDocksWithVehicles(data: any) {
    this.formDockVehicles.patchValue(data[0]);

    const vehicleDepositor = data[0]['vehiclesDepositor'];
    const priorVisualization = data[0]['dockVehicles'][0]['priorVisualization'];
    this.gridVehicleDepositor.selected = vehicleDepositor;
    this.gridDockDepositor.selected = [priorVisualization];

    this.dockVehicleCompatibility = data[0];

    this.formDockVehicles
      .get('vehicleTypeId')
      .setValue(vehicleDepositor.map(v => v.id));
    this.formDockVehicles
      .get('priorVisualizationId')
      .setValue(priorVisualization.id);
  }

  addButton() {
    this.formDockVehicles.reset();
    this.formDockVehicles.patchValue(new DockVehicleCompatibility());
        // this.gridVehicleDepositor.selected = [];
    this.depositorID = '-1';

    setTimeout(() => {
      this.gridDockDepositor.reloadPage();
      this.gridDockDepositor.clearData();
    }, 1);

    setTimeout(() => {
      this.gridVehicleDepositor.reloadPage();
      this.gridVehicleDepositor.clearData();
    }, 1);

    // this.gridDockDepositor.selected = [];
    this.inputEstablishment.items = this.establishments;
  }

  getId(row) {
    return row.id;
  }

  searchValue(row) {
    

    this.formDockVehicles.patchValue(row);
    this.establishmentListAfterInsert = row['establishment'];
    this.depositorsListAfterInsert = row['depositor'];
    this.formDockVehicles.enable();

    this.setEstablishmentOnUpdate();

    // this.formDockVehicles.patchValue(data[0]);

    const vehicleDepositor = row['vehiclesDepositor'];
    const priorVisualization =
      row['dockVehicles'].length > 0
        ? row['dockVehicles'][0]['priorVisualization']
        : [];
    this.gridVehicleDepositor.selected = vehicleDepositor;
    this.gridDockDepositor.selected = [priorVisualization];

    // this.dockVehicleCompatibility = row;

    this.formDockVehicles
      .get('vehicleTypeId')
      .setValue(vehicleDepositor.map(v => v.id));
    this.formDockVehicles
      .get('priorVisualizationId')
      .setValue(priorVisualization.id);
  }

  setDepositorOnUpdate() {
    this.formDockVehicles
      .get('depositor')
      .setValue(
        this.depositors.find(
          value => value.id == this.depositorsListAfterInsert.id
        )
      );
    this.depositorSelected(this.depositorsListAfterInsert);
  }

  setEstablishmentOnUpdate() {
    const establishment = this.establishments.find(
      value => value.id == this.establishmentListAfterInsert.id
    );
    
    

    this.formDockVehicles.get('establishment').setValue(establishment);
    this.establishmentChangedOnUpdate(establishment);
  }

  establishmentChangedOnUpdate(establishment: Establishment) {
    
    if (establishment) {
      this.formDockVehicles.get('establishmentId').setValue(establishment.id);
      this.establishmentID = establishment.id.toString();
      this.subscriptions.push(
        this._depositorService
          .findByEstablishmentId(establishment.id)
          .subscribe(data => {
            if (data && data.body['content'].length === 1) {
              const depositor = data.body['content'][0];
              this.formDockVehicles.get('depositor').setValue(depositor);
              this.depositorSelected(depositor);
            }
            this.depositors = [...data.body['content']];
            this.setDepositorOnUpdate();
          })
      );
    } else {
      this.depositors = [];
      this.formDockVehicles.get('depositor').setValue([]);
    }
  }

  delete(row) {
    
    this._dockVehicleService.deleteById(row.id).subscribe(() => {
      this.gridDepositorVehicleCompatibility.reloadPage();
      this.depositorID = '-1';

      setTimeout(() => {
        this.gridDockDepositor.reloadPage();
        this.gridDockDepositor.clearData();

        this.gridVehicleDepositor.reloadPage();
        this.gridVehicleDepositor.clearData();
      }, 1);

      this.formDockVehicles.reset();
      this.inputEstablishment.items = this.establishments;
    });
  }

  endPointDocaVehicles = () =>
    SDM_URL.docks.dockVehicles.findBySdmDock.replace(
      ':sdmDockId',
      this.sdmDockId
    );

  endPointEstablishments = () => SDM_URL.establishment.default;

  endPointVehicleDepositorCompatiblity = () =>
    SDM_URL.docks.dockVehicleCompatibility.default;

  endPointDocasDepositor = () =>
    SDM_URL.docks.priorVisualization.findByDepositorIdAndStatusTrue.replace(
      ':depositorId',
      this.depositorID
    );

  endPointDepositors = () =>
    SDM_URL.depositor.findDepositorsByEstablishmentId.replace(
      ':establishmentId',
      this.establishmentID
    );

  endPointVehiclesDepositor = () =>
    SDM_URL.vehicle.depositor.findByDepositorAndstatusTrue.replace(
      ':depositorId',
      this.depositorID
    );
}
