import { take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReverseDeliveryManualAllocation } from '../../../../models/sdm/schedules/reverse-delivery/manual-allocation';
import { AbstractService } from '../../../abstract.service';
import { environment } from './../../../../../../environments/environment';
import { SDM_URL } from './../../../../../app.api';

@Injectable({
  providedIn: 'root'
})
export class ManualAllocationService extends AbstractService<
  ReverseDeliveryManualAllocation
> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.schedules.reverseDelivery.manualAllocation.default
    );
  }

  updateScheduleToConflict(manualAllocation: ReverseDeliveryManualAllocation) {
    return this.http.put(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation
          .updateManualAllocationWithConflict
      }`,
      manualAllocation
    );
  }

  createWithConflict(
    schedule: ReverseDeliveryManualAllocation
  ): Observable<ReverseDeliveryManualAllocation> {
    return this.http.post<ReverseDeliveryManualAllocation>(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation.createWithConflict
      }`,
      schedule
    );
  }

  updateManualAllocation(manualAllocation: ReverseDeliveryManualAllocation) {
    return this.http.put(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation
          .updateManualAllocation
      }`,
      manualAllocation
    );
  }

  validateRecurrentSchedule(manualAllocation: ReverseDeliveryManualAllocation) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation
          .validateRecurrentSchedule
      }`,
      manualAllocation,
      { headers: httpOptions, observe: 'response' }
    );
  }

  validateRecurrentScheduleOnUpdate(
    manualAllocation: ReverseDeliveryManualAllocation
  ) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation
          .validateRecurrentScheduleOnUpdate
      }`,
      manualAllocation,
      { headers: httpOptions, observe: 'response' }
    );
  }

  createReverseRecurrent(manualAllocation: ReverseDeliveryManualAllocation) {
    return this.http.post(
      `${
        SDM_URL.schedules.reverseDelivery.manualAllocation
          .createReverseRecurrent
      }`,
      manualAllocation
    );
  }

  findByRecurrent(deliveryAuthorizationId: number): Observable<any> {
    return this.http.get<any>(
      `${this.host}${
        this.url
      }/delivery-authorization/${deliveryAuthorizationId}/recurrent`
    );
  }

  findByReverseSearchExport(queryString: string): Observable<Blob> {
    return this.http
      .get(
        `${
          SDM_URL.schedules.reverseDelivery.manualAllocation
            .findByReverseSearchExport
        }?${queryString}`,
        { responseType: 'blob' }
      )
      .pipe(take(1));
  }
}
