import { AbstractStatusModel } from '../../abstractStatus.model';
import { IDeserializable } from '../../deserializable.model';

export class RegularDelivery extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public manualTypeDoc: boolean;
  public regularDeliveryDepositor: boolean;
  public providerSolicitAuthorization: boolean;
  public uniqueShippingSolicitAuthorization: boolean;
  public minAntecedenceShippingRegDelivery: number;
  public maxAntecedenceShippingRegDelivery: number;

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

  constructor() {
    super();
    this.status = true;
    this.manualTypeDoc = false;
  }
}
