import { Observable } from 'rxjs';
import { environment as env } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegularOperationgDays } from './../../../models/sdm/docks/regular-operationg-days';
import { AbstractService } from '../../abstract.service';
import { SDM_URL } from '../../../../app.api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegularOperatingDaysService extends AbstractService<RegularOperationgDays> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.regularOperatingDays.default);
  }

  findByEstablishmentId(establishmentId: number): Observable<RegularOperationgDays> {
    return this.http.get<RegularOperationgDays>(`${SDM_URL.docks.regularOperatingDays.findByEstablishmentId.replace(':establishmentId', establishmentId.toString())}`);
  }

}
