import { DeliveryAuthorization } from './../../core/models/sdm/delivery-authorization/delivery-authorization';
import { FileUploader, FilterFunction } from 'ng2-file-upload';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-nfe',
  templateUrl: './import-nfe.component.html',
  styleUrls: ['./import-nfe.component.scss'],
  preserveWhitespaces: true
})
export class ImportNfeComponent implements OnInit, OnChanges {
  @Output() uploadSuccessEvent = new EventEmitter();
  @Output() uploadErrorEvent = new EventEmitter();

  @Input() filters: FilterFunction;
  @Input() btnValue = 'Importar XML';
  @Input() accept = 'text/xml';

  @Input()
  set objAdditionalParameters(obj: object) {
    this._objAdditionalParameters = obj;
  }

  get objAdditionalParameters() {
    return this._objAdditionalParameters;
  }

  @Input()
  set deliveryAuthorization(deliveryAuthorization: DeliveryAuthorization) {
    this._deliveryAuthorization = deliveryAuthorization;
  }

  get deliveryAuthorization() {
    return this._deliveryAuthorization;
  }

  constructor(
    private spinner: NgxSpinnerService,
    public toaster: ToastrService
  ) {}

  private _endpoint = '';
  private _objAdditionalParameters = {};
  private _deliveryAuthorization;

  @Input()
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  uploader: FileUploader = new FileUploader({
    url: `${this._endpoint}`,
    autoUpload: false,
    headers: [{ name: 'Authorization', value: localStorage.getItem('token') }],
    queueLimit: 1,
    itemAlias: 'file',
    isHTML5: true
  });

  ngOnInit() {
    this.uploader.onBeforeUploadItem = item => {
      this.spinner.show();
    };

    this.uploader.options.filters.push(this.filters);

    this.uploader.onAfterAddingFile = item => {
      item.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      if (response !== undefined && /201|204/.test(status)) {
        this.uploader.queue.forEach(fileItem => {
          fileItem.remove();
        });

        this.uploadSuccessEvent.emit({
          response: response ? JSON.parse(response) : null
        });
      } else if (/400|409|500|404/g.test(status)) {
        let data = null;
        try {
          data = JSON.parse(response);
        } catch (e) {
          data = response;
        }
        this.uploadErrorEvent.emit({ data, status });
      }

      this.spinner.hide();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const objAdditional: SimpleChange = changes.objAdditionalParameters;
    this.objAdditionalParameters = objAdditional.currentValue;

    const {
      depositorId,
      providerId,
      nfSolicit,
      orderNumber,
      deliveryAuthorizationId,
      documentShipping
    } = objAdditional.currentValue;

    this.uploader.setOptions({
      additionalParameter: {
        depositorId,
        providerId,
        nfSolicit,
        orderNumber,
        deliveryAuthorizationId,
        documentShipping
      }
    });

    this.uploader.setOptions({ url: this._endpoint });
  }
}
