import { RegularDeliveryEvents } from './../../regular-delivery.service';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { RegularDeliverySmallSizeService } from './../../../../../core/services/sdm/regular-delivery/regular-delivery-small-size.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RegularDeliverySmallSize } from '../../../../../core/models/sdm/regular-delivery/regular-delivery-small-size';
import { BaseFormComponent } from '../../../../../shared/base-form/base-form.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EstablishmentService } from '../../../../../core/services/establishment.service';
import { SDM_URL } from '../../../../../app.api';
import { DepositorService } from '../../../../../core/services/depositor.service';
import { delay, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractDelivery } from '../../abstract-delivery';

@Component({
  selector: 'app-regular-delivery-small-size-form',
  templateUrl: './regular-delivery-small-size-form.component.html',
  styleUrls: ['./regular-delivery-small-size-form.component.scss'],
})
export class RegularDeliverySmallSizeFormComponent extends AbstractDelivery<RegularDeliverySmallSize> implements OnInit {

  depositors = [];
  sub: Subscription;
  deliveryInSpecific = false;
  regularDeliverySmallSize: RegularDeliverySmallSize;
  @ViewChild('dataGrdSmallSize') grid: DatatableApiComponent;
  options = [
    { name: 'regular-delivery.small-size.options.true', value: true },
    { name: 'regular-delivery.small-size.options.false', value: false }
  ];

  optionsDocaNumber = [
    { name: 'regular-delivery.small-size.options.doca1', value: "Doca 01" },
    { name: 'regular-delivery.small-size.options.doca2', value: "Doca 02" }
  ];

  columnsSmallSize = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'depositorDeliverySmallSize', name: 'Depositante permite entregas Pequeno Porte SEM Agendamento?', pipe: new BooleanConverter() },
    { prop: 'maxDurationDeliveryInMinutes', name: 'Duração Máxima da Entrega (em minutos)' },
    { prop: 'specificDelivery', name: 'Entrega em Doca Específica (Fixa)?', pipe: new BooleanConverter() },
    { prop: 'docaNumber', name: '# Doca' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _regularDeliverySmallSizeService: RegularDeliverySmallSizeService,
    public regularDeliveryServiceEvents: RegularDeliveryEvents) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.tabSmallSizeFired();

    this.regularDeliverySmallSize = new RegularDeliverySmallSize();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorDeliverySmallSize: new FormControl(''),
      maxDurationDeliveryInMinutes: new FormControl('', [Validators.min(1), Validators.max(999)]),
      specificDelivery: new FormControl(''),
      docaNumber: new FormControl(''),
    });
  }

  tabSmallSizeFired() {
    this.sub = this.regularDeliveryServiceEvents.emitTabSmallSizeFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.get('docaNumber').disable();
  }

  depositorChange(event) {
    
    if (this.formulario.get('depositorDeliverySmallSize').value === 'true') {
      this.setRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
      this.enableFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
      return;
    }
    this.removeRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
    this.disableFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
    // this.deliveryInSpecific = (event.target.value === 'true');
        // this.enableOrDisableDocaNumber();
  }

  enableOrDisableDocaNumber() {
    if (this.formulario.get('depositorDeliverySmallSize').value === 'true') {
      this.setRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
      this.enableFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
    } else {
      this.removeRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
      this.disableFields(['maxDurationDeliveryInMinutes', 'specificDelivery', 'docaNumber']);
    }

    if (this.formulario.get('specificDelivery').value === 'false') {
      this.formulario.get('docaNumber').setValue('');
      this.formulario.get('docaNumber').disable();
    } else {
      this.formulario.get('docaNumber').enable();
    }
  }

  isRequiredDockNumber = () => this.formulario.get('specificDelivery').value === 'true';

  deliverySpecificDock(event) {
    if (this.formulario.get('specificDelivery').value === 'true') {
      this.setRequiredForFields(['docaNumber']);
      this.enableFields(['docaNumber']);
      return;
    }
    this.removeRequiredForFields(['docaNumber']);
    this.disableFields(['docaNumber']);

    if (this.formulario.get('specificDelivery').value === 'false') {
      this.formulario.get('docaNumber').setValue('');
      this.formulario.get('docaNumber').disable();
    } else {
      this.formulario.get('docaNumber').enable();
    }
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.enableOrDisableDocaNumber();
  }

  makeEndPointForSmallSize = () => SDM_URL.regularDelivery.smallSize.default;

  getComponentName(): string {
    return 'regular-delivery.small-size';
  }

  getNewInstance(): RegularDeliverySmallSize {
    return new RegularDeliverySmallSize();
  }
  getService(): AbstractService<RegularDeliverySmallSize> {
    return this._regularDeliverySmallSizeService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  validateNumbersInputs(event) {
    let inputValue = event.target.value;
    if (inputValue < 0 || (!(inputValue % 1 === 0)) || inputValue.length > 4) {
      this.formulario.get('maxDurationDeliveryInMinutes').setErrors({ pattern: true });
    }
    else {
      this.formulario.get('maxDurationDeliveryInMinutes').clearValidators();
      this.formulario.get('maxDurationDeliveryInMinutes').updateValueAndValidity();
    }
  }

}
