import { SDM_URL } from './../../../../../../app.api';
import { CompatibilityProviderService } from './../../../../../../core/services/sdm/provider-management/groups-compatibility/compatibility-provider.service';
import { DepositorService } from './../../../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../../../components/datatable-api/datatable-api.component';
import { CompatibilityProvider } from './../../../../../../core/models/sdm/provider-management/compatibility-provider';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractRegister } from '../../../../abstract-register';
import { AbstractService } from '../../../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompatibilityProviderGroups } from '../../../../../../core/models/sdm/provider-management/compatibility-provider-groups';

@Component({
  selector: 'app-compatibility-provider-form',
  templateUrl: './compatibility-provider-form.component.html',
  styleUrls: ['./compatibility-provider-form.component.scss'],
})
export class CompatibilityProviderFormComponent extends AbstractRegister<CompatibilityProvider> implements OnInit {

  selectedProductivityGroups = [];
  compatibilityProviderId = null;
  @ViewChild('gridCompatibilityProvider') grid$: DatatableApiComponent;
  @ViewChild('gridCompatibilityProviderGroups') gridGroups$: DatatableApiComponent;
  @ViewChild('gridProductivityGroup') gridProductivityGroup$: DatatableApiComponent;

  @ViewChild('hdrTplActiveDeactive') hdrTplActiveDeactive: TemplateRef<any>;
  @ViewChild('bodyTplActiveDeactive') bodyTplActiveDeactive: TemplateRef<any>;

  columnsCompatibilityProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
  ];

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
  ];

  columnsProductivityGroup = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'groupName', name: 'Nome do Grupo', width: 250 },
  ];

  columnsProductivityGroupCompatProvider: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'productivityGroup.groupName', name: 'Nome do Grupo', width: 250 },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    public _compatibilityProviderService: CompatibilityProviderService) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this.columnsProductivityGroupCompatProvider.splice(5, 0, { name: 'Ação', cellTemplate: this.bodyTplActiveDeactive, headerTempalte: this.hdrTplActiveDeactive, width: 80 });

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      document: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      providerGroups: new FormControl(''),
    });
  }

  submit() {
    if (this.formulario.get('id').value == null) {
      this.getService().create(this.formulario.value)
        .subscribe(data => {
          this.formulario.patchValue(data);
          this.disabledForm();
          this.grid$.reloadPage();
          this.showMessageActions();
          this.compatibilityProviderId = data.id;
          setTimeout(() => this.gridGroups$.reloadPage(), 1);
          this.gridProductivityGroup$.selected = [];
          this.selectedProductivityGroups = [];
        });
    } else {
      this.getService().edit(this.formulario.value)
        .subscribe(() => {
          this.disabledForm();
          this.grid$.reloadPage();
          this.showMessageActions('success', 'update');
          this.compatibilityProviderId = this.formulario.get('id').value;
          setTimeout(() => this.gridGroups$.reloadPage(), 1);
          this.gridProductivityGroup$.selected = [];
          this.selectedProductivityGroups = [];
        });
    }
  }

  resetar() {
    this.formulario.reset();
    this.formulario.disable();
    this.formEnabled = false;
    this.clearGridData();
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.clearGridData();
  }

  clearGridData() {
    this.compatibilityProviderId = -1;
    setTimeout(() => this.gridGroups$.reloadPage(), 1);
    this.gridProductivityGroup$.selected = [];
    this.selectedProductivityGroups = [];
  }

  selectedProdGroup(selected) {
    this.selectedProductivityGroups = selected;
    this.formulario.get('providerGroups').setValue(this.selectedProductivityGroups.map(sel => new CompatibilityProviderGroups(sel.id, this.compatibilityProviderId)));
  }

  formFillToEdit(row) {
    
    this.formulario.patchValue(row);
    if (row.hasOwnProperty('depositor') && row['depositor'].hasOwnProperty('establishment')) {
      if (this.formulario.get('depositorId') && this.formulario.get('establishmentId')) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario.get('establishmentId').setValue(row['depositor']['establishment'].id);
      }
    }

    // this.formulario.patchValue(row);
    this.formulario.enable();
    this.compatibilityProviderId = row.id;
    setTimeout(() => this.gridGroups$.reloadPage(), 1);
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    if (row.hasOwnProperty('depositor') && row['depositor'].hasOwnProperty('establishment')) {
      if (this.formulario.get('depositorId') && this.formulario.get('establishmentId')) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario.get('establishmentId').setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.disable();
    this.compatibilityProviderId = row.id;
    setTimeout(() => this.gridGroups$.reloadPage(), 1);
  }

  actInativeGroup(row) {
    const { id } = row;
    this._compatibilityProviderService.activeDeactiveGroup(this.compatibilityProviderId, id)
      .subscribe(data => this.gridGroups$.reloadPage());
  }

  endPointCompatibilityProvider = () => SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.default;
  endpointUpload = () => SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.upload;
  endPointPartial = () => SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.importPartial;
  endPointGetAllGroups = () => SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.getAllGroups.replace(':compatibilityProviderId', this.compatibilityProviderId);
  endPointGroup = () => SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.activeDeactiveGroup.replace(':compatibilityProviderId', this.compatibilityProviderId).replace(':compatibilityProviderGroupId', '');

  queryProviderDocument = () => SDM_URL.providerManagement.priorityProviders.findByDocument.replace(':document', '');
  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  endPointProductivityGroup = () => SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.default;

  getNewInstance(): CompatibilityProvider {
    return new CompatibilityProvider();
  }
  getService(): AbstractService<CompatibilityProvider> {
    return this._compatibilityProviderService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.groups-compatibility.compatibility-provider';
  }

}
