import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SlotPattern } from '../../models/sdm/slot/slot-pattern';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { AbstractService } from '../abstract.service';

@Injectable()
export class SlotPatternService extends AbstractService<SlotPattern> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/slot');
  }

  findByEstablishmentIdAndStatusTrue(
    establishmentId: number
  ): Observable<SlotPattern> {
    return this.http.get<SlotPattern>(
      `${env.host.sdm}${this.url}/establishment/${establishmentId}`
    );
  }

  // create(model: SlotPattern): Observable<SlotPattern> {
  //   return this.http.post<SlotPattern>(`${env.host.sdm}${this.URL}`, model)
  // }

  // edit(model: SlotPattern): Observable<HttpResponse<SlotPattern>> {
  //   return this.http.put<SlotPattern>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
  // }

  // findById(id: number): Observable<SlotPattern> {
  //   throw new Error("Method not implemented.");
  // }

  // findAll(): Observable<HttpResponse<SlotPattern>> {
  //   throw new Error("Method not implemented.");
  // }

  // activeDeactive(id: number): Observable<SlotPattern> {
  //   throw new Error("Method not implemented.");
  // }
}
