import { SDM_URL } from './../../../../app.api';
import { environment as env } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DocksOptions } from './../../../models/sdm/docks/dock-options';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class DocksOptionsService extends AbstractService<DocksOptions> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.docksOptions.default);
  }
}
