import { AbstractStatusModel } from '../../abstractStatus.model';

export class CriticalItems extends AbstractStatusModel {

  public EstablishmentId: number;
  public depositorId: number;
  public depositorDocument: string;
  public document: string;
  public skuProvider: string;
  public skuDepositor: string;
  public skuDescription: string;
  public validityIni: string;
  public validityFin: string;
  public observation: string;

  constructor() {
    super();
  }

}
