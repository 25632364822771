import { AgentTypeDepositor } from './../../models/agent-type-depositor/agent-type-depositor';
import { SDM_URL } from './../../../app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AbstractService } from '../abstract.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentTypeDepositorService extends AbstractService<
  AgentTypeDepositor
> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.agentTypeDepositor.default);
  }

  findByDepositorAndSolution(
    depositorId: number,
    solutionId: number
  ): Observable<AgentTypeDepositor[]> {
    return this.http
      .get<AgentTypeDepositor[]>(
        `${SDM_URL.agentTypeDepositor.findByDepositorAndSolution
          .replace(':depositorId', depositorId.toString())
          .replace(':solutionId', solutionId.toString())}`
      )
      .pipe(take(1));
  }
}
