import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../../app.api';
import { environment } from './../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompatibilityProvider } from './../../../../models/sdm/provider-management/compatibility-provider';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class CompatibilityProviderService extends AbstractService<CompatibilityProvider>  {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.default);
  }

  activeDeactiveGroup(compatibilityProviderId: string, compatibilityProviderGroupId: string): Observable<any> {
    return this.http.delete<any>(`${SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.activeDeactiveGroup.replace(':compatibilityProviderId', compatibilityProviderId).replace(':compatibilityProviderGroupId', compatibilityProviderGroupId)}`, { observe: 'response' });
  }

  findByDocumentAndUserIdStatusTrue(document: string, userId: number): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${SDM_URL.providerManagement.groupsCompatibility.compatibilityProvider.findByDocumentAndUserIdStatusTrue.replace(':document', document).replace(':userId', userId.toString())}`);
  }
}
