import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuService } from '../core/menu/menu.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    @ViewChild('sidebar') sidebar: SidebarComponent

    constructor(public menuService: MenuService) {

    }

    ngOnInit() {
    }

}
