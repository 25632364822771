import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocksService {

  emitEventTabRelationShipFired = new EventEmitter();
  emitEventTabVisualizationFired = new EventEmitter();
  emitEventTabCompatibilityFired = new EventEmitter();
  emitEventTabVehicleCompatibilityFired = new EventEmitter();

  tabHolidayRelationShip = () => this.emitEventTabRelationShipFired.emit();
  tabPriorVisualization = () => this.emitEventTabVisualizationFired.emit();
  tabDockCompatibility = () => this.emitEventTabCompatibilityFired.emit();
  tabDockVehicleCompatibility = () => this.emitEventTabVehicleCompatibilityFired.emit();

}
