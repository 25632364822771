import { PendingHandlingRegularDelivery } from './../../../models/sdm/handling/pending-handling-regular-delivery';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';

@Injectable({
  providedIn: 'root'
})
export class PendingHandlingRegularDeliveryService extends AbstractService<
  PendingHandlingRegularDelivery
> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.handling.pendingHandling.default);
  }
}
