import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuardService as AuthGuard } from '../security/auth-guard.service';
import { NotFoundComponent } from './default/not-found/not-found.component';
import { SbawHomeComponent } from './home/sbaw-home/sbaw-home.component';

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: './user-login/user-login.module#UserLoginModule'
  },
  {
    path: 'sdm',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [AuthGuard],

        loadChildren: './home/home.module#HomeModule' },
      {
        path: 'register',
        canActivate: [AuthGuard],

        loadChildren: './register/register.module#RegisterModule'
      },
      {
        path: 'parameters',
        canActivate: [AuthGuard],

        loadChildren:
          './parametrizations/parametrization.module#ParametrizationModule'
      },
      {
        path: 'delivery-authorization',
        canActivate: [AuthGuard],

        loadChildren:
          './delivery-authorization/delivery-authorization.module#DeliveryAuthorizationModule'
      },
      {
        path: 'dock-management',
        canActivate: [AuthGuard],

        loadChildren:
          './dock-management/dock-management/dock-management.module#DockManagementModule'
      },
      {
        path: 'sdm-user-domain',
        canActivate: [AuthGuard],

        loadChildren:
          './sdm-user-domain/sdm-user-domain.module#SdmUserDomainModule'
      },
      {
        path: 'management-conflicts',
        canActivate: [AuthGuard],

        loadChildren:
          './management-conflict/management-conflict.module#ManagementConflictModule'
      },
      { path: '**', component: NotFoundComponent }
    ]
  },
  {
    path: 'sbaw',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [AuthGuard],

        component: SbawHomeComponent
      },
      {
        path: 'importEdi',

        canActivate: [AuthGuard],
        loadChildren: './import/import.module#ImportModule'
      },
      {
        path: 'overview',
        canActivate: [AuthGuard],

        loadChildren: './overview/overview.module#OverviewModule'
      },
      {
        path: 'filters',
        canActivate: [AuthGuard],

        loadChildren: './filters/filters.module#FiltersModule'
      },
      {
        path: 'picking-strategy',
        canActivate: [AuthGuard],

        loadChildren:
          './picking-strategy/picking-strategy.module#PickingStrategyModule'
      },
      {
        path: 'export-miwa',
        canActivate: [AuthGuard],

        loadChildren:
          './processed-sets/processed-sets.module#ProcessedSetModule'
      },
      {
        path: 'edi-files',
        canActivate: [AuthGuard],

        loadChildren: './edi-files/edi-files.module#EdiFileModule'
      },
      {
        path: 'set-management',
        canActivate: [AuthGuard],

        loadChildren:
          './set-management/set-management.module#SetManagementModule'
      },
      {
        path: 'order-management',
        canActivate: [AuthGuard],

        loadChildren:
          './order-management/order-management.module#OrderManagementModule'
      },
      {
        path: 'gav-sbaw',
        canActivate: [AuthGuard],

        loadChildren:
          './gav-sbaw/gav-sbaw.module#GavSbawModule'
      },
      {
        path: 'region-parametrization',
        canActivate: [AuthGuard],

        loadChildren:
          './region-parametrization/region-parametrization.module#RegionParametrizationModule'
      },
      {
        path: 'size-parametrization',
        canActivate: [AuthGuard],

        loadChildren:
          './size-parametrization/size-parametrization.module#SizeParametrizationModule'
      },
      {
        path: 'sbaw-user-domain',
        canActivate: [AuthGuard],

        loadChildren:
          './sbaw-user-domain/sbaw-user-domain.module#SbawUserDomainModule'
      },
      {
        path: 'settings-parametrization',
        canActivate: [AuthGuard],

        loadChildren:
          './settings-parametrization/settings-parametrization.module#SettingsParametrizationModule'
      },
      {
        path: 'color-code-parametrization',
        canActivate: [AuthGuard],

        loadChildren:
          './color-code-parametrization/color-code-parametrization.module#ColorCodeParametrizationModule'
      },
      { path: '**', component: NotFoundComponent }
    ]
  },
  {
    path: 'gateway',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',

        pathMatch: 'full'
      },
      {
        path: 'home',
        canActivate: [AuthGuard],

        loadChildren: './gateway/gateway.module#GatewayModule'
      },
      {
        path: 'user-domain',
        canActivate: [AuthGuard],

        loadChildren: './user-domain/user-domain.module#UserDomainModule'
      },
      {
        path: 'smart-domain',
        canActivate: [AuthGuard],

        loadChildren: './smart-domain/smart-domain.module#SmartDomainModule'
      },
      {
        path: 'mailer',
        canActivate: [AuthGuard],

        loadChildren: './mailer/mailer.module#MailerModule'
      },
      {
        path: 'blacklist-vip-replace',
        canActivate: [AuthGuard],

        loadChildren:
          './blacklist-vip-replace/blacklist-vip-replace.module#BlacklistVipReplaceModule'
      },
      {
        path: 'picking-sequence-replace',

        loadChildren:
          './picking-sequence-replace/picking-sequence-replace.module#PickingSequenceReplaceModule'
      },
      {
        path: 'product-replace',
        canActivate: [AuthGuard],

        loadChildren:
          './product-replace/product-replace.module#ProductReplaceModule'
      },
      {
        path: 'management-supervisor',
        canActivate: [AuthGuard],

        loadChildren:
          './management-supervisor/management-supervisor.module#ManagementSupervisorModule'
      },
      {
        path: 'signature',
        canActivate: [AuthGuard],

        loadChildren: './gateway/signature.module#SignatureModule'
      },
      {
        path: 'agent-type',
        canActivate: [AuthGuard],

        loadChildren: './gateway/agent-type.module#AgentTypeModule'
      },
      {
        path: 'email-sms-agent',
        canActivate: [AuthGuard],

        loadChildren: './gateway/email-sms-agent.module#EmailSmsAgentModule'
      },
      {
        path: 'vip-blacklist-agent',
        canActivate: [AuthGuard],

        loadChildren: './gateway/vip-blacklist-agent.module#VipBlacklistAgentModule'
      },
      {
        path: 'establishment',
        canActivate: [AuthGuard],

        loadChildren: './gateway/establishment.module#EstablishmentModule'
      },
    ]
  },

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'gateway', pathMatch: 'full' },
    ]
  },
  { path: '**', component: NotFoundComponent }
];
