import { AbstractStatusModel } from '../../abstractStatus.model';
import { IDeserializable } from '../../deserializable.model';

export class SlotReserve extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public depositorId: number;

  public useFirstReserveType = true;
  public updateAutoForAvailableFirst: boolean;
  public daysForUpdateFirst: number;
  public availableTypeFirst: number;
  public availableDeliveryExtFirst = true;

  public useSecondReserveType: boolean;
  public updateAutoForAvailableSecond: boolean;
  public daysForUpdateSecond: number;
  public availableTypeSecond: number;
  public availableDeliveryExtSecond = false;

  constructor() {
    super();
    this.useFirstReserveType = true;
    this.updateAutoForAvailableFirst = false;
    this.availableDeliveryExtFirst = true;
    this.useSecondReserveType = false;
    this.availableDeliveryExtSecond = false;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
