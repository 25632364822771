import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DeliveryType } from './../../../../../core/models/sdm/shared/enums/DeliveryType';
import { RegularDeliveryNfService } from './../../../../../core/services/sdm/regular-delivery/regular-delivery-nf.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { SDM_URL } from './../../../../../app.api';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RegularDeliveryNF } from '../../../../../core/models/sdm/regular-delivery/regular-delivery-nf';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { RegularDeliveryEvents } from '../../regular-delivery.service';
import { Subject, Subscription } from 'rxjs';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractDelivery } from '../../abstract-delivery';

@Component({
  selector: 'app-regular-delivery-nf-form',
  templateUrl: './regular-delivery-nf-form.component.html',
  styleUrls: ['./regular-delivery-nf-form.component.scss'],
})
export class RegularDeliveryNfFormComponent extends AbstractDelivery<RegularDeliveryNF> implements OnInit {

  public keys;
  depositors = [];
  sub: Subscription;
  unsub$ = new Subject();
  registerNoShow = false;
  deliveryType = DeliveryType;
  regularDeliveryNF: RegularDeliveryNF;
  @ViewChild('grdDataNfDeadline') grid: DatatableApiComponent;
  options = [{ name: 'regular-delivery.nf-deadlines.options.true', value: true }, { name: 'regular-delivery.nf-deadlines.options.false', value: false }];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _regDeliveryNfDeadlineService: RegularDeliveryNfService,
    public regularDeliveryServiceEvents: RegularDeliveryEvents) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.deliveryType);
  }

  columnsNF = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'autoNoShowRegister', name: 'No Show Automático?', pipe: new BooleanConverter() },
    { prop: 'afterQtdTime', name: 'Após quanto tempo (min)?' },
    { prop: 'allowUpdateRegister', name: 'Permite Alterar Registro?', pipe: new BooleanConverter() },
    { prop: 'onlyInZeroDay', name: 'Somente em D0?', pipe: new BooleanConverter() },
    { prop: 'maxDeadlineDelivery', name: 'Prazo Máximo Entrega' },
    { prop: 'minConfirmNewAuthorizationExternalUser', name: 'Antecedência Mínima p/ Agendamento Usuário externo' },
    { prop: 'minReschedulingConfirmExternalUser', name: 'Antecedência Mínima p/ Reagendamento Usuário externo' },
    { prop: 'minCancelRequestExternalUser', name: 'Antecedência Mínima p/ Cancelamento Usuário Externo' },
  ];

  ngOnInit() {
    this.tabNfDeadLinesFired();

    this.regularDeliveryNF = new RegularDeliveryNF();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      minDeadLineRequired: new FormControl('', [Validators.min(1), Validators.max(999)]),
      maxDeadlineDelivery: new FormControl(''),
      maxExpirationRequestDeadLine: new FormControl(''),
      maxExpirationRequestMinAntecedence: new FormControl(''),
      autoCancelMaxDeadLine: new FormControl(''),
      autoCancelMinAntecedence: new FormControl(''),
      minConfirmNewAuthorizationExternalUser: new FormControl(''),
      minConfirmNewAuthorizationInternalUser: new FormControl(''),
      minReschedulingConfirmExternalUser: new FormControl(''),
      minReschedulingConfirmInternalUser: new FormControl(''),
      minCancelRequestExternalUser: new FormControl(''),
      minCancelRequestInternalUser: new FormControl(''),
      autoNoShowRegister: new FormControl(''),
      afterQtdTime: new FormControl(''),
      allowUpdateRegister: new FormControl(''),
      onlyInZeroDay: new FormControl(''),
      requirePasswdSupervisor: new FormControl(''),
    });
  }

  // ngOnDestroy(): void {
  //   this.unsub$.next();
  //   this.unsub$.complete();
    // }

  tabNfDeadLinesFired() {
    this.sub = this.regularDeliveryServiceEvents.emitTabNfDeadLinesFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.get('afterQtdTime').disable();
    this.formulario.get('allowUpdateRegister').disable();
    this.formulario.get('onlyInZeroDay').disable();
    this.formulario.get('requirePasswdSupervisor').disable();
  }

  changeRegisterNoShow(event) {
    this.registerNoShow = (event.target.value === 'true');
    this.enableDisableFields();
  }

  enableDisableFields() {
    if (!this.registerNoShow) {
      this.formulario.get('afterQtdTime').disable();
      this.formulario.get('afterQtdTime').setValue('');
      this.formulario.get('allowUpdateRegister').disable();
      this.formulario.get('allowUpdateRegister').setValue(false);
      this.formulario.get('onlyInZeroDay').disable();
      this.formulario.get('onlyInZeroDay').setValue(false);
      this.formulario.get('requirePasswdSupervisor').disable();
      this.formulario.get('requirePasswdSupervisor').setValue(false);
    } else {
      this.formulario.get('afterQtdTime').enable();
      this.formulario.get('allowUpdateRegister').enable();
      this.formulario.get('onlyInZeroDay').enable();
      this.formulario.get('requirePasswdSupervisor').enable();
    }
  }

  allowUpdateRegisterChange(event) {
    if (this.formulario.get('allowUpdateRegister').value === 'true') {
      this.setRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      this.enableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      return;
    }
    this.removeRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
    this.disableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
  }

  makeAndPointForNfDeadlines = () => SDM_URL.regularDelivery.nfDeadlines.default;

  getComponentName(): string {
    return 'regular-delivery.nf-deadlines';
  }

  getNewInstance(): RegularDeliveryNF {
    return new RegularDeliveryNF();
  }
  getService(): AbstractService<RegularDeliveryNF> {
    return this._regDeliveryNfDeadlineService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  validateInputs(event, inputNameGet) {
    let inputValue = event.target.value;
    let inputName = inputNameGet;

    if (inputValue < 0 || (!(inputValue % 1 === 0)) || inputValue.length > 3) {
      this.formulario.get(inputName).setErrors({ pattern: true });
    }
    else {
      this.formulario.get(inputName).clearValidators();
      this.formulario.get(inputName).updateValueAndValidity();
    }
  }

  validateAfterQtdTime(event) {
    let inputValue = event.target.value;

    if (inputValue < 0 || inputValue.length > 3) {
      this.formulario.get('afterQtdTime').setErrors({ pattern: true });
    }
    else {
      this.formulario.get('afterQtdTime').clearValidators();
      this.formulario.get('afterQtdTime').updateValueAndValidity();
    }
  }

}
