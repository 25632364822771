import { Component, OnInit } from '@angular/core';
import { Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-sbaw-home',
  templateUrl: './sbaw-home.component.html',
  styleUrls: ['./sbaw-home.component.scss']
})
export class SbawHomeComponent implements OnInit {

  constructor(public router: Router) {

  }

  ngOnInit() {

  }

}
