import { DocksOperation } from './../../../../../core/models/sdm/dock-management/docks-operation';
import { DocksOperationService } from './../../../../../core/services/sdm/dock-management/docks-operation.service';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  Renderer,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment/moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StatusSolicitationPipe } from '../../../../../core/classes/status-solicitation.pipe';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { SchedulesConflictDTO } from '../../../../../core/models/sdm/schedules/schedules-conflict-dto';
import { SlotPattern } from '../../../../../core/models/sdm/slot/slot-pattern';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { PriorVisualizationService } from '../../../../../core/services/sdm/docks/prior-visualization.service';
import { ProviderService } from '../../../../../core/services/sdm/provider-management/provider.service';
import { SignatureService } from '../../../../../core/services/signature.service';
import { SlotPatternService } from '../../../../../core/services/slot/slot-pattern.service';
import { VehicleDepositorService } from '../../../../../core/services/vehicle-depositor.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { AbstractSchedulesSlots } from '../../abstract-schedules-slots';
import { ValidatorDayOfMonth, validatorFinHour } from '../../shared/utils';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { ModalCustomComponent } from './../../../../../components/modal-custom/modal-custom.component';
import { SelectFieldComponent } from './../../../../../components/select/select-field/select-field.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DateFormatPipe } from './../../../../../core/classes/datePipe';
import { DateTimeFormatPipe } from './../../../../../core/classes/dateTimePipe';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { Establishment } from './../../../../../core/models/sdm/establishment';
import { CollectSchedules } from './../../../../../core/models/sdm/schedules/collect-schedules';
import { AuthService } from './../../../../../core/services/auth.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';
import { DeliveryAuthorizationService } from './../../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { DocksSchedulingService } from './../../../../../core/services/sdm/document/docks-scheduling.service';
import { CollectSchedulesService } from './../../../../../core/services/sdm/schedules/collect-schedules.service';

const SUCCESS_MESSAGE =
  'Agendamento(s) de Coleta(s) Para Transportadora ":shortName" efetuado com sucesso.';
const SUCCESS_MESSAGE_CONFLICT =
  'Agendamento(s) de Coleta(s) Para Transportadora ":shortName" efetuado com sucesso. Foram gravados :totalConflicts eventos recorrentes';
const SUCCESS_MESSAGE_UPDATE =
  'Agendamento(s) de Coleta(s) para Transportadora ":shortName" alterado(s) com sucesso. Foram alterado(s) :updateds eventos recorrentes. Existem :totalConflicts conflitos a serem tratados';
const MESSAGE_CONFLICT =
  'Foram identificados :conflicts conflitos com agendamentos já existentes.';

const OPERATION_NATURE_COLLECT = 3;

@Component({
  selector: 'app-collect-form',
  templateUrl: './collect-form.component.html',
  styleUrls: ['./collect-form.component.scss'],
  preserveWhitespaces: true
})
export class CollectFormComponent
  extends AbstractSchedulesSlots<CollectSchedules>
  implements OnInit {
  columns: object[] = [
    { prop: 'id', name: '#' },
    { prop: 'collectSchedules.establishment.id', name: '# Localidade' },
    { prop: 'collectSchedules.establishment.name', name: 'Localidade' },
    // { prop: 'depositor.id', name: '# Depositante' },
    // { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'authorizationkey', name: '# Agendamento' },
    {
      prop: 'docksScheduling.dateScheduleDelivery',
      name: 'Data',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'docksScheduling.provider.shortName', name: 'Transportadora' },
    {
      prop: 'statusAuthorization',
      name: 'Status Solicitação',
      pipe: new StatusSolicitationPipe()
    },
    { prop: 'collectSchedules.obs', name: 'Obs.' },
    { prop: 'recurrent', name: 'Recorrente', pipe: new BooleanConverter() },
    { prop: 'conflicted', name: 'Conflito', pipe: new BooleanConverter() }
  ];

  columnsDetail: object[] = [
    { prop: 'provider.shortName', name: 'Transportadora' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'scheduleNumber', name: '# Agendamento' },
    { prop: 'dateSchedule', name: 'Data', pipe: new DateFormatPipe('en-US') },
    { prop: 'docks', name: 'Doca' },
    {
      prop: 'createdAt',
      name: 'Criado em',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'updatedAt',
      name: 'Alterado em',
      pipe: new DateTimeFormatPipe('en-US')
    },
    { prop: 'obs', name: 'Observação' }
  ];

  // columnsDetails: object[] = [
  //   { prop: 'authorizationKey', name: 'Agendamento', width: 180 },
  //   { prop: 'opNatureDelivery', name: 'Natureza da Operação' },
  //   { prop: 'operationSubNature', name: 'Sub-Natureza' },
  //   { prop: 'depositor.detailName', name: 'Depositante' },
  //   { prop: 'provider.shortName', name: 'Fornecedor' },
  //   { prop: 'initialHour', name: 'Hora inicial' },
  //   { prop: 'finalHour', name: 'Hora final' }
  //   // { prop: 'vehicle.vehicleType', name: 'Tipo Veículo' }
  // ];

  collectSchedules: CollectSchedules = new CollectSchedules();
  bsModalRef: BsModalRef;
  formFilters: FormGroup;
  selectedDepositor: Depositor;
  isAutoLoadGrid = true;
  countLoad = 0;
  rowDetail: any;
  subscriptions: Subscription[] = [];
  docks = [];

  modalRef: BsModalRef | null;

  depositorsFilter = [];
  providersFilters = [];
  collectRecurrents = [];

  selectedCollectSchedule: any;

  slot: SlotPattern;
  establishments = [];
  establishmentsFilter = [];
  vehiclesDepositor = [];

  selected: any[] = [];
  allConflicts = true;

  fieldsRecurrentCollect = [
    'endsAfterEvents',
    'endsAfterDay',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'everyDayOfMonth'
  ];

  @ViewChild('grdScheduleCollect') grdScheduleCollect$: DatatableApiComponent;
  @ViewChild('hdrTplHours') hdrTplHours: TemplateRef<any>;
  @ViewChild('bodyTplHours') bodyTplHours: TemplateRef<any>;

  @ViewChild('hdrTplDocks') hdrTplDocks: TemplateRef<any>;
  @ViewChild('bodyTplDocks') bodyTplDocks: TemplateRef<any>;

  @ViewChild('hdrTplDepositor') hdrTplDepositor: TemplateRef<any>;
  @ViewChild('bodyTplDepositor') bodyTplDepositor: TemplateRef<any>;

  @ViewChild('hdrTplDepositorId') hdrTplDepositorId: TemplateRef<any>;
  @ViewChild('bodyTplDepositorId') bodyTplDepositorId: TemplateRef<any>;

  @ViewChild('depositor') depositor: SelectFieldComponent;
  @ViewChild('modalDetail') modalDetail: TemplateRef<any>;
  modalRefDetail: BsModalRef;
  modalRef2: BsModalRef;

  @ViewChild('templateModalConfirmAll') templateModalConfirmAll: TemplateRef<
    any
  >;

  @ViewChild('estalblishment') estalblishment: SelectFieldComponent;

  rowsDetails = [];
  conflicts = 0;

  @ViewChild('template') template1: TemplateRef<any>;
  // @ViewChild('inpinitialDate') inpinitialDate: ElementRef;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public providerService: ProviderService,
    public authService: AuthService,
    public sanitizer: DomSanitizer,
    public collectSchedulesService: CollectSchedulesService,
    public modalService: BsModalService,
    public renderer: Renderer,
    private _priorVisualizationService: PriorVisualizationService,
    private router: Router,
    private route: ActivatedRoute,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _slotService: SlotPatternService,
    private _docksScheduling: DocksSchedulingService,
    private _establishmentService: EstablishmentService,
    private _vehiclesDepositorService: VehicleDepositorService,
    private signatureService: SignatureService,
    protected _docksOperationService: DocksOperationService
  ) {
    super(
      location,
      toaster,
      translator,
      sanitizer,
      depositorService,
      providerService,
      authService
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params && params['message']) {
        this.alertMsg(params['message']);
      }
      
    });

    this.columns.splice(5, 0, {
      name: 'Horário Início / Fim',
      cellTemplate: this.bodyTplHours,
      headerTemplate: this.hdrTplHours
    });

    this.columns.splice(6, 0, {
      name: 'Doca',
      cellTemplate: this.bodyTplDocks,
      headerTemplate: this.hdrTplDocks
    });

    this.columns.splice(3, 0, {
      name: '# Depositante',
      cellTemplate: this.bodyTplDepositorId,
      headerTemplate: this.hdrTplDepositorId
    });

    this.columns.splice(4, 0, {
      name: 'Depositante',
      cellTemplate: this.bodyTplDepositor,
      headerTemplate: this.hdrTplDepositor
    });

    this.formulario = new FormGroup({
      id: new FormControl(''),
      depositor: new FormControl('', [Validators.required]),
      provider: new FormControl(''),
      providerId: new FormControl(''),
      dateSchedule: new FormControl('', [Validators.required]),
      priorVisualization: new FormControl('', [Validators.required]),
      iniHour: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{2}:[0-9]{2}')
      ]),
      finHour: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{2}:[0-9]{2}')
      ]),
      obs: new FormControl(''),
      recurrent: new FormControl(false),
      endsAfterEvents: new FormControl('', [
        Validators.min(1),
        Validators.max(100)
      ]),
      endsAfterDay: new FormControl(''),
      monday: new FormControl(this.collectSchedules.monday),
      tuesday: new FormControl(this.collectSchedules.tuesday),
      wednesday: new FormControl(this.collectSchedules.wednesday),
      thursday: new FormControl(this.collectSchedules.thursday),
      friday: new FormControl(this.collectSchedules.friday),
      saturday: new FormControl(this.collectSchedules.saturday),
      sunday: new FormControl(this.collectSchedules.sunday),
      everyDayOfMonth: new FormControl('', [ValidatorDayOfMonth]),
      geralDepositors: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      vehicleTypeId: new FormControl(''),
      dates: new FormControl(''),
      datesWithoutConflict: new FormControl('')
    });

    this.subscriptions.push(
      this._establishmentService.findAll().subscribe(data => {
        this.establishments = [...data.body['content']];
        this.establishmentsFilter = [...data.body['content']];
      })
    );

    this.formFilters = new FormGroup({
      depositorFilter: new FormControl('', [Validators.required]),
      providerFilter: new FormControl(''),
      initialDate: new FormControl(''),
      finalDate: new FormControl(''),
      authorizationKey: new FormControl(''),
      establishmentId: new FormControl('')
    });

    this.formulario.get('finHour').setValidators(validatorFinHour('iniHour'));

    this.formulario.get('iniHour').valueChanges.subscribe(() => {
      this.formulario.get('finHour').hasError('validFinHour');
    });

    this.formulario.get('endsAfterDay').valueChanges.subscribe(value => {
      if (value && !/Invalid date/.test(value)) {
        this.formulario.get('endsAfterEvents').setValue('');
      }
    });

    this.formulario.get('endsAfterEvents').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('endsAfterDay').setValue('');
      }
    });

    this.formulario.get('recurrent').valueChanges.subscribe(value => {
      if (value) {
        this.enableFields(this.fieldsRecurrentCollect);
        return;
      }

      this.formulario.get('provider').valueChanges.subscribe(value => {
        if (value) {
          this.formulario.get('providerId').setValue(value['id']);
          return;
        }

        this.formulario.get('providerId').setValue('');
      });

      this.disableFields(this.fieldsRecurrentCollect);
    });

    // this.loadDepositors();

    [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ].forEach(dayOfWeek => {
      this.formulario.get(dayOfWeek).valueChanges.subscribe(value => {
        if (value) {
          this.formulario.get('everyDayOfMonth').setValue('');
        }
      });
    });

    this.formulario.get('everyDayOfMonth').valueChanges.subscribe(value => {
      if (value) {
        [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday'
        ].forEach(dayOfWeek => {
          this.formulario.get(dayOfWeek).setValue(false);
        });
      }
    });

    this.formulario.get('dateSchedule').valueChanges.subscribe(value => {
      if (value) {
        const inputValue = moment(value).format('YYYY-MM-DD');
        const dateCompare = moment(new Date()).format('YYYY-MM-DD');

        if (inputValue < dateCompare) {
          this.formulario.get('dateSchedule').setValue('');
        }
      }
    });
  }

  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'second modal-lg'
    });
  }

  establishmentChanged(establishment: Establishment) {
    
    if (establishment) {
      if (establishment.signature.uniqueDepositor) {
        this.formulario.get('geralDepositors').disable();
        this.formulario.get('geralDepositors').setValue(false);
      } else {
        this.formulario.get('geralDepositors').enable();
      }
    }
    this.setEstablishment(
      establishment,
      this.formulario,
      this.selectedDepositorId,
      null,
      this.setDepositorList
    );
  }

  establishmentChangedFilter(establishment: Establishment) {
    this.setEstablishment(
      establishment,
      this.formFilters,
      this.selectedDepositorFilter,
      this.clearDepositorFilter,
      this.setDepositorFilter
    );
  }

  clearDepositorFilter() {
    this.depositorsFilter = [];
    this.formFilters.get('depositorFilter').setValue([]);
    this.selectedDepositorFilter(null);
  }

  setDepositorFilter(depositors: Depositor[]) {
    this.depositorsFilter = depositors;
  }

  setDepositorList(depositors: Depositor[]) {
    this.depositors = depositors;

    // this.depositors = depositors.map(value => {
    //   value['shortName'] = `${value['name']}`;
    //   return value;
    // });
  }

  private setEstablishment(
    establishment: Establishment,
    form: FormGroup,
    callBack: Function,
    clearValues?: Function,
    setDepositors?: Function
  ) {
    if (establishment) {
      if (form.controls.hasOwnProperty('depositor')) {
        form.get('depositor').setValue([]);
      }

      if (form.controls.hasOwnProperty('depositorFilter')) {
        form.get('depositorFilter').setValue([]);
      }
      this.subscriptions.push(
        this._depositorService
          .findByEstablishmentId(establishment.id)
          .subscribe(data => {
            if (data && data.body['content'].length === 1) {
              const depositor = data.body['content'][0];
              if (form.controls.hasOwnProperty('depositor')) {
                form.get('depositor').setValue(depositor);
              }
              if (form.controls.hasOwnProperty('depositorFilter')) {
                form.get('depositorFilter').setValue(depositor);
              }
              callBack.call(this, depositor);
            }
            setDepositors.call(this, [...data.body['content']]);
          })
      );
      return;
    }

    clearValues.call(this);
  }

  closeModalConfirmConflicts() {
    this.modalRef.hide();
  }

  createScheduleWithConflict() {
    this.closeModalConfirmConflicts();

    if (!this.selected.length) {
      return;
    }

    let dates = [];
    let datesWithoutConflict = [];

    dates = this.selected
      .filter(row => row['conflict'])
      .map(row => row['date']);

    datesWithoutConflict = this.rowsDetails
      .filter(
        row => !row['conflict'] && !row['holiday'] && !row['notOperational']
      )
      .map(row => row['date']);

    
    

    this.formulario.get('dates').setValue(dates);
    this.formulario.get('datesWithoutConflict').setValue(datesWithoutConflict);

    this.collectSchedulesService
      .createWithConflict(this.formulario.value)
      .subscribe(data => {
        if (data) {
          this.formulario.reset();
          this.formulario.disable();

          this.alertMsg(
            'Agendamento efetuado com sucesso, porém existem conflitos há serem resolvidos',
            'warning'
          );
        }
      });
  }

  submit() {
    if (this.formulario.valid) {
      if (!!this.formulario.get('recurrent').value) {
        if (
          (!this.formulario.get('endsAfterEvents').value &&
            !this.formulario.get('endsAfterDay').value) ||
          !this.recurrentFieldsAreFilled()
        ) {
          this.alertMsg(
            'Parâmetros de recorrência obrigatórios não preenchidos.',
            'danger'
          );
          return;
        }
      }

      const formValue = this.formulario.getRawValue();

      if (formValue['geralDepositors']) {
        formValue['depositor'] = null;
        formValue['depositorId'] = null;
      }

      this.collectSchedulesService
        .validateRecurrentSchedule(formValue)
        .subscribe(
          (data: HttpResponse<SchedulesConflictDTO[]>) => {
            

            if (data) {
              if (data.status == 200) {
                this.rowsDetails = data.body;
                this.selected = this.rowsDetails.filter(row => row['conflict']);
                this.conflicts = this.selected.length;
                this.openModal(this.template1);
                return;
              }

              if (data.status == 202) {
                const dates = data.body.map(date => date['date']);
                this.formulario.get('dates').setValue(dates);

                this.createScheduleToCollectWithoutConflictRecurrent();
              }
            }
          },
          error => this.alertMsg(error.error, 'danger')
        );
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  recurrentFieldsAreFilled(): boolean {
    if (this.formulario.get('everyDayOfMonth').value) {
      return true;
    }

    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];

    for (let weekDay of weekDays) {
      if (this.formulario.get(weekDay).value) {
        return true;
      }
    }

    return false;
  }

  createScheduleToCollectWithoutConflictRecurrent() {
    if (this.formulario.get('id').value == null) {
      const formValue = this.formulario.getRawValue();

      if (formValue['geralDepositors']) {
        formValue['depositor'] = null;
        formValue['depositorId'] = null;
      }

      this.subscriptions.push(
        this.collectSchedulesService
          .createReverseRecurrent(formValue)
          .subscribe((data: CollectSchedules[]) => {
            this.formulario.reset();
            this.formulario.disable();

            
            if (data) {
              if (data.length > 1) {
                this.alertMsg(
                  `Agendamento(s) efetuado(s) com sucesso. Total de agendamentos (${
                    data.length
                  })`
                );
              } else {
                this.alertMsg(
                  `Agendamento efetuado com sucesso. Numeração ${
                    data[0]['deliveryAuthorization'].authorizationkey
                  }`
                );
              }
            }
          })
      );
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  optionGeral() {
    return (
      this.formulario.get('geralDepositors').value === 'true' ||
      this.formulario.get('geralDepositors').value === true
    );
  }

  changeGeralDepositor(e) {
    this.statusDepositorField();
  }

  formFillToEdit(row: object) {
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositor') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositor').setValue(row['depositor'].id);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.enable();
    this.statusDepositorField();
  }

  statusDepositorField() {
    if (!this.optionGeral()) {
      this.setRequiredForFields(['depositor']);
      this.enableFields(['depositor']);

      this.depositor.items = this.depositors;
      this.depositor.bindLabel = 'name';

      this.removeRequiredForFields(['provider']);

      if (this.selectedDepositor || this.depositors.length > 0) {
        this.formulario.get('depositor').setValue(this.depositors[0]);
      }
      return;
    }

    this._providerService
      .findByEstablishmentId(this.formulario.get('establishmentId').value)
      .subscribe(data => {
        this.setProvidersFromDepositor(data);
      });

    this.setRequiredForFields(['provider']);
    this.removeRequiredForFields(['depositor']);
    this.disableFields(['depositor']);

    const depositors = this.depositors;

    this.depositor.items = depositors.map(v => (v['shortName'] = 'Geral'));
    this.formulario.get('depositor').setValue(this.depositor.items[0]);
    this.depositor.bindLabel = 'shortName';
  }

  onSuccess(data: CollectSchedules) {
    this.disabledForm();
    this.alertMsg(
      SUCCESS_MESSAGE.replace(':shortName', data.provider.shortName)
    );
  }

  onSuccesWithConflict(data: CollectSchedules) {
    this.disabledForm();
    this.getGrid().reloadPage();
    this.alertMsg(
      SUCCESS_MESSAGE_CONFLICT.replace(
        ':shortName',
        data.provider.shortName
      ).replace(':totalConflicts', data.totalConflicts.toString())
    );
  }

  onSuccesWithConflictUpdate(data: CollectSchedules) {
    this.disabledForm();
    this.getGrid().reloadPage();
    this.alertMsg(
      SUCCESS_MESSAGE_UPDATE.replace(':shortName', data.provider.shortName)
        .replace(':updateds', data.conflictSchedule ? '0' : '1')
        .replace(':totalConflicts', data.totalConflicts.toString())
    );
  }

  openModalScheduleManualConfirm() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Deseja gravar agendamentos de coleta assim mesmo e tratar conflitos manualmente?',
      form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (/OK/.test(resp)) {
            this.collectSchedulesService
              .createWithConflict(this.formulario.value)
              .subscribe(data => {
                
                this.onSuccesWithConflict(data);
              });
          } else if (/CANCEL/.test(resp)) {
            this.resetar();
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  openModalScheduleManualConfirmUpdate() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Deseja alterar agendamentos de coleta assim mesmo e tratar conflitos manualmente?',
      form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (/OK/.test(resp)) {
            this.collectSchedulesService
              .createWithConflict(this.formulario.value)
              .subscribe(data => {
                
                this.onSuccesWithConflictUpdate(data);
              });
          } else if (/CANCEL/.test(resp)) {
            this.resetar();
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  endPointSchedules() {
    const dateIni = this.convertDate('initialDate');
    const dateFin = this.convertDate('finalDate');

    const urlParams = [
      { name: 'dateIni', value: dateIni },
      { name: 'dateFin', value: dateFin },
      {
        name: 'depositorId',
        value: this.setDepositorToFilter(
          this.formFilters.get('depositorFilter').value
        )
      },
      {
        name: 'providerId',
        value: this.formFilters.get('providerFilter').value
          ? this.formFilters.get('providerFilter').value.id
          : ''
      },
      {
        name: 'authorizationKey',
        value: this.formFilters.get('authorizationKey').value || ''
      },
      { name: 'status', value: '' },
      { name: 'operationSubNature', value: '' },
      { name: 'opNatureDelivery', value: [OPERATION_NATURE_COLLECT] },
      { name: 'statusHistoric', value: '' }
    ];

    let queryString = '';
    const query = [];

    urlParams.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    return `/wrapper/schedules/collect/search/reverse/collect?${queryString}&`;
  }

  setDepositorToFilter(depositor): number {
    if (!depositor) return -1;
    return typeof depositor == 'object' ? depositor['id'] : depositor;
  }

  convertDate(field: string): string {
    if (this.formFilters.get(field).value) {
      return moment(this.formFilters.get(field).value).format('YYYY-MM-DD');
    }
    return '';
  }

  // gridAutoLoad() {
  //   if (this.isAutoLoadGrid && this.countLoad === 0) {
  //     this.countLoad++;
  //     return true;
  //   }
  //   return false;
  // }

  openModalDetail(template: TemplateRef<any>, row: CollectSchedules) {
    this.rowDetail = row;
    this.modalRefDetail = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  selectedCollect(row: CollectSchedules) {
    this.openModalDetail(this.modalDetail, row);
  }

  filterSchedules() {
    if (this.formFilters.valid) {
      this.grdScheduleCollect$.reloadPage();
      return;
    }

    this.verificaValidacoesForm(this.formFilters);
  }

  // selectedDepositorId(data: Depositor) {
  //   this.selectedDepositor = data;
  // }

  loadDepositors() {
    const { id } = this._authService.getUser();

    this._depositorService.findAllByUserId(id).subscribe(data => {
      this.depositors = [...data.body['content']];
    });
  }

  vehicleTypeRequired(): boolean {
    if (!this.formulario.get('depositor').value) {
      return false;
    }

    return this.formulario.get('depositor').value.collect;
  }

  selectedDepositorId(depositor: Depositor) {
    if (depositor) {
      const { id } = this.authService.getUser();

      const getDocksDepositor = this._priorVisualizationService.findByEstablishmentIdAndDepositorIdAndCollectTrue(
        depositor.id,
        depositor.establishment.id
      );
      const getProvidersFromDepositor = this.providerService.findByDepositorId(
        depositor.id
      );
      const getSlotSize = this._slotService.findByEstablishmentIdAndStatusTrue(
        depositor.id
      );
      const getVehiclesDepositor = this._vehiclesDepositorService.findByDepositorAndUserIdAndStatusTrue(
        depositor.id,
        id
      );

      forkJoin([
        getDocksDepositor,
        getProvidersFromDepositor,
        getSlotSize,
        getVehiclesDepositor
      ]).subscribe(response => {
        this.setDocksDepositor(response[0]);
        this.setProvidersFromDepositor(response[1]);
        this.setSlotSize(response[2]);
        this.setVehiclesDepositor(response[3]);
      });

            // this.loadDocksDepositor(depositor);
      // this.loadProvidersFromDepositor(depositor);
    } else {
      this.docks = [];
      this.providers = [];

      this.formulario.get('provider').setValue([]);
      this.formulario.get('priorVisualization').setValue([]);
    }
  }

  selectedDepositorFilter(depositor: Depositor) {
    if (depositor) {
      this.loadProvidersFitlerFromDepositor(depositor);
    } else {
      this.providersFilters = [];
      this.providersFilters = [];
      this.formFilters.get('providerFilter').setValue(null);
    }
  }

  loadProvidersFitlerFromDepositor(depositor: Depositor) {
    this.providerService.findByDepositorId(depositor.id).subscribe(data => {
      if (data) {
        this.providersFilters = data.filter(
          provider =>
            provider['agentType'].id == 2 || provider['agentType'].id == 3
        );

        if (data.length === 1) {
          this.formulario.get('providerFilter').setValue(data[0]);
        }
      } else {
        this.providersFilters = [];
        this.formulario.get('providerFilter').setValue(null);
      }
    });
  }

  setVehiclesDepositor(response: any) {
    if (response) {
      this.vehiclesDepositor = response;
      this.formulario.get('vehicleTypeId').setValue([]);
      return;
    }

    this.vehiclesDepositor = [];
    this.formulario.get('vehicleTypeId').setValue(this.vehiclesDepositor);
  }

  setDocksDepositor(data: PriorVisualization[]) {
    
    if (data) {
      this.docks = data;

      if (this.docks.length) {
        this.docks = data.map(value => {
          value['fullName'] = `${value['signatureDock']} - (${
            value['initialHour']
          } - ${value['finalHour']})`;
          return value;
        });
      }

      if (this.docks.length === 1) {
        this.formulario.get('priorVisualization').setValue(this.docks[0]);
      }
    } else {
      this.docks = [];
      this.formulario.get('priorVisualization').setValue(this.docks);
    }
  }

  loadProvidersFromDepositor(depositor: Depositor) {
    this.providerService.findByDepositorId(depositor.id).subscribe(data => {
      this.providers = data;

      if (data && data.length === 1) {
        this.formulario.get('provider').setValue(data[0]);
      }
    });
  }

  setSlotSize(slot) {
    this.slot = slot ? slot : new SlotPattern();
    
  }

  setProvidersFromDepositor(data: any) {
    if (data) {
      this.providers = data.filter(
        provider =>
          provider['agentType'].id == 2 || provider['agentType'].id == 3
      );

      if (data.length === 1) {
        this.formulario.get('provider').setValue(data[0]);
      }
    } else {
      this.providers = [];
      this.formulario.get('provider').setValue(this.providers);
    }
  }

  loadDocksDepositor(depositor: Depositor) {
    this.subscriptions.push(
      this._priorVisualizationService
        .findByDepositorIdAndStatus(depositor.id)
        .pipe(catchError(this.handleError))
        .subscribe(data => {
          if (data) {
            this.docks = data.map(value => value['priorVisualization']);

            if (this.docks.length === 1)
              this.formulario.get('priorVisualization').setValue(this.docks[0]);
          } else {
            this.docks = [];
            this.formulario.get('priorVisualization').setValue(this.docks);
          }
        })
    );
  }

  handleError(error: any) {
    return Observable.of(null);
  }

  afterAddButton() {
    this.selectedDepositor = null;
    this.formulario.get('dateSchedule').setValue(new Date());
    // this.loadDepositors();
    // this.depositor.items = this.depositors;
    this.estalblishment.items = this.establishments;
  }

  changeDepositorFilter(depositor: Depositor) {
    if (depositor) {
      this.formFilters.get('providerFilter').setValue([]);
      this.providerService.findByDepositorId(depositor.id).subscribe(data => {
        this.providersFilters = data;

        if (data && data.length === 1) {
          this.formFilters.get('providerFilter').setValue(data[0]);
        }
      });
    } else {
      this.providersFilters = [];
      this.formFilters.get('providerFilter').setValue([]);
    }
  }

  selectedCollectAction(row) {
    
    this.selectedCollectSchedule = row;
  }

  checkIfExistsRecurrentToCancel() {
    this.collectRecurrents = [];

    if (!this.selectedCollectSchedule) {
      this.alertMsg('Selecione para cancelar.', 'info');
      return;
    }

    

    this._docksOperationService
      .findByDeliveryAuthorizationId(this.selectedCollectSchedule.id)
      .subscribe((data: DocksOperation) => {
        if (data && data.operationDateArrival != null) {
          this.alertMsg(
            'Operação não permitida. Chegada do veículo já registrada.',
            'danger'
          );
        } else {
          if (this.selectedCollectSchedule['recurrent']) {
            this.collectSchedulesService
              .findByRecurrent(this.selectedCollectSchedule.id)
              .subscribe(data => {
                if (data && data.length > 1) {
                  this.collectRecurrents = data;

                  this.openModal(this.templateModalConfirmAll);
                } else if (data.length == 1) {
                  this.openModalConfirmCancel();
                }
              });
          } else {
            this.openModalConfirmCancel();
          }
        }
      });
  }

  confirmCancelThis() {
    this.modalRef.hide();

    this._deliveryAuthorizationService
      .cancelDeliveryAuthorization(this.selectedCollectSchedule.id)
      .subscribe(
        () => {
          this.selectedCollectSchedule = null;
          this.alertMsg('Agendamento cancelado.');
          this.grdScheduleCollect$.reloadPage();
        },
        error => this.alertMsg(error, 'error')
      );
  }

  confirmCancelAll() {
    this.modalRef.hide();

    this._deliveryAuthorizationService
      .cancelDeliveryAuthorizations(
        this.collectRecurrents.map(manual => manual['deliveryAuthorizationId'])
      )
      .subscribe(
        () => {
          this.selectedCollectSchedule = null;
          this.alertMsg('Agendamento(s) cancelado(s).');
          this.grdScheduleCollect$.reloadPage();
        },
        error => this.alertMsg(error, 'error')
      );
  }

  openModalConfirmCancel() {
    if (!this.selectedCollectSchedule) {
      this.alertMsg('Selecione para cancelar.', 'info');
      return;
    }

    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja Cancelar? O Agendamento será cancelado e portanto não será possível efetuar a Operação de Carga.',
      classBtnSuccess: 'btn-warning',
      classBtnDanger: 'btn-outline-dark'
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._deliveryAuthorizationService
              .cancelDeliveryAuthorization(this.selectedCollectSchedule.id)
              .subscribe(
                () => {
                  this.selectedCollectSchedule = null;
                  this.alertMsg('Agendamento cancelado.');
                  this.grdScheduleCollect$.reloadPage();
                },
                error => this.alertMsg(error, 'error')
              );
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  cancelCollect() {
    this.queryParamsRouter('cancel');
  }

  editCollect() {
    if (!this.selectedCollectSchedule) {
      this.alertMsg('Selecione para reagendar.', 'info');
      return;
    }

    this._docksOperationService
      .findByDeliveryAuthorizationId(this.selectedCollectSchedule.id)
      .subscribe((data: DocksOperation) => {
        if (data && data.operationDateArrival != null) {
          this.alertMsg(
            'Operação não permitida. Chegada do veículo já registrada.',
            'danger'
          );
        } else {
          this.queryParamsRouter('edit');
        }
      });
  }

  queryParamsRouter(route: string) {
    if (this.selectedCollectSchedule) {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          collectSchedule: JSON.stringify(this.selectedCollectSchedule)
        },
        // queryParamsHandling: 'merge',
        // relativeTo: this.route
        // queryParamsHandling: ''
        skipLocationChange: true
      };
      this.router.navigate(
        [`sdm/register/schedules/collect/${route}`],
        navigationExtras
      );
      return navigationExtras;
    }
  }

  allowCancel(): boolean {
    return (
      !this.selectedCollectSchedule ||
      this.checkDiffTime() ||
      /CANCELADO|NO_SHOW|FINALIZADA/.test(
        this.selectedCollectSchedule['statusAuthorization']
      )
    );
  }

  checkDiffTime(): boolean {
    if (!this.selectedCollectSchedule) return false;

    const now = moment().format('YYYY-MM-DD HH:mm');
    const dateSchedule = moment(
      `${this.selectedCollectSchedule['docksScheduling'].dateScheduleDelivery}`
    ).format('YYYY-MM-DD');

    const dateHourSchedule = moment(
      `${dateSchedule} ${
        this.selectedCollectSchedule['docksScheduling'].initialHour
      }`
    ).format('YYYY-MM-DD HH:mm');

    return now > dateHourSchedule;
  }

  export() {
    if (!this.formFilters.valid) {
      this.verificaValidacoesForm(this.formFilters);
    }

    const dateIni = this.convertDate('initialDate');
    const dateFin = this.convertDate('finalDate');

    const url = [
      { name: 'dateIni', value: dateIni },
      { name: 'dateFin', value: dateFin },
      {
        name: 'depositorId',
        value: this.setDepositorToFilter(
          this.formFilters.get('depositorFilter').value
        )
      },
      {
        name: 'providerId',
        value: this.formFilters.get('providerFilter').value
          ? this.formFilters.get('providerFilter').value.id
          : ''
      },
      {
        name: 'authorizationKey',
        value: this.formFilters.get('authorizationKey').value || ''
      },
      { name: 'opNatureDelivery', value: [OPERATION_NATURE_COLLECT] }
    ];

    let queryString = '';
    const query = [];

    url.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    this.collectSchedulesService
      .findBySearchCollectExport(queryString)
      .subscribe(data => {
        importedSaveAs(data, 'agendamentos-coleta.xlsx');
      });
  }

  onSelectConflict({ selected }) {
    this.selected = selected;
    this.allConflicts = this.selected.length == this.rowsDetails.length;
    this.conflicts = this.selected.filter(row => row['conflict']).length;
  }

  onActivate({ row }) {
    if (!row['conflict'] && !row['holiday'] && !row['notOperational']) {
      this.selected = this.selected.filter(selected => selected != row);
    }
  }

  getComponentName(): string {
    return 'schedules.collect';
  }

  getNewInstance(): CollectSchedules {
    return this.collectSchedules;
  }

  getService(): AbstractService<CollectSchedules> {
    return this.collectSchedulesService;
  }

  getGrid(): DatatableApiComponent {
    return this.grdScheduleCollect$;
  }
}
