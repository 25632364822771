import { Location } from '@angular/common';
import { EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../core/translator/translator.service';

// @Component({
//   selector: 'app-base-form',
//   template: '<div></div>'
// })
export abstract class BaseFormComponent implements OnInit {
  formulario: FormGroup;
  formEnabled = false;
  editMode = false;
  viewMode = false;

  @Output()
  formEvent = new EventEmitter(); // emit an event saying the form child be enable

  constructor(
    public location: Location,
    public toaster: ToastrService,
    private _translatorService: TranslatorService
  ) {}

  ngOnInit() {}

  abstract submit();
  abstract getComponentName(): string;

  onSubmit() {
    if (this.formulario.valid) {
      this.submit();
      // this.formulario.markAsUntouched();
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetar() {
    this.formEnabled = false;
    this.formulario.reset();
    this.formulario.disable();
    this.formEvent.emit(this.formEnabled);
  }

  goBack() {
    this.location.back();
  }

  buttonAdd(id: string, value: number) {
    this.formulario.reset();
    this.formulario.enable();
    this.formulario.get(id).setValue(value);
    this.formEnabled = true;
    this.formEvent.emit(this.formEnabled);
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
  }

  disabledForm() {
    this.formEnabled = false;
    this.formulario.disable();
    this.formEvent.emit(this.formEnabled);
  }

  enableForm() {
    this.formEnabled = true;
    this.formulario.enable();
    // this.editMode = true

    if (
      this.formulario.controls['status'].value == null ||
      !this.formulario.controls['status'].value
    ) {
      this.formulario.disable();
    }

    this.formulario.controls['status'].enable();
    this.formEvent.emit(this.formEnabled);
  }

  showMessage(msg: string, type?: string, title: string = 'SDM') {
    switch (type) {
      case 'error':
        this.toaster.error(msg, title);
        break;
      case 'info':
        this.toaster.info(msg, title);
        break;
      case 'warn':
        this.toaster.warning(msg, title);
        break;
      default:
        this.toaster.success(msg, title);
    }
  }

  showMessageActions(
    type: string = 'success',
    action: string = 'create',
    title: string = 'SDM'
  ) {
    switch (type) {
      case 'error':
        this._translatorService.translate
          .get(`pages.${this.getComponentName()}.error.${action}`)
          .subscribe(message => this.toaster.error(message, title));
        break;
      case 'info':
        this._translatorService.translate
          .get(`pages.${this.getComponentName()}.info.${action}`)
          .subscribe(message => this.toaster.info(message, title));
        break;
      case 'warn':
        this._translatorService.translate
          .get(`pages.${this.getComponentName()}.warn.${action}`)
          .subscribe(message => this.toaster.warning(message, title));
        break;
      default:
        this._translatorService.translate
          .get(`pages.${this.getComponentName()}.success.${action}`)
          .subscribe(message => this.toaster.success(message, title));
    }
  }

  formFillToEdit(row: object) {
    this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.enable();
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.disable();
  }

  fillDepositorWhenSelectGridCell(row: object) {
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'] != null &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor']);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
  }

  setRequiredForFields(fields: any[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).setValidators([Validators.required]);
      form.get(field).updateValueAndValidity();
      form.get(field).markAsUntouched();
    }
  }

  removeRequiredForFields(fields: string[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).clearValidators();
      form.get(field).updateValueAndValidity();
      form.get(field).setValue('');
      form.get(field).markAsPristine();
    }
  }

  enableFields(fields: any[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).enable();
    }
  }

  disableFields(fields: any[], form = this.formulario, clearValue = true) {
    for (const field of fields) {
      form.get(field).disable();

      if (clearValue) form.get(field).setValue('');
    }
  }
}
