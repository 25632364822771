import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { EmailSmsParamsAbstract } from '../../email-sms-params-abstract';
import { SDM_URL } from './../../../../../app.api';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { EmailSmsCollect } from './../../../../../core/models/sdm/email-sms/email-sms-collect';
import { AuthService } from './../../../../../core/services/auth.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';
import { EmailsSmsCollectParamsService } from './../../../../../core/services/sdm/emails-sms/emails-sms-collect-params.service';

@Component({
  selector: 'app-email-sms-collect',
  templateUrl: './email-sms-collect.component.html',
  styleUrls: ['./email-sms-collect.component.scss']
})
export class EmailSmsCollectComponent
  extends EmailSmsParamsAbstract<EmailSmsCollect>
  implements OnInit {
  @ViewChild('grdCollectEmailSms')
  grdCollectEmailSms: DatatableApiComponent;

  columnsRevDelivery = [
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    {
      prop: 'confirmAuthorizationWithoutSchedule',
      name: 'Confirmação de Autorização Sem Agendamento',
      pipe: new BooleanConverter()
    },
    {
      prop: 'rescheduleAuthorization',
      name: 'Reagendamento de Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'cancelAuthorization',
      name: 'Cancelamento de Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'vehicleArrival',
      name: 'Chegada de Veículo',
      pipe: new BooleanConverter()
    },
    {
      prop: 'vehicleExits',
      name: 'Saída de Veículo',
      pipe: new BooleanConverter()
    },
    {
      prop: 'noShowRegister',
      name: 'Registro de No Show',
      pipe: new BooleanConverter()
    },
    {
      prop: 'documentalOcurrencyRegister',
      name: 'Registro de Ocorrência Documental',
      pipe: new BooleanConverter()
    },
    {
      prop: 'documentOperationalOcurrencyRegister',
      name: 'Registro de Ocorrência Operacional',
      pipe: new BooleanConverter()
    }
  ];

  constructor(
    public establishmentService: EstablishmentService,
    public depositorService: DepositorService,
    public authService: AuthService,
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public emailSmsService: EmailsSmsCollectParamsService
  ) {
    super(
      location,
      toaster,
      translator,
      depositorService,
      authService,
      establishmentService
    );
  }

  ngOnInit() {}

  endPointCollectSmsEmailsParam = () => SDM_URL.emailsSms.collect.findAll;

  getNewInstance() {
    return new EmailSmsCollect();
  }
  getService(): AbstractService<EmailSmsCollect> {
    return this.emailSmsService;
  }
  getGrid(): DatatableApiComponent {
    return this.grdCollectEmailSms;
  }
  getComponentName(): string {
    return 'emails-sms.default-messages';
  }
}
