import { DockCompatibility } from './../../../../core/models/sdm/docks/dock-compatibility';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { Collect } from '../../../../core/models/sdm/collect/collect';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { AbstractService } from '../../../../core/services/abstract.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../register/abstract-register';
import { SDM_URL } from './../../../../app.api';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { PriorVisualization } from './../../../../core/models/sdm/docks/prior-visualization';
import { DepositorService } from './../../../../core/services/depositor.service';
import { CollectService } from './../../../../core/services/sdm/collect/collect.service';
import { DockCompatibilityService } from './../../../../core/services/sdm/docks/dock-compatibility.service';
import { CollectService as CollectEvents } from './../collect.service';
import { SelectFieldComponent } from '../../../../components/select/select-field/select-field.component';

@Component({
  selector: 'app-collect-form',
  templateUrl: './collect-form.component.html',
  styleUrls: ['./collect-form.component.scss']
})
export class CollectFormComponent extends AbstractRegister<Collect>
  implements OnInit {
  collect: Collect;
  fixedDoc = false;
  containsNF = false;
  controllerNextQuestion = true;
  nextMinReciverReverseValue: number;

  @ViewChild('tabs') tabs: TabsetComponent;
  @ViewChild('dataGrdCollect') grid$: DatatableApiComponent;
  @ViewChild('dock') dock: SelectFieldComponent;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  optionsNOK = [{ name: 'genericValues.options.false', value: false }];

  optionsSOK = [{ name: 'genericValues.options.true', value: true }];

  columnsCollect = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Estabelecimento' },
    { prop: 'establishment.description', name: 'Estabelecimento' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' }
  ];

  docks: PriorVisualization[] = [];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _collectService: CollectService,
    private _collectEvents: CollectEvents,
    private _dockCompatibilityService: DockCompatibilityService
  ) {
    super(location, toaster, translator, depositorService);
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabRma':
        this._collectEvents.tabRmaFired();
        break;
      case 'tabReqMax':
        this._collectEvents.tabReqMaxFired();
        break;
      // case 'tabSmallSize':
      //   this._collectEvents.tabSmallSizeFired();
      //   break;
      case 'tabNfDeadLines':
        this._collectEvents.tabNfDeadLinesFired();
        break;
    }
  }

  ngOnInit() {
    this.collect = new Collect();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      shippingRequest: new FormControl(this.collect.shippingRequest),
      providerRequest: new FormControl(this.collect.providerRequest),
      internalUserRequest: new FormControl(this.collect.internalUserRequest),
      directAllocation: new FormControl(this.collect.directAllocation),
      infoDataOnNF: new FormControl(''),
      linkNfOrQtd: new FormControl(''),
      maxValueForEnableVehicle: new FormControl('', [
        Validators.min(1),
        Validators.max(999)
      ]),
      containsNfOfOthersDepositors: new FormControl(
        this.collect.containsNfOfOthersDepositors
      ),

      fixedDoca: new FormControl(''),
      docaNumber: new FormControl(''),
      priorVisualization: new FormControl(''),

      // dimensionProductivity: new FormControl(this.collect.dimensionProductivity),
      minAntecedenceForShippingToCome: new FormControl('', [
        Validators.min(1),
        Validators.max(999)
      ]),
      maxAntecedenceForShippingToCome: new FormControl('', [
        Validators.min(1),
        Validators.max(999)
      ])
    });

    this.formulario.get('depositorId').valueChanges.subscribe(value => {
      if (value) {
        this.loadDocksFromDepositor(value);
      }
    });

    this.formulario.get('priorVisualization').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('docaNumber').setValue(value['id']);
        return;
      }

      this.formulario.get('docaNumber').setValue('');
    });
  }

  changeFixedDoca(event) {
    this.fixedDoc = event.target.value === 'true';
    this.formulario.get('priorVisualization').enable();
    if (!this.fixedDoc) {
      this.formulario.get('priorVisualization').setValue('');
      this.formulario.get('priorVisualization').disable();
      return;
    }

    if (this.docks.length) {
      this.dock.items = this.docks;
      this.formulario.get('docaNumber').setValue(this.docks[0]);
    }
  }

  setDocksNumber() {
    this.formulario.get('docaNumber').setValue('Doca01');
    let options1 = [
      { name: 'genericValues.options.true', value: true },
      { name: 'genericValues.options.false', value: false }
    ];
  }

  changeContainsNf(event) {
    this.containsNF = event.target.value === 'true';

    if (this.containsNF) {
      this.formulario.get('fixedDoca').enable();
      this.formulario.get('fixedDoca').setValue('');
      this.formulario.get('docaNumber').enable();
      this.formulario.get('docaNumber').setValue('');
    } else {
      this.formulario.get('fixedDoca').disable();
      this.formulario.get('fixedDoca').setValue('');
      this.formulario.get('docaNumber').disable();
      this.formulario.get('docaNumber').setValue('');
    }
  }

  linkNfOrQtdDisabledNextQuestions(event) {
    let verify = event.target.value === 'true';
    if (!verify) {
      this.controllerNextQuestion = false;
      this.formulario.get('maxValueForEnableVehicle').disable();
    } else {
      this.controllerNextQuestion = true;
      this.formulario.get('maxValueForEnableVehicle').enable();
    }
  }

  afterReset() {
    this.containsNF = false;
    this.fixedDoc = false;
    this.docks = [];
  }

  afterAddButtonClick() {
    this.formulario.patchValue(this.collect);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    if (!row.containsNfOfOthersDepositors) {
      this.formulario.get('fixedDoca').disable();
      this.formulario.get('docaNumber').disable();
    }
  }

  endPointAllCollects = () => SDM_URL.collect.default;

  getNewInstance(): Collect {
    throw new Collect();
  }
  getService(): AbstractService<Collect> {
    return this._collectService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'collect';
  }

  //Seta uma variável para comparar se o valor inputado no campo seguinte é equivale ou é maior do que esse
  setminNextQuestonValue(event) {
    this.nextMinReciverReverseValue = parseInt(event.target.value);
  }

  //Compara se o valor inputado é maior ao campo anterior aplicando o estilo valido ou invalido respectivamente
  getMinValue(event) {
    if (parseInt(event.target.value) < this.nextMinReciverReverseValue) {
      this.formulario
        .get('maxAntecedenceForShippingToCome')
        .setErrors({ pattern: true });
    } else {
      this.formulario.get('maxAntecedenceForShippingToCome').clearValidators();
      this.formulario
        .get('maxAntecedenceForShippingToCome')
        .updateValueAndValidity();
    }
  }

  loadDocksFromDepositor(depositor: Depositor) {
    if (depositor) {
      this._dockCompatibilityService
        .findByDepositorIdAndCollectTrue(depositor.id)
        .subscribe((data: DockCompatibility) => {
          this.docks = [data.priorVisualization];

          if (data && this.formulario.get('fixedDoca').value) {
            this.formulario.get('priorVisualization').setValue(data);
          }
        });
    }
  }
}
