import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';
import { environment } from './../../../../../../environments/environment';
import { SDM_URL } from './../../../../../app.api';
import { DimensioningInfo } from './../../../../models/sdm/delivery-authorization/regular-delivery/dimensioning-info';

@Injectable({
  providedIn: 'root'
})
export class DimensioningInfoService extends AbstractService<DimensioningInfo> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.deliveryAuthorization.regularDelivery.dimensioningInfo.default
    );
  }

  deleteByDeliveryAuthorization(deliveryAuthorizationId: number) {
    return this.http.delete(
      `${SDM_URL.deliveryAuthorization.regularDelivery.dimensioningInfo.deleteByDeliveryAuthorization.replace(
        ':deliveryAuthorizationId',
        deliveryAuthorizationId.toString()
      )}`
    );
  }

  findByProductivityGroupsByDeliveryAuthorization(
    deliveryAuthorizationId: number
  ) {
    return this.http.get(
      `${SDM_URL.deliveryAuthorization.regularDelivery.dimensioningInfo.findByProductivityGroupsByDeliveryAuthorization.replace(
        ':deliveryAuthorizationId',
        deliveryAuthorizationId.toString()
      )}`
    );
  }
}
