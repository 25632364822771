import { Component, OnInit } from '@angular/core';
import { SlotService } from '../slot.service';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
})
export class SlotComponent implements OnInit {

  constructor(private _slotServiceEvents: SlotService) { }

  ngOnInit() {
    
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabSlotRound':
        this._slotServiceEvents.tabSlotRoundFired();
        break;
      case 'tabSlotReserve':
        this._slotServiceEvents.tabSlotReserveFired();
        break;
    }
  }

}
