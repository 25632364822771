import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { RegularDeliveryOrder } from '../../../../core/models/sdm/regular-delivery/order';
import { AbstractService } from '../../../../core/services/abstract.service';
import { UnitaryUploadService } from '../../../../core/services/sdm/delivery-authorization/regular-delivery/unitary-upload.service';
import { ProviderService } from '../../../../core/services/sdm/provider-management/provider.service';
import { RegularDeliveryRequestMaxService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-request-max.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractDelivery } from '../../abstract-delivery';
import { SDM_URL } from './../../../../app.api';
import { ImportNfeComponent } from './../../../../components/import-nfe/import-nfe.component';
import { ImportTxtFileComponent } from './../../../../components/import-txt-file/import-txt-file.component';
import { DeliveryAuthorization } from './../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { RegularDeliveryRequestMax } from './../../../../core/models/sdm/regular-delivery/regular-delivery-request-max';
import { AuthService } from './../../../../core/services/auth.service';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { OrderService } from './../../../../core/services/sdm/regular-delivery/order.service';
import { RegularDeliveryService } from './../../../../core/services/sdm/regular-delivery/regular-delivery.service';

const XML_FILE_FILTER = {
  name: 'xmlOnly',
  fn: function(item) {
    const xmlOnly = '|text/xml|';
    return xmlOnly.indexOf(item.type) !== -1;
  }
};

@Component({
  selector: 'app-unitary-upload',
  templateUrl: './unitary-upload.component.html',
  styleUrls: ['./unitary-upload.component.scss']
})
export class UnitaryUploadComponent extends AbstractDelivery<Document>
  implements OnInit {
  order: RegularDeliveryOrder = new RegularDeliveryOrder();
  regularDeliveryReqMax = new RegularDeliveryRequestMax();
  idAuthorization;
  sub: Subscription;
  filters = XML_FILE_FILTER;

  deliveryAuthorization: DeliveryAuthorization;
  deliveryAuthorizationId: number;

  @ViewChild('importXML') importXML: ImportNfeComponent;
  @ViewChild('importTxt') importTxt: ImportTxtFileComponent;

  constructor(
    public modalService: BsModalService,
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public sanitizer: DomSanitizer,
    public deliveryAuthorizationService: DeliveryAuthorizationService,
    public _authService: AuthService,
    private _orderService: OrderService,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    private route: ActivatedRoute,
    private _unitaryUploadService: UnitaryUploadService,
    public router: Router,
    private _regularDeliveryReqMaxService: RegularDeliveryRequestMaxService,
    public _regularDeliveryService: RegularDeliveryService
  ) {
    super(
      modalService,
      location,
      toaster,
      translator,
      sanitizer,
      deliveryAuthorizationService,
      _depositorService,
      _providerService,
      _authService,
      router,
      _regularDeliveryService
    );
  }

  ngOnInit() {
    this.deliveryAuthorization = this.route.snapshot.data[
      'deliveryAuthorization'
    ];

    this.formulario = new FormGroup({
      id: new FormControl(null),
      depositor: new FormControl('', [Validators.required]),
      depositorId: new FormControl(''),
      providerId: new FormControl('', [Validators.required]),
      nfSolicit: new FormControl(''),
      orderNumber: new FormControl(''),
      documentShipping: new FormControl('', [Validators.required]),
      // infNFeId: new FormControl(''),
      deliveryAuthorizationId: new FormControl(this.deliveryAuthorizationId),
      deliveryAuthorization: new FormControl(this.deliveryAuthorization),
      solicitations: new FormControl('')
    });

    this.setDepositorsFromQueryParams(this.deliveryAuthorization);
  }

  setDepositorsFromQueryParams(params: any) {
    try {
      
      const depositor = params['depositor'];
      this.depositors = [params['depositor']];
      this.formulario.get('depositor').setValue(depositor);
      this.depositorChanged(this.formulario.get('depositor').value);
    } catch (e) {
      
      this.depositors = [];
    }
  }

  depositorChanged(data) {
    if (data) {
      const { id } = data;
      const userId = this._authService.getUser().id;
      // this.loadRegularDeliveryParamsByDepositorIdAndUser(id, userId);
      this.loadParamsRegularDeliveryRequestMax(id, userId);
      this.selectedDepositor(data);
      this.loadProvidersByDepositorId(id);
      this.formulario.get('depositorId').setValue(id);
    }
  }

  loadProvidersByDepositorId(depositorId: number) {
    this._providerService
      .findByDepositorId(depositorId)
      .subscribe((data: any) => {
        this.providers = data;
      });
  }

  loadRegularDeliveryParamsByDepositorIdAndUser(
    depositorId: number,
    userId: number
  ) {
    const observe = this._orderService
      .findByDepositorIdAndUser(depositorId, userId)
      .pipe(delay(500))
      .subscribe(res => {
        this.order = res ? res : new RegularDeliveryOrder();
        observe.unsubscribe();
      });
  }

  loadParamsRegularDeliveryRequestMax(depositorId: number, userId: number) {
    this._regularDeliveryReqMaxService
      .findByDepositorId(depositorId, userId)
      .subscribe(data => {
        this.regularDeliveryReqMax = data
          ? data
          : new RegularDeliveryRequestMax();
      });
  }

  cancel() {
    // this.formEnabled = false;
    // this.formManualTypeDoc.disable();
    // this.formManualTypeDoc.reset();
    this._location.back();
  }

  submitOrUploadFile() {
    if (this.insertedNFs >= this.regularDeliveryReqMax.requestMaxNf) {
      this.alertMsg(
        'Quantidade máxima de documentos excedido para esta solicitação',
        'info'
      );
      return;
    }

    if (this.order.orderNumber && !this.importXML.uploader.queue.length) {
      this.alertMsg('Selecione o Documento para continuar', 'info');
      return;
    }

    if (!this.importXML.uploader.queue.length && this.formulario.valid) {
      this.alertMsg('Selecione o Documento para continuar', 'info');
      return;
    } else if (!this.formulario.valid) {
      this.verificaValidacoesForm(this.formulario);
    } else {
      if (!this.deliveryAuthorizationId) {
        this._deliveryAuthorizationService
          .create(this.deliveryAuthorization)
          .subscribe(data => {
            this.deliveryAuthorizationId = data.id;
            this.formulario.get('deliveryAuthorizationId').setValue(data.id);
          })
          .add(() => {
            this.importXML.uploader.options.additionalParameter.deliveryAuthorizationId = this.deliveryAuthorizationId;
            this.importXML.uploader.queue[0].upload();
          });
      } else {
        this.importXML.uploader.queue[0].upload();
      }
    }
  }

  endPointUploadXml = () =>
    SDM_URL.deliveryAuthorization.regularDelivery.unitaryUpload.uploadXml;

  endPointUploadTxt = () =>
    SDM_URL.deliveryAuthorization.regularDelivery.unitaryUpload.uploadTxt;

  getNewInstance() {
    return null;
  }
  getService(): AbstractService<any> {
    return this._unitaryUploadService;
  }
  getComponentName(): string {
    return 'delivery-authorization.unitary-upload';
  }
}
