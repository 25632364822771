import { SDM_URL } from './../../../../app.api';
import { HttpClient } from '@angular/common/http';
import { CriticalItems } from './../../../models/sdm/provider-management/critical-items';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { environment as env } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CriticalItemsService extends AbstractService<CriticalItems> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.providerManagement.criticalItems.default);
  }
}
