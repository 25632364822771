import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DeliveryAuthorization } from '../../../core/models/sdm/delivery-authorization/delivery-authorization';

@Injectable({
  providedIn: 'root'
})
export class RegularDeliveryResolverGuard implements Resolve<any> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (route.queryParams && route.queryParams['deliveryAuthorization']) {
      return JSON.parse(route.queryParams['deliveryAuthorization']);
    }

    return of(new DeliveryAuthorization());
  }
}

// DeliveryAuthorization
