import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '../../../../core/models/sdm/delivery-authorization/regular-delivery/document';

const MESSAGE_DELIVERY_AUTHORIZATION =
  '"Autorização de Entrega Sem Agendamento de número :deliveryAuthorization  gerada com sucesso". IMPORTANTE:  data máxima para entrega é ( :maxDeliveryDate ). para entregas consideradas de pequeno porte o recebimento de cargas de pequeno porte não agendadas irá ocorrer por ordem de chegada. Por favor ler e atender a todas as observações do documento gerado."';
const MESSAGE_SEND_MAIL = 'E-mail enviado para os destinatários cadastrados';

@Component({
  selector: 'app-without-schedule',
  templateUrl: './without-schedule.component.html',
  styleUrls: ['./without-schedule.component.scss'],
  preserveWhitespaces: true
})
export class WithoutScheduleComponent implements OnInit {
  formEnabled = false;
  formWithoutSchedule: FormGroup;

  dismissible = true;
  alerts: any = [];

  depositors = [];
  providers = [];
  solicitations = [];

  constructor(
    private route: ActivatedRoute,
    sanitizer: DomSanitizer,
    private _router: Router
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
    });
  }

  ngOnInit() {
    this.formWithoutSchedule = new FormGroup({
      depositorId: new FormControl(''),
      providerId: new FormControl(''),
      deliveryAuthorizationId: new FormControl('')
    });

    this.route.queryParams.subscribe((params: object) => {
      this.depositors = JSON.parse(params['depositor']) || null;
      this.providers = JSON.parse(params['provider']) || null;
      this.solicitations = JSON.parse(params['deliveryAuthorization']) || null;

      // this.statusAuthorization = this.solicitations['statusAuthorization'];
      this.formWithoutSchedule.get('depositorId').setValue(this.depositors[0]);
      this.formWithoutSchedule.get('providerId').setValue(this.providers[0]);
      this.formWithoutSchedule
        .get('deliveryAuthorizationId')
        .setValue(this.solicitations[0]);

      const oldestDate = this.getDateOfDelivery(
        this.solicitations[0]['documents']
      );

      this.alertMsg(
        `${MESSAGE_DELIVERY_AUTHORIZATION.replace(
          ':deliveryAuthorization',
          this.solicitations[0]['authorizationkey']
        ).replace(':maxDeliveryDate', this.solicitations[0]['validityDate'])}`,
        'info'
      );
      this.alertMsg(`${MESSAGE_SEND_MAIL}`, 'info');
    });
  }

  getDateOfDelivery(documents: Document[]): string {
    
    let oldestDate = '';
    oldestDate = documents
      .map(date => date.emissionDate)
      .sort()
      .reverse()[0];
    return oldestDate;
  }

  cancel() {
    this._router.navigate(['sdm/home']);
  }

  newAuthorization() {
    this._router.navigate(['sdm/delivery-authorization/regular-delivery']);
  }
}
