import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { DateTimeFormatPipe } from '../../../../core/classes/dateTimePipe';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { Vehicle } from '../../../../core/models/sdm/vehicle';
import { EstablishmentService } from '../../../../core/services/establishment.service';
import { VehicleService } from '../../../../core/services/vehicle.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { BaseFormComponent } from '../../../../shared/base-form/base-form.component';
import { VehicleServiceEvents } from '../vehicle.service';
import { SDM_URL } from './../../../../app.api';
import { AuthService } from './../../../../core/services/auth.service';
import { DepositorService } from './../../../../core/services/depositor.service';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./vehicle-form.component.scss'],
  providers: [VehicleServiceEvents]
})
export class VehicleFormComponent extends BaseFormComponent implements OnInit {
  establishments: any[] = [];
  depositors: Depositor[] = [];
  depositorsSearch: Depositor[] = [];
  vehicle: Vehicle;
  statusRegister = true;
  establishmentId: number = 0;
  depositorId = -1;

  searchForm: FormGroup;
  @ViewChild('datatable') grid: DatatableApiComponent;
  @Output() listOfVehicles = new EventEmitter();

  constructor(
    public location: Location,
    public toaster: ToastrService,
    private _vehicleService: VehicleService,
    private _vehicleEventService: VehicleServiceEvents,
    public translatorService: TranslatorService,
    public _establishmentService: EstablishmentService,
    private _authService: AuthService,
    public depositorService: DepositorService
  ) {
    super(location, toaster, translatorService);
    this.vehicle = new Vehicle();
  }

  columns = [
    { prop: 'vehicleEstablishment.vehicle.vehicleType', name: 'Tipo' },
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    {
      prop: 'createdAt',
      name: 'Criado em',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'updatedAt',
      name: 'Alterado em',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(this.vehicle.id),
      vehicleType: new FormControl(this.vehicle.vehicleType, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      establishmentId: new FormControl('', [Validators.required]),
      status: new FormControl(this.statusRegister),
      depositor: new FormControl('', [Validators.required]),
      createdBy: new FormControl(''),
      updatedBy: new FormControl('')
    });

    this.searchForm = new FormGroup({
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required])
    });

    this.loadEstablishments();

    this.searchForm.get('establishmentId').valueChanges.subscribe(value => {
      if (value) {
        this.establishmentId = value.id;
      }
    });

    this.searchForm.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.depositorId = value.id;
      }
    });
  }

  submit() {
    if (this.formulario.valid) {
      if (this.formulario.controls['id'].value == null) {
        this._vehicleService.create(this.formulario.value).subscribe(data => {
          this.formulario.patchValue(data);
          this.disabledForm();
          this.toaster.success('Processo concluído com sucesso', 'SDM');
          this.grid.reloadPage();
        });
      } else {
        this._vehicleService.edit(this.formulario.value).subscribe(() => {
          this.disabledForm();
          this.toaster.success('Processo concluído com sucesso', 'SDM');
          this.grid.reloadPage();
        });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();

    this.loadEstablishments();
  }

  loadEstablishments() {
    const { id } = this._authService.getUser();

    this._establishmentService.findByUserId(id).subscribe(data => {
      this.establishments = data;
    });
  }

  endPointVehicleEstablishment = () =>
    `${SDM_URL.vehicle.depositor.findByDepositorId.replace(
      ':depositorId',
      this.depositorId.toString()
    )}`;

  getComponentName() {
    return 'vehicle-type';
  }

  // teste(row) {
  //   // this.listOfVehicles.emit(row)
  //   this._vehicleEventService.addVehicle(row);
  // }

  establishmentChanged(establishment) {
    this.depositorService
      .findByEstablishmentId(establishment['id'])
      .subscribe(data => {
        // if (this.formulario.controls.hasOwnProperty('depositor')) {
        //   this.formulario.get('depositor').setValue('');
        // } else {
        //   this.formulario.get('depositorId').setValue('');
        // }
        this.depositors = [...data.body['content']];
        
        if (this.depositors.length === 1) {
          if (this.formulario.controls.hasOwnProperty('depositor')) {
            this.formulario.get('depositor').setValue(this.depositors[0]);
          }
        }
      });
  }

  formFillToEdit(row: object) {
    
    this.formulario.get('depositor').setValue(row['depositor']);

    this.formulario
      .get('establishmentId')
      .setValue(row['depositor']['establishment']);

    this.formulario
      .get('vehicleType')
      .setValue(row['vehicleEstablishment']['vehicle'].vehicleType);

    this.formulario
      .get('id')
      .setValue(row['vehicleEstablishment']['vehicle'].id);
    // this.fillDepositorWhenSelectGridCell(row);
    this.formulario.enable();
  }

  establishmentChangedSearch(establishment) {
    this.depositorService
      .findByEstablishmentId(establishment['id'])
      .subscribe(data => {
        this.depositorsSearch = [...data.body['content']];
        
        if (this.depositorsSearch.length === 1) {
          if (this.searchForm.controls.hasOwnProperty('depositor')) {
            this.searchForm.get('depositor').setValue(this.depositors[0]);
          }
        }
      });
  }

  filterVehicles() {
    if (this.searchForm.valid) {
      setTimeout(() => this.grid.reloadPage(), 1);
      return;
    }

    this.verificaValidacoesForm(this.searchForm);
  }
}
