import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from 'lodash';

@Pipe({
  name: 'statusSolicitation'
})
export class StatusSolicitationPipe implements PipeTransform {
  private status = [
    { id: 'DOC_OK', value: 'DOC OK' },
    { id: 'INTERROMPIDA', value: 'INTERROMPIDA' },
    { id: 'PENDENTE', value: 'PENDENTE' },
    { id: 'APROVADO', value: 'APROVADO' },
    { id: 'ALOCACAO_MANUAL', value: 'ALOCACAO MANUAL' },
    { id: 'SEM_AGENDAMENTO', value: 'SEM AGENDAMENTO' },
    { id: 'EXPIRADO', value: 'EXPIRADO' },
    { id: 'CANCELADO', value: 'CANCELADO' }
  ];

  transform(value: string): any {
    return capitalize(value.replace('_', ' '));
    // let statusSolicitation = '';
    // this.status.forEach((v) => {
    // 	if (v.id === value) {
    // 		statusSolicitation = v.value;
    // 		return statusSolicitation;
    // 	}
    // });

    // return statusSolicitation;
  }
}
