import { Establishment } from './../../core/models/sdm/establishment';
import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { AbstractService } from '../../core/services/abstract.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { SDM_URL } from './../../app.api';
import { DatatableApiComponent } from './../../components/datatable-api/datatable-api.component';
import { DepositorService } from './../../core/services/depositor.service';
import { FormGroup, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

export abstract class AbstractRegister<T> extends BaseFormComponent
  implements OnDestroy {
  depositors = [];
  fileName = 'arquivo';
  subscriptions: Subscription[] = [];

  columnsEstablishment = [
    { prop: 'id', name: '#' },
    { prop: 'description', name: 'Descrição' },
    { prop: 'document', name: 'CNPJ' },
    { prop: 'establishCode', name: 'Código Localidade' }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService
  ) {
    super(location, toaster, translator);
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.value)
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.value)
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  fillDepositorWithId() {
    if (
      this.formulario.controls.hasOwnProperty('depositorId') &&
      typeof this.formulario.get('depositorId').value === 'object'
    ) {
      const { id } = this.formulario.get('depositorId').value;
      this.formulario.get('depositorId').setValue(id);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }

    this.subscriptions.push(
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .pipe(delay(500))
        .subscribe(data => {
          if (this.formulario.controls.hasOwnProperty('depositor')) {
            this.formulario.get('depositor').setValue('');
          } else {
            this.formulario.get('depositorId').setValue('');
          }
          this.depositors = [...data.body['content']];
          
          if (this.depositors.length === 1) {
            if (this.formulario.controls.hasOwnProperty('depositor')) {
              this.formulario.get('depositor').setValue(this.depositors[0]);
            } else {
              this.formulario.get('depositorId').setValue(this.depositors[0]);
            }
          }
        })
    );
  }

  fileUpload(data) {
    if (data) {
      this.getGrid().reloadPage();
    }
  }

  backup() {
    this.subscriptions.push(
      this.getService()
        .backup()
        .subscribe(data => {
          this.getGrid().reloadPage();
        })
    );
  }

  export() {
    this.subscriptions.push(
      this.getService()
        .export()
        .subscribe(data => {
          importedSaveAs(data, this.getFileName());
        })
    );
  }

  template() {
    this.subscriptions.push(
      this.getService()
        .template()
        .subscribe(data => {
          importedSaveAs(data, this.getFileName());
        })
    );
  }

  getFileName(): string {
    this.translator.translate
      .get(`pages.${this.getComponentName()}.excelFileName`)
      .subscribe(fileName =>
        fileName ? (this.fileName = fileName) : this.fileName
      );
    return `${this.fileName}.xlsx`;
  }

  makeQueryForEstablishment = () =>
    SDM_URL.establishment.findById.replace(':id', '');
  makeQueryForAllEstablishments = () => SDM_URL.establishment.findAll;

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;
  abstract getGrid(): DatatableApiComponent;
}
