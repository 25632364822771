import { ProviderManagement } from './../../../provider-management.service';
import { BooleanConverter } from './../../../../../../core/classes/boolean-type';
import { take } from 'rxjs/operators';
import { MeasureUnitService } from './../../../../../../core/services/measure-unit/measure-unit.service';
import { SDM_URL } from './../../../../../../app.api';
import { ProductivityPatternService } from './../../../../../../core/services/sdm/provider-management/productivity-pattern.service';
import { ByTimeUnitEnum } from './../../../../../../core/models/sdm/shared/enums/ByTimeUnitEnum';
import { DepositorService } from './../../../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../../../components/datatable-api/datatable-api.component';
import { ProductivityPattern } from './../../../../../../core/models/sdm/provider-management/productivity-pattern';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractRegister } from '../../../../abstract-register';
import { AbstractService } from '../../../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-productivity-pattern-form',
  templateUrl: './productivity-pattern-form.component.html',
  styleUrls: ['./productivity-pattern-form.component.scss'],
})
export class ProductivityPatternFormComponent extends AbstractRegister<ProductivityPattern> implements OnInit {

  public keys;
  byTimeUnitEnum = ByTimeUnitEnum;
  @ViewChild('gridProductivityPattern') grid$: DatatableApiComponent;
  measureUnits = [];
  productivityPattern: ProductivityPattern;

  fixWindowTimeDischargeOp = false;
  setupDischargeOp = false;
  fixWindowConferenceOp = false;
  setupConferenceOp = false;

  @ViewChild('hdrTplDepostorID') hdrTplDepostorID: TemplateRef<any>;
  @ViewChild('bodyTplDepostorID') bodyTplDepostorID: TemplateRef<any>;

  @ViewChild('hdrTplDepositor') hdrTplDepositor: TemplateRef<any>;
  @ViewChild('bodyTplDepositor') bodyTplDepositor: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd1') hdrTplByTimeUnitProd1: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd1') bodyTplByTimeUnitProd1: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd2') hdrTplByTimeUnitProd2: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd2') bodyTplByTimeUnitProd2: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd3') hdrTplByTimeUnitProd3: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd3') bodyTplByTimeUnitProd3: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd4') hdrTplByTimeUnitProd4: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd4') bodyTplByTimeUnitProd4: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd5') hdrTplByTimeUnitProd5: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd5') bodyTplByTimeUnitProd5: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd6') hdrTplByTimeUnitProd6: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd6') bodyTplByTimeUnitProd6: TemplateRef<any>;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  columnsProductivityPattern: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'fixWindowDischargeOp', name: 'Janela Fixa? (Operação de Descarga)', pipe: new BooleanConverter(), width: 230 },
    { prop: 'timeInMinutesDischargeOp', name: 'Duração (em minutos) (Operação de Descarga)', width: 270 },
    { prop: 'setupDischargeOp', name: 'Setup Aplicável (Operação de Descarga)', pipe: new BooleanConverter(), width: 230 },
    { prop: 'timeInMinutesSetupDischargeOp', name: 'Duração (em minutos) (Operação de Descarga)', width: 265 },
    { prop: 'firstPQuantity', name: 'Quantidade Produtividade 1', width: 200 },
    { prop: 'firstPMeasureUnit', name: 'Unidade Medida Produtividade 1', width: 200 },
    { prop: 'secondPQuantity', name: 'Quantidade Produtividade 2', width: 200 },
    { prop: 'secondPMeasureUnit', name: 'Unidade Medida Produtividade 2', width: 200 },
    { prop: 'thirdPQuantity', name: 'Quantidade Produtividade 3', width: 200 },
    { prop: 'thirdPMeasureUnit', name: 'Unidade Medida Produtividade 3', width: 200 },
    { prop: 'fixWindowConferenceOp', name: 'Janela Fixa? (Operação de Conferência)', pipe: new BooleanConverter(), width: 230 },
    { prop: 'timeInMinutesConferenceOp', name: 'Duração (em minutos) (Operação de Conferência)', width: 320 },
    { prop: 'setupConferenceOp', name: 'Setup Aplicável (Operação de Conferência)', pipe: new BooleanConverter(), width: 250 },
    { prop: 'timeInMinutesSetupConferenceOp', name: 'Duração (em minutos) (Operação de Conferência)', width: 260 },
    { prop: 'fourthPQuantity', name: 'Quantidade Produtividade 4', width: 200 },
    { prop: 'fourthPMeasureUnit', name: 'Unidade Medida Produtividade 4', width: 200 },
    { prop: 'fifthPQuantity', name: 'Quantidade Produtividade 5', width: 200 },
    { prop: 'fifthPMeasureUnit', name: 'Unidade Medida Produtividade 5', width: 200 },
    { prop: 'sixPQuantity', name: 'Quantidade Produtividade 6', width: 200 },
    { prop: 'sixPMeasureUnit', name: 'Unidade Medida Produtividade 6', width: 200 },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _productivityPatternService: ProductivityPatternService,
    private _measureUnitService: MeasureUnitService,
    private _provdrManagementService: ProviderManagement) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.byTimeUnitEnum);
  }

  endPointProductivityPattern = () => SDM_URL.providerManagement.docksDimensioningTime.productivityPattern.default;
  endpointUpload = () => SDM_URL.providerManagement.docksDimensioningTime.productivityPattern.upload;
  endPointPartial = () => SDM_URL.providerManagement.docksDimensioningTime.productivityPattern.importPartial;

  ngOnInit() {
    this._provdrManagementService.emitEventTabDimensioningTabProdPattern.pipe(take(1)).subscribe(() => this.grid$.reloadPage());
    this.productivityPattern = new ProductivityPattern();

    this.columnsProductivityPattern.splice(3, 0, { name: '# Depositante', cellTemplate: this.bodyTplDepostorID, headerTempalte: this.hdrTplDepostorID, width: 230 });
    this.columnsProductivityPattern.splice(4, 0, { name: 'Depositante', cellTemplate: this.bodyTplDepositor, headerTempalte: this.hdrTplDepositor, width: 230 });

    this.columnsProductivityPattern.splice(11, 0, { name: 'Por Unidade de Tempo Produtividade 1', cellTemplate: this.bodyTplByTimeUnitProd1, headerTempalte: this.hdrTplByTimeUnitProd1, width: 230 });
    this.columnsProductivityPattern.splice(14, 0, { name: 'Por Unidade de Tempo Produtividade 2', cellTemplate: this.bodyTplByTimeUnitProd2, headerTempalte: this.hdrTplByTimeUnitProd2, width: 230 });
    this.columnsProductivityPattern.splice(17, 0, { name: 'Por Unidade de Tempo Produtividade 3', cellTemplate: this.bodyTplByTimeUnitProd3, headerTempalte: this.hdrTplByTimeUnitProd3, width: 230 });
    this.columnsProductivityPattern.splice(20, 0, { name: 'Por Unidade de Tempo Produtividade 4', cellTemplate: this.bodyTplByTimeUnitProd4, headerTempalte: this.hdrTplByTimeUnitProd4, width: 230 });
    this.columnsProductivityPattern.splice(23, 0, { name: 'Por Unidade de Tempo Produtividade 5', cellTemplate: this.bodyTplByTimeUnitProd5, headerTempalte: this.hdrTplByTimeUnitProd5, width: 230 });
    this.columnsProductivityPattern.splice(26, 0, { name: 'Por Unidade de Tempo Produtividade 6', cellTemplate: this.bodyTplByTimeUnitProd6, headerTempalte: this.hdrTplByTimeUnitProd6, width: 230 });

    this.formulario = new FormGroup({
      id: new FormControl(this.productivityPattern.id),
      establishmentId: new FormControl(this.productivityPattern.establishmentId, [Validators.required]),
      establishmentName: new FormControl(''),
      description: new FormControl(this.productivityPattern.description),
      fixWindowDischargeOp: new FormControl(this.productivityPattern.fixWindowDischargeOp),
      timeInMinutesDischargeOp: new FormControl(this.productivityPattern.timeInMinutesDischargeOp, [Validators.min(0), Validators.max(1440)]),
      setupDischargeOp: new FormControl(this.productivityPattern.setupDischargeOp),
      timeInMinutesSetupDischargeOp: new FormControl(this.productivityPattern.timeInMinutesSetupDischargeOp, [Validators.min(0), Validators.max(1440)]),
      fixWindowConferenceOp: new FormControl(this.productivityPattern.fixWindowConferenceOp),
      timeInMinutesConferenceOp: new FormControl(this.productivityPattern.timeInMinutesConferenceOp, [Validators.min(0), Validators.max(1440)]),
      setupConferenceOp: new FormControl(this.productivityPattern.setupConferenceOp),
      timeInMinutesSetupConferenceOp: new FormControl(this.productivityPattern.timeInMinutesSetupConferenceOp, [Validators.min(0), Validators.max(1440)]),
      firstPQuantity: new FormControl(this.productivityPattern.firstPQuantity, [Validators.min(1), Validators.max(999999)]),
      firstPMeasureUnit: new FormControl(this.productivityPattern.firstPMeasureUnit),
      firstPByTimeUnit: new FormControl(this.productivityPattern.firstpByTimeUnit),
      secondPQuantity: new FormControl(this.productivityPattern.secondPQuantity, [Validators.min(1), Validators.max(999999)]),
      secondPMeasureUnit: new FormControl(this.productivityPattern.secondPMeasureUnit),
      secondPByTimeUnit: new FormControl(this.productivityPattern.secondPByTimeUnit),
      thirdPQuantity: new FormControl(this.productivityPattern.thirdPQuantity, [Validators.min(1), Validators.max(999999)]),
      thirdPMeasureUnit: new FormControl(this.productivityPattern.thirdPMeasureUnit),
      thirdPByTimeUnit: new FormControl(this.productivityPattern.thirdPByTimeUnit),
      fourthPQuantity: new FormControl(this.productivityPattern.fourthPQuantity, [Validators.min(1), Validators.max(999999)]),
      fourthPMeasureUnit: new FormControl(this.productivityPattern.fourthPMeasureUnit),
      fourthPByTimeUnit: new FormControl(this.productivityPattern.fourthPByTimeUnit),
      fifthPQuantity: new FormControl(this.productivityPattern.fifthPQuantity, [Validators.min(1), Validators.max(999999)]),
      fifthPMeasureUnit: new FormControl(this.productivityPattern.fifthPMeasureUnit),
      fifthPByTimeUnit: new FormControl(this.productivityPattern.fifthPByTimeUnit),
      sixPQuantity: new FormControl(this.productivityPattern.sixPQuantity, [Validators.min(1), Validators.max(999999)]),
      sixPMeasureUnit: new FormControl(this.productivityPattern.sixPMeasureUnit),
      sixPByTimeUnit: new FormControl(this.productivityPattern.sixPByTimeUnit),
    });
  }

  loadMeasureUnits() {
    this._measureUnitService.findAll()
      .pipe(
        take(1)).subscribe(data => {
          this.measureUnits = [...data.body['content']];
        });
  }

  addButton() {
    this.productivityPattern = new ProductivityPattern();
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.loadMeasureUnits();
    this.formulario.patchValue(this.productivityPattern);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    if (row.hasOwnProperty('depositor') && row['depositor'].hasOwnProperty('establishment')) {
      if (this.formulario.get('depositorId') && this.formulario.get('establishmentId')) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario.get('establishmentId').setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.enable();
    this.loadMeasureUnits();
    this.statusOfTimeInMinutesSetupDischarge();
    this.statusOfTimeInMinutesConferenceOp();
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    if (row.hasOwnProperty('depositor') && row['depositor'].hasOwnProperty('establishment')) {
      if (this.formulario.get('depositorId') && this.formulario.get('establishmentId')) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario.get('establishmentId').setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.disable();
    this.loadMeasureUnits();
  }

  changedFixWindowDischargeOp(event) {
    this.fixWindowTimeDischargeOp = (event.target.value === 'true');
    this.statusOfTimeInMinutesDischargeOp();
  }

  statusOfTimeInMinutesDischargeOp() {
    if (!this.fixWindowTimeDischargeOp) {
      this.removeRequiredForFields(['timeInMinutesDischargeOp']);
      this.disableFields(['timeInMinutesDischargeOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesDischargeOp']);
    this.enableFields(['timeInMinutesDischargeOp']);
  }

  changeSetupDischargeOp(event) {
    this.setupDischargeOp = (event.target.value === 'true');
    this.statusOfTimeInMinutesSetupDischarge();
  }

  statusOfTimeInMinutesSetupDischarge() {
    if (!this.setupDischargeOp) {
      this.removeRequiredForFields(['timeInMinutesSetupDischargeOp']);
      this.disableFields(['timeInMinutesSetupDischargeOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesSetupDischargeOp']);
    this.enableFields(['timeInMinutesSetupDischargeOp']);
  }

  changeFixWindowConferenceOp(event) {
    this.fixWindowConferenceOp = (event.target.value === 'true');
    this.statusOfTimeInMinutesConferenceOp();
  }

  statusOfTimeInMinutesConferenceOp() {
    if (!this.fixWindowConferenceOp) {
      this.removeRequiredForFields(['timeInMinutesConferenceOp']);
      this.disableFields(['timeInMinutesConferenceOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesConferenceOp']);
    this.enableFields(['timeInMinutesConferenceOp']);
  }

  changeSetupConferenceOp(event) {
    this.setupConferenceOp = (event.target.value === 'true');
    this.statusOfTimeInMinutesSetupConference();
  }

  statusOfTimeInMinutesSetupConference() {
    if (!this.setupConferenceOp) {
      this.removeRequiredForFields(['timeInMinutesSetupConferenceOp']);
      this.disableFields(['timeInMinutesSetupConferenceOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesSetupConferenceOp']);
    this.enableFields(['timeInMinutesSetupConferenceOp']);
  }

  getNewInstance(): ProductivityPattern {
    return new ProductivityPattern();
  }
  getService(): AbstractService<ProductivityPattern> {
    return this._productivityPatternService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.docks-dimensioning-time.productivity-pattern';
  }

}
