import { AbstractModel } from '../../abstract.model';

export class CollectSmallSize extends AbstractModel {
  public establishmentId: number;
  public depositorId: number;
  public depositorDeliverySmallSize: boolean;
  public maxDurationDeliveryInMinutes: number;
  public specificDelivery: boolean;
  public docaNumber: number;

  constructor() {
    super();
  }

}
