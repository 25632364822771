import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { DeliveryType } from '../shared/enums/DeliveryType';

export class RegularDeliveryNF extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public depositorId: number;
  public minDeadLineRequired: DeliveryType;
  public maxDeadlineDelivery: number;
  public maxExpirationRequestDeadLine: number;
  public maxExpirationRequestMinAntecedence: number;
  public autoCancelMaxDeadLine: number;
  public autoCancelMinAntecedence: number;
  public minConfirmNewAuthorizationExternalUser: number;
  public minConfirmNewAuthorizationInternalUser: number;
  public minReschedulingConfirmExternalUser: number;
  public minReschedulingConfirmInternalUser: number;
  public minCancelRequestExternalUser: number;
  public minCancelRequestInternalUser: number;
  public autoNoShowRegister: boolean;
  public afterQtdTime: number;
  public allowUpdateRegister: boolean;
  public onlyInZeroDay: boolean;
  public requirePasswdSupervisor: boolean;

  constructor() {
    super();
    this.minDeadLineRequired = DeliveryType.RUNNING_DAYS;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
