import { Establishment } from './../../../../core/models/sdm/establishment';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { EstablishmentService } from './../../../../core/services/establishment.service';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { IdlenessDocksService } from './../../../../core/services/sdm/logisticcosts/idleness-docks.service';
import { IdlenessDocks } from './../../../../core/models/sdm/logisticcosts/idleness-docks';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../register/abstract-register';
import { AbstractService } from '../../../../core/services/abstract.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SDM_URL } from '../../../../app.api';
import { SelectFieldComponent } from '../../../../components/select/select-field/select-field.component';

@Component({
  selector: 'app-idleness-docks',
  templateUrl: './idleness-docks.component.html',
  styleUrls: ['./idleness-docks.component.scss']
})
export class IdlenessDocksComponent extends AbstractRegister<IdlenessDocks>
  implements OnInit {
  @ViewChild(DatatableApiComponent) grid: DatatableApiComponent;
  @ViewChild('establishment') establishment: SelectFieldComponent;

  depositors: Depositor[] = [];
  establishments: Establishment[] = [];

  columns = [
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.name', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'hourNotUsed', name: 'R$ / Hora Não Utilizada' }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _idlenessDocksService: IdlenessDocksService,
    private establishmentService: EstablishmentService
  ) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      hourNotUsed: new FormControl(''),
      createdBy: new FormControl(''),
      status: new FormControl('')
    });

    this.getEstablishments();
  }

  getEstablishments() {
    this.establishmentService.findAllPath('/findAll').subscribe(data => {
      this.establishments = data['body']['content'];
    });
  }

  establishmentChanged(establishment) {
    this.depositors = [];
    this.formulario.get('depositorId').setValue([]);
    if (establishment) {
      this.depositorService
        .findByEstablishmentId(establishment.id)
        .subscribe(data => {
          this.depositors = data.body['content'];

          if (this.depositors.length === 1) {
            this.formulario.get('depositorId').setValue(this.depositors[0]);
          }
        });
    }
  }

  formFillToEdit(row: object) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.disableFields(['establishmentId', 'depositorId']);
    this.fillDepositorWhenSelectGridCell(row);
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.value)
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.getRawValue())
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();

    this.establishment.items = this.establishments;
  }

  endPointIdlenessDocks = () => SDM_URL.logisticCosts.idlenessDocks.findAll;

  getNewInstance(): IdlenessDocks {
    return new IdlenessDocks();
  }
  getService(): AbstractService<IdlenessDocks> {
    return this._idlenessDocksService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'logistic-costs.idleness-docks';
  }
}
