import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DateFormatPipe } from '../../../../core/classes/datePipe';
import { DeliveryAuthorization } from '../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { DocksOptions } from '../../../../core/models/sdm/docks/dock-options';
import { OpNatureAuthorization } from '../../../../core/models/sdm/shared/enums/op-nature-authorization.enum';
import { SchedulesService } from '../../../../core/services/sdm/dock-management/schedules.service';
import { DocksOptionsService } from '../../../../core/services/sdm/docks/docks-options.service';
import { DocksSchedulingService } from '../../../../core/services/sdm/document/docks-scheduling.service';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { DocksScheduling } from './../../../../core/models/sdm/document/docks-scheduling';
import { Provider } from './../../../../core/models/sdm/provider-management/provider';
import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { ModalDimensioningInfoComponent } from './../dimensioning-info/dimensioning-info.component';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-allocation-finded',
  templateUrl: './allocation-finded.component.html',
  styleUrls: ['./allocation-finded.component.scss']
})
export class AllocationFindedComponent implements OnInit {
  title = 'Alocação 1 opção';

  depositor: Depositor[] = [];
  providers: Provider[] = [];
  deliveryAuthorization: DeliveryAuthorization = null;

  formEnabled = true;

  formAllocationFinded: FormGroup;

  alerts: any = [];
  dismissible = true;

  solicitation;

  selected: DocksScheduling[] = [];

  deliveryTime: number;

  bsModalRef: BsModalRef;

  subscriptions: Subscription[] = [];
  docksOptions: DocksOptions;

  columns: object[] = [
    {
      prop: 'dateScheduleDelivery',
      name: 'Data',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'initialHour', name: 'Início' },
    { prop: 'finalHour', name: 'Término' },
    { prop: 'priorVisualization.signatureDock', name: 'Doca' }
  ];

  @ViewChild('dataTableDocksSchedulings')
  dataTableDocksSchedulings: DatatableComponent;

  // @ViewChild('hdrTplFinded') hdrTplFinded: TemplateRef<any>;
  // @ViewChild('bodyTplFinded') bodyTplFinded: TemplateRef<any>;

  // columns: object[] = [
  //   { prop: 'authorizationkey', name: '# Solicitação', width: 150 },
  //   // { prop: 'documents.id', name: 'Fornecedor' },
  //   { prop: 'createdAt', name: 'Data', pipe: new DateTimeFormatPipe('en-US') },
  //   { prop: 'createdAt', name: 'Validade', pipe: new DateTimeFormatPipe('en-US') },
  //   { prop: 'statusAuthorization', name: 'Status' },
  //   { prop: 'updatedAt', name: 'Data Ult. Alteração', pipe: new DateTimeFormatPipe('en-US') },
  // ];

  availableSchedulingsRows: DocksScheduling[] = [];

  @ViewChild('grdAllocationFinded') grdAllocationFinded: DatatableApiComponent;

  constructor(
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _docksSchedulingService: DocksSchedulingService,
    public router: Router,
    public modalService: BsModalService,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _docksOptionsService: DocksOptionsService,
    public schedulesService: SchedulesService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    // this.columns.splice(3, 0, {
    //   name: 'Doca',
    //   cellTemplate: this.bodyTplFinded,
    //   headerTempalte: this.hdrTplFinded,
    //   width: 230
    // });

    this.formAllocationFinded = new FormGroup({
      depositorId: new FormControl(''),
      providerId: new FormControl(''),
      deliveryAuthorization: new FormControl('')
    });

    this.route.queryParams.subscribe((params: any) => {
      try {
        this.title = params['title'];
      } catch (e) {
        
      }

      

      this.setDepositorFromQueryParams(params);
      this.setProvidersFromQueryParams(params);
      this.setDocksSchedulings(params);
      this.getSolicitationFromQueryParams(params);

      try {
        this.deliveryTime = params['deliveryTime'] || null;
      } catch (e) {
        
      }
    });
  }

  setDepositorFromQueryParams(params: any) {
    try {
      this.depositor = JSON.parse(params['depositor']);
      this.formAllocationFinded.get('depositorId').setValue(this.depositor[0]);
    } catch (e) {
      
    }
  }

  setProvidersFromQueryParams(params: any) {
    try {
      this.providers = JSON.parse(params['provider']);
      this.formAllocationFinded.get('providerId').setValue(this.providers[0]);
    } catch (e) {
      
    }
  }

  setDocksSchedulings(params: any) {
    try {
      this.availableSchedulingsRows = JSON.parse(params['docksScheduling']);
    } catch (e) {
      
    }
  }

  getSolicitationFromQueryParams(params: any) {
    try {
      this.deliveryAuthorization = JSON.parse(params['deliveryAuthorization']);
      this.formAllocationFinded
        .get('deliveryAuthorization')
        .setValue(this.deliveryAuthorization[0]);

      this.title = `${this.title} ${
        this.deliveryAuthorization[0].vip ? 'Prioritária' : ''
      }`;
    } catch (e) {
      
    }
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  cancel() {
    this.openModalConfirmSolicitation(
      'Mensagem confirmação',
      'Status da Solicitação será alterado para Interrompida, Deseja continuar?'
    );
    // this.router.navigate(['sdm/home']);
  }

  openModalConfirmSolicitation(title: string, message: string) {
    const initialState = {
      title,
      message
    };
    this.bsModalRef = this.modalService.show(ModalDimensioningInfoComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Não';
    this.bsModalRef.content.confirmBtn = 'Sim';

    this.subscriptions.push(
      this.modalService.onHidden.subscribe(resp => {
        try {
          if (resp === 'OK') {
            const { id } = this.deliveryAuthorization[0];
            this._deliveryAuthorizationService
              .updateStatusAuthorization(
                id,
                +DeliveryAuthorizationEnum.INTERROMPIDA
              )
              .subscribe(() => {
                this.router.navigate([
                  'sdm/delivery-authorization/regular-delivery'
                ]);
              });
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  confirm() {
    if (!this.selected.length) {
      this.alertMsg('Selecione a janela a ser agendada!', 'warning');
      return;
    }

    if (this.selected[0]['reserveTypeOneOnScheduling']) {
      const { id: priorVisualizationId } = this.selected[0].priorVisualization;
      const { initialHour, finalHour, dateScheduleDelivery } = this.selected[0];
      const { id: deliveryAuthorizationId } = this.deliveryAuthorization[0];

      this.schedulesService
        .removeManagementSlotsReserveTypeOne(
          initialHour,
          finalHour,
          moment(dateScheduleDelivery).format('YYYY-MM-DD')
        )
        .pipe(
          switchMap(data => {
            return this._docksSchedulingService.removeSchedulingReserveTypeOne(
              priorVisualizationId,
              initialHour,
              finalHour,
              moment(dateScheduleDelivery).format('YYYY-MM-DD')
            );
          }),
          switchMap(data => {
            return this._deliveryAuthorizationService.partialUpdate(
              { usedReserveTypeOne: true },
              deliveryAuthorizationId
            );
          }),
          switchMap(data => {
            this.selected[0].deliveryAuthorization = this.deliveryAuthorization[0];
            this.selected[0].depositor = this.depositor[0];
            this.selected[0].establishment = this.depositor[0].establishment;
            this.selected[0].provider = this.providers[0];
            this.selected[0].deliveryTime = this.deliveryTime;
            this.selected[0].opNatureDelivery = OpNatureAuthorization.INBOUND;

            return this._docksSchedulingService.create(this.selected[0]);
          })
        )
        .subscribe(data => {
          

          if (data) {
            this.router.navigate(
              [
                'sdm/delivery-authorization/regular-delivery/generated-authorization'
              ],
              this.prepareQueryParams()
            );
          }
        });
    } else {
      this.selected[0].deliveryAuthorization = this.deliveryAuthorization[0];
      this.selected[0].depositor = this.depositor[0];
      this.selected[0].establishment = this.depositor[0].establishment;
      this.selected[0].provider = this.providers[0];
      this.selected[0].deliveryTime = this.deliveryTime;
      this.selected[0].opNatureDelivery = OpNatureAuthorization.INBOUND;

      this._docksSchedulingService
        .create(this.selected[0])
        .subscribe((data: DocksScheduling) => {
          if (data) {
            this.router.navigate(
              [
                'sdm/delivery-authorization/regular-delivery/generated-authorization'
              ],
              this.prepareQueryParams()
            );
          }
        });
    }
  }

  alertMsg(message?: string, type = 'success', timeout = 2500) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout
    });
  }

  onSelect({ selected }) {
    this.selected = selected;
  }

  prepareQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositor),
        provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization[0])
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  isConsecutive() {
    return this.deliveryAuthorization[0].deliveryConsecutive;
  }

  getRowClass(row) {
    return {
      prioritary: row['reserveTypeOneOnScheduling']
    };
  }
}
