import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ProviderManagement {

  emitEventTabBlackListFired = new EventEmitter();
  emitEventTabPriorityFired = new EventEmitter();
  emitEventTabCriticalItemsFired = new EventEmitter();
  emitEventTabImpreciseFields = new EventEmitter();
  emitEventTabDaysHoursResctriction = new EventEmitter();

  emitEventTabDimensioningTabProdPattern = new EventEmitter();

  emitEventWhenTabCompatibilityDocks = new EventEmitter();

  tabBlackListFired = () => this.emitEventTabBlackListFired.emit();
  tabPriorityFired = () => this.emitEventTabPriorityFired.emit();
  tabCriticalItemsFired = () => this.emitEventTabCriticalItemsFired.emit();
  tabImpreciseFieldsFired = () => this.emitEventTabImpreciseFields.emit();
  tabDaysHoursRestrictionFired = () => this.emitEventTabDaysHoursResctriction.emit();

  tabDocksDimensioningProductivityPatternFired = () => this.emitEventTabDimensioningTabProdPattern.emit();

  tabGroupsCompatibilityDocksFired = () => this.emitEventWhenTabCompatibilityDocks.emit();

}
