import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CurrencyMaskModule } from 'ngx-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment as env } from '../environments/environment';
import { AppComponent } from './app.component';
import { ApplicationErrorHandler } from './app.error-handler';
import { EstablishmentDepositorsComponent } from './components/establishment-depositors/establishment-depositors.component';
import { ModalContentComponent } from './components/input/input-field-query/input-field-query.component';
import { ModalQueryComponent } from './components/modal-query/modal-query.component';
import { DateFormatPipe } from './core/classes/datePipe';
import { DateTimeFormatPipe } from './core/classes/dateTimePipe';
import { CoreModule } from './core/core.module';
import { AuthService } from './core/services/auth.service';
import { LayoutModule } from './layout/layout.module';
import { NotFoundComponent } from './routes/default/not-found/not-found.component';
import {
  ModalContentMessageComponent,
  ModalDeliveryAuthorizationComponent,
} from './routes/delivery-authorization/abstract-delivery';
import { DeliveryAuthorizationModule } from './routes/delivery-authorization/delivery-authorization.module';
import { ModalDimensioningInfoComponent } from './routes/delivery-authorization/regular-delivery/dimensioning-info/dimensioning-info.component';
import { ModalDeliveryAuthMassiveUploadComponent } from './routes/delivery-authorization/regular-delivery/massive-upload/massive-upload.component';
import { SbawHomeComponent } from './routes/home/sbaw-home/sbaw-home.component';
import { ParametrizationModule } from './routes/parametrizations/parametrization.module';
import { RegisterModule } from './routes/register/register.module';
import { RoutesModule } from './routes/routes.module';
import { AuthGuardService as AuthGuard } from './security/auth-guard.service';
import { DateOnlyPipe } from './shared/directives/date-only/date-only';
import { DateOnlyDirective } from './shared/directives/date/date.directive';
import { Decimal } from './shared/directives/number/decimal-format.directive';
import { DecimalOnlyDirective } from './shared/directives/number/decimal.directive';
import { IntegerOnlyDirective } from './shared/directives/number/integer.directive';
import { SharedModule } from './shared/shared.module';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SbawHomeComponent,
    ModalQueryComponent,
    DateFormatPipe,
    DateTimeFormatPipe,
    DateOnlyDirective,
    IntegerOnlyDirective,
    DecimalOnlyDirective,
    Decimal,
    DateOnlyPipe,
    ModalContentComponent,
    ModalContentMessageComponent,
    ModalDeliveryAuthorizationComponent,
    ModalDeliveryAuthMassiveUploadComponent,
    ModalDimensioningInfoComponent,
    EstablishmentDepositorsComponent,
  ],
  imports: [
    CurrencyMaskModule,
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 2500,
      closeButton: true,
      progressBar: true,
    }),
    ModalDialogModule.forRoot(),
    NgxSpinnerModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    RegisterModule,
    ParametrizationModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    TooltipModule.forRoot(),
    DeliveryAuthorizationModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [`${env.host.sbaw}/login`],
      },
    }),
  ],
  exports: [ModalQueryComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: ApplicationErrorHandler },
    AuthService,
    AuthGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalQueryComponent,
    ModalContentComponent,
    ModalContentMessageComponent,
    ModalDeliveryAuthorizationComponent,
    ModalDeliveryAuthMassiveUploadComponent,
    ModalDimensioningInfoComponent,
  ],
  // schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
