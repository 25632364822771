import { SDM_URL } from './../../../../app.api';
import { environment as env } from './../../../../../environments/environment';
import { HolidayRelationShip } from './../../../models/sdm/docks/holiday-relationship';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HolidayRelationshipService extends AbstractService<HolidayRelationShip> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.holidayRelationShip.default);
  }
}
