import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sbawSavingType' })
export class sbawSavingTypeConverter implements PipeTransform {

    private listBr = [
        { id: 0, name: "RELATIVO" },
        { id: 1, name: "ABSOLUTO" },
    ];

    private listEn = [
        { id: 0, name: "RELATIVE" },
        { id: 1, name: "ABSOLUTE" },
    ];

    private locales = [
        { list: this.listBr, lang: "br" },
        { list: this.listEn, lang: "en" }
    ];

    transform(value) {
        let lang = navigator.language.toLowerCase();

        for (let localeStatus of this.locales) {
            if (lang.endsWith(localeStatus.lang)) {

                for (let config of localeStatus.list) {
                    if (config.id === value) {
                        return config.name;
                    }
                }
                return "";

            }
        }
    }
}