import { Provider } from './../../provider-management/provider';
import { Depositor } from './../../depositor';
import { AbstractStatusModel } from '../../../abstractStatus.model';
import { PriorVisualization } from '../../docks/prior-visualization';

export class ReverseDeliveryManualAllocation extends AbstractStatusModel {
  public depositorId: number;
  public providerId: number;
  public vehicleTypeId: number;
  public operationSubNature: number;
  public dateSchedule: number;
  public docks: PriorVisualization[];
  public iniHour: string;
  public finHour: string;
  public eventName: string;
  public recurrent: boolean;
  public endsAfterEvents: string;
  public endsAfterDay: number;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;
  public everyDayOfMonth: number;
  public scheduleNumber: string;
  public conflictSchedule: boolean;
  public updateSchedule: number;

  public depositor: Depositor;
  public provider: Provider;

  public dateScheduleDeliveryNew: string;
  public priorVisualizationNew: PriorVisualization;
  public initialHourNew: string;
  public finalHourNew: string;

  public providerShipping: Provider;

  constructor() {
    super();
    this.updateSchedule = 1;
    this.iniHour = '';
    this.finHour = '';
    this.eventName = '';
    this.recurrent = false;
    this.endsAfterEvents = '';
    this.endsAfterDay = 0;
    this.monday = false;
    this.tuesday = false;
    this.wednesday = false;
    this.thursday = false;
    this.friday = false;
    this.saturday = false;
    this.sunday = false;
    this.everyDayOfMonth = 0;
    this.scheduleNumber = '';
    this.conflictSchedule = false;
  }
}
