import { AbstractStatusModel } from '../../abstractStatus.model';

export class CompatibilityDocksGroups extends AbstractStatusModel {
  public compatibilityDocksId: number;
  public productivityGroupId: number;
  public priorVisualizationId: number;

  constructor(
    productivityGroupId: number,
    priorVisualizationId: number,
    compatibilityDocksId = null
  ) {
    super();
    this.productivityGroupId = productivityGroupId;
    this.compatibilityDocksId = compatibilityDocksId;
    this.priorVisualizationId = priorVisualizationId;
    this.status = true;
  }
}
