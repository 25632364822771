import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'sbawConfigConverter' })
export class sbawConfigConverter implements PipeTransform {

    private listBr = [
        { id: 0, name: "DESATIVADO" },
        { id: 1, name: "COMPLEMENTAR" },
        { id: 2, name: "SOBRESCREVER" }
    ];

    private listEn = [
        { id: 0, name: "OFF" },
        { id: 1, name: "COMPLEMENTARY" },
        { id: 2, name: "OVERLAY" }
    ];

    private locales = [
        { list: this.listBr, lang: "br" },
        { list: this.listEn, lang: "en" }
    ];

    transform(value) {
        let lang = navigator.language.toLowerCase();

        for (let localeStatus of this.locales) {
            if (lang.endsWith(localeStatus.lang)) {

                for (let config of localeStatus.list) {
                    if (config.id === value) {
                        return config.name;
                    }
                }
                return "";

            }
        }
    }
}