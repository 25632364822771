import { PriorVisualizationService } from './../../../../core/services/sdm/docks/prior-visualization.service';
import { SDM_URL } from './../../../../app.api';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { EstablishmentService } from './../../../../core/services/establishment.service';
import { AuthService } from './../../../../core/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PriorVisualization } from '../../../../core/models/sdm/docks/prior-visualization';

@Component({
  selector: 'app-dock-order',
  templateUrl: './dock-order.component.html',
  styleUrls: ['./dock-order.component.scss']
})
export class DockOrderComponent implements OnInit {
  formSequenceDocks: FormGroup;
  establishments = [];

  establishmentId: number = 0;

  formulario;

  selectedDocks: PriorVisualization;

  columnsPriorVisualization: object[] = [
    { prop: 'id', name: '# Doca SDM' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'sequence', name: 'Sequência' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'initialHour', name: 'Horário início' },
    { prop: 'finalHour', name: 'Horário término' }
  ];

  @ViewChild('gridPrioVisualization') gridDocks: DatatableApiComponent;

  constructor(
    public authService: AuthService,
    public establishmentService: EstablishmentService,
    private _priorVisualizationService: PriorVisualizationService
  ) {}

  ngOnInit() {
    this.formSequenceDocks = new FormGroup({
      establishmentId: new FormControl('', [Validators.required]),
      sequence: new FormControl('', [Validators.required, Validators.min(1)])
    });

    const { id } = this.authService.getUser();

    this.establishmentService
      .findByUserId(id)
      .subscribe(data => (this.establishments = data));
  }

  makeEndPointForPriorVisualization = () =>
    SDM_URL.docks.priorVisualization.findAllBySequenceOrderAsc.replace(
      ':establishmentId',
      this.establishmentId.toString()
    );

  docksSelected(docks) {
    this.selectedDocks = docks;
    
  }

  changeEstablishmentId(establishment) {
    this.establishmentId = !establishment ? 0 : establishment['id'];

    setTimeout(() => this.gridDocks.reloadPage(), 1);
  }

  savePosition() {
    if (this.formSequenceDocks.valid && this.selectedDocks) {
      this._priorVisualizationService
        .updatePosition(
          this.selectedDocks['signatureDock'],
          this.formSequenceDocks.get('sequence').value,
          this.establishmentId
        )
        .subscribe(() => this.gridDocks.reloadPage());
    } else {
      this.verificaValidacoesForm(this.formSequenceDocks);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
    });
  }
}
