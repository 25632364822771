import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { RegularDeliverySmallSize } from '../../../models/sdm/regular-delivery/regular-delivery-small-size';
import { AbstractService } from '../../abstract.service';
import { SDM_URL } from './../../../../app.api';

@Injectable({
  providedIn: 'root'
})
export class RegularDeliverySmallSizeService extends AbstractService<
  RegularDeliverySmallSize
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.regularDelivery.smallSize.default);
  }

  createRegularDeliverySmallSize(
    model: RegularDeliverySmallSize[]
  ): Observable<RegularDeliverySmallSize> {
    return this.http.post<RegularDeliverySmallSize>(
      SDM_URL.regularDelivery.smallSize.create,
      model
    );
  }

  findByDepositorId(depositorId: number): Observable<RegularDeliverySmallSize> {
    return this.http
      .get<RegularDeliverySmallSize>(
        `${SDM_URL.regularDelivery.smallSize.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )}`
      )
      .pipe(take(1));
  }
}
