import { DimensioningInfoService } from './../../../../core/services/sdm/delivery-authorization/regular-delivery/dimensioning-info.service';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  Params,
  Router,
  NavigationExtras
} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RescheduleValidationTime } from '../../../../core/models/sdm/delivery-authorization/reschedule-validation-time';
import { PriorVisualization } from '../../../../core/models/sdm/docks/prior-visualization';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { AuthService } from '../../../../core/services/auth.service';
import { ModalCustomComponent } from './../../../../components/modal-custom/modal-custom.component';
import { Authorization } from './../../../../core/models/sdm/delivery-authorization/authorization';
import { DeliveryAuthorization } from './../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-cancel-validation-term',
  templateUrl: './cancel-validation-term.component.html',
  styleUrls: ['./cancel-validation-term.component.scss'],
  preserveWhitespaces: true
})
export class CancelValidationTermComponent implements OnInit {
  bsModalRef: BsModalRef;
  formCancelValidationTerm: FormGroup;

  subscriptions: Subscription[] = [];
  formValues = [];

  depositor: Depositor;
  depositors: Depositor[] = [];
  providers: Provider[] = [];
  docks: PriorVisualization[] = [];
  authorizations: Authorization[] = [];

  formEnabled = true;

  alerts: any = [];
  dismissible = true;

  authorization: Authorization;
  deliveryAuthorization: DeliveryAuthorization;

  constructor(
    private _route: Router,
    public modalService: BsModalService,
    private _actvRouter: ActivatedRoute,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    sanitizer: DomSanitizer,
    private _authService: AuthService,
    private _dimensioningInfoService: DimensioningInfoService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.formCancelValidationTerm = new FormGroup({
      depositor: new FormControl(''),
      providerId: new FormControl(''),
      authorizationkey: new FormControl(''),
      validityDate: new FormControl(''),
      rescheduleValidityDate: new FormControl(''),
      dateScheduleDelivery: new FormControl(''),
      carShowSchedule: new FormControl(''),
      initialHour: new FormControl(''),
      finalHour: new FormControl(''),
      priorVisualization: new FormControl(''),
      profileSolicit: new FormControl(''),
      authorization: new FormControl('')
    });

    this._actvRouter.queryParams.subscribe((params: Params) => {
      try {
        this.authorization = JSON.parse(params['authorization']);
        this.authorizations = [this.authorization];

        this.formCancelValidationTerm
          .get('authorization')
          .setValue(this.authorization);

        this.getDepositorFromQueryParams(this.authorization);

        this.getProvidersFromQueryParams(this.authorization['documents']);
      } catch (e) {}
    });
  }

  alertMsg(message?: string, type = 'success', timeout = 2500) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout: timeout
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  getDepositorFromQueryParams(deliveryAuthorization: any) {
    try {
      this.depositor = deliveryAuthorization['depositor'];
      this.depositors = [this.depositor];
      this.formCancelValidationTerm.get('depositor').setValue(this.depositor);

      this.getDocksScheduling(this.depositor.id, deliveryAuthorization.id);
    } catch (e) {
      console.error(e);
    }
  }

  getDocksScheduling(depositorId: number, deliveryAuthorizationId: number) {
    this._deliveryAuthorizationService
      .findDocksScheduling(depositorId, deliveryAuthorizationId)
      .subscribe((data: RescheduleValidationTime) => {
        this.formCancelValidationTerm.patchValue(data);
        this.formCancelValidationTerm.patchValue(data.docksScheduling);
        this.docks = [data.priorVisualization];

        this.formCancelValidationTerm
          .get('priorVisualization')
          .setValue(this.docks[0]);
      });
  }

  getProvidersFromQueryParams(documents: any) {
    try {
      documents.forEach((doc: object) => {
        this.providers.push(doc['provider']);
      });
      if (this.providers.length === 1) {
        this.formCancelValidationTerm
          .get('providerId')
          .setValue(this.providers[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.authorization)
      },
      skipLocationChange: true
    };

    return navigationExtras;
  }

  exit() {
    this._route.navigate(
      ['sdm/delivery-authorization/edit/cancel-delivery-authorization'],
      this.queryParams()
    );
  }

  confirmContinue() {
    this.openModalConfirmNoShow();
  }

  openModalConfirmNoShow() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja Cancelar? A Autorização será cancelada e portanto não será possível efetuar a entrega dos DOCs em questão.',
      classBtnSuccess: 'btn-outline-danger',
      classBtnDanger: 'btn-success'
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._deliveryAuthorizationService
              .cancelAuthorization(this.authorization.id)
              .pipe(
                mergeMap(() => {
                  return this._dimensioningInfoService.deleteByDeliveryAuthorization(
                    this.authorization.id
                  );
                })
              )
              .subscribe(
                data => {
                  const navigationExtras: NavigationExtras = {
                    queryParams: {
                      message: `Cancelamento efetuado com sucesso.`,
                      type: 'success'
                    },
                    skipLocationChange: true
                  };

                  this._route.navigate(
                    ['sdm/delivery-authorization/edit'],
                    navigationExtras
                  );
                },
                ({ error }) => {
                  console.error(error);
                  this.alertMsg(error.message, 'danger');
                }
              );
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  isDepositor() {
    let isDepositor =
      this._authService.getUserRoles().filter(r => r.code === 'DEPOSITOR') ||
      [];
    return isDepositor.length > 0;
  }

}
