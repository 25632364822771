import { AbstractStatusModel } from '../../abstractStatus.model';
import { CharacterType } from '../shared/enums/CharacterType';
import { IDeserializable } from '../../deserializable.model';

export class RmAuthorization extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public requiredRma: boolean;
  public size: number;
  public rmaType: CharacterType;
  public allowRepeat: boolean;
  public numberAndNFSerie: boolean;
  public emissionDate: boolean;
  public documentSender: boolean;
  public documentRecipient: boolean;
  public documentShipping: boolean;

  constructor() {
    super();
    this.requiredRma = false;
    this.rmaType = CharacterType.DEFAULT;
    this.allowRepeat = true;
    this.numberAndNFSerie = true;
    this.emissionDate = true;
    this.documentSender = true;
    this.documentRecipient = false;
    this.documentShipping = true;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
