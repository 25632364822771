import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseFormComponent } from '../../../../shared/base-form/base-form.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SDM_URL } from '../../../../app.api';
import { DepositorService } from '../../../../core/services/depositor.service';
import { ReverseDeliveryService } from '../../../../core/services/sdm/reverse-delivery/reverse-delivery.service';
import { BooleanConverter } from '../../../../core/classes/boolean-type';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { delay } from 'rxjs/operators';
import { ReverseDelivery } from '../../../../core/models/sdm/reverse-delivery/reverse-delivery';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { ReverseDeliveryEvents } from '../reverse-delivery.service';
import { AbstractDelivery } from '../../regular-delivery/abstract-delivery';
import { AbstractService } from '../../../../core/services/abstract.service';

@Component({
  selector: 'app-reverse-delivery-form',
  templateUrl: './reverse-delivery-form.component.html',
  styleUrls: ['./reverse-delivery-form.component.scss'],
})
export class ReverseDeliveryFormComponent extends AbstractDelivery<ReverseDelivery> implements OnInit {

  public depositors = [];
  public fixedDoc = false;
  public containsNF = false;
  public fields = ['description'];
  public reverseDelivery: ReverseDelivery;
  showNextQuestion = false;

  @ViewChild('tabs') tabs: TabsetComponent;
  @ViewChild('dataGrdReverseDelivery') grid: DatatableApiComponent;

  columnsReverseDelivery = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public depositorService: DepositorService,
    private _reverseDeliveryService: ReverseDeliveryService,
    public translatorService: TranslatorService,
    public reverseDeliveryServiceEvents: ReverseDeliveryEvents) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this.reverseDelivery = new ReverseDelivery();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      shippingRequest: new FormControl(''),
      providerRequest: new FormControl(this.reverseDelivery.providerRequest),
      internalUserRequest: new FormControl(this.reverseDelivery.internalUserRequest),
      directAllocation: new FormControl(''),
      infoDataOnNF: new FormControl(''),
      linkNfOrQtd: new FormControl(''),
      maxValueForEnableVehicle: new FormControl('', [Validators.min(1), Validators.max(999)]),
      containsNfOfOthersDepositors: new FormControl(this.reverseDelivery.containsNfOfOthersDepositors),

      fixedDoca: new FormControl(''),
      docaNumber: new FormControl(''),

      dimensionProductivity: new FormControl(this.reverseDelivery.dimensionProductivity),
      minAntecedenceForShippingToCome: new FormControl('', [Validators.min(1), Validators.max(999)]),
      maxAntecedenceForShippingToCome: new FormControl('', [Validators.min(1), Validators.max(999)]),
    });
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabRma':
        this.reverseDeliveryServiceEvents.tabRmaFired();
        break;
      case 'tabReqMax':
        this.reverseDeliveryServiceEvents.tabReqMaxFired();
        break;
      case 'tabSmallSize':
        this.reverseDeliveryServiceEvents.tabSmallSizeFired();
        break;
      case 'tabNfDeadLines':
        this.reverseDeliveryServiceEvents.tabNfDeadLinesFired();
        break;
    }
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.reverseDelivery);
    this.formulario.get('fixedDoca').disable();
    this.formulario.get('docaNumber').disable();
  }

  resetar() {
    this.formulario.reset();
    this.formulario.disable();
    this.formEnabled = false;
    this.containsNF = false;
    this.fixedDoc = false;
  }

  changeFixedDoca(event) {
    this.fixedDoc = (event.target.value === 'true');
    this.formulario.get('docaNumber').enable();
    if (!this.fixedDoc) {
      this.formulario.get('docaNumber').setValue('');
    }
  }

  changeContainsNf(event) {
    this.containsNF = (event.target.value === 'true');
    this.formulario.get('fixedDoca').enable();
    if (!this.containsNF) {
      this.formulario.get('fixedDoca').setValue(false);
      this.formulario.get('fixedDoca').disable();
      this.formulario.get('docaNumber').setValue('');
      this.fixedDoc = false;
    }
  }

  changeDirecteAllocation(event) {
    if (this.formulario.get('directAllocation').value === 'true') {
      this.setRequiredForFields(['infoDataOnNF', 'linkNfOrQtd', 'maxValueForEnableVehicle']);
      this.enableFields(['infoDataOnNF', 'linkNfOrQtd', 'maxValueForEnableVehicle']);
      return;
    }
    this.removeRequiredForFields(['infoDataOnNF', 'linkNfOrQtd', 'maxValueForEnableVehicle']);
    this.disableFields(['infoDataOnNF', 'linkNfOrQtd', 'maxValueForEnableVehicle']);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    if (!row.containsNfOfOthersDepositors) {
      this.formulario.get('fixedDoca').disable();
      this.formulario.get('docaNumber').disable();
    }
  }

  makeEndPointForReverseDelivery = () => SDM_URL.reverseDelivery.default;

  getComponentName() {
    return 'reverse-delivery';
  }

  getNewInstance(): ReverseDelivery {
    return new ReverseDelivery();
  }
  getService(): AbstractService<ReverseDelivery> {
    return this._reverseDeliveryService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  desableNextQuestion(event)
  {
    if(event.target.value === 'true'){
      this.showNextQuestion = true;
    }
    else
    {
      this.showNextQuestion = false;
    }
  }

  validateInputsForm(event, controllerName) {
    let inputValue = event.target.value;
    if (inputValue < 0 || (!(inputValue % 1 === 0))) {
      this.formulario.get(controllerName).setErrors({ pattern: true });
    }
    else {
      this.formulario.get(controllerName).clearValidators();
      this.formulario.get(controllerName).updateValueAndValidity();
    }
  }

}
