import { HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '../../../node_modules/@angular/router';
import { environment as env } from '../../environments/environment';
import { AuthService } from './../core/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  private refresh = '/wrapper/user-domain/refresh';
  private login = '/wrapper/user-domain/login';

  isLogin(request): Boolean {
    return request.url.endsWith(`${this.login}`);
  }

  isRefresh(request): Boolean {
    return request.url.endsWith(`${this.refresh}`);
  }

  isService(request): Boolean {
    return request.url.startsWith(`${env.host.sbaw}`);
  }

  isTokenRequired(request): Boolean {
    return (this.isService(request) && !this.isLogin(request));
  }

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private spinner: NgxSpinnerService, public auth: AuthService, public router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let cloned: HttpRequest<any>;

    if (this.isTokenRequired(request) && this.auth.isAuthenticated()) {
      let isRefresh = this.isRefresh(request);
      cloned = this.setToken(request, isRefresh ? this.auth.getAuthToken() : this.auth.checkAndRefresh());
    } else {
      cloned = request.clone();
    }



    if (request.method !== 'GET') {
      this.router.url;
    }

    this.increaseRequest();

    return next.handle(cloned).pipe(
      finalize(() => {
        this.decreaseRequest();
      }),
    );

  }

  setToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({ headers: request.headers.set('Authorization', token) });
  }

  increaseRequest() {
    this.totalRequests++;
    this.spinner.show();
    
  }

  decreaseRequest() {
    setTimeout(() => {
      this.totalRequests--;
      if (this.totalRequests < 1) {
        this.spinner.hide();
        
      }
    }, 20);
  }
}
