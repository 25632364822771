export class QueryDelivery {
  public depositorId: string;
  public providerId: string;
  public deliveryAuthorizationId: boolean;
  public docOk: boolean;
  public interrupt: boolean;
  public pending: boolean;
  public approved: boolean;
  public reschedule: boolean;
  public manualAllocation: boolean;
  public approvedWithoutSchedule: boolean;
  public expired: boolean;
  public canceled: boolean;
  public noShow: boolean;
  public accomplished: boolean;
  public initDate: string;
  public finDate: string;

  constructor() {
    this.depositorId = '';
    this.providerId = '';
    this.deliveryAuthorizationId = false;
    this.docOk = false;
    this.interrupt = false;
    this.pending = false;
    this.approved = false;
    this.reschedule = false;
    this.manualAllocation = false;
    this.approvedWithoutSchedule = false;
    this.expired = false;
    this.canceled = false;
    this.noShow = false;
    this.accomplished = false;
    this.initDate = '';
    this.finDate = '';
  }
}
