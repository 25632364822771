import { ManagementDocksAvailableDTO } from './../../../models/sdm/management-conflict/management-docks-available';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { DocksScheduling } from '../../../models/sdm/document/docks-scheduling';
import { AbstractService } from '../../abstract.service';
import { SDM_URL } from './../../../../app.api';
import { DocksSchedulingSearchDTO } from '../../../models/sdm/delivery-authorization/docks-scheduling-search-dto';

@Injectable({
  providedIn: 'root'
})
export class DocksSchedulingService extends AbstractService<DocksScheduling> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.deliveryAuthorization.docksScheduling.default
    );
  }

  findByEstablishmentId(
    establishmentId: number
  ): Observable<DocksScheduling[]> {
    return this.http.get<DocksScheduling[]>(
      `${SDM_URL.deliveryAuthorization.docksScheduling.findByEstablishmentId.replace(
        ':establishmentId',
        establishmentId.toString()
      )}`
    );
  }

  rescheduleDelivery(
    docksScheduleId: number,
    docksScheduling: DocksScheduling,
    reserveTypeInternalUserUpdate: number
  ): Observable<DocksScheduling> {
    return this.http
      .post<DocksScheduling>(
        `${SDM_URL.deliveryAuthorization.docksScheduling.rescheduleDelivery
          .replace(':docksScheduleId', docksScheduleId.toString())
          .replace(
            ':reserveTypeInternalUserUpdate',
            reserveTypeInternalUserUpdate.toString()
          )}`,
        docksScheduling
      )
      .pipe(take(1));
  }

  findByDocksScheduling(
    priorVisualizationId: number,
    initialHour: string,
    finalHour: string,
    dateScheduleDelivery: string
  ): Observable<DocksScheduling[]> {
    return this.http
      .get<DocksScheduling[]>(
        `${SDM_URL.deliveryAuthorization.docksScheduling.findByDocksScheduling
          .replace(':priorVisualizationId', priorVisualizationId.toString())
          .replace(':initialHour', initialHour)
          .replace(
            ':finalHour',
            finalHour
          )}?dateScheduleDelivery=${dateScheduleDelivery}`
      )
      .pipe(take(1));
  }

  removeSchedulingReserveTypeOne(
    priorVisualizationId: number,
    initialHour: string,
    finalHour: string,
    dateScheduleDelivery: string
  ): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${SDM_URL.deliveryAuthorization.docksScheduling.removeSchedulingType
        .replace(':priorVisualizationId', priorVisualizationId.toString())
        .replace(':initialHour', initialHour)
        .replace(
          ':finalHour',
          finalHour
        )}?dateScheduleDelivery=${dateScheduleDelivery}`
    );
  }

  findBySlotsAvailable(
    docksSchedulingSearchDTO: DocksSchedulingSearchDTO
  ): Observable<ManagementDocksAvailableDTO[]> {
    return this.http.get<ManagementDocksAvailableDTO[]>(
      `${SDM_URL.deliveryAuthorization.docksScheduling.findBySlotsAvailable
        .replace(
          ':establishmentId',
          docksSchedulingSearchDTO.establishmentId.toString()
        )
        .replace(
          ':depositorSolicitation',
          docksSchedulingSearchDTO.depositorSolicitation.toString()
        )
        .replace(':qtdSlots', docksSchedulingSearchDTO.qtdSlots.toString())
        .replace(
          ':deliveryAuthorizationId',
          docksSchedulingSearchDTO.deliveryAuthorizationId.toString()
        )}?initialDate=${docksSchedulingSearchDTO.initialDate}&finalDate=${
        docksSchedulingSearchDTO.finalDate
      }&depositorId=${docksSchedulingSearchDTO.depositorId}&opNature=${
        docksSchedulingSearchDTO.opNature
      }&vehicleTypeId=${docksSchedulingSearchDTO.vehicleTypeId}&docksId=${
        docksSchedulingSearchDTO.docksId
          ? docksSchedulingSearchDTO.docksId.join(',')
          : ''
      }&productivityGroupsId=${
        docksSchedulingSearchDTO.productivityGroupsId
          ? docksSchedulingSearchDTO.productivityGroupsId.join(',')
          : ''
      }&initialHour=${docksSchedulingSearchDTO.initialHour}`
    );
  }
}

// removeSchedulingType: `${
//   env.host.sdm
// }${WRAPPER}/delivery-authorization/docks-scheduling/priorVisualization/:priorVisualizationId/initialHour/:initialHour/finalHour/:finalHour/vip`
