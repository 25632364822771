import { AuthService } from './../../core/services/auth.service';
import { DepositorService } from './../../core/services/depositor.service';
import { EstablishmentService } from './../../core/services/establishment.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-establishment-depositors',
  templateUrl: './establishment-depositors.component.html',
  styleUrls: ['./establishment-depositors.component.scss']
})
export class EstablishmentDepositorsComponent implements OnInit {
  establishments = [];
  depositors = [];

  @Input() form: FormGroup;

  constructor(
    private establishmentService: EstablishmentService,
    private depositorService: DepositorService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const { id } = this.authService.getUser();

    this.establishmentService
      .findByUserId(id)
      .subscribe(data => (this.establishments = data));
  }

  establishmentChanged(value) {
    if (!value) {
      return;
    }

    this.depositorService.findByEstablishmentId(value.id).subscribe(data => {
      this.depositors = data.body['content'];
    });
  }
}
