import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';

const INPUT_NUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
};

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [INPUT_NUMBER_VALUE_ACCESSOR]
})
export class InputNumberComponent implements ControlValueAccessor {
  @Input() classeCss;
  @Input() id: string;
  @Input() label = '';
  @Input() name = '';
  @Input() i18n = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() messageHelp: string;
  @Input() min = 0;
  @Input() max = 99999;

  @ViewChild('inputNumber') inputNumber: ElementRef;

  private innerValue: any;

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  constructor(private _renderer: Renderer2) {}

  readOnlyInput = () => this.form.disabled;

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    try {
      this.onTouchedCb = fn;
    } catch (e) {
      console.error(e);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    // this.isReadOnly = isDisabled;
    this._renderer.setProperty(
      this.inputNumber.nativeElement,
      'disabled',
      isDisabled
    );
  }

  isInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }
}
