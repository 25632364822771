import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatatableApiService {

  gridRowSelected = new EventEmitter();

  selectedGridRow = (row) => this.gridRowSelected.emit(row);

}
