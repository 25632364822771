import { FormGroup } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pattern-buttons',
  templateUrl: './pattern-buttons.component.html',
  styleUrls: ['./pattern-buttons.component.scss'],
  preserveWhitespaces: true,
})
export class PatternButtonsComponent implements OnInit {

  @Input() formName = '';
  @Input() formulario: FormGroup;
  @Input() labelButtonConfirm = '';

  @Output() confirmEvent = new EventEmitter();
  @Output() cancelEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  confirm() {
    this.confirmEvent.emit();
  }

  cancel() {
    this.cancelEmit.emit();
  }

}
