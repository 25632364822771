import { CompatibilityProviderGroups } from './compatibility-provider-groups';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class CompatibilityProvider extends AbstractStatusModel {

  public establishmentId: number;
  public description: string;
  public depositorId: number;
  public document: number;

  public providerGroups: CompatibilityProviderGroups[];

  constructor() {
    super();
  }

}
