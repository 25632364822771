import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  constructor() { }

  columns = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'name', name: 'Nome' },
    { prop: 'nickName', name: 'Apelido' },
    // { prop: 'password', name: 'Senha' },
    { prop: 'profile', name: 'Perfil' }
  ];

  ngOnInit() {
  }

}
