import { AbstractService } from '../../abstract.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { Observable } from 'rxjs';
import { ReverseDeliveryRequestMax } from '../../../models/sdm/reverse-delivery/reverse-delivery-request-max';

@Injectable()
export class ReverseDeliveryRequestMaxService extends AbstractService<ReverseDeliveryRequestMax> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.reverseDelivery.requestMax.default);
  }

  createRevDeliveryReqMaxParam(model: ReverseDeliveryRequestMax[]): Observable<ReverseDeliveryRequestMax> {
    
    return this.http.post<ReverseDeliveryRequestMax>(SDM_URL.reverseDelivery.requestMax.create, model);
  }

}
