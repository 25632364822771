import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'model-form-buttons',
  templateUrl: './model-form-buttons.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./model-form-buttons.component.scss']
})
export class ModelFormButtonsComponent implements OnInit {

  @Input() formulario: FormGroup;
  @Input() formName: string;
  @Input() btnGoBack = false;
  @Output() submitted = new EventEmitter();
  @Output() reseted = new EventEmitter();

  constructor(public location: Location) { }

  submit() {
    this.submitted.emit();
  }

  ngOnInit() {
  }

  resetar() {
    this.reseted.emit();
    // this.formEnabled = false
    // this.formEvent.emit(this.formEnabled)
  }

  goBack() {
    this.location.back();
  }

}
