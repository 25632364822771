import { Location } from '@angular/common';
import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeliveryAuthorization } from '../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { RegularDeliveryNF } from '../../../../core/models/sdm/regular-delivery/regular-delivery-nf';
import { RegularDeliveryNfService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-nf.service';
import { SDM_URL } from './../../../../app.api';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { DateTimeFormatPipe } from './../../../../core/classes/dateTimePipe';
import { StatusSolicitationPipe } from './../../../../core/classes/status-solicitation.pipe';
import { AuthService } from './../../../../core/services/auth.service';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-reschedule-approved-selection',
  templateUrl: './reschedule-approved-selection.component.html',
  styleUrls: ['./reschedule-approved-selection.component.scss'],
  preserveWhitespaces: true
})
export class RescheduleApprovedSelectionComponent implements OnInit {
  formRescheduleApprovedSel: FormGroup;
  formEnabled = true;
  solicitations = [];

  isAutoLoadGrid = true;
  countLoad = 0;
  deliveryAuthorizationSelected: DeliveryAuthorization;

  alerts: any = [];
  dismissible = true;

  deliveryAuthorization;

  dateToReschedule = moment(new Date());

  @ViewChild('grdQuerySolicit') grdQuerySolicit: DatatableApiComponent;

  columns: object[] = [
    { prop: 'authorizationkey', name: '# Solicitação', width: 150 },
    // { prop: 'documents.id', name: 'Fornecedor' },
    { prop: 'createdAt', name: 'Data', pipe: new DateTimeFormatPipe('en-US') },
    {
      prop: 'createdAt',
      name: 'Validade',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'statusAuthorization',
      name: 'Status',
      pipe: new StatusSolicitationPipe()
    },
    {
      prop: 'updatedAt',
      name: 'Data Ult. Ação',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  constructor(
    private _location: Location,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _router: Router,
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _regDeliveryNfDeadlineService: RegularDeliveryNfService,
    private _authService: AuthService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));

    this.dateToReschedule = moment(new Date());
  }

  ngOnInit() {
    this.formRescheduleApprovedSel = new FormGroup({
      authorizationKey: new FormControl('')
    });

    this._deliveryAuthorizationService
      .findByStatusAuthorizationIn([4, 6])
      .subscribe(data => {
        this.solicitations = [...data.body['content']];
      });

    this.formRescheduleApprovedSel
      .get('authorizationKey')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        

        this.grdQuerySolicit.reloadPage();
      });

    this.route.queryParams.subscribe((params: any) => {
      this.getDeliveryAuthorizationFromQueryParams(params);
      
    });
  }

  getDeliveryAuthorizationFromQueryParams(params: any) {
    try {
      this.deliveryAuthorization = JSON.parse(params['deliveryAuthorization']);

      if (
        this.deliveryAuthorization &&
        this.deliveryAuthorization.hasOwnProperty('authorizationkey')
      ) {
        if (this.deliveryAuthorization) {
          this.grdQuerySolicit.selected = [this.deliveryAuthorization];
          this.deliveryAuthorizationSelected = this.deliveryAuthorization;

          const userType = this._authService.getUserAuthority();

          this._regDeliveryNfDeadlineService
            .findByDepositorIdAndStatusTrue(
              this.deliveryAuthorization['depositor']['establishment'].id,
              this.deliveryAuthorization['depositor'].id
            )
            .subscribe((data: RegularDeliveryNF) => {
              

              if (data) {
                this.dateToReschedule = moment(new Date());
                if (/Internal/i.test(userType.type)) {
                  this.dateToReschedule.add(
                    data.minReschedulingConfirmInternalUser,
                    'd'
                  );
                } else {
                  this.dateToReschedule.add(
                    data.minReschedulingConfirmExternalUser,
                    'd'
                  );
                }
              }
            });

          this.formRescheduleApprovedSel
            .get('authorizationKey')
            .setValue(this.deliveryAuthorization['authorizationkey']);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  checkAllowReschedule(): boolean {
    return (
      this.dateToReschedule.format('YYYY-MM-DD') >
      this.deliveryAuthorization['validityDate']
    );
  }

  goBack() {
    this._router.navigate(['sdm/delivery-authorization/edit']);
  }

  continue() {
    if (!this.deliveryAuthorizationSelected) {
      this.alertMsg('Selecione a Solicitação para continuar', 'info');
      return;
    }

    this._router.navigate(
      ['sdm/delivery-authorization/edit/reschedule-term-validation'],
      this.queryParams()
    );
  }

  alertMsg(message?: string, type = 'success', timeout = 2500) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout: timeout
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(
          this.deliveryAuthorizationSelected
        )
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  endPointSolicits() {
    const url = [
      {
        name: 'authorizationkey',
        value:
          this.formRescheduleApprovedSel.get('authorizationKey').value || ''
      },
      { name: 'status', value: [3, 4, 5, 6, 9].join(',') }
    ];

    let queryString = '';
    const query = [];

    url.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    return `${
      SDM_URL.deliveryAuthorization.queryDeliveryApproved
    }?${queryString}&`;
  }

  gridAutoLoad() {
    if (this.isAutoLoadGrid && this.countLoad === 0) {
      this.countLoad++;
      return true;
    }
    return false;
  }

  selectedRow(row: DeliveryAuthorization) {
        this.deliveryAuthorizationSelected = row;
    this.deliveryAuthorization = this.deliveryAuthorizationSelected;

    this.validateIfAllowReschedule();
  }

  validateIfAllowReschedule() {
    if (this.checkAllowReschedule()) {
      return true;
    }

    return false;
  }

  selectedSolicit(event) {}
}
