import { Router } from '@angular/router';
import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { AuthService } from './../../../../core/services/auth.service';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { Depositor } from './../../../../core/models/sdm/depositor';

@Component({
  selector: 'app-schedule-without-option',
  templateUrl: './schedule-without-option.component.html',
  styleUrls: ['./schedule-without-option.component.scss'],
  preserveWhitespaces: true
})
export class ScheduleWithoutOptionComponent implements OnInit {
  depositor: Depositor[] = [];
  provider: Provider[] = [];
  solicitation = [];

  alerts: any = [];
  dismissible = true;

  formEnabled = true;
  formScheduleWithoutOption: FormGroup;
  providerId: any;
  depositors: any[];
  providers: Provider[] = [];
  deliveryAuthorization: any;

  constructor(
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _authService: AuthService,
    public _deliveryAuthorizationService: DeliveryAuthorizationService,
    public router: Router
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.formScheduleWithoutOption = new FormGroup({
      depositor: new FormControl(''),
      provider: new FormControl(''),
      deliveryAuthorizationId: new FormControl('')
    });

    this.route.queryParams.subscribe((params: any) => {
      this.getDepositorFromQueryParams(params);
      this.getProvidersFromQueryParams(params);
      this.getSolicitationFromQueryParams(params);
    });
  }

  getUserType(): boolean {
    const roles = this._authService.getUserRoles();
    return roles[0].type === 'Internal';
  }

  getDepositorFromQueryParams(params: any) {
    try {
      this.depositor = JSON.parse(params['depositor']);
      this.formScheduleWithoutOption
        .get('depositor')
        .setValue(this.depositor[0]);
    } catch (e) {
      console.error(e);
      this.depositor = null;
    }
  }

  getProvidersFromQueryParams(params: any) {
    try {
      this.providerId = JSON.parse(params['provider']);
      this.providers = this.providerId;
      this.formScheduleWithoutOption
        .get('provider')
        .setValue(this.providers[0]);
    } catch (e) {
      console.error(e);
      this.providerId = null;
    }
  }

  getSolicitationFromQueryParams(params: any) {
    try {
      this.deliveryAuthorization = JSON.parse(params['deliveryAuthorization']);
      this.formScheduleWithoutOption
        .get('deliveryAuthorizationId')
        .setValue(this.deliveryAuthorization[0]);

      this._deliveryAuthorizationService
        .updateStatusAuthorization(
          this.deliveryAuthorization[0]['id'],
          +DeliveryAuthorizationEnum.PENDENTE
        )
        .toPromise();
    } catch (e) {
      console.error(e);
      this.deliveryAuthorization = null;
    }
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  cancel() {}

  newAuthorization() {
    this.router.navigate(['sdm/delivery-authorization/regular-delivery']);
  }

  manualAllocationPending() {}
}
