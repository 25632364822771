import { SDM_URL } from './../../app.api';
import { environment as env } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ServiceInterface } from './service-interface';
import { Signature } from '../models/signature';
import { Observable } from 'rxjs';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable()
export class SignatureService extends AbstractService<Signature> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.signature.default);
  }

}
