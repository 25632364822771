import { Injectable } from "@angular/core";
import { AbstractService } from "../abstract.service";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";
import { MeasureUnitEstablishment } from "../../models/sdm/measure-unit/measure-unit-establishment";

@Injectable()
export class MeasureUnitEstablishmentService extends AbstractService<MeasureUnitEstablishment> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/measure-unit-establishment')
  }

}