import { HttpHeaderResponse } from '@angular/common/http';
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';
import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-massive-upload-files',
  templateUrl: './massive-upload-files.component.html',
  styleUrls: ['./massive-upload-files.component.scss'],
  preserveWhitespaces: true,
})
export class MassiveUploadFilesComponent implements OnInit, OnChanges {

  @Output() uploadSuccessEvent = new EventEmitter();
  @Output() uploadErrorEvent = new EventEmitter();

  @Input()
  set objAdditionalParameters(obj: object) {
    this._objAdditionalParameters = obj;
  }

  get objAdditionalParameters() {
    return this._objAdditionalParameters;
  }

  constructor(
    private spinner: NgxSpinnerService,
    public toaster: ToastrService) { }

  private _endpoint = '';
  private _objAdditionalParameters = {};

  @Input()
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  uploader: FileUploader = new FileUploader({
    url: `${this._endpoint}`,
    autoUpload: false,
    headers: [{ name: 'Authorization', value: localStorage.getItem('token') }],
    queueLimit: 5,
    itemAlias: 'files',
    isHTML5: true,
    filters: [{
      name: 'xmlOnly',
      fn: function (item) {
        const xmlOnly = '|text/xml|';
        return xmlOnly.indexOf(item.type) !== -1;
      },
    }],
  });

  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item => {
      this.spinner.show();
    });

    this.uploader.onAfterAddingFile = (item => { item.withCredentials = false; });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      
      

      // no content
      if (status === 204) {
        this.uploader.queue.forEach(fileItem => {
          fileItem.remove();
        });

        this.uploadSuccessEvent.emit(null);

      } else if (/400|409|500/g.test(status)) {
        this.uploadErrorEvent.emit(null);
      }

      this.spinner.hide();
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    const objAdditional: SimpleChange = changes.objAdditionalParameters;
    this.objAdditionalParameters = objAdditional.currentValue;

    const { depositorId, providerId, nfSolicit, orderNumber, deliveryAuthorizationId } = objAdditional.currentValue;

    this.uploader.setOptions({ additionalParameter: { depositorId, providerId, nfSolicit, orderNumber, deliveryAuthorizationId } });

    this.uploader.setOptions({ url: this._endpoint });
  }

  uploadAllFiles(): void {

    this.uploader.onBeforeUploadItem(null);

    const xhr = new XMLHttpRequest();
    const sendable = new FormData();
    const fakeitem: FileItem = null;
    this.uploader.onBuildItemForm(fakeitem, sendable);

    for (const item of this.uploader.queue) {
      item.isReady = true;
      item.isUploading = true;
      item.isUploaded = false;
      item.isSuccess = false;
      item.isCancel = false;
      item.isError = false;
      item.progress = 0;

      if (typeof item._file.size !== 'number') {
        throw new TypeError('The file specified is no longer valid');
      }
      sendable.append('files', item._file, item.file.name);
    }

    if (this.uploader.options.additionalParameter !== undefined) {
      Object.keys(this.uploader.options.additionalParameter).forEach((key) => {
        sendable.append(key, this.uploader.options.additionalParameter[key]);
      });
    }

    xhr.onload = () => {
      // const headers = this.uploader.
      const gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
      // const method = '_on' + gist + 'Item';
      // for (const item of this.uploader.queue) {
      //   this.uploader[method](item, response);
      // }
      const method = '_on' + gist + 'Item';
      for (const item of this.uploader.queue) {
        this.uploader[method](item, null, xhr.status, null);
      }
      this.uploader.onCompleteItem(this.uploader.queue[0], null, xhr.status, null);

      // if (xhr.status === 201) {
      //   this.uploader.queue.forEach(fileItem => {
      //     fileItem.remove();
      //   });

      //   this.uploadSuccessEvent.emit(null);
      // } else if (/400|409|500/g.test(status)) {
      //   this.uploadErrorEvent.emit(xhr.response ? JSON.parse(xhr.response) : null);
      // }
      // const gist = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 304 ? 'Success' : 'Error';
      // const method = 'on' + gist + 'Item';
      // this[method](fakeitem, null, xhr.status, null);
    };

    xhr.onerror = () => {
      this.uploader.onErrorItem(fakeitem, null, xhr.status, null);
    };

    xhr.onabort = () => {
      this.uploader.onErrorItem(fakeitem, null, xhr.status, null);
    };

    // this.uploader.onAfterAddingFile = (item => { item.withCredentials = false; });

    xhr.open('POST', this.uploader.options.url, true);
    xhr.withCredentials = false;
    if (this.uploader.options.headers) {
      for (let _i = 0, _a = this.uploader.options.headers; _i < _a.length; _i++) {
        const header = _a[_i];
        xhr.setRequestHeader(header.name, header.value);
      }
    }
    if (this.uploader.authToken) {
      xhr.setRequestHeader(this.uploader.authTokenHeader, this.uploader.authToken);
    }
    xhr.send(sendable);
  }

}
