import { Depositor } from './../depositor';
import { Establishment } from './../establishment';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class EmailSmsRegularDelivery extends AbstractStatusModel {
  public establishment: Establishment;
  public depositor: Depositor[] = [];
  public statusDocOK: boolean;
  public expirationDocOK: boolean;
  public confirmAuthorizationWithoutSchedule: boolean;
  public statusInterrupted: boolean;
  public pendentNotPriority: boolean;
  public pendentPriority: boolean;
  public allocationManualPendentNotPriority: boolean;
  public allocationManualPendentPriority: boolean;
  public confirmAuthorization: boolean;
  public rescheduleAuthorization: boolean;
  public cancelAuthorization: boolean;
  public vehicleArrival: boolean;
  public vehicleExits: boolean;
  public noShowRegister: boolean;
  public documentalOcurrencyRegister: boolean;
  public documentOperationalOcurrencyRegister: boolean;

  constructor() {
    super();
    this.statusDocOK = false;
    this.expirationDocOK = false;
    this.confirmAuthorizationWithoutSchedule = false;
    this.statusInterrupted = false;
    this.pendentNotPriority = false;
    this.pendentPriority = false;
    this.allocationManualPendentNotPriority = false;
    this.allocationManualPendentPriority = false;
    this.confirmAuthorization = false;
    this.rescheduleAuthorization = false;
    this.cancelAuthorization = false;
    this.vehicleArrival = false;
    this.vehicleExits = false;
    this.noShowRegister = false;
    this.documentalOcurrencyRegister = false;
    this.documentOperationalOcurrencyRegister = false;
  }
}
