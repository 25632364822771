import { Component, OnInit, Input, ContentChild, AfterContentInit } from '@angular/core';
import { FormControlName } from '@angular/forms';

@Component({
  selector: 'form-group-label',
  templateUrl: './form-group-label.component.html',
  styleUrls: ['./form-group-label.component.scss'],
})
export class FormGroupLabelComponent implements AfterContentInit {

  @Input() label: string;
  @Input() pH: string;
  @Input() isRequired = false;
  @Input() messageHelp = '';

  input: any;

  @ContentChild(FormControlName) control: FormControlName;

  constructor() { }

  ngAfterContentInit() {
    this.input = this.control;
    if (this.input === undefined) {
      throw new Error('Esse component precisa ser utilizado com uma diretiva formControlName');
    }
  }

}
