import { SDM_URL } from './../../../../../app.api';
import { EmailSmsRegularDelivery } from './../../../../models/sdm/email-sms/email-sms-regular-delivery';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailsSmsParametrizationService extends AbstractService<
  EmailSmsRegularDelivery
> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.emailsSms.regularDelivery.default
    );
  }
}
