import { Location } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { saveAs as importedSaveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { AbstractService } from '../../core/services/abstract.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { SDM_URL } from './../../app.api';
import { DatatableApiComponent } from './../../components/datatable-api/datatable-api.component';
import { IDeserializable } from './../../core/models/deserializable.model';
import { DepositorService } from './../../core/services/depositor.service';
import { TranslatorService } from './../../core/translator/translator.service';

export abstract class AbstractParametrizations<
  T extends IDeserializable
> extends BaseFormComponent {
  depositors = [];
  fileName = 'arquivo';

  establishmentId: number = 0;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService
  ) {
    super(location, toaster, translator);
  }

  columnsEstablishment = [
    { prop: 'id', name: '#' },
    { prop: 'description', name: 'Descrição' },
    { prop: 'document', name: 'CNPJ' },
    { prop: 'establishCode', name: 'Código Localidade' }
  ];

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }

    this.establishmentId = establishment['id'];

    this.depositorService
      .findByEstablishmentId(establishment['id'])
      .subscribe(data => {
        this.formulario.get('depositorId').setValue([]);
        this.depositors = [...data.body['content']];
        if (this.depositors.length === 1) {
          this.formulario.get('depositorId').setValue(this.depositors[0]);
        }
      });
  }

  submitted() {
    if (this.formulario.valid) {
      if (
        this.formulario.controls.hasOwnProperty('depositorId') &&
        typeof this.formulario.get('depositorId').value === 'object'
      ) {
        const { id } = this.formulario.get('depositorId').value;
        this.formulario.get('depositorId').setValue(id);
      }
      if (this.formulario.controls['id'].value == null) {
                this.getService()
          .create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data || data['body']);
            this.getGrid().reloadPage();
            this.showMessageActions();
            this.disabledForm();
          });
      } else {
        this.getService()
          .edit(this.formulario.value)
          .subscribe(() => {
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
            this.disabledForm();
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  submit() {
    if (this.formulario.valid) {
      if (this.formulario.controls['id'].value == null) {
        this.getService()
          .create(this.listOfInstance())
          .subscribe(data => {
            this.formulario.patchValue(data['body'][0]);
            this.getGrid().reloadPage();
            this.showMessageActions();
            this.disabledForm();
          });
      } else {
        this.getService()
          .edit(this.formulario.value)
          .subscribe(() => {
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
            this.disabledForm();
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  listOfInstance(): T[] {
    const arrObj = this.formulario.get('depositorId').value;
    const arrParams: T[] = [];

    if (arrObj == null) {
      return;
    }

    arrObj.forEach((depositorId, index) => {
      arrParams.push(
        this.getNewInstance().deserializable(this.formulario.value)
      );
      arrParams[index]['depositorId'] =
        typeof depositorId === 'object' ? depositorId.id : depositorId;
    });
    return arrParams;
  }

  fileUpload(data) {
    if (data) {
      this.getGrid().reloadPage();
    }
  }

  backup() {
    this.getService()
      .backup()
      .subscribe(data => {
        this.getGrid().reloadPage();
      });
  }

  export() {
    this.getService()
      .export()
      .subscribe(data => {
        importedSaveAs(data, this.getFileName());
      });
  }

  template() {
    this.getService()
      .template()
      .subscribe(data => {
        importedSaveAs(data, this.getFileName());
      });
  }

  getFileName(): string {
    this.translator.translate
      .get(`pages.${this.getComponentName()}.excelFileName`)
      .subscribe(fileName =>
        fileName ? (this.fileName = fileName) : this.fileName
      );
    return `${this.fileName}.xlsx`;
  }

  endpointUpload = () => `${SDM_URL}.${this.getComponentName()}.upload`;
  endPointPartial = () => `${SDM_URL}.${this.getComponentName()}.importPartial`;

  makeQueryForEstablishment = () =>
    SDM_URL.establishment.findById.replace(':id', '');
  makeQueryForAllEstablishments = () => SDM_URL.establishment.findAll;

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;
  abstract getGrid(): DatatableApiComponent;
}
