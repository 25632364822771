import { Component, OnInit, ComponentRef, Input, TemplateRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-query',
  templateUrl: './modal-query.component.html',
  styleUrls: ['./modal-query.component.scss']
})
export class ModalQueryComponent {

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  columns = [
    { name: 'teste - 1' },
    { name: 'teste - 2' },
    { name: 'teste - 3' }
  ]

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }

}
