import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer, Renderer2, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DatatableApiComponent } from './../../datatable-api/datatable-api.component';
import { DatatableApiService } from './../../datatable-api/datatable-api.service';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldQueryComponent),
  multi: true,
};

@Component({
  selector: 'app-input-field-query',
  templateUrl: './input-field-query.component.html',
  styleUrls: ['./input-field-query.component.scss'],
  providers: [INPUT_FIELD_VALUE_ACCESSOR],
})
export class InputFieldQueryComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() name = '';
  @Input() label = '';
  @Input() i18n = true;
  @Input() columns = [];
  @Input() disable = false;
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() nameOfLabel = '';
  @Input() isRequired = false;
  @Input() fields: string[] = [];
  @Input() modalTitle = 'Consulta';
  @Input() dataField = '';
  @Input() inputMask = '';
  @Input() dropSpecialCharact = true;
  @Input() id = '';

  @Output() changed = new EventEmitter();

  @ViewChild('input') input: ElementRef<any>;
  @ViewChild('pop') pop: TooltipDirective;

  @ViewChild(DatatableApiComponent) gridConsulta$: DatatableApiComponent;

  subscriptions: Subscription[] = [];

  private _endPoint;
  private _query;
  private innerValue: any;

  public selectedField: object[];
  public selected = false;
  modalRef: BsModalRef;

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    private renderer: Renderer,
    private _dataTableApiService: DatatableApiService) {
  }

  onChangeCb: (_: any) => void = () => { };
  onTouchedCb: (_: any) => void = () => { };

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
    // this._renderer.setProperty(this.input.nativeElement, 'value', v);
  }

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  @Input()
  set query(query: string) {
    this._query = query;
  }

  get query() {
    return this._query;
  }

  @Input()
  set endpoint(endPoint: string) {
    this._endPoint = endPoint;
  }

  get endpoint() {
    return this._endPoint;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const query: SimpleChange = changes.query;
    const endPoint: SimpleChange = changes.endpoint;
    const isRequired: SimpleChange = changes.isRequired;

    if (query !== undefined) {
      this._query = query.currentValue;
    }
    if (endPoint !== undefined) {
      this._endPoint = endPoint.currentValue;
    }

    // if (isRequired) {
    //   this.control.setValidators([Validators.required]);
    //   this.control.updateValueAndValidity();
    //   return;
    // }
    // this.control.clearValidators();
    // this.control.updateValueAndValidity();
  }

  isInvalid(): boolean {
    if (!this.control) {
      return;
    }
    if (this.form.disabled) {
      this.control.markAsUntouched();
      return false;
    }
    if (this.control.disabled) {
      this.control.markAsUntouched();
    }
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  ngOnInit() {

  }

  loadData() {
    this._dataTableApiService.gridRowSelected.subscribe(data => {
      this._dispatch(data);
    });
  }

  _dispatch(data) {
    if (data) {
      this.input.nativeElement.value = data[this.name];
      const event = new MouseEvent('change', { bubbles: true });
      this.renderer.invokeElementMethod(this.input.nativeElement, 'dispatchEvent', [event]);

      for (const field of this.fields) {
        try {
          this.form.get(field).setValue(data[field]);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      this.input.nativeElement.value = '';

      for (const field of this.fields) {
        try {
          this.form.get(field).setValue('');
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  _dispatchWhenSelectedGridRow(data) {
    if (data) {
      this.value = data[this.name];
      for (const field of this.fields) {
        try {
          this.form.get(field).setValue(data[field]);
        } catch (e) {
          console.error('Input not found: ', e);
        }
      }
      this.changed.emit(data);
    }
  }

  directSearch(event) {
    const value = event.target.value;
    if (!value) {
      this._clearDetailInputs();
      this.changed.emit('');
      return;
    }
    this.http.get(this.makeQuery(value))
      .subscribe((data: object) => {
        if (data) {
          this.value = '';
          this.value = data[this.name];
          this._detailInputs(data);
          this.changed.emit(data);
        } else {
          this.value = '';
          this._clearDetailInputs();
          this.input.nativeElement.focus();
        }
      },
        error => {
          this.pop.show();
          window.setTimeout(() => this.pop.hide(), 1500);
          this.value = '';
          this._clearDetailInputs();
          this.input.nativeElement.focus();
          this.changed.emit('');
        },
      );
  }

  _detailInputs(data) {
    try {
      for (const field of this.fields) {
        this.form.get(field).setValue(data[field]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  _clearDetailInputs() {
    try {
      for (const field of this.fields) {
        this.form.get(field).setValue('');
      }
    } catch (e) {
      console.error(e);
    }
  }

  makeQuery = (id: number) => `${this._query}${id}`;
  makeEndPoint = () => this._endPoint;

  openModal(template: TemplateRef<any>) {

    this.modalService.onShow.subscribe((reason: string) => {
    });

    this.modalRef = this.modalService.show(template, { class: 'gray modal-lg' });
  }

  openModalWithADataTable() {
    
    const initialState = {
      title: 'Consulta',
      columns: this.columns,
      endpoint: this.makeEndPoint(),
    };
    this.modalRef = this.modalService.show(ModalContentComponent, { initialState, class: 'gray modal-lg' });

    this.subscriptions.push(
      this.modalService.onHide
        .pipe(delay(1))
        .subscribe(resp => {
          try {
            const _obj = JSON.parse(resp);
            if (_obj) {
              this._dispatchWhenSelectedGridRow(_obj);
            }
          } catch (e) {
            return null;
          }
          this.unsubscribe();
        }),
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  executeSearch() {
    this.gridConsulta$.reloadPage();
  }

  closeModal() {
    if (this.selectedField) {
      this.input.nativeElement.value = this.selectedField[this.name];
      const event = new MouseEvent('change', { bubbles: true });
      this.renderer.invokeElementMethod(this.input.nativeElement, 'dispatchEvent', [event]);
    }
    this.modalRef.hide();
  }

  selectedRow(row) {
    if (row) {
      this.selected = true;
      this.selectedField = row;
    }
  }
}

@Component({
  selector: 'modal-content',
  template: `
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="col-12">
      <div class="form-row">
        <!-- <app-input-field #inpId label="Código" class="col-lg-2 col-sm-2 col-md-2 col-xl-2"></app-input-field>
        <app-input-field #inpName label="Nome" class="col-lg-8 col-sm-8 col-md-8 col-xl-8"></app-input-field> -->
        <div class="form-horizontal">
          <button type="button" class="btn btn-inverse pull-left mt-5" (click)="executeSearch()"><i class="fa fa-search"></i>&nbsp;&nbsp;&nbsp;
            Pesquisar</button>
        </div>
      </div>
    </div>
    <app-datatable-api #gridConsulta [endpoint]="makeEndPoint()" [endPointAction]="makeEndPoint()" (selectRow)="selectedRow($event)"
      [columns]="columns" [keyfield]="'id'" [isAutoLoad]="false">
    </app-datatable-api>
  </div>
  <div class="modal-footer">
  <button type="button" (click)="closeModal()" class="btn btn-primary pull-right" [disabled]="!selected"><i
    class="fa fa-hand-pointer-o"></i>&nbsp;&nbsp;&nbsp; Selecionar</button>
  </div>
  `,
})

export class ModalContentComponent implements OnInit {

  title: string;
  selected = false;
  list: any[] = [];
  private _endPoint;
  closeBtnName: string;
  selectedField: object[];

  @Input() columns = [];

  @Input()
  set endpoint(endPoint: string) {
    this._endPoint = endPoint;
  }

  get endpoint() {
    return this._endPoint;
  }

  @ViewChild(DatatableApiComponent) gridConsulta$: DatatableApiComponent;

  constructor(public modalRef: BsModalRef, private modalService: BsModalService) { }

  ngOnInit = () => this._endPoint;

  makeEndPoint = () => this._endPoint;

  selectedRow(row) {
    if (row) {
      this.selected = true;
      this.selectedField = row;
    }
  }

  closeModal() {
    if (this.selectedField) {
      this.modalService.setDismissReason(JSON.stringify(this.selectedField));
    } else {
      this.modalService.setDismissReason('OK');
    }
    this.modalRef.hide();
  }

  executeSearch() {
    this.gridConsulta$.reloadPage();
  }
}
