import { take } from 'rxjs/operators';
import { DocksService } from './../../docks.service';
import { OperationNatureEnum } from './../../../../../core/models/sdm/shared/enums/OperationNatureEnum';
import { DateFormatPipe } from './../../../../../core/classes/datePipe';
import { SDM_URL } from './../../../../../app.api';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { HolidayRelationshipService } from './../../../../../core/services/sdm/docks/holiday-relationship.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { HolidayRelationShip } from './../../../../../core/models/sdm/docks/holiday-relationship';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractParametrizations } from '../../../abstract-parametrizations-crud';
import { Location } from '@angular/common';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-holiday-relationship-form',
  templateUrl: './holiday-relationship-form.component.html',
  styleUrls: ['./holiday-relationship-form.component.scss']
})
export class HolidayRelationshipFormComponent
  extends AbstractParametrizations<HolidayRelationShip>
  implements OnInit {
  public keys;
  holidayRelationShip: HolidayRelationShip;
  operationNatureTypeEnum = OperationNatureEnum;
  depositorRequired = false;
  operationNatureRequired = false;

  @ViewChild('grdHolidayRelationShip') grid: DatatableApiComponent;

  @ViewChild('hdrTplOpNature') hdrTplOpNature: TemplateRef<any>;
  @ViewChild('bodyTplOpNature') bodyTplOpNature: TemplateRef<any>;

  @ViewChild('hdrTplDepositorName') hdrTplDepositorName: TemplateRef<any>;
  @ViewChild('bodyTplDepositorName') bodyTplDepositorName: TemplateRef<any>;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  columns: object[] = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    {
      prop: 'initialDate',
      name: 'Data início',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'initialHour', name: 'Hora início' },
    {
      prop: 'finalDate',
      name: 'Data término',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'finalHour', name: 'Hora término' },
    { prop: 'observation', name: 'Observação' }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public holidayRelationShipService: HolidayRelationshipService,
    private _docksServiceEvents: DocksService
  ) {
    super(location, toaster, translator, depositorService);
    this.keys = Object.keys(this.operationNatureTypeEnum).filter(
      v => v != 'COLETA_DIFERENCIADA'
    );
  }

  ngOnInit() {
    this._docksServiceEvents.emitEventTabRelationShipFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());

    this.columns.splice(4, 0, {
      prop: 'depositor.name',
      name: 'Nome Depositante',
      cellTemplate: this.bodyTplDepositorName,
      headerTemplate: this.hdrTplDepositorName
    });
    this.columns.splice(5, 0, {
      prop: 'operationNature',
      name: 'pages.docks.holiday-relationship.operationNature',
      cellTemplate: this.bodyTplOpNature,
      headerTemplate: this.hdrTplOpNature
    });

    this.holidayRelationShip = new HolidayRelationShip();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl(''),
      depositor: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      operationNature: new FormControl('', [Validators.required]),
      initialDate: new FormControl(''),
      initialHour: new FormControl(''),
      finalDate: new FormControl(''),
      finalHour: new FormControl(''),
      observation: new FormControl(''),
      allDepositors: new FormControl(''),
      allOperationNature: new FormControl('')
    });

    this.formulario.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('depositorId').setValue(value.id);
        return;
      }

      this.formulario.get('depositorId').setValue('');
    });
  }

  submit() {
    if (this.formulario.valid) {
      // if (
      //   this.formulario.controls.hasOwnProperty('depositorId') &&
      //   typeof this.formulario.get('depositorId').value === 'object'
      // ) {
      //   const { id } = this.formulario.get('depositorId').value;
      //   this.formulario.get('depositorId').setValue(id);
      // }
      if (this.formulario.controls['id'].value == null) {
                this.getService()
          .create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data || data['body']);
            this.getGrid().reloadPage();
            this.showMessageActions();
            this.disabledForm();
          });
      } else {
        this.getService()
          .edit(this.formulario.value)
          .subscribe(() => {
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
            this.disabledForm();
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  // submit() {
  //   if (this.formulario.controls['id'].value == null) {
  //     this.holidayRelationShipService.create(this.listOfInstance())
  //       .subscribe(data => {
  //         this.formulario.patchValue(data['body'][0]);
  //         this.grid.reloadPage();
  //         this.showMessageActions();
  //         this.disabledForm();
  //       });
  //   } else {
  //     this.holidayRelationShipService.edit(this.formulario.value).subscribe(() => {
  //       this.grid.reloadPage();
  //       this.showMessageActions('success', 'update');
  //       this.disabledForm();
  //     });
  //   }
  // }

  listOfInstance(): HolidayRelationShip[] {
    const arrObj = this.formulario.get('depositorId').value;
    const arrParams: HolidayRelationShip[] = [];
    if (!arrObj.length) {
      arrParams.push(
        new HolidayRelationShip().deserializable(this.formulario.value)
      );
      return arrParams;
    }
    arrObj.forEach((depositorId, index) => {
      arrParams.push(
        this.getNewInstance().deserializable(this.formulario.value)
      );
      arrParams[index]['depositorId'] = depositorId;
    });
    return arrParams;
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.holidayRelationShip);
  }

  changeAllDepositor(event) {
    this.depositorRequired = event.target.value === 'true';
    if (this.depositorRequired) {
      this.formulario.get('depositor').clearValidators();
      this.formulario.get('depositor').updateValueAndValidity();
      this.formulario.get('depositor').setValue([]);
      this.formulario.get('depositor').disable();
    } else {
      this.formulario.get('depositor').setValidators([Validators.required]);
      this.formulario.get('depositor').updateValueAndValidity();
      this.formulario.get('depositor').enable();
      this.depositors = [...this.depositors];
    }
  }

  changeAllOperationNature(event) {
    this.operationNatureRequired = event.target.value === 'true';
    if (this.operationNatureRequired) {
      this.formulario.get('operationNature').clearValidators();
      this.formulario.get('operationNature').updateValueAndValidity();
      this.formulario.get('operationNature').setValue(this.keys[3]);
      // this.formulario.get('operationNature').disable();
    } else {
      this.formulario
        .get('operationNature')
        .setValidators([Validators.required]);
      this.formulario.get('operationNature').updateValueAndValidity();
      this.formulario.get('operationNature').enable();
      this.formulario.get('operationNature').setValue(this.keys[0]);
    }
  }

  changeOpNatureEnum(event) {
    const value = event.target.value;
    if (value === 'TODOS') {
      this.formulario.get('allOperationNature').setValue(true);
      return;
    }
    this.formulario.get('allOperationNature').setValue(false);
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositor').setValue([]);
      this.depositors = [];
      return;
    }
    this.depositorService
      .findByEstablishmentId(establishment['id'])
      .subscribe(data => {
        this.formulario.get('depositor').setValue([]);
        this.depositors = [...data.body['content']];
        if (this.depositors.length === 1) {
          this.formulario.get('depositor').setValue(this.depositors[0]);
        }
      });
  }

  makeEndPointForHolidayRelationShip = () =>
    SDM_URL.docks.holidayRelationShip.default;

  getNewInstance(): HolidayRelationShip {
    return new HolidayRelationShip();
  }
  getService(): AbstractService<HolidayRelationShip> {
    return this.holidayRelationShipService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'docks.holiday-relationship';
  }
}
