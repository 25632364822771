import { Component, OnInit, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions, IModalDialogButton, IModalDialogSettings } from 'ngx-modal-dialog';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements IModalDialog {

  constructor() {
    // this.actionButtons = [
    //   // { text: 'Close', buttonClass: 'btn btn-secondary' }, // no special processing here
    //   // { text: 'Remove', onAction: () => true, buttonClass: 'btn btn-danger' }
    // ]
  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    options.title = 'Confirmação'
  }

}
