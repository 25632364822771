import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { CollectRmAuthorization } from '../../../models/sdm/collect/collect-rma';

@Injectable({
  providedIn: 'root',
})
export class CollectRmaService extends AbstractService<CollectRmAuthorization> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.collect.rma.default);
  }

}
