import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Establishment } from '../models/sdm/establishment';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { overrideProvider } from '@angular/core/src/view';

@Injectable()
export class EstablishmentService extends AbstractService<Establishment> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/establishment');
  }

  findByUserId(userId: number): Observable<Establishment[]> {
    return this.http
      .get<Establishment[]>(`${this.host}${this.url}/getAll/user/${userId}`)
      .pipe(take(1));
  }
}
