import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  Renderer2
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from '@angular/forms';

const SELECT_SIMPLE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectSimpleFieldComponent),
  multi: true
};

@Component({
  selector: 'app-select-simple-field',
  templateUrl: './select-simple-field.component.html',
  styleUrls: ['./select-simple-field.component.scss'],
  providers: [SELECT_SIMPLE_VALUE_ACCESSOR]
})
export class SelectSimpleFieldComponent extends SelectControlValueAccessor {
  @Input() name = '';
  @Input() label = '';
  @Input() i18n = true;
  @Input() isRequired = false;
  @Input() options = [];
  @Input() disabled = true;
  @Input() control: FormControl;

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  private _innerValue: any;

  get value() {
    return this._innerValue;
  }

  set value(value: any) {
    if (value !== this._innerValue) {
      this._innerValue = value;
      this.onChange(value);
    }
  }

  onChange: (_: any) => void;
  onTouched: () => void;
  compareWith: (o1: any, o2: any) => boolean;

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  // selected(event) {

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isInvalid(): boolean {
    // if (this.control.disabled) {
    //   this.control.markAsPristine();
    // }
    return !this.control.valid && (this.control.dirty || this.control.touched);
  }
}
