export enum DeliveryAuthorizationEnum {
  DOC_OK = '1',
  INTERROMPIDA = '2',
  PENDENTE = '3',
  APROVADO = '4',
  ALOCACAO_MANUAL = '5',
  SEM_AGENDAMENTO = '6',
  EXPIRADO = '7',
  CANCELADO = '8',
  REAGENDADO = '9',
  FINALIZADA = '10',
  NO_SHOW = '11'
}
