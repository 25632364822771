import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-custom',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="exitConfirm()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span style="padding-left: 5px">{{ message }}</span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn {{ classBtnDanger }} mr-1"
        (click)="exitConfirm()"
      >
        {{ closeBtnName }}
      </button>
      <button
        type="button"
        class="btn {{ classBtnSuccess }}"
        (click)="exitConfirmOnSuccess()"
      >
        {{ confirmBtn }}
      </button>
    </div>
  `,
  styleUrls: ['./modal-custom.component.scss']
})
export class ModalCustomComponent implements OnInit {
  title: string;
  closeBtnName: string;
  confirmBtn: string;
  message = '';
  wishInclude = false;
  form: FormGroup;

  classBtnSuccess = 'btn-success';
  classBtnDanger = 'btn-outline-danger';

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {}

  exitConfirmOnSuccess() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('OK');
  }

  exitConfirm() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('CANCEL');
  }
}
