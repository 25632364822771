import { AbstractService } from '../../abstract.service';
import { RegularDelivery } from '../../../models/sdm/regular-delivery/regular-delivery';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SDM_URL } from '../../../../app.api';

@Injectable()
export class RegularDeliveryService extends AbstractService<RegularDelivery> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/regular-delivery');
  }

  createRegularDelivery(model: RegularDelivery[]): Observable<RegularDelivery> {
    
    return this.http.post<RegularDelivery>(SDM_URL.regularDelivery.create, model);
  }

  findByDepositorId(depositorId: number): Observable<RegularDelivery> {
    return this.http.get<RegularDelivery>(`${SDM_URL.regularDelivery.findByDepositorId.replace(':depositorId', depositorId.toString())}`);
  }
}
