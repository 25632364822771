import { Location } from '@angular/common';
import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Authorization } from '../../../../core/models/sdm/delivery-authorization/authorization';
import { RegularDeliveryNF } from '../../../../core/models/sdm/regular-delivery/regular-delivery-nf';
import { RegularDeliveryNfService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-nf.service';
import { SDM_URL } from './../../../../app.api';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { DateTimeFormatPipe } from './../../../../core/classes/dateTimePipe';
import { StatusSolicitationPipe } from './../../../../core/classes/status-solicitation.pipe';
import { AuthService } from './../../../../core/services/auth.service';

@Component({
  selector: 'app-cancel-delivery-authorization',
  templateUrl: './cancel-delivery-authorization.component.html',
  styleUrls: ['./cancel-delivery-authorization.component.scss'],
  preserveWhitespaces: true
})
export class CancelDeliveryAuthorizationComponent implements OnInit {
  formCancelDeliveryAuthorization: FormGroup;

  isAutoLoadGrid = true;
  countLoad = 0;
  authorizationSelected: Authorization;
  deliveryAuthorization;

  alerts: any = [];
  dismissible = true;
  formEnabled = false;

  dateToCancel = null;

  @ViewChild('grdQuerySolicit') grdQuerySolicit: DatatableApiComponent;

  columns: object[] = [
    { prop: 'authorizationkey', name: '# Autorização', width: 150 },
    // { prop: 'documents.id', name: 'Fornecedor' },
    { prop: 'createdAt', name: 'Data', pipe: new DateTimeFormatPipe('en-US') },
    {
      prop: 'createdAt',
      name: 'Validade',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'statusAuthorization',
      name: 'Status',
      pipe: new StatusSolicitationPipe()
    },
    {
      prop: 'updatedAt',
      name: 'Data Ult. Ação',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  constructor(
    private _router: Router,
    private _location: Location,
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _regDeliveryNfDeadlineService: RegularDeliveryNfService,
    private _authService: AuthService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));

    this.dateToCancel = moment(new Date());
  }

  ngOnInit() {
    this.formCancelDeliveryAuthorization = new FormGroup({
      authorizationKey: new FormControl('')
    });

    this.route.queryParams.subscribe((params: any) => {
      this.getDeliveryAuthorizationFromQueryParams(params);
      
    });

    this.formCancelDeliveryAuthorization
      .get('authorizationKey')
      .valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(searchTerm => {
        
        this.grdQuerySolicit.reloadPage();
      });
  }

  getDeliveryAuthorizationFromQueryParams(params: any) {
    try {
      this.deliveryAuthorization = JSON.parse(params['deliveryAuthorization']);

      if (
        this.deliveryAuthorization &&
        this.deliveryAuthorization.hasOwnProperty('authorizationkey')
      ) {
        if (this.deliveryAuthorization) {
          this.grdQuerySolicit.selected = [this.deliveryAuthorization];
          this.authorizationSelected = this.deliveryAuthorization;

          const userType = this._authService.getUserAuthority();

          this._regDeliveryNfDeadlineService
            .findByDepositorIdAndStatusTrue(
              this.deliveryAuthorization['depositor']['establishment'].id,
              this.deliveryAuthorization['depositor'].id
            )
            .subscribe((data: RegularDeliveryNF) => {
              

              if (data) {
                this.dateToCancel = moment(new Date());
                if (/Internal/i.test(userType.type)) {
                  this.dateToCancel.add(data.minCancelRequestInternalUser, 'd');
                } else {
                  this.dateToCancel.add(data.minCancelRequestExternalUser, 'd');
                }

                // this.validateIfAllowCancel();
              }
            });
        }

        this.formCancelDeliveryAuthorization
          .get('authorizationKey')
          .setValue(this.deliveryAuthorization['authorizationkey']);
      }
    } catch (e) {
      console.error(e);
    }
  }

  validateIfAllowCancel() {
    if (this.checkAllowCancel()) {
      return true;
    }

    return false;
  }

  checkAllowCancel(): boolean {
    return (
      this.dateToCancel.format('YYYY-MM-DD') >
      this.deliveryAuthorization['validityDate']
    );
  }

  alertMsg(message?: string, type = 'success', timeout = 2500) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout: timeout
    });
  }

  gridAutoLoad() {
    if (this.isAutoLoadGrid && this.countLoad === 0) {
      this.countLoad++;
      return true;
    }
    return false;
  }

  selectedRow(row: Authorization) {
        this.authorizationSelected = row;
    this.deliveryAuthorization = this.authorizationSelected;

    this.validateIfAllowCancel();
  }

  getParamsRegularDeliveryNF() {}

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  endPointAuthorizations() {
    const url = [
      {
        name: 'authorizationkey',
        value:
          this.formCancelDeliveryAuthorization.get('authorizationKey').value ||
          ''
      },
      { name: 'status', value: [1, 2, 3, 4, 5, 6, 9].join(',') }
    ];

    let queryString = '';
    const query = [];

    url.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    return `${
      SDM_URL.deliveryAuthorization.queryDeliveryApproved
    }?${queryString}&`;
  }

  goBack() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.authorizationSelected['depositor'])
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };
    this._router.navigate(
      ['sdm/delivery-authorization/edit'],
      navigationExtras
    );
  }

  continue() {
    if (!this.authorizationSelected) {
      this.alertMsg('Selecione a Autorização para continuar', 'info');
      return;
    }

    this._router.navigate(
      ['sdm/delivery-authorization/edit/cancel-validation-term'],
      this.queryParams()
    );
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        authorization: JSON.stringify(this.authorizationSelected)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }
}
