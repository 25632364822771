import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { EmailsSmsReverseParamsService } from '../../../../../core/services/sdm/emails-sms/emails-sms-reverse-params.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { EmailSmsParamsAbstract } from '../../email-sms-params-abstract';
import { SDM_URL } from './../../../../../app.api';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { EmailSmsReverseDelivery } from './../../../../../core/models/sdm/email-sms/email-sms-reverse';
import { AuthService } from './../../../../../core/services/auth.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';

@Component({
  selector: 'app-email-sms-reverse-delivery',
  templateUrl: './email-sms-reverse-delivery.component.html',
  styleUrls: ['./email-sms-reverse-delivery.component.scss']
})
export class EmailSmsReverseDeliveryComponent
  extends EmailSmsParamsAbstract<EmailSmsReverseDelivery>
  implements OnInit {
  @ViewChild('grdRevDeliveryEmailSms')
  grdRevDeliveryEmailSms: DatatableApiComponent;

  columnsRevDelivery = [
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    {
      prop: 'statusDocOK',
      name: 'Status DOC OK',
      pipe: new BooleanConverter()
    },
    {
      prop: 'expirationDocOK',
      name: 'Expiração DOC OK',
      pipe: new BooleanConverter()
    },
    {
      prop: 'confirmAuthorizationWithoutSchedule',
      name: 'Confirmação de Autorização Sem Agendamento',
      pipe: new BooleanConverter()
    },
    {
      prop: 'statusInterrupted',
      name: 'Status Interrompida',
      pipe: new BooleanConverter()
    },
    {
      prop: 'pendentPriority',
      name: 'Pendente Prioritária',
      pipe: new BooleanConverter()
    },
    {
      prop: 'allocationManualPendentPriority',
      name: 'Alocação Manual de Pendência',
      pipe: new BooleanConverter()
    },
    {
      prop: 'confirmAuthorization',
      name: 'Confirmação de Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'rescheduleAuthorization',
      name: 'Reagendamento de Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'cancelAuthorization',
      name: 'Cancelamento de Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'vehicleArrival',
      name: 'Chegada de Veículo',
      pipe: new BooleanConverter()
    },
    {
      prop: 'vehicleExits',
      name: 'Saída de Veículo',
      pipe: new BooleanConverter()
    },
    {
      prop: 'noShowRegister',
      name: 'Registro de No Show',
      pipe: new BooleanConverter()
    },
    {
      prop: 'documentalOcurrencyRegister',
      name: 'Registro de Ocorrência Documental',
      pipe: new BooleanConverter()
    },
    {
      prop: 'documentOperationalOcurrencyRegister',
      name: 'Registro de Ocorrência Operacional',
      pipe: new BooleanConverter()
    }
  ];

  emailSmsReverseDelivery: EmailSmsReverseDelivery;

  constructor(
    public establishmentService: EstablishmentService,
    public depositorService: DepositorService,
    public authService: AuthService,
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public emailSmsService: EmailsSmsReverseParamsService
  ) {
    super(
      location,
      toaster,
      translator,
      depositorService,
      authService,
      establishmentService
    );
  }

  ngOnInit() {
    this.emailSmsReverseDelivery = new EmailSmsReverseDelivery();

    this.formulario.addControl(
      'statusDocOK',
      new FormControl(this.emailSmsReverseDelivery.statusDocOK)
    );
    this.formulario.addControl(
      'expirationDocOK',
      new FormControl(this.emailSmsReverseDelivery.expirationDocOK)
    );
    this.formulario.addControl(
      'confirmAuthorizationWithoutSchedule',
      new FormControl(
        this.emailSmsReverseDelivery.confirmAuthorizationWithoutSchedule
      )
    );
    this.formulario.addControl(
      'statusInterrupted',
      new FormControl(this.emailSmsReverseDelivery.statusInterrupted)
    );
    this.formulario.addControl(
      'pendentPriority',
      new FormControl(this.emailSmsReverseDelivery.pendentPriority)
    );
    this.formulario.addControl(
      'allocationManualPendentPriority',
      new FormControl('')
    );
    this.formulario.addControl(
      'confirmAuthorization',
      new FormControl(this.emailSmsReverseDelivery.confirmAuthorization)
    );
  }

  endPointRevDeliverySmsEmailsParam = () =>
    SDM_URL.emailsSms.reverseDelivery.findAll;

  getNewInstance() {
    return new EmailSmsReverseDelivery();
  }
  getService(): AbstractService<EmailSmsReverseDelivery> {
    return this.emailSmsService;
  }
  getGrid(): DatatableApiComponent {
    return this.grdRevDeliveryEmailSms;
  }
  getComponentName(): string {
    return 'emails-sms.default-messages';
  }
}
