export class FormValidations {
  static getErrorMsg(
    fieldName: string,
    validatorName: string,
    validatorValue?: any
  ) {
    const config = {
      required: `Campo Obrigatório`,
      minlength: `${fieldName} precisa ter no mínimo ${
        validatorValue.requiredLength
      } caracteres`,
      maxlength: `${fieldName} precisa ter no máximo ${
        validatorValue.requiredLength
      } caracteres`,
      min: `${fieldName} precisa ter no mínimo ${validatorValue.min} dígitos`,
      max: `${fieldName} precisa ter no máximo ${validatorValue.max} dígitos`,
      pattern: 'Campo inválido',
      validFinHour: `Valor final menor que o inicial`,
      validDayOfMonth: `Dia inválido`,
      email: 'E-mail inválido'
    };
    return config[validatorName];
  }
}
