import { AbstractService } from "../abstract.service";
import { OperationNature } from "../../models/operation-nature/operationNature";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";

@Injectable()
export class OperationNatureService extends AbstractService<OperationNature> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/operation-nature');
  }

}