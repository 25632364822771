import { Legend } from './../../components/legend/legend';
import { DateFormatPipe } from './../../core/classes/datePipe';
import { Location } from '@angular/common';
import { SecurityContext } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AbstractService } from '../../core/services/abstract.service';

export abstract class AbstractDockManagementEdit<T> {
  dismissible = true;
  alerts: any = [];
  formEdit: FormGroup;
  formEnabled = true;

  columnsDetails: object[] = [
    { prop: 'authorizationKey', name: 'Agendamento', width: 180 },
    { prop: 'operationNature', name: 'Natureza da Operação' },
    // { prop: 'operationSubNature', name: 'Sub-Natureza' },
    { prop: 'provider.shortName', name: 'Transportadora' },
    { prop: 'provider.shortName', name: 'Fornecedor' },
    { prop: 'ini', name: 'Hora inicial' },
    { prop: 'fin', name: 'Hora final' },
    { prop: 'date', name: 'Data', pipe: new DateFormatPipe('en-US') }
  ];

  configLegend: Legend[] = [
    {
      color: '#f54542',
      text: 'Conflito',
      tooltip: 'será gerado conflito'
    },
    {
      color: `#f57242`,
      text: 'Feriado',
      tooltip: 'Horários e dias com feriado'
    },
    {
      color: `#f5bf42`,
      text: 'Dia não operacional',
      tooltip: 'Data não operacional'
    }
  ];

  constructor(sanitizer: DomSanitizer, public location: Location) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
    });
  }

  onSubmit() {
    if (this.formEdit.valid) {
      this.submit();
    } else {
      this.verificaValidacoesForm(this.formEdit);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  goBack() {
    this.location.back();
  }

  disabledForm() {
    this.formEnabled = false;
    this.formEdit.disable();
  }

  abstract submit();
  abstract getService(): AbstractService<T>;
}
