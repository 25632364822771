import { RegularDeliveryEvents } from './../../regular-delivery.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RegularDeliveryRequestMax } from '../../../../../core/models/sdm/regular-delivery/regular-delivery-request-max';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SDM_URL } from '../../../../../app.api';
import { RegularDeliveryRequestMaxService } from '../../../../../core/services/sdm/regular-delivery/regular-delivery-request-max.service';
import { DepositorService } from '../../../../../core/services/depositor.service';
import { take } from 'rxjs/operators';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { Subscription } from 'rxjs';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractDelivery } from '../../abstract-delivery';

@Component({
  selector: 'app-regular-delivery-req-max-form',
  templateUrl: './regular-delivery-req-max-form.component.html',
  styleUrls: ['./regular-delivery-req-max-form.component.scss']
})
export class RegularDeliveryReqMaxComponent
  extends AbstractDelivery<RegularDeliveryRequestMax>
  implements OnInit {
  depositors = [];
  sub: Subscription;
  regularDeliveryReqMax: RegularDeliveryRequestMax;
  @ViewChild('dataGrdReqMax') grid: DatatableApiComponent;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    private _regDeliveryReqMaxService: RegularDeliveryRequestMaxService,
    public regularDeliveryServiceEvents: RegularDeliveryEvents,
    public depositorService: DepositorService
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  columnsRequestMax = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'requestMaxNf', name: 'Máximo de NFs' },
    { prop: 'maxQtdLnfIssued', name: 'Máximo de LNF / NF' },
    { prop: 'minValueByNf', name: 'Valor Mínimo / NF (R$)' }
  ];

  ngOnInit() {
    this.tabReqMaxFired();

    this.regularDeliveryReqMax = new RegularDeliveryRequestMax();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl(
        this.regularDeliveryReqMax.establishmentId,
        [Validators.required]
      ),
      depositorId: new FormControl(this.regularDeliveryReqMax.depositorId, [
        Validators.required
      ]),
      description: new FormControl(''),
      requestMaxNf: new FormControl(this.regularDeliveryReqMax.requestMaxNf, [
        Validators.min(1),
        Validators.max(999)
      ]),
      maxQtdLnfIssued: new FormControl(
        this.regularDeliveryReqMax.maxQtdLnfIssued,
        [Validators.min(1), Validators.max(9999)]
      ),
      minValueByNf: new FormControl(this.regularDeliveryReqMax.minValueByNf, [
        Validators.min(1),
        Validators.max(99999999)
      ]),
      createdBy: new FormControl('')
    });
  }

  tabReqMaxFired() {
    this.sub = this.regularDeliveryServiceEvents.emitTabReqMaxFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  makeEndPointForRequestMax = () => SDM_URL.regularDelivery.requestMax.default;

  getComponentName(): string {
    return 'regular-delivery.request-max';
  }
  getNewInstance(): RegularDeliveryRequestMax {
    return new RegularDeliveryRequestMax();
  }
  getService(): AbstractService<RegularDeliveryRequestMax> {
    return this._regDeliveryReqMaxService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
}
