import { AbstractStatusModel } from '../../abstractStatus.model';

export class ImpreciseFields extends AbstractStatusModel {
  public establishmentId: number;
  public depositorId: number;
  public document: number;
  public shippingDocument: boolean;
  public qtdLinesNF: boolean;
  public qtdOrPiecesUnit: boolean;
  public qtdVolume: boolean;
  public totalGrossWeight: boolean;
  public totalLiquidWeight: boolean;

  constructor() {
    super();
    this.shippingDocument = false;
    this.qtdVolume = false;
    this.qtdLinesNF = false;
    this.qtdOrPiecesUnit = false;
    this.totalGrossWeight = false;
    this.totalLiquidWeight = false;
  }
}
