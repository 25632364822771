import { AbstractStatusModel } from '../../abstractStatus.model';

export class OcurrencyRework extends AbstractStatusModel {
  public establishmentId: number;

  public depositorId: number;

  public noShowFixedValue: number;

  public noShowVariableValue: number;

  public cancelAntecedenceFixedValue: number;

  public cancelAntecedenceVariableValue: number;

  public arrivedVehicleFixedValue: number;

  public arrivedVehicleVariableValue: number;

  constructor() {
    super();
  }
}
