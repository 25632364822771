import { Component, OnInit, Input } from '@angular/core';
import { Legend } from './legend';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {
  @Input() configLegend: Legend[] = [];
  @Input() className = '';

  constructor() {}

  ngOnInit() {}
}
