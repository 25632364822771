import { PriorVisualization } from './../../../../../core/models/sdm/docks/prior-visualization';
import { DatatableApiService } from './../../../../../components/datatable-api/datatable-api.service';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { take } from 'rxjs/operators';
import { SDM_URL } from './../../../../../app.api';
import { OperationNatureDepositorService } from './../../../../../core/services/operation-nature/operationNatureDepositor.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { DockCompatibilityService } from './../../../../../core/services/sdm/docks/dock-compatibility.service';
import { DockCompatibility } from './../../../../../core/models/sdm/docks/dock-compatibility';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractRegister } from '../../../../register/abstract-register';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DocksService } from '../../docks.service';

@Component({
  selector: 'app-dock-compatibility-form',
  templateUrl: './dock-compatibility-form.component.html',
  styleUrls: ['./dock-compatibility-form.component.scss']
})
export class DockCompatibilityFormComponent
  extends AbstractRegister<DockCompatibility>
  implements OnInit {
  dockCompatibility: DockCompatibility;
  @ViewChild('gridDockCompatibility')
  gridDockCompatibility: DatatableApiComponent;

  @ViewChild('hdrTplVigencia') hdrTplVigencia: TemplateRef<any>;
  @ViewChild('bodyTplVigencia') bodyTplVigencia: TemplateRef<any>;

  @ViewChild('hdrTplStatusDepositor') hdrTplStatusDepositor: TemplateRef<any>;
  @ViewChild('bodyTplStatusDepositor') bodyTplStatusDepositor: TemplateRef<any>;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  columnsDockCompatibility: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    // { prop: 'sdmDock', name: '# Doca  SDM' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    {
      prop: 'regularDelivery',
      name: 'Entrega Regular',
      pipe: new BooleanConverter()
    },
    {
      prop: 'reverseDelivery',
      name: 'Entrega Reversa',
      pipe: new BooleanConverter()
    },
    { prop: 'collect', name: 'Coleta', pipe: new BooleanConverter() }
  ];

  columnsPriorVisualization = [
    { prop: 'id', name: '# Doca SDM' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'initialHour', name: 'Horário início' },
    { prop: 'finalHour', name: 'Horário término' },
    {
      prop: 'exclusiveDock',
      name: 'Doca EXCLUSIVA?',
      pipe: new BooleanConverter()
    }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    private _dockCompatibilityService: DockCompatibilityService,
    public depositorService: DepositorService,
    private _operationNatureDepositorService: OperationNatureDepositorService,
    private _dockServiceEvents: DocksService,
    private _dataTableApiService: DatatableApiService
  ) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.columnsDockCompatibility.splice(5, 0, {
      name: 'Detalhamento Dias da Semana e Horários',
      cellTemplate: this.bodyTplVigencia,
      headerTempalte: this.hdrTplVigencia
    });
    this.columnsDockCompatibility.splice(8, 0, {
      prop: 'depositor.status',
      name: 'Status Depositante',
      cellTemplate: this.bodyTplStatusDepositor,
      headerTempalte: this.hdrTplStatusDepositor
    });

    // this._dataTableApiService.gridRowSelected.subscribe(data => {
        // });

    this._dockServiceEvents.emitEventTabCompatibilityFired
      .pipe(take(1))
      .subscribe(() => this.gridDockCompatibility.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      sdmDock: new FormControl(''),
      signatureDock: new FormControl(''),
      regularDelivery: new FormControl(''),
      reverseDelivery: new FormControl(''),
      collect: new FormControl(''),
      initialHour: new FormControl(''),
      finalHour: new FormControl(''),
      exclusiveDock: new FormControl(''),
      regularDeliveryRisk: new FormControl('')
    });

    this.formulario.get('regularDelivery').valueChanges.subscribe(value => {
      if (typeof value === 'boolean' && value) {
        this.enableFields(['regularDeliveryRisk']);
        return;
      }

      this.formulario.get('regularDeliveryRisk').setValue(false);
      this.disableFields(['regularDeliveryRisk']);
    });
  }

  selectedValue(selected) {
    if (selected) {
      const { id } = selected;
      this.loadOperationNatureToDepositor(id);
      return;
    }
    this.formulario.get('regularDelivery').setValue('');
    this.formulario.get('reverseDelivery').setValue('');
    this.formulario.get('collect').setValue('');
  }

  loadOperationNatureToDepositor(depositorId: number) {
    this._operationNatureDepositorService
      .findByDepositorId(depositorId)
      .subscribe((data: any[]) => {
        this.getOperationsForFillInputs(data);
      });
  }

  getOperationsForFillInputs(operationNatures: any[]) {
    for (const opNature of operationNatures) {
      
      const { status, operationNature } = opNature.operationNature;
      this.fillInputOperationNatureDepositor(operationNature, status);
    }
  }

  fillInputOperationNatureDepositor(
    operationType: string,
    operationValue: boolean
  ) {
    switch (operationType) {
      case 'ENTREGA_REGULAR':
        this.formulario.get('regularDelivery').setValue(operationValue);
        break;
      case 'ENTREGA_REVERSA':
        this.formulario.get('reverseDelivery').setValue(operationValue);
        break;
      case 'COLETA':
        this.formulario.get('collect').setValue(operationValue);
        break;
    }
  }

  endpointUpload = () => SDM_URL.docks.dockCompatibility.upload;
  endPointPartial = () => SDM_URL.docks.dockCompatibility.importPartial;

  endPointDockCompatibility = () => SDM_URL.docks.dockCompatibility.default;

  endPointQuerySignature = () => SDM_URL.docks.priorVisualization.default;

  querySignatureDock = () =>
    SDM_URL.docks.priorVisualization.findById.replace(':id', '');

  getNewInstance(): DockCompatibility {
    return new DockCompatibility();
  }
  getService(): AbstractService<DockCompatibility> {
    return this._dockCompatibilityService;
  }
  getGrid(): DatatableApiComponent {
    return this.gridDockCompatibility;
  }
  getComponentName(): string {
    return 'docks.dock-compatibility';
  }
}
