import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'model-form',
  templateUrl: './model-form.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./model-form.component.scss']
})
export class ModelFormComponent implements OnInit {
  @Input() formName: FormGroup;
  @Input() formId: string;

  @Input() initMode = false;
  @Output() formChange = new EventEmitter();

  private disableForm() {
    this.formName.disable();
  }

  private enableForm() {
    this.formName.enable();
  }

  ngOnInit() {
    if (this.initMode) {
      this.disableForm();
      return;
    }

    this.enableForm();
  }
}
