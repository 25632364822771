import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SDM_URL } from '../../../../../app.api';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { RegularDeliveryOrder } from '../../../../../core/models/sdm/regular-delivery/order';
import { CharacterType } from '../../../../../core/models/sdm/shared/enums/CharacterType';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { DepositorService } from '../../../../../core/services/depositor.service';
import { OrderService } from '../../../../../core/services/sdm/regular-delivery/order.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { AbstractDelivery } from '../../abstract-delivery';
import { RegularDeliveryEvents } from './../../regular-delivery.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent extends AbstractDelivery<RegularDeliveryOrder> implements OnInit {

  public keys;
  depositors = [];
  sub: Subscription;
  characterTypes = CharacterType;
  public fields = ['description'];
  public order: RegularDeliveryOrder;
  @ViewChild('dataGrdRegularDeliveryOrder') grid: DatatableApiComponent;

  constructor(
    public location: Location,
    public tostr: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _orderService: OrderService,
    public regularDeliveryServiceEvents: RegularDeliveryEvents) {
    super(location, tostr, translatorService, depositorService);
    this.keys = Object.keys(this.characterTypes);
  }

  columnsRegularDeliveryOrder = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
  ];

  options = [
    { name: 'regular-delivery.order.options.true', value: true },
    { name: 'regular-delivery.order.options.false', value: false },
  ];

  optionsDefaultTrue = [
    { name: 'regular-delivery.order.options.true', value: true },
  ];

  ngOnInit() {
    this.tabOrderFired();

    this.order = new RegularDeliveryOrder();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      orderNumber: new FormControl(this.order.orderNumber),
      size: new FormControl('', [Validators.min(1), Validators.max(99999)]),
      orderType: new FormControl(''),
      allowRepeat: new FormControl(this.order.allowRepeat),
      numberAndNFSerie: new FormControl(this.order.numberAndNFSerie),
      emissionDate: new FormControl(this.order.emissionDate),
      documentSender: new FormControl(this.order.documentSender),
      documentRecipient: new FormControl(this.order.documentRecipient),
      documentShipping: new FormControl(this.order.documentShipping),
      qtdLines: new FormControl(this.order.qtdLines),
      qtdUnitOrParts: new FormControl(this.order.qtdUnitOrParts),
      qtdVolume: new FormControl(this.order.qtdVolume),
      totalValue: new FormControl(this.order.totalValue),
      totalGrossWeight: new FormControl(this.order.totalGrossWeight),
      totalNetWeight: new FormControl(this.order.totalNetWeight),
    });
  }

  tabOrderFired() {
    this.sub = this.regularDeliveryServiceEvents.emitTabOrderFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.order);
    this.controlFieldsRequireOrder();
  }

  changeOrderNumber(e) {
    this.controlFieldsRequireOrder();
  }

  controlFieldsRequireOrder() {
    if (this.formulario.get('orderNumber').value === 'true') {
      this.setRequiredForFields(['size', 'orderType']);
      this.enableFields(['size', 'orderType']);
      this.formulario.get('orderType').setValue('DEFAULT');
      return;
    }
    this.removeRequiredForFields(['size', 'orderType']);
    this.disableFields(['size', 'orderType']);
    this.formulario.get('orderType').setValue('DEFAULT');
    
  }

  submitted() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      const formValue = this.formulario.getRawValue();
      if (formValue['orderType'] === '') {
        formValue['orderType'] = 'DEFAULT';
      }
      
      if (this.formulario.get('id').value == null) {
        this.getService().create(formValue)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.grid.reloadPage();
            this.showMessageActions();
          });
      } else {
        this.getService().edit(formValue)
          .subscribe(() => {
            this.disabledForm();
            this.grid.reloadPage();
            this.showMessageActions('success', 'update');
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  orderNumberIsRequired = () => this.formulario.get('orderNumber').value === 'true';

  makeEndPointForRegularDeliveryOrder = () => SDM_URL.regularDelivery.order.default;

  getComponentName(): string {
    return 'regular-delivery.order';
  }

  getNewInstance(): RegularDeliveryOrder {
    return new RegularDeliveryOrder();
  }
  getService(): AbstractService<RegularDeliveryOrder> {
    return this._orderService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
}
