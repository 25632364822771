import { RegularDeliveryEvents } from './../regular-delivery.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { delay } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegularDelivery } from '../../../../core/models/sdm/regular-delivery/regular-delivery';
import { SDM_URL } from '../../../../app.api';
import { DepositorService } from '../../../../core/services/depositor.service';
import { RegularDeliveryService } from '../../../../core/services/sdm/regular-delivery/regular-delivery.service';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from '../../../../core/classes/boolean-type';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractService } from '../../../../core/services/abstract.service';
import { AbstractDelivery } from '../abstract-delivery';

@Component({
  selector: 'app-regular-delivery-form',
  templateUrl: './regular-delivery-form.component.html',
  styleUrls: ['./regular-delivery-form.component.scss']
})
export class RegularDeliveryFormComponent
  extends AbstractDelivery<RegularDelivery>
  implements OnInit {
  public depositors = [];
  public fields = ['description'];
  @ViewChild('dataGrdRegularDelivery') grid: DatatableApiComponent;

  constructor(
    public location: Location,
    public toastr: ToastrService,
    private depositorService: DepositorService,
    private _regularDeliveryService: RegularDeliveryService,
    public translatorService: TranslatorService,
    public regularDeliveryServiceEvents: RegularDeliveryEvents
  ) {
    super(location, toastr, translatorService, depositorService);
  }

  columnsRegularDelivery = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    {
      prop: 'manualTypeDoc',
      name: 'Digitação Manual',
      pipe: new BooleanConverter()
    },
    {
      prop: 'regularDeliveryDepositor',
      name: 'Entrega Regular Depositante',
      pipe: new BooleanConverter()
    },
    {
      prop: 'providerSolicitAuthorization',
      name: 'Fornecedor solicita Autorização',
      pipe: new BooleanConverter()
    },
    {
      prop: 'uniqueShippingSolicitAuthorization',
      name: 'Única transportadora efetua Solicitação',
      pipe: new BooleanConverter()
    },
    { prop: 'minAntecedenceShippingRegDelivery', name: 'Antecedência mínima' },
    { prop: 'maxAntecedenceShippingRegDelivery', name: 'Antecedência máxima' }
  ];

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      manualTypeDoc: new FormControl('', [Validators.required]),
      regularDeliveryDepositor: new FormControl('', [Validators.required]),
      providerSolicitAuthorization: new FormControl('', [Validators.required]),
      uniqueShippingSolicitAuthorization: new FormControl('', [
        Validators.required
      ]),
      minAntecedenceShippingRegDelivery: new FormControl('', [
        Validators.required
      ]),
      maxAntecedenceShippingRegDelivery: new FormControl('', [
        Validators.required
      ])
    });

    this.formulario
      .get('providerSolicitAuthorization')
      .valueChanges.subscribe(value => {
        this.formulario
          .get('uniqueShippingSolicitAuthorization')
          .setValue(value);
      });
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabOrder':
        this.regularDeliveryServiceEvents.tabOrder();
        break;
      case 'tabReqMax':
        this.regularDeliveryServiceEvents.tabReqMaxFired();
        break;
      case 'tabSmallSize':
        this.regularDeliveryServiceEvents.tabSmallSizeFired();
        break;
      case 'tabNfDeadLines':
        this.regularDeliveryServiceEvents.tabNfDeadLinesFired();
        break;
    }
  }

  afterAddButton() {
    this.formulario.get('manualTypeDoc').setValue(false);
    this.formulario.get('regularDeliveryDepositor').setValue(false);
    this.formulario.get('providerSolicitAuthorization').setValue(false);
    this.formulario.get('uniqueShippingSolicitAuthorization').setValue(false);
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.editMode = true;
    this.fillDepositorWhenSelectGridCell(row);
    const { depositorId } = this.formulario.getRawValue();
  }

  fillInputDepositorForView(depositorId: number) {
    this.depositorService
      .findById(depositorId)
      .pipe(delay(500))
      .subscribe(data => {
        this.depositors = [data];
      });
  }

  makeEndPointForRegularDelivery = () => SDM_URL.regularDelivery.default;

  getComponentName() {
    return 'regular-delivery';
  }

  getNewInstance(): RegularDelivery {
    return new RegularDelivery();
  }
  getService(): AbstractService<RegularDelivery> {
    return this._regularDeliveryService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
}
