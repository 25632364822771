import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { CollectSmallSize } from '../../../models/sdm/collect/collect-small-size';

@Injectable({
  providedIn: 'root',
})
export class CollectSmallSizeService extends AbstractService<CollectSmallSize> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.collect.smallSize.default);
  }

}
