import { SDM_URL } from './../../app.api';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { VehicleDepositor } from '../models/sdm/vehicle-depositor';
import { take } from 'rxjs/operators';

@Injectable()
export class VehicleDepositorService {
  // super(http, env.host.sdm, '/wrapper/vehicle-depositor');

  private URL = '/wrapper/vehicle-depositor';

  constructor(public http: HttpClient) {}

  activeDeactive(
    depositorId: number,
    vehicleTypeId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete<any>(
      `${env.host.sdm}${
        this.URL
      }/activeDeactive/${depositorId}/${vehicleTypeId}`,
      { observe: 'response' }
    );
  }

  create(model: VehicleDepositor): Observable<VehicleDepositor> {
    return this.http.post<VehicleDepositor>(
      `${env.host.sdm}${this.URL}`,
      model
    );
  }

  findByDepositorAndUserIdAndStatusTrue(
    depositorId: number,
    userId: number
  ): Observable<VehicleDepositor[]> {
    return this.http.get<VehicleDepositor[]>(
      `${SDM_URL.vehicle.depositor.findByDepositorAndUserIdAndStatusTrue
        .replace(':depositorId', depositorId.toString())
        .replace(':userId', userId.toString())}`
    );
  }
}
