import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractRegister } from '../../../abstract-register';
import { SDM_URL } from './../../../../../app.api';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { BlackList } from './../../../../../core/models/sdm/provider-management/black-list';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { BlackListService } from './../../../../../core/services/sdm/provider-management/black-list.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { ProviderManagement } from './../../provider-management.service';

@Component({
  selector: 'app-black-list-form',
  templateUrl: './black-list-form.component.html',
  styleUrls: ['./black-list-form.component.scss'],
})
export class BlackListFormComponent extends AbstractRegister<BlackList> implements OnInit {

  @ViewChild('gridBlackListProvider') grid: DatatableApiComponent;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  columnsBlackListProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'provider.document', name: 'CNPJ Fornecedor' },
    { prop: 'provider.shortName', name: 'Nome fantasia' },
    { prop: 'blackList', name: 'Black-List (Entrega Regular)', pipe: new BooleanConverter() },
  ];

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _providerManagementService: ProviderManagement,
    private _blackListService: BlackListService) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerManagementService.emitEventTabBlackListFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      shortName: new FormControl(''),
      document: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      providerId: new FormControl(''),
      blackList: new FormControl('', [Validators.required]),
    });
  }

  uploadedFile(data) {
    if (data) {
      this.grid.reloadPage();
    }
  }

  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  endPointBlackListProvider = () => SDM_URL.providerManagement.blackList.default;

  queryProviderDocument = () => SDM_URL.providerManagement.provider.findByDocument.replace(':document', '');

  endPointPartial = () => SDM_URL.providerManagement.provider.importPartial;

  getNewInstance(): BlackList {
    return new BlackList();
  }
  getService(): AbstractService<BlackList> {
    return this._blackListService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'provider-management.black-list';
  }

  endpointUpload = () => SDM_URL.providerManagement.blackList.upload;

}
