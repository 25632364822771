import { Depositor } from './../depositor';
import { Provider } from '@angular/compiler/src/core';
import { DeliveryAuthorization } from '../delivery-authorization/delivery-authorization';
import { PriorVisualization } from '../docks/prior-visualization';
import { DocksScheduling } from '../document/docks-scheduling';
export class CollectScheduleCancel {
  public depositor: Depositor;
  public provider: Provider;
  public deliveryAuthorization: DeliveryAuthorization;
  public requestedBy: number;
  public dateScheduleDelivery: string;
  public carShowSchedule: string;
  public initialHour: string;
  public finalHour: string;
  public priorVisualization: PriorVisualization;

  public recurrent: boolean;
  public conflicted: boolean;

  public docksScheduling: DocksScheduling;

  constructor() {
    this.docksScheduling = new DocksScheduling();
  }
}
