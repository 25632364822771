import { AbstractService } from '../../abstract.service';
import { RegularDeliveryOrder } from '../../../models/sdm/regular-delivery/order';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService extends AbstractService<RegularDeliveryOrder> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.regularDelivery.order.default);
  }

  createRegularDeliveryOrder(model: RegularDeliveryOrder[]): Observable<RegularDeliveryOrder> {
    return this.http.post<RegularDeliveryOrder>(SDM_URL.regularDelivery.order.create, model);
  }

  findByDepositorIdAndUser(id: number, userId: number): Observable<RegularDeliveryOrder> {
    return this.http.get<RegularDeliveryOrder>(SDM_URL.regularDelivery.order.findByDepositorId.replace(':depositorId', id.toString()).replace(':userId', userId.toString()));
  }

}
