import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment/moment';

export function validatorFinHour(fieldValidor: string): ValidatorFn {
  return (control: AbstractControl) => {
    const group = control.parent;
    const fieldToCompare = group.get(fieldValidor);
    let value1 = control.value;
    let value2 = fieldToCompare.value;
    return value1 <= value2 ? { validFinHour: { value: control.value } } : null;
  };
}

export function ValidatorDayOfMonth(control: AbstractControl) {
  const day = moment()
    .endOf('month')
    .format('DD');
  return control.value && control.value > day
    ? { validDayOfMonth: true }
    : null;
}
