import { IModalDialogButton, ModalDialogService } from 'ngx-modal-dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'btn-backup',
  templateUrl: './btn-backup.component.html',
  styleUrls: ['./btn-backup.component.scss'],
  preserveWhitespaces: true,
})
export class BtnBackupComponent implements OnInit {

  modalRef: BsModalRef;
  actionButtons: IModalDialogButton[];
  @Output() btnBkpClicked = new EventEmitter();

  constructor(private _modalService: BsModalService) {
    this.actionButtons = [
      { text: 'Sim', onAction: () => { this.backup(); return true; }, buttonClass: 'btn btn-outline-danger' },
      { text: 'Não', buttonClass: 'btn btn-info', onAction: () => true },
    ];
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, { ...{ class: 'gray modal-sm' } });
  }

  ngOnInit() {

  }

  backup() {
    this.modalRef.hide();
    this.btnBkpClicked.emit();
  }

  decline() {
    this.modalRef.hide();
  }

  confirm() {
    this.backup();
  }

}
