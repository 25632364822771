import { CriticalItemsService } from './../../../../../core/services/sdm/provider-management/critical-items.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../abstract-register';
import { CriticalItems } from '../../../../../core/models/sdm/provider-management/critical-items';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SDM_URL } from '../../../../../app.api';
import { ProviderManagement } from '../../provider-management.service';
import { take } from 'rxjs/operators';
import { DateFormatPipe } from '../../../../../core/classes/datePipe';

@Component({
  selector: 'app-critical-items-form',
  templateUrl: './critical-items-form.component.html',
  styleUrls: ['./critical-items-form.component.scss'],
})
export class CriticalItemsFormComponent extends AbstractRegister<CriticalItems> implements OnInit {

  @ViewChild('gridCriticalItems') grid$: DatatableApiComponent;

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
  ];

  columnsCriticalItems = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'depositor.document', name: 'CNPJ Depositante' },
    { prop: 'provider.document', name: 'CNPJ Fornecedor' },
    { prop: 'skuProvider', name: 'Código SKU Fornecedor' },
    { prop: 'skuDepositor', name: 'Código SKU Depositante' },
    { prop: 'skuDescription', name: 'Descrição SKU' },
    { prop: 'validityIni', name: 'Início Vigência', pipe: new DateFormatPipe('en-US') },
    { prop: 'validityFin', name: 'Término Vigência', pipe: new DateFormatPipe('en-US') },
    { prop: 'observation', name: 'Observação' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _criticalItemsService: CriticalItemsService,
    private _providerService: ProviderManagement) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerService.emitEventTabCriticalItemsFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      depositorDocument: new FormControl(''),
      skuProvider: new FormControl(''),
      skuDepositor: new FormControl(''),
      skuDescription: new FormControl(''),
      validityIni: new FormControl(''),
      validityFin: new FormControl(''),
      observation: new FormControl(''),
    });
  }

  changeDepositor(data) {
    if (data) {
      this.formulario.get('depositorDocument').setValue(data.document);
      return;
    }
    this.formulario.get('depositorDocument').setValue('');
  }

  endpointUpload = () => SDM_URL.providerManagement.criticalItems.upload;
  endPointPartial = () => SDM_URL.providerManagement.criticalItems.importPartial;

  queryProviderDocument = () => SDM_URL.providerManagement.priorityProviders.findByDocument.replace(':document', '');

  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  endPointCriticalItems = () => SDM_URL.providerManagement.criticalItems.default;

  getNewInstance(): CriticalItems {
    return new CriticalItems();
  }
  getService(): AbstractService<CriticalItems> {
    return this._criticalItemsService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.critical-items';
  }

}
