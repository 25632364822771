import { Depositor } from './../../core/models/sdm/depositor';
import { AuthService } from './../../core/services/auth.service';
import { DepositorService } from './../../core/services/depositor.service';
import { DeliveryAuthorizationService } from './../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
  SecurityContext
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay, observeOn } from 'rxjs/operators';
import { AbstractService } from '../../core/services/abstract.service';
import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { TranslatorService } from './../../core/translator/translator.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeliveryAuthorizationEnum } from '../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { ProviderService } from '../../core/services/sdm/provider-management/provider.service';
import { Provider } from '../../core/models/sdm/provider-management/provider';
import { DeliveryAuthorization } from '../../core/models/sdm/delivery-authorization/delivery-authorization';
import { RegularDeliveryService } from '../../core/services/sdm/regular-delivery/regular-delivery.service';

export abstract class AbstractDelivery<T> extends BaseFormComponent
  implements OnDestroy {
  bsModalRef: BsModalRef;
  bsModalRefAuthorization: BsModalRef;
  subscriptions: Subscription[] = [];

  docId = null;
  dismissible = true;
  isFirstDocInserted = false;
  solicitationGenerated = false;

  depositorSelected: Depositor;
  providerSelected: Provider;

  depositors = [];
  providers: Provider[] = [];
  listOfDocsInSolicitation: string[] = [];

  insertedNFs = 0;

  constructor(
    public modalService: BsModalService,
    public _location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    sanitizer: DomSanitizer,
    public _deliveryAuthorizationService: DeliveryAuthorizationService,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    public _authService: AuthService,
    public router: Router,
    public _regularDeliveryService: RegularDeliveryService
  ) {
    super(_location, toaster, translator);

    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));

    const { id } = this._authService.getUser();
  }

  alerts: any = [];

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  closeAlertFirstDocValidated(): void {
    this.alerts = this.alerts.filter(alert => !alert.isFirstDocValidated);
  }

  openModalWithComponent(provider: any) {
    const initialState = {
      title: 'Mensagem Confirmação',
      form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalContentMessageComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.pipe(delay(1)).subscribe(resp => {
        try {
          if (/OK/.test(resp)) {
            const depositor = this.formulario.get('depositorId').value;

            

            [
              'nfNumber',
              'nfSerie',
              'orderNumber',
              'totalQtdItems',
              'totalQtdUnit',
              'totalValueNf',
              'totalGrossWeight',
              'totalNetWeight',
              'qtdVol'
            ].forEach(control => {
              if (this.formulario.contains(control)) {
                this.formulario.get(control).setValue('');
              }
            });

            const requestID = this.formulario.get('deliveryAuthorizationId')
              .value;
            this.formulario.get('orderNumber').setValue('');

            this.formulario.get('deliveryAuthorizationId').setValue(requestID);

            this._regularDeliveryService
              .findByDepositorId(this.formulario.get('depositorId').value)
              .subscribe(data => {
                if (data) {
                  if (data['providerSolicitAuthorization']) {
                    if (this.formulario.controls.hasOwnProperty('provider')) {
                      this.formulario.get('provider').setValue('');
                    } else if (
                      this.formulario.controls.hasOwnProperty('providerId')
                    ) {
                      this.formulario.get('providerId').setValue('');
                    }
                  } else {
                    if (this.formulario.controls.hasOwnProperty('provider')) {
                      this.formulario.get('provider').disable();
                      this.formulario.get('provider').setValue(provider);
                    } else if (
                      this.formulario.controls.hasOwnProperty('providerId')
                    ) {
                      this.formulario.get('providerId').disable();
                      this.formulario.get('providerId').setValue(provider);
                    }
                  }
                }
              });
          } else if (resp === DeliveryAuthorizationEnum.DOC_OK) {
            const requestID = this.formulario.get('deliveryAuthorizationId')
              .value;
            this._deliveryAuthorizationService
              .updateStatusAuthorization(
                requestID,
                +DeliveryAuthorizationEnum.DOC_OK
              )
              .subscribe(data => {
                

                if (!this.solicitationGenerated) {
                  this.solicitationGenerated = true;
                }

                this.closeAlertFirstDocValidated();
                this.alertMsg(
                  `# Solicitação de Autorização de entrega ${
                    data['authorizationkey']
                  }`,
                  'success',
                  false,
                  false,
                  this.solicitationGenerated
                );
                this.openModalAuthorization();
              });
          }
        } catch (e) {
          console.error(e);
        }

        this.unsubscribe();
      })
    );
  }

  openModalAuthorization() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Processo de Autorização de Entrega ainda NÃO concluído. Deseja continuar?',
      form: this.formulario
    };
    this.bsModalRef = this.modalService.show(
      ModalDeliveryAuthorizationComponent,
      { initialState }
    );
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe(resp => {
        if (resp === 'CANCEL') {
          this.router.navigate(['sdm/delivery-authorization/regular-delivery']);
          // const requestID = this.formulario.get('deliveryAuthorizationId')
          //   .value;
          // this._deliveryAuthorizationService
          //   .updateStatusAuthorization(
          //     requestID,
          //     +DeliveryAuthorizationEnum.INTERROMPIDA
          //   )
          //   .subscribe(data => {
          //     this.alertMsg(
          //       `Solicitação de Autorização de entrega ${
          //         data['authorizationkey']
          //       }, interrompida`,
          //       'info'
          //     );
          //     this.clearForm();
          //   });
        } else if (resp === 'OK') {
          const requestID = this.formulario.get('deliveryAuthorizationId')
            .value;
          // this._deliveryAuthorizationService
          //   .updateStatusAuthorization(
          //     requestID,
          //     +DeliveryAuthorizationEnum.DOC_OK
          //   )
          this._deliveryAuthorizationService
            .findCustomById(requestID)
            .subscribe((data: DeliveryAuthorization) => {
              
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  depositor: JSON.stringify(this.depositorSelected),
                  provider: JSON.stringify(data.documents.map(d => d.provider)),
                  deliveryAuthorization: JSON.stringify(data)
                },
                queryParamsHandling: 'merge',
                skipLocationChange: true
              };
              this.router.navigate(
                [
                  'sdm/delivery-authorization/regular-delivery/dimensioning-info'
                ],
                navigationExtras
              );
            });
        }

        this.unsubscribe();
      })
    );
  }

  selectedDepositor(depositor: Depositor) {
    this.depositorSelected = depositor;
  }

  selectedProvider(provider: Provider) {
    this.providerSelected = provider;
  }

  clearForm() {
    this.formulario.reset();
    this.formulario.markAsPristine();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  submit() {
    if (this.formulario.valid) {
      if (
        this.formulario.controls.hasOwnProperty('depositorId') &&
        typeof this.formulario.get('depositorId').value === 'object'
      ) {
        const { id } = this.formulario.get('depositorId').value;
        this.formulario.get('depositorId').setValue(id);
      }
      
      this.getService()
        .create(this.formulario.value)
        .subscribe(
          data => {
            this.savedSuccess(data);
          },
          ({ error }) => {
            this.errorMessage(error);
          }
        );
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  savedSuccess(data: any) {
    this.insertedNFs += 1;

    
    const objValue = data.response || data;
    if (objValue) {
      let provider = this.formulario.controls.hasOwnProperty('provider')
        ? this.formulario.get('provider').value
        : this.formulario.get('providerId').value;

      this.formulario.patchValue(objValue);
      this.formulario.get('depositor').setValue(this.depositorSelected);
      this.formulario
        .get('deliveryAuthorizationId')
        .setValue(objValue['deliveryAuth']);

      this.alertMsg(
        `Documento ( ${objValue['providerId']} -
        ${objValue['nfNumber']}/${objValue['nfSerie']} )
        Validado com sucesso!`,
        'success',
        this.isFirstDocInserted,
        !this.isFirstDocInserted
      );

      if (!this.isFirstDocInserted) {
        this.isFirstDocInserted = true;
      }

      this.addDocToListView(objValue);

      this.openModalWithComponent(provider);
    }
  }

  addDocToListView(data: any) {
    const docInfo = `${data['providerId']} - ${data['nfNumber']}/${
      data['nfSerie']
    }`;
    this.listOfDocsInSolicitation = this.listOfDocsInSolicitation.concat(
      docInfo
    );
    this.formulario
      .get('solicitations')
      .patchValue(this.listOfDocsInSolicitation);
  }

  errorMessage(errors) {
    

    const errorsMsg = errors['data'] || errors;

    if (errorsMsg && errorsMsg.length > 0) {
      errorsMsg.forEach(error => {
        this.alertMsg(`${error.field}: ${error.message}`, 'danger');
      });
    }
  }

  alertMsg(
    message?: string,
    type = 'success',
    closable = true,
    isFirstDocValidated = false,
    solicitationGenerated = false
  ) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      closable,
      isFirstDocValidated,
      solicitationGenerated
      // timeout: 2500,
    });
  }

  setFieldErrors(field: string, error: string) {
    const ctrl = this.formulario.get(field);
    return ctrl.dirty && ctrl.hasError(error);
  }

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;

  ngOnDestroy(): void {
    // localStorage.removeItem('docId');
  }
}

@Component({
  selector: 'app-modal-content-message',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="exitConfirm(templateNested)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- <span class="text-success" style="padding-left: 5px">{{message}}</span> -->
      <app-input-checkbox
        #inpCheckInclude
        label="Deseja incluir outra NF na mesma entrega?"
        class="col-sm-6 col-md-6 col-lg-2"
        [(ngModel)]="wishInclude"
      ></app-input-checkbox>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        (click)="exitConfirm(templateNested)"
      >
        {{ closeBtnName }}</button
      >&nbsp;&nbsp;
      <button
        type="button"
        class="btn btn-success"
        (click)="exitConfirmOnSuccess()"
      >
        {{ confirmBtn }}
      </button>
    </div>

    <ng-template #templateNested>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Confirmação</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="modalRefConfirmExit.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Digitação de dados será perdida, Deseja realmente Sair?
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="exitConfirmModal2()"
        >
          Sim</button
        >&nbsp;&nbsp;
        <button
          type="button"
          class="btn btn-success"
          (click)="openGenerateAuthorization()"
        >
          Não
        </button>
      </div>
    </ng-template>
  `
})
export class ModalContentMessageComponent implements OnInit {
  title: string;
  closeBtnName: string;
  confirmBtn: string;
  message = '';
  wishInclude = false;
  form: FormGroup;

  modalRefConfirmExit: BsModalRef;

  @ViewChild('inpCheckInclude') inpCheckInclude: ElementRef<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private _modalService: BsModalService,
    private router: Router,
    public modalService: BsModalService
  ) {}

  ngOnInit() {}

  openGenerateAuthorization() {
    this.modalRefConfirmExit.hide();
    this.form.reset();
    this.form.markAsPristine();
  }

  exitConfirmOnSuccess() {
    if (this.wishInclude) {
      this.bsModalRef.hide();
      this.modalService.setDismissReason('OK');
      return;
    }

    this.wishInclude = false;
    this.bsModalRef.hide();
    this.modalService.setDismissReason(DeliveryAuthorizationEnum.DOC_OK);
  }

  exitConfirm(template: TemplateRef<any>) {
    this.modalRefConfirmExit = this._modalService.show(template, {
      class: 'second'
    });
    this.bsModalRef.hide();
  }

  exitConfirmModal2() {
    // localStorage.removeItem('docId');
    this.modalRefConfirmExit.hide();
    this.router.navigate(['sdm/delivery-authorization/regular-delivery']);
  }
}

@Component({
  selector: 'app-modal-delivery-authorization',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="exitConfirm()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span style="padding-left: 5px">{{ message }}</span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        (click)="exitConfirm()"
      >
        {{ closeBtnName }}</button
      >&nbsp;&nbsp;
      <button
        type="button"
        class="btn btn-success"
        (click)="exitConfirmOnSuccess()"
      >
        {{ confirmBtn }}
      </button>
    </div>
  `
})
export class ModalDeliveryAuthorizationComponent implements OnInit {
  title: string;
  closeBtnName: string;
  confirmBtn: string;
  message = '';
  // wishInclude = false;
  form: FormGroup;

  @ViewChild('inpCheckInclude') inpCheckInclude: ElementRef<any>;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {}

  exitConfirmOnSuccess() {
    // localStorage.removeItem('docId');
    this.bsModalRef.hide();
    this.modalService.setDismissReason('OK');
    // this.router.navigate(['sdm/delivery-authorization/regular-delivery']);
  }

  exitConfirm() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('CANCEL');
  }
}
