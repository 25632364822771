import { RegularDeliveryResolverGuard } from './regular-delivery/regular-delivery-resolver.guard';
import { QueryDeliveryComponent } from './query-delivery/query-delivery.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { TextMaskModule } from 'angular2-text-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSelectModule } from 'ngx-select-ex';
import { ToastrModule } from 'ngx-toastr';
import { PatternButtonsComponent } from './../../components/pattern-buttons/pattern-buttons.component';
import { SharedModule } from './../../shared/shared.module';
import { PendingDeliveryComponent } from './pending-delivery/pending-delivery.component';
import { DimensioningInfoComponent } from './regular-delivery/dimensioning-info/dimensioning-info.component';
import { ManualTypeDocComponent } from './regular-delivery/manual-type-doc/manual-type-doc.component';
import { MassiveUploadComponent } from './regular-delivery/massive-upload/massive-upload.component';
import { RegularDeliveryComponent } from './regular-delivery/regular-delivery.component';
import { UnitaryUploadComponent } from './regular-delivery/unitary-upload/unitary-upload.component';
import { QtdSlotsComponent } from './regular-delivery/qtd-slots/qtd-slots.component';
import { WithoutScheduleComponent } from './regular-delivery/without-schedule/without-schedule.component';
import { ScheduleWithoutOptionComponent } from './regular-delivery/schedule-without-option/schedule-without-option.component';
import { ContinueRescheduleComponent } from './query-delivery/continue-reschedule/continue-reschedule.component';
import { RescheduleApprovedSelectionComponent } from './query-delivery/reschedule-approved-selection/reschedule-approved-selection.component';
import { RescheduleTermValidationComponent } from './query-delivery/reschedule-term-validation/reschedule-term-validation.component';
import { AllocationFindedComponent } from './regular-delivery/allocation-finded/allocation-finded.component';
import { GeneratedAuthorizationComponent } from './regular-delivery/generated-authorization/generated-authorization.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RescheduleUniqueOptionComponent } from './query-delivery/reschedule-unique-option/reschedule-unique-option.component';
import { RescheduleVariousOptionComponent } from './query-delivery/reschedule-various-option/reschedule-various-option.component';
import { CancelValidationTermComponent } from './query-delivery/cancel-validation-term/cancel-validation-term.component';
import { CancelDeliveryAuthorizationComponent } from './query-delivery/cancel-delivery-authorization/cancel-delivery-authorization.component';
import { TagInputModule } from 'ngx-chips';

const routes = [
  {
    path: 'regular-delivery',
    component: RegularDeliveryComponent
    // children: [
    //   { path: 'manual-type-doc', component: ManualTypeDocComponent },
    // ],
  },
  {
    path: 'regular-delivery/manual-type-doc',
    component: ManualTypeDocComponent,
    resolve: { deliveryAuthorization: RegularDeliveryResolverGuard }
  },
  {
    path: 'regular-delivery/unitary-upload',
    component: UnitaryUploadComponent,
    resolve: { deliveryAuthorization: RegularDeliveryResolverGuard }
  },
  {
    path: 'regular-delivery/massive-upload',
    component: MassiveUploadComponent
  },
  {
    path: 'regular-delivery/dimensioning-info',
    component: DimensioningInfoComponent
  },
  { path: 'regular-delivery/qtd-slots', component: QtdSlotsComponent },
  {
    path: 'regular-delivery/without-schedule',
    component: WithoutScheduleComponent
  },
  {
    path: 'regular-delivery/allocation-finded',
    component: AllocationFindedComponent
  },
  {
    path: 'regular-delivery/schedule-without-option',
    component: ScheduleWithoutOptionComponent
  },
  {
    path: 'regular-delivery/generated-authorization',
    component: GeneratedAuthorizationComponent
  },

  { path: 'pending-delivery', component: PendingDeliveryComponent },
  { path: 'edit', component: QueryDeliveryComponent },
  { path: 'edit/continue-reschedule', component: ContinueRescheduleComponent },
  {
    path: 'edit/reschedule-approved-selection',
    component: RescheduleApprovedSelectionComponent
  },
  {
    path: 'edit/reschedule-term-validation',
    component: RescheduleTermValidationComponent
  },
  {
    path: 'edit/reschedule-unique-option',
    component: RescheduleUniqueOptionComponent
  },
  {
    path: 'edit/reschedule-various-option',
    component: RescheduleVariousOptionComponent
  },
  {
    path: 'edit/cancel-delivery-authorization',
    component: CancelDeliveryAuthorizationComponent
  },
  {
    path: 'edit/cancel-validation-term',
    component: CancelValidationTermComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSelectModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    TagInputModule
  ],
  declarations: [
    RegularDeliveryComponent,
    PendingDeliveryComponent,
    ManualTypeDocComponent,
    PatternButtonsComponent,
    UnitaryUploadComponent,
    MassiveUploadComponent,
    DimensioningInfoComponent,
    QtdSlotsComponent,
    WithoutScheduleComponent,
    ScheduleWithoutOptionComponent,
    QueryDeliveryComponent,
    ContinueRescheduleComponent,
    RescheduleApprovedSelectionComponent,
    RescheduleTermValidationComponent,
    AllocationFindedComponent,
    GeneratedAuthorizationComponent,
    RescheduleUniqueOptionComponent,
    RescheduleVariousOptionComponent,
    CancelValidationTermComponent,
    CancelDeliveryAuthorizationComponent
  ],
  exports: [RouterModule, PatternButtonsComponent]
})
export class DeliveryAuthorizationModule {}
