import { ServiceInterface } from "./service-interface";
import { User } from "../models/sdm/user";
import { Observable } from "rxjs";
import { HttpResponse, HttpClient } from "@angular/common/http";
import { environment as env } from "../../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable()
export class UserService implements ServiceInterface<User> {

    private URL: String = '/wrapper/user';

    constructor(private http: HttpClient) { }

    create(model: User): Observable<User> {
        return this.http.post<User>(`${env.host.sdm}${this.URL}`, model)
    }

    edit(model: User): Observable<HttpResponse<User>> {
        return this.http.put<User>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
    }

    findById(id: Number): Observable<User> {
        throw new Error("Method not implemented.");
    }

    findAll(): Observable<HttpResponse<User>> {
        throw new Error("Method not implemented.");
    }

    activeDeactive(id: Number): Observable<User> {
        throw new Error("Method not implemented.");
    }

    delete(id: Number) {
        return this.http.delete(`${env.host.sdm}${this.URL}/${id}`)
    }
}