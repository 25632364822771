import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ManualAllocationReverse } from './../../../../../../core/models/sdm/schedules/reverse-delivery/manual-allocation-reverse';

@Injectable({
  providedIn: 'root'
})
export class ManualAllocationResolverGuard implements Resolve<any> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    
    if (route.queryParams && route.queryParams['manualAllocation']) {
      return JSON.parse(route.queryParams['manualAllocation']);
    }

    return of(new ManualAllocationReverse());
  }
}
