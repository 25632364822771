import { NgModule } from '@angular/core';
import { Router, RouterModule, RoutesRecognized } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxSelectModule } from 'ngx-select-ex';
import { MenuService } from '../core/menu/menu.service';
import { TranslatorService } from '../core/translator/translator.service';
import { SharedModule } from '../shared/shared.module';
import { menuSdm } from './menu';
import { menuGateway } from './menu-gateway';
import { menuSbaw } from './menu-sbaw';
import { routes } from './routes';
import { AuthService } from '../core/services/auth.service';
import { AuthGuardService as AuthGuard } from '../security/auth-guard.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    NgxSelectModule,
    TextMaskModule
  ],
  declarations: [],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard, AuthService]
})
export class RoutesModule {
  constructor(
    public router: Router,
    public menuService: MenuService,
    public authService: AuthService,
    tr: TranslatorService
  ) {

    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {

        let path = event.url;
        if (path.toLowerCase().search('/gateway') > -1) {
          menuService.addMenu(menuGateway);
        } else if (path.toLowerCase().search('/sdm') > -1) {
          menuService.addMenu(menuSdm);
        } else if (path.toLowerCase().search('/sbaw') > -1) {
          menuService.addMenu(menuSbaw);
        }
        else if (path.toLowerCase().search('/login') == -1) {
          menuService.addMenu(menuGateway);
        }
      }
    });
  }
}
