import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { take } from 'rxjs/operators';
import { SDM_URL } from './../../../../../app.api';
import { ImpreciseFieldsService } from './../../../../../core/services/sdm/provider-management/imprecise-fields.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../abstract-register';
import { ImpreciseFields } from '../../../../../core/models/sdm/provider-management/imprecise-fields';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProviderManagement } from '../../provider-management.service';

@Component({
  selector: 'app-imprecise-fields-form',
  templateUrl: './imprecise-fields-form.component.html',
  styleUrls: ['./imprecise-fields-form.component.scss']
})
export class ImpreciseFieldsFormComponent
  extends AbstractRegister<ImpreciseFields>
  implements OnInit {
  @ViewChild('gridImpreciseFields') grid$: DatatableApiComponent;

  columnsImpreciseFields = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'provider.document', name: 'CNPJ Fornecedor' },
    {
      prop: 'shippingDocument',
      name: 'CNPJ Transportadora',
      pipe: new BooleanConverter()
    },
    {
      prop: 'qtdLinesNF',
      name: 'Qtdd de Linhas de NF',
      pipe: new BooleanConverter()
    },
    {
      prop: 'qtdOrPiecesUnit',
      name: 'Qtdd de Unidades ou Peças',
      pipe: new BooleanConverter()
    },
    {
      prop: 'qtdVolume',
      name: 'Qtdd de Volumes',
      pipe: new BooleanConverter()
    },
    {
      prop: 'totalGrossWeight',
      name: 'Peso Bruto Total (Kg)',
      pipe: new BooleanConverter()
    },
    {
      prop: 'totalLiquidWeight',
      name: 'Peso Líquido Total (Kg)',
      pipe: new BooleanConverter()
    }
  ];

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' }
  ];

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _impreciseFieldsService: ImpreciseFieldsService,
    private _providerManagementService: ProviderManagement
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerManagementService.emitEventTabImpreciseFields
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      shippingDocument: new FormControl(''),
      qtdLinesNF: new FormControl(''),
      qtdOrPiecesUnit: new FormControl(''),
      qtdVolume: new FormControl(''),
      totalGrossWeight: new FormControl(''),
      totalLiquidWeight: new FormControl(''),
      createdBy: new FormControl('')
    });
  }

  endpointUpload = () => SDM_URL.providerManagement.impreciseFields.upload;

  endPointPartial = () =>
    SDM_URL.providerManagement.impreciseFields.importPartial;

  queryProviderDocument = () =>
    SDM_URL.providerManagement.priorityProviders.findByDocument.replace(
      ':document',
      ''
    );

  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  endPointImpreciseFields = () =>
    SDM_URL.providerManagement.impreciseFields.default;

  getNewInstance(): ImpreciseFields {
    return new ImpreciseFields();
  }
  getService(): AbstractService<ImpreciseFields> {
    return this._impreciseFieldsService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.imprecise-fields';
  }
}
