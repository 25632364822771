import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { SDM_URL } from './../../../../../app.api';
import { take } from 'rxjs/operators';
import { DeliveryType } from './../../../../../core/models/sdm/shared/enums/DeliveryType';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { CollectNfService } from './../../../../../core/services/sdm/collect/collect-nf.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../../register/abstract-register';
import { CollectNF } from '../../../../../core/models/sdm/collect/collect-nf';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollectService as CollectEvents } from '../../collect.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-collect-nf-form',
  templateUrl: './collect-nf-form.component.html',
  styleUrls: ['./collect-nf-form.component.scss'],
})
export class CollectNfFormComponent extends AbstractRegister<CollectNF> implements OnInit {

  @ViewChild('grdDataCollectNF') grid$: DatatableApiComponent;

  keys: string[];
  sub: Subscription;
  collectNF: CollectNF;
  registerNoShow = false;
  deliveryType = DeliveryType;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  optionsDefaultOK = [
    { name: 'genericValues.options.true', value: true },
  ];

  columnsNF = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Estabelecimento' },
    { prop: 'establishment.description', name: 'Estabelecimento' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'autoNoShowRegister', name: 'No Show Automático?', pipe: new BooleanConverter() },
    { prop: 'afterQtdTime', name: 'Após quanto tempo (min)?' },
    { prop: 'allowUpdateRegister', name: 'Permite Alterar Registro?', pipe: new BooleanConverter() },
    { prop: 'onlyInZeroDay', name: 'Somente em D0?', pipe: new BooleanConverter() },
    { prop: 'maxDeadlineDelivery', name: 'Prazo Máximo Entrega' },
    { prop: 'minConfirmNewAuthorizationExternalUser', name: 'Antecedência Mínima p/ Agendamento Usuário externo' },
    { prop: 'minReschedulingConfirmExternalUser', name: 'Antecedência Mínima p/ Reagendamento Usuário externo' },
    { prop: 'minCancelRequestExternalUser', name: 'Antecedência Mínima p/ Cancelamento Usuário Externo' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _collectNFService: CollectNfService,
    private _collectEvents: CollectEvents,
  ) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.deliveryType);
  }

  ngOnInit() {
    this.tabNfFired();

    this.collectNF = new CollectNF();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      minDeadLineRequired: new FormControl('', [Validators.min(1), Validators.max(999)]),
      maxDeadlineDelivery: new FormControl('', [Validators.required]),
      maxExpirationRequestDeadLine: new FormControl(''),
      maxExpirationRequestMinAntecedence: new FormControl(''),
      autoCancelMaxDeadLine: new FormControl(''),
      autoCancelMinAntecedence: new FormControl(''),
      minConfirmNewAuthorizationExternalUser: new FormControl(''),
      minConfirmNewAuthorizationInternalUser: new FormControl(''),
      minReschedulingConfirmExternalUser: new FormControl(''),
      minReschedulingConfirmInternalUser: new FormControl(''),
      minCancelRequestExternalUser: new FormControl(''),
      minCancelRequestInternalUser: new FormControl(''),
      autoNoShowRegister: new FormControl(''),
      afterQtdTime: new FormControl(''),
      allowUpdateRegister: new FormControl(''),
      onlyInZeroDay: new FormControl(''),
      requirePasswdSupervisor: new FormControl(''),
    });
  }

  tabNfFired() {
    this.sub = this._collectEvents.emitTabNfDeadLinesFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.enableDisableFields();
    this.statusFields();
    this.statusAllowUpdateRegister();
  }

  changeAllowUpdateRegister(e) {
    if (this.formulario.get('allowUpdateRegister').value === 'true' || this.formulario.get('allowUpdateRegister').value === true) {
      this.setRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      this.enableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      this.formulario.get('onlyInZeroDay').setValue(true);
      this.formulario.get('requirePasswdSupervisor').setValue(true);
      return;
    }
    this.removeRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
    this.disableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
    this.formulario.get('onlyInZeroDay').setValue(false);
    this.formulario.get('requirePasswdSupervisor').setValue(false);
  }

  statusFields() {
    this.disableFields(['maxDeadlineDelivery', 'maxExpirationRequestDeadLine', 'maxExpirationRequestMinAntecedence', 'autoCancelMaxDeadLine', 'autoCancelMinAntecedence', 'minConfirmNewAuthorizationExternalUser', 'minReschedulingConfirmExternalUser', 'minCancelRequestExternalUser']);
  }

  statusAllowUpdateRegister() {
    if (this.formulario.get('allowUpdateRegister').value === 'true' || this.formulario.get('allowUpdateRegister').value === true) {
      this.setRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      this.enableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      return;
    }
    this.removeRequiredForFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
    this.disableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
  }

  changeRegisterNoShow(event) {
    this.registerNoShow = (event.target.value === 'true');
    this.enableDisableFields();
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.collectNF);
  }

  enableDisableFields() {
    if (this.formulario.get('autoNoShowRegister').value === 'true' || this.formulario.get('autoNoShowRegister').value === true) {
      this.setRequiredForFields(['afterQtdTime', 'allowUpdateRegister']);
      this.enableFields(['afterQtdTime', 'allowUpdateRegister']);
      return;
    }
    this.removeRequiredForFields(['afterQtdTime', 'allowUpdateRegister']);
    this.disableFields(['afterQtdTime', 'allowUpdateRegister']);

    // if (!this.registerNoShow) {
    //   this.formulario.get('afterQtdTime').disable();
    //   this.formulario.get('afterQtdTime').setValue('');
    //   this.formulario.get('allowUpdateRegister').disable();
    //   this.formulario.get('allowUpdateRegister').setValue(false);
    //   this.formulario.get('onlyInZeroDay').disable();
    //   this.formulario.get('onlyInZeroDay').setValue(false);
    //   this.formulario.get('requirePasswdSupervisor').disable();
    //   this.formulario.get('requirePasswdSupervisor').setValue(false);
    // } else {
    //   this.formulario.get('afterQtdTime').enable();
    //   this.formulario.get('allowUpdateRegister').enable();
    //   this.formulario.get('onlyInZeroDay').enable();
    //   this.formulario.get('requirePasswdSupervisor').enable();
    // }
  }

  afterClickButtonAdd() {
    this.formulario.get('afterQtdTime').disable();
    this.formulario.get('allowUpdateRegister').disable();
    this.formulario.get('onlyInZeroDay').disable();
    this.formulario.get('requirePasswdSupervisor').disable();
    this.statusFields();
  }

  endPointCollectNF = () => SDM_URL.collect.nfDeadlines.default;

  getNewInstance(): CollectNF {
    return new CollectNF();
  }
  getService(): AbstractService<CollectNF> {
    return this._collectNFService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'collect.nf-deadlines';
  }


  validAfterQtdTime(event, inputController) {
    let inputValue = event.target.value;
    if (inputValue < 0 || (!(inputValue % 1 === 0)) || inputValue.length > 3) {
      this.formulario.get(inputController).setErrors({ pattern: true });
    }
    else {
      this.formulario.get(inputController).clearValidators();
      this.formulario.get(inputController).updateValueAndValidity();
    }
  }


}
