import { Legend } from './../../../components/legend/legend';
import { Location } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { SlotPattern } from '../../../core/models/sdm/slot/slot-pattern';
import { ProviderService } from '../../../core/services/sdm/provider-management/provider.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { DateFormatPipe } from './../../../core/classes/datePipe';
import { AuthService } from './../../../core/services/auth.service';
import { DepositorService } from './../../../core/services/depositor.service';
import { AbstractSchedules } from './abstract-schedules';
export abstract class AbstractSchedulesSlots<T> extends AbstractSchedules<T> {
  formulario: FormGroup;
  slot: SlotPattern;

  selected: any[] = [];
  rowsDetails = [];
  conflicts = 0;

  configLegend: Legend[] = [
    {
      color: '#f54542',
      text: 'Conflito',
      tooltip: 'será gerado conflito'
    },
    {
      color: `#f57242`,
      text: 'Feriado',
      tooltip: 'Horários e dias com feriado'
    },
    {
      color: `#f5bf42`,
      text: 'Dia não operacional',
      tooltip: 'Data não operacional'
    },
    {
      color: `#73b846`,
      text: 'Sem conflito',
      tooltip: 'Não irá gerar conflito'
    }
  ];

  columnsDetails: object[] = [
    { prop: 'authorizationKey', name: 'Agendamento', width: 180 },
    { prop: 'provider.shortName', name: 'Transportadora' },
    { prop: 'ini', name: 'Hora inicial' },
    { prop: 'fin', name: 'Hora final' },
    { prop: 'date', name: 'Data', pipe: new DateFormatPipe('en-US') }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public sanitizer: DomSanitizer,
    public depositorService: DepositorService,
    public providerService: ProviderService,
    public authService: AuthService,
    protected iniHour: string = 'iniHour',
    protected finHour: string = 'finHour'
  ) {
    super(
      location,
      toaster,
      translator,
      sanitizer,
      depositorService,
      providerService,
      authService
    );
    this.iniHour = iniHour;
    this.finHour = finHour;
  }

  changeCheckBoxConflict(checked) {
    
    this.selected = checked
      ? this.rowsDetails.filter(row => row['conflict'])
      : [];
    this.conflicts = this.selected.length;
  }

  changeIniHour(e) {
    this.changeHours(e, this.iniHour);
  }

  changeFinHour(e) {
    this.changeHours(e, this.finHour);
  }

  changeHours(e, name) {
    const value = e.target.value;

    if (!this.validateInput(value)) {
      return;
    }

    if (this.slot && this.slot.sizeInMinutes > 0) {
      const hourSplit = value.split(':');

      if (hourSplit[0] > 23) {
        this.formulario.get(name).setValue('');
        return;
      }

      if (hourSplit[1] >= this.slot.sizeInMinutes) {
        hourSplit[1] =
          this.slot.sizeInMinutes > 30 ? '00' : this.slot.sizeInMinutes;
      } else {
        hourSplit[1] = '00';
      }

      // if (
      //   hourSplit[1] < this.slot.sizeInMinutes ||
      //   hourSplit[1] % this.slot.sizeInMinutes > 0
      // ) {
      //   hourSplit[1] = '00';
      // }

      // if (this.slot.sizeInMinutes > 30) {
      //   hourSplit[1] = '00';
      // } else {
      //   if (this.slot.sizeInMinutes) {

      //   }
      // }
      // else {

      //   if (this.slot.sizeInMinutes) {
      //     hourSplit[1] = this.slot.sizeInMinutes;
      //   }

      //   hourSplit[1] = this.slot.sizeInMinutes;
      // }

      const hourJoin = hourSplit.join(':');

      this.formulario.get(name).setValue(hourJoin);

      // const endTime = moment(hourJoin, 'HH:mm')
      //   .add(this.slot.sizeInMinutes, 'minutes')
      //   .format('HH:mm');

      // this.formulario.get('finHour').setValue(endTime);
    }
  }

  validateInput(value): boolean {
    return value && /^([0-9]{2}:[0-9]{2})/.test(value);
  }

  recurrentFieldsAreFilled(): boolean {
    if (this.formulario.get('everyDayOfMonth').value) {
      return true;
    }

    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];

    for (let weekDay of weekDays) {
      if (this.formulario.get(weekDay).value) {
        return true;
      }
    }

    return false;
  }
}
