import { AbstractService } from "../../abstract.service";
import { ReverseDelivery } from "../../../models/sdm/reverse-delivery/reverse-delivery";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../../environments/environment";
import { SDM_URL } from "../../../../app.api";
import { Observable } from "rxjs";

@Injectable()
export class ReverseDeliveryService extends AbstractService<ReverseDelivery> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.reverseDelivery.default);
  }

  createReverseDelivery(model: Array<ReverseDelivery>): Observable<ReverseDelivery> {
    return this.http.post<ReverseDelivery>(SDM_URL.reverseDelivery.create, model)
  }

}