import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../app.api';
import { environment as env } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegularDeliveryNF } from './../../../models/sdm/regular-delivery/regular-delivery-nf';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class RegularDeliveryNfService extends AbstractService<
  RegularDeliveryNF
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.regularDelivery.nfDeadlines.default);
  }

  createRegularDeliveryNFDeadline(
    model: RegularDeliveryNF[]
  ): Observable<RegularDeliveryNF> {
    return this.http.post<RegularDeliveryNF>(
      SDM_URL.regularDelivery.nfDeadlines.create,
      model
    );
  }

  findByEstablishmentIdAndDepositorIdAndStatusTrue(
    establishmentId: number,
    depositorId: number
  ): Observable<RegularDeliveryNF> {
    return this.http.get<RegularDeliveryNF>(
      `${
        this.host
      }/wrapper/regular-delivery/nf/establishment/${establishmentId}/depositor/${depositorId}`
    );
  }
}
