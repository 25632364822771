import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationExtras,
  Params,
  Router
} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription, forkJoin } from 'rxjs';
import { DateFormatPipe } from '../../../../core/classes/datePipe';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { PriorVisualization } from '../../../../core/models/sdm/docks/prior-visualization';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { AuthService } from '../../../../core/services/auth.service';
import { DocksSchedulingService } from '../../../../core/services/sdm/document/docks-scheduling.service';
import { ModalCustomComponent } from './../../../../components/modal-custom/modal-custom.component';
import { DocksScheduling } from './../../../../core/models/sdm/document/docks-scheduling';
import { DeliveryAuthorizationService } from '../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';

@Component({
  selector: 'app-reschedule-various-option',
  templateUrl: './reschedule-various-option.component.html',
  styleUrls: ['./reschedule-various-option.component.scss'],
  preserveWhitespaces: true
})
export class RescheduleVariousOptionComponent implements OnInit {
  bsModalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  depositors: Depositor[] = [];
  docks: PriorVisualization[] = [];
  providers: Provider[] = [];

  formRescheduleVariousOption: FormGroup;
  formValues = [];
  authorizations = [];

  availableSchedulingsRows = [];

  optionsOperationStatus = [
    {
      name: 'reschedule-various-option.operationStatus.options.available',
      value: 1
    },
    {
      name: 'reschedule-various-option.operationStatus.options.blocked',
      value: 2
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeOne',
      value: 3
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeTwo',
      value: 4
    }
  ];

  selected = [];
  docksSchedulings: DocksScheduling[] = [];
  dockSchedule: DocksScheduling;

  deliveryAuthorization: any;

  alerts: any = [];
  dismissible = true;

  columns: object[] = [
    {
      prop: 'dateScheduleDelivery',
      name: 'Data',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'carShowSchedule', name: 'Hora Apresentação' },
    { prop: 'initialHour', name: 'Início' },
    { prop: 'finalHour', name: 'Término' },
    { prop: 'priorVisualization.signatureDock', name: 'Doca' }
  ];

  constructor(
    public modalService: BsModalService,
    private _actvRouter: ActivatedRoute,
    private _router: Router,
    private _docksSchedulingService: DocksSchedulingService,
    sanitizer: DomSanitizer,
    private _authService: AuthService,
    private _deliveryAuthorizationService: DeliveryAuthorizationService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.formRescheduleVariousOption = new FormGroup({
      depositor: new FormControl(''),
      providerId: new FormControl(''),
      authorizationkey: new FormControl(''),
      dateScheduleDelivery: new FormControl(''),
      carShowSchedule: new FormControl(''),
      initialHour: new FormControl(''),
      finalHour: new FormControl(''),
      priorVisualization: new FormControl(''),
      reserveTypeInternalUserUpdate: new FormControl('')
    });

    this._actvRouter.queryParams.subscribe((params: Params) => {
      try {
        const docksScheduling = JSON.parse(params['docksScheduling']);
        this.availableSchedulingsRows = docksScheduling;

        this.deliveryAuthorization = JSON.parse(
          params['deliveryAuthorization']
        );

        

        this.dockSchedule = JSON.parse(params['dockSchedule']);

        
      } catch (e) {}

      this.getFormValuesFromQueryParams(params);
      this.getProvidersFromQueryParams(params);
      this.getAuthorizationFromQueryParams(params);
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message?: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
    });
  }

  getFormValuesFromQueryParams(params: Params) {
    try {
      this.formValues = JSON.parse(params['formValues']);

      

      this.formRescheduleVariousOption.patchValue(this.formValues);
      this.depositors = [this.formValues['depositor']];
      this.docks = [this.formValues['priorVisualization']];
    } catch (e) {}
  }

  getProvidersFromQueryParams(params: Params) {
    try {
      this.providers = JSON.parse(params['provider']);
      this.formRescheduleVariousOption
        .get('providerId')
        .setValue(this.providers[0]);
    } catch (e) {}
  }

  getAuthorizationFromQueryParams(params: Params) {
    try {
      const authorization = JSON.parse(params['formValues']);
      this.authorizations = [authorization['authorization']];

      this.formRescheduleVariousOption
        .get('authorizationkey')
        .setValue(this.authorizations[0]);
    } catch (e) {}
  }

  exit() {
    this._router.navigate(
      ['sdm/delivery-authorization/edit/reschedule-term-validation'],
      this.queryParams()
    );
  }

  confirmContinue() {
    if (!this.selected) {
      this.alertMsg('Selecione um Slot para continuar', 'info');
      return;
    }

    this.openModalConfirmNoShow();
  }

  openModalConfirmNoShow() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja alterar a data e/ou horário da Autorização existente?',
      classBtnSuccess: 'btn-outline-danger',
      classBtnDanger: 'btn-success'
      // form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (/OK/.test(resp)) {
            this.rescheduleDelivery();
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  onSelect({ selected }) {
    this.selected = selected;
    
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  prepareQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositors),
        provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  getOpNatureDelivery() {
    return this.deliveryAuthorization['opNatureDelivery'];
  }

  rescheduleDelivery() {
    let schedule = <DocksScheduling>this.selected[0];
    schedule['opNatureDelivery'] = this.getOpNatureDelivery();
    forkJoin(
      this._docksSchedulingService.rescheduleDelivery(
        this.dockSchedule.id,
        schedule,
        this.formRescheduleVariousOption.get('reserveTypeInternalUserUpdate')
          .value
      ),
      this._deliveryAuthorizationService.updateStatusAuthorization(
        this.deliveryAuthorization.id,
        +DeliveryAuthorizationEnum.REAGENDADO
      )
    ).subscribe(
      data => {
        if (data) {
          this._router.navigate(
            [
              'sdm/delivery-authorization/regular-delivery/generated-authorization'
            ],
            this.prepareQueryParams()
          );
        }
      },
      ({ error }) => {
        
        this.alertMsg(error, 'danger');
      }
    );
  }

  isInternalUser() {
    const userType = this._authService.getUserAuthority();
    return /Internal/.test(userType.type);
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
