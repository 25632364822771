import { Observable } from 'rxjs';
import { environment } from './../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Document } from './../../../../models/sdm/delivery-authorization/regular-delivery/document';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';
import { SDM_URL } from '../../../../../app.api';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManualTypeDocService extends AbstractService<Document> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.deliveryAuthorization.regularDelivery.manualTypeDoc.default
    );
  }

  findByProviderId(providerId: number) {
    return this.http
      .get(
        `${SDM_URL.deliveryAuthorization.regularDelivery.manualTypeDoc.findByProviderId.replace(
          ':providerId',
          providerId.toString()
        )}`
      )
      .pipe(take(1));
  }

  findByDepositorIdAndDeliveryAuthorization(
    depositorId: number,
    deliveryAuthorizationId: number
  ): Observable<Document[]> {
    return this.http
      .get<Document[]>(
        `${SDM_URL.deliveryAuthorization.regularDelivery.manualTypeDoc.findByDepositorIdAndDeliveryAuthorization
          .replace(':depositorId', depositorId.toString())
          .replace(
            ':deliveryAuthorizationId',
            deliveryAuthorizationId.toString()
          )}`
      )
      .pipe(take(1));
  }
}
