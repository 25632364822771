import { NgSelectComponent } from '@ng-select/ng-select';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from '@angular/forms';

const SELECT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectFieldComponent),
  multi: true
};

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  providers: [SELECT_FIELD_VALUE_ACCESSOR]
})
export class SelectFieldComponent extends SelectControlValueAccessor
  implements OnChanges {
  private _items: any[] = [];

  @ViewChild('authorizationkey') authorizationkey: NgSelectComponent;

  @Input() name = '';
  @Input() label = '';
  @Input() i18n = true;
  @Input() placeholder = '';
  @Input()
  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  @Input() form: FormGroup;
  @Input() isMultiple = false;
  @Input() control: FormControl;
  @Output() selectedVal = new EventEmitter();
  @Output() reselectVal = new EventEmitter();
  @Output() removedVal = new EventEmitter();
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() isRequired: boolean;
  @Input() validError: boolean = true;
  @Input() clearable: boolean = true;
  @Input() isForm: boolean = true;
  @Input() reselect: boolean = false;
  @Input() dropdownPosition: String = 'auto';
  @Output() clearValueSelected = new EventEmitter();

  @Input() disabled = false;
  private _innerValue: any = null;

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef);
  }

  setValue(value: any) {
    if (value != this._innerValue) {
      if (value && value instanceof Number) value = this.setValueById(value);
      let oldValue = this._innerValue;
      this._innerValue = value;
      if (oldValue && this.reselect) this.selectedVal.emit(value);
      this.value = value;
      this.onChange(value);
    }
  }

  getValue() {
    return this._innerValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.items &&
      changes.items.previousValue &&
      changes.items.previousValue.length > 0 &&
      this.items &&
      this.items.length > 0
    ) {
      let index = this.existsIn(this.getValue(), changes.items.currentValue);

      if (index > -1) {
        this.setValue(this.items[index]);
      }
    }
  }

  focus() {
    this.authorizationkey.focus();
  }

  existsIn(obj: any, list: any[]) {
    return list && list.length > 0 ? list.indexOf(obj) : -1;
  }

  onTouched: () => void;
  compareWith: (o1: any, o2: any) => boolean;

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  writeValue(v: any): void {
    this.setValue(v);
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setValueById(id) {
    this.setValue(this.items.find(x => Number(x.id) == Number(id)));
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.items = [];
  }

  changeNgSelected(selected) {
    this.selectedVal.emit(selected);
  }

  reselected() {
    if (this._innerValue && this.reselect){
      this.selectedVal.emit(this._innerValue);
    }
  }

  clearSelection() {
    this.setValue(null);
    this.clearValueSelected.emit(this.getValue());
  }

  removeSelected(removed) {
    this.removedVal.emit(removed);
  }

  isInvalid(): boolean {
    return !this.control.valid && (this.control.dirty || this.control.touched);
  }
}
