import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { MeasureUnit } from '../../models/sdm/measure-unit/measure-unit';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { SDM_URL } from '../../../app.api';
import { take } from 'rxjs/operators';

@Injectable()
export class MeasureUnitService extends AbstractService<MeasureUnit> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/measure-unit');
  }

  findByDepositorIdAndEstablishmentIdAndStatusTrue(
    establishmentId: number,
    depositorId: number
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.host}${
          this.url
        }/depositor/${depositorId}/establishment/${establishmentId}/list`
      )
      .pipe(take(1));
  }
}
