import { DeliveryAuthorization } from './delivery-authorization';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { OpNatureAuthorization } from '../shared/enums/op-nature-authorization.enum';

export class Authorization extends AbstractStatusModel {
  constructor(
    public statusAuthorization: number,
    public deliveryAuthorization: DeliveryAuthorization,
    public opNatureDelivery: OpNatureAuthorization
  ) {
    super();
  }
}
