import { RegularDeliveryService } from './../../../../core/services/sdm/regular-delivery/regular-delivery.service';
import { ImportNfeComponent } from './../../../../components/import-nfe/import-nfe.component';
import { RegularDeliveryRequestMax } from './../../../../core/models/sdm/regular-delivery/regular-delivery-request-max';
import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AbstractService } from '../../../../core/services/abstract.service';
import { ProviderService } from '../../../../core/services/sdm/provider-management/provider.service';
import { OrderService } from '../../../../core/services/sdm/regular-delivery/order.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractDelivery } from '../../abstract-delivery';
import { SDM_URL } from './../../../../app.api';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { MassiveUploadFilesComponent } from './../../../../components/massive-upload-files/massive-upload-files.component';
import { RegularDeliveryOrder } from './../../../../core/models/sdm/regular-delivery/order';
import { AuthService } from './../../../../core/services/auth.service';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { MassiveUploadService } from './../../../../core/services/sdm/delivery-authorization/regular-delivery/massive-upload.service';
import { RegularDeliveryRequestMaxService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-request-max.service';

const EXCEL_FILE_FILTER = {
  name: 'excelOnly',
  fn: function(item) {
    const excelOnly =
      '|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/wps-office.xlsx|';
    return excelOnly.indexOf(item.type) !== -1;
  }
};

@Component({
  selector: 'app-massive-upload',
  templateUrl: './massive-upload.component.html',
  styleUrls: ['./massive-upload.component.scss']
})
export class MassiveUploadComponent extends AbstractDelivery<Document>
  implements OnInit {
  order: RegularDeliveryOrder = new RegularDeliveryOrder();
  regularDeliveryReqMax = new RegularDeliveryRequestMax();
  idAuthorization: any;
  sub: Subscription;
  subsModalLog: Subscription[] = [];
  modalRefMassiveUpload: BsModalRef;

  @ViewChild('importDocs') importDocs: ImportNfeComponent;
  @ViewChild('datatableResultUploadNF') grid$: DatatableApiComponent;
  @ViewChild('dataTableLogErrors') gridLog$: DatatableApiComponent;

  @ViewChild('hdrTplStatus') hdrTplStatus: TemplateRef<any>;
  @ViewChild('bodyTplStatus') bodyTplStatus: TemplateRef<any>;

  @ViewChild('hdrTplNF') hdrTplNF: TemplateRef<any>;
  @ViewChild('bodyTplNF') bodyTplNF: TemplateRef<any>;

  @ViewChild('hdrTplSerie') hdrTplSerie: TemplateRef<any>;
  @ViewChild('bodyTplSerie') bodyTplSerie: TemplateRef<any>;

  @ViewChild('hdrTplData') hdrTplData: TemplateRef<any>;
  @ViewChild('bodyTplData') bodyTplData: TemplateRef<any>;

  @ViewChild('hdrTplPedido') hdrTplPedido: TemplateRef<any>;
  @ViewChild('bodyTplPedido') bodyTplPedido: TemplateRef<any>;

  columnsResult: object[] = [];

  columnsLogErro = [
    { prop: 'field', name: 'Campo', width: 100 },
    { prop: 'message', name: 'Erro' },
    { prop: 'documentKey', name: 'Chave Nota' }
  ];
  infNFeId: any;
  rowsLog = [];
  filters = EXCEL_FILE_FILTER;
  partialContinue = true;

  constructor(
    public modalService: BsModalService,
    private _modalService: BsModalService,
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public sanitizer: DomSanitizer,
    public deliveryAuthorizationService: DeliveryAuthorizationService,
    public _authService: AuthService,
    private _orderService: OrderService,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    public route: ActivatedRoute,
    private _massiveUploadService: MassiveUploadService,
    public router: Router,
    private _regularDeliveryReqMaxService: RegularDeliveryRequestMaxService,
    public _regularDeliveryService: RegularDeliveryService
  ) {
    super(
      modalService,
      location,
      toaster,
      translator,
      sanitizer,
      deliveryAuthorizationService,
      _depositorService,
      _providerService,
      _authService,
      router,
      _regularDeliveryService
    );
  }

  ngOnInit() {
    this.columnsResult.push({
      name: '# NF',
      cellTemplate: this.bodyTplNF,
      headerTempalte: this.hdrTplNF
    });
    this.columnsResult.push({
      name: 'Série',
      cellTemplate: this.bodyTplSerie,
      headerTempalte: this.hdrTplSerie
    });
    this.columnsResult.push({
      name: 'Data',
      cellTemplate: this.bodyTplData,
      headerTempalte: this.hdrTplData
    });
    this.columnsResult.push({
      name: '# Pedido',
      cellTemplate: this.bodyTplPedido,
      headerTempalte: this.hdrTplPedido
    });
    this.columnsResult.push({
      name: 'Status',
      cellTemplate: this.bodyTplStatus,
      headerTempalte: this.hdrTplStatus
    });

    this.formulario = new FormGroup({
      id: new FormControl(null),
      depositor: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      providerId: new FormControl('', [Validators.required]),
      nfSolicit: new FormControl(''),
      deliveryAuthorizationId: new FormControl(this.idAuthorization)
    });

    this.sub = this.route.queryParams.subscribe((params: Params) => {
      this.idAuthorization = +params['idAuthorization'] || null;
      this.formulario
        .get('deliveryAuthorizationId')
        .setValue(this.idAuthorization);
      

      this.setDepositorsFromQueryParams(params);
    });
  }

  setDepositorsFromQueryParams(params: Params) {
    try {
      const depositor = JSON.parse(params['selectedDepositor']);
      this.depositors = JSON.parse(params['depositors']);
      this.formulario.get('depositor').setValue(depositor);
      this.depositorChanged(this.formulario.get('depositor').value);
    } catch (e) {
      this.depositors = [];
    }
  }

  depositorChanged(data: any) {
    if (data) {
      const { id } = data;
      const userId = this._authService.getUser().id;
      this._orderService
        .findByDepositorIdAndUser(id, userId)
        .pipe(delay(500))
        .subscribe(res => {
          this.order = res ? res : new RegularDeliveryOrder();
        });
      this.loadParamsRegularDeliveryRequestMax(id, userId);
      this.loadProvidersByDepositorId(id);
      this.formulario.get('depositorId').setValue(id);
    }
  }

  loadProvidersByDepositorId(depositorId: number) {
    this._providerService
      .findByDepositorId(depositorId)
      .subscribe((data: any) => {
        this.providers = data;
      });
  }

  loadParamsRegularDeliveryRequestMax(depositorId: number, userId: number) {
    this._regularDeliveryReqMaxService
      .findByDepositorId(depositorId, userId)
      .subscribe(data => {
        this.regularDeliveryReqMax = data
          ? data
          : new RegularDeliveryRequestMax();
      });
  }

  cancel() {
    this._location.back();
  }

  submitOrUploadFile() {
    if (this.importDocs.uploader.queue.length > 0 && !this.formulario.valid) {
      this.verificaValidacoesForm(this.formulario);
      // this.importDocs.uploadAllFiles();
      return;
    }

    if (!this.importDocs.uploader.queue.length) {
      this.alertMsg('Selecione o(s) arquivo(s) para continuar', 'info');
      return;
    }

    if (this.importDocs.uploader.queue.length && this.formulario.valid) {
      this.importDocs.uploader.uploadAll();
      return;
    }

    // this.importDocs.uploadAllFiles();
  }

  savedSuccess(e) {
    this.grid$.reloadPage();

    const idAuthorization = this.formulario.get('deliveryAuthorizationId')
      .value;
    this._deliveryAuthorizationService
      .updateStatusAuthorization(
        idAuthorization,
        +DeliveryAuthorizationEnum.PENDENTE
      )
      .subscribe(data => {
        
        this.alertMsg(
          `# Solicitação de Autorização de entrega ${data['authorizationkey']}`
        );
        this.openModalAuthorization();
      });
  }

  uploadErrorEvent(res: any) {
    this.grid$.reloadPage();

    
    if (/409/.test(res.status)) {
      this.partialContinue = true;

      if (typeof res['data'] === 'string') {
        this.alertMsg(res['data'], 'danger');
      }
      // const title = 'Continuar Parcial';
      // const message = 'Seguir Parcial, apenas com NF(s) validada(s)?';
      // this.openModalPartialContinue(title, message);
    } else if (/404/.test(res.status)) {
      this.alertMsg(res['data'], 'danger');
    }
  }

  logView() {}

  logExport() {}

  printLog() {}

  continueWithPartialWithDocsOK() {
    const idAuthorization = this.formulario.get('deliveryAuthorizationId')
      .value;

    this.subsModalLog.push(
      this._massiveUploadService
        .partialContinueWithValidDocuments(idAuthorization)
        .subscribe(data => {
          this.alertMsg(
            `Solicitação de Autorização de entrega ${
              data.body['authorizationKey']
            }`
          );
          this.unsubscribe();
          this.openModalAuthorization();
          // const titleMessage = 'Mensagem Confirmação';
          // const messageConfirm = 'Processo de Autorização de Entrega ainda NÃO concluído. Deseja continuar?';
          // this.openModalAuthorizationDeliveryQuestion(titleMessage, messageConfirm);
        })
    );
  }

  openModalWithError(template: TemplateRef<any>, row) {
    
    this.rowsLog = row.documentInconsistency;
    this.modalRefMassiveUpload = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  unsubscribe() {
    this.subsModalLog.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subsModalLog = [];
  }

  endPointDocuments = () =>
    SDM_URL.deliveryAuthorization.regularDelivery.manualTypeDoc.findByDeliveryAuthorizationId.replace(
      ':deliveryAuthorizationId',
      this.idAuthorization
    );

  endPointUploadDocs = () =>
    SDM_URL.deliveryAuthorization.regularDelivery.massiveUpload.uploadDocs;

  openModalPartialContinue(title: string, message: string) {
    const initialState = {
      title,
      message,
      form: this.formulario
    };
    this.modalRefMassiveUpload = this._modalService.show(
      ModalDeliveryAuthMassiveUploadComponent,
      { initialState }
    );
    this.modalRefMassiveUpload.content.closeBtnName = 'Sair';
    this.modalRefMassiveUpload.content.confirmBtn = 'Confirmar';

    this.subsModalLog.push(
      this._modalService.onHidden.subscribe(resp => {
        if (resp === 'OK') {
          const idAuthorization = this.formulario.get('deliveryAuthorizationId')
            .value;

          this._massiveUploadService
            .partialContinueWithValidDocuments(idAuthorization)
            .subscribe(data => {
              
              this.alertMsg(
                `Solicitação de Autorização de entrega ${
                  data.body['authorizationKey']
                }`
              );

              const titleMessage = 'Mensagem Confirmação';
              const messageConfirm =
                'Processo de Autorização de Entrega ainda NÃO concluído. Deseja continuar?';
              this.unsubscribe();
              this.openModalAuthorizationDeliveryQuestion(
                titleMessage,
                messageConfirm
              );
              // this.openModalAuthorization();
            });
        } else {
          const idAuthorization = this.formulario.get('deliveryAuthorizationId')
            .value;

          this._massiveUploadService
            .deleteAllByDeliveryAuthorization(idAuthorization)
            .subscribe(() => {
              this.location.back();
            });
        }
      })
    );
  }

  openModalAuthorizationDeliveryQuestion(title: string, message: string) {
    const initialState = {
      title,
      message,
      form: this.formulario
    };
    this.bsModalRef = this._modalService.show(
      ModalDeliveryAuthMassiveUploadComponent,
      { initialState }
    );
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.modalService.onHide.subscribe(resp => {
      if (resp === 'CANCEL') {
        const idAuthorization = this.formulario.get('deliveryAuthorizationId')
          .value;
        this._deliveryAuthorizationService
          .updateStatusAuthorization(
            idAuthorization,
            +DeliveryAuthorizationEnum.INTERROMPIDA
          )
          .subscribe(data => {
            this.alertMsg(
              `Solicitação de Autorização de entrega ${
                data['authorizationkey']
              }, interrompida`,
              'info'
            );
            this.clearForm();
          });
      } else if (resp === 'OK') {
        const idAuthorization = this.formulario.get('deliveryAuthorizationId')
          .value;
        this._deliveryAuthorizationService
          .updateStatusAuthorization(
            idAuthorization,
            +DeliveryAuthorizationEnum.DOC_OK
          )
          .subscribe(data => {
            
            this.router.navigate(
              ['sdm/delivery-authorization/regular-delivery/dimensioning-info'],
              {
                queryParams: { authorizationKey: data['authorizationkey'] },
                queryParamsHandling: 'merge'
              }
            );
          });
      }
    });
  }

  getNewInstance() {
    return null;
  }
  getService(): AbstractService<any> {
    return this._massiveUploadService;
  }
  getComponentName(): string {
    return 'delivery-authorization.massive-upload';
  }
}

@Component({
  selector: 'app-modal-delivery-auth-massive-upload',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="exitConfirmOnClose()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span style="padding-left: 5px">{{ message }}</span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-danger"
        (click)="exitConfirmOnClose()"
      >
        {{ closeBtnName }}</button
      >&nbsp;&nbsp;
      <button
        type="button"
        class="btn btn-success"
        (click)="exitConfirmSuccessMassiveUpload()"
      >
        {{ confirmBtn }}
      </button>
    </div>
  `
})
export class ModalDeliveryAuthMassiveUploadComponent implements OnInit {
  title: string;
  closeBtnName: string;
  confirmBtn: string;
  message = '';
  form: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {}

  exitConfirmSuccessMassiveUpload() {
    // localStorage.removeItem('docId');
    this.bsModalRef.hide();
    this.modalService.setDismissReason('OK');
  }

  exitConfirmOnClose() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('CANCEL');
  }
}
