import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmailSmsReverseDelivery } from './../../../models/sdm/email-sms/email-sms-reverse';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class EmailsSmsReverseParamsService extends AbstractService<
  EmailSmsReverseDelivery
> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.emailsSms.reverseDelivery.default
    );
  }
}
