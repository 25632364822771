import { AbstractStatusModel } from '../abstractStatus.model';
import { OperationNature } from './operationNature';

export class OperationNatureDepositor extends AbstractStatusModel {

  constructor(
    public establishmentId: number,
    public depositorId: number,
    public operationNature: OperationNature,
    public status: boolean = true) {
    super();
    this.establishmentId = establishmentId;
    this.depositorId = depositorId;
    this.operationNature = operationNature;
    this.status = status;
  }

}
