import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CollectNF } from './../../../models/sdm/collect/collect-nf';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class CollectNfService extends AbstractService<CollectNF> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.collect.nfDeadlines.default);
  }

  findByEstablishmentIdAndDepositorIdAndStatusTrue(
    establishmentId: number,
    depositorId: number
  ): Observable<CollectNF> {
    return this.http.get<CollectNF>(
      `${
        this.host
      }/wrapper/collect/nf/establishment/${establishmentId}/depositor/${depositorId}`
    );
  }
}
