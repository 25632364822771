import { ConferenceTypeEnum } from './../shared/enums/ConferenceTypeEnum';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { ByTimeUnitEnum } from '../shared/enums/ByTimeUnitEnum';

export class ProductivityGroup extends AbstractStatusModel {
  public establishmentId: number;
  public depositorId: number;
  public description: string;
  public groupName: string;
  public operationNature: string;
  public conferenceType: string;
  public unitizationType: string;
  public riskProfile: string;
  public weightProfile: string;

  public fixWindowDischargeOp: boolean;
  public timeInMinutesDischargeOp: number;
  public setupDischargeOp: boolean;
  public timeInMinutesSetupDischargeOp: number;

  public fixWindowConferenceOp: boolean;
  public timeInMinutesConferenceOp: number;
  public setupConferenceOp: boolean;
  public timeInMinutesSetupConferenceOp: number;

  public firstPQuantity: number;
  public firstPMeasureUnit: number;
  public firstpByTimeUnit: string;

  public secondPQuantity: number;
  public secondPMeasureUnit: number;
  public secondPByTimeUnit: string;

  public thirdPQuantity: number;
  public thirdPMeasureUnit: number;
  public thirdPByTimeUnit: string;

  public fourthPQuantity: number;
  public fourthPMeasureUnit: number;
  public fourthPByTimeUnit: string;

  public fifthPQuantity: number;
  public fifthPMeasureUnit: number;
  public fifthPByTimeUnit: string;

  public sixPQuantity: number;
  public sixPMeasureUnit: number;
  public sixPByTimeUnit: string;

  constructor() {
    super();
    this.id = null;
    this.establishmentId = null;
    this.depositorId = null;
    this.firstpByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.secondPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.thirdPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.fourthPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.fifthPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.sixPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.secondPMeasureUnit = null;
    this.firstPMeasureUnit = null;
    this.thirdPMeasureUnit = null;
    this.fourthPMeasureUnit = null;
    this.fifthPMeasureUnit = null;
    this.sixPMeasureUnit = null;
    this.fixWindowDischargeOp = false;
    this.fixWindowConferenceOp = false;
    this.setupDischargeOp = true;
    this.setupDischargeOp = false;
    this.setupConferenceOp = false;
    this.conferenceType = 'NAO_APLICAVEL';
  }
}
