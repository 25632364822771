import { AbstractModel } from '../../abstract.model';
import { IDeserializable } from '../../deserializable.model';

export class ReverseDeliveryRequestMax extends AbstractModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public requestMaxNf: number;
  public maxQtdLnfIssued: number;
  public minValueByNf: number;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
