import { ProviderShippingEnum } from './enums/provider-shipping-enum.enum';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { CompatibilityProvider } from './compatibility-provider';

export class Provider extends AbstractStatusModel {
  public shortName: string;
  public document: string;
  public blackList: boolean;
  public prioritary: boolean;
  public validityTo: string;
  public validityUntil: string;
  public establishmentId: number;
  public depositorId: number;
  public personType: string;

  public compatibilityProvider: CompatibilityProvider[];

  constructor() {
    super();
    this.personType = Object.keys(ProviderShippingEnum).map(
      v => ProviderShippingEnum[v]
    )[0];
  }
}
