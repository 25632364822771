import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SDM_URL } from '../../app.api';
import { AbstractService } from './abstract.service';
import { Report } from '../models/report/report';
import 'rxjs/add/operator/map'; // import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GestaoVistaSdmService {

  private gestaoVistaAPI = '/wrapper/gestao';
  private exemploAPI = 'assets/server';

  constructor(private http: HttpClient) { }

  getGroupReport(url: string): Observable<any> {
    return this.http.get<any[]>(url);
  }

  findByDepositorAndUserIdAndStatusTrue(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${SDM_URL.vehicle.depositor.findByDepositorAndUserIdAndStatusTrue.replace(':depositorId', depositorId.toString()).replace(':userId', userId.toString())}`, { observe: 'response' });
  }

  getOperationNatures(): Observable<any> {
    return this.http.get<any[]>(`${SDM_URL.report.operationNatures}`); // esta em app.api.ts
  }

  getTimeMeasures(): Observable<any> {
    return this.http.get<any[]>(`${SDM_URL.report.timeMeasures}`);
  }

  getEventoPrevistoRealizado(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-evento-previsto-realizado.json`);
  }

  getSituacao(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-situacao.json`);
  }

  getTipoUsuario(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-tipo-usuario.json`);
  }

  getAuditoriaDocumento(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-auditoria-documento.json`);
  }

  getTaxaUtilizacaoDoca(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-taxa-utilizacao-doca.json`);
  }

  getEvolucaoHistoricaQuantidadeDoca(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-evolucao-historica-quantidade-doca.json`);
  }

  getDemandaDoca(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-demanda-doca.json`);
  }

  getMaisPontual(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-mais-pontual.json`);
  }

  getMenosPontual(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-menos-pontual.json`);
  }

  getMuralFama(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/exemplo-mural-fama.json`);
  }

  getSituacao2(depositorId: number, userId: number): Observable<any> {
    return this.http.get<any[]>(`${this.exemploAPI}/ag-owinners.json`);
  }
}
