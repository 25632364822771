import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SDM_URL } from '../../../../../app.api';
import { InputFieldComponent } from '../../../../../components/input/input-field/input-field.component';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractRegister } from '../../../abstract-register';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { AgentTypeDepositor } from './../../../../../core/models/agent-type-depositor/agent-type-depositor';
import { PersonType } from './../../../../../core/models/sdm/provider-management/enums/person-type.enum';
import { Provider } from './../../../../../core/models/sdm/provider-management/provider';
import { AgentTypeDepositorService } from './../../../../../core/services/agent-type-depositor/agent-type-depositor.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { ProviderService } from './../../../../../core/services/sdm/provider-management/provider.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { delay } from 'rxjs/operators';

const FIELDS_TO_DISABLE = ['validityTo', 'validityUntil'];

@Component({
  selector: 'app-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.scss'],
  preserveWhitespaces: true
})
export class ProviderFormComponent extends AbstractRegister<Provider>
  implements OnInit {
  isPrioritary = false;
  @ViewChild('gridProviderShipping') grid: DatatableApiComponent;
  provider: Provider;

  @ViewChild('bodyType') bodyType: TemplateRef<any>;
  @ViewChild('hdrType') hdrType: TemplateRef<any>;

  @ViewChild('document') document: InputFieldComponent;

  columnsProvider: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
    // {
    //   prop: 'blackList',
    //   name: 'Fornecedor Black-List?',
    //   pipe: new BooleanConverter()
    // },
    // {
    //   prop: 'prioritary',
    //   name: 'Fornecedor Prioritário',
    //   pipe: new BooleanConverter()
    // },
    // {
    //   prop: 'validityTo',
    //   name: 'Vigência de',
    //   pipe: new DateFormatPipe('en-US')
    // },
    // {
    //   prop: 'validityUntil',
    //   name: 'Vigência até',
    //   pipe: new DateFormatPipe('en-US')
    // },
    {
      prop: 'personType',
      name: 'Tipo'
    }
  ];

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  optionsPersonType = [];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _providerService: ProviderService,
    private agentTypeService: AgentTypeDepositorService
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    // this.columnsProvider.push({
    //   sortable: true,
    //   name: 'Tipo',
    //   cellTemplate: this.bodyType,
    //   headerTemplate: this.hdrType
    // });

    this.provider = new Provider();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      shortName: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      // blackList: new FormControl(''),
      // prioritary: new FormControl(''),
      // validityTo: new FormControl(''),
      // validityUntil: new FormControl(''),
      establishmentId: new FormControl(''),
      shortDescription: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      personType: new FormControl(PersonType.JURIDICA, [Validators.required]),
      agentType: new FormControl('', [Validators.required]),
      economicGroup: new FormControl(''),
      description: new FormControl(''),
      agentCodeDepositor: new FormControl('', [Validators.required])
    });

    this.formulario.get('personType').valueChanges.subscribe(value => {
      if (value && value === 'FISICA') {
        this.document.maskInput = '000.000.000-00';
        return;
      }

      this.document.maskInput = '00.000.000/0000-00';
    });
  }

  changeSelIsPrioritary(event) {
    this.isPrioritary = event.target.value === 'true';
    this.isPrioriraryProvider();
  }

  isPrioriraryProvider() {
    if (this.isPrioritary) {
      this.enableFields(FIELDS_TO_DISABLE);
      this.setRequiredForFields(FIELDS_TO_DISABLE);
      return;
    }
    this.disableFields(FIELDS_TO_DISABLE);
    this.removeRequiredForFields(FIELDS_TO_DISABLE);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.disable();
    // this.isPrioriraryProvider();

    this.enableInputsOnUpdate();
  }

  enableInputsOnUpdate() {
    this.enableFields(['shortName', 'economicGroup', 'description']);
    // this.formulario.get('personType').disable();
      }

  afterAddButton() {
    this.formulario
      .get('personType')
      .setValue(Object.keys(PersonType).map(v => PersonType[v])[1]);
  }

  depositorChange(depositor) {
    if (depositor) {
      this.agentTypeService
        .findByDepositorAndSolution(depositor.id, 2)
        .subscribe((data: AgentTypeDepositor[]) => {
          const agentTypes = data.map(agent => agent.agentType);
          this.optionsPersonType = agentTypes;
        });
    } else {
      this.optionsPersonType = [];
      this.formulario.get('agentType').setValue([]);
    }
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.value)
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.getRawValue())
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }
    
    this.subscriptions.push(
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .subscribe(data => {
          if (this.formulario.controls.hasOwnProperty('depositorId')) {
            this.formulario.get('depositorId').setValue('');
          }

          if (data) {
            this.depositors = [...data.body['content']];
          }

          if (this.depositors.length === 1) {
            if (this.formulario.controls.hasOwnProperty('depositorId')) {
              this.formulario.get('depositorId').setValue(this.depositors[0]);
              this.depositorChange(this.depositors[0]);
            }
          }
        })
    );
  }

  endPointProviderShipping = () => SDM_URL.providerManagement.provider.default;

  endpointUpload = () => SDM_URL.providerManagement.provider.upload;

  endPointPartial = () => SDM_URL.providerManagement.provider.importPartial;

  getNewInstance(): Provider {
    return new Provider();
  }
  getService(): AbstractService<Provider> {
    return this._providerService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'provider-management.provider';
  }
}
