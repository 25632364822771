import { Component, OnInit, Directive, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';

// const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'upload-btn',
  templateUrl: './upload-btn.component.html',
  styleUrls: ['./upload-btn.component.scss']
})
export class UploadBtnComponent implements OnInit {

  @Input() apiurl: String = '';
  public uploader: FileUploader

  constructor() { }

  ngOnInit() {
    const endpoint = `${environment.host.sdm}${this.apiurl}/uploadfile`
    this.uploader = new FileUploader({
      url: endpoint,
      autoUpload: true,
      queueLimit: 1,
      itemAlias: 'file',
      isHTML5: true
    });

    this.uploader.onAfterAddingFile = (item => {
      item.withCredentials = false;
    });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      if (response == undefined || status != "202") {
        console.error('erro');
      }
    }
  }

}