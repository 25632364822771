import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SchedulesDockManagement } from '../../../models/sdm/dock-management/schedules';
import { AbstractService } from '../../abstract.service';
import { environment as env } from './../../../../../environments/environment';
import { SDM_URL } from './../../../../app.api';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService extends AbstractService<SchedulesDockManagement> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.dockManagement.slotsReserve.default);
  }

  updateStatus(
    model: SchedulesDockManagement[]
  ): Observable<HttpResponse<SchedulesDockManagement>> {
    return this.http.post<SchedulesDockManagement>(
      `${this.host}${this.url}/update/status`,
      model,
      { observe: 'response' }
    );
  }

  validateRecurrentSchedule(schedulesDockManagement: SchedulesDockManagement) {
    return this.http.post(
      `${
        env.host.sdm
      }/wrapper/dock-management/slots-reserve/validate/recurrent`,
      schedulesDockManagement
    );
  }

  createReverseRecurrent(schedulesDockManagement: SchedulesDockManagement) {
    return this.http.post(
      `${env.host.sdm}/wrapper/dock-management/slots-reserve/recurrent`,
      schedulesDockManagement
    );
  }

  updatePartial(managementSlots, id: number, docksSchedulingId: number) {
    
    
    return this.http.patch(
      `${
        env.host.sdm
      }/wrapper/dock-management/slots-reserve/partial/management-slots-reserve/${id}/docks-scheduling/${docksSchedulingId}`,
      managementSlots
    );
  }

  removeManagementSlotsReserveTypeOne(
    initialHour: string,
    finalHour: string,
    dateScheduleDelivery: string
  ): Observable<HttpResponse<any>> {
    return this.http.delete<HttpResponse<any>>(
      `${
        env.host.sdm
      }/wrapper/dock-management/slots-reserve/initialHour/${initialHour}/finalHour/${finalHour}/type-one?dateSchedule=${dateScheduleDelivery}`
    );
  }
}
