import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SelectFieldComponent } from '../../../../../../components/select/select-field/select-field.component';
import { PriorVisualization } from '../../../../../../core/models/sdm/docks/prior-visualization';
import { ReverseDeliveryManualAllocation } from '../../../../../../core/models/sdm/schedules/reverse-delivery/manual-allocation';
import { ManualAllocationReverse } from '../../../../../../core/models/sdm/schedules/reverse-delivery/manual-allocation-reverse';
import { SchedulesConflictDTO } from '../../../../../../core/models/sdm/schedules/schedules-conflict-dto';
import { SlotPattern } from '../../../../../../core/models/sdm/slot/slot-pattern';
import { AbstractService } from '../../../../../../core/services/abstract.service';
import { SlotPatternService } from '../../../../../../core/services/slot/slot-pattern.service';
import { SlotReserveService } from '../../../../../../core/services/slot/slot-reserve.service';
import { AbstractDockManagementEdit } from '../../../../../dock-management/abstract-dock-management-edit';
import { DeliveryAuthorization } from './../../../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Depositor } from './../../../../../../core/models/sdm/depositor';
import { OpNatureAuthorizationEnum } from './../../../../../../core/models/sdm/schedules/reverse-delivery/enums/op-nature-authorization-enum.enum';
import { SubNature } from './../../../../../../core/models/sdm/schedules/reverse-delivery/enums/sub-nature.enum';
import { SlotReserve } from './../../../../../../core/models/sdm/slot/slot-reserve';
import { PriorVisualizationService } from './../../../../../../core/services/sdm/docks/prior-visualization.service';
import { ManualAllocationService } from './../../../../../../core/services/sdm/schedules/reverse-delivery/manual-allocation.service';

@Component({
  selector: 'app-manual-allocation-edit',
  templateUrl: './manual-allocation-edit.component.html',
  styleUrls: ['./manual-allocation-edit.component.scss'],
  preserveWhitespaces: true
})
export class ManualAllocationEditComponent
  extends AbstractDockManagementEdit<ReverseDeliveryManualAllocation>
  implements OnInit, OnDestroy {
  docks = [];
  docksDepositor = [];
  depositors = [];
  establishments = [];
  providers = [];
  carrying = [];
  deliveryAuthorizations: DeliveryAuthorization[] = [];
  subscriptions: Subscription[] = [];

  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;

  rowsDetails = [];
  conflicts = 0;

  conflictRecurrent = false;

  selected: any[] = [];

  depositorSelected;
  manualAllocation: ManualAllocationReverse;
  reverseDeliveryManualAllocation: ReverseDeliveryManualAllocation;

  slot: SlotPattern;

  // configLegend: Legend[] = [
  //   {
  //     color: '#f54542',
  //     text: 'Conflito',
  //     tooltip: 'será gerado conflito'
  //   },
  //   {
  //     color: `#f57242`,
  //     text: 'Feriado',
  //     tooltip: 'Horários e dias com feriado'
  //   },
  //   {
  //     color: `#f5bf42`,
  //     text: 'Dia não operacional',
  //     tooltip: 'Data não operacional'
  //   }
  // ];

  @ViewChild('template') template1: TemplateRef<any>;
  @ViewChild('provider') provider: SelectFieldComponent;

  optionsActionType: object[] = [
    {
      name: 'dock-management.unlock-slots.actionTypesEdit.options.available',
      value: OpNatureAuthorizationEnum.DISPONIVEL
    },
    {
      name: 'dock-management.unlock-slots.actionTypesEdit.options.blocked',
      value: OpNatureAuthorizationEnum.BLOQUEADO
    }
  ];

  optionsOperationSubNature = [
    {
      name:
        'schedules.reverse-delivery.manual-allocation.subNature.options.refuses',
      value: SubNature.REFUSES
    },
    {
      name:
        'schedules.reverse-delivery.manual-allocation.subNature.options.returns',
      value: SubNature.RETURNS
    }
  ];

  // columnsDetails: object[] = [
  //   { prop: 'authorizationKey', name: 'Agendamento', width: 180 },
  //   { prop: 'operationNature', name: 'Natureza da Operação' },
  //   // { prop: 'operationSubNature', name: 'Sub-Natureza' },
  //   { prop: 'depositor.detailName', name: 'Depositante' },
  //   { prop: 'provider.shortName', name: 'Fornecedor' },
  //   { prop: 'ini', name: 'Hora inicial' },
  //   { prop: 'fin', name: 'Hora final' },
  //   { prop: 'date', name: 'Data', pipe: new DateFormatPipe('en-US') }
  // ];

  constructor(
    private _slotReserve: SlotReserveService,
    private route: ActivatedRoute,
    private _manualAllocationService: ManualAllocationService,
    sanitizer: DomSanitizer,
    public location: Location,
    private _router: Router,
    private _slotService: SlotPatternService,
    public modalService: BsModalService,
    private _priorVisualizationService: PriorVisualizationService
  ) {
    super(sanitizer, location);
  }

  ngOnInit() {
    this.manualAllocation = this.route.snapshot.data['manualAllocation'];
    

    this.reverseDeliveryManualAllocation = this.manualAllocation[
      'manualAllocationReverse'
    ];

    this.depositors = [this.manualAllocation.depositor];
    this.establishments = [
      this.manualAllocation['manualAllocationReverse'].establishment
    ];
    this.carrying = [
      this.manualAllocation['manualAllocationReverse'].providerShipping
    ];

    this.providers = [this.manualAllocation.docksScheduling.provider];

    if (!this.providers[0]) {
      this.providers = [];
      this.provider.bindLabel = 'several';
      this.provider.bindValue = '';
      // this.providers.push({ several: 'Diversos' });
      this.providers = this.providers.map(v => (v['several'] = 'Diversos'));
      this.provider.items = this.providers;
    } else {
      this.providers = this.manualAllocation[
        'manualAllocationReverse'
      ].provider;
      this.provider.items = this.providers;
    }

    this.deliveryAuthorizations = [
      this.manualAllocation.docksScheduling.deliveryAuthorization
    ];

    this.setDocks();
    // this.manualAllocation = new ReverseDeliveryManualAllocation();

    this.formEdit = new FormGroup({
      id: new FormControl(''),
      depositor: new FormControl(this.manualAllocation.depositor),
      depositorId: new FormControl(''),
      establishment: new FormControl(
        this.manualAllocation['manualAllocationReverse'].establishment
      ),
      establishmentId: new FormControl(
        this.manualAllocation['manualAllocationReverse'].establishmentId
      ),
      provider: new FormControl(this.provider.items),
      providerId: new FormControl(''),
      providerShipping: new FormControl(
        this.manualAllocation['manualAllocationReverse'].providerShipping
      ),
      deliveryAuthorization: new FormControl(
        this.manualAllocation.docksScheduling.deliveryAuthorization
      ),
      operationSubNature: new FormControl(
        this.manualAllocation['manualAllocationReverse']
          ? this.manualAllocation['manualAllocationReverse'].operationSubNature
          : ''
      ),
      dateSchedule: new FormControl(
        this.manualAllocation.docksScheduling.dateScheduleDelivery
        // disabled: true
      ),
      priorVisualization: new FormControl(
        this.manualAllocation.docksScheduling.priorVisualization
      ),
      iniHour: new FormControl(
        this.manualAllocation.docksScheduling.initialHour
      ),
      finHour: new FormControl(this.manualAllocation.docksScheduling.finalHour),
      eventName: new FormControl(
        this.manualAllocation['manualAllocationReverse']
          ? this.manualAllocation['manualAllocationReverse'].eventName
          : ''
      ),
      endsAfterEvents: new FormControl(
        this.reverseDeliveryManualAllocation
          ? this.reverseDeliveryManualAllocation.endsAfterEvents
          : 0
      ),
      dateScheduleDeliveryNew: new FormControl(''),
      priorVisualizationNew: new FormControl(
        this.manualAllocation.docksScheduling.priorVisualization,
        [Validators.required]
      ),
      initialHourNew: new FormControl(''),
      finalHourNew: new FormControl(''),
      eventNameNew: new FormControl(''),
      actionType: new FormControl(''),
      updateSchedule: new FormControl('1'),
      recurrent: new FormControl(
        this.reverseDeliveryManualAllocation
          ? this.reverseDeliveryManualAllocation.recurrent
          : false
      ),
      dates: new FormControl([])
    });

    // this.formEdit
    //   .get('initialHourNew')
    //   .setValidators(validatorFinHour('iniHour'));

    // this.formEdit.get('iniHour').valueChanges.subscribe(() => {
    //   this.formEdit.get('finHour').hasError('validFinHour');
    // });

    // this.formEdit.get('endsAfterDay').valueChanges.subscribe(value => {
    //   if (value && !/Invalid date/.test(value)) {
    //     this.formEdit.get('endsAfterEvents').setValue('');
    //   }
    // });

    [
      'establishment',
      'depositor',
      'provider',
      'providerShipping',
      'deliveryAuthorization',
      'operationSubNature',
      'dateSchedule',
      'priorVisualization',
      'iniHour',
      'finHour',
      'eventName'
    ].forEach(field => {
      this.formEdit.get(field).disable();
    });

    this._slotService
      .findByEstablishmentIdAndStatusTrue(
        this.manualAllocation.depositor.establishment.id
      )
      .subscribe(slot => (this.slot = slot));

    this.listenValueChanges('depositor', 'depositorId');
    this.listenValueChanges('provider', 'providerId');
    this.selectedDepositor(this.formEdit.getRawValue()['depositor']);

    this.formEdit.get('updateSchedule').valueChanges.subscribe(value => {
      if (value == 1) this.conflictRecurrent = false;
    });
  }

  listenValueChanges(objKeyValue: string, fieldKey: string) {
    this.formEdit.get(objKeyValue).valueChanges.subscribe(value => {
      if (value) {
        this.formEdit.get(fieldKey).setValue(value.id);
        return;
      }

      this.formEdit.get(fieldKey).setValue('');
    });
  }

  setDocks() {
    this.docks = [this.manualAllocation.docksScheduling.priorVisualization];

    if (this.docks.length) {
      this.docks = this.docks.map(value => this.setFullName(value));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  loadReserveTypesByDepositor(depositorId: number) {
    this._slotReserve
      .findByDepositorIdAndStatus(depositorId)
      .subscribe((data: HttpResponse<SlotReserve>) => {
        if (data && data.body) {
          
          if (data.body['useFirstReserveType']) {
            this.optionsActionType.push({
              name:
                'dock-management.unlock-slots.actionTypesEdit.options.reserveTypeOne',
              value: OpNatureAuthorizationEnum.TIPO_1
            });
          }
          if (data.body['useSecondReserveType']) {
            this.optionsActionType.push({
              name:
                'dock-management.unlock-slots.actionTypesEdit.options.reserveTypeTwo',
              value: OpNatureAuthorizationEnum.TIPO_2
            });
          }
        }
      });
  }

  selectedDepositor(depositor: Depositor) {
    
    if (depositor) {
      this.loadReserveTypesByDepositor(depositor.id);

      this._priorVisualizationService
        .findByDepositorIdAndEstablishmentId(
          depositor.id,
          depositor.establishment.id
        )
        .subscribe(data => {
          this.setDocksDepositor(data);
        });
    }
  }

  setDocksDepositor(data: PriorVisualization[]) {
    
    if (data) {
      this.docksDepositor = data;

      if (this.docksDepositor.length) {
        this.docksDepositor = data.map(value => {
          return this.setFullName(value);
        });
      }

      if (this.docksDepositor.length === 1) {
        this.formEdit
          .get('priorVisualizationNew')
          .setValue(this.docksDepositor[0]);
      }
    } else {
      this.docksDepositor = [];
      this.formEdit.get('priorVisualizationNew').setValue(this.docksDepositor);
    }
  }

  private setFullName(value: PriorVisualization) {
    value['fullName'] = `${value['signatureDock']} - (${
      value['initialHour']
    } - ${value['finalHour']})`;
    return value;
  }

  getRowClass(row) {
    return {
      'schedule-ok': !row.conflict && !row.holiday && !row.notOperationl,
      'conflict-row': row.conflict,
      'holiday-color': row.holiday,
      'not-operational': row.notOperationl
    };
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'second modal-lg'
    });
  }

  submit() {
    if (this.formEdit.get('updateSchedule').value == 2) {
      this.conflictRecurrent = true;
      return;
    }

    if (this.formEdit.valid) {
      const formValue = this.formEdit.getRawValue();

      

      this._manualAllocationService
        .validateRecurrentScheduleOnUpdate(formValue)
        .subscribe(
          (data: HttpResponse<SchedulesConflictDTO[]>) => {
            

            if (data) {
              if (data.status == 200) {
                this.rowsDetails = data.body;
                this.selected = this.rowsDetails.filter(row => row['conflict']);
                this.conflicts = this.selected.length;
                this.openModal(this.template1);
                return;
              }

              if (data.status == 202) {
                const dates = data.body.map(date => date['date']);
                this.formEdit.get('dates').setValue(dates);

                this.updateSchedulingReverse();
              }
            }

            // if (data && data.length) {
            //   this.rowsDetails = data;
            //   this.conflicts = data.length;
            //   this.openModal(this.template1);
            //   return;
            // }
            // this.updateScheduleToReverseWithoutConflictRecurrent();
          },
          error => {
            this.alertMsg(error.message || error, 'danger');
          }
        );
    } else {
      this.verificaValidacoesForm(this.formEdit);
    }

    // const formValue = this.formEdit.getRawValue();

    // const depositorId = formValue['depositorId'].id;
    // formValue['depositorId'] = depositorId;

    // const providerId = formValue['providerId'].id;
    // formValue['providerId'] = providerId;

    // this.subscriptions.push(
    //   this._manualAllocationService
    //     .updateManualAllocation(formValue)
    //     .subscribe(() => {
    //       this.alertMsg('Agendamento(s) alterados com sucesso.');
    //       this.disabledForm();
    //     })
    // );
  }

  updateSchedulingReverse() {
    const formValue = this.formEdit.getRawValue();

    this.subscriptions.push(
      this._manualAllocationService.updateManualAllocation(formValue).subscribe(
        () => {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              message: 'Agendamento(s) alterados com sucesso.'
            },
            skipLocationChange: true
          };
          this._router.navigate(
            ['sdm/register/schedules/reverse-delivery/manual-allocation'],
            navigationExtras
          );
        },
        error => this.alertMsg(error, 'danger')
      )
    );
  }

  updateScheduleToReverseWithoutConflictRecurrent() {
    if (this.formEdit.get('id').value == null) {
      const formValue = this.formEdit.getRawValue();

      this.subscriptions.push(
        this._manualAllocationService
          .createReverseRecurrent(formValue)
          .subscribe((data: ReverseDeliveryManualAllocation) => {
            this.formEdit.reset();
            this.formEdit.disable();

            
            this.alertMsg(
              `Agendamento atualizado com sucesso. Numeração ${
                data['deliveryAuthorization'].authorizationkey
              }`
            );
          })
      );
    }
  }

  goBack() {
    this._router.navigate([
      'sdm/register/schedules/reverse-delivery/manual-allocation'
    ]);
  }

  getService(): AbstractService<ReverseDeliveryManualAllocation> {
    return this._manualAllocationService;
  }

  changeIniHour(e) {
    this.changeHours(e, 'initialHourNew');
  }

  changeFinHour(e) {
    this.changeHours(e, 'finalHourNew');
  }

  changeFinalHour() {}

  changeHours(e, name) {
    const value = e.target.value;

    if (!this.validateIfIsValidHour(value)) {
      this.formEdit.get(name).setValue('');
      return;
    }

    if (this.slot && this.slot.sizeInMinutes > 0) {
      const hourSplit = value.split(':');

      if (hourSplit[0] > 23) {
        this.formEdit.get(name).setValue('');
        return;
      }

      if (hourSplit[1] % this.slot.sizeInMinutes > 0) {
        hourSplit[1] = '00';
      }

      const hourJoin = hourSplit.join(':');

      this.formEdit.get(name).setValue(hourJoin);
    }
  }

  validateIfIsValidHour(value): boolean {
    return value && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
  }

  closeModalConfirmConflicts() {
    this.modalRef.hide();
  }

  updateScheduleWithConflict() {
    if (!this.selected.length) {
      return;
    }

    let dates = [];
    let datesWithoutConflict = [];

    dates = this.selected
      .filter(row => row['conflict'])
      .map(row => row['date']);

    // datesWithoutConflict = this.rowsDetails
    //   .filter(
    //     row => !row['conflict'] && !row['holiday'] && !row['notOperational']
    //   )
    //   .map(row => row['date']);

    
    

    this.formEdit.get('dates').setValue(dates);
    // this.formEdit.get('datesWithoutConflict').setValue(datesWithoutConflict);

    

    this.closeModalConfirmConflicts();

    const formValue = this.formEdit.getRawValue();

    this._manualAllocationService.updateScheduleToConflict(formValue).subscribe(
      data => {
        if (data) {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              message:
                'Agendamento(s) atualizado(s) com sucesso, porém existem conflitos há serem resolvidos.'
            },
            skipLocationChange: true
          };
          this._router.navigate(
            ['sdm/register/schedules/reverse-delivery/manual-allocation'],
            navigationExtras
          );
        }
      },
      error => {
        this.alertMsg(error, 'danger');
        
      }
    );
  }
}
