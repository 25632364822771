const gateway = {
    roles: [],
    integration: true, 
    authority: 'USER',
    solution: 'GATEWAY',
    text: 'Portal',
    link: '/gateway/home',
    icon: 'fa fa-arrow-left',
    name: 'User_Gateway',
}

const headingMain = {
    roles: [],
    integration: true, 
    authority: 'USER',
    solution: 'SBAW',
    text: 'Smart Pedidos',
    heading: true
};

const process = {
    roles: [],
    solution: 'SBAW',
    integration: false, 
    authority: 'USER',
    text: 'Processo',
    icon: 'fa fa-th-list',
    link: '#',
    submenu: [
        {
            roles: [],
            integration: true,
            authority: 'USER',
            solution: 'SBAW',
            text: 'Home',
            link: '/sbaw/home',
            icon: 'icon-home',
            name: 'Sbaw_Home',
        },
        {
            roles: [],
            integration: true, 
            authority: 'USER',
            solution: 'SBAW',
            text: 'Importar',
            link: '/sbaw/importEdi',
            icon: 'fa fa-upload',
            name: 'Import',
        },
        {
            roles: [],
            integration: true, 
            authority: 'USER',
            solution: 'SBAW',
            text: 'Resumo Geral',
            link: '/sbaw/overview',
            icon: 'fa fa-th-list',
            name: 'Overview',
        },
        {
            roles: [],
            integration: true, 
            authority: 'USER',
            solution: 'SBAW',
            text: 'Filtros',
            link: '/sbaw/filters',
            icon: '	fa fa-sliders',
            name: 'Filters',
        },
        {
            roles: [],
            integration: true,
             authority: 'USER',
            solution: 'SBAW',
            text: 'Estratégia de Picking',
            link: '/sbaw/picking-strategy',
            icon: 'fa fa-random',
            name: 'Picking_Strategy',
        },
        {
            roles: [],
            integration: true,
            authority: 'USER',
            solution: 'SBAW',
            text: 'Exportar',
            link: '/sbaw/export-miwa',
            icon: 'fa fa-download',
            name: 'Export',
        }

    ]
}

const management = {
    roles: [],
    solution: 'SBAW',
    integration: true, 
    authority: 'USER',
    text: 'Gestão',
    icon: 'icon-graph',
    link: '#',
    submenu: [
        {
            roles: [],
            integration: true, 
            authority: 'USER',
            solution: 'SBAW',
            text: 'Gestão de Arquivos',
            link: '/sbaw/edi-files',
            icon: 'fa fa-file',
            name: 'File_Management',
        },
        {
            roles: [],
            integration: true,
            authority: 'USER',
            solution: 'SBAW',
            text: 'Gestão de Conjuntos',
            link: '/sbaw/set-management',
            icon: 'fa fa-cubes',
            name: 'Set_Management',
        },
        {
            roles: [],
            integration: true, 
            authority: 'USER',
            solution: 'SBAW',
            text: 'Gestão de Pedidos',
            link: '/sbaw/order-management',
            icon: 'fa fa-reorder',
            name: 'Order_Management',
        }//,
        // {
        //     roles: [],
        //     integration: true, 
        //     solution: 'SBAW',
        //     authority: 'USER',
        //     text: 'Gestão á Vista',
        //     link: '/sbaw/gav-sbaw',
        //     icon: 'icon-graph',
        //     name: 'Spot_Management',
        // }
    ]
}

const parametrizations = {
    roles: [],
    solution: 'SBAW',
    integration: true, authority: 'USER',
    text: 'Parametrizações',
    icon: 'fa fa-gears',
    link: '#',
    submenu: [
        {
            roles: [],
            solution: 'SBAW',
            integration: true, authority: 'USER',
            text: 'Região',
            link: 'region-parametrization',
            icon: 'fa fa-map ',
            name: 'Region',
        },
        {
            roles: [],
            solution: 'SBAW',
            integration: true, authority: 'USER',
            text: 'Tamanho',
            link: 'size-parametrization',
            icon: 'fa fa-balance-scale',
            name: 'Size',
        },
        {
            roles: [],
            solution: 'SBAW',
            integration: true, authority: 'USER',
            text: 'Código de Cores',
            link: 'color-code-parametrization',
            icon: 'fa fa-paint-brush',
            name: 'Color',
        },
        {
            roles: [],
            solution: 'SBAW',
            integration: true, authority: 'USER',
            text: 'Configurações',
            link: 'settings-parametrization',
            icon: 'fa fa-cogs',
            name: 'Configuration',
        },
    ]
}

const smartUser = {
    solution: 'SBAW',
    integration: true, authority: 'ADMIN',
    text: 'Usuários e Perfis de Acesso',
    link: 'sbaw-user-domain',
    icon: 'fa fa-user-circle-o',
    name: 'User_Sbaw',
};

export const menuSbaw = [
    gateway,
    headingMain,
    process,
    management,
    parametrizations,
    smartUser
];
