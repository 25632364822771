import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'booleanConverter' })
export class BooleanConverter implements PipeTransform {

  private masks = [
    { locale: "br", true: 'SIM', false: 'NÃO' },
    { locale: "en", true: 'YES', false: 'NO' },
  ]

  transform(value): string {

    let lang = navigator.language.toLowerCase();
    for (let mask of this.masks) {
        if (lang.endsWith(mask.locale)) {
            return value ? mask.true : mask.false;
        }
    }
  }
}
