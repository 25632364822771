import { Pipe } from '@angular/core';



@Pipe({
    name: 'decimal'
})
export class Decimal {
    transform(value: any,
        decimalLength: number = 4,
        chunkDelimiter: string = '.',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
        
        if (typeof value === 'number') {
            let num = (value ? value : 0.0).toFixed(Math.max(0, ~~decimalLength));
            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
        } else
            return null;
    }
}