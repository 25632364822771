import { SDM_URL } from './../../../../app.api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { OperationNature } from '../../../../core/models/operation-nature/operationNature';
import { DepositorService } from '../../../../core/services/depositor.service';
import { OperationNatureService } from '../../../../core/services/operation-nature/operationNature.service';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { OperationNatureDepositorService } from '../../../../core/services/operation-nature/operationNatureDepositor.service';
import { OperationNatureDepositor } from '../../../../core/models/operation-nature/operationNatureDepositor';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-operation-nature-form',
  templateUrl: './operation-nature-form.component.html',
  styleUrls: ['./operation-nature-form.component.scss'],
})
export class OperationNatureFormComponent implements OnInit {

  depositors = [];
  private loaded = false;
  selectedDepositors = [];
  public establishmentId = '';
  selectedOperationNatures = [];
  private _operationNature: OperationNature;

  @ViewChild('grdOperationNature') grdOperationNature: DatatableApiComponent;
  @ViewChild('grdEstablishments') grdEstablishments: DatatableApiComponent;
  @ViewChild('grdDepositors') grdDepositors: DatatableApiComponent;
  @ViewChild('grdOperationNatureDepositor') grdOperationNatureDepositor: DatatableApiComponent;

  columns = [
    { prop: 'id', name: '#' },
    { prop: 'proccess', name: 'Processo' },
    { prop: 'operationNature', name: 'Natureza da Operação' },
  ];

  columnsEstablishment = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'description', name: 'Descrição' },
    { prop: 'shortDescription', name: 'Descrição resumida' },
    { prop: 'document', name: 'CNPJ' },
  ];

  columnsDepositors = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'name', name: 'Razão social' },
    { prop: 'document', name: 'CNPJ' },
  ];

  columnsOperationNatureDepositor = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'operationNature.proccess', name: 'Processo' },
    { prop: 'operationNature.operationNature', name: 'Natureza' },
  ];

  constructor(
    private _operationNatureService: OperationNatureService,
    private _depositorService: DepositorService,
    private _opNatureDepositorService: OperationNatureDepositorService,
    private toaster: ToastrService) {
  }

  ngOnInit() {
    
  }

  establishmentSelected(data) {
    
    this.establishmentId = data.id;
    setTimeout(() => {
      this.grdDepositors.reloadPage();
      this.grdOperationNatureDepositor.reloadPage();
    }, 1);
  }

  depositorsSelected(selected) {
    this.selectedDepositors.splice(0, this.selectedDepositors.length);
    this.selectedDepositors.push(...selected);
  }

  operationNatureSelecteds(selected) {
    this.selectedOperationNatures.splice(0, this.selectedOperationNatures.length);
    this.selectedOperationNatures.push(...selected);
  }

  endPointGetDepositorByEstablishment = () => SDM_URL.depositor.findDepositorsByEstablishmentId.replace(':establishmentId', this.establishmentId);

  addOperationNature() {
    let hasSelectOnlyActives = true;
    for (let operationNature of this.selectedOperationNatures) {
      if (!operationNature['status']) {
        hasSelectOnlyActives = false;
        break;
      }
    }

    if (!hasSelectOnlyActives) {
      this.toaster.error('Selecione apenas as naturezas ativas');
      return;
    }

    const arrNatureOpDepositors: OperationNatureDepositor[] = [];
    for (let n = 0; n < this.selectedOperationNatures.length; n++) {
      const opNatureId = this.selectedOperationNatures[n].id;
      const opNatureProccess = this.selectedOperationNatures[n].proccess;
      const opNature = this.selectedOperationNatures[n].operationNature;
      for (let d = 0; d < this.selectedDepositors.length; d++) {
        const depositorId = this.selectedDepositors[d].id;
        arrNatureOpDepositors.push(new OperationNatureDepositor(+this.establishmentId, depositorId, new OperationNature(opNatureId, opNatureProccess, opNature)));
      }
    }

    this._opNatureDepositorService.createOperationNature(arrNatureOpDepositors)
      .subscribe(res => {
        this.grdOperationNatureDepositor.reloadPage();
      });
  }

}
