import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class HolidayRelationShip extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public depositorId: number;
  public operationNature: number;
  public initialDate: string;
  public initialHour: string;
  public finalDate: string;
  public finalHour: string;
  public observation: string;
  public allDepositors: boolean;
  public allOperationNature: boolean;

  constructor() {
    super();
    this.allDepositors = false;
    this.allOperationNature = false;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
