import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { AbstractService } from '../../abstract.service';
import { ProductivityPattern } from './../../../models/sdm/provider-management/productivity-pattern';

@Injectable({
  providedIn: 'root',
})
export class ProductivityPatternService extends AbstractService<ProductivityPattern> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.providerManagement.docksDimensioningTime.productivityPattern.default);
  }
}
