import { Location } from '@angular/common';
import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment/moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabDirective } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

import * as am4charts from '../../../../../node_modules/@amcharts/amcharts4/charts';
import * as am4core from '../../../../../node_modules/@amcharts/amcharts4/core';
import am4themes_animated from '../../../../../node_modules/@amcharts/amcharts4/themes/animated';
import { GridOptions } from '../../../../../node_modules/ag-grid/main';
import { SelectFieldComponent } from '../../../components/select/select-field/select-field.component';
import { Report } from '../../../core/models/report/report';
import { Establishment } from '../../../core/models/sdm/establishment';
import { CollectSchedules } from '../../../core/models/sdm/schedules/collect-schedules';
import { AuthService } from '../../../core/services/auth.service';
import { DepositorService } from '../../../core/services/depositor.service';
import { EstablishmentService } from '../../../core/services/establishment.service';
import { GestaoVistaSdmService } from '../../../core/services/gestao-vista-sdm.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SDM_URL } from './../../../app.api';

// tslint:disable-next-line:no-duplicate-imports
declare var $: any;
am4core.useTheme(am4themes_animated);

@Component({
    selector: 'app-gestao-vista-sdm',
    templateUrl: './gestao-vista-sdm.component.html',
    styleUrls: ['./gestao-vista-sdm.component.scss'],
})
export class GestaoVistaSdmComponent extends BaseFormComponent implements OnInit, OnDestroy, AfterViewInit {

    // seletores do filtro
    establishments = [];
    depositors = [];
    operationNatures: any[] = [];
    timeMeasures: Array<any>;
    report: Report;

    resizeEvent = 'resize.ag-grid';
    $win = $(window);

    valueTabSituacao: string;
    valueTabEvolucaoHistorica: string;

    isCollapsed = false;
    formFilters: FormGroup;
    isAutoLoadGrid = true;
    countLoad = 0;
    rowDetail: any;
    modalRefDetail: BsModalRef;

    @ViewChild('modalDetail') modalDetail: TemplateRef<any>;

    @ViewChild('establishment') selectEstablishment: SelectFieldComponent;
    @ViewChild('depositor') selectDepositor: SelectFieldComponent;
    @ViewChild('operationNature') selectOperationNature: SelectFieldComponent;
    @ViewChild('timeMeasure') selectTimeMeasure: SelectFieldComponent;

    // chart instance
    private chartTipoUsuario: am4charts.PieChart3D;
    private chartAuditoriaDocumento: am4charts.PieChart3D;
    private chartPerfilUtilizacaoDoca: am4charts.PieChart3D;
    private chartAntecedenciaAutorizacao: am4charts.XYChart;
    private chartTaxaUtilizacaoDoca: am4charts.XYChart;
    private chartEvolucaoHistoricaQuantidadeDoca: am4charts.XYChart;
    private chartCheckListNaturezaOperacao: am4charts.XYChart;
    private chartEvolucaoProducao: am4charts.XYChart;
    private chartInterrupcaoQuantidade: am4charts.XYChart;
    private chartEstimativaHomemHora: am4charts.XYChart;
    private chartProdutividade: am4charts.XYChart;

    gridOptionsSituacao: GridOptions;
    gridOptionsEventoPrevistoRealizado: GridOptions;
    gridOptionsUltimoNoShow: GridOptions;
    gridOptionsDemandaDoca: GridOptions;
    gridOptionsMaisPontual: GridOptions;
    gridOptionsMenosPontual: GridOptions;
    gridOptionsMuralFama: GridOptions;
    gridOptionsSituacao2: GridOptions;
    gridOptionsCriterioCompensacao: GridOptions;
    defaultColDef;

    columnDefsSituacao = [
        {
            headerName: ' ',
            field: 'descricao',
            width: 50,
            pinned: 'left',
            lockPinned: true,
            cellClass: 'lock-pinned',
        }, {
            headerName: 'Previsto',
            field: 'previsto',
            width: 50
        }, {
            headerName: 'Realizado',
            field: 'realizado',
            width: 50
        }, {
            headerName: 'No-Show',
            field: 'noShow',
            width: 50
        }, {
            headerName: 'A acontecer',
            field: 'aAcontecer',
            width: 50
        }, {
            headerName: ' ',
            field: 'porcentagem',
            width: 50
        },
    ];

    columnDefsEventoPrevistoRealizado = [
        {
            headerName: 'Base Dados',
            field: 'baseDados',
            width: 30
        },
        {
            headerName: 'Eventos previstos', children: [
                {
                    headerName: 'Entrega regular',
                    field: 'previstoEntregaRegular',
                    width: 50
                }, {
                    headerName: 'Entrega reversa',
                    field: 'previstoEntregaReversa',
                    width: 50
                }, {
                    headerName: 'Coleta',
                    field: 'previstoColeta',
                    width: 50
                }
            ]
        },
        {
            headerName: 'Eventos realizados', children: [
                {
                    headerName: 'Entrega regular',
                    field: 'realizadoEntregaRegular',
                    width: 50
                }, {
                    headerName: 'Entrega reversa',
                    field: 'realizadoEntregaReversa',
                    width: 50
                }, {
                    headerName: 'Coleta',
                    field: 'realizadoColeta',
                    width: 50
                }, {
                    headerName: '% Docas',
                    field: 'porcentagemDoca',
                    width: 50
                }
            ]
        },
    ];

    columnDefsUltimoNoShow = [
        {
            headerName: 'Data',
            field: 'operationDate',
            width: 20
        }, {
            headerName: 'Depositante',
            field: 'depositor.name',
            width: 50
        }, {
            headerName: 'Quantidade Slots',
            field: 'qtdSlots',
            width: 20
        }, {
            headerName: 'Natureza Operação',
            field: 'opNatureDelivery',
            width: 20
        }, {
            headerName: 'Fornecedor',
            field: 'provider.shortName',
            width: 50
        }, {
            headerName: 'Transportadora',
            field: 'provider.shortName',
            width: 50
        },
    ];

    columnDefsDemandaDoca = [
        {  headerName: '#',
           valueGetter: 'node.rowIndex + 1', 
           width: 50
        }, {
            headerName: 'Depositante',
            field: 'depositorName',
            width: 50
        }, {
            headerName: 'Fornecedor',
            field: 'providerName',
            width: 50
        }, {
            headerName: 'Horas',
            field: 'deliveryTime',
            width: 50
        }, {
            headerName: 'Agendamentos',
            field: 'qtdSlots',
            width: 50
        },
    ];

    columnDefsMaisPontual = [
        {  headerName: '#',
           valueGetter: 'node.rowIndex + 1', 
           width: 50
        }, {
            headerName: 'Depositante',
            field: 'depositante',
            width: 50
        }, {
            headerName: 'Fornecedor',
            field: 'fornecedor',
            width: 50
        }, {
            headerName: 'Eventos',
            field: 'quantidadeEvento',
            width: 50
        }, {
            headerName: 'Pontualidade %',
            field: 'pontualidade',
            width: 50
        }, {
            headerName: 'Último Evento',
            field: 'ultimoEvento',
            width: 50
        },
    ];

    columnDefsMenosPontual = [
        {  headerName: '#',
           valueGetter: 'node.rowIndex + 1', 
           width: 50
        }, {
            headerName: 'Depositante',
            field: 'depositante',
            width: 50
        }, {
            headerName: 'Fornecedor',
            field: 'fornecedor',
            width: 50
        }, {
            headerName: 'Eventos',
            field: 'quantidadeEvento',
            width: 50
        }, {
            headerName: 'Pontualidade %',
            field: 'pontualidade',
            width: 50
        }, {
            headerName: 'Último Evento',
            field: 'ultimoEvento',
            width: 50
        },
    ];

    columnDefsMuralFama = [
        {
            headerName: 'Descrição',
            field: 'descricao',
            width: 50
        },
        {
            headerName: 'Mês', children: [
                {
                    headerName: '%',
                    field: 'porcentagemMes',
                    width: 50
                }, {
                    headerName: 'Data',
                    field: 'dataMes',
                    width: 50
                }
            ]
        },
        {
            headerName: 'Semana', children: [
                {
                    headerName: '%',
                    field: 'porcentagemSemana',
                    width: 50
                }, {
                    headerName: 'Data',
                    field: 'dataSemana',
                    width: 50
                }
            ]
        },
        {
            headerName: 'Dia', children: [
                {
                    headerName: '%',
                    field: 'porcentagemDia',
                    width: 50
                }
            ]
        },
    ];

    columnDefsSituacao2 = [
        {
            headerName: 'Core', children: [
                {
                    headerName: 'Athlete',
                    field: 'athlete',
                    width: 150
                }, {
                    headerName: 'Age',
                    field: 'age',
                    width: 90
                }
            ]
        },
        {
            headerName: 'Details', children: [
                {
                    headerName: 'Country',
                    field: 'country',
                    width: 120
                }, {
                    headerName: 'Year',
                    field: 'year',
                    width: 90
                }, {
                    headerName: 'Date',
                    field: 'date',
                    width: 110
                }, {
                    headerName: 'Sport',
                    field: 'sport',
                    width: 110
                }, {
                    headerName: 'Gold',
                    field: 'gold',
                    width: 100
                }, {
                    headerName: 'Silver',
                    field: 'silver',
                    width: 100
                }, {
                    headerName: 'Bronze',
                    field: 'bronze',
                    width: 100
                }, {
                    headerName: 'Total',
                    field: 'total',
                    width: 100
                }
            ]
        },
    ];

    pieOptionsIndicador = {
        animate: {
            duration: 800,
            enabled: true,
        },
        barColor: this.colors.byName('info'),
        trackColor: 'rgba(200,200,200,0.4)',
        scaleColor: false,
        lineWidth: 10,
        lineCap: 'round',
        size: 245,
    };

    indicator: any = {
        docks: {
            value: 0,
            previousValue: 0
        },
        taxaUtilizacaoDocaEsperada: 0,
        schedulesStarted: {
            value: 0,
            previousValue: 0
        },
        noShow: {
            value: 0,
            previousValue: 0
        },
        diaMaisUm: 0,
        diaMaisDois: 0,
        wtb: 0,
    };

    rating: any = {
        percent: 1,
        max: 1,
        rate: 1,
        isReadonly: true,
    };

    prepareGridOptions() {
        this.defaultColDef = { resizable: true, width: 50 };

        this.gridOptionsSituacao = {
            columnDefs: this.columnDefsSituacao,
            rowData: null,
        };

        this.gridOptionsEventoPrevistoRealizado = {
            columnDefs: this.columnDefsEventoPrevistoRealizado,
            rowData: null,
        };

        this.gridOptionsUltimoNoShow = {
            columnDefs: this.columnDefsUltimoNoShow,
            rowData: null,
        };

        this.gridOptionsDemandaDoca = {
            columnDefs: this.columnDefsDemandaDoca,
            rowData: null,
        };

        this.gridOptionsMaisPontual = {
            columnDefs: this.columnDefsMaisPontual,
            rowData: null,
        };

        this.gridOptionsMenosPontual = {
            columnDefs: this.columnDefsMenosPontual,
            rowData: null,
        };

        this.gridOptionsMuralFama = {
            columnDefs: this.columnDefsMuralFama,
            rowData: null,
        };

        this.gridOptionsSituacao2 = {
            columnDefs: this.columnDefsSituacao2,
            rowData: null,
            rowClassRules: {
                'table-danger': 'data.gold == 1',
                'table-warning': 'data.gold == 2',
                'table-success': 'data.gold == 6',
                'table-info': 'data.gold == 8',
            },
        };
    }

    constructor(
        public colors: ColorsService,
        public location: Location, public toaster: ToastrService, public translatorService: TranslatorService,
        public modalService: BsModalService,
        private zone: NgZone,
        private establishmentService: EstablishmentService,
        private depositorService: DepositorService,
        private authService: AuthService,
        private gestaoVistaService: GestaoVistaSdmService
    ) {
        super(location, toaster, translatorService);
        this.prepareGridOptions();
        moment().locale(navigator.language);
    }

    ngOnInit() {
        this.formFilters = new FormGroup({
            establishment: new FormControl(''),
            depositors: new FormControl(''),
            dateIni: new FormControl(''),
            dateFin: new FormControl(''),
            operationNature: new FormControl(''),
            timeMeasure: new FormControl(''),
        });

        const userId = this.authService.getUser().id;  //  exemplo userId const userId = this._authService.getUser().id;

        this.prepareFilters(userId);
        // this.setFilters(userId);
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.tipoUsuario();
            this.auditoriaDocumento();
            this.perfilUtilizacaoDoca();
            this.taxaUtilizacaoDoca();
            this.antecedenciaAutorizacao();
            this.evolucaoHistoricaQuantidadeDoca();
            this.checkListNaturezaOperacao();
            this.evolucaoProducao();
            this.interrupcaoQuantidade();
            this.estimativaHomemHora();
            this.produtividade();
        });

        this.avaliacaoPerfeita();
        this.contagemHomemHora();
        this.contagemEnderecoHora();
    }

    ngOnDestroy() {
        this.$win.off(this.resizeEvent);

        this.zone.runOutsideAngular(() => {
            if (this.chartTipoUsuario) {
                this.chartTipoUsuario.dispose();
            }
            if (this.chartAuditoriaDocumento) {
                this.chartAuditoriaDocumento.dispose();
            }
            if (this.chartPerfilUtilizacaoDoca) {
                this.chartPerfilUtilizacaoDoca.dispose();
            }
            if (this.chartAntecedenciaAutorizacao) {
                this.chartAntecedenciaAutorizacao.dispose();
            }
            if (this.chartTaxaUtilizacaoDoca) {
                this.chartTaxaUtilizacaoDoca.dispose();
            }
            if (this.chartEvolucaoHistoricaQuantidadeDoca) {
                this.chartEvolucaoHistoricaQuantidadeDoca.dispose();
            }
            if (this.chartCheckListNaturezaOperacao) {
                this.chartCheckListNaturezaOperacao.dispose();
            }
            if (this.chartEvolucaoProducao) {
                this.chartEvolucaoProducao.dispose();
            }
            if (this.chartInterrupcaoQuantidade) {
                this.chartInterrupcaoQuantidade.dispose();
            }
            if (this.chartEstimativaHomemHora) {
                this.chartEstimativaHomemHora.dispose();
            }
            if (this.chartProdutividade) {
                this.chartProdutividade.dispose();
            }
        });
    }

    setDate(val: number) {
        this.formFilters.get("dateFin").setValue(moment().startOf('day').add(1, 'days').format("YYYY-MM-DDTHH:mm:ss"));
        const dateIni = this.formFilters.get("dateIni");
        const timeMeasure = this.formFilters.get("timeMeasure");
        switch (val) {
            case 1:
                dateIni.setValue(moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss"));
                timeMeasure.setValue(3);
                break;
            case 2:
                dateIni.setValue(moment().startOf('week').format("YYYY-MM-DDTHH:mm:ss"));
                timeMeasure.setValue(2);
                break;
            case 3:
                dateIni.setValue(moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss"));
                timeMeasure.setValue(1);
                break;
        }
    }

    prepareFilters(userId: number) {
        this.setDate(1);

        this.gestaoVistaService.getOperationNatures().pipe(take(1)).subscribe(data => {
            this.operationNatures = data;
        });

        this.gestaoVistaService.getTimeMeasures().pipe(take(1)).subscribe(data => {
            this.timeMeasures = data;
        });

        this.establishmentService.findAll().pipe(take(1)).subscribe(data => {
            this.establishments = data.body['content'];
            this.formFilters.get('establishment').setValue(this.establishments[0]);
            this.depositorService.findByEstablishmentId(this.establishments[0].id).pipe(take(1)).subscribe(data => {
                this.depositors = data.body['content'];
            });
        });
    }

    selectedValEstablishment(establishment: Establishment) {
        this.depositorService.findByEstablishmentId(establishment.id).subscribe(data => {
            this.depositors = [...data.body['content']];
        });
    }


    filter() {
        this.gestaoVistaService.getGroupReport(this.filterEndpoint()).subscribe(data => {
            this.getAllData(data['content']);
        });
    }

    filterEndpoint(): string {
        let paramDepositors = [];
        let paramOperationNature = [];
        let depositors = this.formFilters.get("depositors").value;
        let operationsNature = this.formFilters.get("operationNature").value;

        for (let i = 0; i < operationsNature.length; i++) {
            paramOperationNature.push(operationsNature[i].id);
        }

        for (let i = 0; i < depositors.length; i++) {
            paramDepositors.push(depositors[i].id);
        }

        let queryDepositors = paramDepositors.join(',');
        let queryOperationNature = paramOperationNature.join(',');

        const urlParams = [
            { name: 'establishment', value: this.formFilters.get("establishment").value.id },
            { name: 'depositors', value: queryDepositors },
            { name: 'operationNature', value: queryOperationNature },
            { name: 'dateIni', value: this.formFilters.get("dateIni").value },
            { name: 'dateFin', value: this.formFilters.get("dateFin").value },
            { name: 'timeMeasure', value: this.formFilters.get("timeMeasure").value }
        ];

        let queryString = '';
        const query = [];

        urlParams.forEach(obj => {
            query.push(obj.name + '=' + obj.value);
        });

        queryString = query.join('&');

        return `${SDM_URL.report.groupReportSdm}?${queryString}`;
    }

    clearFilters() {
        this.formFilters.get('depositors').setValue('');
        this.formFilters.get('operationNature').setValue('');
        this.setDate(1);
        this.formFilters.get('timeMeasure').setValue('');
    }

    getAllData(content: any) {
        if (content != null) {
            if (content["indicator"] != null) {
                this.indicator = content["indicator"];
            }

            if (content["situacao"] != null) {
                this.gridOptionsSituacao.api.setRowData(content["situacao"]);
                this.gridOptionsSituacao.api.sizeColumnsToFit();
            } else { 
                this.gestaoVistaService.getSituacao(1, 2)
                    .subscribe((data) => {
                        this.gridOptionsSituacao.api.setRowData(data);
                        this.gridOptionsSituacao.api.sizeColumnsToFit();
                    });
            }

            this.gestaoVistaService.getEventoPrevistoRealizado(1, 2)
                .subscribe((data) => {
                    this.gridOptionsEventoPrevistoRealizado.api.setRowData(data);
                    this.gridOptionsEventoPrevistoRealizado.api.sizeColumnsToFit();
                });

            this.gestaoVistaService.getAuditoriaDocumento(1, 2)
                .subscribe(data => this.chartAuditoriaDocumento.data = data);

            if (content["userTypeDocksScheduling"] != null) {
                this.chartTipoUsuario.data = content["userTypeDocksScheduling"];
            }

            if (content["perfilUtilizacaoDoca"] != null) {
                this.chartPerfilUtilizacaoDoca.data = content["perfilUtilizacaoDoca"];
            }

            if (content["sightManagementNoShows"] != null) {
                this.gridOptionsUltimoNoShow.api.setRowData(content["sightManagementNoShows"]);
                this.gridOptionsUltimoNoShow.api.sizeColumnsToFit();
            }

            if (content["daysInAdvance"] != null) {
                this.chartAntecedenciaAutorizacao.data = content["daysInAdvance"];
            }

            this.gestaoVistaService.getTaxaUtilizacaoDoca(1, 3)
                .subscribe(data => {
                    this.chartTaxaUtilizacaoDoca.data = data;
                });

            this.gestaoVistaService.getEvolucaoHistoricaQuantidadeDoca(1, 2)
                .subscribe(data => this.chartEvolucaoHistoricaQuantidadeDoca.data = data);

            if (content["demandaHoraDocas"] != null) {
                this.gridOptionsDemandaDoca.api.setRowData(content["demandaHoraDocas"]);
                this.gridOptionsDemandaDoca.api.sizeColumnsToFit();
            }

            this.gestaoVistaService.getMaisPontual(1, 2)
                .subscribe((data) => {
                    this.gridOptionsMaisPontual.api.setRowData(data);
                    this.gridOptionsMaisPontual.api.sizeColumnsToFit();
                });

            this.gestaoVistaService.getMenosPontual(1, 2)
                .subscribe((data) => {
                    this.gridOptionsMenosPontual.api.setRowData(data);
                    this.gridOptionsMenosPontual.api.sizeColumnsToFit();
                });

            this.gestaoVistaService.getMuralFama(1, 2)
                .subscribe((data) => {
                    this.gridOptionsMuralFama.api.setRowData(data);
                    this.gridOptionsMuralFama.api.sizeColumnsToFit();
                });

            this.gestaoVistaService.getSituacao2(1, 2)
                .subscribe((data) => {
                    this.gridOptionsSituacao2.api.setRowData(data);
                    this.gridOptionsSituacao2.api.sizeColumnsToFit();
                });
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
        this.$win.on(this.resizeEvent, () => {
            setTimeout(() => { params.api.sizeColumnsToFit(); });
        });
    }

    tipoUsuario() {
        this.chartTipoUsuario = am4core.create('chartdivTipoUsuario', am4charts.PieChart3D);
        this.chartTipoUsuario.hiddenState.properties.opacity = 0; 
        this.chartTipoUsuario.legend = new am4charts.Legend();

        const series = this.chartTipoUsuario.series.push(new am4charts.PieSeries3D());
        series.dataFields.category = 'category';
        series.dataFields.value = 'value';
        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;
        series.colors.step = 1;

    }

    auditoriaDocumento() {
        this.chartAuditoriaDocumento = am4core.create('chartdivAuditoriaDocumento', am4charts.PieChart3D);
        this.chartAuditoriaDocumento.hiddenState.properties.opacity = 0; 
        this.chartAuditoriaDocumento.legend = new am4charts.Legend();


        const series = this.chartAuditoriaDocumento.series.push(new am4charts.PieSeries3D());
        series.dataFields.category = 'category';
        series.dataFields.value = 'value';
        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;
        series.colors.step = 2;

    }

    perfilUtilizacaoDoca() {
        this.chartPerfilUtilizacaoDoca = am4core.create('chartdivPerfilUtilizacaoDoca', am4charts.PieChart3D);
        this.chartPerfilUtilizacaoDoca.hiddenState.properties.opacity = 0;
        this.chartPerfilUtilizacaoDoca.legend = new am4charts.Legend();

        const series = this.chartPerfilUtilizacaoDoca.series.push(new am4charts.PieSeries3D());
        series.dataFields.category = 'opNatureDelivery';
        series.dataFields.value = 'qtdSlots';
        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;
        series.colors.step = 1;

    }

    antecedenciaAutorizacao() {
        const chartAntecedenciaAutorizacao = am4core.create('chartdivAntecedenciaAutorizacao', am4charts.XYChart);

        chartAntecedenciaAutorizacao.paddingRight = 40;
        chartAntecedenciaAutorizacao.paddingBottom = 25;


        const categoryAxis = chartAntecedenciaAutorizacao.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'daysInAdvance';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.title.text = 'Dias de antecedência';

        const valueAxis = chartAntecedenciaAutorizacao.yAxes.push(new am4charts.ValueAxis());


        const series = chartAntecedenciaAutorizacao.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'totalDaysInAdvance';
        series.dataFields.categoryX = 'daysInAdvance';
        series.name = 'Total de antecedências';
        series.columns.template.tooltipText = '{categoryX} dias com: [bold]{valueY}[/]';
        series.columns.template.fillOpacity = .8;

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '[bold]{valueY}[/]';

        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;

        const columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        this.chartAntecedenciaAutorizacao = chartAntecedenciaAutorizacao;
    }

    taxaUtilizacaoDoca() {
        this.chartTaxaUtilizacaoDoca = am4core.create('chartdivTaxaUtilizacaoDoca', am4charts.XYChart);
        this.chartTaxaUtilizacaoDoca.hiddenState.properties.opacity = 0; 



        this.chartTaxaUtilizacaoDoca.colors.step = 1;

        this.chartTaxaUtilizacaoDoca.padding(30, 30, 10, 30);
        this.chartTaxaUtilizacaoDoca.legend = new am4charts.Legend();

        const categoryAxis = this.chartTaxaUtilizacaoDoca.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;

        const valueAxis = this.chartTaxaUtilizacaoDoca.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;

        const series1 = this.chartTaxaUtilizacaoDoca.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(80);
        series1.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series1.name = 'Utilizada';
        series1.dataFields.categoryX = 'category';
        series1.dataFields.valueY = 'utilizada';
        series1.dataFields.valueYShow = 'totalPercent';
        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.pointerOrientation = 'vertical';

        const bullet1 = series1.bullets.push(new am4charts.LabelBullet());
        bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet1.label.fill = am4core.color('#ffffff');
        bullet1.locationY = 0.5;

        const series2 = this.chartTaxaUtilizacaoDoca.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(80);
        series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series2.name = 'No-Show';
        series2.dataFields.categoryX = 'category';
        series2.dataFields.valueY = 'noShow';
        series2.dataFields.valueYShow = 'totalPercent';
        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.pointerOrientation = 'vertical';

        const bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet2.locationY = 0.5;
        bullet2.label.fill = am4core.color('#ffffff');

        const series3 = this.chartTaxaUtilizacaoDoca.series.push(new am4charts.ColumnSeries());
        series3.columns.template.width = am4core.percent(80);
        series3.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
        series3.name = 'Ociosa';
        series3.dataFields.categoryX = 'category';
        series3.dataFields.valueY = 'ociosa';
        series3.dataFields.valueYShow = 'totalPercent';
        series3.dataItems.template.locations.categoryX = 0.5;
        series3.stacked = true;
        series3.tooltip.pointerOrientation = 'vertical';

        const bullet3 = series3.bullets.push(new am4charts.LabelBullet());
        bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
        bullet3.locationY = 0.5;
        bullet3.label.fill = am4core.color('#ffffff');

        this.chartTaxaUtilizacaoDoca.scrollbarX = new am4core.Scrollbar();
    }

    evolucaoHistoricaQuantidadeDoca() {
        this.chartEvolucaoHistoricaQuantidadeDoca = am4core.create('chartdivEvolucaoHistoricaQuantidadeDoca', am4charts.XYChart);

        const categoryAxis = this.chartEvolucaoHistoricaQuantidadeDoca.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'mes';

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        const valueAxis = this.chartEvolucaoHistoricaQuantidadeDoca.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = 'Quantidade Doca';

        function createSeries(field, name, stacked, chart) {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.categoryX = 'mes';
            series.name = name;
            series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
            series.stacked = stacked;
            series.columns.template.width = am4core.percent(95);

            const labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.locationX = 0.5;
            labelBullet.label.text = '{valueY}';
        }
        createSeries('utilizado', 'Utilizados', false, this.chartEvolucaoHistoricaQuantidadeDoca);
        createSeries('naoUtilizado', 'Não utilizados', true, this.chartEvolucaoHistoricaQuantidadeDoca);

        this.chartEvolucaoHistoricaQuantidadeDoca.legend = new am4charts.Legend();
    }

    checkListNaturezaOperacao() {

        this.chartCheckListNaturezaOperacao = am4core.create('chartdivCheckListNaturezaOperacao', am4charts.XYChart);


        this.chartCheckListNaturezaOperacao.data = [{
            naturezaOperacao: 'Carga Diferenciada',
            proprios: 30,
            terceiros: 50,
        }, {
            naturezaOperacao: 'Carga Regular',
            proprios: 40,
            terceiros: 80,
        }, {
            naturezaOperacao: 'Descarga Diferenciada',
            proprios: 60,
            terceiros: 20,
        }, {
            naturezaOperacao: 'Descarga Regular',
            proprios: 300,
            terceiros: 350,
        }];


        const categoryAxis = this.chartCheckListNaturezaOperacao.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'naturezaOperacao';

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        const valueAxis = this.chartCheckListNaturezaOperacao.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        function createSeries(field, name, stacked, chart) {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = 'naturezaOperacao';
            series.name = name;
            series.columns.template.tooltipText = '{name}: [bold]{valueX}[/]';
            series.stacked = stacked;
            series.columns.template.width = am4core.percent(95);

            const labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.locationX = 0.5;
            labelBullet.label.text = '{valueX}';
            labelBullet.label.fill = am4core.color('#fff');
        }

        createSeries('proprios', 'Próprios', false, this.chartCheckListNaturezaOperacao);
        createSeries('terceiros', 'Terceiros', true, this.chartCheckListNaturezaOperacao);

        this.chartCheckListNaturezaOperacao.legend = new am4charts.Legend();
    }

    evolucaoProducao() {

        this.chartEvolucaoProducao = am4core.create('chartdivEvolucaoProducao', am4charts.XYChart);


        this.chartEvolucaoProducao.data = [{
            mes: 'Jan',
            proprios: 20,
            terceiros: 50,
        }, {
            mes: 'Fev',
            proprios: 20,
            terceiros: 30,
        }, {
            mes: 'Mar',
            proprios: 20,
            terceiros: 10,
        }, {
            mes: 'Abr',
            proprios: 40,
            terceiros: 10,
        }, {
            mes: 'Mai',
            proprios: 40,
            terceiros: 10,
        }, {
            mes: 'Jun',
            proprios: 40,
            terceiros: 10,
        }, {
            mes: 'Jul',
            proprios: 40,
            terceiros: 50,
        }, {
            mes: 'Ago',
            proprios: 40,
            terceiros: 40,
        }, {
            mes: 'Set',
            proprios: 70,
            terceiros: 10,
        }, {
            mes: 'Out',
            proprios: 60,
            terceiros: 10,
        }, {
            mes: 'Nov',
            proprios: 40,
            terceiros: 20,
        }, {
            mes: 'Dez',
            proprios: 50,
            terceiros: 10,
        }, {
            mes: 'Jan 2019',
            proprios: 20,
            terceiros: 10,
        }];

        const categoryAxis = this.chartEvolucaoProducao.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'mes';
        categoryAxis.title.text = 'Produção';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        const valueAxis = this.chartEvolucaoProducao.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = 'Check-list';


        function createSeries(field, name, stacked, chart) {
            const series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = field;
            series.dataFields.categoryX = 'mes';
            series.name = name;
            series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]';
            series.stacked = stacked;
            series.columns.template.width = am4core.percent(95);

            const labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.locationX = 0.5;
            labelBullet.label.text = '{valueY}';

        }

        createSeries('proprios', 'Próprios', false, this.chartEvolucaoProducao);
        createSeries('terceiros', 'Terceiros', true, this.chartEvolucaoProducao);

        this.chartEvolucaoProducao.legend = new am4charts.Legend();
    }

    interrupcaoQuantidade() {

        this.chartInterrupcaoQuantidade = am4core.create('chartdivInterrupcaoQuantidade', am4charts.XYChart);

        this.chartInterrupcaoQuantidade.data = [{
            interrupcoes: '0',
            litres: 501.9,
            units: 250,
        }, {
            interrupcoes: '1',
            litres: 301.9,
            units: 222,
        }, {
            interrupcoes: '2',
            litres: 201.1,
            units: 170,
        }, {
            interrupcoes: '3',
            litres: 165.8,
            units: 122,
        }, {
            interrupcoes: '4',
            litres: 139.9,
            units: 99,
        }, {
            interrupcoes: '5',
            litres: 128.3,
            units: 85,
        }, {
            interrupcoes: '6',
            litres: 99,
            units: 93,
        }, {
            interrupcoes: '>6',
            litres: 60,
            units: 50,
        }];


        const categoryAxis = this.chartInterrupcaoQuantidade.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'interrupcoes';
        categoryAxis.title.text = 'Interrupções';

        const valueAxis = this.chartInterrupcaoQuantidade.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = 'Colaboradores';


        const series = this.chartInterrupcaoQuantidade.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'litres';
        series.dataFields.categoryX = 'interrupcoes';
        series.name = 'Sales';
        series.columns.template.tooltipText = 'Series: {name}\nCategory: {categoryX}\nValue: {valueY}';


        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.locationY = 0.5;
        labelBullet.locationX = 0.1;
        labelBullet.label.dx = -10;
        labelBullet.label.dy = 10;
        labelBullet.label.text = '{valueY}';
        labelBullet.label.fill = am4core.color('#fff');

        const series2 = this.chartInterrupcaoQuantidade.series.push(new am4charts.LineSeries());
        series2.name = 'Units';

        series2.strokeWidth = 3;
        series2.dataFields.valueY = 'units';
        series2.dataFields.categoryX = 'interrupcoes';

        const bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        const labelBullet2 = series2.bullets.push(new am4charts.LabelBullet());
        labelBullet2.label.text = '{units}';
        labelBullet2.label.dy = -20;
        const square = bullet2.createChild(am4core.Rectangle);
        square.width = 10;
        square.height = 10;
        square.horizontalCenter = 'middle';
        square.verticalCenter = 'middle';
        square.strokeWidth = 1;
        const shadow = new am4core.DropShadowFilter();
        shadow.dx = 2;
        shadow.dy = 2;
        square.filters.push(shadow);
        bullet2.tooltipText = 'Value: [bold]{units}[/]';
    }

    estimativaHomemHora() {

        this.chartEstimativaHomemHora = am4core.create('chartdivEstimativaHomemHora', am4charts.XYChart);


        this.chartEstimativaHomemHora.data = [{
            country: 'Lithuania',
            litres: 501.9,
            units: 250,
        }, {
            country: 'Czech Republic',
            litres: 301.9,
            units: 222,
        }, {
            country: 'Ireland',
            litres: 201.1,
            units: 170,
        }, {
            country: 'Germany',
            litres: 165.8,
            units: 122,
        }, {
            country: 'Australia',
            litres: 139.9,
            units: 99,
        }, {
            country: 'Austria',
            litres: 128.3,
            units: 85,
        }, {
            country: 'UK',
            litres: 99,
            units: 93,
        }, {
            country: 'Belgium',
            litres: 60,
            units: 50,
        }, {
            country: 'The Netherlands',
            litres: 50,
            units: 42,
        }];

        const categoryAxis = this.chartEstimativaHomemHora.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'country';
        categoryAxis.title.text = 'Countries';

        const valueAxis = this.chartEstimativaHomemHora.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = 'Litres sold (M)';


        const series2 = this.chartEstimativaHomemHora.series.push(new am4charts.LineSeries());
        series2.name = 'Units';

        series2.strokeWidth = 3;
        series2.dataFields.valueY = 'units';
        series2.dataFields.categoryX = 'country';

        const bullet = series2.bullets.push(new am4charts.CircleBullet());
        const labelBullet = series2.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = '{units}';
        labelBullet.label.dy = -20;
        const square = bullet.createChild(am4core.Rectangle);
        square.width = 10;
        square.height = 10;
        square.horizontalCenter = 'middle';
        square.verticalCenter = 'middle';
        square.strokeWidth = 1;
        const shadow = new am4core.DropShadowFilter();
        shadow.dx = 2;
        shadow.dy = 2;
        square.filters.push(shadow);
        bullet.tooltipText = 'Value: [bold]{units}[/]';
    }

    produtividade() {
        this.chartProdutividade = am4core.create('chartdivProdutividade', am4charts.XYChart);
        this.chartProdutividade.hiddenState.properties.opacity = 0;
        this.chartProdutividade.paddingRight = 25;

        this.chartProdutividade.data = [
            {
                category: 'Check List próprios',
                produtividade: 80,
                horaHomemProdutividade: 3.6,
                minimoQ4: 3.2,
                quartil: 25,
            },
            {
                category: 'Check List terceiros',
                produtividade: 60,
                horaHomemProdutividade: 2.6,
                minimoQ4: 2.8,
                quartil: 25,
            },
            {
                category: 'Atributos próprios',
                produtividade: 40,
                horaHomemProdutividade: 29.5,
                minimoQ4: 49.6,
                quartil: 25,
            },
            {
                category: 'Atributos terceiros',
                produtividade: 42,
                horaHomemProdutividade: 21.1,
                minimoQ4: 38.4,
                quartil: 25,
            },
        ];

        this.chartProdutividade.colors.list = [am4core.color('gray'), am4core.color('gray'), am4core.color('gray'), am4core.color('gray'),];
        this.chartProdutividade.padding(30, 30, 10, 30);

        const categoryAxis = this.chartProdutividade.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.title.text = '(com INT)';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = 0.9;

        const valueAxis = this.chartProdutividade.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.max = 100;
        valueAxis.strictMinMax = true;
        valueAxis.calculateTotals = true;
        valueAxis.renderer.minWidth = 50;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.grid.template.disabled = true;

        const series1 = this.chartProdutividade.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(40);
        series1.columns.template.tooltipText = 'Q1';
        series1.columns.template.fillOpacity = 0.1;

        series1.dataFields.categoryX = 'category';
        series1.dataFields.valueY = 'quartil';

        series1.dataItems.template.locations.categoryX = 0.5;
        series1.stacked = true;
        series1.tooltip.pointerOrientation = 'vertical';

        const bullet1 = series1.bullets.push(new am4charts.LabelBullet());
        bullet1.label.text = 'Q1'; 
        bullet1.locationY = 0.5;

        const series2 = this.chartProdutividade.series.push(new am4charts.ColumnSeries());
        series2.columns.template.width = am4core.percent(40);
        series2.columns.template.tooltipText = 'Q2'; 
        series2.columns.template.fillOpacity = 0.1;

        series2.dataFields.categoryX = 'category';
        series2.dataFields.valueY = 'quartil';

        series2.dataItems.template.locations.categoryX = 0.5;
        series2.stacked = true;
        series2.tooltip.pointerOrientation = 'vertical';

        const bullet2 = series2.bullets.push(new am4charts.LabelBullet());
        bullet2.label.text = 'Q2'; 
        bullet2.locationY = 0.5;


        const series3 = this.chartProdutividade.series.push(new am4charts.ColumnSeries());
        series3.columns.template.width = am4core.percent(40);
        series3.columns.template.tooltipText = 'Q3';
        series3.columns.template.fillOpacity = 0.1;

        series3.dataFields.categoryX = 'category';
        series3.dataFields.valueY = 'quartil';

        series3.dataItems.template.locations.categoryX = 0.5;
        series3.stacked = true;
        series3.tooltip.pointerOrientation = 'vertical';

        const bullet3 = series3.bullets.push(new am4charts.LabelBullet());
        bullet3.label.text = 'Q3'; 
        bullet3.locationY = 0.5;


        const series4 = this.chartProdutividade.series.push(new am4charts.ColumnSeries());
        series4.columns.template.width = am4core.percent(40);
        series4.columns.template.tooltipText = 'Q4'; 
        series4.columns.template.fillOpacity = 0.1;

        series4.dataFields.categoryX = 'category';
        series4.dataFields.valueY = 'quartil';
        series4.dataFields.valueX = 'minimoQ4';

        series4.dataItems.template.locations.categoryX = 0.5;
        series4.stacked = true;
        series4.tooltip.pointerOrientation = 'vertical';

        const bullet4 = series4.bullets.push(new am4charts.LabelBullet());
        bullet4.label.text = 'Q4';
        bullet4.locationY = 0.5;

        const bulletMininoQ4 = series4.bullets.push(new am4charts.LabelBullet());
        bulletMininoQ4.label.text = '{valueX}'; 
        bulletMininoQ4.locationY = 0.9;
        bulletMininoQ4.locationX = 0.9;


        const lineSeries = this.chartProdutividade.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = 'produtividade';
        lineSeries.dataFields.valueX = 'horaHomemProdutividade';
        lineSeries.dataFields.categoryX = 'category';
        lineSeries.strokeWidth = 0;

        const bulletLine = lineSeries.bullets.push(new am4charts.Bullet());
        bulletLine.layout = 'absolute';
        const line = bulletLine.createChild(am4core.Line);
        line.y1 = 0;
        line.x1 = -80;
        line.y2 = 0;
        line.x2 = 80;
        line.stroke = am4core.color('#000');
        line.strokeWidth = 4;

        const labelBulletLine = lineSeries.bullets.push(new am4charts.LabelBullet());
        labelBulletLine.label.text = '{valueX} / HH';

        labelBulletLine.label.dx = -30;
        labelBulletLine.label.dy = -10;
        labelBulletLine.locationX = 0.4;

        this.chartProdutividade.scrollbarX = new am4core.Scrollbar();
    }

    avaliacaoPerfeita() {
        const chart = am4core.create('chartdivAvaliacaoPerfeita', am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = 100;
        chart.legend = new am4charts.Legend();
        chart.data = [
            {
                category: 'OK',
                value: 80,
            },
            {
                category: 'NOK',
                value: 20,
            },
        ];


        const series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = 'value';
        series.dataFields.category = 'category';
        series.labels.template.disabled = true;
        series.ticks.template.disabled = true;
        series.colors.step = 1;
    }

    contagemHomemHora() {

        const chart = am4core.create('chartdivContagemHomemHora', am4charts.XYChart);
        chart.colors.step = 4;

        chart.data = [{
            year: '08:00',
            italy: 1,
            germany: 5,
            uk: 3
        }, {
            year: '09:00',
            italy: 1,
            germany: 2,
            uk: 6
        }, {
            year: '10:00',
            italy: 2,
            germany: 3,
            uk: 1
        }, {
            year: '11:00',
            italy: 3,
            germany: 4,
            uk: 1
        }, {
            year: '12:00',
            italy: 5,
            germany: 1,
            uk: 2
        }, {
            year: '13:00',
            italy: 3,
            germany: 2,
            uk: 1
        }, {
            year: '14:00',
            italy: 1,
            germany: 2,
            uk: 3
        }, {
            "year": "15:00",
            "italy": 2,
            "germany": 1,
            "uk": 5
        }, {
            "year": "16:00",
            "italy": 3,
            "germany": 5,
            "uk": 2
        }, {
            "year": "17:00",
            "italy": 4,
            "germany": 3,
            "uk": 6
        }, {
            "year": "18:00",
            "italy": 1,
            "germany": 2,
            "uk": 4
        }];

        // Create category axis
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.opposite = true;

        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inversed = true;
        valueAxis.title.text = "Contagem";
        valueAxis.renderer.minLabelPosition = 0.01;

        // Create series
        let series1 = chart.series.push(new am4charts.LineSeries());
        series1.dataFields.valueY = "italy";
        series1.dataFields.categoryX = "year";
        series1.name = "Próprios";
        series1.strokeWidth = 3;
        series1.bullets.push(new am4charts.CircleBullet());
        series1.tooltipText = "{name} in {categoryX}: {valueY}";
        series1.legendSettings.valueText = "{valueY}";
        series1.visible = false;

        let series2 = chart.series.push(new am4charts.LineSeries());
        series2.dataFields.valueY = "germany";
        series2.dataFields.categoryX = "year";
        series2.name = 'Terceiros';
        series2.strokeWidth = 3;
        series2.bullets.push(new am4charts.CircleBullet());
        series2.tooltipText = "{name} in {categoryX}: {valueY}";
        series2.legendSettings.valueText = "{valueY}";

        let series3 = chart.series.push(new am4charts.LineSeries());
        series3.dataFields.valueY = 'uk';
        series3.dataFields.categoryX = 'year';
        series3.name = 'Média';
        series3.strokeWidth = 3;
        series3.bullets.push(new am4charts.CircleBullet());
        series3.tooltipText = "{name} in {categoryX}: {valueY}";
        series3.legendSettings.valueText = "{valueY}";

        // Add chart cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'zoomY';

        // Add legend
        chart.legend = new am4charts.Legend();
    }

    


    contagemEnderecoHora() {
        // Create chart instance
        let chart = am4core.create("chartdivContagemEnderecoHora", am4charts.XYChart);
        chart.padding(40, 40, 40, 40);

        // Export
        //chart.exporting.menu = new am4core.ExportMenu();

        // Data for both series
        let data = [{
            "year": "08:00",
            "picking": 3.5,
            "pulmao": 23.5,
            "outros": 0.5,
            "expenses": 21.1
        }, {
            "year": "09:00",
            "picking": 3.5,
            "pulmao": 13.5,
            "outros": 1.5,
            "expenses": 30.5
        }, {
            "year": "10:00",
            "picking": 21.5,
            "pulmao": 32.5,
            "outros": 2.5,
            "expenses": 34.9
        }, {
            "year": "11:00",
            "picking": 25.5,
            "pulmao": 26.5,
            "outros": 7.5,
            "expenses": 31.1
        }, {
            "year": "12:00",
            "picking": 29.5,
            "pulmao": 21.5,
            "outros": 2.5,
            "expenses": 28.2,
            "lineDash": "5,5",
        }, {
            "year": "13:00",
            "picking": 23.5,
            "pulmao": 21.5,
            "outros": 3.5,
            "expenses": 32.9,
            "strokeWidth": 1,
            "columnDash": "5,5",
            "fillOpacity": 0.2,
            "additional": "(projection)"
        }];

 
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.minGridDistance = 60;

 
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());


        const columnSeries1 = chart.series.push(new am4charts.ColumnSeries());
        columnSeries1.name = "Picking";
        columnSeries1.dataFields.valueY = "picking";
        columnSeries1.dataFields.categoryX = "year";
        columnSeries1.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        columnSeries1.columns.template.propertyFields.fillOpacity = "fillOpacity";

        const columnSeries2 = chart.series.push(new am4charts.ColumnSeries());
        columnSeries2.name = "Pulmão";
        columnSeries2.dataFields.valueY = "pulmao";
        columnSeries2.dataFields.categoryX = "year";
        columnSeries2.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        columnSeries2.columns.template.propertyFields.fillOpacity = "fillOpacity";

        const columnSeries3 = chart.series.push(new am4charts.ColumnSeries());
        columnSeries3.name = "Outros";
        columnSeries3.dataFields.valueY = "outros";
        columnSeries3.dataFields.categoryX = "year";
        columnSeries3.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        columnSeries3.columns.template.propertyFields.fillOpacity = "fillOpacity";


        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.name = "Total";
        lineSeries.dataFields.valueY = "expenses";
        lineSeries.dataFields.categoryX = "year";
        lineSeries.stroke = am4core.color("#fdd400");
        lineSeries.strokeWidth = 3;
        lineSeries.propertyFields.strokeDasharray = "lineDash";
        lineSeries.tooltip.label.textAlign = "middle";

        let bullet = lineSeries.bullets.push(new am4charts.Bullet());
        bullet.fill = am4core.color("#fdd400");
        bullet.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
        let circle = bullet.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color("#fff");
        circle.strokeWidth = 3;

        const latitudeLabel = lineSeries.bullets.push(new am4charts.LabelBullet());
        latitudeLabel.label.text = "{expenses}";
        latitudeLabel.label.horizontalCenter = "left";
        latitudeLabel.label.dx = 14;

        chart.data = data;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.responsive.enabled = true;
    }

    submit() {
        throw new Error('Method not implemented.');
    }
    getComponentName(): string {
        throw new Error('Method not implemented.');
    }

    onSelectTabSituacao(data: TabDirective): void {
        this.valueTabSituacao = data.heading;
    }

    endPointSituacao() {
        let dateIni = '';
        let dateFin = '';
        if (this.formFilters.get('initDate').value) {
            let date = new Date(this.formFilters.get('initDate').value).toLocaleDateString();
            date = date.split('/').reverse().join('-');
            dateIni = date;
        }

        if (this.formFilters.get('finDate').value) {
            let date = new Date(this.formFilters.get('finDate').value).toLocaleDateString();
            date = date.split('/').reverse().join('-');
            dateFin = date;
        }
        const scheduleNumber = this.formFilters.get('scheduleNumber').value || '';
        const depositor = this.formFilters.get('depositor').value || '';
        const provider = this.formFilters.get('provider').value || '';


        return `/wrapper/user-domain`;
    }

    gridAutoLoad() {
        if (this.isAutoLoadGrid && this.countLoad === 0) {
            this.countLoad++;
            return true;
        }
        return false;
    }

    selectedCollect(row: CollectSchedules) {
        this.openModalDetail(this.modalDetail, row);
    }

    openModal(template: TemplateRef<any>) {
        this.modalRefDetail = this.modalService.show(template);
    }

    openModalDetail(template: TemplateRef<any>, row: CollectSchedules) {
        this.rowDetail = row;
        this.modalRefDetail = this.modalService.show(template, { class: 'gray modal-lg' });
    }

    arrowClass(value: string) { 
        if (value === 'up') {
            return {
                'fa fa-arrow-up': true,
                'text-success': true,

            };
        } else {
            return {
                'fa fa-arrow-down': true,
                'text-danger': true,

            };
        }

    }


}
