import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from "lodash";
import { AuthService } from '../services/auth.service';
import { debug } from 'util';

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    clone;

    constructor(public jwtHelper: JwtHelperService, public authService: AuthService) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        solution?: String,
        roles?: String[],
        integration?: boolean,
        authority?: String,
        text?: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        name?: string,
        submenu?: Array<any>
    }>) {
        this.clearMenu();
        this.clone = _.cloneDeep(items);

        let user = undefined;

        setTimeout(() => {
            user = this.authService.getUser();
            if (user) {
                this.checkItem(this.clone, user).forEach(item => {
                    this.menuItems.push(item);
                });
            }
        }, 50);
    }

    setMenu(menu) {
        menu.forEach(item => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return (this.menuItems.length > 0) ? this.menuItems : this.clone;
    }

    clearMenu() {
        this.menuItems = [];
    }

    checkItem(items: Array<any>, user): Array<any> {
        if (items != undefined) {
            let i = 0;
            let end = items.length;
            for (i = 0; i < end; i++) {
                let item = items[i];
                if (this.hasSolution(item.solution, user) &&
                    (this.hasAuthority(item.authority, user) || this.hasRole(item.roles, user)) &&
                    this.hasScreen(item.name, user) &&
                    this.showIntegration(item.integration, user)) {
                    item.submenu = this.checkItem(item.submenu, user);

                } else {
                    items.splice(i, 1);
                    i--;
                    end--;
                }
            }
        }
        return items;
    }

    hasSolution(code: String, user): boolean {
        if (code == null || code == undefined)
            return false;
        if (user.solutions) {
            for (let solution of user.solutions) {
                if (solution.code === code || code.toUpperCase() === "GATEWAY")
                    return true;
            }
        }

        return false;
    }

    hasAuthority(authority: String, user) {
        if (authority === 'USER' && (user.authority.code === authority || user.authority.code === 'ADMIN' || user.authority.code === 'SUPER')) {
            return true;
        }
        else if (authority === 'ADMIN' && (user.authority.code === authority || user.authority.code === 'SUPER')) {
            return true;
        }
        else if (authority === user.authority.code) {
            return true;
        }
        return false;
    }

    hasScreen(screenName: any, user: any): boolean {
        if (!screenName)
            return true;
        if (user.authority.screens.find(x => x.screen.screenName === screenName))
            return true;
        if (user.roles.find(x => x.screens.find(x => x.screen.screenName === screenName)))
            return true;
        if (screenName == 'Picking_Sequence')
            return true;

        return false;
    }

    hasRole(roles: String[], user): boolean {
        if (roles == null || roles == undefined || roles.length == 0)
            return true;

        let check = roles.length;
        let checked = 0;

        for (let role of roles) {
            for (let userRole of user['roles']) {

                if (role === userRole.code) {
                    checked++;
                }

                if (this.check(check, checked))
                    return true;
            }
        }
        return false;
    }

    check(check: number, checked: number): boolean {
        return (check == checked);
    }

    showIntegration(integration, user) {
        return user.integration && integration != undefined ? (integration) : true;
    }

}