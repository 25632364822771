import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { isNullOrUndefined } from 'util';

const INPUT_CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputCheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
  providers: [INPUT_CHECKBOX_VALUE_ACCESSOR]
})
export class InputCheckboxComponent
  implements ControlValueAccessor, AfterViewInit, OnInit {
  @Input() label: string;
  @Input() isForm: boolean = true;
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() disabled = false;
  @Input() isCenter = false;

  @Output() inputChecked = new EventEmitter();

  @ViewChild('checkBox') checkBox: ElementRef;

  private _innerValue: any;

  constructor(private _renderer: Renderer2) {}

  set value(value) {
    if (value !== this._innerValue) {
      let oldValue = this._innerValue;
      this._innerValue = value;
      this.onChangeCb(value);
      if (!isNullOrUndefined(this._innerValue)) {
        this.inputChecked.emit(value);
      }
    }
  }

  get value() {
    return this._innerValue;
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

    this.disabled = isDisabled;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }
}
