import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { RegularDeliveryRequestMax } from '../../../models/sdm/regular-delivery/regular-delivery-request-max';
import { AbstractService } from '../../abstract.service';

@Injectable()
export class RegularDeliveryRequestMaxService extends AbstractService<RegularDeliveryRequestMax> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.regularDelivery.requestMax.default);
  }

  createRegularDeliveryReqMax(model: RegularDeliveryRequestMax[]): Observable<RegularDeliveryRequestMax> {
    return this.http.post<RegularDeliveryRequestMax>(SDM_URL.regularDelivery.requestMax.create, model);
  }

  findByDepositorId(depositorId: number, userId: number): Observable<RegularDeliveryRequestMax> {
    return this.http.get<RegularDeliveryRequestMax>(`${SDM_URL.regularDelivery.requestMax.findByDepositorId.replace(':depositorId', depositorId.toString()).replace(':userId', userId.toString())}`);
  }

}
