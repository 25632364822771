import { Legend } from './../../../../../components/legend/legend';
import { DateFormatPipe } from './../../../../../core/classes/datePipe';
import { CollectSchedules } from './../../../../../core/models/sdm/schedules/collect-schedules';
import { HttpResponse } from '@angular/common/http';
import { CollectSchedulesService } from './../../../../../core/services/sdm/schedules/collect-schedules.service';
import { OpNatureAuthorizationEnum } from './../../../../../core/models/sdm/schedules/reverse-delivery/enums/op-nature-authorization-enum.enum';
import { AuthService } from './../../../../../core/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeliveryAuthorizationService } from './../../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { Router, NavigationExtras } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  SecurityContext,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Provider } from '../../../../../core/models/sdm/provider-management/provider';
import { DeliveryAuthorization } from '../../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { Subscription } from 'rxjs';
import { CollectScheduleCancel } from '../../../../../core/models/sdm/schedules/collect-schedule-cancel';
import { SchedulesConflictDTO } from '../../../../../core/models/sdm/schedules/schedules-conflict-dto';
import { AbstractDockManagementEdit } from '../../../../dock-management/abstract-dock-management-edit';
import { Location } from '@angular/common';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { PriorVisualizationService } from '../../../../../core/services/sdm/docks/prior-visualization.service';
import { SelectFieldComponent } from '../../../../../components/select/select-field/select-field.component';

const UPDATE_SCHEDULE = '1';

@Component({
  selector: 'app-collect-edit',
  templateUrl: './collect-edit.component.html',
  styleUrls: ['./collect-edit.component.scss'],
  preserveWhitespaces: true
})
export class CollectEditComponent
  extends AbstractDockManagementEdit<CollectSchedules>
  implements OnInit {
  formEdit: FormGroup;
  formEnabled = true;

  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;

  bsModalRef: BsModalRef;

  depositors: any[] = [];
  providers: Provider[] = [];
  deliveryAuthorizations: DeliveryAuthorization[] = [];
  docks: PriorVisualization[] = [];
  docksDepositor: PriorVisualization[] = [];

  alerts: any = [];
  dismissible = true;

  subscriptions: Subscription[] = [];
  vehiclesDepositor = [];

  collectEdit: CollectScheduleCancel;

  @ViewChild('template') template1: TemplateRef<any>;
  @ViewChild('depositor') depositor: SelectFieldComponent;

  rowsDetails = [];
  conflicts = 0;
  conflictRecurrent = false;

  selected: any[] = [];

  optionsOperationStatus = [
    {
      name: 'reschedule-various-option.operationStatus.options.available',
      value: OpNatureAuthorizationEnum.DISPONIVEL
    },
    {
      name: 'reschedule-various-option.operationStatus.options.blocked',
      value: OpNatureAuthorizationEnum.BLOQUEADO
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeOne',
      value: OpNatureAuthorizationEnum.TIPO_1
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeTwo',
      value: OpNatureAuthorizationEnum.TIPO_2
    }
  ];

  // columnsDetails: object[] = [
  //   { prop: 'authorizationKey', name: 'Agendamento', width: 180 },
  //   { prop: 'operationNature', name: 'Natureza da Operação' },
  //   // { prop: 'operationSubNature', name: 'Sub-Natureza' },
  //   { prop: 'depositor.detailName', name: 'Depositante' },
  //   { prop: 'provider.shortName', name: 'Fornecedor' },
  //   { prop: 'ini', name: 'Hora inicial' },
  //   { prop: 'fin', name: 'Hora final' },
  //   { prop: 'date', name: 'Data', pipe: new DateFormatPipe('en-US') }
  // ];

  // configLegend: Legend[] = [
  //   {
  //     color: '#f54542',
  //     text: 'Conflito',
  //     tooltip: 'será gerado conflito'
  //   },
  //   {
  //     color: `#f57242`,
  //     text: 'Feriado',
  //     tooltip: 'Horários e dias com feriado'
  //   },
  //   {
  //     color: `#f5bf42`,
  //     text: 'Dia não operacional',
  //     tooltip: 'Data não operacional'
  //   }
  // ];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    sanitizer: DomSanitizer,
    public modalService: BsModalService,
    private _authService: AuthService,
    public collectSchedulesService: CollectSchedulesService,
    public location: Location,
    private _priorVisualizationService: PriorVisualizationService
  ) {
    super(sanitizer, location);
  }

  ngOnInit() {
    this.collectEdit = this._route.snapshot.data['collectSchedule'];
    
    this.depositors = [this.collectEdit.depositor];

    if (!this.depositors.length) {
      this.depositor.items = this.depositors.map(
        v => (v['shortName'] = 'Geral')
      );
      this.depositor.bindLabel = 'shortName';
      this.formEdit.get('depositor').setValue(this.depositor.items[0]);
    }

    this.providers = [this.collectEdit.docksScheduling.provider];
    this.deliveryAuthorizations = [
      this.collectEdit.docksScheduling.deliveryAuthorization
    ];

    this.setDocks();
    // this.docks = [this.collectEdit.docksScheduling.priorVisualization];

    this.formEdit = new FormGroup({
      depositor: new FormControl(this.depositors[0]),
      depositorId: new FormControl(''),
      provider: new FormControl(this.collectEdit.docksScheduling.provider),
      providerId: new FormControl(''),
      establishmentId: new FormControl(
        this.collectEdit['collectSchedules'].establishmentId
      ),
      deliveryAuthorization: new FormControl(this.collectEdit),
      dateScheduleDelivery: new FormControl(
        this.collectEdit.docksScheduling.dateScheduleDelivery
      ),
      carShowSchedule: new FormControl(
        this.collectEdit.docksScheduling.carShowSchedule
      ),
      iniHour: new FormControl(this.collectEdit.docksScheduling.initialHour),
      finHour: new FormControl(this.collectEdit.docksScheduling.finalHour),
      priorVisualization: new FormControl(
        this.collectEdit.docksScheduling.priorVisualization
      ),

      dateScheduleDeliveryNew: new FormControl('', [Validators.required]),
      initialHourNew: new FormControl('', [Validators.required]),
      finalHourNew: new FormControl('', [Validators.required]),
      priorVisualizationNew: new FormControl(
        this.collectEdit.docksScheduling.priorVisualization,
        [Validators.required]
      ),
      updateSchedule: new FormControl(UPDATE_SCHEDULE),
      actionType: new FormControl(''),
      obs: new FormControl(this.collectEdit['collectSchedules'].obs),
      obsNew: new FormControl(''),
      dates: new FormControl([]),
      vehicleTypeId: new FormControl()
    });

    [
      'depositorId',
      'providerId',
      'depositor',
      'provider',
      'vehicleTypeId',
      'deliveryAuthorization',
      'dateScheduleDelivery',
      'priorVisualization',
      'carShowSchedule',
      'iniHour',
      'finHour',
      'obs'
    ].forEach(field => {
      this.formEdit.get(field).disable();
    });

    this.listenValueChanges('depositor', 'depositorId');
    this.listenValueChanges('provider', 'providerId');
    this.selectedDepositor(this.formEdit.getRawValue()['depositor']);
  }

  listenValueChanges(objKeyValue: string, fieldKey: string) {
    this.formEdit.get(objKeyValue).valueChanges.subscribe(value => {
      if (value) {
        this.formEdit.get(fieldKey).setValue(value.id);
        return;
      }

      this.formEdit.get(fieldKey).setValue('');
    });

    this.formEdit.get('updateSchedule').valueChanges.subscribe(value => {
      if (value == 1) this.conflictRecurrent = false;
    });
  }

  setDocks() {
    this.docks = [this.collectEdit.docksScheduling.priorVisualization];

    if (this.docks.length) {
      this.docks = this.docks.map(value => {
        value['fullName'] = `${value['signatureDock']} - (${
          value['initialHour']
        } - ${value['finalHour']})`;
        return value;
      });
    }
  }

  isInternalUser() {
    let isInternal =
      this._authService.getUserRoles().filter(r => /Internal/gi.test(r.type)) ||
      [];
    return isInternal.length > 0;
  }

  goBack() {
    this._router.navigate(['sdm/register/schedules/collect']);
  }

  selectedDepositor(depositor: Depositor) {
    
    if (depositor) {
      this._priorVisualizationService
        .findByEstablishmentIdAndDepositorIdAndCollectTrue(
          depositor.id,
          depositor.establishment.id
        )
        .subscribe(data => {
          this.setDocksDepositor(data);
        });
    } else {
      this._priorVisualizationService
        .findByEstablishmentIdAndDepositorIdAndCollectTrue(
          0,
          this.collectEdit['collectSchedules']['establishment'].id
        )
        .subscribe(data => {
          this.setDocksDepositor(data);
        });
    }
  }

  setDocksDepositor(data: PriorVisualization[]) {
    
    if (data) {
      this.docksDepositor = data;

      if (this.docksDepositor.length) {
        this.docksDepositor = data.map(value => {
          return this.setFullName(value);
        });
      }

      if (this.docksDepositor.length === 1) {
        this.formEdit
          .get('priorVisualizationNew')
          .setValue(this.docksDepositor[0]);
      }
    } else {
      this.docksDepositor = [];
      this.formEdit.get('priorVisualizationNew').setValue(this.docksDepositor);
    }
  }

  private setFullName(value: PriorVisualization) {
    value['fullName'] = `${value['signatureDock']} - (${
      value['initialHour']
    } - ${value['finalHour']})`;
    return value;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  submit() {
    if (this.formEdit.get('updateSchedule').value == 2) {
      this.conflictRecurrent = true;
      return;
    }

    if (this.formEdit.valid) {
      const formValue = this.formEdit.getRawValue();

      

      this.collectSchedulesService
        .validateRecurrentScheduleOnUpdate(formValue)
        .subscribe(
          (data: HttpResponse<SchedulesConflictDTO[]>) => {
            

            if (data) {
              if (data.status == 200) {
                this.rowsDetails = data.body;
                this.selected = this.rowsDetails.filter(row => row['conflict']);
                this.conflicts = this.selected.length;
                this.openModal(this.template1);
                return;
              }

              if (data.status == 202) {
                const dates = data.body.map(date => date['date']);
                this.formEdit.get('dates').setValue(dates);

                this.updateSchedulingCollect();
              }
            }
          },
          error => {
            
            this.alertMsg(error.error || error.message, 'danger');
          }
        );
    } else {
      this.verificaValidacoesForm(this.formEdit);
    }
  }

  updateSchedulingCollect() {
    const formValue = this.formEdit.getRawValue();

    this.subscriptions.push(
      this.collectSchedulesService.updateCollectScheduling(formValue).subscribe(
        () => {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              message: 'Agendamento(s) alterados com sucesso.'
            },
            skipLocationChange: true
          };
          this._router.navigate(
            ['sdm/register/schedules/collect'],
            navigationExtras
          );
        },
        error => this.alertMsg(error, 'danger')
      )
    );
  }

  closeModalConfirmConflicts() {
    this.modalRef.hide();
  }

  updateScheduleWithConflict() {
    if (!this.selected.length) {
      return;
    }

    let dates = [];
    let datesWithoutConflict = [];

    dates = this.selected
      .filter(row => row['conflict'])
      .map(row => row['date']);

    // datesWithoutConflict = this.rowsDetails
    //   .filter(
    //     row => !row['conflict'] && !row['holiday'] && !row['notOperational']
    //   )
    //   .map(row => row['date']);

    
    

    this.formEdit.get('dates').setValue(dates);
    // this.formEdit.get('datesWithoutConflict').setValue(datesWithoutConflict);

    

    this.closeModalConfirmConflicts();

    const formValue = this.formEdit.getRawValue();

    this.collectSchedulesService.updateScheduleToConflict(formValue).subscribe(
      data => {
        if (data) {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              message:
                'Agendamento(s) atualizado(s) com sucesso, porém existem conflitos há serem resolvidos.'
            },
            skipLocationChange: true
          };
          this._router.navigate(
            ['sdm/register/schedules/collect'],
            navigationExtras
          );
        }
      },
      error => {
        this.alertMsg(error, 'danger');
        
      }
    );
  }

  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'second modal-lg'
    });
  }

  getRowClass(row) {
    return {
      'schedule-ok': !row.conflict && !row.holiday && !row.notOperationl,
      'conflict-row': row.conflict,
      'holiday-color': row.holiday,
      'not-operational': row.notOperationl
    };
  }

  getService(): AbstractService<CollectSchedules> {
    return this.collectSchedulesService;
  }
}
