import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, Router, RoutesRecognized } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from "lodash";
import { AuthService } from '../core/services/auth.service';
import { menuSdm } from '../routes/menu';
import { menuGateway } from '../routes/menu-gateway';
import { menuSbaw } from '../routes/menu-sbaw';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public location: Location, public router: Router, public auth: AuthService, public jwtHelper: JwtHelperService) { }

  authorities = [
    {
      value: 3,
      name: 'SUPER'
    },
    {
      value: 2,
      name: 'ADMIN'
    },
    {
      value: 1,
      name: 'USER'
    }
  ];

  canActivate(): boolean {
    let user = this.auth.getUser();
    let url = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        if (event.url.endsWith('/login'))
          return true;

        if (!this.isAuthenticated()) {
          this.router.navigate(['/login']);
          return false;
        }

        if (event.url.endsWith('/gateway/home'))
          return true;
        if (!this.validRoute(event, user)) {
          this.router.navigate([url]);
          return false;
        }
      }
    });
    return true;
  }

  getAuthorityValue(name) {
    let authority = this.authorities.find(x => x.name == name.toUpperCase());
    return authority ? authority.value : 0;
  }

  getMenu(event: any) {
    if (event.url.toLowerCase().search('gateway') > -1) {
      return _.cloneDeep(menuGateway);
    } else if (event.url.toLowerCase().search('sdm') > -1) {
      return _.cloneDeep(menuSdm);
    } else if (event.url.toLowerCase().search('sbaw') > -1) {
      return _.cloneDeep(menuSbaw);
    }
    return _.cloneDeep(menuGateway);
  }

  findRoute(event: any, menu: any): any {
    for (let item of menu) {
      if (item.link && event.url.search(item.link) > -1)
        return item;
      if (item.submenu) {
        let route = this.findRoute(event, item.submenu);
        if (route)
          return route;
      }
    }
    return;
  }

  validRoute(event, user) {
    if (user) {
      if (user.authority.code == 'SUPER')
        return true;
      if (this.invalidSolution(event, user))
        return false;
      if (this.invalidIntegration(event, user))
        return false;
      let menu = this.getMenu(event);
      if (!menu)
        return false;
      let menuRoute = this.findRoute(event, menu);
      if (!menuRoute)
        return false;
      if (this.invalidAuthority(user, menuRoute))
        return false;
      if (this.invalidRoles(user, menuRoute))
        return false;
    }
    return true;
  }

  invalidAuthority(user: any, menuRoute: any): boolean {
    return this.getAuthorityValue(user.authority.code) < this.getAuthorityValue(menuRoute.authority);
  }

  invalidRoles(user: any, menuRoute: any): boolean {
    if (!menuRoute.name)
      return false;
    
    if (menuRoute.name == 'Picking_Sequence') {
      return false;
    }

    if (user.authority.screens.find(x => x.screen.screenName === menuRoute.name)){
      return false;
    }
  
    if (user.roles.find(x => x.screens.find(x => x.screen.screenName === menuRoute.name))){
      return false;
    }

    return true;
  }

  invalidIntegration(event: any, user: any): boolean {
    return false;
  }

  invalidSolution(event, user): boolean {
    if (event.url.toUpperCase().search('GATEWAY'))
      return false;
    for (let solution of user.solutions)
      if (event.url.toUpperCase().search(solution.code) == 1)
        return false;
    return true;
  }

  isAuthenticated(): boolean {
    return (this.auth.isAuthenticated()) ? this.refreshIfNecessary() : this.logout();
  }

  refreshIfNecessary(): boolean {
    this.auth.refreshIfNecessary();
    return true;
  }

  logout(): boolean {
    this.auth.logout();
    return false;
  }

}