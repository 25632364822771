import { DeliveryAuthorization } from './../delivery-authorization';
import { Depositor } from './../../depositor';
import { AbstractStatusModel } from '../../../abstractStatus.model';
import { NumberMeasure } from '../../../../../shared/directives/numberMeasure/numberMeasure.directive';
import { Provider } from '@angular/compiler/src/core';
import { Vehicle } from '../../vehicle';

export class DimensioningInfo extends AbstractStatusModel {

  public depositorId: number;
  public providerId: number;

  public authorizationKey: string;
  public providerIdDimensioning: number;

  public productivityGroup: number;
  public vehicleTypeId: number;
  public freightCubing: number;
  public totalWeight: number;

  // public documentSender: string;
  // public documentRecipient: string;
  public documentShipping: string;
  // public nfNumber: number;
  // public nfSerie: number;
  // public emissionDate: string;
  // public orderNumber: number;
  public totalQtdItems: number;
  public totalQtdUnit: number;
  public totalValueNf: number;
  public totalGrossWeight: number;
  public totalNetWeight: number;
  public qtdVol: number;
  public deliveryAuthorizationId: number;

  public qtdInMinutesProductivityGroups: number;
  public qtdSlots: number;
  public allowDeliverySmallSize: boolean;

  public joinInformationFinish: boolean;
  public validityDate: string;

  public depositor: Depositor;
  public provider: Provider;
  public vehicle: Vehicle;

  public deliveryAuthorization: DeliveryAuthorization;

  constructor() {
    super();
  }

}
