/**
 * An object used to get page information from the server
 */
export class Page {
  public endpoint = '';
  // The number of elements in the page
  public size = 10;
  // The total number of elements
  public totalElements = 0;
  // The total number of pages
  public total = 0;
  // The current page number
  public pageNumber = 0;
  // The name of field by sorting
  public sortName = 'id';
  // order DESC / ASC
  public sortOrder = 'desc';

  public first = 0;

  public pageCount = 0;

  constructor() {

    this.endpoint = '';
    this.size = 10;
    this.totalElements = 0;
    this.total = 0;
    this.pageNumber = 0;
    this.sortName = 'id';
    this.sortOrder = 'desc';
    this.first = 0;
    this.pageCount = 0;
  }

  generateURL(): string {
    return this.endpoint.includes("?") ? `${this.endpoint}size=${this.size}&page=${this.pageNumber}&sort=${this.sortName},${this.sortOrder}` : `${this.endpoint}?size=${this.size}&page=${this.pageNumber}&sort=${this.sortName},${this.sortOrder}`;
  }
}
