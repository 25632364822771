import { PriorVisualization } from './prior-visualization';
import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class DockCompatibility extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public depositorId: number;
  public sdmDock: number;
  public signatureDock: number;
  public regularDelivery: boolean;
  public reverseDelivery: boolean;
  public collect: boolean;

  public priorVisualization: PriorVisualization;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
