import { AbstractStatusModel } from '../../abstractStatus.model';
import { ByTimeUnitEnum } from '../shared/enums/ByTimeUnitEnum';

export class ProductivityPattern extends AbstractStatusModel {

  public establishmentId: number;
  public description: string;

  public fixWindowDischargeOp: boolean;
  public timeInMinutesDischargeOp: number;
  public setupDischargeOp: boolean;
  public timeInMinutesSetupDischargeOp: number;

  public fixWindowConferenceOp: boolean;
  public timeInMinutesConferenceOp: number;
  public setupConferenceOp: boolean;
  public timeInMinutesSetupConferenceOp: number;

  public firstPQuantity: number;
  public firstPMeasureUnit: string;
  public firstpByTimeUnit: string;

  public secondPQuantity: number;
  public secondPMeasureUnit: string;
  public secondPByTimeUnit: string;

  public thirdPQuantity: number;
  public thirdPMeasureUnit: string;
  public thirdPByTimeUnit: string;

  public fourthPQuantity: number;
  public fourthPMeasureUnit: string;
  public fourthPByTimeUnit: string;

  public fifthPQuantity: number;
  public fifthPMeasureUnit: string;
  public fifthPByTimeUnit: string;

  public sixPQuantity: number;
  public sixPMeasureUnit: string;
  public sixPByTimeUnit: string;

  constructor() {
    super();
    this.firstpByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.secondPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.thirdPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.fourthPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.fifthPByTimeUnit = ByTimeUnitEnum.DEFAULT;
    this.sixPByTimeUnit = ByTimeUnitEnum.DEFAULT;
  }

}
