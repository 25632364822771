import { AbstractModel } from "./abstract.model";

export abstract class AbstractStatusModel extends AbstractModel {

  private _status: boolean;

  set status(status: boolean) {
    this._status = status;
  }

  get status() {
    return this._status;
  }

  public delete(): void {
    this.status = false;
  }

  public active(): void {
    this.status = true;
  }

}