import { AbstractStatusModel } from '../../abstractStatus.model';
import { IDeserializable } from '../../deserializable.model';

export class RegularDeliveryRequestMax extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public requestMaxNf = 0;
  public maxQtdLnfIssued = 0;
  public minValueByNf = 0;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
