import { take } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../abstract.service';
import { environment } from './../../../../../environments/environment';
import { SDM_URL } from './../../../../app.api';
import { CollectSchedules } from './../../../models/sdm/schedules/collect-schedules';

@Injectable({
  providedIn: 'root'
})
export class CollectSchedulesService extends AbstractService<CollectSchedules> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.schedules.collect.default);
  }

  createWithConflict(
    collectSchedules: CollectSchedules
  ): Observable<CollectSchedules> {
    return this.http.post<CollectSchedules>(
      `${SDM_URL.schedules.collect.createWithConflict}`,
      collectSchedules
    );
  }

  updateScheduleToConflict(collectSchedule: CollectSchedules) {
    return this.http.put(
      `${SDM_URL.schedules.collect.updateCollectionWithConflict}`,
      collectSchedule
    );
  }

  updateWithConflict(
    collectSchedules: CollectSchedules
  ): Observable<HttpResponse<CollectSchedules>> {
    return this.http.put<CollectSchedules>(
      `${SDM_URL.schedules.collect.updateWithConflict}/${collectSchedules.id}`,
      collectSchedules,
      { observe: 'response' }
    );
  }

  validateRecurrentSchedule(collectSchedules: CollectSchedules) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${SDM_URL.schedules.collect.validateRecurrentSchedule}`,
      collectSchedules,
      { headers: httpOptions, observe: 'response' }
    );
  }

  validateRecurrentScheduleOnUpdate(collectScheduling: CollectSchedules) {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${SDM_URL.schedules.collect.validateRecurrentScheduleOnUpdate}`,
      collectScheduling,
      { headers: httpOptions, observe: 'response' }
    );
  }

  updateCollectScheduling(collectScheduling: CollectSchedules) {
    return this.http.put(
      `${SDM_URL.schedules.collect.updateCollectSchedules}`,
      collectScheduling
    );
  }

  createReverseRecurrent(collectSchedules: CollectSchedules) {
    return this.http.post(
      `${SDM_URL.schedules.collect.createReverseRecurrent}`,
      collectSchedules
    );
  }

  findByRecurrent(deliveryAuthorizationId: number): Observable<any> {
    return this.http.get<any>(
      `${this.host}${
        this.url
      }/delivery-authorization/${deliveryAuthorizationId}/recurrent`
    );
  }

  findBySearchCollectExport(queryString: string): Observable<Blob> {
    return this.http
      .get(
        `${
          SDM_URL.schedules.collect.findBySearchCollectExport
        }?${queryString}&`,
        { responseType: 'blob' }
      )
      .pipe(take(1));
  }
}
