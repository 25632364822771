import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'model-status',
  templateUrl: './model-status.component.html',
  styleUrls: ['./model-status.component.scss']
})
export class ModelStatusComponent implements OnInit {

  @Input() statusRegister: Boolean = false;
  @Input() checkDisabled: Boolean = false;
  @Output() toggleAct = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  toogleActive(e) {
    this.statusRegister = e.target.checked ? true : false
    this.toggleAct.emit(e.target.checked)
  }

}
