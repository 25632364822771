import { ReverseDeliveryEvents } from './../../reverse-delivery.service';
import { ReverseDeliverySmallSizeService } from './../../../../../core/services/sdm/reverse-delivery/reverse-delivery-small-size.service';
import { SDM_URL } from './../../../../../app.api';
import { Location } from '@angular/common';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../../../../../shared/base-form/base-form.component';
import { ReverseDeliverySmallSize } from '../../../../../core/models/sdm/reverse-delivery/reverse-delivery-small-size';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { delay, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AbstractDelivery } from '../../../regular-delivery/abstract-delivery';
import { AbstractService } from '../../../../../core/services/abstract.service';

@Component({
  selector: 'app-reverse-delivery-small-size-form',
  templateUrl: './reverse-delivery-small-size-form.component.html',
  styleUrls: ['./reverse-delivery-small-size-form.component.scss'],
})
export class ReverseDeliverySmallSizeFormComponent extends AbstractDelivery<ReverseDeliverySmallSize> implements OnInit {

  depositors = [];
  sub: Subscription;
  deliveryInSpecific = false;
  reverseDeliverySmallSize: ReverseDeliverySmallSize;
  @ViewChild('dataGrdSmallSize') grid: DatatableApiComponent;
  options = [{ name: 'reverse-delivery.small-size.options.true', value: true }, { name: 'reverse-delivery.small-size.options.false', value: false }];

  columnsSmallSize = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'depositorDeliverySmallSize', name: 'P.Porte S/Agendamento', pipe: new BooleanConverter() },
    { prop: 'maxDurationDeliveryInMinutes', name: 'D.MAX da Entrega (min)' },
    { prop: 'specificDelivery', name: 'Doca Específica (Fixa)', pipe: new BooleanConverter() },
    { prop: 'docaNumber', name: '# Doca' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _reverseDeliverySmallSize: ReverseDeliverySmallSizeService,
    public reverseDeliveryServiceEvents: ReverseDeliveryEvents) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.tabSmallSizeFired();

    this.reverseDeliverySmallSize = new ReverseDeliverySmallSize();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorDeliverySmallSize: new FormControl(this.reverseDeliverySmallSize.depositorDeliverySmallSize),
      maxDurationDeliveryInMinutes: new FormControl('', [Validators.required, Validators.min(1), Validators.max(999)]),
      specificDelivery: new FormControl(''),
      docaNumber: new FormControl(''),
    });
  }

  tabSmallSizeFired() {
    this.sub = this.reverseDeliveryServiceEvents.emitTabSmallSizeFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.get('docaNumber').disable();
    this.formulario.patchValue(this.reverseDeliverySmallSize);
    this.statusFieldsSmallSize();
  }

  changeDeliveryDoca(event) {
    this.statusDeliveryDockField();
  }

  specificDockIsRequired = () => this.formulario.get('specificDelivery').value === 'true';
  depositorDeliverySmallSizeIsRequired = () => this.formulario.get('depositorDeliverySmallSize').value === 'true';

  changeDepositorDeliverySmallSize(e) {
    this.statusFieldsSmallSize();
  }

  statusFieldsSmallSize() {
    if (this.formulario.get('depositorDeliverySmallSize').value === 'true' || this.formulario.get('depositorDeliverySmallSize').value === true) {
      this.setRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
      this.enableFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
      return;
    }
    this.removeRequiredForFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
    this.disableFields(['maxDurationDeliveryInMinutes', 'specificDelivery']);
  }

  statusDeliveryDockField() {
    if (this.formulario.get('specificDelivery').value === 'true') {
      this.setRequiredForFields(['docaNumber']);
      this.enableFields(['docaNumber']);
      return;
    }
    this.removeRequiredForFields(['docaNumber']);
    this.disableFields(['docaNumber']);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.statusFieldsSmallSize();
    this.statusDeliveryDockField();
  }

  makeEndPointForSmallSize = () => SDM_URL.reverseDelivery.smallSize.default;

  getComponentName(): string {
    return 'reverse-delivery.small-size';
  }

  getNewInstance(): ReverseDeliverySmallSize {
    return new ReverseDeliverySmallSize();
  }

  getService(): AbstractService<ReverseDeliverySmallSize> {
    return this._reverseDeliverySmallSize;
  }

  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  validateDepositorDeliverySmallSize(event, inputController) {
    let inputValue = event.target.value;
    if (inputValue < 1 || (!(inputValue % 1 === 0)) || inputValue.length > 4) {
      this.formulario.get(inputController).setErrors({ pattern: true });
    }
    else {
      this.formulario.get(inputController).clearValidators();
      this.formulario.get(inputController).updateValueAndValidity();
    }
  }



}
