import { AbstractStatusModel } from '../../abstractStatus.model';

export class CompatibilityProviderGroups extends AbstractStatusModel {

  public compatibilityProviderId: number;
  public productivityGroupId: number;

  constructor(productivityGroupId: number, compatibilityProviderId = null) {
    super();
    this.id = null;
    this.productivityGroupId = productivityGroupId;
    this.compatibilityProviderId = compatibilityProviderId;
  }

}
