import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sbawFilterConverter' })
export class SbawFilterConverter implements PipeTransform {

    private listBr = [
        { id: true,  name: "SOMENTE" },
        { id: false,  name: "EXCETO" },
        { id: 0,  name: "TAMBEM CONTÉM" },
        { id: 1,  name: "SOMENTE CONTÉM" },
        { id: 2,  name: "INCLUIR" },
        { id: 3,  name: "SOMENTE" },
        { id: 4,  name: "NÃO CONTÉM" }
    ]

    private listEn = [
        { id: true,  name: "ONLY" },
        { id: false,  name: "EXCEPT" },
        { id: 0,  name: "ALSO CONTAINS" },
        { id: 1,  name: "CONTAINS ONLY" },
        { id: 2,  name: "INCLUDE" },
        { id: 3,  name: "ONLY" },
        { id: 4,  name: "NOT CONTAINS" }
    ]

    private locales = [
        { list: this.listBr, lang: "br" },
        { list: this.listEn, lang: "en" }
    ]

    transform(value): string {
        let lang = navigator.language.toLowerCase();

        for (let localeStatus of this.locales) {
            if (lang.endsWith(localeStatus.lang)) {
                for (let status of localeStatus.list) {
                    if (status.id === value) {
                        return status.name;
                    }
                }
                return "";
            }
        }
    }

}
