import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class SlotPattern extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public sizeInMinutes: number;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
