import { AbstractService } from '../abstract.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { OperationNatureDepositor } from '../../models/operation-nature/operationNatureDepositor';
import { Observable } from 'rxjs';
import { SDM_URL } from '../../../app.api';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationNatureDepositorService extends AbstractService<
  OperationNatureDepositor
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.operationNature.depositor.default);
  }

  createOperationNature(
    model: OperationNatureDepositor[]
  ): Observable<OperationNatureDepositor> {
    return this.http.post<OperationNatureDepositor>(
      `${env.host.sdm}/wrapper/operation-nature-depositor`,
      model
    );
  }

  findByDepositorId(
    depositorId: number
  ): Observable<OperationNatureDepositor[]> {
    return this.http
      .get<OperationNatureDepositor[]>(
        SDM_URL.operationNature.depositor.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )
      )
      .pipe(take(1));
  }
}
