import { ReverseDeliveryEvents } from './../../reverse-delivery.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseFormComponent } from '../../../../../shared/base-form/base-form.component';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RmAuthorizationService } from '../../../../../core/services/sdm/reverse-delivery/rma.service';
import { RmAuthorization } from '../../../../../core/models/sdm/reverse-delivery/rma';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { SDM_URL } from '../../../../../app.api';
import { DepositorService } from '../../../../../core/services/depositor.service';
import { delay, take } from 'rxjs/operators';
import { CharacterType } from '../../../../../core/models/sdm/shared/enums/CharacterType';
import { Subscription } from 'rxjs';
import { AbstractDelivery } from '../../../regular-delivery/abstract-delivery';
import { AbstractService } from '../../../../../core/services/abstract.service';

@Component({
  selector: 'app-rma-form',
  templateUrl: './rma-form.component.html',
  styleUrls: ['./rma-form.component.scss'],
})
export class RmaFormComponent extends AbstractDelivery<RmAuthorization> implements OnInit {

  keys: any;
  depositors = [];
  sub: Subscription;
  rmaRequired = false;
  rma: RmAuthorization;
  characterTypes = CharacterType;

  @ViewChild('dataGrdRMA') grid: DatatableApiComponent;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    private _rmaService: RmAuthorizationService,
    public depositorService: DepositorService,
    private _reverseDeliveryServiceEvents: ReverseDeliveryEvents,
  ) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.characterTypes);
  }

  columnsRma = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
  ];

  options = [
    { name: 'reverse-delivery.rma.options.true', value: true },
    { name: 'reverse-delivery.rma.options.false', value: false },
  ];

  optionsRequiredRma = [
    { name: 'reverse-delivery.rma.options.false', value: false },
    { name: 'reverse-delivery.rma.options.true', value: true },
  ];

  ngOnInit() {
    this.tabRmaFired();
    this.rma = new RmAuthorization();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      requiredRma: new FormControl(this.rma.requiredRma),
      size: new FormControl(''),
      rmaType: new FormControl(this.rma.rmaType),
      allowRepeat: new FormControl(this.rma.allowRepeat),
      numberAndNFSerie: new FormControl(this.rma.numberAndNFSerie),
      emissionDate: new FormControl(this.rma.emissionDate),
      documentSender: new FormControl(this.rma.documentSender),
      documentRecipient: new FormControl(this.rma.documentRecipient),
      documentShipping: new FormControl(this.rma.documentShipping),
    });

    // this.formulario.valueChanges.subscribe(data => 
  }

  requiredRmaField = () => this.formulario.get('requiredRma').value === 'true';

  tabRmaFired() {
    this.sub = this._reverseDeliveryServiceEvents.emitTabRmaFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.rma);
  }

  controlFieldsRequireRMA(event) {
    if (!(event.target.value === 'true')) {
      this.formulario.get('size').setValue('');
      this.formulario.get('size').disable();
      this.formulario.get('rmaType').setValue('');
      this.formulario.get('rmaType').disable();
      this.formulario.get('allowRepeat').setValue('');
      this.formulario.get('allowRepeat').disable();
    }
    else {
      this.formulario.get('size').enable();
      this.formulario.get('rmaType').enable();
      this.formulario.get('allowRepeat').enable();
    }
  }

  desabledInputs() {
    this.formulario.get('size').setValue('');
    this.formulario.get('size').disable();
    this.formulario.get('rmaType').setValue('');
    this.formulario.get('rmaType').disable();
    this.formulario.get('allowRepeat').setValue('');
    this.formulario.get('allowRepeat').disable();
  }

  submitted() {
    if (this.formulario.valid) {
      const formValue = this.formulario.getRawValue();
      if (formValue['rmaType'] === '') {
        formValue['rmaType'] = 'DEFAULT';
      }
      
      if (this.formulario.get('id').value == null) {
        this.getService().create(formValue)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.grid.reloadPage();
            this.showMessageActions();
          });
      } else {
        this.getService().edit(formValue)
          .subscribe(() => {
            this.disabledForm();
            this.grid.reloadPage();
            this.showMessageActions('success', 'update');
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  makeEndPointForRMAs = () => SDM_URL.reverseDelivery.rma.default;

  getComponentName(): string {
    return 'reverse-delivery.rma';
  }

  getNewInstance(): RmAuthorization {
    return new RmAuthorization();
  }
  getService(): AbstractService<RmAuthorization> {
    return this._rmaService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  afterAddButton(){

  }
  
}
