import {
  Component,
  forwardRef,
  Input,
  Renderer2,
  ViewChild,
  ElementRef,
  SimpleChanges,
  SimpleChange,
  OnChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment/moment';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
defineLocale('pt-br', ptBrLocale);

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldDateComponent),
  multi: true
};

@Component({
  selector: 'app-input-field-date',
  templateUrl: './input-field-date.component.html',
  styleUrls: ['./input-field-date.component.scss'],
  providers: [INPUT_FIELD_VALUE_ACCESSOR]
})
export class InputFieldDateComponent
  implements ControlValueAccessor, OnChanges {
  datePickerConfig: Partial<BsDatepickerConfig>;
  locale = navigator.language;

  @Input() classeCss;
  @Input() id: string;
  @Input() label: string;
  @Input() name = '';
  @Input() i18n = true;
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() isform = true;
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() placeholder : string = "";
  @ViewChild('inputDate') private _inputDate: ElementRef;

  constructor(
    private _localeService: BsLocaleService,
    private _renderer: Renderer2
  ) {
    moment().locale(this.locale);

    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-dark-blue', dateInputFormat: 'DD/MM/YYYY' }
    );
    this._localeService.use(this.locale);
  }

  private innerValue: any;

  get value() {
    if (this.innerValue)
      return moment(this.innerValue).format(
        this.datePickerConfig.dateInputFormat
      );
    return '';
  }

  set value(v: any) {
    if (v && typeof v === 'string') {
      v = moment(v).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (v && v instanceof Object) {
      let date = moment.parseZone(v.toUTCString());
      v = date.zone(v.getTimezoneOffset()).format('YYYY-MM-DDTHH:mm:ss');
    }
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
    // this._renderer.setProperty(this._inputDate.nativeElement, 'disabled', isDisabled);
  }

  hasSuccess(): boolean {
    return (this.control) ? this.control.valid && (this.control.dirty || this.control.touched) : true;
  }

  isInvalid(): boolean {
    // if (this.form.disabled) {
    //   this.control.markAsUntouched({ onlySelf: true });
    //   return false;
    // }
    // if (this.control.disabled) {
    //   this.control.markAsUntouched();
    // }
    return (this.control) ? this.control.invalid && (this.control.dirty || this.control.touched) : false;

    // if (this.control && this.form) {
    //   if (this.form.disabled || this.control.disabled) {
    //     this.control.markAsPristine({ onlySelf: true });
    //     return false;
    //   }
    //   return (
    //     !this.control.valid && (this.control.dirty || this.control.touched)
    //   );
    // }
    // return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //   if (typeof this.isRequired !== 'undefined') {
    //     const required: SimpleChange = changes.isRequired;
    //     if (typeof required !== 'undefined' && required.currentValue) {
    //       this.control.setValidators(Validators.required);
    //       this.control.updateValueAndValidity();
    //       return;
    //     }
    //     this.control.clearValidators();
    //     this.control.updateValueAndValidity();
    //   }
  }
}
