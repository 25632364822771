import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { DocksScheduling } from '../../../models/sdm/document/docks-scheduling';
import { DocksSchedulingDTO } from '../../../models/sdm/document/docks-schedulingDTO';
import { OperationTimesInfo } from '../../../models/sdm/document/operation-times-info';
import { AbstractService } from '../../abstract.service';
import { PriorVisualization } from './../../../models/sdm/docks/prior-visualization';
import { ManagementDocksConflict } from './../../../models/sdm/management-conflict/management-docks-conflict';

@Injectable({
  providedIn: 'root'
})
export class PriorVisualizationService extends AbstractService<
  PriorVisualization
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.priorVisualization.default);
  }

  findByDepositorIdAndStatus(
    depositorId: number
  ): Observable<PriorVisualization[]> {
    
    return this.http
      .get<PriorVisualization[]>(
        `${
          env.host.sdm
        }${SDM_URL.docks.priorVisualization.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )}?status=true`
      )
      .pipe(take(1));
  }

  findByDocksAvailable(
    depositorId: number,
    vehicleTypeId: number,
    validityDate: string,
    establishmentId: number,
    qtdSlots: number,
    statusAuthorization: number,
    risk: boolean,
    deliveryAuthorizationId: number
  ): Observable<DocksScheduling[]> {
    return this.http
      .get<DocksScheduling[]>(
        `${SDM_URL.docks.priorVisualization.findByDocksAvailable
          .replace(':depositorId', depositorId.toString())
          .replace(
            ':vehicleTypeId',
            vehicleTypeId != null ? vehicleTypeId.toString() : ''
          )
          .replace(':validityDate', validityDate)
          .replace(':establishmentId', establishmentId.toString())
          .replace(':qtdSlots', qtdSlots.toString())
          .replace(':statusAuthorization', statusAuthorization.toString())
          .replace(':risk', risk.toString())
          .replace(
            ':deliveryAuthorizationId',
            deliveryAuthorizationId.toString()
          )}`
      )
      .pipe(take(1));
  }

  findByDocksAvailableManagement(
    establishmentId: number,
    depositorId: number,
    date: string,
    operationNature: number[]
  ): Observable<DocksScheduling[]> {
    

    let regularDelivery = operationNature.includes(1);
    let reverseDelivery = operationNature.includes(2);
    let collect = operationNature.includes(3);

    // return;

    return this.http
      .get<DocksScheduling[]>(
        `${SDM_URL.docks.priorVisualization.findByDocksAvailableManagement
          .replace(':establishmentId', establishmentId.toString())
          .replace(':depositorId', depositorId.toString())
          .replace(':initialDate', date)
          .replace(':regularDelivery', regularDelivery.toString())
          .replace(':reverseDelivery', reverseDelivery.toString())
          .replace(':collect', collect.toString())}`
      )
      .pipe(take(1));
  }

  findBySlotsAvailable(
    establishmentId: number,
    qtdSlots: number,
    date: string
  ): Observable<ManagementDocksConflict[]> {
    return this.http.get<ManagementDocksConflict[]>(
      `${SDM_URL.docks.priorVisualization.findBySlotsAvailable
        .replace(':establishmentId', establishmentId.toString())
        .replace(':qtdSlots', qtdSlots.toString())
        .replace(':initialDate', date)}`
    );
  }

  findMinInitialHourAndMaxFinalHourByEstablishmentId(
    establishmentId: number
  ): Observable<OperationTimesInfo[]> {
    return this.http.get<OperationTimesInfo[]>(
      `${SDM_URL.docks.priorVisualization.findMinInitialHourAndMaxFinalHourByEstablishmentId.replace(
        ':establishmentId',
        establishmentId.toString()
      )}`
    );
  }

  findByEstablishmentIdAndDateSchedule(
    establishmentId: number,
    dateSchedule: string
  ): Observable<DocksSchedulingDTO> {
    return this.http.get<DocksSchedulingDTO>(
      `${SDM_URL.docks.priorVisualization.findByEstablishmentIdAndDateSchedule.replace(
        ':establishmentId',
        establishmentId.toString()
      )}?initialDate=${dateSchedule}`
    );
  }

  findByDepositorIdAndEstablishmentId(
    depositorId: number,
    establishmentId: number
  ): Observable<PriorVisualization[]> {
    return this.http
      .get<PriorVisualization[]>(
        `${
          env.host.sdm
        }${SDM_URL.docks.priorVisualization.findByDepositorIdAndEstablishmentId
          .replace(':depositorId', depositorId.toString())
          .replace(':establishmentId', establishmentId.toString())}?status=true`
      )
      .pipe(take(1));
  }

  findByEstablishmentIdAndDepositorIdAndCollectTrue(
    depositorId: number,
    establishmentId: number
  ): Observable<PriorVisualization[]> {
    return this.http
      .get<PriorVisualization[]>(
        `${
          env.host.sdm
        }${SDM_URL.docks.priorVisualization.findByEstablishmentIdAndDepositorIdAndCollectTrue
          .replace(':depositorId', depositorId.toString())
          .replace(':establishmentId', establishmentId.toString())}?status=true`
      )
      .pipe(take(1));
  }

  findByDepositorIdAndEstablishmentIdAll(
    depositorId: number,
    establishmentId: number
  ): Observable<PriorVisualization[]> {
    return this.http
      .get<PriorVisualization[]>(
        `${
          env.host.sdm
        }${SDM_URL.docks.priorVisualization.findByEstablishmentIdAndDepositorIdAll
          .replace(':depositorId', depositorId.toString())
          .replace(':establishmentId', establishmentId.toString())}?status=true`
      )
      .pipe(take(1));
  }

  updatePosition(
    signatureDock: number,
    sequence: number,
    establishmentId: number
  ) {
    return this.http.put(
      `${SDM_URL.docks.priorVisualization.updatePosition
        .replace(':signatureDock', signatureDock.toString())
        .replace(':sequence', sequence.toString())
        .replace(':establishmentId', establishmentId.toString())}`,
      null
    );
  }

  findByDocksAvailableConsecutiveAndDeliveryAuthorizationId(
    deliveryAuthorizationId: number
  ): Observable<DocksScheduling[]> {
    

    return this.http.get<DocksScheduling[]>(
      `${SDM_URL.docks.priorVisualization.findByDocksAvailableConsecutiveAndDeliveryAuthorizationId.replace(
        ':deliveryAuthorizationId',
        deliveryAuthorizationId.toString()
      )}`
    );
  }
}
