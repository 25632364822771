import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { UploadResult } from '../../core/models/dto/uploadResult';

@Component({
  selector: 'app-upload-file-tool',
  templateUrl: './upload-file-tool.component.html',
  styleUrls: ['./upload-file-tool.component.scss'],
  preserveWhitespaces: true,
})
export class UploadFileToolComponent implements OnInit, OnChanges {

  inconsistencies = [];
  list = [];
  modalRef: BsModalRef;
  private _endpoint = '';
  modalDetail: BsModalRef;
  private _endPointPartialImport = '';

  @ViewChild('importContinue') importContinue$: TemplateRef<any>;
  @ViewChild('templateDetail') templateDetail$: TemplateRef<any>;

  @Input() accept = '.xlsx,.xls';
  @Input() autoUpload = true;

  @Input()
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  @Input()
  set endPointPartialImport(endPointPartial: string) {
    this._endPointPartialImport = endPointPartial;
  }

  get endPointPartialImport() {
    return this._endPointPartialImport;
  }

  @Output() uploaded = new EventEmitter();

  uploader: FileUploader = new FileUploader({
    url: `${this._endpoint}`,
    autoUpload: this.autoUpload,
    headers: [{ name: 'Authorization', value: localStorage.getItem('token') }],
    queueLimit: 1,
    itemAlias: 'file',
    isHTML5: true,
    filters: [{
      name: 'excelOnly',
      fn: function (item) {
        const excelOnly = '|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/wps-office.xlsx|';
        return excelOnly.indexOf(item.type) !== -1;
      },
    }],
  });

  constructor(
    public auth: AuthService,
    public router: Router,
    public toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private _modalService: BsModalService,
    private http: HttpClient) { }

  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item => {

      const token = localStorage.getItem("token");

      if (token) {
        if (!this.auth.isAuthenticated()) {
          this.router.navigate(['login']);
          return false;
        }
      }

      this.spinner.show();
    });

    this.uploader.onAfterAddingFile = (item => { item.withCredentials = false; });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      let data: UploadResult = JSON.parse(response);
      if (status === 406) {
        this.toaster.error('Falha na conexão', 'SBAW');
      }
      if (status === 406) {
        this.inconsistencies = data.inconsistencies;
        this.openModal2(this.templateDetail$);
      } else if (data) {
        this.list = data.list;
        if (data.partial === false) {
          this.toaster.success('Dados do Arquivo salvos com sucesso', 'SBAW');
          this.uploaded.emit(data.list);
        } else {
          this.inconsistencies = data.inconsistencies;
          this.openModal(this.importContinue$);
        }
      }
      this.spinner.hide();
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const endpoint: SimpleChange = changes.endpoint;
    this.endpoint = endpoint.currentValue;

    const endPointPartial: SimpleChange = changes.endPointPartialImport;
    this.endPointPartialImport = endPointPartial.currentValue;

    this.uploader.setOptions({ url: this._endpoint });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, { class: 'gray modal-sm' });
  }

  openModal2(template: TemplateRef<any>) {
    this.modalDetail = this._modalService.show(template, { class: 'gray modal-lg' });
  }

  decline() {
    this.modalRef.hide();
  }

  confirm() {
    this.importContinue()
      .subscribe(resp => {
        if (resp) {
          this.uploaded.emit(resp);
        }
        
        this.modalRef.hide();
      });
  }

  importContinue(): Observable<HttpResponse<any>> {
    return this.http.post(this._endPointPartialImport, this.list, { observe: 'response' });
  }

}
