import { AbstractStatusModel } from '../abstractStatus.model';

export class OperationNature extends AbstractStatusModel {

  constructor(public id: number, public proccess: number, public operationNature: number) {
    super();
    this.id = id;
    this.proccess = proccess;
    this.operationNature = operationNature;
  }

}
