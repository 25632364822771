import { DockOperationDTO } from './../../../models/sdm/dock-management/dock-operation-dto';
import { Observable } from 'rxjs';
import { environment } from './../../../../../environments/environment';
import { SDM_URL } from './../../../../app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { DocksOperation } from '../../../models/sdm/dock-management/docks-operation';

@Injectable({
  providedIn: 'root'
})
export class DocksOperationService extends AbstractService<DocksOperation> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.dockManagement.docksOperation.default
    );
  }

  inputVehicle(model: DocksOperation): Observable<DocksOperation> {
    return this.http.post<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.inputVehicleArrival}`,
      model
    );
  }

  outputVehicle(model: DocksOperation): Observable<DocksOperation> {
    return this.http.post<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.outputVehicleArrival}`,
      model
    );
  }

  docksStart(model: DocksOperation): Observable<DocksOperation> {
    return this.http.post<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.docksStart}`,
      model
    );
  }

  docksFinished(model: DocksOperation): Observable<DocksOperation> {
    return this.http.post<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.docksFinished}`,
      model
    );
  }

  noShow(model: DocksOperation): Observable<DocksOperation> {
    return this.http.post<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.noShow}`,
      model
    );
  }

  findByDeliveryAuthorizationId(
    deliveryAuthorizationId: number
  ): Observable<DocksOperation> {
    return this.http.get<DocksOperation>(
      `${SDM_URL.dockManagement.docksOperation.findByDeliveryAuthorizationId.replace(
        ':deliveryAuthorizationId',
        deliveryAuthorizationId.toString()
      )}`
    );
  }

  updatePartial(body: any, deliveryAuthorizationId: number) {
    
    

    return this.http.patch(
      `${
        this.host
      }/wrapper/dock-management/docks-operation/operations/deliveryAuthorization/${deliveryAuthorizationId}`,
      body
    );
  }

  validateDocksOnOperation(
    idDocaOP: number,
    idDocaPrevista: number,
    establishmentId: number,
    depositorId: number
  ): Observable<DockOperationDTO> {
    return this.http.get<DockOperationDTO>(
      `${SDM_URL.dockManagement.docksOperation.validateDocksOnOperation
        .replace(':idDocaOP', idDocaOP.toString())
        .replace(
          ':idDocaPrevista',
          idDocaPrevista.toString()
        )}?establishment=${establishmentId}&depositor=${depositorId}`
    );
  }
}
