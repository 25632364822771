import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { DateTimeFormatPipe } from '../../../../core/classes/dateTimePipe';
import { SlotPattern } from '../../../../core/models/sdm/slot/slot-pattern';
import { AbstractService } from '../../../../core/services/abstract.service';
import { SlotPatternService } from '../../../../core/services/slot/slot-pattern.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../register/abstract-register';
import { DepositorService } from './../../../../core/services/depositor.service';

@Component({
  selector: 'app-slot-pattern-form',
  templateUrl: './slot-pattern-form.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./slot-pattern-form.component.scss'],
})
export class SlotPatternFormComponent extends AbstractRegister<SlotPattern> implements OnInit {

  slot: SlotPattern = new SlotPattern();
  @ViewChild('datatable') private grid: DatatableApiComponent;

  slotSizes: number[] = [10, 15, 20, 30, 60, 90, 120, 150, 180, 210, 240];
  columns = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'establishmentId', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'sizeInMinutes', name: 'Tamanho (em minutos)' },
    { prop: 'createdAt', name: 'Criado em', pipe: new DateTimeFormatPipe('en-US') },
    { prop: 'updatedAt', name: 'Alterado em', pipe: new DateTimeFormatPipe('en-US') },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public _slotService: SlotPatternService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(this.slot.id),
      establishmentId: new FormControl(this.slot.establishmentId, [Validators.required]),
      description: new FormControl(''),
      sizeInMinutes: new FormControl(this.slot.sizeInMinutes, [Validators.required]),
      status: new FormControl(this.slot.status),
    });
  }

  // submit() {
  //   if (this.formulario.controls['id'].value == null) {
  //     this._slotService.create(this.formulario.value)
  //       .subscribe(data => {
  //         this.formulario.patchValue(data)
  //         this.grid.reloadPage()
  //         this.toaster.success('Processo concluído com sucesso')
  //         this.disabledForm()
  //       });
  //   } else {
  //     this._slotService.edit(this.formulario.value)
  //       .subscribe(() => {
  //         this.grid.reloadPage()
  //         this.toaster.success('Processo concluído com sucesso')
  //         this.disabledForm()
  //       })
  //   }
  // }

  getNewInstance(): SlotPattern {
    return new SlotPattern();
  }
  getService(): AbstractService<SlotPattern> {
    return this._slotService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName() {
    return 'slot.size';
  }

}
