import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { SDM_URL } from './../../../../../app.api';
import { Location } from '@angular/common';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../abstract-register';
import { DaysHoursRestriction } from '../../../../../core/models/sdm/provider-management/days-hours-restriction';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { DaysHoursRestrictionService } from '../../../../../core/services/sdm/provider-management/days-hours-restriction.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProviderManagement } from '../../provider-management.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-days-hours-restriction-form',
  templateUrl: './days-hours-restriction-form.component.html',
  styleUrls: ['./days-hours-restriction-form.component.scss'],
})
export class DaysHoursRestrictionFormComponent extends AbstractRegister<DaysHoursRestriction> implements OnInit {

  @ViewChild('gridDaysHoursRestriction') grid$: DatatableApiComponent;

  columnsDaysHoursRestriction = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'provider.document', name: 'CNPJ' },
    { prop: 'iniHour', name: 'Horário Início' },
    { prop: 'finHour', name: 'Horário Término' },
    { prop: 'monday', name: 'Segundas-feiras', pipe: new BooleanConverter() },
    { prop: 'tuesday', name: 'Terças-feiras', pipe: new BooleanConverter() },
    { prop: 'wednesday', name: 'Quartas-feiras', pipe: new BooleanConverter() },
    { prop: 'thursday', name: 'Quintas-feiras', pipe: new BooleanConverter() },
    { prop: 'friday', name: 'Sextas-feiras', pipe: new BooleanConverter() },
    { prop: 'saturday', name: 'Sabádos', pipe: new BooleanConverter() },
    { prop: 'sunday', name: 'Domingos', pipe: new BooleanConverter() },
    { prop: 'reason', name: 'Motivo' },
  ];

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
  ];

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _daysHoursRestriction: DaysHoursRestrictionService,
    private _providerManagementService: ProviderManagement) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerManagementService.emitEventTabDaysHoursResctriction
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl(''),
      depositor: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      iniHour: new FormControl(''),
      finHour: new FormControl(''),
      monday: new FormControl(''),
      tuesday: new FormControl(''),
      wednesday: new FormControl(''),
      thursday: new FormControl(''),
      friday: new FormControl(''),
      saturday: new FormControl(''),
      sunday: new FormControl(''),
      reason: new FormControl(''),
    });

    this.formulario.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('depositorId').setValue(value.id);
      } else {
        this.formulario.get('depositorId').setValue('');
      }
    })
  }

  endPointDaysHoursRestriction = () => SDM_URL.providerManagement.daysHoursRestriction.default;

  endpointUpload = () => SDM_URL.providerManagement.daysHoursRestriction.upload;

  endPointPartial = () => SDM_URL.providerManagement.daysHoursRestriction.importPartial;

  queryProviderDocument = () => SDM_URL.providerManagement.priorityProviders.findByDocument.replace(':document', '');

  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  getNewInstance(): DaysHoursRestriction {
    return new DaysHoursRestriction();
  }
  getService(): AbstractService<DaysHoursRestriction> {
    return this._daysHoursRestriction;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.days-hours-restriction';
  }

}
