import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DeliveryAuthorization } from '../../../models/sdm/delivery-authorization/delivery-authorization';
import { RescheduleValidationTime } from '../../../models/sdm/delivery-authorization/reschedule-validation-time';
import { AbstractService } from '../../abstract.service';
import { environment } from './../../../../../environments/environment';
import { SDM_URL } from './../../../../app.api';
import { Authorization } from './../../../models/sdm/delivery-authorization/authorization';
import { ManagementConflictsDto } from './../../../models/sdm/delivery-authorization/management-conflicts-dto';
import { DocksScheduling } from './../../../models/sdm/document/docks-scheduling';
import { ManagementConflicts } from './../../../models/sdm/management-conflict/management-docks';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAuthorizationService extends AbstractService<
  DeliveryAuthorization
> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.deliveryAuthorization.regularDelivery.default
    );
  }

  updateStatusAuthorization(
    id: number,
    status: number
  ): Observable<DeliveryAuthorization> {
    return this.http
      .put<DeliveryAuthorization>(
        `${this.host}${this.url}/${id}/status/${status}`,
        {}
      )
      .pipe(take(1));
  }

  cancelAuthorization(
    deliveryAuthorizationId: number
  ): Observable<DeliveryAuthorization> {
    return this.http
      .put<DeliveryAuthorization>(
        `${this.host}${
          this.url
        }/cancel-authorization/${deliveryAuthorizationId}`,
        {}
      )
      .pipe(take(1));
  }

  cancelDeliveryAuthorization(deliveryAuthorizationId: number) {
    return this.http
      .put(
        `${this.host}${
          this.url
        }/cancel-delivery-authorization/${deliveryAuthorizationId}`,
        {}
      )
      .pipe(take(1));
  }

  cancelDeliveryAuthorizations(deliveryAuthorizations: string[]) {
    return this.http
      .put(
        `${this.host}${
          this.url
        }/cancel-delivery-authorization/recurrent?deliveryAuthorizations=${deliveryAuthorizations.join(
          ','
        )}`,
        {}
      )
      .pipe(take(1));
  }

  findByStatusAuthorizationIn(
    status: number[]
  ): Observable<HttpResponse<DeliveryAuthorization>> {
    return this.http.get<DeliveryAuthorization>(
      `${SDM_URL.deliveryAuthorization.regularDelivery.findByStatusAuthorizationIn.replace(
        ':status',
        status.join()
      )}`,
      { observe: 'response' }
    );
  }

  findByAuthorizationKeyIsNotNull(): Observable<
    HttpResponse<DeliveryAuthorization>
  > {
    return this.http.get<HttpResponse<DeliveryAuthorization>>(
      `${
        SDM_URL.deliveryAuthorization.regularDelivery
          .findByAuthorizationKeyIsNotNull
      }`
    );
  }

  findCustomById(solicitationId: number): Observable<DeliveryAuthorization> {
    return this.http.get<DeliveryAuthorization>(
      `${SDM_URL.deliveryAuthorization.regularDelivery.findCustomById.replace(
        ':id',
        solicitationId.toString()
      )}`
    );
  }

  findByAuthorizationKey(
    authorizationKey: string
  ): Observable<DeliveryAuthorization> {
    return this.http.get<DeliveryAuthorization>(
      `${SDM_URL.deliveryAuthorization.regularDelivery.findByAuthorizationKey.replace(
        ':authorizationkey',
        authorizationKey.toString()
      )}`
    );
  }

  findDocksScheduling(
    depositorId: number,
    deliveryAuthorizationId: number
  ): Observable<RescheduleValidationTime> {
    return this.http.get<RescheduleValidationTime>(
      `${SDM_URL.deliveryAuthorization.regularDelivery.findDocksScheduling
        .replace(':deliveryAuthorizationId', deliveryAuthorizationId.toString())
        .replace(':depositorId', depositorId.toString())}`
    );
  }

  findByFiltersAndExportFile(queryString: string): Observable<Blob> {
    return this.http
      .get(
        `${
          SDM_URL.deliveryAuthorization.queryDeliveryToExportFile
        }?${queryString}&`,
        { responseType: 'blob' }
      )
      .pipe(take(1));
  }

  findBySearchCollectExport(queryString: string): Observable<Blob> {
    return this.http
      .get(
        `${
          SDM_URL.deliveryAuthorization.findBySearchCollectExport
        }?${queryString}&`,
        { responseType: 'blob' }
      )
      .pipe(take(1));
  }

  findBySearchReverseExport(queryString: string): Observable<Blob> {
    return this.http
      .get(
        `${
          SDM_URL.deliveryAuthorization.queryDeliveryReverseExport
        }?${queryString}&`,
        { responseType: 'blob' }
      )
      .pipe(take(1));
  }

  findByDepositorId(depositorId: number): Observable<ManagementConflictsDto> {
    return this.http
      .get<ManagementConflictsDto>(
        `${SDM_URL.deliveryAuthorization.regularDelivery.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )}`
      )
      .pipe(take(1));
  }

  fixPendingAllocation(
    managementDocks: ManagementConflicts
  ): Observable<DocksScheduling> {
    return this.http
      .post<DocksScheduling>(
        `${SDM_URL.deliveryAuthorization.fixPendingAllocation}`,
        managementDocks
      )
      .pipe(take(1));
  }

  findByDepositorIdAndStatusDocsOK(
    depositorId: number
  ): Observable<DeliveryAuthorization[]> {
    return this.http.get<DeliveryAuthorization[]>(
      `${SDM_URL.deliveryAuthorization.findByDepositorIdAndStatusDocsOK.replace(
        ':depositorId',
        depositorId.toString()
      )}`
    );
  }

  findByDepositorIdAndFinisherStatus(
    depositorId: string
  ): Observable<DeliveryAuthorization[]> {
    return this.http.get<DeliveryAuthorization[]>(
      `${
        SDM_URL.deliveryAuthorization.findByDepositorIdAndFinisherStatus
      }?depositorId=${depositorId}`
    );
  }

  findByDepositorIdAndStatus(
    depositorId: number,
    status: number[]
  ): Observable<DeliveryAuthorization[]> {
    return this.http.get<DeliveryAuthorization[]>(
      `${SDM_URL.deliveryAuthorization.findByDepositorIdAndStatus.replace(
        ':depositorId',
        depositorId.toString()
      )}?status=${status.join(',')}`
    );
  }

  partialUpdate(
    body,
    deliveryAuthorizationId: number
  ): Observable<DeliveryAuthorization> {
    return this.http.patch<DeliveryAuthorization>(
      `${SDM_URL.deliveryAuthorization.partialUpdate.replace(
        ':id',
        deliveryAuthorizationId.toString()
      )}`,
      body
    );
  }

  findByDepositorIdAndStatusTrueAndConflictedTrueAndStatusPending(
    depositorId: number
  ) {
    return this.http.get(
      `${SDM_URL.deliveryAuthorization.findByDepositorIdAndStatusTrueAndConflictedTrue.replace(
        ':depositorId',
        depositorId.toString()
      )}`
    );
  }

  resolvewhenExistsBothConflict(
    priorVisualizationId: number,
    initialHour: string,
    finalHour: string
  ) {
    return this.http.post(
      `${this.host}${
        this.url
      }/resolve-conflicted?priorVisualizationId=${priorVisualizationId}&initialHour=${initialHour}&finalHour=${finalHour}`,
      {}
    );
  }
}
