import { DockCompatibility } from './../../../models/sdm/docks/dock-compatibility';
import { SDM_URL } from '../../../../app.api';
import { environment as env } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DockCompatibilityService extends AbstractService<DockCompatibility> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.dockCompatibility.default);
  }

  findByDepositorIdAndCollectTrue(depositorId: number): Observable<DockCompatibility> {
    return this.http.get<DockCompatibility>(`${SDM_URL.docks.dockCompatibility.findByDepositorIdAndCollectTrue.replace(':depositorId', depositorId.toString())}`);
  }
}
