const Gateway = {
  roles: [],
  authority: 'USER',
  solution: 'GATEWAY',
  text: 'Portal',
  link: '/gateway/home',
  icon: 'fa fa-arrow-left'
};

const Home = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Home',
  link: '/sdm/home',
  icon: 'icon-home'
};

// const Signature = {
//   text: 'Assinatura',
//   link: '/sdm/signature',
//   icon: 'icon-pencil',
// };

const AutoricaoEntrega = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Autorizações de entrega',
  link: '#',
  icon: 'icon-note',
  submenu: [
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Regular',
      link: '#',
      icon: 'fa fa-arrow-right',
      submenu: [
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Nova Autorização',
          link: 'delivery-authorization/regular-delivery',
          icon: 'icon-note'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Info. Dimensionamento',
          link: 'delivery-authorization/regular-delivery/dimensioning-info',
          icon: 'fa fa-calendar-check-o'
        }
      ]
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Pendentes',
      link: 'delivery-authorization/pending-delivery',
      icon: 'fa fa-exclamation-triangle'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Consultar / Editar',
      link: 'delivery-authorization/edit',
      icon: 'fa fa-search'
    }
  ]
};

const Agendamento = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Agendamentos',
  link: '#',
  icon: 'fa fa-calendar-plus-o',
  submenu: [
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Entrega Reversa',
      link: '#',
      icon: 'fa fa-recycle',
      submenu: [
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Alocação Manual',
          link: 'register/schedules/reverse-delivery/manual-allocation',
          icon: ''
        }
      ]
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Coleta',
      link: 'register/schedules/collect',
      icon: 'fa fa-truck'
    }
    // {
    //   roles: [],
    //   authority: 'USER',
    //   solution: 'SDM',
    //   text: 'Portaria',
    //   link: 'lobby',
    //   icon: 'fa fa-arrow-up'
    // }
    // {
    //   roles: [],
    //   solution: 'SDM',
    //   authority: 'USER',
    //   text: 'Fiscal/Auditoria',
    //   link: '#',
    //   icon: 'fa fa-check-square-o'
    // }
  ]
};

const GestaoDocas = {
  roles: [],
  solution: 'SDM',
  authority: 'ADMIN',
  text: 'Gestão de Docas',
  link: '#',
  icon: 'icon-grid',
  submenu: [
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Visualização Geral',
      link: 'dock-management/overview',
      icon: ''
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Gestão Slots Reserva',
      link: 'dock-management/slots-reserve',
      icon: ''
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Gestão (des)bloqueios Slots',
      link: 'dock-management/unlock-slots',
      icon: ''
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Operação Docas',
      link: 'dock-management/docks-operation',
      icon: ''
    },
    {
      roles: [],
      authority: 'USER',
      solution: 'SDM',
      text: 'Portaria',
      link: 'dock-management/lobby',
      icon: 'fa fa-arrow-right'
    }
  ]
};

const GestaoAVista = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Gestão á Vista SBAW',
  link: 'register/gestao-vista',
  icon: 'icon-graph'
};

const GestaoAVistaSdm = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Gestão á Vista SDM',
  link: 'register/gestao-vista-sdm',
  icon: 'icon-graph'
};

const ConsultasRelatorios = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Consultas e relatórios',
  link: '#',
  icon: 'icon-docs'
};

const Register = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Cadastros',
  link: '#',
  icon: 'icon-note',
  submenu: [
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Natureza da Operação',
      link: 'register/operation-nature',
      icon: 'fa fa-asterisk'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Tipos de veículo',
      link: 'register/vehicle',
      icon: 'fa fa-car'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'SLOT´s',
      link: 'parameters/slot',
      icon: 'fa fa-cube'
      // submenu: [
      //   {
      //     text: 'Tamanho padrão',
      //     link: 'parameters/slot',
      //     icon: 'fa fa-bars',
      //   },
      //   {
      //     text: 'Forma arredondamento',
      //     link: 'parameters/slot-round',
      //     icon: 'fa fa-refresh',
      //   },
      //   {
      //     text: 'Reservas',
      //     link: 'parameters/slot-reserve',
      //     icon: 'fa fa-sticky-note',
      //   },
      // ],
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Fornecedores/Transportadoras',
      // link: 'register/provider-management',
      link: '#',
      icon: 'fa fa-handshake-o',
      submenu: [
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Fornecedor / Transportadora',
          link: 'register/providers/provider-management',
          icon: 'fa fa-users'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Campos imprecisos',
          link: 'register/providers/imprecise-fields',
          icon: 'fa fa-window-close-o'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Restrições Dias e Horários',
          link: 'register/providers/days-hours-restriction',
          icon: 'fa fa-clock-o'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Dimensionamento Tempo Docas',
          link: 'register/providers/docks-dimensioning',
          icon: 'fa fa-hourglass-end'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Compatibilidade Grupos',
          link: 'register/providers/groups-compatibility',
          icon: 'fa fa-object-group'
        }
      ]
    }
  ]
};

const Parametrizations = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'Parametrizações',
  icon: 'fa fa-gears',
  link: '#',
  submenu: [
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Unidades de medida',
      link: 'parameters/measure-unit',
      icon: 'fa fa-arrows-h'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Validação de Documentos',
      link: '#',
      icon: 'fa fa-check-square-o',
      submenu: [
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Entrega Regular',
          link: 'parameters/regular-delivery',
          icon: 'fa fa-arrow-right'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Entrega Reversa',
          link: 'parameters/reverse-delivery',
          icon: 'fa fa-recycle'
        },
        {
          roles: [],
          solution: 'SDM',
          authority: 'USER',
          text: 'Coleta',
          link: 'parameters/collect',
          icon: 'fa fa-truck'
        }
      ]
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Docas',
      link: 'parameters/docks',
      icon: 'fa fa-th-large'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'Tratamento Pendências',
      link: 'parameters/handling/pending',
      icon: 'fa fa-wrench'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'USER',
      text: 'E-mails/SMS',
      link: 'parameters/email-sms',
      icon: 'fa fa-envelope-o'
    },
    {
      roles: [],
      solution: 'SDM',
      authority: 'ADMIN',
      text: 'Custos logísticos',
      link: 'parameters/logistic-costs',
      icon: 'fa fa-usd'
    }
  ]
};

const GestaoPendenciasConflitos = {
  solution: 'SDM',
  authority: 'USER',
  text: 'Gestão Pendências/Conflitos',
  icon: 'fa fa-exclamation-triangle',
  link: 'management-conflicts'
};

const Lobby = {
  solution: 'SDM',
  authority: 'USER',
  text: 'Portaria',
  icon: 'fa fa-sign-in',
  link: 'lobby'
};
const smartUser = {
  solution: 'SDM',
  authority: 'ADMIN',
  text: 'Usuários e Perfis de Acesso',
  link: 'sdm-user-domain',
  icon: 'fa fa-user-circle-o'
};

const headingMain = {
  roles: [],
  solution: 'SDM',
  authority: 'USER',
  text: 'SDM',
  heading: true
};

export const menuSdm = [
  Gateway,
  headingMain,
  Home,
  // Signature,
  GestaoDocas,
  AutoricaoEntrega,
  Agendamento,
  GestaoAVista,
  GestaoAVistaSdm,
  // ConsultasRelatorios,
  Register,
  Lobby,
  Parametrizations,
  GestaoPendenciasConflitos,
  smartUser
];
