import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  preserveWhitespaces: true,
})
export class UploadFileComponent implements OnInit, OnChanges {

  inconsistencies = [];
  modalRef: BsModalRef;
  private _endpoint = '';
  modalDetail: BsModalRef;
  private _endPointPartialImport = '';

  @ViewChild('importContinue') importContinue$: TemplateRef<any>;
  @ViewChild('templateDetail') templateDetail$: TemplateRef<any>;

  @Input() accept = '.xlsx,.xls';
  @Input() autoUpload = true;

  @Input()
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  @Input()
  set endPointPartialImport(endPointPartial: string) {
    this._endPointPartialImport = endPointPartial;
  }

  get endPointPartialImport() {
    return this._endPointPartialImport;
  }

  @Output() uploaded = new EventEmitter();

  uploader: FileUploader = new FileUploader({
    url: `${this._endpoint}`,
    autoUpload: this.autoUpload,
    headers: [{ name: 'Authorization', value: localStorage.getItem('token') }],
    queueLimit: 1,
    itemAlias: 'file',
    isHTML5: true,
    filters: [{
      name: 'excelOnly',
      fn: function (item) {
        const excelOnly = '|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel|application/wps-office.xlsx|';
        return excelOnly.indexOf(item.type) !== -1;
      },
    }],
  });

  constructor(
    public toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private _modalService: BsModalService,
    private http: HttpClient) { }

  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item => {
      this.spinner.show();
    });

    this.uploader.onAfterAddingFile = (item => { item.withCredentials = false; });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (response !== undefined && status === 201) {
        this.toaster.success('Dados do Arquivo salvos com sucesso', 'SDM');
        this.uploaded.emit(response);
      } else if (status === 409) {
        this.inconsistencies = JSON.parse(response);
        this.openModal(this.importContinue$);
      } else if (status === 406) {
        this.inconsistencies = JSON.parse(response);
        this.openModal2(this.templateDetail$);
      }
      this.spinner.hide();
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    const endpoint: SimpleChange = changes.endpoint;
    this.endpoint = endpoint.currentValue;

    const endPointPartial: SimpleChange = changes.endPointPartialImport;
    this.endPointPartialImport = endPointPartial.currentValue;

    this.uploader.setOptions({ url: this._endpoint });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, { class: 'gray modal-sm' });
  }

  openModal2(template: TemplateRef<any>) {
    this.modalDetail = this._modalService.show(template, { class: 'gray modal-lg' });
  }

  decline() {
    this.modalRef.hide();
  }

  confirm() {
    this.importContinue()
      .subscribe(resp => {
        if (resp) {
          this.uploaded.emit(resp);
        }
        
        this.modalRef.hide();
      });
  }

  importContinue(): Observable<HttpResponse<any>> {
    return this.http.post(this._endPointPartialImport, {}, { observe: 'response' });
  }

}
