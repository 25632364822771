import { AbstractStatusModel } from '../../abstractStatus.model';

export class BlackList extends AbstractStatusModel {

  public establishmentId: number;
  public depositorId: number;
  public regularDelivery: boolean;

  constructor() {
    super();
  }

}
