import { AbstractStatusModel } from '../../abstractStatus.model';

export class DaysHoursRestriction extends AbstractStatusModel {

  public EstablishmentId: number;
  public depositorId: number;
  public document: string;
  public iniHour: string;
  public finHour: string;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;
  public reason: string;

  constructor() {
    super();
  }

}
