import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { delay, take } from 'rxjs/operators';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from '../../../../core/classes/boolean-type';
import { DateTimeFormatPipe } from '../../../../core/classes/dateTimePipe';
import { SlotReserve } from '../../../../core/models/sdm/slot/slot-reserve';
import { AbstractService } from '../../../../core/services/abstract.service';
import { DepositorService } from '../../../../core/services/depositor.service';
import { SlotReserveService } from '../../../../core/services/slot/slot-reserve.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../register/abstract-register';
import { SlotService } from '../slot.service';

@Component({
  selector: 'app-slot-reserve-form',
  templateUrl: './slot-reserve-form.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./slot-reserve-form.component.scss'],
})
export class SlotReserveFormComponent extends AbstractRegister<SlotReserve> implements OnInit {

  slotReserve: SlotReserve = new SlotReserve();

  // for control inputs, show or hide
  public showHide = false;
  public showHideTp2 = false;

  @ViewChild('datatable') private grid: DatatableApiComponent;

  // @ViewChild('hdrTplTeste') hdrTplTeste: TemplateRef<any>;
  // @ViewChild('actTplTeste') actTplTeste: TemplateRef<any>;

  columns: object[] = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'establishmentId', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositorId', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'useFirstReserveType', name: 'Reserva Tipo 1?', pipe: new BooleanConverter() },
    { prop: 'useSecondReserveType', name: 'Reserva Tipo 2?', pipe: new BooleanConverter() },
    { prop: 'createdAt', name: 'Criado em', pipe: new DateTimeFormatPipe('en-US') },
    { prop: 'updatedAt', name: 'Alterado em', pipe: new DateTimeFormatPipe('en-US') },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    private _slotReserveService: SlotReserveService,
    private _depositorService: DepositorService,
    public translatorService: TranslatorService,
    private _slotServiceEvents: SlotService) {
    super(location, toaster, translatorService, _depositorService);
  }

  ngOnInit() {
    // this.columns.push({ name: 'teste', cellTemplate: this.actTplTeste, headerTemplate: this.hdrTplTeste })
    this._slotServiceEvents.emitEventTabSlotReserveFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(this.slotReserve.id),
      establishmentId: new FormControl(this.slotReserve.establishmentId, [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl(this.slotReserve.depositorId),
      useFirstReserveType: new FormControl(this.slotReserve.useFirstReserveType, [Validators.required]),
      updateAutoForAvailableFirst: new FormControl(this.slotReserve.updateAutoForAvailableFirst, [Validators.required]),
      daysForUpdateFirst: new FormControl(this.slotReserve.daysForUpdateFirst),
      availableTypeFirst: new FormControl(this.slotReserve.availableTypeFirst),
      availableDeliveryExtFirst: new FormControl(this.slotReserve.availableDeliveryExtFirst, [Validators.required]),
      useSecondReserveType: new FormControl(this.slotReserve.useSecondReserveType, [Validators.required]),
      updateAutoForAvailableSecond: new FormControl(this.slotReserve.updateAutoForAvailableSecond, [Validators.required]),
      daysForUpdateSecond: new FormControl(this.slotReserve.daysForUpdateSecond),
      availableTypeSecond: new FormControl(this.slotReserve.availableTypeSecond),
      availableDeliveryExtSecond: new FormControl(this.slotReserve.availableDeliveryExtSecond),
      status: new FormControl(this.slotReserve.status),
    });
  }

  daysAndTypeIsRequiredFirstReserve() {
    return this.formulario.get('updateAutoForAvailableFirst').value === 'true' || this.formulario.get('updateAutoForAvailableFirst').value === true;
  }

  daysAndTypeIsRequiredSecondReserve() {
    return this.formulario.get('updateAutoForAvailableSecond').value === 'true' || this.formulario.get('updateAutoForAvailableSecond').value === true;
  }

  formFillToEdit(row: object) {
        this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.enable();
    this.statusFieldsFirstReserveType();
    this.statusFieldsSecondReserveType();
  }

  changeLocaleId(localeId) {
    if (!localeId) {
      this.formulario.get('depositors').setValue([]);
      this.depositors = [];
      return;
    }
    this._depositorService.findByEstablishmentId(localeId).pipe(delay(500)).subscribe(data => {
      this.depositors = [...data.body['content']];
    });
  }

  // submit() {
  //   if (this.formulario.controls['id'].value == null) {
  //     this._slotReserveService.create(this.formulario.value)
  //       .subscribe(data => {
  //         this.formulario.patchValue(data)
  //         this.grid.reloadPage()
  //         this.toaster.success('Processo concluído com sucesso', 'SDM')
  //         this.disabledForm()
  //       });
  //   } else {
  //     this._slotReserveService.edit(this.formulario.value)
  //       .subscribe(() => {
  //         this.grid.reloadPage()
  //         this.toaster.success('Processo concluído com sucesso', 'SDM')
  //         this.disabledForm()
  //       })
  //   }
  // }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.depositors = [];
    this.formulario.patchValue(this.slotReserve);
    this.statusFieldsFirstReserveType();
    this.statusFieldsSecondReserveType();
    // this.formulario.controls['localeId'].setValue([]);
  }

  showDaysAndTypeReserve(event) {
    this.showHide = (event.target.value === 'true');
    this.formulario.get('daysForUpdateFirst').setValue('');
    this.formulario.get('availableTypeFirst').setValue('');
    this.statusFieldsFirstReserveType();
  }

  showDaysAndTypeReserveSecond(event) {
    this.showHideTp2 = (event.target.value === 'true');
    this.formulario.controls['daysForUpdateSecond'].setValue('');
    this.formulario.controls['availableTypeSecond'].setValue('');
    this.statusFieldsSecondReserveType();
  }

  resetar() {
    this.formulario.reset();
    this.formulario.disable();
    this.formEnabled = false;
    this.showHide = false;
    this.showHideTp2 = false;
  }

  useReverseFirstType() {
    this.statusFieldsFirstReserveType();
  }

  useReverseSecondType() {
    this.statusFieldsSecondReserveType();
  }

  statusFieldsFirstReserveType() {
    if (this.formulario.get('useFirstReserveType').value === 'true' || this.formulario.get('useFirstReserveType').value === true) {
      this.setRequiredForFields(['updateAutoForAvailableFirst']);
      this.enableFields(['updateAutoForAvailableFirst', 'availableDeliveryExtFirst']);
      return;
    }
    this.removeRequiredForFields(['updateAutoForAvailableFirst', 'daysForUpdateFirst', 'availableTypeFirst', 'availableDeliveryExtFirst']);
    this.disableFields(['updateAutoForAvailableFirst', 'availableDeliveryExtFirst']);
    this.formulario.get('updateAutoForAvailableFirst').setValue(false);
    this.formulario.get('availableDeliveryExtFirst').setValue(false);
  }

  statusFieldsSecondReserveType() {
    if (this.formulario.get('useSecondReserveType').value === 'true' || this.formulario.get('useSecondReserveType').value === true) {
      this.setRequiredForFields(['updateAutoForAvailableSecond']);
      this.enableFields(['updateAutoForAvailableSecond', 'availableDeliveryExtSecond']);
      return;
    }
    this.removeRequiredForFields(['updateAutoForAvailableSecond', 'daysForUpdateSecond', 'availableTypeSecond', 'availableDeliveryExtSecond']);
    this.disableFields(['updateAutoForAvailableSecond', 'availableDeliveryExtSecond']);
    this.formulario.get('updateAutoForAvailableSecond').setValue(false);
    this.formulario.get('availableDeliveryExtSecond').setValue(false);
  }

  // formFillToEdit(row) {
  //   this.formulario.patchValue(row);
  //   this.formulario.enable();
  //   const localeId = this.formulario.controls['localeId'].value;
  //   this.changeLocaleId(localeId);
  // }

  // formFillToView(row) {
  //   this.formulario.patchValue(row);
  //   this.formulario.disable();
  //   const localeId = this.formulario.controls['localeId'].value;
  //   this.changeLocaleId(localeId);
  // }

  getNewInstance(): SlotReserve {
    return new SlotReserve();
  }
  getService(): AbstractService<SlotReserve> {
    return this._slotReserveService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  getComponentName() {
    return 'slot.reserve';
  }

}
