import { DomSanitizer } from '@angular/platform-browser';
import { PriorVisualizationService } from './../../../core/services/sdm/docks/prior-visualization.service';
import { DocksScheduling } from './../../../core/models/sdm/document/docks-scheduling';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { RegularDelivery } from '../../../core/models/sdm/regular-delivery/regular-delivery';
import { DepositorService } from '../../../core/services/depositor.service';
import { RegularDeliveryService } from '../../../core/services/sdm/regular-delivery/regular-delivery.service';
import { DeliveryAuthorization } from './../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Depositor } from './../../../core/models/sdm/depositor';
import { Establishment } from './../../../core/models/sdm/establishment';
import { OpNatureAuthorization } from './../../../core/models/sdm/shared/enums/op-nature-authorization.enum';
import { AuthService } from './../../../core/services/auth.service';
import { EstablishmentService } from './../../../core/services/establishment.service';
import { DeliveryAuthorizationService } from './../../../core/services/sdm/delivery-authorization/delivery-authorization.service';


@Component({
  selector: 'app-regular-delivery',
  templateUrl: './regular-delivery.component.html',
  styleUrls: ['./regular-delivery.component.scss']
})
export class RegularDeliveryComponent implements OnInit, OnDestroy {
  formEnabled = true;
  formDeliveryAuthorization: FormGroup;
  deliveryAuthorization: DeliveryAuthorization;
  depositors = [];
  establishments = [];
  regularDelivery: RegularDelivery;
  subscriptions: Subscription[] = [];

  dismissible = true;
  alerts: any = [];

  solicitations = [];
  selectedAuthorization;

  options = [
    {
      name:
        'delivery-authorization.regular-delivery.uploadDocType.options.manualType',
      value: 1
    },
    {
      name:
        'delivery-authorization.regular-delivery.uploadDocType.options.uploadUnitary',
      value: 2
    },
    {
      name:
        'delivery-authorization.regular-delivery.uploadDocType.options.massiveLoad',
      value: 3
    }
  ];

  tabManualTypeEnabled = true;
  tabUploadUnitaryEnabled = true;
  tabMassiveLoad = true;

  constructor(
    private _router: Router,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _authService: AuthService,
    private _establishmentService: EstablishmentService,
    private _depositorService: DepositorService,
    private _regularDeliveryService: RegularDeliveryService,
    private _priorVisualizationService: PriorVisualizationService,
    sanitizer: DomSanitizer
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  ngOnInit() {
    this.deliveryAuthorization = new DeliveryAuthorization();
    this.regularDelivery = new RegularDelivery();

    this.formDeliveryAuthorization = new FormGroup({
      deliveryConsecutive: new FormControl(
        this.deliveryAuthorization.deliveryConsecutive
      ),
      uploadDocType: new FormControl(
        this.deliveryAuthorization.uploadDocType.toString(),
        Validators.required
      ),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      opNatureDelivery: new FormControl(''),
      authorizationKey: new FormControl('')
    });

    // const { id } = this._authService.getUser();

    this.subscriptions.push(
      this._establishmentService.findAll().subscribe(data => {
        this.establishments = [...data.body['content']];
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  cancel() {
    this.formDeliveryAuthorization.get('deliveryConsecutive').setValue(false);
    this.formDeliveryAuthorization.get('uploadDocType').setValue(null);
    this.formDeliveryAuthorization.get('uploadDocType').markAsPristine();
  }

  confirm() {
    if (this.formDeliveryAuthorization.valid) {
      this.formDeliveryAuthorization
        .get('opNatureDelivery')
        .setValue(OpNatureAuthorization.INBOUND);

      const navigationExtras: NavigationExtras = {
        queryParams: {
          deliveryAuthorization: JSON.stringify(
            this.formDeliveryAuthorization.value
          )
        },
        // queryParamsHandling: 'merge',
        skipLocationChange: true
      };

      if (
        this.formDeliveryAuthorization.get('deliveryConsecutive').value &&
        this.formDeliveryAuthorization.get('authorizationKey').value
      ) {
        this._priorVisualizationService
          .findByDocksAvailableConsecutiveAndDeliveryAuthorizationId(
            this.formDeliveryAuthorization.get('authorizationKey').value.id
          )
          .subscribe(
            (data: DocksScheduling[]) => {
              

              if (data) {
                const docks = data;

                if (docks.length) {
                  this.navigateToDocksFound(docks);
                } else {
                  this.navigateToWithoutOption();
                }
              }
            },
            ({ error }) => {
              this.alertMsg(error, 'danger');
            }
          );

        // this._router.navigate(
        //   ['sdm/delivery-authorization/regular-delivery/manual-type-doc'],
        //   navigationExtras
        // );
        return;
      }

      switch (
        parseInt(this.formDeliveryAuthorization.get('uploadDocType').value)
      ) {
        case 1:
          this._router.navigate(
            ['sdm/delivery-authorization/regular-delivery/manual-type-doc'],
            navigationExtras
          );
          break;
        case 2:
          this._router.navigate(
            ['sdm/delivery-authorization/regular-delivery/unitary-upload'],
            navigationExtras
          );
          break;
        case 3:
          this._router.navigate(
            ['sdm/delivery-authorization/regular-delivery/massive-upload'],
            navigationExtras
          );
      }
    } else {
      this.verificaValidacoesForm(this.formDeliveryAuthorization);
    }
  }

  private navigateToWithoutOption() {
    this._router.navigate(
      ['sdm/delivery-authorization/regular-delivery/schedule-without-option'],
      this.prepareQueryParamsConsecutive('')
    );
  }

  private navigateToDocksFound(docks: DocksScheduling[]) {
    this._router.navigate(
      ['sdm/delivery-authorization/regular-delivery/allocation-finded'],
      this.prepareQueryParamsConsecutive(
        docks.length > 1 ? 'Alocação Diversas opções.' : 'Alocação 1 Opção',
        docks
      )
    );
  }

  alertMsg(message: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
    });
  }

  prepareQueryParamsConsecutive(
    title: string,
    docksScheduling?: DocksScheduling[],
    deliveryTime?: number
  ) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositors),
        // provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify([this.selectedAuthorization]),
        title
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    if (docksScheduling && docksScheduling.length) {
      navigationExtras.queryParams.docksScheduling = JSON.stringify(
        docksScheduling
      );
    }

    if (deliveryTime) {
      navigationExtras.queryParams.deliveryTime = deliveryTime;
    }

    return navigationExtras;
  }

  authorizationChanged(deliveryAuthorization) {
    
    this.selectedAuthorization = deliveryAuthorization;
  }

  // scheduleDelivery() {
  //   const {
  //     depositor: {
  //       id: depositorId,
  //       establishment: { id: establishmentId }
  //     },
  //     validityDate
  //   } = this.dimensioningInfo;

  //   const vehicleTypeId =
  //     this.dimensioningInfo.hasOwnProperty('vehicle') &&
  //     this.dimensioningInfo.vehicle != null
  //       ? this.dimensioningInfo.vehicle.id
  //       : null;
  //   this._docksService
  //     .findByDocksAvailable(
  //       depositorId,
  //       vehicleTypeId,
  //       validityDate,
  //       establishmentId,
  //       this.dimensioningInfo.qtdSlots
  //     )
  //     .subscribe(
  //       (data: DocksScheduling[]) => {
    //         if (data) {
  //           const docks = data;

  //           if (docks.length) {
  //             this._router.navigate(
  //               [
  //                 'sdm/delivery-authorization/regular-delivery/allocation-finded'
  //               ],
  //               this.prepareQueryParams(
  //                 docks.length > 1
  //                   ? 'Alocação Diversas opções. Prioritária'
  //                   : 'Alocação 1 Opção',
  //                 docks,
  //                 this.dimensioningInfo.qtdInMinutesProductivityGroups
  //               )
  //             );
  //           } else {
  //             this._router.navigate(
  //               [
  //                 'sdm/delivery-authorization/regular-delivery/schedule-without-option'
  //               ],
  //               this.prepareQueryParams('')
  //             );
  //           }
  //         }
  //       },
  //       ({ error }) => {
  //         this.alertMsg(error, 'danger');
  //       }
  //     );
  // }

  prepareQueryParams(navigateStr: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryParams: JSON.stringify(this.depositors)
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    this._router.navigate(
      [`sdm/delivery-authorization/regular-delivery/${navigateStr}`],
      navigationExtras
    );
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
            const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  onSelect(selected) {}

  establishmentChanged(establishment: Establishment) {
    if (establishment) {
      this.formDeliveryAuthorization.get('depositor').setValue([]);
      this.subscriptions.push(
        this._depositorService
          .findByEstablishmentId(establishment.id)
          .subscribe(data => {
            if (data && data.body['content'].length === 1) {
              const depositor = data.body['content'][0];
              this.formDeliveryAuthorization
                .get('depositor')
                .setValue(depositor);
              this.depositorChanged(depositor);
            }
            this.depositors = [...data.body['content']];
          })
      );
    }
  }

  depositorChanged(depositor: Depositor) {
    if (depositor) {
      const { id } = depositor;

      const getSolicitations = this._deliveryAuthorizationService.findByDepositorIdAndStatus(
        id,
        [4, 5, 9]
      );

      const getRegularDelivery = this._regularDeliveryService.findByDepositorId(
        id
      );

      forkJoin([getSolicitations, getRegularDelivery]).subscribe(response => {
        this.setSolicitations(response[0]);
        this.setRegularDelivery(response[1]);
      });
    } else {
      this.regularDelivery = null;
      this.solicitations = [];
    }
  }

  setSolicitations(solicitations: DeliveryAuthorization[]) {
    this.solicitations = solicitations;
  }

  setRegularDelivery(regularDelivery: RegularDelivery) {
    this.regularDelivery = regularDelivery;
  }

  cancelType() {
    this.formDeliveryAuthorization.enable();
    this.tabManualTypeEnabled = true;
    this.tabUploadUnitaryEnabled = true;
    this.tabMassiveLoad = true;
  }

  changeDeliveryConsecutive(event) {
    const { checked } = event.target;

    if (!checked) {
      this.formDeliveryAuthorization.get('authorizationKey').setValue('');
    }
  }
}
