import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeOperationNatureConverter'
})
export class PipeOperationNatureConverterPipe implements PipeTransform {
  private listBr = [
    { id: 'INBOUND', name: 'Entrega Regular' },
    { id: 'ENTREGA_REVERSA', name: 'Entrega Reversa' },
    { id: 'OUTBOUND', name: 'Coleta' }
  ];

  private listEn = [
    { id: 'INBOUND', name: 'Regular Delivery' },
    { id: 'ENTREGA_REVERSA', name: 'Reverse Delivery' },
    { id: 'OUTBOUND', name: 'Outbound' }
  ];

  private locales = [
    { list: this.listBr, lang: 'br' },
    { list: this.listEn, lang: 'en' }
  ];

  transform(value: any, args?: any): any {
    let lang = navigator.language.toLowerCase();

    for (let localeStatus of this.locales) {
      if (lang.endsWith(localeStatus.lang)) {
        for (let config of localeStatus.list) {
          if (config.id === value) {
            return config.name;
          }
        }
        return '';
      }
    }
  }
}
