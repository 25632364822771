import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'authorizationShape'
})
export class AuthorizationShapePipe implements PipeTransform {
  private authorizations = {
    1: 'Telefone',
    2: 'E-mail',
    3: 'Pessoalmente',
    4: 'Outros'
  };

  transform(value: any, args?: any): any {
    return this.authorizations[value];
  }
}
