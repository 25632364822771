import { SDM_URL } from './../../../../../app.api';
import { take } from 'rxjs/operators';
import { CollectRequestMaxService } from './../../../../../core/services/sdm/collect/collect-request-max.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { CollectRequestMax } from './../../../../../core/models/sdm/collect/collect-request-max';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../../register/abstract-register';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollectService as CollectEvents } from '../../collect.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-request-max-form',
  templateUrl: './request-max-form.component.html',
  styleUrls: ['./request-max-form.component.scss'],
})
export class RequestMaxFormComponent extends AbstractRegister<CollectRequestMax> implements OnInit {

  sub: Subscription;
  collectReqMax: CollectRequestMax;
  @ViewChild('dataGrdCollectReqMax') grid$: DatatableApiComponent;

  columnsRequestMax = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Estabelecimento' },
    { prop: 'establishment.description', name: 'Estabelecimento' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'requestMaxNf', name: 'Quantidade Maxima de NFs' },
    { prop: 'maxQtdLnfIssued', name: 'Qtdd Máxima de LNF por NF emitida' },
    { prop: 'minValueByNf', name: 'Valor Mínimo por NF (R$)' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _collectReqMaxService: CollectRequestMaxService,
    private _collectEvents: CollectEvents,
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this.tabReqMaxFired();
    this.collectReqMax = new CollectRequestMax();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl(this.collectReqMax.establishmentId, [Validators.required]),
      depositorId: new FormControl(this.collectReqMax.depositorId, [Validators.required]),
      description: new FormControl(''),
      requestMaxNf: new FormControl(this.collectReqMax.requestMaxNf, [Validators.required, Validators.min(1), Validators.max(999)]),
      maxQtdLnfIssued: new FormControl({ value: '', disabled: true }),
      minValueByNf: new FormControl({ value: '', disabled: true }),
    });
  }

  tabReqMaxFired() {
    this.sub = this._collectEvents.emitTabReqMaxFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());
  }

  disabledFieldsUpdate() {
    this.disableFields(['maxQtdLnfIssued', 'minValueByNf']);
  }

  afterAddButton() {
    this.disableFields(['maxQtdLnfIssued', 'minValueByNf']);
  }

  endPointCollectReqMax = () => SDM_URL.collect.requestMax.default;

  getNewInstance(): CollectRequestMax {
    return new CollectRequestMax();
  }
  getService(): AbstractService<CollectRequestMax> {
    return this._collectReqMaxService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'collect.request-max';
  }

}
