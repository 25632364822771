import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegularDeliveryEvents {

  emitTabOrderFired = new EventEmitter();
  emitTabReqMaxFired = new EventEmitter();
  emitTabSmallSizeFired = new EventEmitter();
  emitTabNfDeadLinesFired = new EventEmitter();

  tabOrder = () => this.emitTabOrderFired.emit();
  tabReqMaxFired = () => this.emitTabReqMaxFired.emit();
  tabSmallSizeFired = () => this.emitTabSmallSizeFired.emit();
  tabNfDeadLinesFired = () => this.emitTabNfDeadLinesFired.emit();

}
