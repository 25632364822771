import { Component, OnInit, Input, ContentChild, AfterContentInit, SkipSelf } from '@angular/core';
import { NgModel, FormControlName, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-input-container',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {

  @Input() label: string
  @Input() fieldName: string
  @Input() placeholder: string

  @Input() public groupName: string;

  input: any

  constructor(@SkipSelf() public group: ControlContainer) {

  }

  ngOnInit() {
  }

  hasSuccess(): boolean {
    return this.input.valid && (this.input.dirty || this.input.touched)
  }

  hasError(): boolean {
    return this.input.invalid && (this.input.dirty || this.input.dirty)
  }

}
