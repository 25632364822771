import { AuthService } from './../../../../../../core/services/auth.service';
import { EstablishmentService } from './../../../../../../core/services/establishment.service';
import { Depositor } from './../../../../../../core/models/sdm/depositor';
import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { take, map } from 'rxjs/operators';
import { ProductivityGroup } from '../../../../../../core/models/sdm/provider-management/productivity-group';
import { ByTimeUnitEnum } from '../../../../../../core/models/sdm/shared/enums/ByTimeUnitEnum';
import { AbstractService } from '../../../../../../core/services/abstract.service';
import { TranslatorService } from '../../../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../../abstract-register';
import { SDM_URL } from './../../../../../../app.api';
import { DatatableApiComponent } from './../../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../../core/classes/boolean-type';
import { ConferenceTypeEnum } from './../../../../../../core/models/sdm/shared/enums/ConferenceTypeEnum';
import { OperationNatureEnum } from './../../../../../../core/models/sdm/shared/enums/OperationNatureEnum';
import { UnitizationTypeEnum } from './../../../../../../core/models/sdm/shared/enums/UnitizationTypeEnum';
import { WeightProfileEnum } from './../../../../../../core/models/sdm/shared/enums/WeightProfileEnum';
import { DepositorService } from './../../../../../../core/services/depositor.service';
import { MeasureUnitService } from './../../../../../../core/services/measure-unit/measure-unit.service';
import { ProductivityGroupService } from './../../../../../../core/services/sdm/provider-management/productivity-group.service';
import { SelectFieldComponent } from '../../../../../../components/select/select-field/select-field.component';
import { MeasureUnitDepositorService } from '../../../../../../core/services/measure-unit/measure-unit-depositor.service';
import { object } from '@amcharts/amcharts4/core';

const CONTROL_FIELDS = [
  'fixWindowConferenceOp',
  'timeInMinutesConferenceOp',
  'setupConferenceOp',
  'timeInMinutesSetupConferenceOp',
  'fourthPQuantity',
  'fourthPMeasureUnit',
  'fourthPByTimeUnit',
  'fifthPQuantity',
  'fifthPMeasureUnit',
  'fifthPByTimeUnit',
  'sixPQuantity',
  'sixPMeasureUnit',
  'sixPByTimeUnit'
];

const FIELDS_CONTROL = [
  {
    formControl: 'fixWindowDischargeOp',
    control: 'timeInMinutesDischargeOp'
  },
  {
    formControl: 'setupDischargeOp',
    control: 'timeInMinutesSetupDischargeOp'
  },
  {
    formControl: 'fixWindowConferenceOp',
    control: 'timeInMinutesConferenceOp'
  },
  {
    formControl: 'setupConferenceOp',
    control: 'timeInMinutesSetupConferenceOp'
  }
];

@Component({
  selector: 'app-productivity-group-form',
  templateUrl: './productivity-group-form.component.html',
  styleUrls: ['./productivity-group-form.component.scss']
})
export class ProductivityGroupFormComponent
  extends AbstractRegister<ProductivityGroup>
  implements OnInit, AfterViewInit {
  public keys;
  public keysOpNature;
  public keysConfType;
  public keysUnitizationType;
  public keysWeightProfileType;
  byTimeUnitEnum = ByTimeUnitEnum;
  opNatureEnum = OperationNatureEnum;
  confTypeEnum = ConferenceTypeEnum;
  unitizationEnum = UnitizationTypeEnum;
  weightProfileEnum = WeightProfileEnum;
  @ViewChild('gridProductivityGroup') grid$: DatatableApiComponent;
  measureUnits = [];
  productivityGroup: ProductivityGroup;

  fixWindowTimeDischargeOp = false;
  setupDischargeOp = false;
  fixWindowConferenceOp = false;
  setupConferenceOp = false;

  establishments = [];

  @ViewChild('hdrTplOperationNature') hdrTplOperationNature: TemplateRef<any>;
  @ViewChild('bodyTplOperationNature') bodyTplOperationNature: TemplateRef<any>;

  @ViewChild('hdrTplConferenceType') hdrTplConferenceType: TemplateRef<any>;
  @ViewChild('bodyTplConferenceType') bodyTplConferenceType: TemplateRef<any>;

  @ViewChild('hdrTplUnitizationType') hdrTplUnitizationType: TemplateRef<any>;
  @ViewChild('bodyTplUnitizationType') bodyTplUnitizationType: TemplateRef<any>;

  @ViewChild('hdrTplWeightProfile') hdrTplWeightProfile: TemplateRef<any>;
  @ViewChild('bodyTplWeightProfile') bodyTplWeightProfile: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd1') hdrTplByTimeUnitProd1: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd1') bodyTplByTimeUnitProd1: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd2') hdrTplByTimeUnitProd2: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd2') bodyTplByTimeUnitProd2: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd3') hdrTplByTimeUnitProd3: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd3') bodyTplByTimeUnitProd3: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd4') hdrTplByTimeUnitProd4: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd4') bodyTplByTimeUnitProd4: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd5') hdrTplByTimeUnitProd5: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd5') bodyTplByTimeUnitProd5: TemplateRef<any>;

  @ViewChild('hdrTplByTimeUnitProd6') hdrTplByTimeUnitProd6: TemplateRef<any>;
  @ViewChild('bodyTplByTimeUnitProd6') bodyTplByTimeUnitProd6: TemplateRef<any>;

  @ViewChild('firstMeasureUnit') firstMeasureUnit: SelectFieldComponent;
  @ViewChild('secondMeasureUnit') secondMeasureUnit: SelectFieldComponent;
  @ViewChild('thirdMeasureUnit') thirdMeasureUnit: SelectFieldComponent;
  @ViewChild('fourthMeasure') fourthMeasure: SelectFieldComponent;
  @ViewChild('fifthMeasure') fifthMeasure: SelectFieldComponent;
  @ViewChild('sixMeasure') sixMeasure: SelectFieldComponent;

  @ViewChild('establishments') establishment: SelectFieldComponent;

  columnsProductivityGroup: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'groupName', name: 'Nome do Grupo', width: 250 },
    { prop: 'riskProfile', name: 'Perfil Risco', pipe: new BooleanConverter() },
    {
      prop: 'fixWindowDischargeOp',
      name: 'Janela Fixa? (Operação de Descarga)',
      pipe: new BooleanConverter(),
      width: 230
    },
    {
      prop: 'timeInMinutesDischargeOp',
      name: 'Duração (em minutos) (Operação de Descarga)',
      width: 270
    },
    {
      prop: 'setupDischargeOp',
      name: 'Setup Aplicável (Operação de Descarga)',
      pipe: new BooleanConverter(),
      width: 230
    },
    {
      prop: 'timeInMinutesSetupDischargeOp',
      name: 'Duração (em minutos) (Operação de Descarga)',
      width: 265
    },
    { prop: 'firstPQuantity', name: 'Quantidade Produtividade 1', width: 200 },
    {
      prop: 'firstPMeasureUnit',
      name: 'Unidade Medida Produtividade 1',
      width: 200
    },
    { prop: 'secondPQuantity', name: 'Quantidade Produtividade 2', width: 200 },
    {
      prop: 'secondPMeasureUnit',
      name: 'Unidade Medida Produtividade 2',
      width: 200
    },
    { prop: 'thirdPQuantity', name: 'Quantidade Produtividade 3', width: 200 },
    {
      prop: 'thirdPMeasureUnit',
      name: 'Unidade Medida Produtividade 3',
      width: 200
    },
    {
      prop: 'fixWindowConferenceOp',
      name: 'Janela Fixa? (Operação de Conferência)',
      pipe: new BooleanConverter(),
      width: 230
    },
    {
      prop: 'timeInMinutesConferenceOp',
      name: 'Duração (em minutos) (Operação de Conferência)',
      width: 320
    },
    {
      prop: 'setupConferenceOp',
      name: 'Setup Aplicável (Operação de Conferência)',
      pipe: new BooleanConverter(),
      width: 250
    },
    {
      prop: 'timeInMinutesSetupConferenceOp',
      name: 'Duração (em minutos) (Operação de Conferência)',
      width: 260
    },
    { prop: 'fourthPQuantity', name: 'Quantidade Produtividade 4', width: 200 },
    {
      prop: 'fourthPMeasureUnit',
      name: 'Unidade Medida Produtividade 4',
      width: 200
    },
    { prop: 'fifthPQuantity', name: 'Quantidade Produtividade 5', width: 200 },
    {
      prop: 'fifthPMeasureUnit',
      name: 'Unidade Medida Produtividade 5',
      width: 200
    },
    { prop: 'sixPQuantity', name: 'Quantidade Produtividade 6', width: 200 },
    {
      prop: 'sixPMeasureUnit',
      name: 'Unidade Medida Produtividade 6',
      width: 200
    }
  ];

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _productivityService: ProductivityGroupService,
    private _measureUnitService: MeasureUnitService,
    private cd: ChangeDetectorRef,
    private establishmentService: EstablishmentService,
    private authService: AuthService
  ) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.byTimeUnitEnum);

    this.keysOpNature = Object.keys(this.opNatureEnum).filter(
      value => value == 'ENTREGA_REGULAR'
    );
    this.keysConfType = Object.keys(this.confTypeEnum);
    this.keysUnitizationType = Object.keys(this.unitizationEnum);
    this.keysWeightProfileType = Object.keys(this.weightProfileEnum);
  }

  public ngAfterViewInit() {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.productivityGroup = new ProductivityGroup();

    this.columnsProductivityGroup.splice(6, 0, {
      name: 'Natureza da Operação',
      cellTemplate: this.bodyTplOperationNature,
      headerTempalte: this.hdrTplOperationNature
    });
    this.columnsProductivityGroup.splice(7, 0, {
      name: 'Tipo Conferência',
      cellTemplate: this.bodyTplConferenceType,
      headerTempalte: this.hdrTplConferenceType
    });
    this.columnsProductivityGroup.splice(8, 0, {
      name: 'Tipo Unitização',
      cellTemplate: this.bodyTplUnitizationType,
      headerTempalte: this.hdrTplUnitizationType
    });
    this.columnsProductivityGroup.splice(10, 0, {
      name: 'Perfil Peso',
      cellTemplate: this.bodyTplWeightProfile,
      headerTempalte: this.hdrTplWeightProfile
    });

    this.columnsProductivityGroup.splice(17, 0, {
      name: 'Por Unidade de Tempo Produtividade 1',
      cellTemplate: this.bodyTplByTimeUnitProd1,
      headerTempalte: this.hdrTplByTimeUnitProd1,
      width: 230
    });
    this.columnsProductivityGroup.splice(20, 0, {
      name: 'Por Unidade de Tempo Produtividade 2',
      cellTemplate: this.bodyTplByTimeUnitProd2,
      headerTempalte: this.hdrTplByTimeUnitProd2,
      width: 230
    });
    this.columnsProductivityGroup.splice(23, 0, {
      name: 'Por Unidade de Tempo Produtividade 3',
      cellTemplate: this.bodyTplByTimeUnitProd3,
      headerTempalte: this.hdrTplByTimeUnitProd3,
      width: 230
    });
    this.columnsProductivityGroup.splice(30, 0, {
      name: 'Por Unidade de Tempo Produtividade 4',
      cellTemplate: this.bodyTplByTimeUnitProd4,
      headerTempalte: this.hdrTplByTimeUnitProd4,
      width: 230
    });
    this.columnsProductivityGroup.splice(33, 0, {
      name: 'Por Unidade de Tempo Produtividade 5',
      cellTemplate: this.bodyTplByTimeUnitProd5,
      headerTempalte: this.hdrTplByTimeUnitProd5,
      width: 230
    });
    this.columnsProductivityGroup.splice(36, 0, {
      name: 'Por Unidade de Tempo Produtividade 6',
      cellTemplate: this.bodyTplByTimeUnitProd6,
      headerTempalte: this.hdrTplByTimeUnitProd6,
      width: 230
    });

    this.formulario = new FormGroup({
      id: new FormControl(this.productivityGroup.id),
      establishmentId: new FormControl(this.productivityGroup.establishmentId, [
        Validators.required
      ]),
      depositorId: new FormControl(this.productivityGroup.depositorId, [
        Validators.required
      ]),
      description: new FormControl(this.productivityGroup.description),
      groupName: new FormControl(this.productivityGroup.groupName, [
        Validators.maxLength(100)
      ]),
      operationNature: new FormControl(this.productivityGroup.operationNature),
      conferenceType: new FormControl(this.productivityGroup.conferenceType),
      unitizationType: new FormControl(this.productivityGroup.unitizationType),
      riskProfile: new FormControl(this.productivityGroup.riskProfile),
      weightProfile: new FormControl(this.productivityGroup.weightProfile),
      fixWindowDischargeOp: new FormControl(
        this.productivityGroup.fixWindowDischargeOp
      ),
      timeInMinutesDischargeOp: new FormControl(
        this.productivityGroup.timeInMinutesDischargeOp,
        [Validators.min(0), Validators.max(1440)]
      ),
      setupDischargeOp: new FormControl(
        this.productivityGroup.setupDischargeOp
      ),
      timeInMinutesSetupDischargeOp: new FormControl(
        this.productivityGroup.timeInMinutesSetupDischargeOp,
        [Validators.min(0), Validators.max(1440)]
      ),
      fixWindowConferenceOp: new FormControl(
        this.productivityGroup.fixWindowConferenceOp
      ),
      timeInMinutesConferenceOp: new FormControl(
        this.productivityGroup.timeInMinutesConferenceOp,
        [Validators.min(0), Validators.max(1440)]
      ),
      setupConferenceOp: new FormControl(
        this.productivityGroup.setupConferenceOp
      ),
      timeInMinutesSetupConferenceOp: new FormControl(
        this.productivityGroup.timeInMinutesSetupConferenceOp,
        [Validators.min(0), Validators.max(1440)]
      ),
      firstPQuantity: new FormControl(this.productivityGroup.firstPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      firstPMeasureUnit: new FormControl(
        this.productivityGroup.firstPMeasureUnit
      ),
      firstPByTimeUnit: new FormControl(
        this.productivityGroup.firstpByTimeUnit
      ),
      secondPQuantity: new FormControl(this.productivityGroup.secondPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      secondPMeasureUnit: new FormControl(
        this.productivityGroup.secondPMeasureUnit
      ),
      secondPByTimeUnit: new FormControl(
        this.productivityGroup.secondPByTimeUnit
      ),
      thirdPQuantity: new FormControl(this.productivityGroup.thirdPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      thirdPMeasureUnit: new FormControl(
        this.productivityGroup.thirdPMeasureUnit
      ),
      thirdPByTimeUnit: new FormControl(
        this.productivityGroup.thirdPByTimeUnit
      ),
      fourthPQuantity: new FormControl(this.productivityGroup.fourthPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      fourthPMeasureUnit: new FormControl(
        this.productivityGroup.fourthPMeasureUnit
      ),
      fourthPByTimeUnit: new FormControl(
        this.productivityGroup.fourthPByTimeUnit
      ),
      fifthPQuantity: new FormControl(this.productivityGroup.fifthPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      fifthPMeasureUnit: new FormControl(
        this.productivityGroup.fifthPMeasureUnit
      ),
      fifthPByTimeUnit: new FormControl(
        this.productivityGroup.fifthPByTimeUnit
      ),
      sixPQuantity: new FormControl(this.productivityGroup.sixPQuantity, [
        Validators.min(0),
        Validators.max(999999)
      ]),
      sixPMeasureUnit: new FormControl(this.productivityGroup.sixPMeasureUnit),
      sixPByTimeUnit: new FormControl(this.productivityGroup.sixPByTimeUnit)
    });

    const { id } = this.authService.getUser();

    this.establishmentService
      .findByUserId(id)
      .subscribe(data => (this.establishments = data));

    this.formulario.get('conferenceType').valueChanges.subscribe(selected => {
      
      if (selected === 'NO_ATO') {
        this.setRequiredForFields(['fixWindowConferenceOp']);
        this.enableFields(CONTROL_FIELDS);
        this.fourthMeasure.items = this.measureUnits;
        this.fifthMeasure.items = this.measureUnits;
        this.sixMeasure.items = this.measureUnits;
      } else {
        this.removeRequiredForFields(CONTROL_FIELDS);
        this.disableFields(CONTROL_FIELDS);
        this.setDefaultMeasureUnit();
      }
    });

    this.fieldsIsRequired(FIELDS_CONTROL);

    this.formulario
      .get('fixWindowDischargeOp')
      .valueChanges.subscribe(value => {
        const FIELDS_TO_CONTROL = [
          'setupDischargeOp',
          'timeInMinutesSetupDischargeOp',
          'firstPQuantity',
          'firstPMeasureUnit',
          'firstPByTimeUnit',
          'secondPQuantity',
          'secondPMeasureUnit',
          'secondPByTimeUnit',
          'thirdPQuantity',
          'thirdPMeasureUnit',
          'thirdPByTimeUnit'
        ];

        if (!value || value === 'false') {
          this.removeRequiredForFields(['timeInMinutesDischargeOp']);
          this.disableFields(['timeInMinutesDischargeOp']);

          this.enableFields(FIELDS_TO_CONTROL);

          this.firstMeasureUnit.items = this.measureUnits;
          this.secondMeasureUnit.items = this.measureUnits;
          this.thirdMeasureUnit.items = this.measureUnits;
          return;
        }

        this.formulario
          .get('timeInMinutesDischargeOp')
          .setValidators([Validators.min(1)]);
        this.formulario
          .get('timeInMinutesDischargeOp')
          .updateValueAndValidity();
        this.formulario.get('timeInMinutesDischargeOp').markAsUntouched();

        this.setRequiredForFields(['timeInMinutesDischargeOp']);
        this.enableFields(['timeInMinutesDischargeOp']);

        this.removeRequiredForFields(FIELDS_TO_CONTROL);
        this.disableFields(FIELDS_TO_CONTROL);

        this.setDefaultValueForFields([
          'firstPByTimeUnit',
          'secondPByTimeUnit',
          'thirdPByTimeUnit'
        ]);

        this.firstMeasureUnit.items = this.measureUnits;
        this.secondMeasureUnit.items = this.measureUnits;
        this.thirdMeasureUnit.items = this.measureUnits;
      });

    this.formulario
      .get('fixWindowConferenceOp')
      .valueChanges.subscribe(value => {
        const FIELD_TO_CONTROL2 = [
          'setupConferenceOp',
          'timeInMinutesSetupConferenceOp',
          'fourthPQuantity',
          'fourthPMeasureUnit',
          'fourthPByTimeUnit',
          'fifthPQuantity',
          'fifthPMeasureUnit',
          'fifthPByTimeUnit',
          'sixPQuantity',
          'sixPMeasureUnit',
          'sixPByTimeUnit'
        ];

        if (!value || value === 'false') {
          this.removeRequiredForFields(['timeInMinutesConferenceOp']);
          this.disableFields(['timeInMinutesConferenceOp']);

          this.enableFields(FIELD_TO_CONTROL2);

          this.fourthMeasure.items = this.measureUnits;
          this.fifthMeasure.items = this.measureUnits;
          this.sixMeasure.items = this.measureUnits;
          return;
        }

        this.formulario
          .get('timeInMinutesConferenceOp')
          .setValidators([Validators.min(1)]);
        this.formulario
          .get('timeInMinutesConferenceOp')
          .updateValueAndValidity();
        this.formulario.get('timeInMinutesConferenceOp').markAsUntouched();

        this.setRequiredForFields(['timeInMinutesConferenceOp']);
        this.enableFields(['timeInMinutesConferenceOp']);

        this.removeRequiredForFields(FIELD_TO_CONTROL2);
        this.disableFields(FIELD_TO_CONTROL2);

        this.setDefaultValueForFields([
          'fourthPByTimeUnit',
          'fifthPByTimeUnit',
          'sixPByTimeUnit'
        ]);

        this.fourthMeasure.items = this.measureUnits;
        this.fifthMeasure.items = this.measureUnits;
        this.sixMeasure.items = this.measureUnits;
      });
  }

  setDefaultValueForFields(fields: string[]) {
    for (let field of fields) {
      this.formulario.get(field).setValue('DEFAULT');
    }
  }

  establishmentChanged(value) {
    if (!value) {
      return;
    }

    this.depositorService.findByEstablishmentId(value.id).subscribe(data => {
      this.formulario.get('depositorId').setValue([]);

      this.depositors = [...data.body['content']];

      if (this.depositors.length === 1) {
        this.formulario.get('depositorId').setValue(this.depositors[0]);
        this.changedDepositor(this.depositors[0]);
      }
    });
  }

  fieldsIsRequired(fields: any) {
    for (let field of fields) {
      this.formulario
        .get(field['formControl'])
        .valueChanges.subscribe(value => {
          if (!value) {
            this.removeRequiredForFields([field['control']]);
            this.disableFields([field['control']]);
            return;
          }
          this.setRequiredForFields([field['control']]);
          this.enableFields([field['control']]);
        });
    }
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }
    this.subscriptions.push(
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .subscribe(data => {
          if (this.formulario.controls.hasOwnProperty('depositor')) {
            this.formulario.get('depositor').setValue('');
          } else {
            this.formulario.get('depositorId').setValue('');
          }
          this.depositors = [...data.body['content']];
          
          if (this.depositors.length === 1) {
            if (this.formulario.controls.hasOwnProperty('depositor')) {
              this.formulario.get('depositor').setValue(this.depositors[0]);
            } else {
              this.formulario.get('depositorId').setValue(this.depositors[0]);
            }
            this.changedDepositor(this.depositors[0]);
          }
        })
    );
  }

  changedDepositor(depositor: Depositor) {
    
    if (depositor) {
      this.loadMeasureUnits(depositor['establishment'].id, depositor.id);
    } else {
      this.measureUnits = [];

      [
        'secondPMeasureUnit',
        'firstPMeasureUnit',
        'thirdPMeasureUnit',
        'fifthPMeasureUnit',
        'fourthPMeasureUnit',
        'sixPMeasureUnit'
      ].forEach(element => {
        this.formulario.get(element).setValue([]);
      });
    }
  }

  setDefaultMeasureUnit() {
    this.formulario.get('fourthPByTimeUnit').setValue(0);
    this.formulario.get('fifthPByTimeUnit').setValue(0);
    this.formulario.get('sixPByTimeUnit').setValue(0);
  }

  loadMeasureUnits(establishmentId: number, depositorId: number) {
    this._measureUnitService
      .findByDepositorIdAndEstablishmentIdAndStatusTrue(
        establishmentId,
        depositorId
      )
      .subscribe((data: any) => {
        this.measureUnits = data.map(m => m['measureUnit']);
      });
  }

  addButton() {
    this.productivityGroup = new ProductivityGroup();
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    // this.loadMeasureUnits();
    this.setMeasureUnitForAll();
    this.formulario.patchValue(this.productivityGroup);
    this.establishment.items = this.establishments;
  }

  setMeasureUnitForAll() {
    this.firstMeasureUnit.items = this.measureUnits;
    this.secondMeasureUnit.items = this.measureUnits;
    this.thirdMeasureUnit.items = this.measureUnits;
    this.fourthMeasure.items = this.measureUnits;
    this.fifthMeasure.items = this.measureUnits;
    this.sixMeasure.items = this.measureUnits;
  }

  formFillToEdit(row) {
    
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor']);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }

    this.formulario.enable();
    // this.detectFieldChangesOnUpdate();
    this._measureUnitService
      .findByDepositorIdAndEstablishmentIdAndStatusTrue(
        row['depositor']['establishment'].id,
        row['depositor'].id
      )
      .pipe(
        map((data: any) => {
          this.measureUnits = data.map(m => m['measureUnit']);
        })
      )
      .subscribe(() => {
        setTimeout(() => this.setMeasureUnitOnUpdate(row), 1);
      });

    // this.loadMeasureUnits();
    this.statusOfTimeInMinutesSetupDischarge();
    // this.formulario.get('firstPMeasureUnit').setValue(this.measureUnits[2]);
  }

  detectFieldChangesOnUpdate() {
    for (let field of FIELDS_CONTROL) {
      if (!this.formulario.get(field['formControl']).value) {
        this.removeRequiredForFields([field['control']]);
        this.disableFields([field['control']]);
      } else {
        this.setRequiredForFields([field['control']]);
        this.enableFields([field['control']]);
      }
    }
  }

  setMeasureUnitOnUpdate(row: any) {
    [
      'firstPMeasureUnit',
      'secondPMeasureUnit',
      'thirdPMeasureUnit',
      'fourthPMeasureUnit',
      'fifthPMeasureUnit',
      'sixPMeasureUnit'
    ].forEach(measure => {
      if (this.measureUnits.length) {
        let element = this.measureUnits.map(m => m['id']).indexOf(row[measure]);
        if (element) {
          let itemObjFinded = this.measureUnits[element];

          if (itemObjFinded) {
            this.formulario.get(measure).patchValue(itemObjFinded);
            
            
          }
        }
      }
    });
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.disable();

    this._measureUnitService
      .findByDepositorIdAndEstablishmentIdAndStatusTrue(
        row['depositor']['establishment'].id,
        row['depositor'].id
      )
      .pipe(
        map((data: any) => {
          this.measureUnits = data.map(m => m['measureUnit']);
        })
      )
      .subscribe(() => {
        setTimeout(() => this.setMeasureUnitOnUpdate(row), 1);
      });
    // this.loadMeasureUnits();
  }

  changedFixWindowDischargeOp(event) {
    this.fixWindowTimeDischargeOp = event.target.value === 'true';
    this.statusOfTimeInMinutesDischargeOp();
  }

  statusOfTimeInMinutesDischargeOp() {
    if (!this.fixWindowTimeDischargeOp) {
      this.removeRequiredForFields(['timeInMinutesDischargeOp']);
      this.disableFields(['timeInMinutesDischargeOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesDischargeOp']);
    this.enableFields(['timeInMinutesDischargeOp']);
  }

  changeSetupDischargeOp(event) {
    this.setupDischargeOp = event.target.value === 'true';
    this.statusOfTimeInMinutesSetupDischarge();
  }

  statusOfTimeInMinutesSetupDischarge() {
    if (!this.setupDischargeOp) {
      this.removeRequiredForFields(['timeInMinutesSetupDischargeOp']);
      this.disableFields(['timeInMinutesSetupDischargeOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesSetupDischargeOp']);
    this.enableFields(['timeInMinutesSetupDischargeOp']);
  }

  changeFixWindowConferenceOp(event) {
    this.fixWindowConferenceOp = event.target.value === 'true';
    this.statusOfTimeInMinutesConferenceOp();
  }

  statusOfTimeInMinutesConferenceOp() {
    if (!this.fixWindowConferenceOp) {
      this.removeRequiredForFields([
        'timeInMinutesConferenceOp',
        'fourthPQuantity',
        'fourthPMeasureUnit',
        'fourthPByTimeUnit'
      ]);
      this.disableFields(['timeInMinutesConferenceOp']);
      return;
    }
    this.setRequiredForFields([
      'timeInMinutesConferenceOp',
      'fourthPQuantity',
      'fourthPMeasureUnit',
      'fourthPByTimeUnit'
    ]);
    this.enableFields(['timeInMinutesConferenceOp']);
  }

  changeSetupConferenceOp(event) {
    this.setupConferenceOp = event.target.value === 'true';
    this.statusOfTimeInMinutesSetupConference();
  }

  statusOfTimeInMinutesSetupConference() {
    if (!this.setupConferenceOp) {
      this.removeRequiredForFields(['timeInMinutesSetupConferenceOp']);
      this.disableFields(['timeInMinutesSetupConferenceOp']);
      return;
    }
    this.setRequiredForFields(['timeInMinutesSetupConferenceOp']);
    this.enableFields(['timeInMinutesSetupConferenceOp']);
  }

  changeConferenceType(event) {
    const selected = event.target.value;
    if (selected === 'NO_ATO') {
      this.setRequiredForFields(['fixWindowConferenceOp']);
      this.enableFields(['fixWindowConferenceOp']);
    } else {
      this.removeRequiredForFields(['fixWindowConferenceOp']);
      this.disableFields(['fixWindowConferenceOp']);
    }
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.getRawValue())
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.convertObjMeasureUnitToIDOnUpdate();
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.getRawValue())
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  convertObjMeasureUnitToIDOnUpdate() {
    [
      'firstPMeasureUnit',
      'secondPMeasureUnit',
      'thirdPMeasureUnit',
      'fourthPMeasureUnit',
      'fifthPMeasureUnit',
      'sixPMeasureUnit'
    ].forEach(measure => {
      if (typeof this.formulario.get(measure).value == 'object') {
        this.formulario
          .get(measure)
          .setValue(this.formulario.get(measure).value['id']);
      }
    });
      }

  endPointProductivityGroup = () =>
    SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.default;
  endpointUpload = () =>
    SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.upload;
  endPointPartial = () =>
    SDM_URL.providerManagement.docksDimensioningTime.productivityGroup
      .importPartial;

  getNewInstance(): ProductivityGroup {
    return new ProductivityGroup();
  }
  getService(): AbstractService<ProductivityGroup> {
    return this._productivityService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.docks-dimensioning-time.productivity-group';
  }
}
