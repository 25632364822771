import { Injectable } from "@angular/core";

@Injectable()
export class VehicleServiceEvents {

  vehicles: Object[] = [];

  getVehicles() {
    return this.vehicles;
  }

  addVehicle(vehicle: Object[]) {
    this.vehicles.push(vehicle);
    
  }

}