import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-delivery',
  templateUrl: './pending-delivery.component.html',
  styleUrls: ['./pending-delivery.component.scss']
})
export class PendingDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
