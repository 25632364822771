import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[integerOnly]'
})
export class IntegerOnlyDirective {
    private regex: RegExp = new RegExp(/[^0-9]/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }else if(event.key === ',' || event.key === '.' || event.key === 'e' || event.key === 'E'){
            event.preventDefault();
        }
        /*
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
            this.el.nativeElement.value = next;
        }
        */
    }
}