import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SlotService {

  constructor() { }

  emitEventTabSlotRoundFired = new EventEmitter();
  emitEventTabSlotReserveFired = new EventEmitter();

  tabSlotRoundFired = () => this.emitEventTabSlotRoundFired.emit();
  tabSlotReserveFired = () => this.emitEventTabSlotReserveFired.emit();
}
