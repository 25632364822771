import { AbstractStatusModel } from '../../abstractStatus.model';

export class Collect extends AbstractStatusModel {
  public establishmentId: number;
  public depositorId: number;
  public shippingRequest: boolean;
  public providerRequest: boolean;
  public internalUserRequest: boolean;
  public directAllocation: boolean;
  public infoDataOnNF: boolean;
  public linkNfOrQtd: boolean;
  public maxValueForEnableVehicle: number;
  public containsNfOfOthersDepositors: boolean;
  public fixedDoca: boolean;
  public docaNumber: number;
  public dimensionProductivity: number;
  public minAntecedenceForShippingToCome: number;
  public maxAntecedenceForShippingToCome: number;

  constructor() {
    super();
    this.status = true;
    this.containsNfOfOthersDepositors = true;
    this.providerRequest = false;
    this.internalUserRequest = true;
    this.dimensionProductivity = 1;
    this.shippingRequest = false;
    this.directAllocation = true;
  }

}
