import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../../app.api';
import { environment } from './../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';
import { CompatibilityDocks } from '../../../../models/sdm/provider-management/compatibility-docks';

@Injectable({
  providedIn: 'root',
})
export class CompatibilityDocksService extends AbstractService<CompatibilityDocks>  {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.default);
  }

  activeDeactiveGroup(compatibilityDocksId: string, compatibilityDocksGroupId: string): Observable<any> {
    return this.http.delete<any>(`${SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.activeDeactiveGroup.replace(':compatibilityDocksId', compatibilityDocksId).replace(':compatibilityDocksGroupId', compatibilityDocksGroupId)}`, { observe: 'response' });
  }
}
