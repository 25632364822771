import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";
import { SolutionLocale } from "../../models/sdm/solution/solution-locale";
import { AbstractService } from "../abstract.service";

@Injectable()
export class SolutionLocaleService extends AbstractService<SolutionLocale> {

    constructor(public http: HttpClient) {
        super(http, env.host.sdm, '/wrapper/solutionLocale');
    }

    // private URL = '/wrapper/solutionLocale'

    // create(model: any): Observable<SolutionLocale> {
    //     return this.http.post<SolutionLocale>(`${env.host.sdm}${this.URL}`, model)
    // }

    // edit(model: any): Observable<HttpResponse<any>> {
    //     throw new Error("Method not implemented.");
    // }

    // findById(id: Number): Observable<any> {
    //     throw new Error("Method not implemented.");
    // }

    // findAll(): Observable<HttpResponse<any>> {
    //     throw new Error("Method not implemented.");
    // }

    // activeDeactive(id: Number): Observable<any> {
    //     throw new Error("Method not implemented.");
    // }


}