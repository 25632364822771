import { AuthService } from './../../../core/services/auth.service';
import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  user: any;
  username = '';
  role = '';
  constructor(public userblockService: UserblockService, public auth: AuthService) {

    this.user = {
      picture: 'assets/img/user/default-user.png'
    };
  }

  ngOnInit() {
    this.username = this.auth.getUser()['username'];
    this.role =  this.auth.getUser()['authority'].name;
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

}
