import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { MeasureUnitDepositor } from '../../models/sdm/measure-unit/measure-unit-depositor';

@Injectable()
export class MeasureUnitDepositorService {
  private URL =
    '/wrapper/measure-unit/depositor/:depositorId/establishment/:establishmentId';

  constructor(public http: HttpClient) {}

  activeDeactive(depositorId, establishmentId, id): Observable<any> {
    return this.http.delete<any>(
      `${env.host.sdm}${this.URL.replace(':depositorId', depositorId).replace(
        ':establishmentId',
        establishmentId
      )}/activeDeactive/${id}`,
      { observe: 'response' }
    );
  }

  create(depositorId, establishmentId): Observable<MeasureUnitDepositor> {
    return this.http.post<MeasureUnitDepositor>(
      `${env.host.sdm}${this.URL.replace(':depositorId', depositorId).replace(
        ':establishmentId',
        establishmentId
      )}`,
      {}
    );
  }
}
