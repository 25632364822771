import { Subscription } from 'rxjs';
import { ModalCustomComponent } from './../../../../../components/modal-custom/modal-custom.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeliveryAuthorization } from './../../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { CollectScheduleCancel } from './../../../../../core/models/sdm/schedules/collect-schedule-cancel';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { Provider } from '../../../../../core/models/sdm/provider-management/provider';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { DeliveryAuthorizationService } from '../../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';

const REQUEST_BY_DEPOSITOR = '2';

@Component({
  selector: 'app-collect-cancel',
  templateUrl: './collect-cancel.component.html',
  styleUrls: ['./collect-cancel.component.scss'],
  preserveWhitespaces: true
})
export class CollectCancelComponent implements OnInit {
  formCancel: FormGroup;
  formEnabled = true;

  bsModalRef: BsModalRef;

  depositors: Depositor[] = [];
  providers: Provider[] = [];
  deliveryAuthorizations: DeliveryAuthorization[] = [];
  docks: PriorVisualization[] = [];

  alerts: any = [];
  dismissible = true;

  subscriptions: Subscription[] = [];

  collectCancel: CollectScheduleCancel;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    sanitizer: DomSanitizer,
    public modalService: BsModalService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.collectCancel = this._route.snapshot.data['collectSchedule'];
    
    this.depositors = [this.collectCancel.depositor];
    this.providers = [this.collectCancel.docksScheduling.provider];
    this.deliveryAuthorizations = [
      this.collectCancel.docksScheduling.deliveryAuthorization
    ];
    this.docks = [this.collectCancel.docksScheduling.priorVisualization];

    this.formCancel = new FormGroup({
      depositorId: new FormControl(this.collectCancel.depositor),
      providerId: new FormControl(this.collectCancel.docksScheduling.provider),
      deliveryAuthorization: new FormControl(this.collectCancel),
      requestedBy: new FormControl(REQUEST_BY_DEPOSITOR),
      dateScheduleDelivery: new FormControl(
        this.collectCancel.docksScheduling.dateScheduleDelivery
      ),
      carShowSchedule: new FormControl(
        this.collectCancel.docksScheduling.carShowSchedule
      ),
      initialHour: new FormControl(
        this.collectCancel.docksScheduling.initialHour
      ),
      finalHour: new FormControl(this.collectCancel.docksScheduling.finalHour),
      priorVisualization: new FormControl(
        this.collectCancel.docksScheduling.priorVisualization
      )
    });
  }

  openModalConfirmCancel() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja Cancelar? O Agendamento será cancelado e portanto não será possível efetuar a Operação.',
      classBtnSuccess: 'btn-outline-danger',
      classBtnDanger: 'btn-success'
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._deliveryAuthorizationService
              .cancelDeliveryAuthorization(
                this.collectCancel.docksScheduling.deliveryAuthorization.id
              )
              .subscribe(
                () => this._router.navigate(['sdm/register/schedules/collect']),
                error => this.alertMsg(error, 'error')
              );
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  confirmContinue() {
    this.openModalConfirmCancel();
  }

  goBack() {
    this._router.navigate(['sdm/register/schedules/collect']);
  }

  alertMsg(message?: string, type = 'success', timeout = 2500) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout: timeout
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  selectedDepositor() {}
}
