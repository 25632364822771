import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

const INPUT_FIELD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldComponent),
  multi: true
};

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [INPUT_FIELD_VALUE_ACCESSOR]
})
export class InputFieldComponent implements ControlValueAccessor, OnChanges {
  @Input() classeCss;
  @Input() id: string;
  @Input() label = '';
  @Input() name = '';
  @Input() i18n = true;
  @Input() type = 'text';
  @Input() isRequired = false;
  @Input() isReadOnly = false;
  @Input() maskInput = '';
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() messageHelp: string;
  @Input() dropSpecialCharact = true;
  @Input() isForm = true;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() placeHolder = '';
  @ViewChild('inputText') inputText: ElementRef;

  private innerValue: any;

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCb(v);
    }
  }

  constructor(private _renderer: Renderer2) {}

  readOnlyInput = () => this.form.disabled;

  onChangeCb: (_: any) => void = () => {};
  onTouchedCb: (_: any) => void = () => {};

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    try {
      this.onTouchedCb = fn;
    } catch (e) {
      console.error(e);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    // this.isReadOnly = isDisabled;
    // this._renderer.setProperty(
    //   this.inputText.nativeElement,
    //   'disabled',
    //   isDisabled
    // );
    this.isReadOnly = isDisabled;
  }

  isInvalid(): boolean {
    if (this.control && this.form) {
      if (this.form.disabled || this.control.disabled) {
        this.control.markAsPristine({ onlySelf: true });
        return false;
      }
      return (
        !this.control.valid && (this.control.dirty || this.control.touched)
      );
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (typeof this.isRequired !== 'undefined' && this.control) {
    //   const required: SimpleChange = changes.isRequired;
    //   if (typeof required !== 'undefined' && required.currentValue) {
    //     this.control.setValidators(Validators.required);
    //     this.control.updateValueAndValidity();
    //     return;
    //   }
    //   this.control.clearValidators();
    //   this.control.updateValueAndValidity();
    // }
  }
}
