import { SDM_URL } from './../../../../app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { IdlenessDocks } from '../../../models/sdm/logisticcosts/idleness-docks';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdlenessDocksService extends AbstractService<IdlenessDocks> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.logisticCosts.idlenessDocks.default
    );
  }
}
