import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../app.api';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { Provider } from '../../../models/sdm/provider-management/provider';
import { environment as env } from '../../../../../environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends AbstractService<Provider> {
  
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.providerManagement.provider.default);
  }

  findByEstablishmentIdAndDepositorIdAndSolutionIdAndAgentId(establishmentId: number, depositorId: number, solutionId: number, agentId: number): any {
    return this.http
    .get<Provider[]>(
      `${SDM_URL.providerManagement.provider.findByEstablishmentIdAndDepositorIdAndSolutionIdAndAgentId
        .replace(':establishmentId', establishmentId.toString())
        .replace(':depositorId', depositorId.toString())
        .replace(':solutionId', solutionId.toString())
        .replace(':agentId', agentId.toString())
      }`
    ).pipe(take(1));
  }

  findByEstablishmentIdAndDepositorIdAndSolutionId(establishmentId: number, depositorId: number, solutionId: number): any {
    return this.http
    .get<Provider[]>(
      `${SDM_URL.providerManagement.provider.findByEstablishmentIdAndDepositorIdAndSolutionId
        .replace(':establishmentId', establishmentId.toString())
        .replace(':depositorId', depositorId.toString())
        .replace(':solutionId', solutionId.toString())
      }`
    ).pipe(take(1));
  }

  findByDepositorId(depositorId: number): Observable<Provider[]> {
    return this.http
      .get<Provider[]>(
        `${SDM_URL.providerManagement.provider.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )}`
      )
      .pipe(take(1));
  }

  findByEstablishmentIdAndDepositorId(
    establishmentId: number,
    depositorId: number
  ): Observable<Provider[]> {
    return this.http.get<Provider[]>(
      `${SDM_URL.providerManagement.provider.findByEstablishmentIdAndDepositorId
        .replace(':establishmentId', establishmentId.toString())
        .replace(':depositorId', depositorId.toString())}`
    );
  }

  findByEstablishmentId(establishmentId: number): Observable<Provider[]> {
    return this.http
      .get<Provider[]>(
        `${SDM_URL.providerManagement.provider.findByEstablishmentId.replace(
          ':establishmentId',
          establishmentId.toString()
        )}`
      )
      .pipe(take(1));
  }
}
