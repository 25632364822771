import { Depositor } from './../depositor';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class DocksOptions extends AbstractStatusModel {
  public establishmentId: number;
  public depositorId: number;
  public depositor: Depositor;
  public qtdMaxOptionsVip: number;
  public qtdMaxOptionsNVip: number;
  public allocationByHour: boolean;
  public showDocks: boolean;

  constructor() {
    super();
    this.allocationByHour = false;
    this.showDocks = true;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
