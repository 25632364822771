import { Injectable } from "@angular/core";
import { ServiceInterface } from "../service-interface";
import { Observable } from "rxjs";
import { HttpResponse, HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";
import { SolutionProfile } from "../../models/sdm/solution/solution-profile";
import { AbstractService } from "../abstract.service";

@Injectable()
export class SolutionProfileService extends AbstractService<SolutionProfile> {

    constructor(public http: HttpClient) {
        super(http, env.host.sdm, '/wrapper/solutionProfile');
    }

    // private URL = '/wrapper/solutionProfile'

    // create(model: SolutionProfile): Observable<SolutionProfile> {
    //     return this.http.post<SolutionProfile>(`${env.host.sdm}${this.URL}`, model)
    // }

    // edit(model: SolutionProfile): Observable<HttpResponse<SolutionProfile>> {
    //     return this.http.put<SolutionProfile>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
    // }

    // findById(id: number): Observable<SolutionProfile> {
    //     throw new Error("Method not implemented.");
    // }

    // findAll(): Observable<HttpResponse<SolutionProfile>> {
    //     throw new Error("Method not implemented.");
    // }

    // activeDeactive(id: number): Observable<SolutionProfile> {
    //     throw new Error("Method not implemented.");
    // }

    // deleteById(id: number) {
    //     return this.http.delete(`${env.host.sdm}${this.URL}/${id}`)
    // }

}