import { AbstractStatusModel } from '../../abstractStatus.model';
import { CharacterType } from '../shared/enums/CharacterType';

export class CollectRmAuthorization extends AbstractStatusModel {
  public establishmentId: number;
  public depositorId: number;
  public requiredRma: boolean;
  public size: number;
  public rmaType: CharacterType;
  public allowRepeat: boolean;

  public documentSender: boolean;
  public documentRecipient: boolean;
  public documentShipping: boolean;

  public qtdLines: boolean;
  public qtdUnitOrParts: boolean;
  public qtdVolume: boolean;
  public totalValue: boolean;
  public totalGrossWeight: boolean;
  public totalNetWeight: boolean;
  public qtdPallet: boolean;
  public qtdCubicMeters: boolean;

  constructor() {
    super();
    this.requiredRma = false;
    this.rmaType = CharacterType.ALFA;
    this.allowRepeat = true;
    this.documentSender = false;
    this.documentRecipient = false;
    this.documentShipping = true;
  }

}
