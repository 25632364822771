import { SDM_URL } from './../../../../../app.api';
import { environment } from './../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class UnitaryUploadService extends AbstractService<Document> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.deliveryAuthorization.regularDelivery.unitaryUpload.default);
  }
}
