import { ReverseDeliveryEvents } from './../../reverse-delivery.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReverseDeliveryRequestMaxService } from '../../../../../core/services/sdm/reverse-delivery/reverse-delivery-request-max.service';
import { DepositorService } from '../../../../../core/services/depositor.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { ReverseDeliveryRequestMax } from '../../../../../core/models/sdm/reverse-delivery/reverse-delivery-request-max';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { SDM_URL } from '../../../../../app.api';
import { Subscription } from 'rxjs';
import { AbstractDelivery } from '../../../regular-delivery/abstract-delivery';
import { AbstractService } from '../../../../../core/services/abstract.service';

@Component({
  selector: 'app-reverse-delivery-req-max-form',
  templateUrl: './reverse-delivery-req-max-form.component.html',
  styleUrls: ['./reverse-delivery-req-max-form.component.scss'],
})
export class ReverseDeliveryReqMaxComponent extends AbstractDelivery<ReverseDeliveryRequestMax> implements OnInit {

  depositors = [];
  sub: Subscription;
  reverseDeliveryReqMax: ReverseDeliveryRequestMax;
  @ViewChild('dataGrdReqMax') grid: DatatableApiComponent;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    private _reqDelivReqMaxService: ReverseDeliveryRequestMaxService,
    public depositorService: DepositorService,
    public reverseDeliveryServiceEvents: ReverseDeliveryEvents,
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  columnsRequestMax = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'requestMaxNf', name: 'Máximo de NFs' },
    { prop: 'maxQtdLnfIssued', name: 'Máximo de LNF / NF' },
    { prop: 'minValueByNf', name: 'Valor Mínimo / NF (R$)' },
  ];

  ngOnInit() {
    this.tabReqMaxFired();

    this.reverseDeliveryReqMax = new ReverseDeliveryRequestMax();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl(this.reverseDeliveryReqMax.establishmentId, [Validators.required]),
      depositorId: new FormControl(this.reverseDeliveryReqMax.depositorId, [Validators.required]),
      description: new FormControl(''),
      requestMaxNf: new FormControl(this.reverseDeliveryReqMax.requestMaxNf, [Validators.required, Validators.min(1), Validators.max(999)]),
      maxQtdLnfIssued: new FormControl({ vaule: '', readonly: 'true' }),
      minValueByNf: new FormControl({ vaule: '', readonly: 'true' }),
    });
  }

  tabReqMaxFired() {
    this.sub = this.reverseDeliveryServiceEvents.emitTabReqMaxFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  disabledFields() {
    this.disableFields(['maxQtdLnfIssued', 'minValueByNf']);
  }

  makeEndPointForRequestMax = () => SDM_URL.reverseDelivery.requestMax.default;

  getComponentName(): string {
    return 'reverse-delivery.request-max';
  }

  getNewInstance(): ReverseDeliveryRequestMax {
    return new ReverseDeliveryRequestMax();
  }
  getService(): AbstractService<ReverseDeliveryRequestMax> {
    return this._reqDelivReqMaxService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }


}
