import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { AbstractDelivery } from '../../../regular-delivery/abstract-delivery';
import { ReverseDeliveryEvents } from '../../reverse-delivery.service';
import { SDM_URL } from './../../../../../app.api';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { ReverseDeliveryNF } from './../../../../../core/models/sdm/reverse-delivery/reverse-delivery-nf';
import { DeliveryType } from './../../../../../core/models/sdm/shared/enums/DeliveryType';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { ReverseDeliveryNfService } from './../../../../../core/services/sdm/reverse-delivery/reverse-delivery-nf.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';

@Component({
  selector: 'app-reverse-delivery-nf-form',
  templateUrl: './reverse-delivery-nf-form.component.html',
  styleUrls: ['./reverse-delivery-nf-form.component.scss'],
})
export class ReverseDeliveryNfFormComponent extends AbstractDelivery<ReverseDeliveryNF> implements OnInit {

  public keys;
  depositors = [];
  sub: Subscription;
  registerNoShow = false;
  deliveryType = DeliveryType;
  reverseDeliveryNF: ReverseDeliveryNF;
  @ViewChild('grdDataNfDeadline') grid: DatatableApiComponent;
  options =
    [
      { name: 'reverse-delivery.nf-deadlines.options.true', value: true },
      { name: 'reverse-delivery.nf-deadlines.options.false', value: false }
    ];

  allowUpdateRegisterSetOptions =
    [
      { name: 'reverse-delivery.nf-deadlines.options.true', value: true },
      { name: 'reverse-delivery.nf-deadlines.options.false', value: false }
    ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _revDeliveryNfDeadlineService: ReverseDeliveryNfService,
    public reverseDeliveryServiceEvents: ReverseDeliveryEvents) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.deliveryType);
  }

  columnsNF = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'autoNoShowRegister', name: 'No Show Automático?', pipe: new BooleanConverter() },
    { prop: 'afterQtdTime', name: 'Após quanto tempo (min)?' },
    { prop: 'allowUpdateRegister', name: 'Alterar Registro?', pipe: new BooleanConverter() },
    { prop: 'onlyInZeroDay', name: 'Somente em D0?', pipe: new BooleanConverter() },
    { prop: 'maxDeadlineDelivery', name: 'Prazo MAX Entrega' },
    { prop: 'minConfirmNewAuthorizationExternalUser', name: 'Antecedência MIN p/ Agendamento Usuário externo' },
    { prop: 'minReschedulingConfirmExternalUser', name: 'Antecedência MIN p/ Reagendamento Usuário externo' },
    { prop: 'minCancelRequestExternalUser', name: 'Antecedência MIN p/ Cancelamento Usuário Externo' },
  ];

  ngOnInit() {
    this.tabNfFired();

    this.reverseDeliveryNF = new ReverseDeliveryNF();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      minDeadLineRequired: new FormControl('', [Validators.min(1), Validators.max(999)]),
      maxDeadlineDelivery: new FormControl('', [Validators.required]),
      maxExpirationRequestDeadLine: new FormControl(''),
      maxExpirationRequestMinAntecedence: new FormControl(''),
      autoCancelMaxDeadLine: new FormControl(''),
      autoCancelMinAntecedence: new FormControl(''),
      minConfirmNewAuthorizationExternalUser: new FormControl(''),
      minConfirmNewAuthorizationInternalUser: new FormControl(''),
      minReschedulingConfirmExternalUser: new FormControl(''),
      minReschedulingConfirmInternalUser: new FormControl(''),
      minCancelRequestExternalUser: new FormControl(''),
      minCancelRequestInternalUser: new FormControl(''),
      autoNoShowRegister: new FormControl(''),
      afterQtdTime: new FormControl(''),
      allowUpdateRegister: new FormControl(''),
      onlyInZeroDay: new FormControl(''),
      requirePasswdSupervisor: new FormControl(''),
    });
  }

  tabNfFired() {
    this.sub = this.reverseDeliveryServiceEvents.emitTabNfDeadLinesFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.enableDisableFields();
    this.statusFieldAllowUpdateRegister();
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.get('afterQtdTime').disable();
    this.formulario.get('allowUpdateRegister').disable();
    this.formulario.get('onlyInZeroDay').disable();
    this.formulario.get('requirePasswdSupervisor').disable();
    this.statusFieldAllowUpdateRegister();
  }

  changeRegisterNoShow(event) {
    this.registerNoShow = (event.target.value === 'true');
    this.enableDisableFields();
  }

  enableDisableFields() {
    if (!this.registerNoShow) {
      this.formulario.get('afterQtdTime').disable();
      this.formulario.get('afterQtdTime').setValue('');
      this.formulario.get('allowUpdateRegister').disable();
      this.formulario.get('allowUpdateRegister').setValue(false);
      this.formulario.get('onlyInZeroDay').disable();
      this.formulario.get('onlyInZeroDay').setValue(false);
      this.formulario.get('requirePasswdSupervisor').disable();
      this.formulario.get('requirePasswdSupervisor').setValue(false);
    } else {
      this.formulario.get('afterQtdTime').enable();
      this.formulario.get('allowUpdateRegister').enable();
      this.formulario.get('onlyInZeroDay').enable();
      this.formulario.get('requirePasswdSupervisor').enable();
    }
  }

  changeAllowUpdtRegister(e) {
    this.statusFieldAllowUpdateRegister();
  }

  statusFieldAllowUpdateRegister() {
    if (this.formulario.get('allowUpdateRegister').value === 'true' || this.formulario.get('allowUpdateRegister').value === true) {
      this.setRequiredForFields(['onlyInZeroDay']);
      this.enableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
      this.formulario.get('onlyInZeroDay').setValue(true);
      return;
    }
    this.removeRequiredForFields(['onlyInZeroDay']);
    this.disableFields(['onlyInZeroDay', 'requirePasswdSupervisor']);
    this.formulario.get('onlyInZeroDay').setValue(false);
  }

  makeAndPointForNfDeadlines = () => SDM_URL.reverseDelivery.nfDeadlines.default;

  getComponentName(): string {
    return 'reverse-delivery.nf-deadlines';
  }

  getNewInstance(): ReverseDeliveryNF {
    return new ReverseDeliveryNF();
  }
  getService(): AbstractService<ReverseDeliveryNF> {
    return this._revDeliveryNfDeadlineService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  allowUpdateRegisterNextQuestion(event) {
    if (event.target.value === 'true') {
      this.allowUpdateRegisterSetOptions =
        [
          { name: 'reverse-delivery.nf-deadlines.options.true', value: true },
        ];
    }
  }

  validateInputsForm(event, controllerName) {
    let inputValue = event.target.value;
    if (inputValue < 0 || (!(inputValue % 1 === 0))) {
      this.formulario.get(controllerName).setErrors({ pattern: true });
    }
    else {
      this.formulario.get(controllerName).clearValidators();
      this.formulario.get(controllerName).updateValueAndValidity();
    }
  }

}
