import { FileUploader } from 'ng2-file-upload';
import { Component, OnInit, Output, SimpleChanges, SimpleChange, OnChanges, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-txt-file',
  templateUrl: './import-txt-file.component.html',
  styleUrls: ['./import-txt-file.component.scss'],
  preserveWhitespaces: true,
})
export class ImportTxtFileComponent implements OnInit, OnChanges {

  @Output() uploadSuccessEvent = new EventEmitter();
  @Output() uploadErrorEvent = new EventEmitter();

  @Input()
  set objAdditionalParameters(obj: object) {
    this._objAdditionalParameters = obj;
  }

  get objAdditionalParameters() {
    return this._objAdditionalParameters;
  }

  constructor(
    private spinner: NgxSpinnerService,
    public toaster: ToastrService) { }

  private _endpoint = '';
  private _objAdditionalParameters = {};

  @Input()
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  get endpoint() {
    return this._endpoint;
  }

  uploader: FileUploader = new FileUploader({
    url: `${this._endpoint}`,
    autoUpload: false,
    headers: [{ name: 'Authorization', value: localStorage.getItem('token') }],
    queueLimit: 1,
    itemAlias: 'file',
    isHTML5: true,
    filters: [{
      name: 'txtOnly',
      fn: function (item) {
        const txtOnly = '|text/plain|';
        return txtOnly.indexOf(item.type) !== -1;
      },
    }],
  });

  ngOnInit() {
    this.uploader.onBeforeUploadItem = (item => {
      this.spinner.show();
    });

    this.uploader.onAfterAddingFile = (item => { item.withCredentials = false; });

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      if (response !== undefined && status === 201) {
        this.uploader.queue.forEach(fileItem => {
          fileItem.remove();
        });

      } else if (/400|409|500/g.test(status)) {
        this.uploadErrorEvent.emit(response ? JSON.parse(response) : null);
      }

      this.spinner.hide();
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    const objAdditional: SimpleChange = changes.objAdditionalParameters;
    this.objAdditionalParameters = objAdditional.currentValue;

    const { depositorId, providerId, nfSolicit, orderNumber, deliveryAuthorizationId } = objAdditional.currentValue;

    this.uploader.setOptions({ additionalParameter: { depositorId, providerId, nfSolicit, orderNumber, deliveryAuthorizationId } });

    this.uploader.setOptions({ url: this._endpoint });
  }

}
