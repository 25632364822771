import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Vehicle } from "../models/sdm/vehicle";
import { environment as env } from "../../../environments/environment";
import { AbstractService } from "./abstract.service";

@Injectable()
export class VehicleService extends AbstractService<Vehicle> {

    constructor(public http: HttpClient) {
        super(http, env.host.sdm, '/wrapper/vehicle');
    }

    // create(model: Vehicle): Observable<Vehicle> {
    //     return this.http.post<Vehicle>(`${env.host.sdm}${this.URL}`, model)
    // }

    // edit(model: Vehicle): Observable<HttpResponse<Vehicle>> {
    //     return this.http.put<Vehicle>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
    // }

    // findAll(): Observable<HttpResponse<Vehicle>> {
    //     throw new Error("Method not implemented.");
    // }

    // findById(id: number): Observable<Vehicle> {
    //     return this.http.get<Vehicle>(`${env.host.sdm}${this.URL}/${id}`)
    // }

    // activeDeactive(id: number): Observable<Vehicle> {
    //     return this.http.delete<Vehicle>(`${env.host.sdm}${this.URL}/activeDeactive/${id}`)
    // }


}