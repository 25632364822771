import { AbstractStatusModel } from '../../abstractStatus.model';
import { CompatibilityDocksGroups } from './compatibility-docks-groups';

export class CompatibilityDocks extends AbstractStatusModel {

  public establishmentId: number;
  public description: string;
  public depositorId: number;
  public signatureDock: number;

  public docksGroups: CompatibilityDocksGroups[];

  constructor() {
    super();
  }

}
