import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Page } from '../models/page';

@Injectable()
export abstract class AbstractService<T> {
  constructor(
    public http: HttpClient,
    public host: string,
    public url: string
  ) {}

  create(model: T | T[]): Observable<T> {
    return this.http.post<T>(`${this.host}${this.url}`, model);
  }

  createWithEstablishment(model: T, establishmentId): Observable<T> {
    return this.http.post<T>(
      `${this.host}${this.url}?establishmentId=${establishmentId}`,
      model
    );
  }

  createWithEstablishmentAndDepositor(
    model: T,
    establishmentId,
    depositorId
  ): Observable<T> {
    return this.http.post<T>(
      `${this.host}${
        this.url
      }?establishmentId=${establishmentId}&depositorId=${depositorId}`,
      model
    );
  }

  edit(model: T): Observable<HttpResponse<T>> {
    return this.http.put<T>(`${this.host}${this.url}/${model['id']}`, model, {
      observe: 'response'
    });
  }

  update(model: T): Observable<HttpResponse<T>> {
    return this.http.put<T>(`${this.host}${this.url}`, model, {
      observe: 'response'
    });
  }

  save(model: T): Observable<HttpResponse<T>> {
    return this.http.patch<T>(`${this.host}${this.url}`, model, {
      observe: 'response'
    });
  }

  findById(id: number): Observable<T> {
    return this.http.get<T>(`${this.host}${this.url}/${id}`);
  }

  findByIdAndEstablishmentIdAndDepositorId(
    id: number,
    establishmentId: number,
    depositorId: number
  ) {
    return this.http.get<T>(
      `${this.host}${
        this.url
      }/${id}?establishmentId=${establishmentId}&depositorId=${depositorId}`
    );
  }

  findIfExists(code: String, establishmentId?:Number, depositorId?: Number, solutionId?: Number): Observable<HttpResponse<T>> {
    let query =  ``;
    [{name: 'establishmentId', value: establishmentId }, 
     {name: 'depositorId', value: depositorId }, 
     {name: 'solutionId', value: solutionId },
    ].forEach(
      x => query += (x.value != undefined) ? `&${x.name}=${x.value}`:  ``
    );
    return this.http.get<any>(
      `${this.host}${this.url}/findIfExists?code=${code}&${query}`
    );
  }

  findAll(): Observable<HttpResponse<T>> {
    return this.http.get<T>(`${this.host}${this.url}`, { observe: 'response' });
  }

  findAllWithEstablishmentAndDepositor(
    establishmentId,
    depositorId
  ): Observable<HttpResponse<T>> {
    return this.http.get<T>(
      `${this.host}${
        this.url
      }?establishmentId=${establishmentId}&depositorId=${depositorId}`,
      { observe: 'response' }
    );
  }

  findAllWithEstablishment(establishmentId): Observable<HttpResponse<T>> {
    return this.http.get<T>(
      `${this.host}${this.url}?establishmentId=${establishmentId}`,
      { observe: 'response' }
    );
  }

  findAllPath(path: string): Observable<HttpResponse<T>> {
    return this.http
      .get<T>(`${this.host}${this.url}${path}`, {
        observe: 'response'
      })
      .pipe(take(1));
  }

  findAllPathWithEstablishmentAndDepositor(
    path: string,
    establishmentId,
    depositorId
  ): Observable<HttpResponse<T>> {
    return this.http
      .get<T>(
        `${this.host}${
          this.url
        }${path}?establishmentId=${establishmentId}&depositorId=${depositorId}`,
        {
          observe: 'response'
        }
      )
      .pipe(take(1));
  }

  findAllPageable(page: Page): Observable<HttpResponse<T>> {
    return this.http.get<T>(
      `${this.host}${this.url}?size=${page.size}&page=${page.pageNumber}&sort=${
        page.sortName
      },${page.sortOrder}`,
      { observe: 'response' }
    );
  }

  activeDeactive(id: number): Observable<any> {
    return this.http.delete<any>(
      `${this.host}${this.url}/activeDeactive/${id}`,
      { observe: 'response' }
    );
  }

  deleteById(id: number) {
    return this.http.delete(`${this.host}${this.url}/${id}`);
  }

  backup(): Observable<HttpResponse<any>> {
    return this.http
      .post<HttpResponse<any>>(`${this.host}${this.url}/backup`, null)
      .pipe(take(1));
  }

  rollBack(): Observable<HttpResponse<any>> {
    return this.http
      .patch<HttpResponse<any>>(`${this.host}${this.url}/rollBack`, null)
      .pipe(take(1));
  }

  export(): Observable<Blob> {
    return this.http
      .get(`${this.host}${this.url}/export`, {
        responseType: 'blob'
      })
      .pipe(take(1));
  }

  template(): Observable<Blob> {
    return this.http
      .get(`${this.host}${this.url}/template`, {
        responseType: 'blob'
      })
      .pipe(take(1));
  }

  findAllByPathAndId(
    path: string,
    id: number,
    establishmentId: number,
    depositorId: number,
    page: Page
  ): Observable<HttpResponse<T>> {
    return this.http.get<T>(
      `${this.host}${this.url}/${
        path ? path + '/' : ''
      }${id}?establishmentId=${establishmentId}&depositorId=${depositorId}&size=${
        page.size
      }&page=${page.pageNumber}&sort=${page.sortName},${page.sortOrder}`,
      { observe: 'response' }
    );
  }

  findAllByPathAndIdNotPageable(
    path: string,
    id: number
  ): Observable<HttpResponse<T>> {
    if (path === '' || path === null || path === undefined)
      return this.http.get<T>(`${this.host}${this.url}/${id}`, {
        observe: 'response'
      });
    return this.http.get<T>(`${this.host}${this.url}/${path}/${id}`, {
      observe: 'response'
    });
  }

  findAllByUserId(userId: number): Observable<HttpResponse<T>> {
    return this.http.get<T>(`${this.host}${this.url}/user/${userId}`, {
      observe: 'response'
    });
  }

  findByDepositorIdAndStatusTrue(
    establishmentId: number,
    depositorId: number
  ): Observable<T> {
    return this.http
      .get<T>(
        `${this.host}${
          this.url
        }/establishment/${establishmentId}/depositor/${depositorId}`
      )
      .pipe(take(1));
  }

  findByIdAndEstablishmentIdAndDepositorIdAndSolutionId(
    establishmentId: number,
    depositorId: number,
    solutionId: number
  ): Observable<T> {
    return this.http
      .get<T>(
        `${this.host}${
          this.url
        }/establishment/${establishmentId}/depositor/${depositorId}/solution/${solutionId}`
      )
      .pipe(take(1));
  }
}
