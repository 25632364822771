import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localDateTime'
})
export class DateTimePipe implements PipeTransform {

  private masks = [
    { locale: "br", maskDate: 'DD/MM/YYYY', maskHour: 'HH:mm:ss' },
    { locale: "en", maskDate: 'YYYY/MM/DD', maskHour: 'HH:mm:ss' },
  ]

  public showTime: boolean = true;
  private localeMask: string;

  transform(value: string, showTime?: any): string {
    if (showTime != undefined)
      this.showTime = showTime;
    let lang = navigator.language.toLowerCase();
    for (let mask of this.masks) {
      if (lang.endsWith(mask.locale)) {
        this.localeMask = mask.maskDate;
        if (this.showTime)
          this.localeMask += " " + mask.maskHour;
        break;
      }
    }
    return value ? moment.utc(value).format(this.localeMask) : '';
  }

}
