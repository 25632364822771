import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../../../core/models/sdm/user';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from '../../../components/datatable-api/datatable-api.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild('datatable') private grid: DatatableApiComponent

  formUser: FormGroup
  user: User = new User()
  profiles: Array<any>

  formEnabled: boolean = false

  columns = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'name', name: 'Nome' },
    { prop: 'nickName', name: 'Apelido' },
    { prop: 'profile', name: 'Perfil' }
  ];

  constructor(
    private router: Router,
    private _userService: UserService,
    private toaster: ToastrService,
    private _translateService: TranslateService) { }

  ngOnInit() {
    this.formUser = new FormGroup({
      id: new FormControl(this.user.id),
      name: new FormControl(this.user.name, [Validators.maxLength(120), Validators.required]),
      nickName: new FormControl(this.user.nickName, [Validators.maxLength(60)]),
      password: new FormControl(this.user.password, [Validators.required, Validators.minLength(8)]),
      profile: new FormControl(this.user.profile, [Validators.required])
    })

    this.formEnabled = this.router.url.search('add') > 0

    this.formStatus(false)
    this.loadEnumProfile()
  }

  onSubmit() {
    if (this.formUser.controls['id'].value == null) {
      this._userService.create(this.formUser.value)
        .subscribe(data => {
          this.formUser.patchValue(data)
          this.grid.reloadPage()
          this.toaster.success('Processo concluído com sucesso')
          this.formStatus(false)
        });
    } else {
      this._userService.edit(this.formUser.value)
        .subscribe(() => {
          this.grid.reloadPage()
          this.toaster.success('Processo concluído com sucesso')
          this.formStatus(false)
        })
    }
  }

  loadEnumProfile() {
    this._translateService.get('pages.users.Enum.profile').subscribe(data => this.profiles = data)
  }

  addButton() {
    this.formEnabled = true;
    this.formUser.enable()
    this.formUser.reset()
  }

  formStatus(status: boolean) {
    this.formEnabled = status
    status ? this.formUser.enable() : this.formUser.disable()
  }

  resetForm() {
    this.formUser.reset()
    this.formUser.disable()
    this.formEnabled = false
  }

  formFilled(row) {
    this.formUser.patchValue(row)
    this.formUser.enable()
    window.scrollTo(0, 0)
  }

  formFillView(row) {
    this.formUser.patchValue(row)
    this.formUser.disable()
    window.scrollTo(0, 0)
  }

  deleted(e) {
    this.resetForm()
  }

}
