import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Depositor } from '../models/sdm/depositor';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ServiceInterface } from './service-interface';
import { AbstractService } from './abstract.service';
import { take } from 'rxjs/operators';

const URL = '/wrapper/depositor';

@Injectable()
export class DepositorService extends AbstractService<Depositor> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, URL);
  }

  // create(model: Depositor): Observable<Depositor> {
  //   return this.http.post<Depositor>(`${env.host.sdm}${this.URL}`, model)
  // }

  // edit(model: Depositor): Observable<HttpResponse<Depositor>> {
  //   return this.http.put<Depositor>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
  // }

  // findById(id: number): Observable<Depositor> {
  //   return this.http.get<Depositor>(`${env.host.sdm}${this.URL}/${id}`)
  // }

  // inactivate(id: number): Observable<HttpResponse<any>> {
  //   return this.http.delete<any>(`${env.host.sdm}${this.URL}/inactivate/${id}`, { observe: 'response' })
  // }

  getAll(): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${env.host.sdm}${URL}/getAll`, { observe: 'response' }).pipe(take(1));
  }

  findByEstablishmentId(id: number): Observable<HttpResponse<Depositor>> {
    return this.http.get<Depositor>(`${env.host.sdm}${URL}/findDepositorsByEstablishmentId/${id}`, { observe: 'response' }).pipe(take(1));
  }

  // activeDeactive(id: number): Observable<Depositor> {
  //   throw new Error('Method not implemented.');
  // }

}