import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Vehicle } from '../models/sdm/vehicle';
import { environment as env } from '../../../environments/environment';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { VehicleEstablishemnt } from '../models/sdm/vehicle-establishment';

@Injectable()
export class VehicleEstablishmentService {
  // super(http, env.host.sdm, '/wrapper/vehicle-depositor');

  private URL = '/wrapper/vehicle-establishment';

  constructor(public http: HttpClient) {
  }

  create(model: VehicleEstablishemnt): Observable<VehicleEstablishemnt> {
    
    return this.http.post<VehicleEstablishemnt>(`${env.host.sdm}${this.URL}`, model);
  }

  activeDeactive(depositorId: number, vehicleTypeId: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${env.host.sdm}${this.URL}/activeDeactive/${depositorId}/${vehicleTypeId}`, { observe: 'response' });
  }

}
