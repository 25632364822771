import { take } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { AbstractService } from '../../abstract.service';
import { ImpreciseFields } from './../../../models/sdm/provider-management/imprecise-fields';

@Injectable({
  providedIn: 'root'
})
export class ImpreciseFieldsService extends AbstractService<ImpreciseFields> {
  constructor(public http: HttpClient) {
    super(
      http,
      env.host.sdm,
      SDM_URL.providerManagement.impreciseFields.default
    );
  }

  findByDocumentAndUserIdStatusTrue(
    documentProvider: string,
    userId: number
  ): Observable<ImpreciseFields> {
    return this.http
      .get<ImpreciseFields>(
        `${SDM_URL.providerManagement.impreciseFields.findByDocumentAndUserIdStatusTrue
          .replace(':document', documentProvider)
          .replace(':userId', userId.toString())}`
      )
      .pipe(take(1));
  }
}
