import { Observable } from 'rxjs';
import { DeliveryAuthorization } from './../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay, concatMap, switchMap } from 'rxjs/operators';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { AbstractService } from '../../../../core/services/abstract.service';
import { RegularDeliveryRequestMaxService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-request-max.service';
import { AbstractDelivery } from '../../abstract-delivery';
import { Document } from './../../../../core/models/sdm/delivery-authorization/regular-delivery/document';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { RegularDeliveryOrder } from './../../../../core/models/sdm/regular-delivery/order';
import { RegularDeliveryRequestMax } from './../../../../core/models/sdm/regular-delivery/regular-delivery-request-max';
import { AuthService } from './../../../../core/services/auth.service';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { ManualTypeDocService } from './../../../../core/services/sdm/delivery-authorization/regular-delivery/manual-type-doc.service';
import { ProviderService } from './../../../../core/services/sdm/provider-management/provider.service';
import { OrderService } from './../../../../core/services/sdm/regular-delivery/order.service';
import { RegularDeliveryService } from './../../../../core/services/sdm/regular-delivery/regular-delivery.service';
import { TranslatorService } from './../../../../core/translator/translator.service';
import { map } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import { mergeMap } from 'rxjs-compat/operator/mergeMap';

@Component({
  selector: 'app-manual-type-doc',
  templateUrl: './manual-type-doc.component.html',
  styleUrls: ['./manual-type-doc.component.scss']
})
export class ManualTypeDocComponent extends AbstractDelivery<Document>
  implements OnInit, OnDestroy {
  @Input() formEnabled = false;
  @Output() manualTypeCanceled = new EventEmitter();
  order: RegularDeliveryOrder = new RegularDeliveryOrder();
  regularDeliveryReqMax = new RegularDeliveryRequestMax();

  idAuthorization;

  deliveryAuthorization: DeliveryAuthorization;
  deliveryAuthorizationId: number;

  modalRef: BsModalRef;
  sub: Subscription;
  subscriptions: Subscription[] = [];

  saveAuthorization: Observable<{}>;
  // insertedNFs = 0;

  constructor(
    public _location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    private _manualTypeDoc: ManualTypeDocService,
    public _authService: AuthService,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    public modalService: BsModalService,
    private _orderService: OrderService,
    private route: ActivatedRoute,
    sanitizer: DomSanitizer,
    _deliveryAuthorizationService: DeliveryAuthorizationService,
    public router: Router,
    private _regularDeliveryReqMaxService: RegularDeliveryRequestMaxService,
    public _regularDeliveryService: RegularDeliveryService
  ) {
    super(
      modalService,
      _location,
      toaster,
      translator,
      sanitizer,
      _deliveryAuthorizationService,
      _depositorService,
      _providerService,
      _authService,
      router,
      _regularDeliveryService
    );
  }

  ngOnInit() {
    this.deliveryAuthorization = this.route.snapshot.data[
      'deliveryAuthorization'
    ];

    this.formulario = new FormGroup({
      // id: new FormControl(null),
      depositor: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      providerId: new FormControl('', [Validators.required]),
      provider: new FormControl('', [Validators.required]),
      nfSolicit: new FormControl(''),
      documentSender: new FormControl(''),
      documentRecipient: new FormControl(''),
      documentShipping: new FormControl(''),
      nfNumber: new FormControl(''),
      nfSerie: new FormControl(''),
      emissionDate: new FormControl(''),
      orderNumber: new FormControl(''),
      totalQtdItems: new FormControl('', [Validators.min(1)]),
      totalQtdUnit: new FormControl('', [Validators.min(1)]),
      totalValueNf: new FormControl('', [Validators.min(1)]),
      totalGrossWeight: new FormControl(''),
      totalNetWeight: new FormControl(''),
      qtdVol: new FormControl(''),
      deliveryAuthorizationId: new FormControl(this.deliveryAuthorizationId),
      solicitations: new FormControl('')
    });

    this.setDepositorsFromQueryParams(this.deliveryAuthorization);

    this.formulario
      .get('provider')
      .valueChanges.subscribe((provider: Provider) => {
        if (provider) {
          this.formulario.get('providerId').setValue(provider.id);
        }
      });

    this.formulario.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('depositorId').setValue(value['id']);
      } else {
        this.formulario.get('depositorId').setValue('');
      }
    });

    this.formulario
      .get('emissionDate')
      .setValue(moment(new Date()).format('YYYY-MM-DD'));
  }

  confirm() {}

  setDepositorsFromQueryParams(params: any) {
    try {
      
      const depositor = params['depositor'];
      this.depositors = [params['depositor']];
      this.formulario.get('depositor').setValue(depositor);
      this.depositorChanged(this.formulario.get('depositor').value);
    } catch (e) {
      
      this.depositors = [];
    }
  }

  cancel() {
    this.router.navigate(['sdm/home']);
  }

  submit() {
    if (this.insertedNFs >= this.regularDeliveryReqMax.requestMaxNf) {
      this.alertMsg(
        'Quantidade máxima de documentos excedido para esta solicitação',
        'info'
      );
      return;
    }

    if (this.formulario.valid) {
      if (!this.deliveryAuthorizationId) {
        this._deliveryAuthorizationService
          .create(this.deliveryAuthorization)
          .pipe(
            switchMap(authorization => {
              this.deliveryAuthorizationId = authorization.id;
              this.formulario
                .get('deliveryAuthorizationId')
                .setValue(this.deliveryAuthorizationId);
              return this.getService().create(this.formulario.value);
            })
          )
          .subscribe(
            data => {
              this.savedSuccess(data);
            },
            ({ error }) => {
              this.errorMessage(error);
            }
          );
      } else {
        this.getService()
          .create(this.formulario.value)
          .subscribe(
            data => {
              this.savedSuccess(data);
            },
            ({ error }) => {
              this.errorMessage(error);
            }
          );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  statusForm() {
    if (!this.formEnabled) {
      this.formulario.enable();
      return;
    }
    this.formulario.disable();
    this.formulario.reset();
  }

  depositorChanged(data: Depositor) {
    
    if (data) {
      this.formulario.get('documentRecipient').setValue(data['document']);
      this.selectedDepositor(data);
      const { id } = data;
      const userId = this._authService.getUser().id;
      this.loadParamsRegularDeliveryOrderByDepositorAndUser(id, userId);
      this.loadParamsRegularDeliveryRequestMax(id, userId);
      this.loadProvidersByDepositorId(id);
    }
  }

  loadProvidersByDepositorId(depositorId: number) {
    this._providerService
      .findByDepositorId(depositorId)
      .subscribe((data: Provider[]) => {
        this.providers = data;

        if (data.length === 1) {
          this.formulario.get('provider').setValue(this.providers[0]);
          this.selectedProvider(this.providers[0]);
        }
      });
  }

  clearDepositor() {
    this.order = new RegularDeliveryOrder();
  }

  loadParamsRegularDeliveryOrderByDepositorAndUser(
    depositorId: number,
    userId: number
  ) {
    this.subscriptions.push(
      this._orderService
        .findByDepositorIdAndUser(depositorId, userId)
        .pipe(delay(500))
        .subscribe(res => {
          this.order = res ? res : new RegularDeliveryOrder();
        })
    );
  }

  loadParamsRegularDeliveryRequestMax(depositorId: number, userId: number) {
    this.subscriptions.push(
      this._regularDeliveryReqMaxService
        .findByDepositorId(depositorId, userId)
        .subscribe(data => {
          
          this.regularDeliveryReqMax = data
            ? data
            : new RegularDeliveryRequestMax();
        })
    );
  }

  selectedProvider(provider: Provider) {
    this.providerSelected = provider;

    

    if (provider) {
      this.formulario.get('documentSender').setValue(provider['document']);
      return;
    }

    this.formulario.get('documentSender').setValue('');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
    localStorage.removeItem('docId');
  }

  getNewInstance(): Document {
    return new Document();
  }
  getService(): AbstractService<Document> {
    return this._manualTypeDoc;
  }
  getComponentName(): string {
    return 'delivery-authorization.regular-delivery.manual-type-doc';
  }
}
