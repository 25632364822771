import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../../app.api';
import { environment } from './../../../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class MassiveUploadService extends AbstractService<Document> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.deliveryAuthorization.regularDelivery.massiveUpload.default);
  }

  partialContinueWithValidDocuments(deliveryAuthorizationId: number): Observable<HttpResponse<any>> {
    return this.http.put<any>(`${SDM_URL.deliveryAuthorization.regularDelivery.massiveUpload.partialContinue.replace(':deliveryAuthorizationId', deliveryAuthorizationId.toString())}`, {}, { observe: 'response' });
  }

  deleteAllByDeliveryAuthorization(deliveryAuthorizationId: number) {
    return this.http.delete(`${SDM_URL.deliveryAuthorization.regularDelivery.massiveUpload.deleteAllByDeliveryAuthorization.replace(':deliveryAuthorizationId', deliveryAuthorizationId.toString())}`);
  }
}
