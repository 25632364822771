import { Component, OnInit } from '@angular/core';
import { ProviderManagement } from '../../provider-management.service';

@Component({
  selector: 'app-groups-compatibility-form',
  templateUrl: './groups-compatibility-form.component.html',
  styleUrls: ['./groups-compatibility-form.component.scss'],
})
export class GroupsCompatibilityFormComponent implements OnInit {

  constructor(private _providerManagementService: ProviderManagement) { }

  ngOnInit() {
  }

  onSelectTab(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabCompatibilityDocks':
        this._providerManagementService.tabGroupsCompatibilityDocksFired();
        break;
    }
  }

}
