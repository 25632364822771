import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeliveryAuthorization } from '../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { Depositor } from './../../../../core/models/sdm/depositor';

@Component({
  selector: 'app-generated-authorization',
  templateUrl: './generated-authorization.component.html',
  styleUrls: ['./generated-authorization.component.scss']
})
export class GeneratedAuthorizationComponent implements OnInit {
  depositor: Depositor[] = [];
  providers: Provider[] = [];
  solicitation: DeliveryAuthorization;

  formEnabled = true;

  formGeneratedAuthorization: FormGroup;

  authorization = null;

  constructor(private _router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.formGeneratedAuthorization = new FormGroup({
      depositorId: new FormControl(''),
      providerId: new FormControl(''),
      deliveryAuthorizationId: new FormControl('')
    });

    this.route.queryParams.subscribe((params: object) => {
      this.setDepositorFromQueryParams(params);
      this.setProvidersFromQueryParams(params);
      this.setSolicitation(params);
    });
  }

  setDepositorFromQueryParams(params: Params) {
    try {
      this.depositor = JSON.parse(params['depositor']);
      this.formGeneratedAuthorization
        .get('depositorId')
        .setValue(this.depositor[0]);
    } catch (e) {
      
    }
  }

  setProvidersFromQueryParams(params: Params) {
    try {
      this.providers = JSON.parse(params['provider']);
      this.formGeneratedAuthorization
        .get('providerId')
        .setValue(this.providers[0]);
    } catch (e) {
      
    }
  }

  setSolicitation(params: Params) {
    try {
      this.solicitation = JSON.parse(params['deliveryAuthorization']);
      
      this.formGeneratedAuthorization
        .get('deliveryAuthorizationId')
        .setValue(this.solicitation);
      this.authorization = this.solicitation['authorizationkey'];
    } catch (e) {
      
    }
  }

  exit() {
    this._router.navigate(['sdm/home']);
  }

  newSolicitation() {
    this._router.navigate(['sdm/delivery-authorization/regular-delivery']);
  }
}
