import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class PendingHandlingRegularDelivery extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;

  public depositorId: number;

  public userCanAllocateOutOfTheValidity: boolean;

  public needSupervisorPassword: boolean;

  public needDepositorAuthorize: boolean;

  public authorizedBy: number;

  public authorizationShape: number;

  public observation: boolean;

  public operationNature: boolean;

  public vehicleType: boolean;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
