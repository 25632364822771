import { DateTimeFormatPipe } from './../../../../core/classes/dateTimePipe';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { SlotRound } from '../../../../core/models/sdm/slot/slot-round';
import { AbstractService } from '../../../../core/services/abstract.service';
import { DepositorService } from '../../../../core/services/depositor.service';
import { SlotRoundService } from '../../../../core/services/slot/slot-round.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../register/abstract-register';
import { SlotService } from '../slot.service';

@Component({
  selector: 'app-slot-round-form',
  templateUrl: './slot-round-form.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./slot-round-form.component.scss']
})
export class SlotRoundFormComponent extends AbstractRegister<SlotRound>
  implements OnInit {
  slotRound: SlotRound = new SlotRound();
  @ViewChild('datatable') private grid: DatatableApiComponent;

  columns = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'establishmentId', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositorId', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'round', name: 'Arredondamento' },
    {
      prop: 'createdAt',
      name: 'Criado em',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'updatedAt',
      name: 'Alterado em',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    private _slotRoundService: SlotRoundService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _slotServiceEvents: SlotService
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._slotServiceEvents.emitEventTabSlotRoundFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(this.slotRound.id),
      establishmentId: new FormControl(this.slotRound.establishmentId, [
        Validators.required
      ]),
      description: new FormControl(''),
      depositorId: new FormControl(this.slotRound.depositorId, [
        Validators.required
      ]),
      round: new FormControl(this.slotRound.round, [
        Validators.required,
        Validators.min(0)
      ]),
      status: new FormControl(this.slotRound.status)
    });

  }


  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
  }

  getNewInstance(): SlotRound {
    return new SlotRound();
  }

  getService(): AbstractService<SlotRound> {
    return this._slotRoundService;
  }

  getGrid(): DatatableApiComponent {
    return this.grid;
  }

  getComponentName() {
    return 'slot.round';
  }

  validSlotRound(event) {
    let inputValue = event.target.value;
    if (inputValue < 0 || inputValue > 100) {
      this.formulario.get('round').setErrors({ pattern: true });
      return;
    }

    this.formulario.get('round').clearValidators();
    this.formulario.get('round').updateValueAndValidity();
  }
}
