export enum OpNatureAuthorizationEnum {
  INBOUND = 1,
  ENTREGA_REVERSA = 2,
  OUTBOUND = 3,
  OUTROS = 4,
  TIPO_1 = 5,
  TIPO_2 = 6,
  DISPONIVEL = 7,
  BLOQUEADO = 8
}
