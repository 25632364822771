import { Directive } from '@angular/core';

@Directive({
    selector: '[dateOnly]'
})
export class DateOnlyDirective {

    transform(value: String): String {
        if (value && value.length >= 10) {
            value = value.substring(0, 10);
        }

        return value;

    }

}