import { Location } from '@angular/common';
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AbstractService } from '../../../core/services/abstract.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { DatatableApiComponent } from './../../../components/datatable-api/datatable-api.component';
import { AuthService } from './../../../core/services/auth.service';
import { DepositorService } from './../../../core/services/depositor.service';
import { EstablishmentService } from './../../../core/services/establishment.service';

export abstract class EmailSmsParamsAbstract<T> extends BaseFormComponent
  implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  establishments = [];
  depositors = [];
  formEnabled = true;

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public authService: AuthService,
    public establishmentService: EstablishmentService
  ) {
    super(location, toaster, translator);

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishment: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      establishmentId: new FormControl(''),
      depositorId: new FormControl(''),
      confirmAuthorizationWithoutSchedule: new FormControl(),
      rescheduleAuthorization: new FormControl(),
      cancelAuthorization: new FormControl(),
      vehicleArrival: new FormControl(),
      vehicleExits: new FormControl(),
      noShowRegister: new FormControl(),
      documentalOcurrencyRegister: new FormControl(),
      documentOperationalOcurrencyRegister: new FormControl()
    });

    this.formulario.patchValue(this.getNewInstance());

    const values = [
      { valueChange: 'establishment', valueToChange: 'establishmentId' },
      { valueChange: 'depositor', valueToChange: 'depositorId' }
    ];

    this.setValueChanges(values);
  }

  ngOnInit() {}

  setValueChanges(valueChanges: any[]) {
    for (let values of valueChanges) {
      this.formulario
        .get(values['valueChange'])
        .valueChanges.subscribe(value => {
          if (value) {
            this.formulario.get(values['valueToChange']).setValue(value.id);
            return;
          }

          this.formulario.get(values['valueToChange']).setValue('');
        });
    }
  }

  submit() {
    if (this.formulario.valid) {
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.value)
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.value)
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  loadEstablishment() {
    const { id } = this.authService.getUser();

    this.establishmentService
      .findByUserId(id)
      .subscribe(data => (this.establishments = data));
  }

  establishmentChanged(value) {
    if (!value) {
      return;
    }

    this.depositorService.findByEstablishmentId(value.id).subscribe(data => {
      this.formulario.get('depositor').setValue([]);

      this.depositors = [...data.body['content']];

      if (this.depositors.length === 1) {
        this.formulario.get('depositor').setValue(this.depositors[0]);
      }
    });
  }

  afterAddButton() {
    this.formulario.patchValue(this.getNewInstance());

    this.loadEstablishment();
  }

  formFillToEdit(row: object) {
    this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.enable();
  }

  formFillToView(row: object) {
    this.formulario.patchValue(row);
    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.disable();
  }

  fillDepositorWhenSelectGridCell(row: object) {
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'] != null &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositor') &&
        this.formulario.get('establishment')
      ) {
        this.formulario.get('depositor').setValue(row['depositor']);
        this.formulario
          .get('establishment')
          .setValue(row['depositor']['establishment']);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;
  abstract getGrid(): DatatableApiComponent;
}
