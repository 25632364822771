import { FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, Renderer2, ElementRef, ViewChild, Renderer } from '@angular/core';

const TEXT_AREA_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormTextareaComponent),
  multi: true,
};

@Component({
  selector: 'app-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  providers: [TEXT_AREA_VALUE_ACCESSOR],
})
export class FormTextareaComponent extends DefaultValueAccessor implements OnInit {

  @Input() label = '';
  @Input() name = '';
  @Input() i18n = true;
  @Input() cols = 40;
  @Input() rows = 3;
  @Input() id = '';
  @Input() form: FormGroup;
  @Input() control: FormControl;
  @Input() disabled = false;
  private innerValue: any;

  onChange: (_: any) => void;
  onTouched: () => void;

  constructor(_renderer: Renderer2, _elementRef: ElementRef) {
    super(_renderer, _elementRef, false);
  }

  ngOnInit() {
  }

  get value() {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
    // this._renderer.setProperty(this._elementRef.nativeElement, 'value', v);
  }

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    try {
      this.onTouched = fn;
    } catch (e) {
      console.error(e);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    // this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  isInvalid(): boolean {
    if (this.form.disabled) {
      this.control.markAsUntouched();
      return false;
    }
    if (this.control.disabled) {
      this.control.markAsUntouched();
    }
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

}
