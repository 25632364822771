import { Depositor } from '../depositor';
import { Establishment } from '../establishment';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class EmailSmsReverseDelivery extends AbstractStatusModel {
  public establishment: Establishment;
  public depositor: Depositor[] = [];
  public statusDocOK: boolean;
  public expirationDocOK: boolean;
  public confirmAuthorizationWithoutSchedule: boolean;
  public statusInterrupted: boolean;
  public pendentPriority: boolean;
  public AllocationManualPendentPriority: boolean;
  public confirmAuthorization: boolean;
  public rescheduleAuthorization: boolean;
  public cancelAuthorization: boolean;
  public vehicleArrival: boolean;
  public vehicleExits: boolean;
  public noShowRegister: boolean;
  public documentalOcurrencyRegister: boolean;
  public documentOperationalOcurrencyRegister: boolean;

  constructor() {
    super();
    this.statusDocOK = false;
    this.expirationDocOK = false;
    this.confirmAuthorizationWithoutSchedule = false;
    this.statusInterrupted = false;
    this.pendentPriority = false;
    this.AllocationManualPendentPriority = false;
    this.confirmAuthorization = false;
    this.rescheduleAuthorization = false;
    this.cancelAuthorization = false;
    this.vehicleArrival = false;
    this.vehicleExits = false;
    this.noShowRegister = false;
    this.documentalOcurrencyRegister = false;
    this.documentOperationalOcurrencyRegister = false;
  }
}
