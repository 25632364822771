import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class RegularOperationgDays extends AbstractStatusModel implements IDeserializable {

  public establishmentId: number;
  public depositorId: number;
  public sunday: boolean;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;

  constructor() {
    super();
    this.sunday = false;
    this.monday = false;
    this.tuesday = false;
    this.wednesday = false;
    this.thursday = false;
    this.friday = false;
    this.saturday = false;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }

}
