import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxSelectModule } from 'ngx-select-ex';
import { ToastrModule } from 'ngx-toastr';
import { AgGridModule } from '../../../../node_modules/ag-grid-angular/main';
import { ChartsModule } from '../../../../node_modules/ng2-charts/ng2-charts';
import { ModelStatusComponent } from '../../components/model-status/model-status.component';
import { UploadBtnComponent } from '../../components/upload-btn/upload-btn.component';
import { DepositorService } from '../../core/services/depositor.service';
import { EstablishmentService } from '../../core/services/establishment.service';
import { MeasureUnitDepositorService } from '../../core/services/measure-unit/measure-unit-depositor.service';
import { MeasureUnitEstablishmentService } from '../../core/services/measure-unit/measure-unit-establishment.service';
import { OperationNatureService } from '../../core/services/operation-nature/operationNature.service';
import { OperationNatureDepositorService } from '../../core/services/operation-nature/operationNatureDepositor.service';
import { SignatureService } from '../../core/services/signature.service';
import { SolutionLocaleService } from '../../core/services/solution/solution-locale.service';
import { SolutionProfileService } from '../../core/services/solution/solution-profile.service';
import { UserService } from '../../core/services/user.service';
import { VehicleDepositorService } from '../../core/services/vehicle-depositor.service';
import { VehicleEstablishmentService } from '../../core/services/vehicle-establishment';
import { VehicleService } from '../../core/services/vehicle.service';
import { SharedModule } from '../../shared/shared.module';
import { ModalCustomComponent } from './../../components/modal-custom/modal-custom.component';
import { GestaoVistaSdmComponent } from './gestao-vista-sdm/gestao-vista-sdm.component';

import { OperationNatureFormComponent } from './operation-nature/form/operation-nature-form.component';
import { BlackListFormComponent } from './provider-management/black-list/form/black-list-form.component';
import { CriticalItemsFormComponent } from './provider-management/critical-items/form/critical-items-form.component';
import { DaysHoursRestrictionFormComponent } from './provider-management/days-hours-restriction/form/days-hours-restriction-form.component';
import { DocksDimensioningFormComponent } from './provider-management/docks-dimensioning/form/docks-dimensioning-form.component';
import { ProductivityGroupFormComponent } from './provider-management/docks-dimensioning/productivity-group/form/productivity-group-form.component';
import { ProductivityPatternFormComponent } from './provider-management/docks-dimensioning/productivity-pattern/form/productivity-pattern-form.component';
import { ProviderManagementFormComponent } from './provider-management/form/provider-management-form.component';
import { CompatibilityDocksFormComponent } from './provider-management/groups-compatibility/compatibility-docks/form/compatibility-docks-form.component';
import { CompatibilityProviderFormComponent } from './provider-management/groups-compatibility/compatibility-provider/form/compatibility-provider-form.component';
import { GroupsCompatibilityFormComponent } from './provider-management/groups-compatibility/form/groups-compatibility-form.component';
import { ImpreciseFieldsFormComponent } from './provider-management/imprecise-fields/form/imprecise-fields-form.component';
import { PriorityProviderFormComponent } from './provider-management/priority-provider/form/priority-provider-form.component';
import { ProviderManagement } from './provider-management/provider-management.service';
import { ProviderFormComponent } from './provider-management/provider/form/provider-form.component';
import { CollectCancelComponent } from './schedules/collect/collect-cancel/collect-cancel.component';
import { CollectEditComponent } from './schedules/collect/collect-edit/collect-edit.component';
import { CollectFormComponent } from './schedules/collect/form/collect-form.component';
import { CollectScheduleResolverGuard } from './schedules/collect/guards/collect-schedule-resolver.guard';
import { ManualAllocationEditComponent } from './schedules/reverse-delivery/manual-allocation/edit/manual-allocation-edit.component';
import { ManualAllocationResolverGuard } from './schedules/reverse-delivery/manual-allocation/guards/manual-allocation-resolver.guard';
import { ManualAllocationComponent } from './schedules/reverse-delivery/manual-allocation/manual-allocation.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserComponent } from './user/user.component';
import { VehicleFormComponent } from './vehicle/form/vehicle-form.component';

const routes: Routes = [
  { path: 'vehicle', component: VehicleFormComponent },
  { path: 'operation-nature', component: OperationNatureFormComponent },

  {
    path: 'providers/provider-management',
    component: ProviderManagementFormComponent
  },
  {
    path: 'providers/docks-dimensioning',
    component: DocksDimensioningFormComponent
  },
  {
    path: 'providers/groups-compatibility',
    component: GroupsCompatibilityFormComponent
  },
  {
    path: 'providers/imprecise-fields',
    component: ImpreciseFieldsFormComponent
  },
  {
    path: 'providers/days-hours-restriction',
    component: DaysHoursRestrictionFormComponent
  },

  {
    path: 'schedules/collect',
    component: CollectFormComponent
  },
  {
    path: 'schedules/collect/cancel',
    component: CollectCancelComponent,
    resolve: { collectSchedule: CollectScheduleResolverGuard }
  },
  {
    path: 'schedules/collect/edit',
    component: CollectEditComponent,
    resolve: { collectSchedule: CollectScheduleResolverGuard }
  },
  {
    path: 'schedules/reverse-delivery/manual-allocation',
    component: ManualAllocationComponent
  },
  {
    path: 'schedules/reverse-delivery/manual-allocation/edit',
    component: ManualAllocationEditComponent,
    resolve: { manualAllocation: ManualAllocationResolverGuard }
  },

  { path: 'gestao-vista-sdm', component: GestaoVistaSdmComponent }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    ToastrModule.forRoot(),
    NgxSelectModule,
    TextMaskModule,
    NgSelectModule,
    NgxDatatableModule,
    ChartsModule,
    RatingModule.forRoot(),
    AgGridModule.withComponents([GestaoVistaSdmComponent])
  ],
  providers: [
    EstablishmentService,
    DepositorService,
    VehicleService,
    UserService,
    SolutionLocaleService,
    SolutionProfileService,
    SignatureService,
    VehicleDepositorService,
    VehicleEstablishmentService,
    MeasureUnitEstablishmentService,
    MeasureUnitDepositorService,
    OperationNatureService,
    OperationNatureDepositorService,
    ProviderManagement
  ],
  declarations: [
    ModelStatusComponent,
    VehicleFormComponent,
    UploadBtnComponent,
    UserListComponent,
    UserComponent,
    OperationNatureFormComponent,
    BlackListFormComponent,
    ProviderManagementFormComponent,
    ProviderFormComponent,
    PriorityProviderFormComponent,
    CriticalItemsFormComponent,
    ImpreciseFieldsFormComponent,
    DaysHoursRestrictionFormComponent,
    DocksDimensioningFormComponent,
    ProductivityGroupFormComponent,
    ProductivityPatternFormComponent,
    GroupsCompatibilityFormComponent,
    CompatibilityProviderFormComponent,
    CompatibilityDocksFormComponent,
    CollectFormComponent,
    ModalCustomComponent,
    GestaoVistaSdmComponent,
    ManualAllocationComponent,
    ManualAllocationEditComponent,
    CollectEditComponent,
    CollectCancelComponent
  ],
  exports: [RouterModule],
  entryComponents: [ModalCustomComponent]
  // schemas: [NO_ERRORS_SCHEMA]
})
export class RegisterModule {}
