import { DimensioningInfoService } from './../../../../core/services/sdm/delivery-authorization/regular-delivery/dimensioning-info.service';
import { DeliveryAuthorization } from './../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { Authorization } from './../../../../core/models/sdm/delivery-authorization/authorization';
import { AuthorizationService } from './../../../../core/services/sdm/delivery-authorization/authorization.service';
import { DocksScheduling } from './../../../../core/models/sdm/document/docks-scheduling';
import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { min } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Document } from '../../../../core/models/sdm/delivery-authorization/regular-delivery/document';
import { RegularDeliverySmallSize } from '../../../../core/models/sdm/regular-delivery/regular-delivery-small-size';
import { DimensioningInfo } from './../../../../core/models/sdm/delivery-authorization/regular-delivery/dimensioning-info';
import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { PriorVisualizationService } from './../../../../core/services/sdm/docks/prior-visualization.service';
import { RegularDeliverySmallSizeService } from './../../../../core/services/sdm/regular-delivery/regular-delivery-small-size.service';
import { ModalDimensioningInfoComponent } from './../dimensioning-info/dimensioning-info.component';
import { OpNatureAuthorization } from '../../../../core/models/sdm/shared/enums/op-nature-authorization.enum';

const MESSAGE_QTD_SLOTS = `QUANTIDADE DE SLOTS NECESSÁRIOS: "Para as quantidades informadas e o perfil da carga e/ou veículo será necessária uma janela com duração total de :qtdInMinutesProductivityGroups min (:qtdslots slots)`;
const MESSAGE_SMALL_SIZE = `A Entrega em questão é considerada de Pequeno Porte e portanto, pode ser obtida Confirmação de Autorização de Entrega sem a necessidade de Agendamento.`;

@Component({
  selector: 'app-qtd-slots',
  templateUrl: './qtd-slots.component.html',
  styleUrls: ['./qtd-slots.component.scss'],
  preserveWhitespaces: true
})
export class QtdSlotsComponent implements OnInit, OnDestroy {
  formEnabled = false;

  depositors = [];
  providers = [];
  solicitations = [];
  vehicleType: any;
  // requiredSlots: DimensioningInfo;

  formQtdSlots: FormGroup;

  dismissible = true;
  alerts: any = [];

  bsModalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  regularDeliverySmallSize: RegularDeliverySmallSize = new RegularDeliverySmallSize();
  dimensioningInfo: DimensioningInfo = new DimensioningInfo();
  confirmAuthorization: Authorization;

  // @Input() alerts: any = [];

  // regDeliverySmallSize: RegularDeliverySmallSize = new RegularDeliverySmallSize();

  constructor(
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    public modalService: BsModalService,
    public router: Router,
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _smallSizeService: RegularDeliverySmallSizeService,
    private _docksService: PriorVisualizationService,
    private _authorizationService: AuthorizationService,
    private _dimensioningInfoService: DimensioningInfoService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  allowDeliverySmallSize(): boolean {
    return (
      this.regularDeliverySmallSize.maxDurationDeliveryInMinutes <=
      this.dimensioningInfo.qtdInMinutesProductivityGroups
    );
  }

  ngOnInit() {
    this.formQtdSlots = new FormGroup({
      depositorId: new FormControl(''),
      providerId: new FormControl(''),
      deliveryAuthorizationId: new FormControl('')
    });
    // this.errorMessage.emit('teste');
    // this.alerts.push({
    //   type: 'error',
    //   msg: `teste`,
    // });

    this.route.queryParams.subscribe((params: object) => {
            this.depositors = JSON.parse(params['depositor']) || null;
      this.providers = JSON.parse(params['provider']) || null;
      this.vehicleType = JSON.parse(params['vehicleType'] || null);

      

      this.getSolicitationsFromQueryParams(params);

      try {
        // this.requiredSlots = JSON.parse(params['dimensioningInfo']);
        this.dimensioningInfo = JSON.parse(params['dimensioningInfo']);

        this.alertMsg(
          `${MESSAGE_QTD_SLOTS.replace(
            ':qtdInMinutesProductivityGroups',
            this.dimensioningInfo.qtdInMinutesProductivityGroups.toString()
          ).replace(':qtdslots', this.dimensioningInfo.qtdSlots.toString())}`,
          'info'
        );

        // Permite Entregas de pequeno porte
        if (this.dimensioningInfo.allowDeliverySmallSize) {
          this.alertMsg(`${MESSAGE_SMALL_SIZE}`, 'info');
        }
      } catch (e) {
        console.error(e);
      }

      this.formQtdSlots.get('depositorId').setValue(this.depositors[0]);
      this.formQtdSlots.get('providerId').setValue(this.providers[0]);
      this.formQtdSlots
        .get('deliveryAuthorizationId')
        .setValue(this.solicitations[0]);

      const { id } = this.formQtdSlots.get('depositorId').value;

      this._smallSizeService
        .findByDepositorId(id)
        .subscribe((data: RegularDeliverySmallSize) => {
          this.regularDeliverySmallSize = data;
        });
    });
  }

  getSolicitationsFromQueryParams(params: any) {
    try {
      const solicitations = JSON.parse(params['deliveryAuthorization']) || null;
      this.solicitations = [solicitations];

    } catch (e) {
      console.error(e);
    }
  }

  btnConfirmWithoutSchedule(): boolean {
    return (
      this.dimensioningInfo.qtdInMinutesProductivityGroups <
      this.regularDeliverySmallSize.maxDurationDeliveryInMinutes
    );
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  getDateOfDelivery(documents: Document[]) {
    const dates = [];
    dates.push(
      documents.map(doc => new Date(doc.emissionDate).toLocaleDateString())
    );
    return min(dates);
  }

  cancel() {
    this.openModalConfirmSolicitation(
      'Mensagem confirmação',
      'Status da Solicitação será alterado para Interrompida, Deseja continuar?'
    );
  }

  openModalConfirmSolicitation(title: string, message: string) {
    const initialState = {
      title,
      message
    };
    this.bsModalRef = this.modalService.show(ModalDimensioningInfoComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Não';
    this.bsModalRef.content.confirmBtn = 'Sim';

    this.subscriptions.push(
      this.modalService.onHidden.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            const { id } = this.solicitations[0];
            this._deliveryAuthorizationService
              .updateStatusAuthorization(
                id,
                +DeliveryAuthorizationEnum.INTERROMPIDA
              )
              .subscribe(() => {
                this.router.navigate(['sdm/home']);
              });
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  alertMsg(message: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
    });
  }

  confirmWithoutSchedule() {
    if (!this.dimensioningInfo.allowDeliverySmallSize) {
      this.alertMsg(
        'Depositante não permite entregas de Pequeno Porte sem Agendamento e/ou o tempo de entrega ultrapassa o tempo limite de duração da entrega de pequeno porte.',
        'warning'
      );
      return;
    }

    const { id } = this.formQtdSlots.get('deliveryAuthorizationId').value;

    this._deliveryAuthorizationService
      .partialUpdate(
        { qtdSlots: this.dimensioningInfo.qtdSlots },
        this.dimensioningInfo.deliveryAuthorization.id
      )
      .switchMap(() => {
        return this._deliveryAuthorizationService.updateStatusAuthorization(
          id,
          +DeliveryAuthorizationEnum.SEM_AGENDAMENTO
        );
      })
      .subscribe(
        () => {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              depositor: JSON.stringify(this.depositors),
              provider: JSON.stringify(this.providers),
              deliveryAuthorization: JSON.stringify(this.solicitations)
            },
            queryParamsHandling: 'merge',
            skipLocationChange: true
          };

          this.confirmAuthorization = new Authorization(
            +DeliveryAuthorizationEnum.SEM_AGENDAMENTO,
            <DeliveryAuthorization>this.solicitations[0],
            OpNatureAuthorization.INBOUND
          );
          this._authorizationService
            .create(this.confirmAuthorization)
            .subscribe(data => {
              
            });

          this.router.navigate(
            ['sdm/delivery-authorization/regular-delivery/without-schedule'],
            navigationExtras
          );
        },
        error => {
          this.alertMsg(error, 'error');
        }
      );

    // this._deliveryAuthorizationService
    //   .updateStatusAuthorization(id, +DeliveryAuthorizationEnum.SEM_AGENDAMENTO)
    //   .subscribe(
    //     () => {
    //       const navigationExtras: NavigationExtras = {
    //         queryParams: {
    //           depositor: JSON.stringify(this.depositors),
    //           provider: JSON.stringify(this.providers),
    //           deliveryAuthorization: JSON.stringify(this.solicitations)
    //         },
    //         queryParamsHandling: 'merge',
    //         skipLocationChange: true
    //       };

    //       this.confirmAuthorization = new Authorization(
    //         +DeliveryAuthorizationEnum.SEM_AGENDAMENTO,
    //         <DeliveryAuthorization>this.solicitations[0],
    //         OpNatureAuthorization.INBOUND
    //       );
    //       this._authorizationService
    //         .create(this.confirmAuthorization)
    //         .subscribe(data => {
        //         });

    //       this.router.navigate(
    //         ['sdm/delivery-authorization/regular-delivery/without-schedule'],
    //         navigationExtras
    //       );
    //     },
    //     error => {
    //       this.alertMsg(error, 'error');
    //     }
    //   );

    // this._deliveryAuthorizationService.updateStatusAuthorization(+this.authorizationKey, +DeliveryAuthorizationEnum.EXPIRADO)
    //   .subscribe(data => 
  }

  scheduleDelivery() {
    const {
      depositor: {
        id: depositorId,
        establishment: { id: establishmentId }
      },
      validityDate,
      deliveryAuthorization: { statusAuthorization }
    } = this.dimensioningInfo;

    const vehicleTypeId = this.vehicleExists() ? this.vehicleType.id : null;

    const status = Object.keys(DeliveryAuthorizationEnum).filter(
      value => value == statusAuthorization
    )[0];

    this._deliveryAuthorizationService
      .partialUpdate(
        { qtdSlots: this.dimensioningInfo.qtdSlots },
        this.dimensioningInfo.deliveryAuthorization.id
      )
      .switchMap(() => {
        return this._docksService.findByDocksAvailable(
          depositorId,
          vehicleTypeId,
          validityDate,
          establishmentId,
          this.dimensioningInfo.qtdSlots,
          parseInt(DeliveryAuthorizationEnum[status]),
          this.dimensioningInfo.deliveryAuthorization.risk,
          this.dimensioningInfo.deliveryAuthorization.id
        );
      })
      .subscribe(
        (data: DocksScheduling[]) => {
          

          if (data) {
            const docks = data;

            if (docks.length) {
              // this.deleteDimensioningInfoSaveTemporarily();
              this.router.navigate(
                [
                  'sdm/delivery-authorization/regular-delivery/allocation-finded'
                ],
                this.prepareQueryParams(
                  docks.length > 1
                    ? 'Alocação Diversas opções.'
                    : 'Alocação 1 Opção',
                  docks,
                  this.dimensioningInfo.qtdInMinutesProductivityGroups
                )
              );
            } else {
              // this.deleteDimensioningInfoSaveTemporarily();
              this.router.navigate(
                [
                  'sdm/delivery-authorization/regular-delivery/schedule-without-option'
                ],
                this.prepareQueryParams('')
              );
            }
          }
        },
        ({ error }) => {
          this.alertMsg(error, 'danger');
        }
      );

    // this._docksService
    //   .findByDocksAvailable(
    //     depositorId,
    //     vehicleTypeId,
    //     validityDate,
    //     establishmentId,
    //     this.dimensioningInfo.qtdSlots,
    //     parseInt(DeliveryAuthorizationEnum[status]),
    //     this.dimensioningInfo.deliveryAuthorization.risk,
    //     this.dimensioningInfo.deliveryAuthorization.id
    //   )
    //   .subscribe(
    //     (data: DocksScheduling[]) => {
        //       if (data) {
    //         const docks = data;

    //         if (docks.length) {
    //           // this.deleteDimensioningInfoSaveTemporarily();
    //           this.router.navigate(
    //             [
    //               'sdm/delivery-authorization/regular-delivery/allocation-finded'
    //             ],
    //             this.prepareQueryParams(
    //               docks.length > 1
    //                 ? 'Alocação Diversas opções.'
    //                 : 'Alocação 1 Opção',
    //               docks,
    //               this.dimensioningInfo.qtdInMinutesProductivityGroups
    //             )
    //           );
    //         } else {
    //           // this.deleteDimensioningInfoSaveTemporarily();
    //           this.router.navigate(
    //             [
    //               'sdm/delivery-authorization/regular-delivery/schedule-without-option'
    //             ],
    //             this.prepareQueryParams('')
    //           );
    //         }
    //       }
    //     },
    //     ({ error }) => {
    //       this.alertMsg(error, 'danger');
    //     }
    //   );
  }

  private vehicleExists(): boolean {
    return this.vehicleType != null;
  }

  prepareQueryParams(
    title: string,
    docksScheduling?: DocksScheduling[],
    deliveryTime?: number
  ) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositors),
        provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify(this.solicitations),
        title
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    if (docksScheduling && docksScheduling.length) {
      navigationExtras.queryParams.docksScheduling = JSON.stringify(
        docksScheduling
      );
    }

    if (deliveryTime) {
      navigationExtras.queryParams.deliveryTime = deliveryTime;
    }

    return navigationExtras;
  }
}
