import { Provider } from './../provider-management/provider';
import { AbstractStatusModel } from '../../abstractStatus.model';

export class CollectSchedules extends AbstractStatusModel {

  public depositorId: number;
  public providerId: number;
  public dateSchedule: string;
  public docks: string;
  public iniHour: string;
  public finHour: string;
  public obs: string;
  public recurrentCollect: boolean;
  public endsAfterEvents: number;
  public endsAfterDay: string;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;
  public everyDayOfMonth: number;
  public scheduleNumber: string;
  public provider: Provider;
  public geralDepositors: boolean;
  public totalConflicts: number;
  public conflictSchedule: boolean;

  constructor() {
    super();
    this.geralDepositors = false;
    this.monday = false;
    this.tuesday = false;
    this.wednesday = false;
    this.thursday = false;
    this.friday = false;
    this.saturday = false;
    this.sunday = false;
  }

}
