import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CollectService {

  constructor() { }

  emitTabRmaFired = new EventEmitter();
  emitTabReqMaxFired = new EventEmitter();
  emitTabSmallSizeFired = new EventEmitter();
  emitTabNfDeadLinesFired = new EventEmitter();

  tabRmaFired = () => this.emitTabRmaFired.emit();
  tabReqMaxFired = () => this.emitTabReqMaxFired.emit();
  tabSmallSizeFired = () => this.emitTabSmallSizeFired.emit();
  tabNfDeadLinesFired = () => this.emitTabNfDeadLinesFired.emit();
}
