import { PriorVisualization } from './../docks/prior-visualization';
import { DockCompatibility } from './../docks/dock-compatibility';
import { Establishment } from './../establishment';
import { DeliveryAuthorization } from './../delivery-authorization/delivery-authorization';
import { Depositor } from '../depositor';
import { Provider } from '../provider-management/provider';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { OpNatureAuthorization } from '../shared/enums/op-nature-authorization.enum';

export class DocksScheduling extends AbstractStatusModel {
  public qtdSlots: number;
  public priorVisualizationId: number;
  public depositor: Depositor;
  public provider: Provider;
  public deliveryAuthorization: DeliveryAuthorization;
  public deliveryTime: number;
  public dateScheduleDelivery: string;
  public reserveType: number;
  public establishment: Establishment;
  public dockCompatibility: DockCompatibility;
  public initialHour: string;
  public finalHour: string;
  public carShowSchedule: string;

  public priorVisualization: PriorVisualization;

  // Transient
  public reserveTypeInternalUserUpdate: number;

  public opNatureDelivery: OpNatureAuthorization;

  public reserveTypeOneOnScheduling: boolean;

  constructor() {
    super();
  }
}
