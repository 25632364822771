import { SelectFieldComponent } from './../../../../components/select/select-field/select-field.component';
import { SDM_URL } from './../../../../app.api';
import { DepositorService } from './../../../../core/services/depositor.service';
import { AuthService } from './../../../../core/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MeasureUnit } from '../../../../core/models/sdm/measure-unit/measure-unit';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { ToastrService } from 'ngx-toastr';
import { BaseFormComponent } from '../../../../shared/base-form/base-form.component';
import { Location } from '@angular/common';
import { DateTimeFormatPipe } from '../../../../core/classes/dateTimePipe';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MeasureUnitService } from '../../../../core/services/measure-unit/measure-unit.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { environment } from '../../../../../environments/environment';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { EstablishmentService } from '../../../../core/services/establishment.service';

@Component({
  selector: 'app-measure-unit-form',
  templateUrl: './measure-unit-form.component.html',
  styleUrls: ['./measure-unit-form.component.scss']
})
export class MeasureUnitFormComponent extends BaseFormComponent
  implements OnInit {
  establishments: any[] = [];
  depositors: Depositor[] = [];
  depositorsSearch: Depositor[] = [];
  measureUnit: MeasureUnit;
  modalRef: BsModalRef;
  searchForm: FormGroup;

  establishmentId: number = 0;
  depositorId = -1;
  @ViewChild('datatable') grid: DatatableApiComponent;

  @ViewChild('btnActions') btnActions: TemplateRef<any>;
  @ViewChild('actionHeader') actionHeader: TemplateRef<any>;

  @ViewChild('establishment') establishment: SelectFieldComponent;
  @ViewChild('establishmentFilter') establishmentFilter: SelectFieldComponent;

  columns: object[] = [
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'measureUnit.measureUnit', name: 'Unidade de medida' },
    {
      prop: 'createdAt',
      name: 'Criado em',
      pipe: new DateTimeFormatPipe('en-US')
    },
    {
      prop: 'updatedAt',
      name: 'Alterado em',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];
  _id: any;
  constructor(
    public location: Location,
    public toaster: ToastrService,
    private _measureUnitService: MeasureUnitService,
    public translatorService: TranslatorService,
    private _modalService: BsModalService,
    private http: HttpClient,
    public _establishmentService: EstablishmentService,
    private _authService: AuthService,
    public depositorService: DepositorService
  ) {
    super(location, toaster, translatorService);
  }

  ngOnInit() {
    // this.columns.splice(9, 0, {
    //   name: 'Ações',
    //   cellTemplate: this.btnActions,
    //   headerTemplate: this.actionHeader,
    //   width: 60
    // });

    this.measureUnit = new MeasureUnit();

    this.formulario = new FormGroup({
      id: new FormControl(this.measureUnit.id),
      measureUnit: new FormControl(this.measureUnit.measureUnit, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      status: new FormControl(this.measureUnit.status),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      createdBy: new FormControl(''),
      updatedBy: new FormControl('')
    });

    this.searchForm = new FormGroup({
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required])
    });

    this.loadEstablishments();

    this.searchForm.get('establishmentId').valueChanges.subscribe(value => {
      if (value) {
        this.establishmentId = value.id;
      }
    });

    this.searchForm.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.depositorId = value.id;
      }
    });
  }

  openModal(template: TemplateRef<any>, { id }) {
    
    this._id = id;
    this.modalRef = this._modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-sm' })
    );
  }

  delete() {
    this.http
      .delete(`${environment.host.sdm}/wrapper/measure-unit/${this._id}`)
      .subscribe(() => {
        this.toaster.success(`#${this._id} excluído com sucesso!`);
        this.grid.reloadPage();
        this.formulario.reset();
        this.formulario.disable();
        this.formEnabled = false;
      });
  }

  loadEstablishments() {
    const { id } = this._authService.getUser();

    this._establishmentService.findByUserId(id).subscribe(data => {
      this.establishments = data;
    });
  }

  confirm() {
    this.delete();
    this.modalRef.hide();
  }

  decline() {
    this.modalRef.hide();
  }

  swicthActive(row) {
    this.http
      .delete(
        `${environment.host.sdm}/wrapper/measure-unit/activeDeactive/${row.id}`,
        { observe: 'response' }
      )
      .subscribe(res => {
        this.grid.reloadPage();
      });
  }

  submit() {
    if (this.formulario.valid) {
      if (this.formulario.controls['id'].value == null) {
        const observe = this._measureUnitService
          .create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.toaster.success('Processo concluído com sucesso', 'SDM');
            this.grid.reloadPage();
            observe.unsubscribe();
          });
      } else {
        const observe = this._measureUnitService
          .edit(this.formulario.value)
          .subscribe(() => {
            this.disabledForm();
            this.toaster.success('Processo concluído com sucesso', 'SDM');
            this.grid.reloadPage();
            observe.unsubscribe();
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  endPointMeasureUnitDepositor = () =>
    `${SDM_URL.measureUnit.depositor.findByDepositorIdAndEstablishmentId
      .replace(':depositorId', this.depositorId.toString() || null)
      .replace(':establishmentId', this.establishmentId.toString() || null)}`;

  establishmentChangedSearch(establishment) {
    if (establishment) {
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .subscribe(data => {
          this.depositorsSearch = [...data.body['content']];
          
          if (this.depositorsSearch.length === 1) {
            if (this.searchForm.controls.hasOwnProperty('depositor')) {
              this.searchForm.get('depositor').setValue(this.depositors[0]);
            }
          }
        });
    } else {
      this.depositorsSearch = [];
      this.searchForm.get('depositor').setValue([]);
    }
  }

  filterMeasureUnits() {
    if (this.searchForm.valid) {
      setTimeout(() => this.grid.reloadPage(), 1);
      return;
    }

    this.verificaValidacoesForm(this.searchForm);
  }

  formFillToEdit(row: object) {
    
    this.formulario.patchValue(row);

    this.formulario.get('id').setValue(row['measureUnit'].id);

    this.formulario.get('measureUnit').setValue(row['measureUnit'].measureUnit);

    this.formulario
      .get('establishmentId')
      .setValue(row['depositor'].establishment);

    this.fillDepositorWhenSelectGridCell(row);
    this.formulario.enable();
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();

    this.establishment.items = this.establishments;
    this.establishmentFilter.items = this.establishments;
  }

  establishmentChanged(establishment) {
    if (establishment) {
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .subscribe(data => {
          this.depositors = [...data.body['content']];
          
          if (this.depositors.length === 1) {
            if (this.formulario.controls.hasOwnProperty('depositor')) {
              this.formulario.get('depositor').setValue(this.depositors[0]);
            }
          }
        });
    } else {
      this.depositors = [];
      this.formulario.get('depositor').setValue([]);
    }
  }

  getComponentName() {
    return 'measure-unit';
  }
}
