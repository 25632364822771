import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  SecurityContext,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment/moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DocksOperation } from '../../../../../core/models/sdm/dock-management/docks-operation';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { Provider } from '../../../../../core/models/sdm/provider-management/provider';
import { ReverseDeliveryManualAllocation } from '../../../../../core/models/sdm/schedules/reverse-delivery/manual-allocation';
import { SlotPattern } from '../../../../../core/models/sdm/slot/slot-pattern';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { DeliveryAuthorizationService } from '../../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { DocksSchedulingService } from '../../../../../core/services/sdm/document/docks-scheduling.service';
import { ProviderService } from '../../../../../core/services/sdm/provider-management/provider.service';
import { SlotPatternService } from '../../../../../core/services/slot/slot-pattern.service';
import { VehicleDepositorService } from '../../../../../core/services/vehicle-depositor.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { AbstractSchedulesSlots } from '../../abstract-schedules-slots';
import { validatorFinHour } from '../../shared/utils';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Legend } from './../../../../../components/legend/legend';
import { ModalCustomComponent } from './../../../../../components/modal-custom/modal-custom.component';
import { SelectFieldComponent } from './../../../../../components/select/select-field/select-field.component';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DateFormatPipe } from './../../../../../core/classes/datePipe';
import { StatusSolicitationPipe } from './../../../../../core/classes/status-solicitation.pipe';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { Establishment } from './../../../../../core/models/sdm/establishment';
import { SubNature } from './../../../../../core/models/sdm/schedules/reverse-delivery/enums/sub-nature.enum';
import { SchedulesConflictDTO } from './../../../../../core/models/sdm/schedules/schedules-conflict-dto';
import { AuthService } from './../../../../../core/services/auth.service';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../core/services/establishment.service';
import { DocksOperationService } from './../../../../../core/services/sdm/dock-management/docks-operation.service';
import { PriorVisualizationService } from './../../../../../core/services/sdm/docks/prior-visualization.service';
import { ManualAllocationService } from './../../../../../core/services/sdm/schedules/reverse-delivery/manual-allocation.service';

const REVERSE_DELIVERY = 2;

@Component({
  selector: 'app-manual-allocation',
  templateUrl: './manual-allocation.component.html',
  styleUrls: ['./manual-allocation.component.scss']
})
export class ManualAllocationComponent
  extends AbstractSchedulesSlots<ReverseDeliveryManualAllocation>
  implements OnInit, OnDestroy {
  modalRef: BsModalRef | null;
  modalRef2: BsModalRef;

  formFilters: FormGroup;
  formulario: FormGroup;
  vehicleTypeRequired = false;
  bsModalRef: BsModalRef;

  docks = [];
  docksFilter = [];

  providers = [];
  carrying = [];
  carryingFilter = [];
  depositors = [];

  depositorsFilter = [];
  providersFilters = [];

  solicitations = [];
  vehiclesDepositor = [];
  optionsOperationNature = [];
  establishments = [];
  establishmentsFilter = [];

  selectedManualAllocationObj: any;
  priorVisualizationSelected: PriorVisualization;

  manualAllocationRecurrents = [];
  allConflicts = true;

  configLegend: Legend[] = [
    {
      color: '#f54542',
      text: 'Conflito',
      tooltip: 'será gerado conflito'
    },
    {
      color: `#f57242`,
      text: 'Feriado',
      tooltip: 'Horários e dias com feriado'
    },
    {
      color: `#f5bf42`,
      text: 'Dia não operacional',
      tooltip: 'Data não operacional'
    },
    {
      color: `#73b846`,
      text: 'Sem conflito',
      tooltip: 'Não irá gerar conflito'
    }
  ];

  @ViewChild('templateModalConfirmAll') templateModalConfirmAll: TemplateRef<
    any
  >;
  @ViewChild('template') template1: TemplateRef<any>;
  @ViewChild('depositor') depositor: SelectFieldComponent;
  @ViewChild('depositorFilter') depositorFilter: SelectFieldComponent;
  @ViewChild('provider') provider: SelectFieldComponent;
  @ViewChild('estalblishment') estalblishment: SelectFieldComponent;

  @ViewChild('hdrTplProvider') hdrTplProvider: TemplateRef<any>;
  @ViewChild('bodyTplProvider') bodyTplProvider: TemplateRef<any>;

  @ViewChild('hdrTplDocks') hdrTplDocks: TemplateRef<any>;
  @ViewChild('bodyTplDocks') bodyTplDocks: TemplateRef<any>;

  @ViewChild('hdrTplHours') hdrTplHours: TemplateRef<any>;
  @ViewChild('bodyTplHours') bodyTplHours: TemplateRef<any>;

  conflicts = 0;

  optionsOperationSubNature = [
    {
      name:
        'schedules.reverse-delivery.manual-allocation.subNature.options.refuses',
      value: SubNature.REFUSES
    },
    {
      name:
        'schedules.reverse-delivery.manual-allocation.subNature.options.returns',
      value: SubNature.RETURNS
    }
  ];

  formEnabled = false;

  dismissible = true;
  alerts: any = [];

  // slot: SlotPattern;

  subscriptions: Subscription[] = [];
  fieldsRecurrentReverse = [
    'endsAfterEvents',
    'endsAfterDay',
    'everyDayOfMonth',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  @ViewChild('tabsRevDeliveryManualAllocation')
  tabsRevDeliveryManualAllocation: TabsetComponent;

  columns: object[] = [
    { prop: 'id', name: '#' },
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.name', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'authorizationkey', name: '# Agendamento' },
    {
      prop: 'docksScheduling.dateScheduleDelivery',
      name: 'Data',
      pipe: new DateFormatPipe('en-US')
    },
    {
      prop: 'manualAllocationReverse.providerShipping.shortName',
      name: 'Transportadora'
    },
    {
      prop: 'statusAuthorization',
      name: 'Status Solicitação',
      pipe: new StatusSolicitationPipe()
    },
    { prop: 'manualAllocationReverse.eventName', name: 'Nome Solicitação' },
    { prop: 'recurrent', name: 'Recorrente', pipe: new BooleanConverter() },
    { prop: 'conflicted', name: 'Conflito', pipe: new BooleanConverter() }
  ];

  // columnsDetails: object[] = [
  //   { prop: 'operationNature', name: 'Natureza da Operação' },
  //   // { prop: 'operationSubNature', name: 'Sub-Natureza' },
  //   { prop: 'depositor.detailName', name: 'Depositante' },
  //   { prop: 'provider.shortName', name: 'Fornecedor' },
  //   { prop: 'ini', name: 'Hora inicial' },
  //   { prop: 'fin', name: 'Hora final' },
  //   { prop: 'date', name: 'Data', pipe: new DateFormatPipe('en-US') }
  //   // { prop: 'vehicle.vehicleType', name: 'Tipo Veículo' }
  // ];

  rowsDetails = [];

  @ViewChild('grdManualAllocation') $grdManualAllocation: DatatableApiComponent;

  selected: any[] = [];

  constructor(
    public authService: AuthService,
    public depositorService: DepositorService,
    private _vehiclesDepositorService: VehicleDepositorService,
    private _priorVisualizationService: PriorVisualizationService,
    public sanitizer: DomSanitizer,
    private _reverseDeliveryManualAllocationService: ManualAllocationService,
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public providerService: ProviderService,
    public router: Router,
    public modalService: BsModalService,
    private _docksScheduling: DocksSchedulingService,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _slotService: SlotPatternService,
    private activatedRoute: ActivatedRoute,
    private _establishmentService: EstablishmentService,
    protected _docksOperationService: DocksOperationService
  ) {
    super(
      location,
      toaster,
      translator,
      sanitizer,
      depositorService,
      providerService,
      authService
    );
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params['message']) {
        this.alertMsg(params['message']);
      }
      
    });

    this.columns.splice(8, 0, {
      name: 'Fornecedor',
      cellTemplate: this.bodyTplProvider,
      headerTemplate: this.hdrTplProvider
    });

    this.columns.splice(5, 0, {
      name: 'Doca',
      cellTemplate: this.bodyTplDocks,
      headerTemplate: this.hdrTplDocks
    });

    this.columns.splice(5, 0, {
      name: 'Horário Início / Fim',
      cellTemplate: this.bodyTplHours,
      headerTemplate: this.hdrTplHours
    });

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      depositorId: new FormControl(''),
      providerId: new FormControl(''),
      provider: new FormControl(''),
      severalProviders: new FormControl(''),
      vehicleTypeId: new FormControl(''),
      operationSubNature: new FormControl(''),
      dateSchedule: new FormControl('', [Validators.required]),
      priorVisualization: new FormControl('', [Validators.required]),
      iniHour: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{2}:[0-9]{2}')
      ]),
      finHour: new FormControl('', [
        Validators.required,
        Validators.pattern('[0-9]{2}:[0-9]{2}')
      ]),
      eventName: new FormControl(''),
      recurrent: new FormControl(''),
      endsAfterEvents: new FormControl('', [
        Validators.min(1),
        Validators.max(100)
      ]),
      endsAfterDay: new FormControl(''),
      monday: new FormControl(''),
      tuesday: new FormControl(''),
      wednesday: new FormControl(''),
      thursday: new FormControl(''),
      friday: new FormControl(''),
      saturday: new FormControl(''),
      sunday: new FormControl(''),
      everyDayOfMonth: new FormControl(''),
      dates: new FormControl(''),
      datesWithoutConflict: new FormControl(''),
      shippingCompany: new FormControl('')
    });

    this.subscriptions.push(
      this._establishmentService.findAll().subscribe(data => {
        this.establishments = [...data.body['content']];
        this.establishmentsFilter = [...data.body['content']];
      })
    );

    this.formFilters = new FormGroup({
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl(''),
      providerId: new FormControl(''),
      authorizationKey: new FormControl(''),

      opNatureRegular: new FormControl(''),
      opNatureReverse: new FormControl(''),
      opNatureCollect: new FormControl(''),

      opSubNatureRefuse: new FormControl(''),
      opSubNatureReturns: new FormControl(''),
      docOk: new FormControl(''),
      interrupt: new FormControl(''),
      pending: new FormControl(''),
      approved: new FormControl(''),
      approvedWithoutSchedule: new FormControl(''),
      manualAllocation: new FormControl(''),
      expired: new FormControl(''),
      canceled: new FormControl(''),
      reschedule: new FormControl(''),
      finalized: new FormControl(''),
      noShow: new FormControl(''),
      accomplished: new FormControl(''),
      initDate: new FormControl(''),
      finDate: new FormControl(''),
      priorVisualization: new FormControl(''),
      shippingCompany: new FormControl(''),
      geralDepositors: new FormControl('')
    });

    this.formulario.get('finHour').setValidators(validatorFinHour('iniHour'));

    this.formulario.get('iniHour').valueChanges.subscribe(() => {
      this.formulario.get('finHour').hasError('validFinHour');
    });

    this.formulario.get('endsAfterDay').valueChanges.subscribe(value => {
      if (value && !/Invalid date/.test(value)) {
        this.formulario.get('endsAfterEvents').setValue('');
      }
    });

    this.formulario.get('endsAfterEvents').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('endsAfterDay').setValue('');

        

        if (value > 100 || value <= 0 || !/[0-9]*/.test(value)) {
          this.formulario.get('endsAfterEvents').setValue('');
        }
      }
    });

    this.formulario.get('recurrent').valueChanges.subscribe(value => {
      if (value) {
        this.enableFields(this.fieldsRecurrentReverse);
        return;
      }

      this.disableFields(this.fieldsRecurrentReverse);
    });

    this.formulario
      .get('provider')
      .valueChanges.subscribe((value: Provider) => {
        if (value) {
          this.formulario.get('providerId').setValue(value.id);
          return;
        }

        this.formulario.get('providerId').setValue('');
      });

    this.valueChangeSeveralProviders();

    this.setDepositorId();

    // this.loadDepositors();

    [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ].forEach(dayOfWeek => {
      this.formulario.get(dayOfWeek).valueChanges.subscribe(value => {
        if (value) {
          this.formulario.get('everyDayOfMonth').setValue('');
        }
      });
    });

    this.formulario.get('everyDayOfMonth').valueChanges.subscribe(value => {
      if (value) {
        [
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday'
        ].forEach(dayOfWeek => {
          this.formulario.get(dayOfWeek).setValue(false);
        });
      }
    });

    this.formulario.get('dateSchedule').valueChanges.subscribe(value => {
      if (value) {
        const inputValue = moment(value).format('YYYY-MM-DD');
        const dateCompare = moment(new Date()).format('YYYY-MM-DD');

        if (inputValue < dateCompare) {
          this.formulario.get('dateSchedule').setValue('');
        }
      }
    });
  }

  valueChangeSeveralProviders() {
    this.formulario.get('severalProviders').valueChanges.subscribe(value => {
      let val = !!value;
      if (val) {
        this.formulario.get('provider').setValue(null);
        this.formulario.get('provider').disable();

        const providers = this.providers;

        this.provider.items = providers.map(v => (v['several'] = 'Diversos'));
        this.formulario.get('provider').setValue(this.provider.items[0]);
        this.provider.bindLabel = 'several';

        return;
      }

      this.formulario.get('provider').setValue({});
      this.formulario.get('provider').enable();
      this.provider.items = this.providers;
      this.provider.bindLabel = 'shortName';
    });
  }

  establishmentChanged(establishment: Establishment) {
    this.setEstablishment(
      establishment,
      this.formulario,
      this.selectedDepositor
    );
  }

  establishmentChangedFilter(establishment: Establishment) {
    this.setEstablishment(
      establishment,
      this.formFilters,
      this.selectedDepositorFilter
    );
  }

  private setEstablishment(
    establishment: Establishment,
    form: FormGroup,
    callBack: Function
  ) {
    if (establishment) {
      form.get('depositor').setValue([]);
      this.subscriptions.push(
        this._depositorService
          .findByEstablishmentId(establishment.id)
          .subscribe(data => {
            if (data && data.body['content'].length === 1) {
              const depositor = data.body['content'][0];
              form.get('depositor').setValue(depositor);
              callBack.call(this, depositor);
            }
            this.depositors = [...data.body['content']];
          })
      );
    }
  }

  loadDepositors() {
    const { id } = this.authService.getUser();

    this.subscriptions.push(
      this.depositorService.findAllByUserId(id).subscribe(data => {
        this.depositors = [...data.body['content']];
      })
    );
  }

  setDepositorId() {
    this.formulario.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('depositorId').setValue(value.id);
        return;
      }

      this.formulario.get('depositorId').setValue('');
    });
  }

  hourToMinutes = value => value * 60;

  ngOnDestroy() {
    this.unsubscribe();
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabNewManualAllocation':
        break;
      case 'tabSearchManualAllocations':
        // this.loadDepositors();
        // this.loadProvidersFilters();
        break;
    }
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message: string, type = 'success', hasHtmlContent = false) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      hasHtmlContent
    });
  }

  selectedDepositor(depositor: Depositor) {
    if (depositor) {
      const { id } = this.authService.getUser();
      
      this.vehicleTypeRequired = depositor.reverseDelivery;

      const getDocksDepositor = this._priorVisualizationService.findByDepositorIdAndEstablishmentId(
        depositor.id,
        depositor.establishment.id
      );
      const getVehiclesDepositor = this._vehiclesDepositorService.findByDepositorAndUserIdAndStatusTrue(
        depositor.id,
        id
      );
      const getProvidersFromDepositor = this.providerService.findByDepositorId(
        depositor.id
      );

      const getSlotSize = this._slotService.findByEstablishmentIdAndStatusTrue(
        depositor.establishment.id
      );

      forkJoin([
        getDocksDepositor,
        getVehiclesDepositor,
        getProvidersFromDepositor,
        getSlotSize
      ]).subscribe(response => {
        this.setDocksDepositor(response[0]);
        this.setVehiclesDepositor(response[1]);
        this.setProvidersFromDepositor(response[2]);
        this.setSlotSize(response[3]);
      });
    } else {
      this.docks = [];
      this.providers = [];
      this.vehiclesDepositor = [];

      this.formulario.get('provider').setValue([]);
      this.formulario.get('vehicleTypeId').setValue([]);
      this.formulario.get('priorVisualization').setValue([]);
    }
  }

  selectedDepositorFilter(depositor: Depositor) {
    if (depositor) {
      // this.loadProvidersFitlerFromDepositor(depositor);

      const getProviders = this.providerService.findByDepositorId(depositor.id);
      const getDocksDepositor = this._priorVisualizationService.findByDepositorIdAndEstablishmentId(
        depositor.id,
        depositor.establishment.id
      );

      forkJoin([getProviders, getDocksDepositor]).subscribe(response => {
        this.setProvidersFitlerFromDepositor(response[0]);
        this.setDocksDepositorFilter(response[1]);
      });
    } else {
      this.carryingFilter = [];
      this.providersFilters = [];
      this.docksFilter = [];
      this.formFilters.get('shippingCompany').setValue([]);
      this.formFilters.get('providerId').setValue([]);
    }
  }

  setProvidersFitlerFromDepositor(data: object[]) {
    if (data) {
      this.providersFilters = data.filter(
        provider =>
          provider['agentType'].id == 1 || provider['agentType'].id == 3
      );

      this.carryingFilter = data.filter(
        provider =>
          provider['agentType'].id == 2 || provider['agentType'].id == 3
      );

      if (data.length === 1) {
        this.formulario.get('providerId').setValue(data[0]);
      }
    } else {
      this.carryingFilter = [];
      this.providersFilters = [];
      this.formulario.get('providerId').setValue([]);
    }
  }

  setDocksDepositorFilter(data: PriorVisualization[]) {
    
    if (data) {
      this.docksFilter = data;

      if (this.docksFilter.length) {
        this.docksFilter = data.map(value => {
          value['fullName'] = `${value['signatureDock']} - (${
            value['initialHour']
          } - ${value['finalHour']})`;
          return value;
        });
      }

      if (this.docksFilter.length === 1) {
        this.formFilters
          .get('priorVisualization')
          .setValue(this.docksFilter[0]);
      }
    } else {
      this.docksFilter = [];
      this.formFilters.get('priorVisualization').setValue(this.docksFilter);
    }
  }

  // loadProvidersFromDepositor(depositor: Depositor) {
  //   this.providerService.findByDepositorId(depositor.id).subscribe(data => {
  //     if (data) {
  //       this.providers = data;

  //       if (data.length === 1) {
  //         this.formulario.get('provider').setValue(data[0]);
  //       }
  //     } else {
  //       this.providers = [];
  //       this.formulario.get('provider').setValue(this.providers);
  //     }
  //   });
  // }

  setProvidersFromDepositor(data: object[]) {
    if (data) {
      
      this.providers = data.filter(
        provider =>
          provider['agentType'].id == 1 || provider['agentType'].id == 3
      );

      this.carrying = data.filter(
        provider =>
          provider['agentType'].id == 2 || provider['agentType'].id == 3
      );

      if (data.length === 1) {
        this.formulario.get('provider').setValue(data[0]);
      }
    } else {
      this.carrying = [];
      this.providers = [];
      this.formulario.get('provider').setValue(this.providers);
    }
  }

  setSlotSize(slot) {
    this.slot = slot ? slot : new SlotPattern();
    
  }

  setDocksDepositor(data: PriorVisualization[]) {
    
    if (data) {
      this.docks = data;

      if (this.docks.length) {
        this.docks = data.map(value => {
          value['fullName'] = `${value['signatureDock']} - (${
            value['initialHour']
          } - ${value['finalHour']})`;
          return value;
        });
      }

      if (this.docks.length === 1) {
        this.formulario.get('priorVisualization').setValue(this.docks[0]);
      }
    } else {
      this.docks = [];
      this.formulario.get('priorVisualization').setValue(this.docks);
    }
  }

  // loadDocksDepositor(depositor: Depositor) {
  //   this.docks = [];
  //   this.formulario.get('priorVisualization').setValue([]);

  //   this._priorVisualizationService
  //     .findByDepositorIdAndStatus(depositor.id)
  //     .pipe(catchError(this.handleError))
  //     .subscribe((data: PriorVisualization[]) => {
  //       if (data) {
  //         this.docks = data.map(value => value['priorVisualization']);

  //         if (this.docks.length === 1) {
  //           this.formulario.get('priorVisualization').setValue(this.docks[0]);
  //         }
  //       } else {
  //         this.docks = [];
  //         this.formulario.get('priorVisualization').setValue(this.docks);
  //       }
  //     });
  // }

  // loadVehiclesDepositor(depositorId: number, userId: number) {
  //   this.subscriptions.push(
  //     this._vehiclesDepositorService
  //       .findByDepositorAndUserIdAndStatusTrue(depositorId, userId)

  //       .subscribe((res: any) => {
  //         if (res) {
  //           this.vehiclesDepositor = res;
  //           this.formulario.get('vehicleTypeId').setValue([]);
  //           return;
  //         }

  //         this.vehiclesDepositor = [];
  //         this.formulario.get('vehicleTypeId').setValue(this.vehiclesDepositor);
  //       })
  //   );
  // }

  setVehiclesDepositor(response: any) {
    if (response) {
      this.vehiclesDepositor = response;
      this.formulario.get('vehicleTypeId').setValue([]);
      return;
    }

    this.vehiclesDepositor = [];
    this.formulario.get('vehicleTypeId').setValue(this.vehiclesDepositor);
  }

  handleError(error: any) {
    return Observable.of(null);
  }

  submit() {
    if (this.formulario.valid) {
      if (!!this.formulario.get('recurrent').value) {
        if (
          (!this.formulario.get('endsAfterEvents').value &&
            !this.formulario.get('endsAfterDay').value) ||
          !this.recurrentFieldsAreFilled()
        ) {
          this.alertMsg(
            'Parâmetros de recorrência obrigatórios não preenchidos.',
            'danger'
          );
          return;
        }
      }

      const formValue = this.formulario.getRawValue();

      if (formValue['severalProviders']) {
        formValue['provider'] = null;
        formValue['providerId'] = null;
      }

      this._reverseDeliveryManualAllocationService
        .validateRecurrentSchedule(formValue)
        .subscribe(
          (data: HttpResponse<SchedulesConflictDTO[]>) => {
            

            if (data) {
              if (data.status == 200) {
                this.rowsDetails = data.body;
                this.selected = this.rowsDetails.filter(row => row['conflict']);
                this.conflicts = this.selected.length;
                this.openModal(this.template1);
                return;
              }

              if (data.status == 202) {
                const dates = data.body.map(date => date['date']);
                this.formulario.get('dates').setValue(dates);

                this.createScheduleToReverseWithoutConflictRecurrent();
              }
            }
          },
          error => this.alertMsg(error.error, 'danger')
        );
      // }
      // else {
      //   this._docksScheduling
      //     .findByDocksScheduling(
      //       this.formulario.get('priorVisualization').value['id'],
      //       this.formulario.get('iniHour').value,
      //       this.formulario.get('finHour').value,
      //       moment(this.formulario.get('dateSchedule').value).format(
      //         'YYYY-MM-DD'
      //       )
      //     )
      //     .subscribe((data: DocksScheduling[]) => {
      //       if (data && data.length) {
      //         // openModal
            //         this.rowsDetails = data;
      //         this.conflicts = data.length;
      //         this.openModal(this.template1);
      //         return;
      //       }

      //       this.createScheduleToReverseWithoutConflict();
      //     });
      // }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  closeModalConfirmConflicts() {
    this.modalRef.hide();
  }

  createScheduleToReverseWithoutConflict() {
    if (this.formulario.get('id').value == null) {
      const formValue = this.formulario.getRawValue();

      if (formValue['severalProviders']) {
        formValue['provider'] = null;
        formValue['providerId'] = null;
      }

      this.subscriptions.push(
        this._reverseDeliveryManualAllocationService
          .create(formValue)
          .subscribe((data: ReverseDeliveryManualAllocation) => {
            this.formulario.reset();
            this.formulario.disable();

            
            this.alertMsg(
              `Agendamento efetuado com sucesso. Numeração ${
                data[0]['deliveryAuthorization'].authorizationkey
              }`
            );
          })
      );
    }
  }

  createScheduleToReverseWithoutConflictRecurrent() {
    if (this.formulario.get('id').value == null) {
      const formValue = this.formulario.getRawValue();

      if (formValue['severalProviders']) {
        formValue['provider'] = null;
        formValue['providerId'] = null;
      }

      this.subscriptions.push(
        this._reverseDeliveryManualAllocationService
          .createReverseRecurrent(formValue)
          .subscribe((data: ReverseDeliveryManualAllocation) => {
            this.formulario.reset();
            this.formulario.disable();

            
            this.alertMsg(
              `Agendamento efetuado com sucesso. Numeração ${
                data[0]['deliveryAuthorization'].authorizationkey
              }`
            );
          })
      );
    }
  }

  createScheduleWithConflict() {
    if (!this.selected.length) {
      return;
    }

    let dates = [];
    let datesWithoutConflict = [];

    dates = this.selected
      .filter(row => row['conflict'])
      .map(row => row['date']);

    datesWithoutConflict = this.rowsDetails
      .filter(
        row => !row['conflict'] && !row['holiday'] && !row['notOperational']
      )
      .map(row => row['date']);

    
    

    this.formulario.get('dates').setValue(dates);
    this.formulario.get('datesWithoutConflict').setValue(datesWithoutConflict);

    

    this.closeModalConfirmConflicts();

    this._reverseDeliveryManualAllocationService
      .createWithConflict(this.formulario.value)
      .subscribe(data => {
        if (data) {
          this.formulario.reset();
          this.formulario.disable();

          this.alertMsg(
            'Agendamento efetuado com sucesso, porém existem conflitos há serem resolvidos',
            'warning'
          );
        }
      });
  }

  openModalConflicts() {}

  openModalScheduleManualConfirm(message: string) {
    const initialState = {
      title: 'Mensagem Confirmação',
      message,
      form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._reverseDeliveryManualAllocationService
              .createWithConflict(this.formulario.value)
              .subscribe(data => {
                
                this.onSuccesWithConflict(data);
              });
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  onSuccesWithConflict(data: ReverseDeliveryManualAllocation) {
    // this.disabledForm();
    // this.getGrid().reloadPage();
    // this.alertMsg(SUCCESS_MESSAGE_CONFLICT.replace(':shortName', data.provider.shortName).replace(':totalConflicts', data.totalConflicts.toString()));
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  filterSchedules() {
    if (this.formFilters.valid) {
      this.$grdManualAllocation.reloadPage();
    } else {
      this.verificaValidacoesForm(this.formFilters);
    }
  }

  clearFilters() {
    this.formFilters.reset();
  }

  selectedCollect(event) {}

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    // this.loadDepositors();

    this.depositor.items = this.depositors;
    this.formulario.get('recurrent').setValue(false);
    this.formulario
      .get('dateSchedule')
      .setValue(moment(new Date()).format('YYYY-MM-DD'));
    // this.loadProviders();
    this.estalblishment.items = this.establishments;
  }

  resetar() {
    this.formulario.reset();
    this.formulario.disable();
    this.formEnabled = false;
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositor') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositor').setValue(row['depositor'].id);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.enable();
  }

  endPointManualAllocation() {
    return this.queryParamsFromFilters(
      '/wrapper/schedules/reverse-delivery/manual-allocation/search/reverse/collect'
    );
  }

  export() {
    if (this.formFilters.valid) {
      const dateIni = this.convertDate('initDate');
      const dateFin = this.convertDate('finDate');

      const urlParams = [
        { name: 'dateIni', value: dateIni },
        { name: 'dateFin', value: dateFin },
        {
          name: 'depositorId',
          value: this.formFilters.get('depositor').value
            ? this.formFilters.get('depositor').value['id']
            : ''
        },
        {
          name: 'providerId',
          value: this.formFilters.get('providerId').value || ''
        },
        {
          name: 'authorizationKey',
          value: this.formFilters.get('authorizationKey').value || ''
        },
        { name: 'status', value: this.listOfStatus().join() },
        { name: 'operationSubNature', value: this.listOfOpSubNature().join() },
        { name: 'opNatureDelivery', value: this.listOfOpNature().join() },
        { name: 'statusHistoric', value: this.listOfStatusHistoric().join() },
        {
          name: 'priorVisualization',
          value: this.formFilters.get('priorVisualization').value || ''
        }
      ];

      let queryString = '';
      const query = [];

      urlParams.forEach(obj => {
        query.push(
          encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
        );
      });

      queryString = query.join('&');

      this._reverseDeliveryManualAllocationService
        .findByReverseSearchExport(queryString)
        .subscribe(data => {
          importedSaveAs(data, 'agendamentos-reversa.xlsx');
        });
    } else {
      this.verificaValidacoesForm(this.formFilters);
    }
  }

  queryParamsFromFilters(uri: string) {
    const dateIni = this.convertDate('initDate');
    const dateFin = this.convertDate('finDate');

    const urlParams = [
      { name: 'dateIni', value: dateIni },
      { name: 'dateFin', value: dateFin },
      {
        name: 'depositorId',
        value: this.formFilters.get('depositor').value
          ? this.formFilters.get('depositor').value['id']
          : ''
      },
      {
        name: 'providerId',
        value: this.formFilters.get('providerId').value || ''
      },
      {
        name: 'authorizationKey',
        value: this.formFilters.get('authorizationKey').value || ''
      },
      { name: 'status', value: this.listOfStatus().join() },
      { name: 'operationSubNature', value: this.listOfOpSubNature().join() },
      { name: 'opNatureDelivery', value: this.listOfOpNature().join() },
      { name: 'statusHistoric', value: this.listOfStatusHistoric().join() },
      {
        name: 'priorVisualization',
        value: this.formFilters.get('priorVisualization').value || ''
      },
      {
        name: 'geralDepositors',
        value: this.formFilters.get('geralDepositors').value
      }
    ];

    let queryString = '';
    const query = [];

    urlParams.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    return `${uri}?${queryString}&`;
  }

  // `${SDM_URL.deliveryAuthorization.queryDelivery}`;

  listOfStatusHistoric(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'accomplished', value: 10 },
      { fieldName: 'noShow', value: 11 }
    ];
    for (const field of fieldStatus) {
      if (this.formFilters.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  listOfStatus(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'docOk', value: 1 },
      { fieldName: 'interrupt', value: 2 },
      { fieldName: 'pending', value: 3 },
      { fieldName: 'approved', value: 4 },
      { fieldName: 'manualAllocation', value: 5 },
      { fieldName: 'approvedWithoutSchedule', value: 6 },
      { fieldName: 'expired', value: 7 },
      { fieldName: 'canceled', value: 8 },
      { fieldName: 'reschedule', value: 9 },
      { fieldName: 'finalized', value: 10 },
      { fieldName: 'noShow', value: 11 }
    ];
    for (const field of fieldStatus) {
      if (this.formFilters.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  listOfOpSubNature(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'opSubNatureRefuse', value: SubNature.REFUSES },
      { fieldName: 'opSubNatureReturns', value: SubNature.RETURNS }
    ];
    for (const field of fieldStatus) {
      if (this.formFilters.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  listOfOpNature(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'opNatureRegular', value: 1 },
      { fieldName: 'opNatureReverse', value: 2 },
      { fieldName: 'opNatureCollect', value: 3 }
    ];
    for (const field of fieldStatus) {
      if (this.formFilters.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  // { name: 'schedules.reverse-delivery.manual-allocation.subNature.options.refuses', value: SubNature.REFUSES },
  //   { name: 'schedules.reverse-delivery.manual-allocation.subNature.options.returns', value: SubNature.RETURNS },

  convertDate(field: string): string {
    if (this.formFilters.get(field).value) {
      let date = new Date(
        this.formFilters.get(field).value
      ).toLocaleDateString();
      date = date
        .split('/')
        .reverse()
        .join('-');
      return date;
    }
    return '';
  }

  allowReschedule() {
    return (
      !this.selectedManualAllocationObj ||
      this.checkDiffTime() ||
      /CANCELADO|NO_SHOW|FINALIZADA/.test(
        this.selectedManualAllocationObj['statusAuthorization']
      )
    );
  }

  reschedule() {
    if (!this.selectedManualAllocationObj) {
      this.alertMsg('Selecione para reagendar.', 'info');
      return;
    }

    this._docksOperationService
      .findByDeliveryAuthorizationId(this.selectedManualAllocationObj.id)
      .subscribe((data: DocksOperation) => {
        if (data && data.operationDateArrival != null) {
          this.alertMsg(
            'Operação não permitida. Chegada do veículo já registrada.',
            'danger'
          );
        } else {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              manualAllocation: JSON.stringify(this.selectedManualAllocationObj)
            },
            skipLocationChange: true
          };
          this.router.navigate(
            ['sdm/register/schedules/reverse-delivery/manual-allocation/edit'],
            navigationExtras
          );
        }
      });
  }

  selectedManualAllocation(row) {
    
    this.selectedManualAllocationObj = row;
  }

  selectedDock(row: PriorVisualization) {
    this.priorVisualizationSelected = row;
    
    this.formulario
      .get('priorVisualization')
      .setValue(this.priorVisualizationSelected);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  openModal2(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(template, {
      class: 'second modal-lg'
    });
  }

  setRequiredForFields(fields: any[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).setValidators([Validators.required]);
      form.get(field).updateValueAndValidity();
      form.get(field).markAsUntouched();
    }
  }

  removeRequiredForFields(fields: string[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).clearValidators();
      form.get(field).updateValueAndValidity();
      form.get(field).setValue('');
      form.get(field).markAsPristine();
    }
  }

  enableFields(fields: any[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).enable();
    }
  }

  disableFields(fields: any[], form = this.formulario) {
    for (const field of fields) {
      form.get(field).disable();
      form.get(field).setValue('');
    }
  }

  cancelReverseDelivery() {}

  allowCancel() {
    return (
      !this.selectedManualAllocationObj ||
      this.checkDiffTime() ||
      /CANCELADO|NO_SHOW|FINALIZADA/.test(
        this.selectedManualAllocationObj['statusAuthorization']
      )
    );
  }

  checkDiffTime(): boolean {
    if (!this.selectedManualAllocationObj) return false;

    const now = moment().format('YYYY-MM-DD HH:mm');
    const dateSchedule = moment(
      `${
        this.selectedManualAllocationObj['docksScheduling'].dateScheduleDelivery
      }`
    ).format('YYYY-MM-DD');

    const dateHourSchedule = moment(
      `${dateSchedule} ${
        this.selectedManualAllocationObj['docksScheduling'].initialHour
      }`
    ).format('YYYY-MM-DD HH:mm');

    return now > dateHourSchedule;
  }

  checkIfExistsRecurrentToCancel() {
    this.manualAllocationRecurrents = [];

    if (!this.selectedManualAllocationObj) {
      this.alertMsg('Selecione para cancelar.', 'info');
      return;
    }

    

    this._docksOperationService
      .findByDeliveryAuthorizationId(this.selectedManualAllocationObj.id)
      .subscribe((data: DocksOperation) => {
        if (data && data.operationDateArrival != null) {
          this.alertMsg(
            'Operação não permitida. Chegada do veículo já registrada.',
            'danger'
          );
        } else {
          if (this.selectedManualAllocationObj['recurrent']) {
            this._reverseDeliveryManualAllocationService
              .findByRecurrent(this.selectedManualAllocationObj.id)
              .subscribe(data => {
                if (data && data.length > 1) {
                  this.manualAllocationRecurrents = data;

                  this.openModal(this.templateModalConfirmAll);
                } else if (data.length == 1) {
                  this.openModalConfirmCancel();
                }
              });
          } else {
            this.openModalConfirmCancel();
          }
        }
      });
  }

  openModalConfirmCancel() {
    if (!this.selectedManualAllocationObj) {
      this.alertMsg('Selecione para cancelar.', 'info');
      return;
    }

    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja Cancelar? O Agendamento será cancelado e portanto não será possível efetuar a Operação de Carga.',
      classBtnSuccess: 'btn-warning',
      classBtnDanger: 'btn-outline-dark'
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._deliveryAuthorizationService
              .cancelDeliveryAuthorization(this.selectedManualAllocationObj.id)
              .subscribe(
                () => {
                  // this._deliveryAuthorizationService
                  //   .resolvewhenExistsBothConflict(this.selectedManualAllocationObj['docksScheduling'].priorVisualizationId,
                  //   this.selectedManualAllocationObj['docksScheduling'].initialHour,
                  //   this.selectedManualAllocationObj['docksScheduling'].finalHour)
                  this.selectedManualAllocationObj = null;
                  this.alertMsg('Agendamento cancelado.');
                  this.$grdManualAllocation.reloadPage();
                },
                error => this.alertMsg(error, 'error')
              );
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  confirmCancelThis() {
    this.modalRef.hide();

    this._deliveryAuthorizationService
      .cancelDeliveryAuthorization(this.selectedManualAllocationObj.id)
      .subscribe(
        () => {
          this.selectedManualAllocationObj = null;
          this.alertMsg('Agendamento cancelado.');
          this.$grdManualAllocation.reloadPage();
        },
        error => this.alertMsg(error, 'error')
      );
  }

  confirmCancelAll() {
    this.modalRef.hide();

    this._deliveryAuthorizationService
      .cancelDeliveryAuthorizations(
        this.manualAllocationRecurrents.map(
          manual => manual['deliveryAuthorizationId']
        )
      )
      .subscribe(
        () => {
          this.selectedManualAllocationObj = null;
          this.alertMsg('Agendamento(s) cancelado(s).');
          this.$grdManualAllocation.reloadPage();
        },
        error => this.alertMsg(error, 'error')
      );
  }

  onSelectConflict({ selected }) {
    this.selected = selected;
    this.allConflicts = this.selected.length == this.rowsDetails.length;
    this.conflicts = this.selected.filter(row => row['conflict']).length;
  }

  onActivate({ row }) {
    if (!row['conflict'] && !row['holiday'] && !row['notOperational']) {
      this.selected = this.selected.filter(selected => selected != row);
    }
  }

  changeCheckBoxConflict(checked) {
    
    this.selected = checked
      ? this.rowsDetails.filter(row => row['conflict'])
      : [];
    this.conflicts = this.selected.length;
  }

  getNewInstance(): ReverseDeliveryManualAllocation {
    return new ReverseDeliveryManualAllocation();
  }
  getService(): AbstractService<ReverseDeliveryManualAllocation> {
    return this._reverseDeliveryManualAllocationService;
  }
  getComponentName(): string {
    return '';
  }

  getRowClass(row) {
    return {
      'schedule-ok': !row.conflict && !row.holiday && !row.notOperationl,
      'conflict-row': row.conflict,
      'holiday-color': row.holiday,
      'not-operational': row.notOperationl
    };
  }
}
