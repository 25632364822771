import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'SbawProcessStatusConverter' })
export class SbawProcessStatusConverter implements PipeTransform {

    public statusListBr = [
        { code: -3, status: "IMPORTED", description: "Importado" },
        { code: -2, status: "CANCELED", description: "Cancelado" },
        { code: -1, status: "IGNORED", description: "Ignorado" },
        { code: 0, status: "PENDING", description: "Pendente" },
        { code: 1, status: "PARTIAL", description: "Parcial" },
        { code: 2, status: "PROCESSED", description: "Processado" },
        { code: 3, status: "EXPORTED", description: "Exportado" },
    ]

    public statusListEn = [
        { code: -3, status: "IMPORTED", description: "Imported" },
        { code: -2, status: "CANCELED", description: "Canceled" },
        { code: -1, status: "IGNORED", description: "Ignored" },
        { code: 0, status: "PENDING", description: "Pending" },
        { code: 1, status: "PARTIAL", description: "Partial" },
        { code: 2, status: "PROCESSED", description: "Processed" },
        { code: 3, status: "EXPORTED", description: "Exported" },
    ]

    private locales = [
        { list: this.statusListBr, lang: "br" },
        { list: this.statusListEn, lang: "en" }
    ]

    transform(value): string {
        let lang = navigator.language.toLowerCase();

        for (let localeStatus of this.locales) {
            if (lang.endsWith(localeStatus.lang)) {
                for (let status of localeStatus.list) {
                    if (status.code === value) {
                        return status.description;
                    }
                }
                return "";
            }
        }
    }

}
