import * as moment from 'moment/moment';
import { AbstractStatusModel } from '../../../abstractStatus.model';
import { DeliveryAuthorization } from '../../delivery-authorization/delivery-authorization';
import { Depositor } from '../../depositor';
import { PriorVisualization } from '../../docks/prior-visualization';
import { DocksScheduling } from '../../document/docks-scheduling';
import { Provider } from '../../provider-management/provider';

export class ManualAllocationReverse extends AbstractStatusModel {
  public depositor: Depositor;
  public provider: Provider;
  public operationSubNature: number;
  public deliveryAuthorization: DeliveryAuthorization;
  public dateScheduleDelivery: number;
  public priorVisualization: PriorVisualization;
  public initialHour: string;
  public finalHour: string;
  public eventName: string;

  public dateScheduleDeliveryNew: string;
  public priorVisualizationNew: PriorVisualization;
  public docksNew: PriorVisualization;
  public initialHourNew: string;
  public finalHourNew: string;
  public eventNameNew: string;
  public recurrent: boolean;
  public conflicted: boolean;

  public updateSchedule: number;
  public actionType: number;

  public docksScheduling: DocksScheduling;

  constructor() {
    super();
    this.dateScheduleDeliveryNew = '';
    this.docksNew = new PriorVisualization();
    this.initialHourNew = '';
    this.finalHourNew = '';
    this.eventNameNew = '';
    this.updateSchedule = 1;
    this.actionType = 1;
    this.docksScheduling = new DocksScheduling();
  }
}
