import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { DatatableApiComponent } from '../../../../../components/datatable-api/datatable-api.component';
import { PriorityProviders } from '../../../../../core/models/sdm/provider-management/priority-providers';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { PriorityProvidersService } from '../../../../../core/services/sdm/provider-management/priority-providers.service';
import { AbstractRegister } from '../../../abstract-register';
import { ProviderManagement } from '../../provider-management.service';
import { SDM_URL } from './../../../../../app.api';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { DateFormatPipe } from './../../../../../core/classes/datePipe';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';

const fields = ['dateTo', 'dateUntil'];

@Component({
  selector: 'app-priority-provider-form',
  templateUrl: './priority-provider-form.component.html',
  styleUrls: ['./priority-provider-form.component.scss'],
})
export class PriorityProviderFormComponent extends AbstractRegister<PriorityProviders> implements OnInit {

  tempPriority = false;
  @ViewChild('gridPriorityProviders') grid$: DatatableApiComponent;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  columnsPriority = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishment.id', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'depositor.document', name: 'CNPJ Depositante' },
    { prop: 'provider.document', name: 'CNPJ Fornecedor' },
    { prop: 'provider.shortName', name: 'Nome fantasia' },
    { prop: 'priority', name: 'Prioritário', pipe: new BooleanConverter() },
    { prop: 'temporaryPriority', name: 'Prioridade Temporária', pipe: new BooleanConverter() },
    { prop: 'dateTo', name: 'De', pipe: new DateFormatPipe('en-US') },
    { prop: 'dateUntil', name: 'Até', pipe: new DateFormatPipe('en-US') },
  ];

  columnsProvider = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Nome fantasia' },
    { prop: 'document', name: 'CNPJ' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _priorityProviderService: PriorityProvidersService,
    private _providerService: ProviderManagement) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerService.emitEventTabPriorityFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      document: new FormControl('', [Validators.required]),
      shortName: new FormControl(''),
      priority: new FormControl(''),
      temporaryPriority: new FormControl(''),
      dateTo: new FormControl(''),
      dateUntil: new FormControl(''),
    });

  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.disableFields(fields);
  }

  changeTempPriority(event) {
    this.tempPriority = (event.target.value === 'true');
    if (this.tempPriority) {
      this.setRequiredForFields(fields);
      this.enableFields(fields);
      return;
    }
    this.removeRequiredForFields(fields);
    this.disableFields(fields);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    if (row.hasOwnProperty('depositor') && row['depositor'].hasOwnProperty('establishment')) {
      if (this.formulario.get('depositorId') && this.formulario.get('establishmentId')) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario.get('establishmentId').setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.enable();
    this.isTempPriority();
  }

  isTempPriority() {
    if (this.tempPriority) {
      this.setRequiredForFields(fields);
      this.enableFields(fields);
      return;
    }
    this.removeRequiredForFields(fields);
    this.disableFields(fields);
  }

  queryProviderDocument = () => SDM_URL.providerManagement.priorityProviders.findByDocument.replace(':document', '');

  endpointUpload = () => SDM_URL.providerManagement.priorityProviders.upload;

  endPointPriorityProviders = () => SDM_URL.providerManagement.priorityProviders.default;

  endPointPartial = () => SDM_URL.providerManagement.priorityProviders.importPartial;

  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  getNewInstance(): PriorityProviders {
    return new PriorityProviders();
  }
  getService(): AbstractService<PriorityProviders> {
    return this._priorityProviderService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.priority-providers';
  }

}
