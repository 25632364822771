import { LogisticCostsComponent } from './logistic-costs/logistic-costs.component';
import { EmailSmsCollectComponent } from './emails-sms/email-sms/email-sms-collect/email-sms-collect.component';
import { EmailSmsReverseDeliveryComponent } from './emails-sms/email-sms/email-sms-reverse-delivery/email-sms-reverse-delivery.component';
import { EmailSmsRegularDeliveryComponent } from './emails-sms/email-sms/email-sms-regular-delivery/email-sms-regular-delivery.component';
import { EmailSmsComponent } from './emails-sms/email-sms/email-sms.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TextMaskModule } from 'angular2-text-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSelectModule } from 'ngx-select-ex';
import { ToastrModule } from 'ngx-toastr';
import { MeasureUnitService } from '../../core/services/measure-unit/measure-unit.service';
import { OrderService } from '../../core/services/sdm/regular-delivery/order.service';
import { RegularDeliveryRequestMaxService } from '../../core/services/sdm/regular-delivery/regular-delivery-request-max.service';
import { RegularDeliveryService } from '../../core/services/sdm/regular-delivery/regular-delivery.service';
import { ReverseDeliveryRequestMaxService } from '../../core/services/sdm/reverse-delivery/reverse-delivery-request-max.service';
import { ReverseDeliveryService } from '../../core/services/sdm/reverse-delivery/reverse-delivery.service';
import { RmAuthorizationService } from '../../core/services/sdm/reverse-delivery/rma.service';
import { SlotPatternService } from '../../core/services/slot/slot-pattern.service';
import { SlotReserveService } from '../../core/services/slot/slot-reserve.service';
import { SlotRoundService } from '../../core/services/slot/slot-round.service';
import { SharedModule } from '../../shared/shared.module';
import { CollectFormComponent } from './collect/collect-form/collect-form.component';
import { CollectNfFormComponent } from './collect/collect-nf/form/collect-nf-form.component';
import { CollectRmaFormComponent } from './collect/collect-rma/form/collect-rma-form.component';
import { CollectSmallSizeFormComponent } from './collect/collect-small-size/form/collect-small-size-form.component';
import { RequestMaxFormComponent } from './collect/request-max/form/request-max-form.component';
import { DockCompatibilityFormComponent } from './docks/dock-compatibility/form/dock-compatibility-form.component';
import { DockVehicleCompatibilityFormComponent } from './docks/dock-vehicle-compatibility/form/dock-vehicle-compatibility-form.component';
import { DocksFormComponent } from './docks/form/docks-form.component';
import { HolidayRelationshipFormComponent } from './docks/holiday-relationship/form/holiday-relationship-form.component';
import { PriorVisualizationFormComponent } from './docks/prior-visualization/form/prior-visualization-form.component';
import { RegularOperatingDaysFormComponent } from './docks/regular-operating-days/form/regular-operating-days-form.component';
import { MeasureUnitFormComponent } from './measure-unit/form/measure-unit-form.component';
import { OrderFormComponent } from './regular-delivery/order/form/order-form.component';
import { RegularDeliveryFormComponent } from './regular-delivery/regular-delivery-form/regular-delivery-form.component';
import { RegularDeliveryNfFormComponent } from './regular-delivery/regular-delivery-nf/form/regular-delivery-nf-form.component';
import { RegularDeliverySmallSizeFormComponent } from './regular-delivery/regular-delivery-small-size/form/regular-delivery-small-size-form.component';
import { RegularDeliveryReqMaxComponent } from './regular-delivery/request-max/form/regular-delivery-req-max-form.component';
import { ReverseDeliveryReqMaxComponent } from './reverse-delivery/request-max/form/reverse-delivery-req-max-form.component';
import { ReverseDeliveryFormComponent } from './reverse-delivery/reverse-delivery-form/reverse-delivery-form.component';
import { ReverseDeliveryNfFormComponent } from './reverse-delivery/reverse-delivery-nf/form/reverse-delivery-nf-form.component';
import { ReverseDeliverySmallSizeFormComponent } from './reverse-delivery/reverse-delivery-small-size/form/reverse-delivery-small-size-form.component';
import { RmaFormComponent } from './reverse-delivery/rma/form/rma-form.component';
import { SlotPatternFormComponent } from './slot/slot-pattern-form/slot-pattern-form.component';
import { SlotReserveFormComponent } from './slot/slot-reserve-form/slot-reserve-form.component';
import { SlotRoundFormComponent } from './slot/slot-round-form/slot-round-form.component';
import { SlotComponent } from './slot/slot/slot.component';
import { HandlingPendingRegularDeliveryComponent } from './handling/handling-pending-regular-delivery/handling-pending-regular-delivery.component';
import { PendingHandlingRegularDeliveryService } from '../../core/services/sdm/handling/pending-handling-regular-delivery.service';
import { OcurrencyReworkComponent } from './logistic-costs/ocurrency-rework/ocurrency-rework.component';
import { IdlenessDocksComponent } from './logistic-costs/idleness-docks/idleness-docks.component';
import { DockOptionsComponent } from './docks/dock-options/dock-options.component';
import { DockOrderComponent } from './docks/dock-order/dock-order.component';

const routes = [
  { path: 'slot', component: SlotComponent },

  { path: 'measure-unit', component: MeasureUnitFormComponent },

  { path: 'regular-delivery', component: RegularDeliveryFormComponent },
  { path: 'regular-delivery/order', component: OrderFormComponent },

  { path: 'reverse-delivery', component: ReverseDeliveryFormComponent },
  { path: 'reverse-delivery/rma', component: RmaFormComponent },

  { path: 'collect', component: CollectFormComponent },

  { path: 'docks', component: DocksFormComponent },

  {
    path: 'handling/pending',
    component: HandlingPendingRegularDeliveryComponent
  },
  {
    path: 'email-sms',
    component: EmailSmsComponent
  },
  {
    path: 'logistic-costs',
    component: LogisticCostsComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    ToastrModule.forRoot(),
    NgxDatatableModule,
    NgxSelectModule,
    TextMaskModule,
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule
  ],
  providers: [
    SlotPatternService,
    SlotRoundService,
    SlotReserveService,
    MeasureUnitService,
    RegularDeliveryService,
    ReverseDeliveryService,
    OrderService,
    RmAuthorizationService,
    RegularDeliveryRequestMaxService,
    ReverseDeliveryRequestMaxService,
    PendingHandlingRegularDeliveryService
  ],
  declarations: [
    SlotPatternFormComponent,
    SlotRoundFormComponent,
    SlotReserveFormComponent,
    MeasureUnitFormComponent,
    RegularDeliveryFormComponent,
    ReverseDeliveryFormComponent,
    OrderFormComponent,
    RmaFormComponent,
    RegularDeliveryReqMaxComponent,
    ReverseDeliveryReqMaxComponent,
    RegularDeliverySmallSizeFormComponent,
    ReverseDeliverySmallSizeFormComponent,
    RegularDeliveryNfFormComponent,
    ReverseDeliveryNfFormComponent,
    RegularOperatingDaysFormComponent,
    HolidayRelationshipFormComponent,
    DocksFormComponent,
    PriorVisualizationFormComponent,
    DockCompatibilityFormComponent,
    DockVehicleCompatibilityFormComponent,
    SlotComponent,
    CollectFormComponent,
    RequestMaxFormComponent,
    CollectFormComponent,
    CollectNfFormComponent,
    CollectSmallSizeFormComponent,
    CollectRmaFormComponent,
    HandlingPendingRegularDeliveryComponent,
    EmailSmsComponent,
    EmailSmsRegularDeliveryComponent,
    EmailSmsReverseDeliveryComponent,
    EmailSmsCollectComponent,
    LogisticCostsComponent,
    OcurrencyReworkComponent,
    IdlenessDocksComponent,
    DockOptionsComponent,
    DockOrderComponent
  ],
  exports: [RouterModule]
})
export class ParametrizationModule {}
