import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { Location } from '@angular/common';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { RegularOperatingDaysService } from './../../../../../core/services/sdm/docks/regular-operating-days.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractParametrizations } from '../../../abstract-parametrizations-crud';
import { RegularOperationgDays } from '../../../../../core/models/sdm/docks/regular-operationg-days';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SDM_URL } from '../../../../../app.api';

@Component({
  selector: 'app-regular-operating-days-form',
  templateUrl: './regular-operating-days-form.component.html',
  styleUrls: ['./regular-operating-days-form.component.scss'],
})
export class RegularOperatingDaysFormComponent extends AbstractParametrizations<RegularOperationgDays> implements OnInit {

  regularOperatingDays: RegularOperationgDays;
  @ViewChild('grdRegularOperating') grid: DatatableApiComponent;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  columns = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    { prop: 'monday', name: 'Segundas-feiras', pipe: new BooleanConverter() },
    { prop: 'tuesday', name: 'Terças-feiras', pipe: new BooleanConverter() },
    { prop: 'wednesday', name: 'Quartas-feiras', pipe: new BooleanConverter() },
    { prop: 'thursday', name: 'Quintas-feiras', pipe: new BooleanConverter() },
    { prop: 'friday', name: 'Sextas-feiras', pipe: new BooleanConverter() },
    { prop: 'saturday', name: 'Sabados', pipe: new BooleanConverter() },
    { prop: 'sunday', name: 'Domingos', pipe: new BooleanConverter() },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public regularOperatingDaysService: RegularOperatingDaysService) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.regularOperatingDays = new RegularOperationgDays();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      sunday: new FormControl(this.regularOperatingDays.sunday),
      monday: new FormControl(this.regularOperatingDays.monday),
      tuesday: new FormControl(this.regularOperatingDays.tuesday),
      wednesday: new FormControl(this.regularOperatingDays.wednesday),
      thursday: new FormControl(this.regularOperatingDays.thursday),
      friday: new FormControl(this.regularOperatingDays.friday),
      saturday: new FormControl(this.regularOperatingDays.saturday),
    });
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.regularOperatingDays);
  }

  makeEndPointForRegularOperatingDays = () => SDM_URL.docks.regularOperatingDays.default;

  getNewInstance(): RegularOperationgDays {
    return new RegularOperationgDays();
  }
  getService(): AbstractService<RegularOperationgDays> {
    return this.regularOperatingDaysService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'docks.regular-operating-days';
  }

}
