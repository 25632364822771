import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ElementRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, SelectControlValueAccessor } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslatorService } from '../../../core/translator/translator.service';
import { Page } from './../../../core/models/page';

@Component({
  selector: 'app-select-entity',
  templateUrl: './select-entity.component.html',
  styleUrls: ['./select-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectEntityComponent,
      multi: true
    }
  ]
})
export class SelectEntityComponent extends SelectControlValueAccessor {
  @Input() public name: string = '';
  @Input() public label: string = '';
  @Input() public i18n: boolean = true;
  @Input() public disabled: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public optionTextField: string = '';
  @Input() public optionValueField: string = 'id';
  @Input() public urlListAll: string = '';
  @Input() public disableOnly: boolean = false;
  @Output() public clearItens = new EventEmitter();
  @Output() public onClearSelect = new EventEmitter();
  @Input() public requestParams: Array<any>;
  @Input() public property: string = '';
  public items: Array<Object> = new Array();
  public loading: boolean = false;
  public page: Page = new Page();
  public filter = '';
  private charged: boolean = false;
  public needInnerValue: boolean = true;
  private innerValue: any;
  private searchTearm: String = '';

  constructor(
    private http: HttpClient,
    private config: NgSelectConfig,
    private translatorService: TranslatorService,
    _renderer: Renderer2,
    _elementRef: ElementRef
  ) {
    super(_renderer, _elementRef);
    this.config.notFoundText = 'Nenhum registro encontrado';

    // this.translatorService.translate.get('ui.select.emptySelect')
    //   .subscribe(message => { this.config.notFoundText = 'teste'; });
  }

  set value(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  get value() {
    return this.innerValue;
  }

  onChange: (_: any) => void;
  onTouched: () => void;
  compareWith: (o1: any, o2: any) => boolean;

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  writeValue(v: any): void {
    this.value = v;
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  ngOnInit() {
    if (this.urlListAll == '') {
      return;
    }

    this.page.endpoint = this.urlListAll;
    this.fetchMore(0);
  }

  ngDoCheck() {
    if (!this.charged && this.innerValue) {
      this.items = new Array();
      this.fetchMore(0);
      this.charged = true;
      this.items = new Array();
    }
  }

  clearInternalValue() {
    this.innerValue = null;
  }

  fetchMore(pageNumber: number) {
    if (this.page.endpoint === null || this.page.endpoint === '') {
      return;
    }

    this.loading = true;
    this.page.pageNumber = pageNumber;

    if (this.requestParams !== null && this.requestParams !== undefined) {
      let params = '';
      this.requestParams.forEach(value => {
        params += '&' + this.property + '=' + value;
      });
    }

    let url = this.page.generateURL();

    if (this.filter) {
      url += '&' + this.optionTextField + '=' + this.filter;
    }

    if (this.innerValue) {
      url += '&' + this.optionValueField + '=' + this.innerValue;
      url = url.replace(url.split('\?')[1].split('&').find(x => x.startsWith('sort=')).split(',')[0], 'sort=' + this.optionTextField);
    }

    this.http.get<any[]>(url).subscribe(
      response => {
        if (this.items && this.items.length != 0) {
          this.items = this.items.concat(
            response['content'].filter((itemResp: any) => {
              return (
                this.items.find((item: any) => item.id == itemResp.id) ===
                undefined
              );
            })
          );
        } else {
          this.items = response['content'];
        }

        if (response && response['pageable']) {
          this.page.size = 10;
          this.page.totalElements = response['pageable']['totalElements'] || 0;
          this.page.pageNumber = response['pageable']['pageNumber'] || null;
          this.page.total = response['pageable']['total'];
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  scroll({ end }) {
    if (this.loading || !end) {
      return;
    }

    if (!this.page.pageNumber) {
      this.page.pageNumber = 0;
    }

    if (
      end >= this.items.length &&
      this.page.total > this.page.pageNumber + 1
    ) {
      this.fetchMore(this.page.pageNumber + 1);
    }
  }

  onClear() {
    this.onClose();
  }

  onSearch(filterValue) {
    this.searchTearm = filterValue;

    if (this.loading) {
      return;
    }

    

    this.filter = filterValue['term'];
    this.fetchMore(0);
    this.items = new Array();
  }

  onClearManual() {
    this.innerValue = null;
  }

  onClose() {
    if (this.loading) {
      return;
    }

    this.filter = null;
    this.items = new Array();
    this.fetchMore(0);
  }

  @Output() selectedItem = new EventEmitter();

  onChangeSelect(event: any) {
    this.selectedItem.emit(event);
  }
}
