import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { take } from 'rxjs/operators';
import { CollectSmallSizeService } from './../../../../../core/services/sdm/collect/collect-small-size.service';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { CollectSmallSize } from './../../../../../core/models/sdm/collect/collect-small-size';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../../register/abstract-register';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { CollectService as CollectEvents } from '../../collect.service';
import { Subscription } from 'rxjs';
import { SDM_URL } from '../../../../../app.api';

@Component({
  selector: 'app-collect-small-size-form',
  templateUrl: './collect-small-size-form.component.html',
  styleUrls: ['./collect-small-size-form.component.scss'],
})
export class CollectSmallSizeFormComponent extends AbstractRegister<CollectSmallSize> implements OnInit {

  sub: Subscription;
  deliveryInSpecific = false;
  collectSmallSize: CollectSmallSize;
  @ViewChild('dataGrdCollectSmallSize') grid$: DatatableApiComponent;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false },
  ];

  columnsSmallSize = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Estabelecimento' },
    { prop: 'establishment.description', name: 'Estabelecimento' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'depositorDeliverySmallSize', name: 'Depositante permite entregas Pequeno Porte SEM Agendamento?', pipe: new BooleanConverter() },
    { prop: 'maxDurationDeliveryInMinutes', name: 'Duração Máxima da Entrega (em minutos)' },
    { prop: 'specificDelivery', name: 'Entrega em Doca Específica (Fixa)?', pipe: new BooleanConverter() },
    { prop: 'docaNumber', name: '# Doca' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _collectSmallSizeService: CollectSmallSizeService,
    private _collectEvents: CollectEvents,
  ) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.tabSmallSizeFired();

    this.collectSmallSize = new CollectSmallSize();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      depositorDeliverySmallSize: new FormControl(''),
      maxDurationDeliveryInMinutes: new FormControl('', [Validators.required, Validators.min(1), Validators.max(999)]),
      specificDelivery: new FormControl(''),
      docaNumber: new FormControl(''),
    });
  }

  tabSmallSizeFired() {
    this.sub = this._collectEvents.emitTabSmallSizeFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());
  }

  afterClickButtonAdd() {
    this.formulario.get('docaNumber').disable();
  }

  changeDeliveryDoca(event) {
    this.deliveryInSpecific = (event.target.value === 'true');
    
    this.enableOrDisableDocaNumber();
  }

  enableOrDisableDocaNumber() {
    if (!this.deliveryInSpecific) {
      this.formulario.get('docaNumber').setValue('');
      this.formulario.get('docaNumber').disable();
    } else {
      this.formulario.get('docaNumber').enable();
    }
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.enableOrDisableDocaNumber();
  }

  endPointCollectSmallSize = () => SDM_URL.collect.smallSize.default;

  getNewInstance(): CollectSmallSize {
    return new CollectSmallSize();
  }
  getService(): AbstractService<CollectSmallSize> {
    return this._collectSmallSizeService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'collect.small-size';
  }

}
