import { AbstractStatusModel } from '../../abstractStatus.model';
import { IDeserializable } from '../../deserializable.model';

export class SlotRound extends AbstractStatusModel implements IDeserializable {

  public id: number;
  public establishmentId: number;
  public depositorId: number;
  public round: number;
  public status: boolean;

  constructor() {
    super();
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
