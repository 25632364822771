import { AbstractService } from '../../abstract.service';
import { RmAuthorization } from '../../../models/sdm/reverse-delivery/rma';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { Observable } from 'rxjs';

@Injectable()
export class RmAuthorizationService extends AbstractService<RmAuthorization> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.reverseDelivery.rma.default);
  }

  createRMA(model: RmAuthorization[]): Observable<RmAuthorization> {
    return this.http.post<RmAuthorization>(
      SDM_URL.reverseDelivery.rma.create,
      model,
    );
  }
}
