import { ProviderManagement } from './../provider-management.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-management-form',
  templateUrl: './provider-management-form.component.html',
  styleUrls: ['./provider-management-form.component.scss'],
})
export class ProviderManagementFormComponent implements OnInit {

  constructor(private _providerManagementService: ProviderManagement) { }

  ngOnInit() {
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabBlackList':
        this._providerManagementService.tabBlackListFired();
        break;
      case 'tabPriorityProviders':
        this._providerManagementService.tabPriorityFired();
        break;
      case 'tabCriticalItems':
        this._providerManagementService.tabCriticalItemsFired();
        break;
      case 'tabImpreciseFields':
        this._providerManagementService.tabImpreciseFieldsFired();
        break;
      case 'tabDaysHoursRestriction':
        this._providerManagementService.tabDaysHoursRestrictionFired();
        break;
    }
  }

}
