import { Depositor } from './../../depositor';
import { Provider } from './../../provider-management/provider';
import { AbstractStatusModel } from '../../../abstractStatus.model';

export class Document extends AbstractStatusModel {
  public depositorId: number;
  public providerId: number;
  public documentSender: string;
  public documentRecipient: string;
  public documentShipping: string;
  public nfNumber: number;
  public nfSerie: number;
  public emissionDate: string;
  public orderNumber: number;
  public totalQtdItems: number;
  public totalQtdUnit: number;
  public totalValueNf: number;
  public totalGrossWeight: number;
  public totalNetWeight: number;
  public deliveryAuthorizationId: number;

  public provider: Provider[];
  public depositor: Depositor;

  constructor() {
    super();
  }
}
