import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { AbstractService } from '../../abstract.service';
import { PriorityProviders } from './../../../models/sdm/provider-management/priority-providers';

@Injectable({
  providedIn: 'root',
})
export class PriorityProvidersService extends AbstractService<PriorityProviders> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.providerManagement.priorityProviders.default);
  }
}
