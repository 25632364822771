import { AuthorizationShapePipe } from './../../../../core/classes/authorization-shape.pipe';
import { BooleanConverter } from './../../../../core/classes/boolean-type';
import { Establishment } from './../../../../core/models/sdm/establishment';
import { DateTimeFormatPipe } from './../../../../core/classes/dateTimePipe';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from '../../../../components/datatable-api/datatable-api.component';
import { AbstractService } from '../../../../core/services/abstract.service';
import { DepositorService } from '../../../../core/services/depositor.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractParametrizations } from '../../abstract-parametrizations-crud';
import { PendingHandlingRegularDelivery } from './../../../../core/models/sdm/handling/pending-handling-regular-delivery';
import { PendingHandlingRegularDeliveryService } from './../../../../core/services/sdm/handling/pending-handling-regular-delivery.service';
import { EstablishmentService } from '../../../../core/services/establishment.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-handling-pending-regular-delivery',
  templateUrl: './handling-pending-regular-delivery.component.html',
  styleUrls: ['./handling-pending-regular-delivery.component.scss']
})
export class HandlingPendingRegularDeliveryComponent
  extends AbstractParametrizations<PendingHandlingRegularDelivery>
  implements OnInit {
  @ViewChild('datatablePendingHandling')
  datatablePendingHandling: DatatableApiComponent;
  establishments: Establishment[] = [];

  authorizationShapeOptions = [
    {
      name:
        'handling-pending-regular-delivery.authorizationShapeOptions.options.phone',
      value: 1
    },
    {
      name:
        'handling-pending-regular-delivery.authorizationShapeOptions.options.mail',
      value: 2
    },
    {
      name:
        'handling-pending-regular-delivery.authorizationShapeOptions.options.inPerson',
      value: 3
    },
    {
      name:
        'handling-pending-regular-delivery.authorizationShapeOptions.options.others',
      value: 4
    }
  ];

  columns = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'depositor.establishment.shortDescription', name: 'Localidade' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    {
      prop: 'userCanAllocateOutOfTheValidity',
      name: 'Aloca fora prazo validade?',
      pipe: new BooleanConverter()
    },
    {
      prop: 'needSupervisorPassword',
      name: 'Precisa senha supervisor?',
      pipe: new BooleanConverter()
    },
    {
      prop: 'needDepositorAuthorize',
      name: 'Precisa autorização depositante?',
      pipe: new BooleanConverter()
    },
    {
      prop: 'maxQtdShowOptions',
      name: 'Quantidades máximas de opções a mostrar'
    },
    {
      prop: 'createdAt',
      name: 'Autorizado em',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  constructor(
    public location: Location,
    toaster: ToastrService,
    translatorService: TranslatorService,
    depositorService: DepositorService,
    public pendingHandlingService: PendingHandlingRegularDeliveryService,
    private establishmentService: EstablishmentService
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishment: new FormControl('', [Validators.required]),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      userCanAllocateOutOfTheValidity: new FormControl(''),
      needSupervisorPassword: new FormControl(''),
      needDepositorAuthorize: new FormControl(''),
      maxQtdShowOptions: new FormControl('', [
        Validators.required,
        Validators.min(1),
        Validators.max(9999)
      ])
    });

    this.formulario
      .get('userCanAllocateOutOfTheValidity')
      .valueChanges.subscribe(value => {
        if (!value) {
          this.disableFields([
            'needSupervisorPassword',
            'needDepositorAuthorize'
          ]);
          this.formulario.get('needSupervisorPassword').setValue(false);
          this.formulario.get('needDepositorAuthorize').setValue(false);
          return;
        }

        this.enableFields(['needSupervisorPassword', 'needDepositorAuthorize']);
      });

    this.formulario.get('establishment').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('establishmentId').setValue(value['id']);
      }
    });
  }

  afterAddButton() {
    this.establishmentService
      .findAll()
      .pipe(take(1))
      .subscribe(data => {
        this.establishments = [...data.body['content']];
        if (this.establishments.length === 1) {
          this.formulario.get('establishment').setValue(this.establishments[0]);
        }
      });
  }

  fillDepositorWhenSelectGridCell(row: object) {
    
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'] != null &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor']);
        this.formulario
          .get('establishment')
          .setValue(row['depositor']['establishment']);
      }
    }
  }

  getNewInstance(): PendingHandlingRegularDelivery {
    return new PendingHandlingRegularDelivery();
  }

  getService(): AbstractService<PendingHandlingRegularDelivery> {
    return this.pendingHandlingService;
  }

  getGrid(): DatatableApiComponent {
    return this.datatablePendingHandling;
  }

  getComponentName(): string {
    return 'handling-pending-regular-delivery';
  }
}
