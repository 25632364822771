import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { User } from '../models/sbaw/user';
import { Solution } from '../models/solution.model';
import { UserDomain } from './../models/user-domain.model';
import { BaseService } from './sbaw/base.service';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthService extends BaseService {
  constructor(private http: HttpClient, public router: Router, private jwtHelper: JwtHelperService) {
    super();
  }

  private path = '/wrapper/user-domain/login';
  private refresh = '/wrapper/user-domain/refresh';

  postUser(user: User): Observable<HttpResponse<any>> {
    return this.http.post(`${env.host.sbaw}${this.path}`, user, {
      observe: 'response',
      responseType: 'text'
    });
  }

  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getAuthToken());
  }

  public isTokenExpire(token) {
    return (token) ? this.jwtHelper.isTokenExpired(token) : true;
  }

  public getExpirationDate(): any {
    return this.jwtHelper.getTokenExpirationDate(this.getAuthToken());
  }

  public invalidToken(token) {
    if (token === void 0) {
      this.getAuthToken();
    }
    if (token === null || token === undefined) {
      return true;
    }
    var parts = token.split('.');
    if (parts.length !== 3) {
      return true;
    }
    var decoded = this.jwtHelper.urlBase64Decode(parts[1]);
    if (!decoded) {
      return true;
    }
    return false;
  }

  public getUser() {
    const token = this.getAuthToken();
    if (this.invalidToken(token) || this.isTokenExpire(token)) {
      localStorage.removeItem('token');
      this.logout();
      return undefined;
    }
    return this.jwtHelper.decodeToken(token);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  getAuthToken(): string {
    return localStorage.getItem('token') || undefined;
  }

  setAuthToken(token) {
    localStorage.setItem('token', token);
  }

  getUserRoles() {
    const token = localStorage.getItem('token');
    const userDomain: UserDomain = this.jwtHelper.decodeToken(token);
    const { roles } = userDomain;
    return roles;
  }

  getUserAuthority() {
    const token = localStorage.getItem('token');
    const userDomain: UserDomain = this.jwtHelper.decodeToken(token);
    const { authority } = userDomain;
    return authority;
  }

  getSolutions() {
    const token = localStorage.getItem('token');
    const solutions: Solution[] = this.jwtHelper.decodeToken(token);
    return solutions;
  }

  refreshToken() {
    return this.http.post(`${env.host.sbaw}${this.refresh}`, null, {
      observe: 'response',
      responseType: 'text'
    }).pipe(take(1)).subscribe(data => {
      this.setAuthToken(data.headers.get('Authorization'));
    });
  }

  checkAndRefresh() {
    if (!this.isAuthenticated()) {
      this.logout();
    } else {
      this.refreshIfNecessary();
      return this.getAuthToken();
    }
  }

  refreshIfNecessary() {
    let time = this.getExpirationDate();
    let mileseconds = time.getTime();

    let now = moment.now();
    let dif = (mileseconds - now.valueOf()) / 1000 / 60;

    if (dif < 5) {
      this.refreshToken();
    }
  }
}
