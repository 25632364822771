import {
  Component,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ModalCustomComponent } from '../../../../components/modal-custom/modal-custom.component';
import { SelectFieldComponent } from '../../../../components/select/select-field/select-field.component';
import { DockVehicleCompatibility } from '../../../../core/models/sdm/docks/dock-vehicle-compatibility';
import { ProductivityGroup } from '../../../../core/models/sdm/provider-management/productivity-group';
import { RegularDeliverySmallSize } from '../../../../core/models/sdm/regular-delivery/regular-delivery-small-size';
import { DimensioningInfoService } from '../../../../core/services/sdm/delivery-authorization/regular-delivery/dimensioning-info.service';
import { DockVehicleCompatiblityService } from '../../../../core/services/sdm/docks/dock-vehicle-compatiblity.service';
import { ProductivityGroupService } from '../../../../core/services/sdm/provider-management/productivity-group.service';
import { ProviderService } from '../../../../core/services/sdm/provider-management/provider.service';
import { OrderService } from '../../../../core/services/sdm/regular-delivery/order.service';
import { RegularDeliverySmallSizeService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-small-size.service';
import { VehicleDepositorService } from '../../../../core/services/vehicle-depositor.service';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { DeliveryAuthorization } from './../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { DimensioningInfo } from './../../../../core/models/sdm/delivery-authorization/regular-delivery/dimensioning-info';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { ImpreciseFields } from './../../../../core/models/sdm/provider-management/imprecise-fields';
import { Provider } from './../../../../core/models/sdm/provider-management/provider';
import { RegularDeliveryOrder } from './../../../../core/models/sdm/regular-delivery/order';
import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { AuthService } from './../../../../core/services/auth.service';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DeliveryAuthorizationService } from './../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { ManualTypeDocService } from './../../../../core/services/sdm/delivery-authorization/regular-delivery/manual-type-doc.service';
import { CompatibilityProviderService } from './../../../../core/services/sdm/provider-management/groups-compatibility/compatibility-provider.service';
import { ImpreciseFieldsService } from './../../../../core/services/sdm/provider-management/imprecise-fields.service';

const MESSAGE_PROVIDERS =
  'Mais de 1 Fornecedor e/ou Grupo. Na recorrência indica Fornecedor e Grupo já OK.';

const TONELADAS = 8;
const METROS_CUBICOS = 9;
const PALETES = 10;

@Component({
  selector: 'app-dimensioning-info',
  templateUrl: './dimensioning-info.component.html',
  styleUrls: ['./dimensioning-info.component.scss']
})
export class DimensioningInfoComponent implements OnInit, OnDestroy {
  dismissible = true;
  bsModalRef: BsModalRef;
  formDimensioningInfo: FormGroup;
  subscriptions: Subscription[] = [];
  bsModalRefAuthorization: BsModalRef;
  bsModalRefCancel: BsModalRef;

  providers = [];
  alerts: any = [];
  solicitations = [];
  vehiclesDepositor = [];
  productivityGroups: ProductivityGroup[] = [];
  depositors: Depositor[] = [];
  providerSelected: Provider;
  providerListWithoutDuplicated: any[] = [];

  authorizationKey: any;
  idAuthorization: number;
  depositorId: any;
  vehicleSelected: any;
  order: RegularDeliveryOrder = new RegularDeliveryOrder();
  impreciseFields: ImpreciseFields = new ImpreciseFields();
  regDeliverySmallSize: RegularDeliverySmallSize = new RegularDeliverySmallSize();
  openedByMenu = false;
  measureUnitsForCheckRequired = {};
  dockVehicleCompatibility: DockVehicleCompatibility;

  documentShipping: string = '';

  @ViewChild('selectDepositor') selectDepositor: SelectFieldComponent;
  @ViewChild('selectedProvider') selectedProvider: SelectFieldComponent;

  vehicleIsRequired = false;

  formEnabled = false;

  @ViewChild('tabsDimensioningInfo') tabsDimensioningInfo: TabsetComponent;

  constructor(
    public modalService: BsModalService,
    public toaster: ToastrService,
    public translator: TranslatorService,
    sanitizer: DomSanitizer,
    public _authService: AuthService,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    private route: ActivatedRoute,
    private _orderService: OrderService,
    private _productivityGroupService: ProductivityGroupService,
    private _vehiclesDepositorService: VehicleDepositorService,
    private _dimensioningInfoService: DimensioningInfoService,
    private _compatibilityProviderService: CompatibilityProviderService,
    private _impreciseFieldsService: ImpreciseFieldsService,
    private _regularDeliverySmallSizeService: RegularDeliverySmallSizeService,
    private _documentService: ManualTypeDocService,
    private _router: Router,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _dockVehiclesCompatibilityService: DockVehicleCompatiblityService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  onSubmit() {}

  ngOnInit() {
    this.formDimensioningInfo = new FormGroup({
      id: new FormControl(null),
      depositor: new FormControl(this.selectDepositor.value, [
        Validators.required
      ]),
      provider: new FormControl(this.selectedProvider.value, [
        Validators.required
      ]),
      providers: new FormControl(''),

      authorizationKey: new FormControl(this.authorizationKey, [
        Validators.required
      ]),
      productivityGroup: new FormControl(''),

      vehicle: new FormControl(''),
      freightCubing: new FormControl(''),
      totalWeight: new FormControl(''),
      qtdPallet: new FormControl(''),
      documentShipping: new FormControl(''),
      totalQtdItems: new FormControl(''),
      totalQtdUnit: new FormControl(''),
      totalValueNf: new FormControl(''),
      totalGrossWeight: new FormControl(''),
      totalNetWeight: new FormControl(''),
      qtdVol: new FormControl(''),
      deliveryAuthorizationId: new FormControl(this.idAuthorization),
      deliveryAuthorization: new FormControl(),
      qtdProvidersInDimensioningInfo: new FormControl('')
    });

    this.subscriptions.push(
      this.route.queryParams.subscribe((params: any) => {
        if (isEmpty(params)) {
          this.loadDepositorsWhenNotExistsQueryParams();
          this.openedByMenu = true;
          return;
        }

        this.getDepositorFromQueryParams(params);
        this.getProvidersFromQueryParams(params);
        this.getSolicitationFromQueryParams(params);

      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  private deleteDimensioningInfoSaveTemporarily() {
    this._dimensioningInfoService
      .deleteByDeliveryAuthorization(this.solicitations[0].id)
      .subscribe();
  }

  loadDepositorsWhenNotExistsQueryParams() {
    const { id } = this._authService.getUser();
    this.subscriptions.push(
      this._depositorService.findAllByUserId(id).subscribe(data => {
        if (data) {
          this.depositors = [...data.body['content']];

          if (this.depositors.length === 1) {
            this.formDimensioningInfo
              .get('depositor')
              .setValue(this.depositors[0]);
            this.depositorChanged(this.depositors[0]);
          }
        } else {
          this.depositors = [];
          this.providers = [];

          this.formDimensioningInfo.get('depositor').setValue([]);
          this.formDimensioningInfo.get('provider').setValue([]);
        }
      })
    );
    // );
  }

  loadProvidersWhenNotExistsQueryParams() {
    const { id } = this._authService.getUser();
    this.subscriptions.push(
      this._providerService.findAllByUserId(id).subscribe(data => {
        this.providers = [...data.body['content']];
      })
    );
  }

  loadSolicitationWhenNotExistsQueryParams(depositorId: number) {
    this.subscriptions.push(
      this._deliveryAuthorizationService
        .findByDepositorIdAndStatusDocsOK(depositorId)
        .subscribe(data => {
          this.solicitations = data;
        })
    );
  }

  getDepositorFromQueryParams(params: any) {
    try {
      this.depositorId = JSON.parse(params['depositor']);
      this.depositors = [this.depositorId];
      this.formDimensioningInfo.get('depositor').setValue(this.depositors[0]);
      this.depositorChanged(this.depositorId);
    } catch (e) {
      console.error(e);
    }
  }

  getProvidersFromQueryParams(params: any) {
    try {
      this.providers = JSON.parse(params['provider']);

      this.removeDuplicatedProvider();

      this.providerChanged(this.providers[0]);

      if (this.providerListWithoutDuplicated.length > 1) {
        this.alertMsg(MESSAGE_PROVIDERS, 'info');
      }
    } catch (e) {
      console.error(e);
    }
  }

  removeDuplicatedProvider() {
    const providersList = this.providers
      .map(provider => provider['id'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => this.providers[e])
      .map(e => this.providers[e]);

    this.providerListWithoutDuplicated = providersList;

    if (this.providerListWithoutDuplicated.length === 1) {
      this.formDimensioningInfo
        .get('provider')
        .setValue(this.providerListWithoutDuplicated[0]);
    }
  }

  getSolicitationFromQueryParams(params: any) {
    try {
      this.authorizationKey = JSON.parse(params['deliveryAuthorization']);

      this.solicitations = [this.authorizationKey];

      this.formDimensioningInfo
        .get('authorizationKey')
        .setValue(this.authorizationKey);
      this.idAuthorization = +this.authorizationKey['id'] || null;
      this.formDimensioningInfo
        .get('deliveryAuthorizationId')
        .setValue(this.idAuthorization);
    } catch (e) {
      console.error(e);
    } finally {
      if (this.solicitations.length) {
        this.deleteDimensioningInfoSaveTemporarily();
      }
    }
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message: string, type = 'success', timeout?: number) {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`,
      timeout
    });
  }

  errorQtdSlots(error: string) {
    this.alertMsg(error, 'danger');
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabQtdSlots':
        break;
      case 'tabPeqSemAgendamento':
        break;
      case 'tabSemOpcao':
        break;
    }
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  cancel() {
    if (!this.openedByMenu) {
      this.openModalConfirmInterrupt();
    } else {
      this._router.navigate(['sdm/home']);
    }
  }

  openModalConfirmInterrupt() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message: 'Deseja realmente Sair? Totalidade dos Dados será perdida.',
      classBtnSuccess: 'btn-outline-danger',
      classBtnDanger: 'btn-success'
    };
    this.bsModalRefCancel = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRefCancel.content.closeBtnName = 'Sair';
    this.bsModalRefCancel.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this._deliveryAuthorizationService
              .updateStatusAuthorization(
                this.idAuthorization,
                +DeliveryAuthorizationEnum.INTERROMPIDA
              )
              .subscribe(() => {
                this._router.navigate([
                  'sdm/delivery-authorization/regular-delivery'
                ]);
              });
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  setValueForFields() {
    this.formDimensioningInfo.get('depositor').setValue(this.depositorId);
    this.formDimensioningInfo.get('provider').setValue(this.providerSelected);
    // this.formDimensioningInfo
    //   .get('authorizationKey')
    //   .setValue(this.authorizationKey);
  }

  submit() {
    // if (this.formDimensioningInfo.validator) {
    const validator = this.formDimensioningInfo.get('documentShipping')
      .validator
      ? this.formDimensioningInfo
          .get('documentShipping')
          .validator({} as AbstractControl)
      : null;

    if (
      this.documentShipping != '' &&
      this.formDimensioningInfo.get('documentShipping').value !=
        this.documentShipping &&
      (validator && validator.required)
    ) {
      this.alertMsg('Transportadora precisa ser igual a primeira', 'danger');
      return;
    }
    // }

    if (this.formDimensioningInfo.valid) {
      const depositor = this.depositorId;
      const provider = this.formDimensioningInfo.get('provider').value;

      this.formDimensioningInfo.get('depositor').setValue(depositor);
      this.formDimensioningInfo.get('provider').setValue(provider);

      this.formDimensioningInfo
        .get('deliveryAuthorization')
        .setValue(this.formDimensioningInfo.get('authorizationKey').value);

      this.formDimensioningInfo.get('providers').setValue(this.providers);

      if (
        this.formDimensioningInfo.get('vehicle').value === null ||
        this.formDimensioningInfo.get('vehicle').value === ''
      ) {
        this.formDimensioningInfo.get('vehicle').setValue(null);
      } else {
        this.formDimensioningInfo.get('vehicle').value[
          'vehicleEstablishment'
        ] = [];
      }

      // qtd of providers, for know when finish save informations in DB and calculate dimensioning docks
      this.formDimensioningInfo
        .get('qtdProvidersInDimensioningInfo')
        .setValue(this.providerListWithoutDuplicated.length);

      this._dimensioningInfoService
        .create(this.formDimensioningInfo.value)
        .pipe(finalize(() => this.setValueForFields()))
        .subscribe(
          (data: DimensioningInfo) => {
            if (!data.joinInformationFinish) {
              this.alertMsg(
                'Selecione o próximo Fornecedor e Grupo de produtividade.',
                'info'
              );

              if (this.formDimensioningInfo.get('documentShipping').value) {
                this.documentShipping = this.formDimensioningInfo.get(
                  'documentShipping'
                ).value;
              }

              if (this.formDimensioningInfo.get('vehicle').value) {
                this.formDimensioningInfo.get('vehicle').disable();
              }

              return;
            }

            if (data) {
              const navigationExtras: NavigationExtras = {
                queryParams: {
                  depositor: JSON.stringify([this.depositorId]),
                  provider: JSON.stringify(this.providers),
                  vehicleType: JSON.stringify(this.vehicleSelected),
                  deliveryAuthorization: JSON.stringify(
                    this.formDimensioningInfo.get('deliveryAuthorization').value
                  ),
                  dimensioningInfo: JSON.stringify(data)
                },
                queryParamsHandling: 'merge',
                skipLocationChange: true
              };

              this._router.navigate(
                ['sdm/delivery-authorization/regular-delivery/qtd-slots'],
                navigationExtras
              );
            }
          },
          ({ error }) => {
            this.errorMessage(error);
          }
        );
    } else {
      this.verificaValidacoesForm(this.formDimensioningInfo);
    }
  }

  errorMessage(errors: any[]) {
    if (typeof errors === 'string') {
      this.alertMsg(`${errors}`, 'danger');
      return;
    }
    if (errors.length > 0) {
      errors.forEach(error => {
        this.alertMsg(`${error.field}: ${error.message}`, 'danger');
      });
    }
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  depositorChanged(data: any) {
    if (data) {
      const { id, regularDelivery } = data;
      const userId = this._authService.getUser().id;

      const getByDepositorIdAndStatusDocsOK = this._deliveryAuthorizationService.findByDepositorIdAndStatusDocsOK(
        id
      );
      const getByDepositorAndUserIdAndStatusTrue = this._vehiclesDepositorService.findByDepositorAndUserIdAndStatusTrue(
        id,
        userId
      );
      const getVehiclesCompatibility = this._dockVehiclesCompatibilityService.findByDepositorId(
        id
      );
      const getRegularDeliverySmallSize = this._regularDeliverySmallSizeService.findByDepositorId(
        id
      );

      forkJoin([
        getByDepositorIdAndStatusDocsOK,
        getByDepositorAndUserIdAndStatusTrue,
        getVehiclesCompatibility,
        getRegularDeliverySmallSize
      ]).subscribe(response => {
        this.setSolicitations(response[0]);
        this.setVehiclesDepositor(response[1]);
        this.vehicleIsRequire(regularDelivery, response[2]);
        this.setRegularDelivery(response[3]);
      });

      this.depositorId = data;
    }
  }

  setSolicitations(data: any) {
    if (this.openedByMenu) {
      this.solicitations = data;
      this.deleteDimensioningInfoSaveTemporarily();
    }
  }

  setVehiclesDepositor(data) {
    this.vehiclesDepositor = data;
  }

  vehicleChanged(vehicle) {
    if (vehicle) {
      this.vehicleSelected = vehicle;
      return
    }

    this.vehicleSelected = null;
  }

  vehicleIsRequire(regularDelivery: boolean, data: DockVehicleCompatibility[]) {
    this.dockVehicleCompatibility = data[0];

    if (regularDelivery) {
      this.vehicleIsRequired = true;
    } else {
      if (
        !this.dockVehicleCompatibility ||
        this.dockVehicleCompatibility.vehicleTypeCompatibilityAllDocks
      ) {
        this.vehicleIsRequired = false;
      } else {
        this.vehicleIsRequired = true;
      }
    }

    if (!this.vehicleIsRequired) {
      this.formDimensioningInfo.get('vehicle').disable();
    }

    this.vehicleIsRequired
      ? this.setRequiredForFields(['vehicle'])
      : this.removeRequiredForFields(['vehicle']);
  }

  setRegularDelivery(data: any) {
    this.regDeliverySmallSize = data ? data : new RegularDeliverySmallSize();
  }

  providerChanged(data: Provider) {
    this.removeRequiredForRevalidationFieldsNF();
    this.impreciseFields = new ImpreciseFields();
    this.measureUnitsForCheckRequired = {};
    this.productivityGroups = [];
    this.formDimensioningInfo.get('productivityGroup').setValue([]);

    if (data) {
      this.providerSelected = data;

      const { document } = data;
      const userId = this._authService.getUser().id;
      this.loadProductivityGroupsProvider(document, userId);
      this.loadImpreciseFieldsNFProvider(document, userId);
      return;
    }
  }

  changedAuthorization(data: DeliveryAuthorization) {
    this.removeRequiredForRevalidationFieldsNF();
    if (
      this.openedByMenu &&
      data &&
      this.formDimensioningInfo.get('depositor').value
    ) {
      this._dimensioningInfoService
        .deleteByDeliveryAuthorization(data.id)
        .subscribe();

      this._documentService
        .findByDepositorIdAndDeliveryAuthorization(
          this.formDimensioningInfo.get('depositor').value['id'],
          data.id
        )
        .pipe(
          finalize(() => {
            this.removeDuplicatedProvider();

            if (this.providerListWithoutDuplicated.length === 1) {
              this.formDimensioningInfo
                .get('provider')
                .setValue(this.providerListWithoutDuplicated[0]);
              this.providerChanged(this.providerListWithoutDuplicated[0]);
            }
          })
        )
        .subscribe(data => {
          this.providers = data.map(document => document['provider']);
        });
    } else {
      this.providers = [];
      this.providerListWithoutDuplicated = [];
      this.productivityGroups = [];
      this.impreciseFields = new ImpreciseFields();
      this.measureUnitsForCheckRequired = {};

      this.formDimensioningInfo.get('provider').setValue([]);
      this.formDimensioningInfo.get('productivityGroup').setValue([]);
    }

    if (data) {
      this.idAuthorization = data.id;
      this.formDimensioningInfo
        .get('deliveryAuthorizationId')
        .setValue(this.idAuthorization);
    }
  }

  removeRequiredForRevalidationFieldsNF() {
    this.removeRequiredForFields([
      'documentShipping',
      'qtdVol',
      'totalQtdItems',
      'totalQtdUnit',
      'totalGrossWeight',
      'totalNetWeight'
    ]);
  }

  loadProductivityGroupsProvider(documentProvider: string, userId: number) {
    this._compatibilityProviderService
      .findByDocumentAndUserIdStatusTrue(documentProvider, userId)
      .pipe(
        tap(),
        map(prodGroups => prodGroups['content'][0].providerGroups),
        tap((prodGroups: any[]) => prodGroups.map(p => p['productivityGroup'])),
        map(prodGroups => prodGroups.filter((p: any) => p.status)),
        map(prodGroups => prodGroups.map((p: any) => p.productivityGroup)),
        catchError(this.handleError)
      )
      .subscribe((response: ProductivityGroup[]) => {
        if (!response) {
          this.alertMsg(
            'Fornecedor sem Grupo Produtividade vinculado. Não é possível prosseguir. Favor solicitar ajuda',
            'warning'
          );
          return;
        }
        this.productivityGroups = response;

        if (response.length === 1)
          this.formDimensioningInfo
            .get('productivityGroup')
            .setValue(response[0]);
      });
  }

  handleError(error: any) {
    return Observable.of(null);
  }

  setRequiredForFields(fields: any[], form = this.formDimensioningInfo) {
    for (const field of fields) {
      form.get(field).setValidators([Validators.required]);
      form.get(field).updateValueAndValidity();
      form.get(field).markAsUntouched();
      form.get(field).enable();
    }
  }

  removeRequiredForFields(fields: string[], form = this.formDimensioningInfo) {
    for (const field of fields) {
      form.get(field).clearValidators();
      form.get(field).updateValueAndValidity();
      form.get(field).setValue('');
      form.get(field).markAsPristine();
      form.get(field).disable();
    }
  }

  loadImpreciseFieldsNFProvider(document: string, userId: number) {
    this._impreciseFieldsService
      .findByDocumentAndUserIdStatusTrue(document, userId)
      .subscribe(response => {
        this.impreciseFields = response ? response : new ImpreciseFields();

        this.impreciseFields.shippingDocument
          ? this.setRequiredForFields(['documentShipping'])
          : this.removeRequiredForFields(['documentShipping']);
        this.impreciseFields.qtdVolume
          ? this.setRequiredForFields(['qtdVol'])
          : this.removeRequiredForFields(['qtdVol']);
        this.impreciseFields.qtdLinesNF
          ? this.setRequiredForFields(['totalQtdItems'])
          : this.removeRequiredForFields(['totalQtdItems']);
        this.impreciseFields.qtdOrPiecesUnit
          ? this.setRequiredForFields(['totalQtdUnit'])
          : this.removeRequiredForFields(['totalQtdUnit']);
        this.impreciseFields.totalGrossWeight
          ? this.setRequiredForFields(['totalGrossWeightt'])
          : this.removeRequiredForFields(['totalGrossWeight']);
        this.impreciseFields.totalLiquidWeight
          ? this.setRequiredForFields(['totalNetWeight'])
          : this.removeRequiredForFields(['totalNetWeight']);

        // if (this.formDimensioningInfo.validator) {
        const validator = this.formDimensioningInfo.get('documentShipping')
          .validator
          ? this.formDimensioningInfo
              .get('documentShipping')
              .validator({} as AbstractControl)
          : null;

        if (validator && validator.required && this.documentShipping != '') {
          this.formDimensioningInfo
            .get('documentShipping')
            .setValue(this.documentShipping);
          // }
        }
      });
  }

  loadSolicitationsProvider(providerId: number) {
    this._documentService.findByProviderId(providerId).subscribe((res: any) => {
      this.solicitations = res;
    });
  }

  loadVehiclesDepositor(depositorId: number, userId: number) {
    this.subscriptions.push(
      this._vehiclesDepositorService
        .findByDepositorAndUserIdAndStatusTrue(depositorId, userId)
        .subscribe((response: any) => {
          if (response) {
            setTimeout(() => (this.vehiclesDepositor = response), 1);
          }
        })
    );
  }

  loadProductivityGroupsDepositor(depositorId: number, userId: number) {
    this._productivityGroupService
      .findByDepositorAndUserIdAndStatusTrue(depositorId, userId)
      .pipe(
        map(prodGroups => [...prodGroups.body['content']]),
        catchError(this.handleError)
      )
      .subscribe((prodGroups: any) => {
        this.productivityGroups = prodGroups;
      });
  }

  productivityChanged(group: ProductivityGroup) {
    if (group) {
      this._productivityGroupService
        .findById(group.id)
        .subscribe((data: object) => {
          const fieldsToCompare = [
            'firstPMeasureUnit',
            'secondPMeasureUnit',
            'thirdPMeasureUnit',
            'fourthPMeasureUnit',
            'fifthPMeasureUnit',
            'sixPMeasureUnit'
          ];

          const objMap = fieldsToCompare.map(field => {
            let obj = {};
            obj[field] = data.hasOwnProperty(field) ? data[field] : null;
            return obj;
          });

          this.measureUnitsForCheckRequired = Object.assign({}, ...objMap);
        });
    } else {
      this.measureUnitsForCheckRequired = {};
    }
  }

  freightCubingIsRequired(): boolean {
    if (
      Object.values(this.measureUnitsForCheckRequired).indexOf(
        METROS_CUBICOS
      ) >= 0
    ) {
      this.setRequiredForFields(['freightCubing']);
      this.enableFields(['freightCubing']);
      return true;
    }

    this.removeRequiredForFields(['freightCubing']);
    this.disableFields(['freightCubing']);
    return false;
  }

  totalWeightIsRequired(): boolean {
    if (
      Object.values(this.measureUnitsForCheckRequired).indexOf(TONELADAS) >= 0
    ) {
      this.setRequiredForFields(['totalWeight']);
      this.enableFields(['totalWeight']);
      return true;
    }

    this.removeRequiredForFields(['totalWeight']);
    this.disableFields(['totalWeight']);
    return false;
  }

  qtdPalletIsRequired() {
    if (
      Object.values(this.measureUnitsForCheckRequired).indexOf(PALETES) >= 0
    ) {
      this.setRequiredForFields(['qtdPallet']);
      this.enableFields(['qtdPallet']);
      return true;
    }

    this.removeRequiredForFields(['qtdPallet']);
    this.disableFields(['qtdPallet']);
    return false;
  }

  enableFields(fields: any[], form = this.formDimensioningInfo) {
    for (const field of fields) {
      form.get(field).enable();
    }
  }

  disableFields(
    fields: any[],
    form = this.formDimensioningInfo,
    clearValue = true
  ) {
    for (const field of fields) {
      form.get(field).disable();

      if (clearValue) form.get(field).setValue('');
    }
  }
}

@Component({
  selector: 'app-modal-dimensioning-info-component',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ title }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="confirmCancel()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span style="padding-left: 5px">{{ message }}</span>
    </div>
    <div *ngIf="messageQtdSlots">
      <span style="padding-left: 5px">{{ messageQtdSlots }}</span>
    </div>
    <div *ngIf="messageSmallSize">
      <span style="padding-left: 5px">{{ messageSmallSize }}</span>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-success"
        (click)="confirmCancel()"
      >
        {{ closeBtnName }}</button
      >&nbsp;
      <button type="button" class="btn btn-warning" (click)="confirmOK()">
        {{ confirmBtn }}
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="confirmOK2()"
        *ngIf="deliverySchedule"
      >
        {{ confirmBtnSchedule }}
      </button>
    </div>
  `
})
export class ModalDimensioningInfoComponent implements OnInit {
  message = '';
  title: string;
  form: FormGroup;
  confirmBtn: string;
  closeBtnName: string;
  deliverySchedule = false;
  confirmBtnSchedule: string;
  messageQtdSlots = '';
  messageSmallSize = '';

  constructor(
    public bsModalRef: BsModalRef,
    public modalService: BsModalService
  ) {}

  ngOnInit() {}

  confirmCancel() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('CANCEL');
  }

  confirmOK() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('OK');
  }

  confirmOK2() {
    this.bsModalRef.hide();
    this.modalService.setDismissReason('OK2');
  }
}
