import { ReverseDeliveryNF } from './../../../models/sdm/reverse-delivery/reverse-delivery-nf';
import { Observable } from 'rxjs';
import { SDM_URL } from '../../../../app.api';
import { environment as env } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ReverseDeliveryNfService extends AbstractService<
  ReverseDeliveryNF
> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.reverseDelivery.nfDeadlines.default);
  }

  createReverseDeliveryNFDeadline(
    model: ReverseDeliveryNF[]
  ): Observable<ReverseDeliveryNF> {
    return this.http.post<ReverseDeliveryNF>(
      SDM_URL.reverseDelivery.nfDeadlines.create,
      model
    );
  }

  findByEstablishmentIdAndDepositorIdAndStatusTrue(
    establishmentId: number,
    depositorId: number
  ): Observable<ReverseDeliveryNF> {
    return this.http.get<ReverseDeliveryNF>(
      `${
        this.host
      }/wrapper/reverse-delivery/nf/establishment/${establishmentId}/depositor/${depositorId}`
    );
  }
}
