import { EstablishmentService } from './../../../../core/services/establishment.service';
import { AuthService } from './../../../../core/services/auth.service';
import { PriorVisualization } from './../../../../core/models/sdm/docks/prior-visualization';
import { SDM_URL } from './../../../../app.api';
import { BooleanConverter } from './../../../../core/classes/boolean-type';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DocksOptionsService } from './../../../../core/services/sdm/docks/docks-options.service';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RegularOperationgDays } from '../../../../core/models/sdm/docks/regular-operationg-days';
import { DocksOptions } from '../../../../core/models/sdm/docks/dock-options';
import { AbstractParametrizations } from '../../abstract-parametrizations-crud';
import { AbstractService } from '../../../../core/services/abstract.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { Location } from '@angular/common';
import { SelectFieldComponent } from '../../../../components/select/select-field/select-field.component';

@Component({
  selector: 'app-dock-options',
  templateUrl: './dock-options.component.html',
  styleUrls: ['./dock-options.component.scss']
})
export class DockOptionsComponent extends AbstractParametrizations<DocksOptions>
  implements OnInit {
  regularOperatingDays: RegularOperationgDays;
  @ViewChild('grdDocksOptions') grid: DatatableApiComponent;

  @ViewChild('establishment') establishment: SelectFieldComponent;

  docksOptions: DocksOptions;
  establishments = [];

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  columns = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
    {
      prop: 'qtdMaxOptionsVip',
      name: 'Quantidades máxima fornecimento prioritário'
    },
    {
      prop: 'qtdMaxOptionsNVip',
      name: 'Quantidades máxima fornecimento não prioritário'
    },
    {
      prop: 'allocationByHour',
      name: 'Alocação por Horário',
      pipe: new BooleanConverter()
    }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public docksOptionsService: DocksOptionsService,
    public authService: AuthService,
    public establishmentService: EstablishmentService
  ) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.docksOptions = new DocksOptions();

    this.regularOperatingDays = new RegularOperationgDays();
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl(''),
      depositorId: new FormControl('', [Validators.required]),
      qtdMaxOptionsVip: new FormControl(this.docksOptions.qtdMaxOptionsVip),
      qtdMaxOptionsNVip: new FormControl(this.docksOptions.qtdMaxOptionsNVip),
      allocationByHour: new FormControl(this.docksOptions.allocationByHour),
      showDocks: new FormControl(this.docksOptions.showDocks),
      createdBy: new FormControl('')
    });

    const { id } = this.authService.getUser();

    this.establishmentService
      .findByUserId(id)
      .subscribe(data => (this.establishments = data));

    this.formulario.get('depositor').valueChanges.subscribe(value => {
      if (value) {
        this.formulario.get('depositorId').setValue(value.id);
      } else {
        this.formulario.get('depositorId').setValue('');
      }
    });
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.docksOptions);

    this.establishment.items = this.establishments;
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }

    this.depositorService
      .findByEstablishmentId(establishment['id'])
      .subscribe(data => {
        this.formulario.get('depositorId').setValue([]);
        this.depositors = [...data.body['content']];
        if (this.depositors.length === 1) {
          this.formulario.get('depositorId').setValue(this.depositors[0]);
        }
      });
  }

  makeEndPointForDocksOptions = () => SDM_URL.docks.docksOptions.default;

  getNewInstance(): DocksOptions {
    return new DocksOptions();
  }
  getService(): AbstractService<DocksOptions> {
    return this.docksOptionsService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'docks.docks-options';
  }
}
