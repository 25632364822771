import { Location } from '@angular/common';
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { delay } from 'rxjs/operators';
import { AbstractService } from '../../../core/services/abstract.service';
import { ProviderService } from '../../../core/services/sdm/provider-management/provider.service';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { DatatableApiComponent } from './../../../components/datatable-api/datatable-api.component';
import { AuthService } from './../../../core/services/auth.service';
import { DepositorService } from './../../../core/services/depositor.service';
import { TranslatorService } from './../../../core/translator/translator.service';
import { saveAs as importedSaveAs } from 'file-saver';

export abstract class AbstractSchedules<T> extends BaseFormComponent {
  depositors = [];
  providers = [];

  alerts: any = [];
  dismissible = true;

  fileName = 'arquivo';

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public sanitizer: DomSanitizer,
    public _depositorService: DepositorService,
    public _providerService: ProviderService,
    public _authService: AuthService
  ) {
    super(location, toaster, translator);

    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));

    const { id } = this._authService.getUser();

    // this._depositorService.findAllByUserId(id).pipe(delay(500)).subscribe(data => {
    //   this.depositors = [...data.body['content']];
    // });

    // this._providerService.findAllByUserId(id).pipe(delay(500)).subscribe(data => {
    //   this.providers = [...data.body['content']];
    // });
  }

  // submit() {
  //   if (this.formulario.valid) {
  //     if (this.formulario.get('id').value == null) {
  //       this.getService()
  //         .create(this.formulario.value)
  //         .subscribe(data => {
  //           this.formulario.patchValue(data);
  //           this.disabledForm();
  //           this.getGrid().reloadPage();
  //           this.showMessageActions();
  //         });
  //     } else {
  //       this.getService()
  //         .edit(this.formulario.value)
  //         .subscribe(() => {
  //           this.disabledForm();
  //           this.getGrid().reloadPage();
  //           this.showMessageActions('success', 'update');
  //         });
  //     }
  //   } else {
  //     this.verificaValidacoesForm(this.formulario);
  //   }
  // }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  loadDepositorAndProvider() {
    const { id } = this._authService.getUser();

    this._depositorService
      .findAllByUserId(id)
      .pipe(delay(500))
      .subscribe(data => {
        this.depositors = [...data.body['content']];
      });

    this._providerService
      .findAllByUserId(id)
      .pipe(delay(500))
      .subscribe(data => {
        this.providers = [...data.body['content']];
      });
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    // this.loadDepositorAndProvider();
    this.getNewInstance();
  }

  alertMsg(message?: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
      // timeout: 2500,
    });
  }

  export() {
    this.getService()
      .export()
      .subscribe(data => {
        importedSaveAs(data, this.getFileName());
      });
  }

  getFileName(): string {
    this.translator.translate
      .get(`pages.${this.getComponentName()}.excelFileName`)
      .subscribe(fileName =>
        fileName ? (this.fileName = fileName) : this.fileName
      );
    return `${this.fileName}.xlsx`;
  }

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;
}
