import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment as env } from "../../../../environments/environment";
import { SlotRound } from "../../models/sdm/slot/slot-round";
import { AbstractService } from "../abstract.service";

@Injectable()
export class SlotRoundService extends AbstractService<SlotRound> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/slot-round');
  }

  // create(model: SlotRound): Observable<SlotRound> {
  //   return this.http.post<SlotRound>(`${env.host.sdm}${this.URL}`, model)
  // }

  // edit(model: SlotRound): Observable<HttpResponse<SlotRound>> {
  //   return this.http.put<SlotRound>(`${env.host.sdm}${this.URL}/${model.id}`, model, { observe: 'response' })
  // }

  // findById(id: number): Observable<SlotRound> {
  //   throw new Error("Method not implemented.");
  // }

  // findAll(): Observable<HttpResponse<SlotRound>> {
  //   throw new Error("Method not implemented.");
  // }

  // activeDeactive(id: number): Observable<SlotRound> {
  //   throw new Error("Method not implemented.");
  // }


}