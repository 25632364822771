import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../app.api';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { environment } from '../../../../../environments/environment';
import { Authorization } from '../../../models/sdm/delivery-authorization/authorization';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends AbstractService<Authorization> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.deliveryAuthorization.confirmAuthorization.default
    );
  }

  updateStatusAuthorization(
    id: number,
    status: number
  ): Observable<Authorization> {
    return this.http.put<Authorization>(
      `${this.host}${this.url}/${id}/status/${status}`,
      {}
    );
  }
}
