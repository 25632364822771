import { ErrorHandler, Injectable, NgZone, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {

  constructor(private zone: NgZone, private toaster: ToastrService) {
    super();
  }

  handleError(error: HttpErrorResponse | any) {
    if (error instanceof HttpErrorResponse) {
      const message = error.error.message || error.error;
      this.zone.run(() => {
        switch (error.status) {
          case 400:
            this.toaster.error('Bad Request');
            break;
          case 401:
            this.toaster.error(message || 'Não autorizado');
            break;
          case 403:
            this.toaster.error(message || 'Acesso negado');
            break;
          case 404:
            this.toaster.error(message || 'Recurso não encontrado');
            break;
          case 409:
            this.toaster.error(message);
            break;
          case 500:
            this.toaster.error(message || 'Erro interno do servidor');
            break;
        }
      });
    }
    super.handleError(error);
  }

}
