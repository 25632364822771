import { AbstractStatusModel } from '../../abstractStatus.model';

export class PriorityProviders extends AbstractStatusModel {

  public establishmentId: number;
  public depositorId: number;
  public document: string;
  public priority: boolean;
  public temporaryPriority: boolean;
  public dateTo: string;
  public dateUntil: string;

  constructor() {
    super();
  }
}
