import { AbstractStatusModel } from '../../abstractStatus.model';
import { CharacterType } from '../shared/enums/CharacterType';
import { IDeserializable } from '../../deserializable.model';

export class RegularDeliveryOrder extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public orderNumber: boolean;
  public size: number;
  public orderType: CharacterType;
  public allowRepeat: boolean;
  public numberAndNFSerie: boolean;
  public emissionDate: boolean;
  public documentSender: boolean;
  public documentRecipient: boolean;
  public documentShipping: boolean;
  public qtdLines: boolean;
  public qtdUnitOrParts: boolean;
  public qtdVolume: boolean;
  public totalValue: boolean;
  public totalGrossWeight: boolean;
  public totalNetWeight: boolean;
  public qtdVol: number;

  constructor() {
    super();
    this.orderNumber = false;
    this.allowRepeat = false;
    this.numberAndNFSerie = true;
    this.emissionDate = true;
    this.documentSender = true;
    this.documentRecipient = true;
    this.documentShipping = true;
    this.qtdLines = true;
    this.qtdUnitOrParts = true;
    this.qtdVolume = true;
    this.totalValue = true;
    this.totalGrossWeight = true;
    this.totalNetWeight = false;
    this.status = true;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
