import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CollectScheduleCancel } from '../../../../../core/models/sdm/schedules/collect-schedule-cancel';

@Injectable({
  providedIn: 'root'
})
export class CollectScheduleResolverGuard implements Resolve<any> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    
    if (route.queryParams && route.queryParams['collectSchedule']) {
      return JSON.parse(route.queryParams['collectSchedule']);
    }

    return of(new CollectScheduleCancel());
  }
}
