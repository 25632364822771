import { AbstractStatusModel } from '../../abstractStatus.model';
import { IDeserializable } from '../../deserializable.model';

export class ReverseDelivery extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public shippingRequest: boolean;
  public providerRequest: boolean;
  public internalUserRequest: boolean;
  public directAllocation: boolean;
  public infoDataOnNF: boolean;
  public linkNfOrQtd: boolean;
  public maxValueForEnableVehicle: number;
  public containsNfOfOthersDepositors: boolean;
  public fixedDoca: boolean;
  public docaNumber: number;
  public dimensionProductivity: number;
  public minAntecedenceForShippingToCome: number;
  public maxAntecedenceForShippingToCome: number;

  constructor() {
    super();
    this.status = true;
    this.containsNfOfOthersDepositors = false;
    this.providerRequest = false;
    this.internalUserRequest = true;
    this.dimensionProductivity = 1;
    this.internalUserRequest = true;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
