import {
  AfterViewChecked,
  Component,
  OnInit,
  SecurityContext,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { delay, take } from 'rxjs/operators';
import { DateTimeFormatPipe } from '../../../core/classes/dateTimePipe';
import { DeliveryAuthorizationService } from '../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { ProviderService } from '../../../core/services/sdm/provider-management/provider.service';
import { SDM_URL } from './../../../app.api';
import { DatatableApiComponent } from './../../../components/datatable-api/datatable-api.component';
import { DateFormatPipe } from './../../../core/classes/datePipe';
import { StatusSolicitationPipe } from './../../../core/classes/status-solicitation.pipe';
import { QueryDelivery } from './../../../core/models/sdm/delivery-authorization/query-delivery';
import { Depositor } from './../../../core/models/sdm/depositor';
import { Provider } from './../../../core/models/sdm/provider-management/provider';
import { AuthService } from './../../../core/services/auth.service';
import { DepositorService } from './../../../core/services/depositor.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-query-delivery',
  templateUrl: './query-delivery.component.html',
  styleUrls: ['./query-delivery.component.scss'],
  preserveWhitespaces: true
})
export class QueryDeliveryComponent implements OnInit, AfterViewChecked {
  providers: Provider[] = [];
  depositors: Depositor[] = [];
  formDeliverySearch: FormGroup;
  rowsDetails = [];
  modalRefDetail: BsModalRef;
  solicitSelected: any;
  depositorSelected: Depositor[];
  providerSelected: Provider[];
  formEnabled = false;

  isAutoLoadGrid = true;
  countLoad = 0;

  alerts: any = [];
  dismissible = true;
  status = [];

  solicitations = [];

  queryDelivery: QueryDelivery;

  @ViewChild('grdQuerySolicit') grdQuerySolicit: DatatableApiComponent;
  @ViewChild('gridDetails') gridDetails: TemplateRef<any>;

  columns: object[] = [
    // { prop: 'provider', name: 'Fornecedor' },
    { prop: 'authorizationkey', name: '# Solicitação', width: 150 },
    // { prop: 'documents.id', name: 'Fornecedor' },
    { prop: 'createdAt', name: 'Data', pipe: new DateTimeFormatPipe('en-US') },
    {
      prop: 'validityDate',
      name: 'Validade',
      pipe: new DateFormatPipe('en-US')
    },
    {
      prop: 'statusAuthorization',
      name: 'Status',
      pipe: new StatusSolicitationPipe()
    },
    {
      prop: 'updatedAt',
      name: 'Data Ult. Ação',
      pipe: new DateTimeFormatPipe('en-US')
    }
  ];

  columnsDetails: object[] = [
    // { prop: 'authorizationkey', name: '# Solicitação', width: 100 },
    { prop: 'infNFeId', name: 'Chave Nota', width: 360 },
    { prop: 'provider.document', name: 'Fornecedor', width: 180 },
    {
      prop: 'emissionDate',
      name: 'Data Emissão',
      pipe: new DateFormatPipe('en-US')
    },
    { prop: 'documentSender', name: 'Remetente', width: 180 },
    { prop: 'documentRecipient', name: 'Destinatário', width: 180 },
    { prop: 'documentShipping', name: 'Transportadora', width: 180 }
    // { prop: 'createdAt', name: 'Data', pipe: new DateTimeFormatPipe('en-US') },
    // { prop: 'createdAt', name: 'Validade', pipe: new DateTimeFormatPipe('en-US') },
    // { prop: 'statusAuthorization', name: 'Status' },
    // { prop: 'updatedAt', name: 'Data Ult. Alteração', pipe: new DateTimeFormatPipe('en-US') },
  ];

  constructor(
    private _depositorService: DepositorService,
    private _providerService: ProviderService,
    private _authService: AuthService,
    public modalService: BsModalService,
    sanitizer: DomSanitizer,
    private _router: Router,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private route: ActivatedRoute
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.queryDelivery = new QueryDelivery();

    this.formDeliverySearch = new FormGroup({
      depositorId: new FormControl(this.queryDelivery.depositorId, [
        Validators.required
      ]),
      providerId: new FormControl(this.queryDelivery.providerId),
      deliveryAuthorizationId: new FormControl(
        this.queryDelivery.deliveryAuthorizationId
      ),
      docOk: new FormControl(this.queryDelivery.docOk),
      interrupt: new FormControl(this.queryDelivery.interrupt),
      pending: new FormControl(this.queryDelivery.pending),
      approved: new FormControl(this.queryDelivery.approved),
      reschedule: new FormControl(this.queryDelivery.reschedule),
      manualAllocation: new FormControl(this.queryDelivery.manualAllocation),
      approvedWithoutSchedule: new FormControl(
        this.queryDelivery.approvedWithoutSchedule
      ),
      expired: new FormControl(this.queryDelivery.expired),
      canceled: new FormControl(this.queryDelivery.canceled),
      noShow: new FormControl(this.queryDelivery.noShow),
      accomplished: new FormControl(this.queryDelivery.accomplished),
      initDate: new FormControl(this.queryDelivery.initDate),
      finDate: new FormControl(this.queryDelivery.finDate),
      authorizationKey: new FormControl('')
    });

    const { id } = this._authService.getUser();

    const observeDepositor = this._depositorService
      .findAllByUserId(id)
      .pipe(
        delay(500),
        take(1)
      )
      .subscribe(data => {
        
        this.depositors = [...data.body['content']];
        observeDepositor.unsubscribe();
      });

    const observeProvider = this._providerService
      .findAllByUserId(id)
      .pipe(
        delay(500),
        take(1)
      )
      .subscribe(data => {
        this.providers = [...data.body['content']];
        observeProvider.unsubscribe();
      });

    this.loadSolicitations();

    this.route.queryParams.subscribe((params: any) => {
      
      if (!isEmpty(params)) {
        // if (params.hasOwnProperty('depositor')) {
        //   this.formDeliverySearch
        //     .get('depositorId')
        //     .setValue(JSON.parse(params.depositor.id));
        //   this.filterSchedules();
        // }

        if (params.hasOwnProperty('message')) {
          this.alertMsg(params.message, params.type);
        }
      }
    });
  }

  selectedDepositorId(depositor: Depositor) {
    if (depositor) {
      this.loadProvidersFromDepositor(depositor);
    } else {
      this.providers = [];
      this.formDeliverySearch.get('providerId').setValue([]);
    }
  }

  loadProvidersFromDepositor(depositor: Depositor) {
    this._providerService.findByDepositorId(depositor.id).subscribe(data => {
      this.providers = data;

      if (data && data.length === 1) {
        this.formDeliverySearch.get('providerId').setValue(data[0]);
      }
    });
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  filterSchedules() {
    if (this.formDeliverySearch.valid) {
      this.grdQuerySolicit.page.pageNumber = 0;
      this.grdQuerySolicit.reloadPage();
    } else {
      this.verificaValidacoesForm(this.formDeliverySearch);
    }
  }

  verificaValidacoesForm(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      controle.markAsDirty();
      controle.markAsTouched();
      if (controle instanceof FormGroup || controle instanceof FormArray) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  clearFilters() {
    this.formDeliverySearch.patchValue(new QueryDelivery());
    setTimeout(() => this.grdQuerySolicit.reloadPage(), 1);
  }

  endPointSolicits() {
    const uri = `${SDM_URL.deliveryAuthorization.queryDelivery}`;
    return this.queryFilters(uri);
  }

  queryFilters(queryConcat: string) {
    const dateIni = this.convertDate('initDate');
    const dateFin = this.convertDate('finDate');

    const url = [
      { name: 'dateIni', value: dateIni },
      { name: 'dateFin', value: dateFin },
      {
        name: 'depositorId',
        value: this.formDeliverySearch.get('depositorId').value || ''
      },
      {
        name: 'providerId',
        value: this.formDeliverySearch.get('providerId').value || ''
      },
      {
        name: 'authorizationkey',
        value: this.formDeliverySearch.get('authorizationKey').value || ''
      },
      {
        name: 'noShow',
        value: this.formDeliverySearch.get('noShow').value || ''
      },
      { name: 'status', value: this.listOfStatus().join(',') },
      // { name: 'statusHistoric', value: this.listOfStatusHistoric().join() },
      { name: 'opNatureDelivery', value: [1] }
    ];

    let queryString = '';
    const query = [];

    url.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    return `${queryConcat}?${queryString}&`;
  }

  // dateIni=${dateIni}&dateFin=${dateFin}&scheduleNumber=${scheduleNumber}&depositorId=${depositor}&providerId=${provider}

  listOfStatus(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'docOk', value: 1 },
      { fieldName: 'interrupt', value: 2 },
      { fieldName: 'pending', value: 3 },
      { fieldName: 'approved', value: 4 },
      { fieldName: 'manualAllocation', value: 5 },
      { fieldName: 'approvedWithoutSchedule', value: 6 },
      { fieldName: 'expired', value: 7 },
      { fieldName: 'canceled', value: 8 },
      { fieldName: 'reschedule', value: 9 },
      { fieldName: 'accomplished', value: 10 },
      { fieldName: 'noShow', value: 11 }
    ];
    for (const field of fieldStatus) {
      if (this.formDeliverySearch.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  listOfStatusHistoric(): number[] {
    const arrStatus = [];
    const fieldStatus = [
      { fieldName: 'accomplished', value: 10 },
      { fieldName: 'noShow', value: 11 }
    ];
    for (const field of fieldStatus) {
      if (this.formDeliverySearch.get(field.fieldName).value) {
        arrStatus.push(field.value);
      }
    }
    return arrStatus;
  }

  convertDate(field: string) {
    if (this.formDeliverySearch.get(field).value) {
      let date = new Date(
        this.formDeliverySearch.get(field).value
      ).toLocaleDateString();
      date = date
        .split('/')
        .reverse()
        .join('-');
      return date;
    }
    return '';
  }

  selectedSolicit(row) {
    if (row) {
      
      this.openModalDetails(this.gridDetails, row[0]['documents']);
    }
  }

  export() {
    if (!this.formDeliverySearch.get('depositorId').value) {
      this.alertMsg('Preencha os filtros Obrigatórios', 'warning');
      return;
    }

    const dateIni = this.convertDate('initDate');
    const dateFin = this.convertDate('finDate');

    const url = [
      { name: 'dateIni', value: dateIni },
      { name: 'dateFin', value: dateFin },
      {
        name: 'depositorId',
        value: this.formDeliverySearch.get('depositorId').value || ''
      },
      {
        name: 'providerId',
        value: this.formDeliverySearch.get('providerId').value || ''
      },
      {
        name: 'authorizationkey',
        value: this.formDeliverySearch.get('authorizationKey').value || ''
      },
      {
        name: 'noShow',
        value: this.formDeliverySearch.get('noShow').value || ''
      },
      { name: 'status', value: this.listOfStatus().join() },
      { name: 'statusHistoric', value: this.listOfStatusHistoric().join() }
    ];

    let queryString = '';
    const query = [];

    url.forEach(obj => {
      query.push(
        encodeURIComponent(obj.name) + '=' + encodeURIComponent(obj.value)
      );
    });

    queryString = query.join('&');

    this._deliveryAuthorizationService
      .findByFiltersAndExportFile(queryString)
      .subscribe(data => {
        importedSaveAs(data, 'solicitacoes.xlsx');
      });
  }

  openModalDetails(template: TemplateRef<any>, row) {
    this.rowsDetails = row;

    this.modalRefDetail = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    );
  }

  selectedRow(row) {
    
    this.providerSelected = [];
    this.depositorSelected = [];
    this.solicitSelected = row;
    this.selectedDepositor();
    this.selectedProvider();
  }

  selectedDepositor() {
    if (this.solicitSelected) {
      this.solicitSelected['documents'].forEach(obj => {
        this.depositorSelected.push(obj['depositor']);
      });
    }
  }

  selectedProvider() {
    if (this.solicitSelected) {
      this.solicitSelected['documents'].forEach(obj => {
        this.providerSelected.push(obj['provider']);
      });
    }
  }

  reschedule() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.solicitSelected)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    this.navigation('reschedule-approved-selection', navigationExtras);
  }

  cancelDeliveryAuthorization() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.solicitSelected)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    this.navigation('cancel-delivery-authorization', navigationExtras);
  }

  navigation(route: string, navigationExtras?: NavigationExtras) {
    this._router.navigate(
      [`sdm/delivery-authorization/edit/${route}`],
      navigationExtras
    );
  }

  continue() {
    if (!this.solicitSelected) {
      this.alertMsg('Selecione a Solicitação', 'info');
      return;
    }

        const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositorSelected),
        provider: JSON.stringify(this.providerSelected),
        deliveryAuthorization: JSON.stringify(this.solicitSelected)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    this._router.navigate(
      ['sdm/delivery-authorization/edit/continue-reschedule'],
      navigationExtras
    );
  }

  loadSolicitations() {
    this._deliveryAuthorizationService
      .findByAuthorizationKeyIsNotNull()
      .pipe(delay(500))
      .subscribe((data: any) => {
        
        this.solicitations = [...data['content']];
      });
  }

  gridAutoLoad() {
    if (this.isAutoLoadGrid && this.countLoad === 0) {
      this.countLoad++;
      return true;
    }
    return false;
  }

  ngAfterViewChecked() {
    this.gridAutoLoad();
  }

  allowContinue(): boolean {
    const userType = this._authService.getUserAuthority();

    if (/Internal/i.test(userType.type)) {
      return (
        this.solicitSelected != null &&
        /DOC_OK|INTERROMPIDA/.test(this.solicitSelected['statusAuthorization'])
      );
    }

    return (
      this.solicitSelected != null &&
      /DOC_OK|INTERROMPIDA/.test(this.solicitSelected['statusAuthorization'])
    );
  }

  allowCancel() {
    const userType = this._authService.getUserAuthority();

    if (/Internal/i.test(userType.type)) {
      return (
        this.solicitSelected != null &&
        /DOC_OK|INTERROMPIDA|PENDENTE|APROVADO|SEM_AGENDAMENTO|ALOCACAO_MANUAL|REAGENDADO/.test(
          this.solicitSelected['statusAuthorization']
        )
      );
    }

    return (
      this.solicitSelected != null &&
      /DOC_OK|INTERROMPIDA|PENDENTE|APROVADO|SEM_AGENDAMENTO|ALOCACAO_MANUAL|REAGENDADO/.test(
        this.solicitSelected['statusAuthorization']
      )
    );
  }

  allowReschedule() {
    const userType = this._authService.getUserAuthority();

    if (/Internal/i.test(userType.type)) {
      return (
        this.solicitSelected != null &&
        /PENDENTE|APROVADO|SEM_AGENDAMENTO|ALOCACAO_MANUAL|REAGENDADO/.test(
          this.solicitSelected['statusAuthorization']
        )
      );
    }

    return (
      this.solicitSelected != null &&
      /PENDENTE|APROVADO|SEM_AGENDAMENTO|ALOCACAO_MANUAL|REAGENDADO/.test(
        this.solicitSelected['statusAuthorization']
      )
    );
  }

  alertMsg(message?: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
      // timeout: 2500,
    });
  }
}
