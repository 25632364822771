import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmailSmsCollect } from './../../../models/sdm/email-sms/email-sms-collect';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root'
})
export class EmailsSmsCollectParamsService extends AbstractService<
  EmailSmsCollect
> {
  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.emailsSms.collect.default);
  }
}
