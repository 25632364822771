import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap, flatMap } from 'rxjs/operators';
import { Depositor } from '../../../../core/models/sdm/depositor';
import { ProductivityGroup } from '../../../../core/models/sdm/provider-management/productivity-group';
import { DimensioningInfoService } from '../../../../core/services/sdm/delivery-authorization/regular-delivery/dimensioning-info.service';
import { DimensioningInfo } from './../../../../core/models/sdm/delivery-authorization/regular-delivery/dimensioning-info';
import { Provider } from './../../../../core/models/sdm/provider-management/provider';
import { AuthService } from './../../../../core/services/auth.service';
import { CompatibilityProviderService } from './../../../../core/services/sdm/provider-management/groups-compatibility/compatibility-provider.service';
import { RegularDeliveryNfService } from '../../../../core/services/sdm/regular-delivery/regular-delivery-nf.service';
import * as moment from 'moment/moment';
import { RegularDeliveryNF } from '../../../../core/models/sdm/regular-delivery/regular-delivery-nf';

@Component({
  selector: 'app-continue-reschedule',
  templateUrl: './continue-reschedule.component.html',
  styleUrls: ['./continue-reschedule.component.scss'],
  preserveWhitespaces: true
})
export class ContinueRescheduleComponent implements OnInit {
  depositors = [];
  providers: Provider[] = [];
  depositor: Depositor;
  solicitations = [];
  formEnabled = true;
  providerId: Provider[];
  deliveryAuthorization: any[];
  formContinueReschedule: FormGroup;
  statusAuthorization: any;
  providerListWithoutDuplicated = [];

  totalProviders = 0;
  totalProductivityGroups = 0;
  productivityGroups: ProductivityGroup[];

  dateToContinue = null;

  constructor(
    private _location: Location,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private _router: Router,
    private _dimensioningInfoService: DimensioningInfoService,
    private _compatibilityProviderService: CompatibilityProviderService,
    private _regDeliveryNfDeadlineService: RegularDeliveryNfService
  ) {}

  ngOnInit() {
    this.formContinueReschedule = new FormGroup({
      depositor: new FormControl(''),
      provider: new FormControl(''),
      deliveryAuthorization: new FormControl(''),
      statusAuthorization: new FormControl({ value: '', disabled: true }),
      productivityGroup: new FormControl(''),
      deliveryAuthorizationId: new FormControl('')
    });

    this.route.queryParams.subscribe((params: any) => {
      

      this.getProviderFromQueryParams(params);
      this.getDeliveryAuthorizationFromQueryParams(params);
      this.getDepositorFromQueryParams(params);
    });

    this.totalProductivityGroups = this.providers[0].compatibilityProvider[0].providerGroups.length;

    this.totalProviders = this.providers.length;
  }

  validateDateToContinue() {
    return this.dateToContinue > this.deliveryAuthorization['validityDate'];
  }

  getDepositorFromQueryParams(params: any) {
    try {
      this.depositor = JSON.parse(params['depositor']);

      if (this.depositor) {
        const userType = this._authService.getUserAuthority();

        this._regDeliveryNfDeadlineService
          .findByDepositorIdAndStatusTrue(
            this.depositor[0]['establishment'].id,
            this.depositor[0]['id']
          )
          .subscribe((data: RegularDeliveryNF) => {
            if (data) {
              this.dateToContinue = moment(new Date());
              if (/Internal/i.test(userType.type)) {
                this.dateToContinue.add(
                  data.minConfirmNewAuthorizationInternalUser,
                  'd'
                );
              } else {
                this.dateToContinue.add(
                  data.minConfirmNewAuthorizationExternalUser,
                  'd'
                );
              }
            }
          });

        this.formContinueReschedule
          .get('depositor')
          .setValue(this.depositor[0]);
      }
    } catch (e) {
      console.error(e);
    }
  }

  getProviderFromQueryParams(params: any) {
    try {
      this.providers = JSON.parse(params['provider']);

      this.removeDuplicatedProvider();
    } catch (e) {
      console.error(e);
    }
  }

  removeDuplicatedProvider() {
    const providersList = this.providers
      .map(provider => provider['id'])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => this.providers[e])
      .map(e => this.providers[e]);

    this.providerListWithoutDuplicated = providersList;

    if (this.providerListWithoutDuplicated.length === 1) {
      this.formContinueReschedule
        .get('provider')
        .setValue(this.providerListWithoutDuplicated[0]);
      this.providerChanged(this.providerListWithoutDuplicated[0]);
    }
  }

  getDeliveryAuthorizationFromQueryParams(params: any) {
    try {
      this.deliveryAuthorization = JSON.parse(params['deliveryAuthorization']);

      if (this.deliveryAuthorization) {
        

        this.formContinueReschedule
          .get('deliveryAuthorization')
          .setValue(this.deliveryAuthorization);

        this.formContinueReschedule
          .get('statusAuthorization')
          .setValue(this.deliveryAuthorization['statusAuthorization']);
      }
    } catch (e) {
      console.error(e);
    }
  }

  goBack() {
    this._router.navigate(['sdm/delivery-authorization/edit']);
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositor[0]),
        provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  validateDocumentAndCalcTime() {
    this._dimensioningInfoService
      .create(this.formContinueReschedule.value)
      .subscribe((data: DimensioningInfo) => {
        
        if (data) {
          this._router.navigate(
            ['sdm/delivery-authorization/regular-delivery/qtd-slots'],
            this.queryParams()
          );
        }
      });
  }

  continue() {
    if (this.totalProviders === 1 && this.totalProductivityGroups === 1) {
      this.validateDocumentAndCalcTime();
    } else {
      this._router.navigate(
        ['sdm/delivery-authorization/regular-delivery/dimensioning-info'],
        this.queryParams()
      );
    }
  }

  providerChanged(provider: Provider) {
    const { id } = this._authService.getUser();
    this.loadProductivityGroupsProvider(provider.document, id);
  }

  loadProductivityGroupsProvider(documentProvider: string, userId: number) {
    this._compatibilityProviderService
      .findByDocumentAndUserIdStatusTrue(documentProvider, userId)
      .pipe(
        map(prodGroups => prodGroups['content'][0].providerGroups),
        tap((prodGroups: any[]) => prodGroups.map(p => p['productivityGroup'])),
        map(prodGroups => prodGroups.filter((p: any) => p.status)),
        map(prodGroups => prodGroups.map((p: any) => p.productivityGroup)),
        catchError(this.handleError)
      )
      .subscribe((response: ProductivityGroup[]) => {
        this.productivityGroups = response;

        if (response.length === 1) {
          this.formContinueReschedule
            .get('productivityGroup')
            .setValue(this.productivityGroups[0]);
        }
      });
  }

  handleError(error: any) {
    return Observable.of(null);
  }
}
