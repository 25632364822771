import { environment as env } from '../environments/environment';

// 00.000.000/0000-00
export const REGEX_CNPJ = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/;

const WRAPPER = '/wrapper';

/*
  URN´s for make calls http requests in WRAPPER
*/

// /wrapper/vehicle-depositor/findVehiclesByDepositorId/
export const SDM_URL = {
  establishment: {
    findById: `${env.host.sdm}${WRAPPER}/establishment/:id`,
    findAll: `${WRAPPER}/establishment`,
    default: `${WRAPPER}/establishment`,
  },
  depositor: {
    findDepositorsByEstablishmentId: `${WRAPPER}/depositor/findDepositorsByEstablishmentId/:establishmentId`,
    findAllByUserId: `${env.host.sdm}${WRAPPER}/depositor/user/:userId`,
  },
  vehicle: {
    depositor: {
      findByDepositorActive: `${WRAPPER}/vehicle-depositor/findVehiclesByDepositorId/:depositorId/:establishmentId`,
      findByDepositorAndUserIdAndStatusTrue: `${
        env.host.sdm
      }${WRAPPER}/vehicle-depositor/depositor/:depositorId/user/:userId/vehicles/active`,
      findByDepositorAndstatusTrue: `${WRAPPER}/vehicle-depositor/depositor/:depositorId/vehicles/isActive`,
      findByDepositorId: `${WRAPPER}/vehicle-depositor/depositor/:depositorId/vehicles`,
    },
    establishment: {
      findByEstablishmentId: `${WRAPPER}/vehicle-establishment/establishment/:establishmentId/depositor/:depositorId`,
    },
  },
  measureUnit: {
    depositor: {
      findByDepositorIdAndEstablishmentId: `${WRAPPER}/measure-unit/depositor/:depositorId/establishment/:establishmentId`,
    },
  },
  operationNature: {
    depositor: {
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/operation-nature-depositor/findByDepositorId/:depositorId`,
      default: `${WRAPPER}/operation-nature-depositor`,
    },
  },
  signature: {
    create: `${env.host.sdm}${WRAPPER}/signature`,
    findById: `${env.host.sdm}${WRAPPER}/signature/:id`,
    findAll: `${env.host.sdm}${WRAPPER}/signature`,
    activeDeactive: `${env.host.sdm}${WRAPPER}/signature/activeDeactive/:id`,
    default: `${WRAPPER}/signature`,
    findBySignatureNumber: `${
      env.host.sdm
    }${WRAPPER}/signature/findBySignatureNumber/:signatureNumber`,
  },
  regularDelivery: {
    create: `${env.host.sdm}${WRAPPER}/regular-delivery`,
    findById: `${env.host.sdm}${WRAPPER}/regular-delivery/:id`,
    findAll: `${env.host.sdm}${WRAPPER}/regular-delivery`,
    activeDeactive: `${
      env.host.sdm
    }${WRAPPER}/regular-delivery/activeDeactive/:id`,
    default: `${WRAPPER}/regular-delivery`,
    findByDepositorId: `${
      env.host.sdm
    }${WRAPPER}/regular-delivery/depositor/:depositorId`,
    order: {
      create: `${env.host.sdm}${WRAPPER}/regular-delivery/order`,
      findById: `${env.host.sdm}${WRAPPER}/regular-delivery/order/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/regular-delivery/order`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/regular-delivery/order/activeDeactive/:id`,
      default: `${WRAPPER}/regular-delivery/order`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/regular-delivery/order/depositor/:depositorId/user/:userId`,
    },
    requestMax: {
      create: `${env.host.sdm}${WRAPPER}/regular-delivery/request-max`,
      default: `${WRAPPER}/regular-delivery/request-max`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/regular-delivery/request-max/depositor/:depositorId/user/:userId`,
    },
    smallSize: {
      create: `${env.host.sdm}${WRAPPER}/regular-delivery/small-size`,
      default: `${WRAPPER}/regular-delivery/small-size`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/regular-delivery/small-size/depositor/:depositorId`,
    },
    nfDeadlines: {
      create: `${env.host.sdm}${WRAPPER}/regular-delivery/nf`,
      default: `${WRAPPER}/regular-delivery/nf`,
    },
  },
  reverseDelivery: {
    create: `${env.host.sdm}${WRAPPER}/reverse-delivery`,
    findById: `${env.host.sdm}${WRAPPER}/reverse-delivery/:id`,
    findAll: `${env.host.sdm}${WRAPPER}/reverse-delivery`,
    activeDeactive: `${
      env.host.sdm
    }${WRAPPER}/reverse-delivery/activeDeactive/:id`,
    default: `${WRAPPER}/reverse-delivery`,
    rma: {
      create: `${env.host.sdm}${WRAPPER}/reverse-delivery/rma`,
      findById: `${env.host.sdm}${WRAPPER}/reverse-delivery/rma/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/reverse-delivery/rma`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/reverse-delivery/activeDeactive/rma/:id`,
      default: `${WRAPPER}/reverse-delivery/rma`,
    },
    requestMax: {
      create: `${env.host.sdm}${WRAPPER}/reverse-delivery/request-max`,
      findById: `${env.host.sdm}${WRAPPER}/reverse-delivery/request-max/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/reverse-delivery/request-max`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/reverse-delivery/activeDeactive/request-max/:id`,
      default: `${WRAPPER}/reverse-delivery/request-max`,
    },
    smallSize: {
      create: `${env.host.sdm}${WRAPPER}/reverse-delivery/small-size`,
      default: `${WRAPPER}/reverse-delivery/small-size`,
    },
    nfDeadlines: {
      create: `${env.host.sdm}${WRAPPER}/reverse-delivery/nf`,
      default: `${WRAPPER}/reverse-delivery/nf`,
    },
  },
  collect: {
    create: `${env.host.sdm}${WRAPPER}/collect`,
    findById: `${env.host.sdm}${WRAPPER}/collect/:id`,
    findAll: `${env.host.sdm}${WRAPPER}/collect`,
    activeDeactive: `${env.host.sdm}${WRAPPER}/collect/activeDeactive/:id`,
    default: `${WRAPPER}/collect`,
    rma: {
      create: `${env.host.sdm}${WRAPPER}/collect/rma`,
      findById: `${env.host.sdm}${WRAPPER}/collect/rma/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/collect/rma`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/collect/activeDeactive/rma/:id`,
      default: `${WRAPPER}/collect/rma`,
    },
    requestMax: {
      create: `${env.host.sdm}${WRAPPER}/collect/request-max`,
      findById: `${env.host.sdm}${WRAPPER}/collect/request-max/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/collect/request-max`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/collect/activeDeactive/request-max/:id`,
      default: `${WRAPPER}/collect/request-max`,
    },
    smallSize: {
      create: `${env.host.sdm}${WRAPPER}/collect/small-size`,
      default: `${WRAPPER}/collect/small-size`,
    },
    nfDeadlines: {
      create: `${env.host.sdm}${WRAPPER}/collect/nf`,
      default: `${WRAPPER}/collect/nf`,
    },
  },
  docks: {
    regularOperatingDays: {
      create: `${env.host.sdm}${WRAPPER}/docks/regular-operating-days`,
      findById: `${env.host.sdm}${WRAPPER}/docks/regular-operating-days/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/regular-operating-days`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/regular-operating-days/:id`,
      default: `${WRAPPER}/docks/regular-operating-days`,
      findByEstablishmentId: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/regular-operating-days/establishment/:establishmentId`,
    },
    holidayRelationShip: {
      create: `${env.host.sdm}${WRAPPER}/docks/holiday-relationship`,
      findById: `${env.host.sdm}${WRAPPER}/docks/holiday-relationship/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/holiday-relationship`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/holiday-relationship/:id`,
      default: `${WRAPPER}/docks/holiday-relationship`,
    },
    priorVisualization: {
      create: `${env.host.sdm}${WRAPPER}/docks/prior-visualization`,
      findById: `${env.host.sdm}${WRAPPER}/docks/prior-visualization/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/prior-visualization`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/prior-visualization/:id`,
      default: `${WRAPPER}/docks/prior-visualization`,
      findByDepositorId: `${WRAPPER}/docks/prior-visualization/findByDepositorId/:depositorId`,
      findByDepositorIdAndStatusTrue: `${WRAPPER}/docks/prior-visualization/depositor/:depositorId/docks`,
      findBySignatureDock: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/signatureDock/:signatureDock`,
      upload: `${env.host.sdm}${WRAPPER}/docks/prior-visualization/upload`,
      backup: `${env.host.sdm}${WRAPPER}/docks/prior-visualization/backup`,
      export: `${env.host.sdm}${WRAPPER}/docks/prior-visualization/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/import-partial`,
      findByDepositorAndStatusTrue: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/getAll/depositor/:depositorId`,
      findByDocksAvailable: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/docks-available/depositor/:depositorId/validity-date/:validityDate/delivery-authorization/:deliveryAuthorizationId/risk/:risk/establishment/:establishmentId/slots/:qtdSlots/statusAuthorization/:statusAuthorization/vehicleType/:vehicleTypeId`,
      findByDocksAvailableManagement: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/docks-available/establishment/:establishmentId/depositor/:depositorId/initial-date/:initialDate/regularDelivery/:regularDelivery/reverseDelivery/:reverseDelivery/collect/:collect`,
      findBySlotsAvailable: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/docks-available/establishment/:establishmentId/qtdSlots/:qtdSlots/initialDate/:initialDate`,
      findMinInitialHourAndMaxFinalHourByEstablishmentId: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/establishment/:establishmentId/min-max-hours-operation`,
      findByEstablishmentIdAndDateSchedule: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/establishment/:establishmentId`,
      findByDepositorIdAndEstablishmentId: `${WRAPPER}/docks/prior-visualization/findByDepositorId/:depositorId/establishment/:establishmentId`,
      findByEstablishmentIdAndDepositorIdAndCollectTrue: `${WRAPPER}/docks/prior-visualization/depositor/:depositorId/establishment/:establishmentId/collect`,
      findByEstablishmentIdAndDepositorIdAll: `${WRAPPER}/docks/prior-visualization/depositor/:depositorId/establishment/:establishmentId/all`,
      findAllBySequenceOrderAsc: `${WRAPPER}/docks/prior-visualization/establishmentId/:establishmentId/sequence`,
      updatePosition: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/edit/signatureDock/:signatureDock/sequence/:sequence/establishment/:establishmentId`,
      findByDocksAvailableConsecutiveAndDeliveryAuthorizationId: `${
        env.host.sdm
      }${WRAPPER}/docks/prior-visualization/docks-available/delivery-authorization/:deliveryAuthorizationId/consecutive`,
      findByEstablishmentIdAndStatusTrue: `${WRAPPER}/docks/prior-visualization/all/establishment/:establishmentId`,
    },
    dockCompatibility: {
      create: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility`,
      findById: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/dock-compatibility/:id`,
      default: `${WRAPPER}/docks/dock-compatibility`,
      upload: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility/upload`,
      backup: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility/backup`,
      export: `${env.host.sdm}${WRAPPER}/docks/dock-compatibility/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-compatibility/import-partial`,
      findByDepositorIdAndCollectTrue: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-compatibility/depositor/:depositorId/collect`,
    },
    dockVehicleCompatibility: {
      create: `${env.host.sdm}${WRAPPER}/docks/dock-vehicle-compatibility`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-vehicle-compatibility/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/dock-vehicle-compatibility`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/dock-vehicle-compatibility/:id`,
      default: `${WRAPPER}/docks/dock-vehicle-compatibility`,
      findByDepositorId: `${WRAPPER}/docks/dock-vehicle-compatibility/depositor/:depositorId`,
      findByDepositorIdAndStatusTrue: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-vehicle-compatibility/depositor/:depositorId`,
    },
    dockVehicles: {
      create: `${env.host.sdm}${WRAPPER}/docks/dock-vehicles`,
      findById: `${env.host.sdm}${WRAPPER}/docks/dock-vehicles/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/dock-vehicles`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/dock-vehicles/:id`,
      default: `${WRAPPER}/docks/dock-vehicles`,
      findBySdmDock: `${WRAPPER}/docks/dock-vehicles/sdmDock/:sdmDockId`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-vehicles/depositor/:depositorId`,
    },
    docksOptions: {
      create: `${env.host.sdm}${WRAPPER}/docks/options`,
      findById: `${env.host.sdm}${WRAPPER}/docks/options/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/docks/options`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/options/:id`,
      default: `${WRAPPER}/docks/options`,
      findBySdmDock: `${WRAPPER}/docks/options/sdmDock/:sdmDockId`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/docks/options/depositor/:depositorId`,
    },
  },
  providerManagement: {
    blackList: {
      create: `${env.host.sdm}${WRAPPER}/provider-management/black-list`,
      findById: `${env.host.sdm}${WRAPPER}/provider-management/black-list/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/provider-management/black-list`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/dock-vehicles/:id`,
      default: `${WRAPPER}/provider-management/black-list`,
      upload: `${env.host.sdm}${WRAPPER}/provider-management/black-list/upload`,
      backup: `${env.host.sdm}${WRAPPER}/provider-management/black-list/backup`,
      export: `${env.host.sdm}${WRAPPER}/provider-management/black-list/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/black-list/import-partial`,
    },
    provider: {
      create: `${env.host.sdm}${WRAPPER}/provider-management/provider`,
      findById: `${env.host.sdm}${WRAPPER}/provider-management/provider/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/provider-management/provider`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/dock-vehicles/:id`,
      default: `${WRAPPER}/provider-management/provider`,
      upload: `${env.host.sdm}${WRAPPER}/provider-management/provider/upload`,
      findByDocument: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/document/:document`,
      backup: `${env.host.sdm}${WRAPPER}/provider-management/provider/backup`,
      export: `${env.host.sdm}${WRAPPER}/provider-management/provider/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/import-partial`,
      findAllByUserId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/user/:userId`,
      template: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/template`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/depositor/:depositorId`,
      findByEstablishmentIdAndDepositorIdAndSolutionId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/solution?establishmentId=:establishmentId&depositorId=:depositorId&solutionId=:solutionId`,
      findByEstablishmentIdAndDepositorIdAndSolutionIdAndAgentId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/agent?establishmentId=:establishmentId&depositorId=:depositorId&solutionId=:solutionId&agentId=:agentId`,
      findByEstablishmentIdAndDepositorId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/establishment/:establishmentId/depositor/:depositorId`,
      findByEstablishmentId: `${
        env.host.sdm
      }${WRAPPER}/provider-management/provider/establishment/:establishmentId`,
    },
    priorityProviders: {
      create: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/:id`,
      findAll: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/priority-providers/:id`,
      default: `${WRAPPER}/provider-management/priority-providers`,
      upload: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/upload`,
      findByDocument: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/document/:document`,
      backup: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/backup`,
      export: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/priority-providers/import-partial`,
    },
    criticalItems: {
      create: `${env.host.sdm}${WRAPPER}/provider-management/critical-items`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/provider-management/critical-items/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/provider-management/critical-items`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/critical-items/:id`,
      default: `${WRAPPER}/provider-management/critical-items`,
      upload: `${
        env.host.sdm
      }${WRAPPER}/provider-management/critical-items/upload`,
      backup: `${
        env.host.sdm
      }${WRAPPER}/provider-management/critical-items/backup`,
      export: `${
        env.host.sdm
      }${WRAPPER}/provider-management/critical-items/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/critical-items/import-partial`,
    },
    impreciseFields: {
      create: `${env.host.sdm}${WRAPPER}/provider-management/imprecise-fields`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/provider-management/imprecise-fields`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/imprecise-fields/:id`,
      default: `${WRAPPER}/provider-management/imprecise-fields`,
      upload: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/upload`,
      backup: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/backup`,
      export: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/import-partial`,
      findByDocumentAndUserIdStatusTrue: `${
        env.host.sdm
      }${WRAPPER}/provider-management/imprecise-fields/document/:document/user/:userId`,
    },
    daysHoursRestriction: {
      create: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction/:id`,
      findAll: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/days-hours-restriction/:id`,
      default: `${WRAPPER}/provider-management/days-hours-restriction`,
      upload: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction/upload`,
      backup: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction/backup`,
      export: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/provider-management/days-hours-restriction/import-partial`,
    },
    docksDimensioningTime: {
      productivityGroup: {
        create: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group`,
        findById: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/:id`,
        findAll: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group`,
        activeDeactive: `${
          env.host.sdm
        }${WRAPPER}/docks/activeDeactive/docks-dimensioning/productivity-group/:id`,
        default: `${WRAPPER}/provider-management/docks-dimensioning/productivity-group`,
        upload: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/upload`,
        backup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/backup`,
        export: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/export`,
        importPartial: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/import-partial`,
        findAllByUserId: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/user/:created_by`,
        findByDepositorIdAndUserIdAndStatusTrue: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/depositor/:depositorId/user/:userId/active`,
        findByDepositorIdAndStatusTrue: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/depositor/getAll/depositor/:depositorId`,
        findByDepositorAndStatusTrue: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-group/getAll/depositor/:depositorId`,
      },
      productivityPattern: {
        create: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern`,
        findById: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern/:id`,
        findAll: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern`,
        activeDeactive: `${
          env.host.sdm
        }${WRAPPER}/docks/activeDeactive/docks-dimensioning/productivity-pattern/:id`,
        default: `${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern`,
        upload: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern/upload`,
        backup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern/backup`,
        export: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern/export`,
        importPartial: `${
          env.host.sdm
        }${WRAPPER}/provider-management/docks-dimensioning/productivity-pattern/import-partial`,
      },
    },
    groupsCompatibility: {
      compatibilityProvider: {
        create: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider`,
        findById: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/:id`,
        findAll: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider`,
        activeDeactive: `${
          env.host.sdm
        }${WRAPPER}/docks/activeDeactive/groups-compatibility/compatibility-provider/:id`,
        default: `${WRAPPER}/provider-management/groups-compatibility/compatibility-provider`,
        upload: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/upload`,
        backup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/backup`,
        export: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/export`,
        importPartial: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/import-partial`,
        getAllGroups: `${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/groups/compatibilityProvider/:compatibilityProviderId`,
        activeDeactiveGroup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/groups/compatibilityProvider/:compatibilityProviderId/activeDeactiveGroup/:compatibilityProviderGroupId`,
        findByDocumentAndUserIdStatusTrue: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-provider/document/:document/user/:userId/groups`,
      },
      compatibilityDocks: {
        create: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks`,
        findById: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/:id`,
        findAll: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks`,
        activeDeactive: `${
          env.host.sdm
        }${WRAPPER}/docks/activeDeactive/groups-compatibility/compatibility-docks/:id`,
        default: `${WRAPPER}/provider-management/groups-compatibility/compatibility-docks`,
        upload: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/upload`,
        backup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/backup`,
        export: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/export`,
        importPartial: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/import-partial`,
        getAllGroups: `${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/groups/compatibilityDocks/:compatibilityDocksId`,
        activeDeactiveGroup: `${
          env.host.sdm
        }${WRAPPER}/provider-management/groups-compatibility/compatibility-docks/groups/compatibilityDocks/:compatibilityDocksId/activeDeactiveGroup/:compatibilityDocksGroupId`,
      },
    },
    emailSmsAgent: {
      create: `${env.host.sdm}${WRAPPER}/email-sms-agent`,
      findById: `${env.host.sdm}${WRAPPER}/email-sms-agent/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/email-sms-agent`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/email-sms-agent/:id`,
      default: `${WRAPPER}/email-sms-agent`,
      search: `${WRAPPER}/email-sms-agent/search`,
      upload: `${env.host.sdm}${WRAPPER}/email-sms-agent/upload`,
      backup: `${env.host.sdm}${WRAPPER}/email-sms-agent/backup`,
      export: `${env.host.sdm}${WRAPPER}/email-sms-agent/export`,
      importPartial: `${env.host.sdm}${WRAPPER}/email-sms-agent/import-partial`,
    },
    vipBlackListAgent: {
      create: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent`,
      findById: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/docks/activeDeactive/vip-blacklist-agent/:id`,
      default: `${WRAPPER}/vip-blacklist-agent`,
      search: `${WRAPPER}/vip-blacklist-agent/search`,
      upload: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent/upload`,
      backup: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent/backup`,
      export: `${env.host.sdm}${WRAPPER}/vip-blacklist-agent/export`,
      importPartial: `${
        env.host.sdm
      }${WRAPPER}/vip-blacklist-agent/import-partial`,
    },
  },
  deliveryAuthorization: {
    regularDelivery: {
      manualTypeDoc: {
        create: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/document`,
        default: `${WRAPPER}/delivery-authorization/regular-delivery/document`,
        findByDeliveryAuthorizationId: `${WRAPPER}/delivery-authorization/regular-delivery/document/delivery-authorization/:deliveryAuthorizationId/documents`,
        findByDocumentKey: `${WRAPPER}/delivery-authorization/regular-delivery/document/delivery-authorization/document/:documentKey/inconsistencies`,
        findByProviderId: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/document/provider/:providerId`,
        findByDepositorIdAndDeliveryAuthorization: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/document/depositor/:depositorId/delivery-authorization/:deliveryAuthorizationId`,
      },
      unitaryUpload: {
        create: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/unitary-upload`,
        uploadXml: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/unitary-upload`,
        uploadTxt: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/unitary-upload/txt`,
        default: `${WRAPPER}/delivery-authorization/regular-delivery/unitary-upload`,
      },
      massiveUpload: {
        create: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/massive-upload`,
        uploadDocs: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/massive-upload`,
        default: `${WRAPPER}/delivery-authorization/regular-delivery/massive-upload`,
        partialContinue: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/massive-upload/partial-continue/delivery-authorization/:deliveryAuthorizationId`,
        deleteAllByDeliveryAuthorization: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/massive-upload/delivery-authorization/:deliveryAuthorizationId`,
      },
      dimensioningInfo: {
        create: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/dimensioning-info`,
        default: `${WRAPPER}/delivery-authorization/regular-delivery/dimensioning-info`,
        findByDeliveryAuthorizationId: `${WRAPPER}/delivery-authorization/regular-delivery/dimensioning-info/delivery-authorization/:deliveryAuthorizationId/documents`,
        findByDocumentKey: `${WRAPPER}/delivery-authorization/regular-delivery/document/delivery-authorization/dimensioning-info/:documentKey/inconsistencies`,
        deleteByDeliveryAuthorization: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/dimensioning-info/deliveryAuthorization/:deliveryAuthorizationId`,
        findByProductivityGroupsByDeliveryAuthorization: `${
          env.host.sdm
        }${WRAPPER}/delivery-authorization/regular-delivery/dimensioning-info/deliveryAuthorization/:deliveryAuthorizationId/productivityGroups`,
      },
      create: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery`,
      default: `${WRAPPER}/delivery-authorization/regular-delivery`,
      findByStatusAuthorizationIn: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/authorizations/status?status=:status`,
      findByAuthorizationKeyIsNotNull: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/authorizations/combo`,
      findCustomById: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/authorization/:id`,
      findByAuthorizationKey: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/authorizationKey/:authorizationkey`,
      findDocksScheduling: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/revalidationSchedule/deliveryAuthorization/:deliveryAuthorizationId/depositor/:depositorId`,
      findByDepositorId: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/regular-delivery/depositor/:depositorId`,
    },
    docksScheduling: {
      create: `${WRAPPER}/delivery-authorization/docks-scheduling`,
      default: `${WRAPPER}/delivery-authorization/docks-scheduling`,
      findByEstablishmentId: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/docks-scheduling/establishment/:establishmentId`,
      rescheduleDelivery: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/docks-scheduling/reschedule-delivery/:docksScheduleId/status-reserve/:reserveTypeInternalUserUpdate`,
      findByDocksScheduling: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/docks-scheduling/priorVisualization/:priorVisualizationId/initialHour/:initialHour/finalHour/:finalHour`,
      removeSchedulingType: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/docks-scheduling/priorVisualization/:priorVisualizationId/initialHour/:initialHour/finalHour/:finalHour/vip`,
      findBySlotsAvailable: `${
        env.host.sdm
      }${WRAPPER}/delivery-authorization/docks-scheduling/establishment/:establishmentId/depositor/:depositorSolicitation/qtdSlots/:qtdSlots/delivery-authorization/:deliveryAuthorizationId/search-slots`,
    },
    queryDelivery: `${WRAPPER}/delivery-authorization/regular-delivery/search`,
    queryDeliveryReverseCollect: `${WRAPPER}/delivery-authorization/regular-delivery/search/reverse/collect`,
    queryDeliveryReverseExport: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/search/reverse/export`,
    queryDeliveryToExportFile: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/search/export`,
    findBySearchCollectExport: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/search/collect/export`,
    queryDeliveryApproved: `${WRAPPER}/delivery-authorization/regular-delivery/search/approved`,
    queryAuthorizationsApproved: `${WRAPPER}/authorization/regular-delivery/search/approved`,
    confirmAuthorization: {
      create: `${WRAPPER}/authorization/regular-delivery`,
      default: `${WRAPPER}/authorization/regular-delivery`,
    },
    queryDeliveryManagementConflict: `${WRAPPER}/delivery-authorization/regular-delivery/search/management/conflicts`,
    fixPendingAllocation: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/pending-allocation/management`,
    findByDepositorIdAndStatusDocsOK: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/depositor/:depositorId/docs-ok`,
    findByDepositorIdAndFinisherStatus: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/finisher-status`,
    findByDepositorIdAndStatus: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/depositor/:depositorId/status`,
    partialUpdate: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/partial/authorization/:id`,
    findByDepositorIdAndStatusTrueAndConflictedTrue: `${
      env.host.sdm
    }${WRAPPER}/delivery-authorization/regular-delivery/depositor/:depositorId/conflict/pending`,
  },
  locale: {
    filterLocalities: `${env.host.sdm}${WRAPPER}/localities/cities`,
  },
  schedules: {
    collect: {
      create: `${env.host.sdm}${WRAPPER}/schedules/collect`,
      default: `${WRAPPER}/schedules/collect`,
      createWithConflict: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/conflict`,
      updateWithConflict: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/conflict`,
      export: `${env.host.sdm}${WRAPPER}/schedules/collect/export`,
      findWithFilters: `${WRAPPER}/schedules/collect/search`,
      validateRecurrentSchedule: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/validate/recurrent`,
      createReverseRecurrent: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/recurrent`,
      validateRecurrentScheduleOnUpdate: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/validate/recurrent/update`,
      updateCollectSchedules: `${env.host.sdm}${WRAPPER}/schedules/collect`,
      findBySearchCollectExport: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/search/export/collect`,
      updateCollectionWithConflict: `${
        env.host.sdm
      }${WRAPPER}/schedules/collect/conflict`,
    },
    reverseDelivery: {
      manualAllocation: {
        create: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation`,
        default: `${WRAPPER}/schedules/reverse-delivery/manual-allocation`,
        export: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/export`,
        search: `${WRAPPER}/schedules/reverse-delivery/manual-allocation/search`,
        createWithConflict: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/conflict`,
        updateManualAllocation: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation`,
        updateManualAllocationWithConflict: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/conflict`,
        createReverseRecurrent: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/recurrent`,
        validateRecurrentSchedule: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/validate/recurrent`,
        validateRecurrentScheduleOnUpdate: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/validate/recurrent/update`,
        findByReverseSearchExport: `${
          env.host.sdm
        }${WRAPPER}/schedules/reverse-delivery/manual-allocation/search/export/reverse`,
      },
    },
  },
  dockManagement: {
    slotsReserve: {
      create: `${env.host.sdm}${WRAPPER}/dock-management/slots-reserve`,
      default: `${WRAPPER}/dock-management/slots-reserve`,
      createWithConflict: `${
        env.host.sdm
      }${WRAPPER}/dock-management/slots-reserve`,
      updateWithConflict: `${
        env.host.sdm
      }${WRAPPER}/dock-management/slots-reserve`,
      export: `${env.host.sdm}${WRAPPER}/dock-management/slots-reserve/export`,
      search: `${WRAPPER}/dock-management/slots-reserve/search`,
    },
    unlockSlots: {
      create: `${env.host.sdm}${WRAPPER}/dock-management/unlock-slots`,
      default: `${WRAPPER}/dock-management/unlock-slots`,
      createWithConflict: `${
        env.host.sdm
      }${WRAPPER}/dock-management/unlock-slots`,
      updateWithConflict: `${
        env.host.sdm
      }${WRAPPER}/dock-management/unlock-slots`,
      export: `${env.host.sdm}${WRAPPER}/dock-management/unlock-slots/export`,
      search: `${WRAPPER}/dock-management/unlock-slots/search`,
    },
    docksOperation: {
      create: `${env.host.sdm}${WRAPPER}/dock-management/docks-operation`,
      inputVehicleArrival: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/input`,
      outputVehicleArrival: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/output`,
      docksStart: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/start`,
      docksFinished: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/finished`,
      noShow: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/no-show`,
      default: `${WRAPPER}/dock-management/docks-operation`,
      export: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/export`,
      search: `${WRAPPER}/dock-management/docks-operation/search`,
      findByDeliveryAuthorizationId: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/deliveryAuthorization/:deliveryAuthorizationId`,
      validateDocksOnOperation: `${
        env.host.sdm
      }${WRAPPER}/dock-management/docks-operation/docksOP/:idDocaOP/docksPreview/:idDocaPrevista`,
    },
  },
  lobby: {
    create: `${env.host.sdm}${WRAPPER}/lobby/module`,
    default: `${WRAPPER}/lobby/module`,
    createWithConflict: `${env.host.sdm}${WRAPPER}/lobby/module`,
    updateWithConflict: `${env.host.sdm}${WRAPPER}/lobby/module`,
    export: `${env.host.sdm}${WRAPPER}/lobby/module/export`,
    search: `${WRAPPER}/lobby/module/search`,
    registerInput: `${env.host.sdm}${WRAPPER}/lobby/module/input`,
    registerOutput: `${env.host.sdm}${WRAPPER}/lobby/module/output`,
  },
  report: {
    groupReportSdm: `${env.host.report}${WRAPPER}/gavs-sdm`,
    create: `${env.host.report}${WRAPPER}/report/reports`,
    reports: `${env.host.report}${WRAPPER}/report/reports`,

    report: `${env.host.report}${WRAPPER}/report/reports/establishment/:establishmentId/depositor/:depositorId/`,
    preReport: `${env.host.report}${WRAPPER}/report/reports/establishment/:establishmentId/`,

    reportUserId: `${env.host.report}${WRAPPER}/report/reports/user/:userId`,
    operationNatures: `${env.host.report}${WRAPPER}/report/operation-natures`,
    timeMeasures: `${env.host.report}${WRAPPER}/report/time-measures`,
    indicadores: `${env.host.report}${WRAPPER}/report/indicadores`,
    indicador: `${
      env.host.report
    }${WRAPPER}/report/indicadores/report/:reportId`,
    perfilUtilizacaoDoca: `${
      env.host.report
    }${WRAPPER}/report/perfil-utilizacao-docas/report/:reportId`,
  },
  handling: {
    pendingHandling: {
      create: `${env.host.sdm}${WRAPPER}/handling/pending/regular-delivery`,
      findById: `${
        env.host.sdm
      }${WRAPPER}/docks/dock-vehicle-compatibility/:id`,
      findAll: `${env.host.sdm}${WRAPPER}/handling/pending/regular-delivery`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/handling/pending/regular-delivery/activeDeactive/:id`,
      default: `${WRAPPER}/handling/pending/regular-delivery`,
    },
  },
  emailsSms: {
    regularDelivery: {
      create: `${env.host.sdm}${WRAPPER}/email-sms-params`,
      default: `${WRAPPER}/email-sms-params`,
      findAll: `${WRAPPER}/email-sms-params`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/email-sms-params/activeDeactive/:id`,
    },
    reverseDelivery: {
      create: `${env.host.sdm}${WRAPPER}/email-sms-params/reverse-delivery`,
      default: `${WRAPPER}/email-sms-params/reverse-delivery`,
      findAll: `${WRAPPER}/email-sms-params/reverse-delivery`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/email-sms-params/reverse-delivery/activeDeactive/:id`,
    },
    collect: {
      create: `${env.host.sdm}${WRAPPER}/email-sms-params/collect`,
      default: `${WRAPPER}/email-sms-params/collect`,
      findAll: `${WRAPPER}/email-sms-params/collect`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/email-sms-params/collect/activeDeactive/:id`,
    },
  },
  agentTypeDepositor: {
    create: `${env.host.sdm}${WRAPPER}/agent-type-depositor`,
    default: `${WRAPPER}/agent-type-depositor`,
    findAll: `${WRAPPER}/agent-type-depositor`,
    search: `${WRAPPER}/agent-type-depositor/search`,
    activeDeactive: `${
      env.host.sdm
    }${WRAPPER}/agent-type-depositor/activeDeactive/:id`,
    findByDepositorAndSolution: `${
      env.host.sdm
    }${WRAPPER}/agent-type-depositor/depositor/:depositorId/solution/:solutionId`,
  },
  logisticCosts: {
    ocurrencyRework: {
      create: `${env.host.sdm}${WRAPPER}/ocurrency-rework`,
      default: `${WRAPPER}/ocurrency-rework`,
      findAll: `${WRAPPER}/ocurrency-rework`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/ocurrency-rework/activeDeactive/:id`,
    },
    idlenessDocks: {
      create: `${env.host.sdm}${WRAPPER}/idleness-docks`,
      default: `${WRAPPER}/idleness-docks`,
      findAll: `${WRAPPER}/idleness-docks`,
      activeDeactive: `${
        env.host.sdm
      }${WRAPPER}/idleness-docks/activeDeactive/:id`,
    },
  },
  managementSupervisor: {
    create: `${env.host.sdm}${WRAPPER}/management-supervisor`,
    default: `${WRAPPER}/management-supervisor`,
    findAll: `${WRAPPER}/management-supervisor`,
    search: `${WRAPPER}/management-supervisor/search`,
    activeDeactive: `${
      env.host.sdm
    }${WRAPPER}/management-supervisor/activeDeactive/:id`,
    findByDepositorAndSolution: `${
      env.host.sdm
    }${WRAPPER}/management-supervisor/depositor/:depositorId/solution/:solutionId`,
    validateSupervisorPass: `${
      env.host.sdm
    }${WRAPPER}/management-supervisor/establishment/:establishmentId/depositor/:depositorId/solution/:solutionId/validate`,
  },
};

export const SBAW_URL = {};
