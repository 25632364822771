import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../../../environments/environment';
import { SDM_URL } from '../../../../app.api';
import { AbstractService } from '../../abstract.service';
import { DockVehicles } from './../../../models/sdm/docks/dock-vehicles';

@Injectable({
  providedIn: 'root'
})
export class DockVehiclesService extends AbstractService<DockVehicles> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.docks.dockVehicles.default);
  }

  findByDepositorId(depositorId: number): Observable<any> {
    return this.http
      .get(
        `${SDM_URL.docks.dockVehicles.findByDepositorId.replace(
          ':depositorId',
          depositorId.toString()
        )}`
      )
      .pipe(take(1));
  }
}
