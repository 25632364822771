import { Depositor } from './../depositor';
import { IDeserializable } from './../../deserializable.model';
import { AbstractStatusModel } from '../../abstractStatus.model';
import { DocksScheduling } from '../document/docks-scheduling';
import { DockCompatibility } from './dock-compatibility';

export class PriorVisualization extends AbstractStatusModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: Depositor[];
  // public sdmDock: number;
  public signatureDock: number;
  public priorType: number;
  public initialDate: string;
  public finalDate: string;
  public initialHour: string;
  public finalHour: string;
  public regularBlockIniHour: number;
  public regularBlockFinHour: number;
  public exclusiveDock: boolean;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;

  public docksSchedulings: DocksScheduling[];
  public dockCompatibility: DockCompatibility;

  constructor() {
    super();
    this.monday = true;
    this.tuesday = true;
    this.wednesday = true;
    this.thursday = true;
    this.friday = true;
    this.saturday = false;
    this.sunday = false;
    this.exclusiveDock = false;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
