import { AbstractStatusModel } from '../../abstractStatus.model';
import { Document } from './regular-delivery/document';
import { OpNatureAuthorization } from '../shared/enums/op-nature-authorization.enum';

export class DeliveryAuthorization extends AbstractStatusModel {
  public deliveryConsecutive = false;
  public uploadDocType: number;
  public statusAuthorization: string;
  public risk: boolean;
  public authorizationkey: string;

  public documents: Document[];
  public opNatureDelivery: OpNatureAuthorization;

  constructor() {
    super();
    this.uploadDocType = 2;
  }
}
