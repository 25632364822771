import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CollectRequestMax } from './../../../models/sdm/collect/collect-request-max';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class CollectRequestMaxService extends AbstractService<CollectRequestMax> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.collect.requestMax.default);
  }

}
