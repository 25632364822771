import { Observable } from 'rxjs';
import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { ProductivityGroup } from '../../../models/sdm/provider-management/productivity-group';

@Injectable({
  providedIn: 'root'
})
export class ProductivityGroupService extends AbstractService<
  ProductivityGroup
> {
  constructor(public http: HttpClient) {
    super(
      http,
      environment.host.sdm,
      SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.default
    );
  }

  findByDepositorAndUserIdAndStatusTrue(
    depositorId: number,
    userId: number
  ): Observable<HttpResponse<ProductivityGroup>> {
    return this.http.get<ProductivityGroup>(
      `${SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.findByDepositorIdAndUserIdAndStatusTrue
        .replace(':depositorId', depositorId.toString())
        .replace(':userId', userId.toString())}`,
      { observe: 'response' }
    );
  }

  findByDepositorIdAndStatus(
    depositorId: number
  ): Observable<HttpResponse<ProductivityGroup>> {
    
    return this.http.get<ProductivityGroup>(
      `${SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.findByDepositorAndStatusTrue.replace(
        ':depositorId',
        depositorId.toString()
      )}`,
      { observe: 'response' }
    );
  }
}
