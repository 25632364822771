import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SettingsService } from './settings/settings.service';
import { ThemesService } from './themes/themes.service';
import { TranslatorService } from './translator/translator.service';
import { MenuService } from './menu/menu.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { StatusSolicitationPipe } from './classes/status-solicitation.pipe';
import { AuthorizationShapePipe } from './classes/authorization-shape.pipe';
import { StatusPipe } from './classes/status.pipe';

@NgModule({
    imports: [
    ],
    providers: [
        SettingsService,
        ThemesService,
        TranslatorService,
        MenuService,
    ],
    declarations: [
    StatusSolicitationPipe,
    AuthorizationShapePipe,
    StatusPipe],
    exports: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
