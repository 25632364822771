import { SDM_URL } from './../../../../app.api';
import { environment } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Collect } from './../../../models/sdm/collect/collect';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';

@Injectable({
  providedIn: 'root',
})
export class CollectService extends AbstractService<Collect> {

  constructor(public http: HttpClient) {
    super(http, environment.host.sdm, SDM_URL.collect.default);
  }

}
