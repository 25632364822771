import { SelectFieldComponent } from './../../../../../components/select/select-field/select-field.component';
import { Depositor } from './../../../../../core/models/sdm/depositor';
import { BooleanConverter } from './../../../../../core/classes/boolean-type';
import { take, delay, finalize } from 'rxjs/operators';
import { DocksService } from './../../docks.service';
import { VisualizationTypeEnum } from './../../../../../core/models/sdm/shared/enums/VisualizationTypeEnum';
import { PriorVisualizationService } from './../../../../../core/services/sdm/docks/prior-visualization.service';
import { Location } from '@angular/common';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { TranslatorService } from './../../../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AbstractParametrizations } from '../../../abstract-parametrizations-crud';
import { PriorVisualization } from '../../../../../core/models/sdm/docks/prior-visualization';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { SDM_URL } from '../../../../../app.api';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RegularOperationgDays } from '../../../../../core/models/sdm/docks/regular-operationg-days';
import { RegularOperatingDaysService } from '../../../../../core/services/sdm/docks/regular-operating-days.service';
import { SlotPattern } from '../../../../../core/models/sdm/slot/slot-pattern';
import { SlotPatternService } from '../../../../../core/services/slot/slot-pattern.service';
import { forkJoin } from 'rxjs';
import { validatorFinHour } from '../../../../register/schedules/shared/utils';

@Component({
  selector: 'app-prior-visualization-form',
  templateUrl: './prior-visualization-form.component.html',
  styleUrls: ['./prior-visualization-form.component.scss']
})
export class PriorVisualizationFormComponent
  extends AbstractParametrizations<PriorVisualization>
  implements OnInit {
  public keys;
  establishmentID = '';
  depositorId: any[] = [];
  dockExclusiveOpt = false;
  priorTypeChange = false;
  priorVisualization: PriorVisualization;
  visualizationTypeEnum = VisualizationTypeEnum;
  private _listOfFields = ['initialDate', 'finalDate'];
  regularOperationDays: RegularOperationgDays;
  depositorsListAfterInsert: Depositor[] = [];
  slot: SlotPattern;

  @ViewChild('gridPrioVisualization') grid: DatatableApiComponent;
  @ViewChild('gridDepositorPriorVisual') gridDepositor: DatatableApiComponent;

  @ViewChild('hdrTplVigencia') hdrTplVigencia: TemplateRef<any>;
  @ViewChild('bodyTplVigencia') bodyTplVigencia: TemplateRef<any>;

  @ViewChild('hdrTplTipo') hdrTplTipo: TemplateRef<any>;
  @ViewChild('bodyTplTipo') bodyTplTipo: TemplateRef<any>;

  options = [
    { name: 'genericValues.options.true', value: true },
    { name: 'genericValues.options.false', value: false }
  ];

  columnsPriorVisualization: object[] = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'sdmDock', name: '# Doca  SDM' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'initialHour', name: 'Horário início' },
    { prop: 'finalHour', name: 'Horário término' },
    {
      prop: 'regularBlockIniHour',
      name: 'Bloqueio Regular Diário Horário início'
    },
    {
      prop: 'regularBlockFinHour',
      name: 'Bloqueio Regular Diário Horário término'
    },
    {
      prop: 'exclusiveDock',
      name: 'Doca EXCLUSIVA?',
      pipe: new BooleanConverter()
    },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'monday', name: 'Segundas-feiras', pipe: new BooleanConverter() },
    { prop: 'tuesday', name: 'Terças-feiras', pipe: new BooleanConverter() },
    { prop: 'wednesday', name: 'Quartas-feiras', pipe: new BooleanConverter() },
    { prop: 'thursday', name: 'Quintas-feiras', pipe: new BooleanConverter() },
    { prop: 'friday', name: 'Sextas-feiras', pipe: new BooleanConverter() },
    { prop: 'saturday', name: 'Sabados', pipe: new BooleanConverter() },
    { prop: 'sunday', name: 'Domingos', pipe: new BooleanConverter() }
  ];

  columnsDepositor = [
    { prop: 'id', name: '#', checkboxable: false },
    { prop: 'name', name: 'Razão social' },
    { prop: 'document', name: 'CNPJ' }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    public priorVisualizationService: PriorVisualizationService,
    private _docksServiceEvents: DocksService,
    private _regularOperationDaysService: RegularOperatingDaysService,
    private _slotService: SlotPatternService
  ) {
    super(location, toaster, translator, depositorService);
    this.keys = Object.keys(this.visualizationTypeEnum);
  }

  ngOnInit() {
    this._docksServiceEvents.emitEventTabVisualizationFired
      .pipe(take(1))
      .subscribe(() => this.grid.reloadPage());

    this.columnsPriorVisualization.splice(5, 0, {
      name: 'Tipo',
      cellTemplate: this.bodyTplTipo,
      headerTempalte: this.hdrTplTipo
    });
    this.columnsPriorVisualization.splice(6, 0, {
      name: 'Vigência',
      cellTemplate: this.bodyTplVigencia,
      headerTempalte: this.hdrTplVigencia
    });

    this.priorVisualization = new PriorVisualization();
    this.regularOperationDays = new RegularOperationgDays();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl(this.depositorId),
      depositors: new FormControl(''),
      description: new FormControl(''),
      // sdmDock: new FormControl(''),
      signatureDock: new FormControl(''),
      priorType: new FormControl(''),
      initialDate: new FormControl(''),
      finalDate: new FormControl(''),
      initialHour: new FormControl('', [Validators.required]),
      finalHour: new FormControl('', [Validators.required]),
      regularBlockIniHour: new FormControl(''),
      regularBlockFinHour: new FormControl(''),
      monday: new FormControl(this.regularOperationDays.monday),
      tuesday: new FormControl(this.regularOperationDays.tuesday),
      wednesday: new FormControl(this.regularOperationDays.wednesday),
      thursday: new FormControl(this.regularOperationDays.thursday),
      friday: new FormControl(this.regularOperationDays.friday),
      saturday: new FormControl(this.regularOperationDays.saturday),
      sunday: new FormControl(this.regularOperationDays.sunday),
      exclusiveDock: new FormControl(''),
      sequence: new FormControl(''),
      createdBy: new FormControl('')
    });

    this.formulario
      .get('finalHour')
      .setValidators(validatorFinHour('initialHour'));

    this.formulario.get('initialHour').valueChanges.subscribe(() => {
      this.formulario.get('finalHour').hasError('validFinHour');
    });

    this.formulario.get('regularBlockIniHour').valueChanges.subscribe(value => {
      if (value) {
        this.formulario
          .get('regularBlockFinHour')
          .setValidators(validatorFinHour('regularBlockIniHour'));

        this.formulario.get('regularBlockFinHour').hasError('validFinHour');
      } else {
        this.formulario.get('regularBlockFinHour').clearValidators();
        this.formulario.get('regularBlockFinHour').updateValueAndValidity();
      }
    });

    this.changesExclusiveDocksField();

    this.formulario
      .get('depositorId')
      .valueChanges.subscribe((val: Depositor[]) => {
        if (val && val.length > 0) {
          this.formulario
            .get('depositors')
            .setValue(val.map(depositor => depositor.id || depositor));
          return;
        }

        // this.formulario.get('depositors').setValue([]);
      });

    // this.formulario.get('exclusiveDock').valueChanges.subscribe(value => {
    //   if (value && this.formulario.get('establishmentId').value) {
    //     // this.depositorsListAfterInsert = this.formulario.get(
    //     //   'depositorId'
    //     // ).value;

    //     this.loadDepositorsFromUpdate(
    //       this.formulario.get('establishmentId').value
    //     );
    //     return;
    //   }

    //   this.formulario.get('depositorId').setValue([]);
    // });
  }

  submit() {
    if (this.formulario.valid) {
      // if (this.formulario.controls.hasOwnProperty('depositorId') && typeof this.formulario.get('depositorId').value === 'object') {
      //   const { id } = this.formulario.get('depositorId').value;
      //   this.formulario.get('depositorId').setValue(id);
      // }
      if (this.formulario.controls['id'].value == null) {
        this.getService()
          .create(this.formulario.getRawValue())
          .subscribe(data => {
            if (data) {
              this.formulario.patchValue(data);
              this.getGrid().reloadPage();
              this.showMessageActions();
              this.disabledForm();
            }
          });
      } else {
        this.getService()
          .edit(this.formulario.getRawValue())
          .subscribe(() => {
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
            this.disabledForm();
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  changesExclusiveDocksField() {
    this.formulario.get('exclusiveDock').valueChanges.subscribe(val => {
      if (!val || val === 'false') {
        this.removeRequiredForFields(['depositorId']);
        this.disableFields(['depositorId']);
        this.formulario.get('depositorId').setValue([]);
        return;
      }

      this.setRequiredForFields(['depositorId']);
      this.enableFields(['depositorId']);

      if (
        this.formulario.get('establishmentId').value &&
        this.formulario.get('id').value == null
      ) {
        this.loadDepositors(this.formulario.get('establishmentId').value);
      } else {
        this.loadDepositorsFromUpdate(
          this.formulario.get('establishmentId').value
        );
      }
    });
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.formulario.patchValue(this.priorVisualization);
  }

  formFillToEdit(row) {
    this.formulario.patchValue(row);
    this.depositorsListAfterInsert = row['depositorId'];
    this.formulario.enable();
    this._priorTypeStatus(row.priorType);
  }

  changeEstablishmentId(establishment) {
    if (
      !establishment ||
      this.formulario.get('exclusiveDock').value === false ||
      this.formulario.get('exclusiveDock').value === 'false'
    ) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];

      this._slotService
        .findByEstablishmentIdAndStatusTrue(establishment['id'])
        .subscribe(slot => (this.slot = slot ? slot : new SlotPattern()));

      return;
    }

    this.loadDepositors(establishment['id']);
  }

  loadDepositors(establishmentId: number) {
    this.depositorService
      .findByEstablishmentId(establishmentId)
      .subscribe(data => {
        this.depositors = [...data.body['content']];
      });
  }

  loadDepositorsFromUpdate(establishmentId: number) {
    this.depositorService
      .findByEstablishmentId(establishmentId)
      .subscribe(data => {
        this.depositors = data.body['content'];

        this.formulario
          .get('depositorId')
          .setValue(
            this.depositorsListAfterInsert.filter(dep =>
              this.depositors.indexOf(dep)
            )
          );
      });
  }

  loadRegularOperationDays(establishmentId: number) {
    this._regularOperationDaysService
      .findByEstablishmentId(establishmentId)
      .subscribe();
  }


  changePriorType(event) {
    const type = event.target.value;
    this._priorTypeStatus(type);
  }

  _priorTypeStatus(type: string) {
    if (type === 'TEMPORARIO') {
      this._setRequiredForFields(this._listOfFields);
      this._enableFields(this._listOfFields);
      return;
    }
    this._removeRequiredForFields(this._listOfFields);
    this._disableFields(this._listOfFields);
  }

  _setRequiredForFields(fields: any[]) {
    for (const field of fields) {
      this.formulario.get(field).setValidators([Validators.required]);
      this.formulario.get(field).updateValueAndValidity();
    }
  }

  _removeRequiredForFields(fields: any[]) {
    for (const field of fields) {
      this.formulario.get(field).clearValidators();
      this.formulario.get(field).updateValueAndValidity();
      this.formulario.get(field).markAsPristine();
    }
  }

  _disableFields(fields: any[]) {
    for (const field of fields) {
      this.formulario.get(field).disable();
    }
  }

  _enableFields(fields: any[]) {
    for (const field of fields) {
      this.formulario.get(field).enable();
    }
  }

  changeIniHourBlock(e) {
    this.changeHours(e, 'regularBlockIniHour');
  }

  changeFinHourBlock(e) {
    this.changeHours(e, 'regularBlockFinHour');
  }

  changeIniHour(e) {
    this.changeHours(e, 'initialHour');
  }

  changeFinHour(e) {
    this.changeHours(e, 'finalHour');
  }

  changeHours(e, name) {
    const value = e.target.value;

    if (!this.validateInput(value)) {
      return;
    }

    if (this.slot && this.slot.sizeInMinutes > 0) {
      const hourSplit = value.split(':');

      if (hourSplit[0] > 23) {
        this.formulario.get(name).setValue('');
        return;
      }

      if (hourSplit[1] >= this.slot.sizeInMinutes) {
        hourSplit[1] =
          this.slot.sizeInMinutes > 30 ? '00' : this.slot.sizeInMinutes;
      } else {
        hourSplit[1] = '00';
      }

      // if (this.slot.sizeInMinutes > 30) {
      //   hourSplit[1] = '00';
      // } else {
      //   hourSplit[1] = this.slot.sizeInMinutes;
      // }

      const hourJoin = hourSplit.join(':');

      this.formulario.get(name).setValue(hourJoin);

      // const endTime = moment(hourJoin, 'HH:mm')
      //   .add(this.slot.sizeInMinutes, 'minutes')
      //   .format('HH:mm');

      // this.formulario.get('finHour').setValue(endTime);
    }
  }

  validateInput(value): boolean {
    return value && /^([0-9]{2}:[0-9]{2})/.test(value);
  }

  endpointUpload = () => SDM_URL.docks.priorVisualization.upload;
  endPointPartial = () => SDM_URL.docks.priorVisualization.importPartial;

  endPointDepositor = () =>
    SDM_URL.depositor.findDepositorsByEstablishmentId.replace(
      ':establishmentId',
      this.establishmentID
    );

  makeEndPointForPriorVisualization = () =>
    SDM_URL.docks.priorVisualization.default;

  getNewInstance(): PriorVisualization {
    return new PriorVisualization();
  }
  getService(): AbstractService<PriorVisualization> {
    return this.priorVisualizationService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'docks.prior-visualization';
  }
}
