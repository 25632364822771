import { SDM_URL } from './../../../../app.api';
import { DepositorService } from './../../../../core/services/depositor.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-establishment-depositors',
  templateUrl: './establishment-depositors.component.html',
  styleUrls: ['./establishment-depositors.component.scss'],
})
export class EstablishmentDepositorsComponent implements OnInit {

  depositors = [];
  form: FormGroup;
  description: any;
  isReadOnly = false;
  fields = ['description'];
  @Input() depositorId: any;
  @Input() establishmentId: any;

  columns = [
    { prop: 'id', name: '#' },
    { prop: 'description', name: 'Descrição' },
    { prop: 'document', name: 'CNPJ' },
    { prop: 'establishCode', name: 'Código Localidade' },
  ];

  constructor(private _depositorService: DepositorService) { }

  ngOnInit() {
  }

  changeInputEstablishment(value) {
    if (!value) {
      this.depositorId = [];
      this.depositors = [];
      return;
    }
    this._depositorService
      .findByEstablishmentId(value['id'])
      .pipe(delay(500))
      .subscribe(data => {
        this.depositors = [...data.body['content']];
      });
  }

  makeQueryForEstablishment = () => SDM_URL.establishment.findById.replace(':id', '');
  makeQueryForAllEstablishments = () => SDM_URL.establishment.findAll;

}
