import { Pipe } from '@angular/core';

@Pipe({
    name: 'numberMeasure'
})
export class NumberMeasure {
    transform(value: any,
        decimalLength: number = 4,
        chunkDelimiter: string = '.',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';

        if (typeof value === 'string') {
            return value;
        }

        let num = (value ? parseFloat(value) : 0.0).toFixed(Math.max(0, ~~decimalLength));
        return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
    }
}