import { IDeserializable } from '../../../core/models/deserializable.model';
import { DatatableApiComponent } from '../../../components/datatable-api/datatable-api.component';
import { SDM_URL } from '../../../app.api';
import { DepositorService } from '../../../core/services/depositor.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseFormComponent } from '../../../shared/base-form/base-form.component';
import { delay } from 'rxjs/operators';
import { AbstractService } from '../../../core/services/abstract.service';
import { Subscription } from 'rxjs';

export abstract class AbstractDelivery<T extends IDeserializable> extends BaseFormComponent implements OnInit, OnDestroy {

  depositors = [];

  subscriptions: Subscription[] = [];

  columns = [
    { prop: 'id', name: '#' },
    { prop: 'description', name: 'Descrição' },
    { prop: 'document', name: 'CNPJ' },
    { prop: 'establishCode', name: 'Código Localidade' },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    private _depositorService: DepositorService) {
    super(location, toaster, translatorService);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositorId').setValue([]);
      this.depositors = [];
      return;
    }
    this.subscriptions.push(this._depositorService
      .findByEstablishmentId(establishment['id'])
      .pipe(delay(500))
      .subscribe(data => {
        this.depositors = [...data.body['content']];
        if (this.depositors.length === 1) {
          this.formulario.get('depositorId').setValue(this.depositors[0]);
        }
      }));
  }

  submitted() {
    if (this.formulario.valid) {
      if (this.formulario.controls.hasOwnProperty('depositorId') && typeof this.formulario.get('depositorId').value === 'object') {
        const { id } = this.formulario.get('depositorId').value;
        this.formulario.get('depositorId').setValue(id);
      }
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(this.getService().create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions();
          }));
      } else {
        this.subscriptions.push(this.getService().edit(this.formulario.value)
          .subscribe(() => {
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
          }));
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.controls['id'].value == null) {
        this.subscriptions.push(this.getService().create(this.listOfInstance()).subscribe(data => {
          this.formulario.patchValue(data['body'][0]);
          this.getGrid().reloadPage();
          this.showMessageActions();
          this.disabledForm();
        }));
      } else {
        this.subscriptions.push(this.getService().edit(this.formulario.value).subscribe(() => {
          this.getGrid().reloadPage();
          this.showMessageActions('success', 'update');
          this.disabledForm();
        }));
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  fillDepositorWithId() {
    if (this.formulario.controls.hasOwnProperty('depositorId') && typeof this.formulario.get('depositorId').value === 'object') {
      const { id } = this.formulario.get('depositorId').value;
      this.formulario.get('depositorId').setValue(id);
    }
  }

  listOfInstance(): T[] {
    const arrObj = this.formulario.get('depositorId').value;
    const arrParams: T[] = [];
    arrObj.forEach((depositorId, index) => {
      arrParams.push(this.getNewInstance().deserializable(this.formulario.value));
      arrParams[index]['depositorId'] = depositorId;
    });
    return arrParams;
  }

  makeQueryForEstablishment = () => SDM_URL.establishment.findById.replace(':id', '');
  makeQueryForAllEstablishments = () => SDM_URL.establishment.findAll;

  abstract getNewInstance(): T;
  abstract getService(): AbstractService<T>;
  abstract getGrid(): DatatableApiComponent;

}
