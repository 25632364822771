import { Location } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { SelectFieldComponent } from '../../../../../../components/select/select-field/select-field.component';
import { AbstractService } from '../../../../../../core/services/abstract.service';
import { ProductivityGroupService } from '../../../../../../core/services/sdm/provider-management/productivity-group.service';
import { TranslatorService } from '../../../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../../abstract-register';
import { ProviderManagement } from '../../../provider-management.service';
import { SDM_URL } from './../../../../../../app.api';
import { DatatableApiComponent } from './../../../../../../components/datatable-api/datatable-api.component';
import { Establishment } from './../../../../../../core/models/sdm/establishment';
import { CompatibilityDocks } from './../../../../../../core/models/sdm/provider-management/compatibility-docks';
import { CompatibilityDocksGroups } from './../../../../../../core/models/sdm/provider-management/compatibility-docks-groups';
import { AuthService } from './../../../../../../core/services/auth.service';
import { DepositorService } from './../../../../../../core/services/depositor.service';
import { EstablishmentService } from './../../../../../../core/services/establishment.service';
import { PriorVisualizationService } from './../../../../../../core/services/sdm/docks/prior-visualization.service';
import { CompatibilityDocksService } from './../../../../../../core/services/sdm/provider-management/groups-compatibility/compatibility-docks.service';

@Component({
  selector: 'app-compatibility-docks-form',
  templateUrl: './compatibility-docks-form.component.html',
  styleUrls: ['./compatibility-docks-form.component.scss']
})
export class CompatibilityDocksFormComponent
  extends AbstractRegister<CompatibilityDocks>
  implements OnInit {
  selectedDocks = [];
  compatibilityDocksId = null;
  productivityGroups: any[] = [];
  establishmentId = 0;
  establishments: Establishment[] = [];

  @ViewChild('gridCompatibilityDocks') grid$: DatatableApiComponent;
  @ViewChild('gridCompatibilityDocksGroups') gridGroups$: DatatableApiComponent;
  @ViewChild('gridProductivityGroup')
  gridProductivityGroup$: DatatableApiComponent;

  @ViewChild('gridDocks')
  gridDocks: DatatableApiComponent;

  @ViewChild('hdrTplActiveDeactive') hdrTplActiveDeactive: TemplateRef<any>;
  @ViewChild('bodyTplActiveDeactive') bodyTplActiveDeactive: TemplateRef<any>;
  @ViewChild('inputEstablishment') inputEstablishment: SelectFieldComponent;

  columnsCompatibilityDocks = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'establishmentId', name: '# Localidade' },
    { prop: 'establishment.description', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' }
  ];

  columnsDocks = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'shortName', name: 'Doca Assinante' }
  ];

  columnsProductivityGroup = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'groupName', name: 'Nome do Grupo', width: 250 }
  ];

  columnsProductivityGroupCompatDocks: object[] = [
    { prop: 'id', name: '#', width: 60 },
    { prop: 'productivityGroup.groupName', name: 'Nome do Grupo', width: 250 }
  ];

  columnsPriorVisualization = [
    { prop: 'id', name: '# Doca SDM' },
    { prop: 'signatureDock', name: '# Doca Assinante' },
    { prop: 'initialHour', name: 'Horário início' },
    { prop: 'finalHour', name: 'Horário término' }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    public _compatibilityDocksService: CompatibilityDocksService,
    private _providerManagementService: ProviderManagement,
    private _productivityGroupService: ProductivityGroupService,
    private _priorVisualizationService: PriorVisualizationService,
    private _establishmentService: EstablishmentService,
    private _authService: AuthService
  ) {
    super(location, toaster, translatorService, depositorService);
  }

  ngOnInit() {
    this._providerManagementService.emitEventWhenTabCompatibilityDocks
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());

    this.columnsProductivityGroupCompatDocks.splice(5, 0, {
      name: 'Ação',
      cellTemplate: this.bodyTplActiveDeactive,
      headerTempalte: this.hdrTplActiveDeactive,
      width: 80
    });

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      // description: new FormControl(''),
      // signatureDock: new FormControl('', [Validators.required]),
      docksGroups: new FormControl(''),
      productivityGroup: new FormControl('')
    });

    this.loadEstablishments();
  }

  loadEstablishments() {
    const { id } = this._authService.getUser();

    this._establishmentService.findByUserId(id).subscribe(data => {
      
      this.establishments = data;

      if (this.establishments.length === 1) {
        this.formulario.get('establishmentId').setValue(this.establishments[0]);
        this.changeEstablishmentId(
          this.formulario.get('establishmentId').value
        );
      }
    });
  }

  submit() {
    if (this.formulario.valid) {
      if (!this.selectedDocks.length) {
        this.toaster.info('Selecione a(s) Doca(s)', 'SDM');
        return;
      }
      // this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.getService()
          .create(this.formulario.value)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.grid$.reloadPage();
            this.showMessageActions();
            this.compatibilityDocksId = data.id;
            // setTimeout(() => this.gridGroups$.reloadPage(), 1);
            // this.gridProductivityGroup$.selected = [];
            this.selectedDocks = [];
          });
      } else {
        this.getService()
          .edit(this.formulario.value)
          .subscribe(() => {
            this.disabledForm();
            this.grid$.reloadPage();
            this.showMessageActions('success', 'update');
            this.compatibilityDocksId = this.formulario.get('id').value;
            // setTimeout(() => this.gridGroups$.reloadPage(), 1);
            // this.gridProductivityGroup$.selected = [];
            this.selectedDocks = [];
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  resetar() {
    this.formulario.reset();
    this.formulario.disable();
    this.formEnabled = false;
    this.clearGridData();

    this.clearGridDocks();
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();
    this.clearGridData();
    this.inputEstablishment.items = this.establishments;
  }

  clearGridData() {
    this.compatibilityDocksId = -1;
    this.establishmentId = 0;
    // setTimeout(() => this.gridGroups$.reloadPage(), 1);
    // this.gridProductivityGroup$.selected = [];
    this.selectedDocks = [];
    this.gridDocks.selected = [];
    setTimeout(() => this.gridDocks.reloadPage(), 1);
  }

  selectedDocksGroup(selected) {
    if (!this.formulario.get('productivityGroup').value) {
      return;
    }

    
    this.selectedDocks = selected;
    this.formulario
      .get('docksGroups')
      .setValue(
        this.selectedDocks.map(
          sel =>
            new CompatibilityDocksGroups(
              this.formulario.get('productivityGroup').value.id,
              sel.id
            )
        )
      );

    
  }

  formFillToEdit(row) {
    
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.enable();
    // this.compatibilityDocksId = row.id;
    this.establishmentId = row['establishmentId'];
    setTimeout(() => this.gridDocks.reloadPage(), 1);

    this.gridDocks.selected = row['docksGroups'].map(
      data => data['priorVisualization']
    );
  }

  formFillToView(row) {
    this.formulario.patchValue(row);
    if (
      row.hasOwnProperty('depositor') &&
      row['depositor'].hasOwnProperty('establishment')
    ) {
      if (
        this.formulario.get('depositorId') &&
        this.formulario.get('establishmentId')
      ) {
        this.formulario.get('depositorId').setValue(row['depositor'].id);
        this.formulario
          .get('establishmentId')
          .setValue(row['depositor']['establishment'].id);
      }
    }
    this.formulario.disable();
    // this.compatibilityDocksId = row.id;
    setTimeout(() => {
      this.establishmentId = row['establishmentId'];
      this.gridDocks.reloadPage();
    }, 1);

    this.gridDocks.selected = row['docksGroups'].map(
      data => data['priorVisualization']
    );
  }

  actInativeGroup(row) {
    const { id } = row;
    this._compatibilityDocksService
      .activeDeactiveGroup(this.compatibilityDocksId, id)
      .subscribe(data => this.gridGroups$.reloadPage());
  }

  endPointCompatibilityDocks = () =>
    SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.default;
  endpointUpload = () =>
    SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.upload;
  endPointPartial = () =>
    SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks
      .importPartial;
  endPointGetAllGroups = () =>
    SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.getAllGroups.replace(
      ':compatibilityDocksId',
      this.compatibilityDocksId
    );
  endPointGroup = () =>
    SDM_URL.providerManagement.groupsCompatibility.compatibilityDocks.activeDeactiveGroup
      .replace(':compatibilityDocksId', this.compatibilityDocksId)
      .replace(':compatibilityDocksGroupId', '');

  queryProviderDocument = () =>
    SDM_URL.providerManagement.priorityProviders.findByDocument.replace(
      ':document',
      ''
    );
  makeQueryForAllProviders = () => SDM_URL.providerManagement.provider.default;

  queryDocks = () =>
    SDM_URL.docks.priorVisualization.findBySignatureDock.replace(
      ':signatureDock',
      ''
    );

  changeDepositor(depositor) {
    this.productivityGroups = [];
    this.formulario.get('productivityGroup').setValue([]);

    
    if (depositor) {
      this._productivityGroupService
        .findByDepositorIdAndStatus(depositor.id)
        .subscribe((data: any) => {
          
          this.productivityGroups = data.body;

          if (this.productivityGroups && this.productivityGroups.length === 1) {
            this.formulario
              .get('productivityGroup')
              .setValue(this.productivityGroups[0]);
          }
        });
    } else {
      this.productivityGroups = [];
      this.formulario.get('productivityGroup').setValue([]);
    }
  }

  changeEstablishmentId(establishment) {
    if (!establishment) {
      this.formulario.get('depositor').setValue([]);
      this.formulario.get('productivityGroup').setValue([]);
      this.clearGridDocks();
      this.depositors = [];
      this.productivityGroups = [];
      return;
    }

    this.establishmentId = establishment['id'];

    setTimeout(() => this.gridDocks.reloadPage(), 1);

    this.subscriptions.push(
      this.depositorService
        .findByEstablishmentId(establishment['id'])
        .subscribe(data => {
          if (this.formulario.controls.hasOwnProperty('depositor')) {
            this.formulario.get('depositor').setValue('');
          } else {
            this.formulario.get('depositorId').setValue('');
          }
          this.depositors = [...data.body['content']];
          
          if (this.depositors.length === 1) {
            if (this.formulario.controls.hasOwnProperty('depositor')) {
              this.formulario.get('depositor').setValue(this.depositors[0]);
              this.changeDepositor(this.depositors[0]);
            } else {
              this.formulario.get('depositorId').setValue(this.depositors[0]);
            }
          }
        })
    );
  }

  onActivated(e) {
    
    if (e.type == 'click' && !this.formulario.get('productivityGroup').value) {
      this.toaster.info('Selecione o Grupo Produtividade', 'SDM');
      e.event.preventDefault();
      this.selectedDocks = [];
      this.gridDocks.selected = [];
    }
  }

  makeQueryForAllDocks = () => SDM_URL.docks.priorVisualization.default;

  getAllDocks = () =>
    SDM_URL.docks.priorVisualization.findByEstablishmentIdAndStatusTrue.replace(
      ':establishmentId',
      this.establishmentId.toString()
    );

  endPointProductivityGroup = () =>
    SDM_URL.providerManagement.docksDimensioningTime.productivityGroup.default;

  private clearGridDocks() {
    this.establishmentId = 0;
    this.gridDocks.selected = [];
    setTimeout(() => this.gridDocks.reloadPage(), 1);
  }

  getNewInstance(): CompatibilityDocks {
    return new CompatibilityDocks();
  }
  getService(): AbstractService<CompatibilityDocks> {
    return this._compatibilityDocksService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'provider-management.groups-compatibility.compatibility-docks';
  }
}
