import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
})
export class StatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 0:
        return 'Enviado';
      case 1:
        return 'Não enviado';
      case 2:
        return 'Reenviado';
    }
  }

}
