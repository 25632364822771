const SBAW = {
  roles: [],
  integration: true,
  authority: 'USER',
  solution: 'SBAW',
  text: 'Smart Pedidos',
  link: '/sbaw/home',
  icon: 'icon-home',
};

const SDM = {
  roles: [],
  integration: true,
  authority: 'USER',
  solution: 'SDM',
  text: 'S.D.M.',
  link: '/sdm/home',
  icon: 'icon-home',
};

const componenteGav = {
  roles: [],
  integration: true,
  authority: 'USER',
  solution: 'GATEWAY',
  text: 'Componente Gav',
  link: '/sdm/register/gestao-vista-sdm',
  icon: 'icon-graph',
};

const headingMain = {
  roles: [],
  integration: true,
  authority: 'USER',
  solution: 'GATEWAY',
  text: 'Smart2Log',
  heading: true
};

const smart = {
  roles: [],
  integration: true,
  authority: 'ADMIN',
  solution: 'GATEWAY',
  text: 'Portal Smart',
  link: '#',
  icon: 'fa fa-gears',
  submenu: [
    {
      roles: [],
      integration: true,
      solution: 'GATEWAY',
      authority: 'ADMIN',
      text: 'Localidades',
      link: '/gateway/establishment',
      icon: 'fa fa-university',
      name: 'Establishment',
    },
    {
      roles: [],
      integration: true,
      solution: 'GATEWAY',
      authority: 'ADMIN',
      text: 'Assinaturas',
      link: '/gateway/signature',
      icon: 'fa fa-pencil-square-o',
    },
    {
      roles: [],
      integration: true,
      authority: 'SUPER',
      solution: 'GATEWAY',
      text: 'Domínios',
      link: '/gateway/smart-domain',
      icon: 'fa fa-home',
      name: 'Domain',
    },
    {
      roles: [],
      integration: true,
      authority: 'ADMIN',
      solution: 'GATEWAY',
      text: 'Usuários',
      link: '/gateway/user-domain',
      icon: 'fa fa-user-circle-o',
      name: 'User_Gateway',
    },
    {
      roles: [],
      integration: true,
      authority: 'ADMIN',
      solution: 'GATEWAY',
      text: 'Agentes',
      link: '/gateway/agent-type',
      icon: 'fa fa-users',
    },
    {
      roles: [],
      integration: true,
      authority: 'ADMIN',
      solution: 'GATEWAY',
      text: 'E-mails e SMS',
      link: '/gateway/email-sms-agent',
      icon: 'fa fa-envelope-o',
    },
    // {
    //   roles: [],
    //   integration: true,
    //   authority: 'ADMIN',
    //   solution: 'GATEWAY',
    //   text: 'VIP e Black-List',
    //   link: 'vip-blacklist-agent',
    //   icon: 'fa fa-child'
    // },
    {
      roles: [],
      integration: true,
      authority: 'USER',
      solution: 'GATEWAY',
      text: 'E-mail e SMS',
      link: '/gateway/mailer',
      icon: 'fa fa-envelope'
    },
    {
      roles: [],
      integration: true,
      authority: 'USER',
      solution: 'GATEWAY',
      text: 'Red Alert & VIP',
      link: '/gateway/blacklist-vip-replace',
      icon: 'fa fa-child',
      name: 'Blacklist_Vip'
    },
    {
      roles: [],
      integration: true,
      authority: 'USER',
      solution: 'GATEWAY',
      text: 'Produto',
      link: '/gateway/product-replace',
      icon: 'fa fa-cubes',
      name: 'Product'
    },
    // {
    //   roles: [],
    //   integration: true,
    //   authority: 'USER',
    //   solution: 'GATEWAY',
    //   text: 'Sequenciamento',
    //   link: '/gateway/picking-sequence-replace',
    //   icon: 'fa fa-sort',
    //   name: 'Picking_Sequence'
    // },
    {
      roles: [],
      integration: true,
      authority: 'ADMIN',
      solution: 'GATEWAY',
      text: 'Gestão Supervisores',
      link: '/gateway/management-supervisor',
      icon: 'fa fa-lock'
    }
  ]
};

export const menuGateway = [headingMain, SBAW, SDM, smart];
