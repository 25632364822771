import { Component, OnInit } from '@angular/core';
import { ProviderManagement } from '../../provider-management.service';

@Component({
  selector: 'app-docks-dimensioning-form',
  templateUrl: './docks-dimensioning-form.component.html',
  styleUrls: ['./docks-dimensioning-form.component.scss'],
})
export class DocksDimensioningFormComponent implements OnInit {

  constructor(private _providerManagementService: ProviderManagement) { }

  ngOnInit() {
  }

  onSelectTab(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabProductivityPattern':
        this._providerManagementService.tabDocksDimensioningProductivityPatternFired();
        break;
    }
  }

}
