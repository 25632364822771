import { HttpHeaders } from "@angular/common/http";

export class BaseService {

    public httpOptions = {
        headers: new HttpHeaders(
            {
                'Content-Type': 'application/json'
            }
        )
    }

    public httpOptionsObserveResponse = {
        observe: 'response' as 'response'
    }

}