import { DeliveryAuthorizationEnum } from './../../../../core/models/sdm/shared/enums/DeliveryAuthorizationEnum';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { DeliveryAuthorization } from '../../../../core/models/sdm/delivery-authorization/delivery-authorization';
import { PriorVisualization } from '../../../../core/models/sdm/docks/prior-visualization';
import { DocksScheduling } from '../../../../core/models/sdm/document/docks-scheduling';
import { DeliveryAuthorizationService } from '../../../../core/services/sdm/delivery-authorization/delivery-authorization.service';
import { PriorVisualizationService } from '../../../../core/services/sdm/docks/prior-visualization.service';
import { RescheduleValidationTime } from './../../../../core/models/sdm/delivery-authorization/reschedule-validation-time';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { Provider } from './../../../../core/models/sdm/provider-management/provider';

@Component({
  selector: 'app-reschedule-term-validation',
  templateUrl: './reschedule-term-validation.component.html',
  styleUrls: ['./reschedule-term-validation.component.scss'],
  preserveWhitespaces: true
})
export class RescheduleTermValidationComponent implements OnInit {
  depositor: Depositor;
  depositors: Depositor[] = [];
  providers: Provider[] = [];
  formRescheduleTermValidation: FormGroup;
  deliveryAuthorization: DeliveryAuthorization;
  authorizations = [];

  alerts: any = [];
  dismissible = true;
  formEnabled = true;
  goBack;
  docks: PriorVisualization[] = [];

  dockSchedule: DocksScheduling;

  allowReschedule = true;

  constructor(
    sanitizer: DomSanitizer,
    private _router: Router,
    private _actvRouter: ActivatedRoute,
    private _deliveryAuthorizationService: DeliveryAuthorizationService,
    private _docksService: PriorVisualizationService
  ) {
    this.alerts = this.alerts.map((alert: any) => ({
      type: alert.type,
      msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
    }));
  }

  ngOnInit() {
    this.formRescheduleTermValidation = new FormGroup({
      depositor: new FormControl(''),
      providerId: new FormControl(''),
      authorizationkey: new FormControl(''),
      validityDate: new FormControl(''),
      rescheduleValidityDate: new FormControl(''),
      dateScheduleDelivery: new FormControl(''),
      carShowSchedule: new FormControl(''),
      initialHour: new FormControl(''),
      finalHour: new FormControl(''),
      priorVisualization: new FormControl(''),
      qtdSlots: new FormControl(''),
      authorization: new FormControl('')
    });

    this._actvRouter.queryParams.subscribe((params: any) => {
      try {
        this.deliveryAuthorization = JSON.parse(
          params['deliveryAuthorization']
        );
        this.authorizations = [this.deliveryAuthorization];

        this.formRescheduleTermValidation
          .get('authorization')
          .setValue(this.authorizations[0]);
      } catch (e) {
        console.error(e);
      }

      this.getDepositorFromQueryParams(this.deliveryAuthorization);
      this.getProvidersFromQueryParams(this.deliveryAuthorization['documents']);
    });
  }

  getDepositorFromQueryParams(deliveryAuthorization: DeliveryAuthorization) {
    try {
      this.depositor = deliveryAuthorization['depositor'];
      this.depositors = [this.depositor];
      this.formRescheduleTermValidation
        .get('depositor')
        .setValue(this.depositor);

      this.getDocksScheduling(this.depositor.id, deliveryAuthorization.id);
    } catch (e) {
      console.error(e);
    }
  }

  getDocksScheduling(depositorId: number, deliveryAuthorizationId: number) {
    this._deliveryAuthorizationService
      .findDocksScheduling(depositorId, deliveryAuthorizationId)
      .subscribe((data: RescheduleValidationTime) => {
        

        if (data) {
          this.formRescheduleTermValidation.patchValue(data);

          if (data.docksScheduling != null) {
            this.formRescheduleTermValidation.patchValue(data.docksScheduling);

            this.docks = [data.priorVisualization];
            this.dockSchedule = data.docksScheduling;

            this.formRescheduleTermValidation
              .get('priorVisualization')
              .setValue(this.docks[0]);
          } else {
            this.formRescheduleTermValidation
              .get('depositor')
              .setValue(this.depositor);
          }
        }
      });
  }

  getProvidersFromQueryParams(documents: any[]) {
    try {
      this.providers = documents.map(document => document['provider']);

      if (this.providers.length === 1) {
        this.formRescheduleTermValidation
          .get('providerId')
          .setValue(this.providers[0]);
      }

      
    } catch (e) {
      console.error(e);
    }
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }

  alertMsg(message?: string, type = 'success') {
    this.alerts.push({
      type: `${type}`,
      msg: `<strong>${message}</strong>`
      // timeout: 2500,
    });
  }

  queryParams(
    docksScheduling?: DocksScheduling[],
    keepDataWhenReturnToBack?: any,
    dockSchedule?: DocksScheduling
  ) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        formValues: JSON.stringify(this.formRescheduleTermValidation.value),
        provider: JSON.stringify(this.providers)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    if (docksScheduling && docksScheduling.length) {
      navigationExtras.queryParams.docksScheduling = JSON.stringify(
        docksScheduling
      );
    }

    if (keepDataWhenReturnToBack) {
      navigationExtras.queryParams.deliveryAuthorization = JSON.stringify(
        keepDataWhenReturnToBack
      );
    }

    if (dockSchedule) {
      navigationExtras.queryParams.dockSchedule = JSON.stringify(dockSchedule);
    }

    return navigationExtras;
  }

  consultSlots() {
    const depositor = <Depositor>(
      this.formRescheduleTermValidation.get('depositor').value
    );
    const qtdSlots = this.formRescheduleTermValidation.get('qtdSlots').value;
    const validityDate = this.formRescheduleTermValidation.get('validityDate')
      .value;

    const status = Object.keys(DeliveryAuthorizationEnum).filter(
      value => value == this.deliveryAuthorization.statusAuthorization
    )[0];

    this._docksService
      .findByDocksAvailable(
        depositor.id,
        null,
        moment(validityDate).format('YYYY-MM-DD'),
        depositor.establishment.id,
        qtdSlots,
        parseInt(DeliveryAuthorizationEnum[status]),
        this.deliveryAuthorization.risk,
        this.deliveryAuthorization.id
      )
      .subscribe(
        (data: DocksScheduling[]) => {
          if (data && data.length) {
            
            const docks = data;

            if (docks.length > 1) {
              this._router.navigate(
                ['sdm/delivery-authorization/edit/reschedule-various-option'],
                this.queryParams(
                  docks,
                  this.deliveryAuthorization,
                  this.dockSchedule
                )
              );
            } else {
              this._router.navigate(
                ['sdm/delivery-authorization/edit/reschedule-unique-option'],
                this.queryParams(
                  docks,
                  this.deliveryAuthorization,
                  this.dockSchedule
                )
              );
            }
          } else {
            this.alertMsg(
              'Infelizmente não foi possível encontrar outras janelas compatíveis com a necessária. Agendamento será mantido para o mesmo dia e horário já confirmados.',
              'info'
            );
          }
        },
        ({ error }) => {
                    this.alertMsg(error, 'warning');
        }
      );
  }

  exit() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      skipLocationChange: true
    };

    this._router.navigate(
      ['sdm/delivery-authorization/edit/reschedule-approved-selection'],
      navigationExtras
    );
  }
}
