import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IModalDialogButton, ModalDialogService } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Page } from '../../core/models/page';
import { ModalConfirmComponent } from '../../shared/libraries/modal-confirm/modal-confirm.component';
import { DatatableApiService } from './datatable-api.service';

@Component({
  selector: 'app-datatable-api',
  templateUrl: './datatable-api.component.html',
  styleUrls: ['./datatable-api.component.scss']
})
export class DatatableApiComponent implements OnInit, OnChanges {
  private IsfirstRequest = true;
  private _endPoint = '';
  selected = [];
  modalRef: BsModalRef;

  @Input() public rowClass;

  @Input()
  set endpoint(endpoint: string) {
    this._endPoint = endpoint;

    if (this.endPointAction == '') {
      this.endPointAction = endpoint;
    }
  }

  get endpoint() {
    return this._endPoint;
  }

  @Input() public showReload: boolean = false;

  @Input() public endPointAction = this.endpoint;
  @Input() public showSummary: boolean = false;
  @Input() public columns: object[];
  @Input() public keyfield = '';
  @Input() public selectionType = 'single';

  set endpointTeste(url: any) {
    this.endpoint = url;
  }

  @Input() public actButtons = false;
  @Input() public actView = false;
  @Input() public actEdit = false;
  @Input() public actDelete = false;

  @Input() public customActionButtons = false;
  @Input() public actViewCustom = false;
  @Input() public actEditCustom = false;
  @Input() public actInactive = false;

  @Input() public viewStatus = false;

  @Input() public baseRoute = '';

  public data: any[];

  public page: Page;
  public summary: any;
  public loading = false;

  @ViewChild('actTpl') actTpl: TemplateRef<any>;
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;

  @ViewChild('actTplCustom') actTplCustom: TemplateRef<any>;

  @Output() formFill = new EventEmitter();
  @Output() formFillView = new EventEmitter();

  @Output() deleted = new EventEmitter();
  @Output() switchStatus = new EventEmitter();
  @Output() whenDblClick = new EventEmitter();
  @Output() onActive = new EventEmitter();

  @ViewChild('statusTpl') statusTpl: TemplateRef<any>;
  @ViewChild('hdrStatusTpl') hdrStatusTpl: TemplateRef<any>;

  @Output() selectRow = new EventEmitter();

  @Input() isAutoLoad = true;
  actionButtons: IModalDialogButton[];
  actionButtonsModalDisableData: IModalDialogButton[];

  private _id: number = null;
  private _status: boolean;


  private _translator: any;

  constructor(
    private http: HttpClient,
    private modalService: ModalDialogService,
    private viewRef: ViewContainerRef,
    private toastr: ToastrService,
    private translate: TranslateService,
    private _modalService: BsModalService,
    private _dataTableApiService: DatatableApiService
  ) {
    this.page = new Page();

    this.actionButtons = [
      {
        text: 'Sim',
        onAction: () => {
          this.delete();
          return true;
        },
        buttonClass: 'btn btn-outline-danger'
      },
      { text: 'Não', buttonClass: 'btn btn-info', onAction: () => true }
    ];

    this.actionButtonsModalDisableData = [
      {
        text: 'Sim',
        onAction: () => {
          return true;
        },
        buttonClass: 'btn btn-outline-danger'
      },
      { text: 'Não', buttonClass: 'btn btn-info', onAction: () => true }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const endpoint: SimpleChange = changes.endpoint;
    this._endPoint = endpoint.currentValue;

    this.makeEndPoint(this._endPoint);
    this.page.generateURL();

    if (this.isAutoLoad) {
      if (!this.IsfirstRequest) {
        setTimeout(() => this.reloadPage(), 1);
      }
    }
  }

  ngOnInit() {
    this.addViewBadgeStatus();
    this.addActButtons();
    this.addCustomActButtons();
    this.makeEndPoint(this._endPoint);
    this.page.sortName = this.keyfield;

    if (this.isAutoLoad) {
      this.reloadPage();

    }
  }

  addActButtons() {
    if (this.actButtons) {
      this.columns.push({
        name: 'Ações',
        cellTemplate: this.actTpl,
        headerTemplate: this.hdrTpl
      });
    }
  }

  addCustomActButtons() {
    if (this.customActionButtons) {
      this.columns.push({
        name: 'Ações',
        cellTemplate: this.actTplCustom,
        headerTemplate: this.hdrTpl
      });
    }
  }

  addViewBadgeStatus() {
    if (this.viewStatus) {
      this.columns.push({
        prop: 'status',
        name: 'Status',
        cellTemplate: this.statusTpl,
        headerTemplate: this.hdrStatusTpl
      });
    }
  }

  makeEndPoint(endPoint) {
    if (endPoint) this.page.endpoint = `${environment.host.sdm}${endPoint}`;
  }


  reloadPage() {
    const observe = this.http
      .get(this.page.generateURL())
      .subscribe((data: object[]) => {
        this.page.size = null;
        this.summary = null;
        this.page.totalElements = 0;
        this.page.pageNumber = null;
        if (data['pageable']) {
          this.summary = data['pageable']['exportTableIndicators'] || null;
          this.page.size = data['pageable']['size'] || null;
          this.page.totalElements = data['pageable']['totalElements'] || 0;
          this.page.pageNumber = data['pageable']['pageNumber'] || null;
        }
        this.data = data['content'];

        this.loading = false;
        observe.unsubscribe();
      });
  }


  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loading = true;
    const observe = this.http
      .get(this.page.generateURL())
      .subscribe((data: object[]) => {
        this.data = data['content'];
        this.page.totalElements = data['pageable']['totalElements'] || null;
        this.page.size = data['pageable']['size'] || data['size'];
        this.page.pageNumber = data['pageable']['pageNumber'] || null;
        observe.unsubscribe();
      });
  }

  resetPage() {
    this.loading = true;
    const observe = this.http
      .get(this.page.generateURL())
      .subscribe((data: object[]) => {
        this.data = data['content'];
        this.page.totalElements = data['pageable']['totalElements'] || null;
        this.page.size = data['pageable']['size'] || data['size'];
        this.page.pageNumber = data['pageable']['pageNumber'] || null;
        observe.unsubscribe();
      });
  }

  sortCallback(event) {
    this.page.sortOrder = event.sorts[0].dir;
    this.page.sortName = event.sorts[0].prop;
    this.reloadPage();
  }

  delete() {
    this.http
      .delete(`${environment.host.sdm}${this.endPointAction}/${this._id}`)
      .subscribe(() => {
        this.toastr.success(`#${this._id} excluído com sucesso!`);
        this.data = this.data.filter(obj => obj.id !== this._id);
        this.deleted.emit(this._id);
      });
  }

  openDialog(id) {
    this._id = id;
    this.modalService.openDialog(this.viewRef, {
      actionButtons: this.actionButtons,
      childComponent: ModalConfirmComponent
    });
  }

  openDialogForActiveOrInactive({ id, status }) {
    this._id = id;
    this._status = status;
    this.modalService.openDialog(this.viewRef, {
      actionButtons: this.actionButtonsModalDisableData,
      childComponent: ModalConfirmComponent
    });
  }

  selectButton(row) {
    this._dataTableApiService.selectedGridRow(row);
    this.formFill.emit(row);
  }

  selectButtonForView(row) {
    this._dataTableApiService.selectedGridRow(row);
    this.formFillView.emit(row);
  }

  onSelect({ selected }) {
    if (/multi|multiClick/gi.test(this.selectionType)) {

      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);

      this.selectRow.emit(this.selected);
      return;
    }

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.selectRow.emit(this.selected[0]);
  }

  openModal(template: TemplateRef<any>, { id, status }) {
    this._id = id;
    this.modalRef = this._modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-sm' })
    );
  }

  closeModal() {
    this.modalRef.hide();
  }

  confirm() {
    this.delete();
    this.modalRef.hide();
  }

  decline() {
    this.modalRef.hide();
  }

  swicthActive(row) {
    let url = this.endPointAction.split('?');
    this.http
      .delete(
        `${environment.host.sdm}${url[0]}/activeDeactive/${row.id}${url.length > 1 ? '?' + url[1] : ''
        }`,
        { observe: 'response' }
      )
      .subscribe(res => {

        this.reloadPage();
        this.switchStatus.emit(row);
      });
  }

  onActivate(event) {
    this.onActive.emit(event);
  }

  clearData() {
    this.data = [];
  }

  getId(row) {
    return row ? row.id : null;
  }

  whenDoubleClicked(e) {
    this.whenDblClick.emit(this.selected);
  }
}











