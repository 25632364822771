import { Observable } from 'rxjs';
import { ReverseDeliverySmallSize } from './../../../models/sdm/reverse-delivery/reverse-delivery-small-size';
import { environment as env } from './../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../abstract.service';
import { SDM_URL } from '../../../../app.api';

@Injectable({
  providedIn: 'root',
})
export class ReverseDeliverySmallSizeService extends AbstractService<ReverseDeliverySmallSize> {

  constructor(public http: HttpClient) {
    super(http, env.host.sdm, SDM_URL.reverseDelivery.smallSize.default);
  }

  createReverseDeliverySmallSize(model: ReverseDeliverySmallSize[]): Observable<ReverseDeliverySmallSize> {
    return this.http.post<ReverseDeliverySmallSize>(SDM_URL.reverseDelivery.smallSize.create, model);
  }
}
