import { CharacterType } from './../../../../../core/models/sdm/shared/enums/CharacterType';
import { SDM_URL } from './../../../../../app.api';
import { take } from 'rxjs/operators';
import { CollectRmaService } from './../../../../../core/services/sdm/collect/collect-rma.service';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractService } from '../../../../../core/services/abstract.service';
import { TranslatorService } from '../../../../../core/translator/translator.service';
import { AbstractRegister } from '../../../../register/abstract-register';
import { DatatableApiComponent } from './../../../../../components/datatable-api/datatable-api.component';
import { CollectRmAuthorization } from './../../../../../core/models/sdm/collect/collect-rma';
import { DepositorService } from './../../../../../core/services/depositor.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CollectService as CollectEvents } from '../../collect.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-collect-rma-form',
  templateUrl: './collect-rma-form.component.html',
  styleUrls: ['./collect-rma-form.component.scss'],
})
export class CollectRmaFormComponent extends AbstractRegister<CollectRmAuthorization> implements OnInit {

  keys: any;
  sub: Subscription;
  rmaRequired = false;
  characterTypes = CharacterType;
  collectRma: CollectRmAuthorization;
  @ViewChild('dataGrdCollectRma') grid$: DatatableApiComponent;

  columnsRma = [
    { prop: 'id', name: '#' },
    { prop: 'establishment.id', name: 'Código Estabelecimento' },
    { prop: 'establishment.description', name: 'Estabelecimento' },
    { prop: 'depositor.id', name: 'Código Depositante' },
    { prop: 'depositor.detailName', name: 'Depositante' },
  ];

  options = [
    { name: 'reverse-delivery.rma.options.true', value: true },
    { name: 'reverse-delivery.rma.options.false', value: false },
  ];

  optionsDefaultNOK = [
    { name: 'reverse-delivery.rma.options.false', value: false },
  ];

  optionsDefaultOK = [
    { name: 'reverse-delivery.rma.options.true', value: true },
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translatorService: TranslatorService,
    public depositorService: DepositorService,
    private _collectRmaService: CollectRmaService,
    private _collectEvents: CollectEvents,
  ) {
    super(location, toaster, translatorService, depositorService);
    this.keys = Object.keys(this.characterTypes);
  }

  ngOnInit() {
    this.tabRmaFired();
    this.collectRma = new CollectRmAuthorization();

    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      depositor: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      requiredRma: new FormControl(this.collectRma.requiredRma),
      size: new FormControl(''),
      rmaType: new FormControl(this.collectRma.rmaType),
      allowRepeat: new FormControl(this.collectRma.allowRepeat),

      documentSender: new FormControl(this.collectRma.documentSender),
      documentRecipient: new FormControl(this.collectRma.documentRecipient),
      documentShipping: new FormControl(this.collectRma.documentShipping),

      qtdLines: new FormControl(this.collectRma.qtdLines),
      qtdUnitOrParts: new FormControl(this.collectRma.qtdUnitOrParts),
      qtdVolume: new FormControl(this.collectRma.qtdVolume),
      totalValue: new FormControl(this.collectRma.totalValue),
      totalGrossWeight: new FormControl(this.collectRma.totalGrossWeight),
      totalNetWeight: new FormControl(this.collectRma.totalNetWeight),
      qtdPallet: new FormControl(this.collectRma.qtdPallet),
      qtdCubicMeters: new FormControl(this.collectRma.qtdCubicMeters),
    });

    this.formulario.get('depositor').valueChanges
    .subscribe(value => {
      
      if (value) {
        this.formulario.get('depositorId').setValue(value.id);
        return;
      }

      this.formulario.get('depositorId').setValue('');
    })
  }

  tabRmaFired() {
    this.sub = this._collectEvents.emitTabRmaFired
      .pipe(take(1))
      .subscribe(() => this.grid$.reloadPage());
  }

  afterClickButtonAdd() {
    this.formulario.patchValue(this.collectRma);
    this.statusRmaRequired();
  }

  rmaIsRequired = () => this.formulario.get('requiredRma').value === 'true';

  changeRequiredRma(event) {
    this.statusRmaRequired();
  }

  statusRmaRequired() {
    if (this.formulario.get('requiredRma').value === 'true' || this.formulario.get('requiredRma').value === true) {
      this.setRequiredForFields(['size', 'rmaType', 'allowRepeat']);
      this.enableFields(['size', 'rmaType', 'allowRepeat']);
      return;
    }
    this.removeRequiredForFields(['size', 'rmaType', 'allowRepeat']);
    this.disableFields(['size', 'rmaType', 'allowRepeat']);
  }

  submit() {
    if (this.formulario.valid) {
      const formValue = this.formulario.getRawValue();
      if (formValue['rmaType'] === '') {
        formValue['rmaType'] = 'DEFAULT';
      }
      if (this.formulario.get('id').value == null) {
        this.getService().create(formValue)
          .subscribe(data => {
            this.formulario.patchValue(data);
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions();
          });
      } else {
        this.getService().edit(formValue)
          .subscribe(() => {
            this.disabledForm();
            this.getGrid().reloadPage();
            this.showMessageActions('success', 'update');
          });
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  endPointCollectRma = () => SDM_URL.collect.rma.default;

  getNewInstance(): CollectRmAuthorization {
    return new CollectRmAuthorization();
  }
  getService(): AbstractService<CollectRmAuthorization> {
    return this._collectRmaService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid$;
  }
  getComponentName(): string {
    return 'collect.rma';
  }

  validateRma(event) {
    let inputValue = event.target.value;
    if (inputValue == 0 || (!(inputValue % 1 === 0)) || inputValue.length > 3) {
      this.formulario.get('size').setErrors({ pattern: true });
    }
    else {
      this.formulario.get('size').clearValidators();
      this.formulario.get('size').updateValueAndValidity();
    }
  }

}
