import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-btn-download',
  templateUrl: './btn-download.component.html',
  styleUrls: ['./btn-download.component.scss'],
  preserveWhitespaces: true,
})
export class BtnDownloadComponent implements OnInit {

  @Input() isTemplate = false;
  @Output() btnDownloadClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  download() {
    this.btnDownloadClicked.emit();
  }

}
