import { AbstractModel } from '../../abstract.model';
import { IDeserializable } from '../../deserializable.model';

export class ReverseDeliverySmallSize extends AbstractModel
  implements IDeserializable {
  public establishmentId: number;
  public depositorId: number;
  public depositorDeliverySmallSize: boolean;
  public maxDurationDeliveryInMinutes: number;
  public specificDelivery: boolean;
  public docaNumber: number;

  constructor() {
    super();
    this.depositorDeliverySmallSize = false;
  }

  deserializable(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
