import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
  preserveWhitespaces: true
})
export class FormCardComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string = 'fa fa-list';
  @Input() buttonAdd = false;
  @Input() className = '';

  @Output() buttonAddClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  addButton() {
    this.buttonAddClicked.emit();
  }
}
