import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DocksScheduling } from '../../../../core/models/sdm/document/docks-scheduling';
import { Provider } from '../../../../core/models/sdm/provider-management/provider';
import { DocksSchedulingService } from '../../../../core/services/sdm/document/docks-scheduling.service';
import { ModalCustomComponent } from './../../../../components/modal-custom/modal-custom.component';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { PriorVisualization } from './../../../../core/models/sdm/docks/prior-visualization';
import { AuthService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-reschedule-unique-option',
  templateUrl: './reschedule-unique-option.component.html',
  styleUrls: ['./reschedule-unique-option.component.scss'],
  preserveWhitespaces: true
})
export class RescheduleUniqueOptionComponent implements OnInit, OnDestroy {
  depositors: Depositor[] = [];
  docks: PriorVisualization[] = [];
  providers: Provider[] = [];

  subscriptions: Subscription[] = [];
  formValues = [];
  authorizations = [];

  optionsOperationStatus = [
    {
      name: 'reschedule-various-option.operationStatus.options.available',
      value: 1
    },
    {
      name: 'reschedule-various-option.operationStatus.options.blocked',
      value: 2
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeOne',
      value: 3
    },
    {
      name: 'reschedule-various-option.operationStatus.options.reserveTypeTwo',
      value: 4
    }
  ];

  bsModalRef: BsModalRef;

  formRescheduleUniqueOption: FormGroup;
  deliveryAuthorization: any;
  dockSchedule: DocksScheduling;
  docksSchedulingSearch: DocksScheduling;

  constructor(
    public modalService: BsModalService,
    private _docksSchedulingService: DocksSchedulingService,
    private _router: Router,
    private _actvRouter: ActivatedRoute,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this.formRescheduleUniqueOption = new FormGroup({
      depositor: new FormControl(''),
      providerId: new FormControl(''),
      authorizationkey: new FormControl(''),
      dateScheduleDelivery: new FormControl(''),
      carShowSchedule: new FormControl(''),
      initialHour: new FormControl(''),
      finalHour: new FormControl(''),
      priorVisualization: new FormControl(''),
      dateScheduleDeliveryUnique: new FormControl(''),
      carShowScheduleUnique: new FormControl(''),
      initialHourUnique: new FormControl(''),
      finalHourUnique: new FormControl(''),
      priorVisualizationUnique: new FormControl(''),
      reserveType: new FormControl('')
    });

    this._actvRouter.queryParams.subscribe((params: any) => {
      try {
        this.deliveryAuthorization = JSON.parse(
          params['deliveryAuthorization']
        );

        this.docksSchedulingSearch = JSON.parse(params['docksScheduling']);

        this.dockSchedule = JSON.parse(params['dockSchedule']);

        
      } catch (e) {}

      this.getFormValuesFromQueryParams(params);
      this.getProvidersFromQueryParams(params);
      this.getAuthorizationFromQueryParams(params);
      this.getScheduling(this.docksSchedulingSearch[0]);
    });
  }

  getScheduling(docksScheduling: DocksScheduling) {
    try {
      this.formRescheduleUniqueOption
        .get('dateScheduleDeliveryUnique')
        .setValue(docksScheduling.dateScheduleDelivery);
      this.formRescheduleUniqueOption
        .get('carShowScheduleUnique')
        .setValue(docksScheduling.carShowSchedule);
      this.formRescheduleUniqueOption
        .get('initialHourUnique')
        .setValue(docksScheduling.initialHour);
      this.formRescheduleUniqueOption
        .get('finalHourUnique')
        .setValue(docksScheduling.finalHour);
      this.formRescheduleUniqueOption
        .get('priorVisualizationUnique')
        .setValue(docksScheduling.priorVisualization);
    } catch (e) {}
  }

  getFormValuesFromQueryParams(params: any) {
    try {
      this.formValues = JSON.parse(params['formValues']);

      

      this.formRescheduleUniqueOption.patchValue(this.formValues);
      this.depositors = [this.formValues['depositor']];
      this.docks = [this.formValues['priorVisualization']];
    } catch (e) {}
  }

  getProvidersFromQueryParams(params: any) {
    try {
      this.providers = JSON.parse(params['provider']);
      this.formRescheduleUniqueOption
        .get('providerId')
        .setValue(this.providers[0]);
    } catch (e) {}
  }

  getAuthorizationFromQueryParams(params: any) {
    try {
      const authorization = JSON.parse(params['formValues']);
      this.authorizations = [authorization['authorization']];

      this.formRescheduleUniqueOption
        .get('authorizationkey')
        .setValue(this.authorizations[0]);
    } catch (e) {}
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  exit() {
    this._router.navigate(
      ['sdm/delivery-authorization/edit/reschedule-term-validation'],
      this.queryParams()
    );
  }

  queryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      // queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  confirmContinue() {
    this.openModalConfirmNoShow();
  }

  openModalConfirmNoShow() {
    const initialState = {
      title: 'Mensagem Confirmação',
      message:
        'Tem certeza que deseja alterar a data e/ou horário da Autorização existente?',
      classBtnSuccess: 'btn-outline-danger',
      classBtnDanger: 'btn-success'
      // form: this.formulario
    };
    this.bsModalRef = this.modalService.show(ModalCustomComponent, {
      initialState
    });
    this.bsModalRef.content.closeBtnName = 'Sair';
    this.bsModalRef.content.confirmBtn = 'Confirmar';

    this.subscriptions.push(
      this.modalService.onHide.subscribe((resp: string) => {
        try {
          if (resp === 'OK') {
            this.rescheduleDelivery();
          }
        } finally {
          this.unsubscribe();
        }
      })
    );
  }

  rescheduleDelivery() {
    this._docksSchedulingService
      .rescheduleDelivery(this.dockSchedule.id, this.docksSchedulingSearch, 0)
      .subscribe((data: DocksScheduling) => {
        if (data) {
          this._router.navigate(
            [
              'sdm/delivery-authorization/regular-delivery/generated-authorization'
            ],
            this.prepareQueryParams()
          );
        }
      });
  }

  prepareQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        depositor: JSON.stringify(this.depositors),
        provider: JSON.stringify(this.providers),
        deliveryAuthorization: JSON.stringify(this.deliveryAuthorization)
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    };

    return navigationExtras;
  }

  isInternalUser() {
    let isInternal =
      this._authService.getUserRoles().filter(r => /Internal/gi.test(r.type)) ||
      [];
    return isInternal.length > 0;
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
