import { Component, OnInit, Input } from '@angular/core';
import { state, style, trigger, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  animations: [
    trigger('collapsedExpanded', [
      state('expanded', style({
        'max-height': '500px',
      })),
      state('collapsed', style({
        'max-height': '0',
      })),
      transition('collapsed => expanded', [
        animate('300ms ease-in', style({ 'max-height': '500px' })),
      ]),
      transition('expanded => collapsed', [
        animate('150ms ease-out', style({ 'max-height': '0' })),
      ]),
    ]),
  ],
})
export class CollapseComponent implements OnInit {

  isCollapsible = true;

  @Input() label = '';

  constructor() { }

  ngOnInit() {
  }

  filter() {

  }

}
