import { SelectFieldComponent } from './../../../../components/select/select-field/select-field.component';
import { Establishment } from './../../../../core/models/sdm/establishment';
import { Depositor } from './../../../../core/models/sdm/depositor';
import { EstablishmentService } from './../../../../core/services/establishment.service';
import { SDM_URL } from './../../../../app.api';
import { DepositorService } from './../../../../core/services/depositor.service';
import { DatatableApiComponent } from './../../../../components/datatable-api/datatable-api.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractRegister } from '../../../register/abstract-register';
import { OcurrencyRework } from '../../../../core/models/sdm/logisticcosts/ocurrency-rework';
import { OcurrencyReworkService } from '../../../../core/services/sdm/logisticcosts/ocurrency-rework.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from '../../../../core/translator/translator.service';
import { AbstractService } from '../../../../core/services/abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-ocurrency-rework',
  templateUrl: './ocurrency-rework.component.html',
  styleUrls: ['./ocurrency-rework.component.scss']
})
export class OcurrencyReworkComponent extends AbstractRegister<OcurrencyRework>
  implements OnInit {
  @ViewChild(DatatableApiComponent) grid: DatatableApiComponent;
  @ViewChild('establishment') establishment: SelectFieldComponent;

  depositors: Depositor[] = [];
  establishments: Establishment[] = [];

  columns = [
    { prop: 'depositor.establishment.id', name: '# Localidade' },
    { prop: 'depositor.establishment.name', name: 'Localidade' },
    { prop: 'depositor.id', name: '# Depositante' },
    { prop: 'depositor.name', name: 'Depositante' },
    { prop: 'noShowFixedValue', name: 'No Show Valor Fixo (R$)' },
    {
      prop: 'noShowVariableValue',
      name: 'No Show Valor Variável (R$ / hora)'
    },
    {
      prop: 'cancelAntecedenceFixedValue',
      name: 'Cancelamento Valor Fixo (R$)'
    },
    {
      prop: 'cancelAntecedenceVariableValue',
      name: 'Cancelamento Valor Variável (R$ / hora)'
    },
    {
      prop: 'arrivedVehicleFixedValue',
      name: 'Chegada Atrasada Veículo Valor Fixo (R$)'
    },
    {
      prop: 'arrivedVehicleVariableValue',
      name: 'Chegada Atrasada Veículo Valor Variável (R$ / hora)'
    }
  ];

  constructor(
    public location: Location,
    public toaster: ToastrService,
    public translator: TranslatorService,
    public depositorService: DepositorService,
    private _ocurrencyReworkService: OcurrencyReworkService,
    private establishmentService: EstablishmentService
  ) {
    super(location, toaster, translator, depositorService);
  }

  ngOnInit() {
    this.formulario = new FormGroup({
      id: new FormControl(''),
      establishmentId: new FormControl('', [Validators.required]),
      depositorId: new FormControl('', [Validators.required]),
      noShowFixedValue: new FormControl(''),
      noShowVariableValue: new FormControl(''),
      cancelAntecedenceFixedValue: new FormControl(''),
      cancelAntecedenceVariableValue: new FormControl(''),
      arrivedVehicleFixedValue: new FormControl(''),
      arrivedVehicleVariableValue: new FormControl(''),
      createdBy: new FormControl(''),
      status: new FormControl('')
    });

    this.getEstablishments();
  }

  getEstablishments() {
    this.establishmentService.findAllPath('/findAll').subscribe(data => {
      this.establishments = data['body']['content'];
    });
  }

  establishmentChanged(establishment) {
    this.depositors = [];
    this.formulario.get('depositorId').setValue([]);
    if (establishment) {
      this.depositorService
        .findByEstablishmentId(establishment.id)
        .subscribe(data => {
          this.depositors = data.body['content'];

          if (this.depositors.length === 1) {
            this.formulario.get('depositorId').setValue(this.depositors[0]);
          }
        });
    }
  }

  addButton() {
    this.formEnabled = true;
    this.formulario.enable();
    this.formulario.reset();

    this.establishment.items = this.establishments;
  }

  formFillToEdit(row: object) {
    this.formulario.patchValue(row);
    this.formulario.enable();
    this.disableFields(['establishmentId', 'depositorId']);
    this.fillDepositorWhenSelectGridCell(row);
  }

  submit() {
    if (this.formulario.valid) {
      this.fillDepositorWithId();
      if (this.formulario.get('id').value == null) {
        this.subscriptions.push(
          this.getService()
            .create(this.formulario.value)
            .subscribe(data => {
              this.formulario.patchValue(data);
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions();
            })
        );
      } else {
        this.subscriptions.push(
          this.getService()
            .edit(this.formulario.getRawValue())
            .subscribe(() => {
              this.disabledForm();
              this.getGrid().reloadPage();
              this.showMessageActions('success', 'update');
            })
        );
      }
    } else {
      this.verificaValidacoesForm(this.formulario);
    }
  }

  endPointOcurrencyRework = () => SDM_URL.logisticCosts.ocurrencyRework.findAll;

  getNewInstance() {
    return new OcurrencyRework();
  }
  getService(): AbstractService<OcurrencyRework> {
    return this._ocurrencyReworkService;
  }
  getGrid(): DatatableApiComponent {
    return this.grid;
  }
  getComponentName(): string {
    return 'logistic-costs.ocurrency-rework';
  }
}
