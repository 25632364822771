import { DocksService } from './../docks.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docks-form',
  templateUrl: './docks-form.component.html',
  styleUrls: ['./docks-form.component.scss'],
})
export class DocksFormComponent implements OnInit {

  constructor(private _docksServiceEvents: DocksService) { }

  ngOnInit() {
  }

  onSelect(selected) {
    const element = selected.id;
    switch (element) {
      case 'tabHolidayRelationShip':
        this._docksServiceEvents.tabHolidayRelationShip();
        break;
      case 'tabPriorVisualization':
        this._docksServiceEvents.tabPriorVisualization();
        break;
      case 'tabDockCompatibility':
        this._docksServiceEvents.tabDockCompatibility();
        break;
      case 'tabDockVehicleCompatibility':
        this._docksServiceEvents.tabDockVehicleCompatibility();
        break;
    }
  }

}
