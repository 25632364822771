import { Pipe } from '@angular/core';

@Pipe({
  name: 'dateOnlyPipe'
})
export class DateOnlyPipe {

  transform(value: String): String {
    let date = value.split("T")[0];
    date = date.replace("-", "/");
    date = date.replace("-", "/");
    return date;
  }

}
