import { SDM_URL } from './../../../app.api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SlotReserve } from '../../models/sdm/slot/slot-reserve';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment as env } from '../../../../environments/environment';
import { AbstractService } from '../abstract.service';

@Injectable()
export class SlotReserveService extends AbstractService<SlotReserve> {
  constructor(public http: HttpClient) {
    super(http, env.host.sdm, '/wrapper/slot-reserve');
  }

  findByDepositorIdAndStatus(
    depositorId: number
  ): Observable<HttpResponse<SlotReserve>> {
    return this.http.get<SlotReserve>(
      `${env.host.sdm}/wrapper/slot-reserve/depositor/${depositorId}`,
      { observe: 'response' }
    );
  }
}
