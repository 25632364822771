import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[decimalOnly]'
})
export class DecimalOnlyDirective {
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];
    private decimalLength: number = 4;
    private chunkLength: number = 3;

    constructor(private el: ElementRef) {
    }
    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        let result = '\\d(?=(\\d{' + this.chunkLength + '})+' + (this.decimalLength > 0 ? '\\D' : '$') + ')';
        let num = parseFloat(this.el.nativeElement.value).toFixed(Math.max(0, ~~this.decimalLength));
        this.el.nativeElement.value = num.replace(new RegExp(result, 'g'), '$&');
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        } else if (event.key === ',' || event.key === '.' || event.key === 'e' || event.key === 'E') {
            event.preventDefault();
        }else{
            return;
        }

    }
}